import { translate } from '../../../utils/locale';

const isVisible = (p: string, param: string): boolean => param !== p;

/**
 ** Connect Routes
 */
export const connectStudents: (
	params: { [key: string]: string },
	isLoading: boolean,
	canViewKs4PerfMeasures: boolean,
	canViewP8Measures: boolean
) => PrimaryNavigation.Route[] = (params, isLoading, canViewKs4PerfMeasures, canViewP8Measures) => {
	return [
		{
			name: translate('primaryNavigation.connect.spo.OVERVIEW') as string,
			path: '/overview',
			isDisabled: isLoading,
			isVisible: isVisible('overview', params.studentPage),
			type: 'studentPage',
		},
		{
			name: translate('primaryNavigation.connect.spo.KS4_PERFORMANCE_MEASURES') as string,
			path: '/performance-measures',
			isDisabled: isLoading,
			isVisible: canViewKs4PerfMeasures && isVisible('performance-measures', params.studentPage),
			type: 'studentPage',
		},
		{
			name: translate('primaryNavigation.connect.spo.KS4_P8_MEASURES') as string,
			path: '/progress8',
			isDisabled: isLoading,
			isVisible: canViewP8Measures && isVisible('progress8', params.studentPage),
			type: 'studentPage',
		},
	];
};
