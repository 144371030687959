/**
 * Pads a number to the specified decimal place. Will pad with 0 unless a character is supplied
 * @param {string | number} value
 * @param {number} num
 * @param {character} character
 * @returns {string | number}
 */
export default (
	value: string | number,
	num: number = 0,
	character: string = '0'
): string | number => {
	if (!value.toString().includes('-')) {
		value = Math.round((Number(value) + Number.EPSILON) * 100) / 100;

		let v = value.toString();

		// handle anything weird like ".1" as a value
		if (v.indexOf('.') === 0) {
			v = `0${v}`;
		}

		let pad = character;
		while (pad.length < num) {
			pad = `${pad}${character}`;
		}

		const [pre = 0, post = 0] = v.split('.');
		return `${pre}${num > 0 ? '.' : ''}${`${post}${pad}`.substr(0, num)}`;
	}

	return value;
};
