import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Initial State
 */

const initialState: PerformanceMetrics.State = {
	loading: false,
	metrics: undefined,
	message: undefined,
	appliedComparison: { label: '', value: -1 },
	comparisonGradepointId: undefined,
	mainGradepointId: undefined,
	appliedGradepoints: [],
	hasNewData: false,
	updatedGpId: 'No updated gp',
	progress8Measures: {},

	loadingComparisons: false,
	availableComparisons: [],
	appliedComparisons: [],

	// TODO - remove after testing
	studentMetrics: undefined,
	studentMetricComparison: { label: 'All', value: 0 },
	studentMetricsLoading: false,

	// Toggles
	summaryToggle: false,
	engMathsToggle: false,
	grade4Toggle: false,
	grade5Toggle: false,
	entriesBaccToggle: false,
};

const performanceSlice = createSlice({
	name: 'performanceMetrics',
	initialState,
	reducers: {
		setComparisonsPending(state: PerformanceMetrics.State) {
			return {
				...state,
				loadingComparisons: true,
			};
		},
		setComparisonsSuccess(
			state: PerformanceMetrics.State,
			action: PayloadAction<PerformanceMetrics.Comparison[]>
		) {
			return {
				...state,
				loadingComparisons: false,
				availableComparisons: action.payload,
				appliedComparisons: [],
			};
		},
		setComparisonsFail(state: PerformanceMetrics.State) {
			return {
				...state,
				loadingComparisons: false,
				availableComparisons: [],
				appliedComparisons: [],
			};
		},
		// used in progress 8
		setAppliedComparisons(
			state: PerformanceMetrics.State,
			action: PayloadAction<{ label: string; value: string }[]>
		) {
			return {
				...state,
				appliedComparisons: action.payload
					.map(({ value }) => {
						const [comparisonId = -1, comparisonValueId = -1] = value.split('|');

						const isApplied = state.appliedComparisons.find(
							(c) =>
								c.comparisonId === Number(comparisonId) &&
								c.comparisonValueId === Number(comparisonValueId)
						);

						const match = state.availableComparisons.find(
							(c) =>
								c.comparisonId === Number(comparisonId) &&
								c.comparisonValueId === Number(comparisonValueId)
						);

						if (!match || isApplied) {
							return null;
						}

						return {
							...match,
							comparisonDisplay: `${match.comparisonDisplay} - ${match.comparisonValueDisplay}`,
						};
					})
					.filter(Boolean),
			};
		},
		setMetricsPending(state: PerformanceMetrics.State) {
			state.loading = true;
		},
		setMetricsSuccess(
			state: PerformanceMetrics.State,
			action: PayloadAction<Array<PerformanceMetrics.Metrics>>
		) {
			const res = action.payload;
			const initialComparison = res.find((r) => r.comparisonName === 'Disadvantaged');
			const appliedComparison = {
				label: initialComparison!.comparisonName,
				value: initialComparison!.comparisonId,
			};

			return {
				...state,
				appliedComparison,
				metrics: res,
				loading: false,
			};
		},
		setMetricsFail(
			state: PerformanceMetrics.State,
			action: PayloadAction<PerformanceMetrics.Message>
		) {
			(state.loading = false), (state.message = action.payload);
		},
		clearMetrics(state: PerformanceMetrics.State) {
			state.loading = false;
			state.metrics = [];
		},
		setAppliedComparison(
			state: PerformanceMetrics.State,
			action: PayloadAction<PerformanceMetrics.AppliedComparison>
		) {
			return {
				...state,
				appliedComparison: action.payload,
			};
		},
		hasDataBeenUpdated(state: PerformanceMetrics.State) {
			state.loading = false;
			state.hasNewData = true;
		},
		resetDataFlag(state: PerformanceMetrics.State) {
			state.loading = false;
			state.hasNewData = false;
		},
		setUpdatedGp(state: PerformanceMetrics.State, action: PayloadAction<any>) {
			state.loading = false;
			state.updatedGpId = action.payload;
		},
		// TODO - remove after testing
		setStudentMetricsSuccess(
			state: PerformanceMetrics.State,
			action: PayloadAction<Array<PerformanceMetrics.Metrics>>
		) {
			const res = action.payload;

			return {
				...state,
				studentMetrics: res,
				studentMetricsLoading: false,
			};
		},
		setStudentMetricComparison(
			state: PerformanceMetrics.State,
			action: PayloadAction<PerformanceMetrics.AppliedComparison>
		) {
			return {
				...state,
				studentMetricComparison: action.payload,
			};
		},
		setStudentMetricsPending(state: PerformanceMetrics.State) {
			return {
				...state,
				studentMetricsLoading: true,
			};
		},
		setProgress8MetricsPending(state: PerformanceMetrics.State) {
			return {
				...state,
				loading: true,
			};
		},
		setProgress8MetricsSuccess(state: PerformanceMetrics.State, action: PayloadAction<any>) {
			return {
				...state,
				progress8Measures: action.payload,
				loading: false,
			};
		},
		setProgress8MetricsFailure(state: PerformanceMetrics.State) {
			return {
				...state,
				loading: false,
				progress8Measures: {},
			};
		},
		resetPerformanceMeasures(state: PerformanceMetrics.State) {
			return {
				...state,
				loading: false,
				metrics: undefined,
				message: undefined,
				appliedComparison: { label: 'Disadvantaged', value: 5 },
				comparisonGradepointId: undefined,
				mainGradepointId: undefined,
				appliedGradepoints: [],
				hasNewData: false,
				updatedGpId: 'No updated gp',
				progress8Measures: {},
				appliedComparisons: [],
				availableComparisons: [],
				// TODO - remove after testing
				studentMetrics: undefined,
				studentMetricComparison: { label: 'All', value: 0 },
				studentMetricsLoading: false,
			};
		},
		handleSummaryToggle(state: PerformanceMetrics.State, action: PayloadAction<boolean>) {
			return {
				...state,
				summaryToggle: action.payload,
			};
		},
		handleEngMathsToggle(state: PerformanceMetrics.State, action: PayloadAction<boolean>) {
			return {
				...state,
				engMathsToggle: action.payload,
			};
		},
		handleGrade4Toggle(state: PerformanceMetrics.State, action: PayloadAction<boolean>) {
			return {
				...state,
				grade4Toggle: action.payload,
			};
		},
		handleGrade5Toggle(state: PerformanceMetrics.State, action: PayloadAction<boolean>) {
			return {
				...state,
				grade5Toggle: action.payload,
			};
		},
		handleEntriesBaccToggle(state: PerformanceMetrics.State, action: PayloadAction<boolean>) {
			return {
				...state,
				entriesBaccToggle: action.payload,
			};
		},
	},
});

export const {
	setMetricsPending,
	setMetricsSuccess,
	setMetricsFail,
	clearMetrics,
	setAppliedComparison,
	hasDataBeenUpdated,
	resetDataFlag,
	setUpdatedGp,
	resetPerformanceMeasures,
	setProgress8MetricsPending,
	setProgress8MetricsFailure,
	setProgress8MetricsSuccess,
	setComparisonsPending,
	setComparisonsFail,
	setComparisonsSuccess,
	setAppliedComparisons,

	// TODO - remove after testing
	setStudentMetricsSuccess,
	setStudentMetricComparison,
	setStudentMetricsPending,

	handleSummaryToggle,
	handleEngMathsToggle,
	handleEntriesBaccToggle,
	handleGrade4Toggle,
	handleGrade5Toggle,
} = performanceSlice.actions;

/**
 * Export Reducer
 */
export default performanceSlice.reducer;
