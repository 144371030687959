import { getAppliedDefaultExamLevels } from '../';
import { getActiveExamLevelTab } from 'features/strategicAnalysis/redux/';
import { AnalysisPages } from 'features/strategicAnalysis/enums';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { select, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

/**
 * Gets the current strategic analysis page
 * @returns string
 */
export function* getStrategicAnalysisPage(): SagaIterator<StrategicAnalysis.AnalysisPage> {
	const pathname = window.location.pathname;
	const isStrategicAnalysisPage = pathname.includes('strategic-analysis');
	const page = pathname.split('/').pop() as StrategicAnalysis.AnalysisPage;
	/**
	 * Handle strategic page
	 * If a gradepoint is changed outside of the strategic analysis area,
	 * set the page to overview because that is where they will land when they return to the strat area,
	 * otherwise set the currently active strategic page
	 */
	const strategicAnalysisPage = isStrategicAnalysisPage ? page : AnalysisPages.OVERVIEW;

	return strategicAnalysisPage;
}

/**
 * Get the current or default exam level tab
 * @param isReset boolean, if true returns the default exam level and index
 * @returns active exam level tab
 */
export function* getExamLevelTab(
	isReset?: boolean
): SagaIterator<StrategicAnalysis.ActiveExamLevelTab> {
	const strategicAnalysisPage: StrategicAnalysis.AnalysisPage = yield call(
		getStrategicAnalysisPage
	);

	const appliedExamLevels: StrategicAnalysis.ExamLevelOption = yield select(
		getAppliedDefaultExamLevels
	);

	const activeTab: StrategicAnalysis.ActiveExamLevelTab = yield select(
		getActiveExamLevelTab(strategicAnalysisPage)
	);

	return isReset ? { examLevel: appliedExamLevels[0].value, index: 0 } : activeTab;
}
