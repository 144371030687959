import { VAS } from '../../../../utils/studentGroup';

/**
 * Returns the fine grade groups for the subject as an object as:
 * Stretch:
 * 		0: { StudentId: "6", Modified: false, AvgPA: 4, FineGradeStatus: 1 }
 * AtRisk:
 * 		0: { StudentId: "6", Modified: false, AvgPA: 4, FineGradeStatus: -1 }
 * Secure:
 * 		0: { StudentId: "6", Modified: false, AvgPA: 4, FineGradeStatus: 0 }
 */
export const getFineGradeGroupsForSubject = ({
	subjectPage,
}: RootState): SubjectPage.FineGradesGroups | undefined => {
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!data || !selectedSubject) {
		return undefined;
	}

	return selectedSubject.FineGradeGroups;
};

export const getAlpsGradesForFineGrades = ({
	subjectPage,
}: RootState): SubjectPage.FineGradesGroups | undefined => {
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!data || !selectedSubject) {
		return undefined;
	}

	const filteredStudents = Object.values(selectedSubject.Students);

	const { Id, PercentileSet, OutcomeGroups, Outcomes, TargetSet, VAMultiplier } = selectedSubject;

	let subjectInformation = {
		displayName: Id,
		percentileSet: PercentileSet,
		outcomeSet: {
			outcomes: Outcomes,
			outcomeGroups: OutcomeGroups,
		},
		targetSet: TargetSet,
		entryMultiplier: VAMultiplier,
	};

	return VAS(
		filteredStudents,
		subjectInformation,
		false /* what if */,
		'FineGradesPage',
		true /* fine grades */
	).grade;
};
