import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex } from 'basecamp';

type Props = {
	dataTest?: string;
	height: number;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
};

/**
 ** Handles the rendering of the home icon / welcome text on homepage
 */
const PrimaryNavMenuItemWrapper: FunctionComponent<Props> = ({
	dataTest,
	children,
	height,
	onMouseEnter,
	onMouseLeave,
}) => {
	/**
	 ** Render Component
	 */
	return (
		<Flex
			dataTest={dataTest ? `${dataTest} primaryNavMenuItemWrapper` : 'primaryNavMenuItemWrapper'}
			setAs="column"
			onMouseEnter={() => onMouseEnter()}
			onMouseLeave={() => onMouseLeave()}
			position="relative"
			height={height}
			withAlign="center"
		>
			{children}
		</Flex>
	);
};

export default PrimaryNavMenuItemWrapper;
