import { IbPerformanceMeasures } from '../../types/index';
/**
 * Gets loading status
 * @param state Redux state
 * @returns Boolean
 */
export const getLoading = (state: RootState) => {
	return state.ibPerformanceMeasures.loading;
};

/**
 * Gets loading status for both the Graph and Grid
 * @param state Redux state
 * @returns Boolean
 */
export const getOverallLoading = (state: RootState) => {
	return state.ibPerformanceMeasures.loading || state.ibPerformanceMeasures.graphLoading;
};

/**
 * Gets any errors message for the graph
 * @param state Redux state
 * @returns string
 */
export const getGraphErrors = (state: RootState) => {
	return state.ibPerformanceMeasures.graphError;
};

/**
 * Gets any errors message
 * @param state Redux state
 * @returns string
 */
export const getErrors = (state: RootState) => {
	return state.ibPerformanceMeasures.error;
};
/**
 * Gets any errors message
 * @param state Redux state
 * @returns string
 */
export const getExportExcel = (state: RootState) => {
	return state.ibPerformanceMeasures.exportExcel;
};
/**
 * Gets the KS5 performance measures selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedComparisons = (
	state: RootState
): IbPerformanceMeasures.ComparisonOption => {
	return state.ibPerformanceMeasures.selectedComparisons;
};
/**
 * Gets the KS5 performance measures applied comparisons
 * @param state Redux state
 * @returns Array of applied comparisons
 */
export const getAppliedComparisons = (state: RootState): IbPerformanceMeasures.ComparisonOption => {
	return state.ibPerformanceMeasures.appliedComparisons;
};
/**
 * Gets the KS5 performance measures selected filters
 * @param state Redux state
 * @returns Array of selected filters
 */
export const getSelectedFilters = (state: RootState) => {
	return state.ibPerformanceMeasures.selectedFilters;
};
/**
 * Gets the KS5 performance measures applied filters
 * @param state Redux state
 * @returns Array of applied filters
 */
export const getAppliedFilters = (state: RootState) => {
	return state.ibPerformanceMeasures.appliedFilters;
};
/**
 * Gets all the loaded exam level data
 * @param state redux state
 * @returns An object byId / allIds for the loaded exam level data
 */
export const getAllExamLevelData = (state: RootState) => {
	return state.ibPerformanceMeasures.examLevels;
};
/**
 * Gets all the loaded grid data
 * @param state redux state
 * @returns An object byId / allIds for the loaded grid data
 */
export const getAllGridData = (state: RootState) => {
	return state.ibPerformanceMeasures.grids;
};
/**
 * Gets all the loaded comparison data
 * @param state redux state
 * @returns An object byId / allIds for the loaded comparison data
 */
export const getAllComparisonData = (state: RootState) => {
	return state.ibPerformanceMeasures.comparisons;
};
/**
 * Gets all the loaded row data
 * @param state redux state
 * @returns An object byId / allIds for the loaded row data
 */
export const getAllRowData = (state: RootState) => {
	return state.ibPerformanceMeasures.rows;
};

/**
 * Gets state of value toggle
 * @param state Redux state
 * @returns bool
 */
export const getValueToggle = (state: RootState) => {
	return state.ibPerformanceMeasures.valueToggle;
};

/**
 * Gets currently active tab
 * @param state Redux state
 * @returns index of selected tab
 */
export const getActiveTab = (state: RootState): number => {
	return state.ibPerformanceMeasures.activeTab;
};

/**
 * get grid data
 */
export const getGridDataForExamLevel = (gridIds: string[]) => (state: RootState) => {
	const gridData = getAllGridData(state);
	const getGridsForExamLevel = gridData
		? gridIds.flatMap((id) => {
				return Object.values(gridData.byId).filter((data) => {
					return data.gridId === id;
				});
		  })
		: [];

	return getGridsForExamLevel.length > 0 ? { id: gridData!.id, grid: getGridsForExamLevel } : null;
};
