import {
	setSetupLoading,
	setSetupErrorMessage,
	setCustomComparisons,
	addCustomComparison,
	deleteCustomComparison,
} from '..';
import {
	getCustomComparisons as getCustomComparisonsAPI,
	addCustomComparisons as addCustomComparisonsAPI,
	updateCustomComparisons as updateCustomComparisonsAPI,
	deleteCustomComparisons as deleteCustomComparisonsAPI,
} from '../../../../../api/groupsSetupAPI';
import { getCustomComparisons as getCustomComparisonsSelector } from '../selectors';

/**
 * Fetch Group custom comparisons and store them in Redux
 */
export const fetchCustomComparisonsThunk = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	try {
		const responseBuilder = await getCustomComparisonsAPI();

		// Handle response builder errors
		if (responseBuilder.HasError) dispatch(setSetupErrorMessage(responseBuilder.Errors[0].Message));

		// Map api response to a structure suitable for Redux
		const customComparisons: Array<Groups.Setup.ComparisonGroup> = responseBuilder.ResponseObject.map(
			(x: Groups.Setup.ComparisonGroup) => {
				return {
					...x,
					isNew: false,
					isEdited: false,
					isDeleted: false,
				} as Groups.Setup.ComparisonGroup;
			}
		);

		dispatch(setCustomComparisons(customComparisons));
	} catch (err) {
		dispatch(setSetupErrorMessage(err.message));
	}
};

/**
 * Add or update a custom comparison in Redux
 * @param customComparison The custom comparison to create
 */
export const addCustomComparisonThunk = (
	customComparison: Groups.Setup.ComparisonGroup
): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const existing = getCustomComparisonsSelector(state)?.find(
		({ id }) => id === customComparison.id
	);

	const newCustomComparison: Groups.Setup.ComparisonGroup = {
		...customComparison,
		isNew: existing ? existing.isNew || false : true,
		isEdited: true,
		isDeleted: false,
	};

	dispatch(addCustomComparison(newCustomComparison));
};

/**
 * Mark a custom comparison as deleted in Redux
 * @param customComparison The custom comparison to delete
 */
export const deleteCustomComparisonThunk = (
	customComparison: Groups.Setup.ComparisonGroup
): AppThunk => async (dispatch) => {
	dispatch(
		deleteCustomComparison({
			...customComparison,
			isDeleted: true,
		})
	);
};

/**
 * Thunk that will save all custom comparison changes in one go.
 * Including new, updates and deleted custom comparisons.
 */
export const SaveCustomComparisonChangesThunk = (): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const customComparisons = getCustomComparisonsSelector(state);

	// Newly created groups that need to be created
	const newCustomComparisons: Array<Groups.Setup.ComparisonGroup> | undefined =
		customComparisons?.filter((x) => x.isNew && !x.isDeleted) ?? [];

	// Any existing items to be saved
	const existingCustomComparisons: Array<Groups.Setup.ComparisonGroup> | undefined =
		customComparisons?.filter((x) => x.isEdited && !x.isDeleted && !x.isNew && !x.lockHasChanged) ??
		[];

	// Items where lock status has changed
	const deletedCustomComparisons: Array<Groups.Setup.ComparisonGroup> | undefined =
		customComparisons?.filter((x) => x.isDeleted) ?? [];

	// Make all requests in one go
	const [create, update, remove] = await Promise.all([
		newCustomComparisons.length ? addCustomComparisonsAPI(newCustomComparisons) : null,

		existingCustomComparisons.length ? updateCustomComparisonsAPI(existingCustomComparisons) : null,

		deletedCustomComparisons.length
			? deleteCustomComparisonsAPI(deletedCustomComparisons.map((x) => x.id))
			: null,
	]);

	if ((create && create.HasError) || (update && update.HasError) || (remove && remove.HasError)) {
		const errors = [
			...(create ? create.Errors : []),
			...(update ? update.Errors : []),
			...(remove ? remove.Errors : []),
		];

		dispatch(setSetupErrorMessage(errors[0].Message));
	} else {
		dispatch(fetchCustomComparisonsThunk());
	}
};
