import { translate } from '../../../utils/locale';

/**
 ** Connect Routes
 */
export const connectStrategicAnalysis: (
	params: { [key: string]: string },
	isLoading: boolean,
	canViewStrategicAnalysis: boolean
) => PrimaryNavigation.Route[] = (params, isLoading, canViewStrategicAnalysis) => {
	const isVisible = (p: string): boolean => params.strategicAnalysisPage !== p;

	return [
		{
			name: translate('primaryNavigation.connect.strategicAnalysis.OVERVIEW') as string,
			path: '/Overview',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('overview'),
			type: 'strategicAnalysisPage',
		},
		{
			name: translate('primaryNavigation.connect.strategicAnalysis.ATTAINMENT_PROFILE') as string,
			path: '/AttainmentProfile',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('raw-results'),
			type: 'strategicAnalysisPage',
		},
		{
			name: translate('primaryNavigation.connect.strategicAnalysis.QUALITY_INDICATOR') as string,
			path: '/QualityIndicator',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('quality-indicator'),
			type: 'strategicAnalysisPage',
		},
		{
			name: translate('primaryNavigation.connect.strategicAnalysis.SUBJECT_VALUE_ADDED') as string,
			path: '/SubjectValueAdded',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('subject-value-added'),
			type: 'strategicAnalysisPage',
		},
		{
			name: translate('primaryNavigation.connect.strategicAnalysis.BBPA_MULTI_YEAR') as string,
			path: '/BbpaMultiYear',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('bbpa-multi-year'),
			type: 'strategicAnalysisPage',
		},
		{
			name: translate('primaryNavigation.connect.strategicAnalysis.BBPA_CURRENT_YEAR') as string,
			path: '/BbpaCurrentYear',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('bbpa-current-year'),
			type: 'strategicAnalysisPage',
		},
		{
			name: translate('primaryNavigation.connect.strategicAnalysis.T_SCORE') as string,
			path: '/Tscore',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('t-score'),
			type: 'strategicAnalysisPage',
		},

		{
			name: translate('primaryNavigation.connect.strategicAnalysis.QUALITY_OF_TEACHING') as string,
			path: '/QualityOfTeaching',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('quality-of-teaching'),
			type: 'strategicAnalysisPage',
		},

		{
			name: translate(
				'primaryNavigation.connect.strategicAnalysis.STUDENTS_NOT_INCLUDED'
			) as string,
			path: '/StudentsNotIncluded',
			isDisabled: isLoading && canViewStrategicAnalysis,
			isVisible: isVisible('students-not-included'),
			type: 'strategicAnalysisPage',
		},
	];
};
