import { ExcelStyle } from 'ag-grid-community';

/**
 * Check if the supplied value is numeric
 * @param {any}
 * @return {boolean}
 */
export function isNumeric(n: any): boolean {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Check that supplied value is undefined or string and if so if empty
 * @param {any}
 * @return {boolean}
 */
export function stringIsUndefinedOrEmpty(s: any): boolean {
	if (s === undefined) {
		return true;
	}

	if (typeof s !== 'string' && !(s instanceof String)) {
		return false;
	}

	if (s.trim && s.trim() === '') {
		return true;
	}

	// Not sure what we have at this point...
	return false;
}

/**
 * Check if the supplied value is a function
 * @param {any}
 * @return {boolean}
 */
export function isFunction(f: any): boolean {
	var getType = {};
	return f && getType.toString.call(f) === '[object Function]';
}

/**
 * Check the supplied value is defined
 * @param {any}
 * @return {boolean}
 */
export function exists(x: any): boolean {
	return !(typeof x === 'undefined' || x === null);
}

/**
 * Capitalises the first letter of a string
 * @param {string}
 * @return {string}
 */
export function capitalise(s: string) {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Create valid css selector name - valid for both classes and IDs
 * [/W] matches any non-word character (equal to [^a-zA-Z0-9_])
 * @param {string}
 * @return {string}
 */
export function createValidCSSName(s: any): string {
	if (typeof s !== 'string') {
		s = s.toString();
	}

	// Check to see if the classname already begins with ir
	if (s.slice(0, 3) !== 'ir-') {
		s = `ir-${s}`;
	}
	return s.replace(/[\W]+/g, '-');
}

/**
 * Perform a scroll action to an element of ID if it exists
 * Could be expanded to allow scroll behaviour, eg behavior: smooth
 * @param {string}
 */
export function scrollTo(id: string) {
	const targetEl = document.getElementById(id.toLowerCase().replace(/\s/g, ''));
	targetEl?.scrollIntoView();
}

/**
 * Innumerable exists and has values
 * @param {any[] | undefined | null}
 * @returns {boolean}
 */
export function checkNotNullAndNotEmpty(innumerable: any[] | undefined | null): boolean {
	return innumerable !== null && innumerable !== undefined && innumerable.length > 0;
}

/**
 * Checks if value is null or undefined
 * @param value
 * @returns {boolean}
 */
export function isNullOrUndefined(value: any) {
	return value === null || value === undefined;
}

/**
 * Checks if value is null or undefined or NAN
 * @param value
 * @returns {boolean}
 */
export function isNullOrUndefinedOrNaN(value: any) {
	return value === null || value === undefined || isNaN(value);
}

/**
 * Checks if value is not null or not undefined
 * @param value
 * @returns {boolean}
 */
export function isNotNullOrUndefined(value: any) {
	return value !== null || value !== undefined;
}

/**
 * Excel Styling
 */
export const excelStyles: ExcelStyle[] = [
	{
		id: 'header',
		interior: {
			color: '#aaaaaa',
			pattern: 'Solid',
		},
	},
	{
		id: 'body',
		interior: {
			color: '#dddddd',
			pattern: 'Solid',
		},
	},
];
