import * as React from 'react';
import { FunctionComponent } from 'react';
import moment from 'moment';

import { RtmMessageDrawer } from './components';

import { RtmMessageWrap, RtmMessage, RtmNoMessage } from './components/';

type Props = {
	removeMessages: (messageIds: number[]) => void;
	removeMessage: (messageId: number) => void;
	acknowledgeMessage: (messageId: number) => void;
	rtmMessages: RTM.Message[];
	close: () => void;
};

const RtmMessaging: FunctionComponent<Props> = (props) => {
	/**
	 ** Time Period
	 */
	const timeperiod = {
		0: 'Today',
		1: 'Yesterday',
		2: '1 days ago',
		3: '2 days ago',
		4: '3 days ago',
		5: '4 days ago',
		6: '5 days ago',
		7: '6 days ago',
		8: 'A week ago',
		9: 'About a week ago',
		10: 'About 2 weeks ago',
		11: 'About 3 weeks ago',
		31: '1 month ago',
		32: 'More than 1 month ago',
	};

	/**
	 ** Determine Age of message
	 */
	const determineAge = (date: any) => {
		const today = moment();
		const ageInDays: number = today.diff(date, 'days');

		if (ageInDays === 0) {
			return timeperiod[0];
		} else if (ageInDays === 1) {
			return timeperiod[1];
		} else if (ageInDays < 7) {
			return timeperiod[(ageInDays + 1) as 2 | 3 | 4 | 5 | 6 | 7];
		} else if (ageInDays === 7) {
			return timeperiod[8];
		} else if (ageInDays < 14) {
			return timeperiod[9];
		} else if (ageInDays < 21) {
			return timeperiod[10];
		} else if (ageInDays < 31) {
			return timeperiod[11];
		} else if (ageInDays === 31) {
			return timeperiod[31];
		} else {
			return timeperiod[32];
		}
	};

	/**
	 ** Sort and group messages
	 */
	const rtmMsgs = props.rtmMessages
		.slice()
		.sort((a, b) => {
			return moment(a.timestamp).diff(b.timestamp);
		})
		.reverse()
		.map((message: RTM.Message) => {
			return {
				timeperiod: determineAge(message.timestamp),
				...message,
			};
		})
		.reduce((result, curr) => {
			result[curr.timeperiod] = result[curr.timeperiod] || [];
			result[curr.timeperiod].push(curr);
			return result;
		}, Object.create(null));

	/**
	 ** Render Component
	 */
	return (
		<RtmMessageDrawer close={() => props.close()}>
			{props.rtmMessages && props.rtmMessages.length ? (
				Object.entries(rtmMsgs).map(
					([timeperiod, messages]: any, i: number): React.ReactNode => (
						// Message wrapping element
						<RtmMessageWrap
							key={i}
							rtmMessages={messages}
							timeperiod={timeperiod}
							removeMessages={(ids: number[]) => props.removeMessages(ids)}
						>
							{messages.map(
								(message: any): React.ReactNode => (
									// Individual message
									<RtmMessage
										key={message.id}
										message={message}
										removeMessage={() => props.removeMessage(message.id)}
										acknowledgeMessage={() => props.acknowledgeMessage(message.id)}
									/>
								)
							)}
						</RtmMessageWrap>
					)
				)
			) : (
				// Display if we have no messages
				<RtmNoMessage close={() => props.close()} />
			)}
		</RtmMessageDrawer>
	);
};

export default RtmMessaging;
