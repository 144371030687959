import { setLoading, setErrorMessage } from '../';
import { getAgreementExpiry } from '../../../../api/subscriptionAPI';
import { setAgreementData } from '../slice';

export const fetchAgreements = (): AppThunk => async (dispatch) => {
	dispatch(setLoading());
	try {
		const responseBuilder = await getAgreementExpiry();

		if (responseBuilder.HasError) {
			dispatch(setErrorMessage(responseBuilder.Errors[0].Message));
		}

		dispatch(setAgreementData(responseBuilder.ResponseObject));
	} catch (err) {
		if (err) {
			dispatch(setErrorMessage(err.message));
		}
	}
};
