import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { Report } from 'features/reports/types';
import { hasIncludedStudents } from '../../../utils/studentGroup';
import { Direction } from '../../../types/enum';
import { SubjectPage } from '../types';

enum FineGradeStatus {
	Stretch = 1,
	Secure = 0,
	AtRisk = -1,
}

const initialState: SubjectPage.State = {
	loading: {},
	errorMessage: null,
	data: null,
	currentSubjectId: '',
	exportId: undefined,
	currentSubjectUdfType: '',
	currentSubjectUdfValue: '',
	thermoSlider: {
		droppedGrade: undefined,
		groupTitle: undefined,
		originalGrade: undefined,
	},
	whatIf: false,
	reportControls: {
		history: {
			back: [],
			forward: [],
			hasChanged: undefined,
		},
	},
	adHoc: false,
	savedReportToLoadId: undefined,
	savedReportTitle: undefined,
};

/**
 ** Format the StudentGroup, Teachers, TeachingSets
 */
const formatGroup = (group: Report.Rows[], missingGroup?: Report.Group): SubjectPage.Group | {} => {
	if (!group || !group.length) {
		return {};
	}

	/**
	 * If we have missing udf values add them to the group here,
	 * otherwise just use the group
	 */
	const groups: Report.Rows[] = missingGroup ? [...group, ...missingGroup.Rows] : group;

	return groups.reduce((acc, curr) => {
		if (curr.ExternalIDs?.length > 0) {
			return {
				...acc,
				[curr.Name]: {
					Name: curr.Name,
					ExternalIDs: curr.ExternalIDs ?? [],
					...Object.entries(curr.GradepointScores).reduce((acc, [key, val]) => {
						const { AvgPriorAch, Score, OverallPriorAchType, OverallPriorAchTypeDisplayName } = val;

						return {
							...acc,
							[key.replace(/[ ,.]/g, '')]: {
								Name: key,
								AvgPa: AvgPriorAch,
								Entries: Score.Entries,
								Grade: Score.Grade,
								Score: Score.Display,
								AvgPaType: OverallPriorAchType,
								AvgPaDisplay: OverallPriorAchTypeDisplayName,
							},
						};
					}, {}),
				},
			};
		}

		return acc;
	}, {});
};

const filterStudentsByFineGradeStatus = (
	students: Report.Students[],
	fineGradeStatus: FineGradeStatus
) => {
	return students
		.filter((student: Report.Students) => {
			return student.Outcome.FineGradeStatus === fineGradeStatus && student.AvgPriorAch !== null;
		})
		.map((student: Report.Students) => {
			return {
				StudentId: student.ExternalId,
				Modified: false,
				AvgPA: student.AvgPriorAch?.reduce((acc, student) => {
					return student.Score;
				}, {}),
				FineGradeStatus: student.Outcome.FineGradeStatus,
			} as SubjectPage.FineGrade;
		});
};

/**
 ** Creates an object containing the groups of students within each fine grade group.
 ** Students without a PA are filtered out as they are not VAS-able
 ** Reference old connect - subjectPage.js line 264
 */
const setFineGradeGroups = (
	students: Report.Students[],
	fineGradeSettings: SubjectPage.FineGradeSettings
) => {
	// if there are no students to be included or fine grades is turned off, bail
	if (!hasIncludedStudents(students) || !fineGradeSettings.useFineGrades) {
		return [];
	}

	return {
		Stretch: filterStudentsByFineGradeStatus(students, FineGradeStatus.Stretch),
		AtRisk: filterStudentsByFineGradeStatus(students, FineGradeStatus.AtRisk),
		Secure: filterStudentsByFineGradeStatus(students, FineGradeStatus.Secure),
	} as SubjectPage.FineGradesGroups;
};

const setStudentViewGroups = (students: any) => {
	let studentsGroupedByComparisons = students.reduce((prev: { [k: string]: any[] }, student) => {
		// go through the viewgroups of the student
		let viewGroupsWithStudents = student.ViewGroups.reduce(
			(obj, curr) => ({
				...obj,
				[curr]: [...(obj[curr] || []), student],
			}),
			{}
		);
		return Object.entries(viewGroupsWithStudents).reduce(
			(obj, [key, students]) => ({
				...obj,
				[key]: [...(obj[key] || []), ...students],
			}),
			prev
		);
	}, {});

	let studentViewGroups = {
		All: students,
		...studentsGroupedByComparisons,
	};

	return studentViewGroups;
};

/**
 ** Format students
 */
const formatStudents = (
	acc: SubjectPage.Students,
	curr: Report.Students
): SubjectPage.Students | {} => {
	let forename: string | undefined = undefined;
	let surname: string | undefined = undefined;

	if (curr.Forename?.length !== 0) {
		forename = curr.Forename;
	}

	if (curr.Surname?.length !== 0) {
		surname = curr.Surname;
	}

	return {
		...acc,
		[curr.ExternalId]: {
			StudentId: curr.ExternalId,
			Forename: forename,
			Surname: surname,
			Fullname: forename && surname ? `${surname}, ${forename}` : surname ?? forename ?? '-',
			Upn: curr.Upn,
			GCSEScore: !!curr.GCSEScore ? Number(curr.GCSEScore) : undefined,
			Gender: curr.Gender,
			Disadvantaged: curr.Disadvantaged,
			Eal: curr.Eal,
			GradepointScores: curr.GradepointScores,
			LearningBias: curr.LearningBias,
			MeanSAS: curr.MeanSAS,
			VerbalSAS: curr.VerbalSAS,
			NonVerbalSAS: curr.NonVerbalSAS,
			SpatialSAS: curr.SpatialSAS,
			QuantitativeSAS: curr.QuantitativeSAS,
			PriorAttainment: curr.AvgPriorAch?.reduce((acc, curr) => {
				return {
					...acc,
					[curr.Type]: {
						Level: curr.Type,
						Type: curr.PAType,
						Score: curr.Score,
						PriorAchievementType: curr.PriorAchievementType,
						PriorAchievementTypeDisplayName: curr.PriorAchievementTypeDisplayName,
					},
				};
			}, {}),
			PriorAttainmentType: curr.AvgPriorAch.length && curr.AvgPriorAch[0]?.Type,
			Outcome: curr.Outcome,
			Send: curr.Send,
			TeachingSet: curr.TeachingSet,
			Teacher: curr.Teacher,
			Target: curr.Target,
			TargetPoints: curr.TargetPoints,
			TargetBandId: curr.TargetBandId,
			TargetOutcomePointsDiff: curr.TargetOutcomePointsDiff,
			ViewGroup: curr.ViewGroup,
			AdHocGroup: curr.AdHocGroup || 'orange',
			IsIncluded: curr.IsIncluded,
			IsQGrade: curr.IsQGrade,
			WhatIfOutcome: curr.WhatIfOutcome || curr.Outcome.Value,
			WhatIfOutcomeDisplay: curr.WhatIfOutcomeDisplay || curr.Outcome.Display,
			FineGrade: curr.Outcome.Value,
			ViewGroups: curr.ViewGroups,
		},
	};
};

// go through student list
// set them to modified
// return the modified list
const sortAndModifyStudents = (
	studentCountToModify: number,
	studentsFromGroup: any,
	sliderAction: Direction,
	areRiskGrades = false
): any[] => {
	let studentsToModify = [];
	studentsFromGroup = sortStudentsByAvgPA(studentsFromGroup, sliderAction, areRiskGrades);

	for (let i = 0; i < studentCountToModify; i++) {
		const index =
			sliderAction === Direction.UP
				? studentsFromGroup.findIndex((s) => !s.Modified) // find by not modified
				: studentsFromGroup.findIndex((s) => s.Modified); // find by modified
		studentsFromGroup[index].Modified =
			sliderAction === Direction.UP
				? true // set modified to true
				: false; // set modified to false
		studentsToModify.push(studentsFromGroup[index]);
	}

	return studentsToModify;
};

const sortStudentsByAvgPA = (
	studentsFromGroup: any,
	sliderAction: Direction,
	areRiskGrades = false
) => {
	if (sliderAction === Direction.UP) {
		return !areRiskGrades
			? sortStudentsByAvgPAHighestFirst(studentsFromGroup)
			: sortStudentsByAvgPALowestFirst(studentsFromGroup);
	}

	return !areRiskGrades
		? sortStudentsByAvgPALowestFirst(studentsFromGroup)
		: sortStudentsByAvgPAHighestFirst(studentsFromGroup);
};

const sortStudentsByAvgPALowestFirst = (studentFromGroup: any) => {
	return sortStudentsByAvgPAHighestFirst(studentFromGroup).reverse();
};

const sortStudentsByAvgPAHighestFirst = (studentsFromGroup: any) => {
	return studentsFromGroup.sort(
		(a: SubjectPage.FineGrade, b: SubjectPage.FineGrade) => a.AvgPA > b.AvgPA
	);
};

const modifyFineGradesForStudents = (
	studentsToModify: any[],
	studentsToUpdate: any[],
	outcomes: any,
	sliderDirection: Direction,
	areRiskGrades = false
) => {
	// students to update contains all of the students and details
	// students to modify contains a summary of avg pa and whether they are modified
	// students to update needs to return ALL students but those in the modified list
	// need to have their grades changed to reflect the action
	return studentsToUpdate.map((student) => {
		// if the student is present in the students to modify list
		let studentExists = studentsToModify.find((stu) => stu.StudentId === student.StudentId);
		if (studentExists) {
			// modify the student
			let calculatedStudentGrade = determineStudentGrade(
				student,
				outcomes,
				sliderDirection,
				areRiskGrades
			);
			return {
				...student,
				FineGrade: calculatedStudentGrade,
			};
		}

		return student;
	});
};

const determineStudentGrade = (
	student: any,
	outcomes: any,
	sliderDirection: Direction,
	areRiskGrades = false
): string => {
	if (sliderDirection === Direction.UP) {
		return areRiskGrades
			? dropStudentGrade(outcomes, student)
			: raiseStudentGrade(outcomes, student);
	}

	// the slider is moving down, reset grade
	return student.Outcome.Value;
};

const dropStudentGrade = (outcomes: any, student: any) => {
	const currentGradeIndex = outcomes.indexOf(student.FineGrade);
	if (currentGradeIndex < outcomes.length - 1) {
		return outcomes[currentGradeIndex + 1];
	}
};

const raiseStudentGrade = (outcomes: any, student: any) => {
	const currentGradeIndex = outcomes.indexOf(student.FineGrade);
	if (currentGradeIndex > 0 && student.Outcome.Value !== outcomes[outcomes.length - 1]) {
		return outcomes[currentGradeIndex - 1];
	}

	// Grade index is equal to 0, no change so return the original outcome
	return student.FineGrade;
};

const getSavedReportStudents = (report: Report.SubjectReport, existingStudents: any) => {
	const { whatIf, adHoc, changedStudents } = report;

	let newStudents = { ...existingStudents };

	if (existingStudents) {
		Object.keys(existingStudents)?.forEach((key) => {
			const student = { ...newStudents[key] };
			const changedStudent = changedStudents.find((s) => s.studentId === key || s.id === key);
			if (whatIf && (changedStudent?.grade || changedStudent?.whatIfOutcome)) {
				student.WhatIfOutcome =
					changedStudent?.grade || changedStudent?.whatIfOutcome || student?.WhatIfOutcome; // grade property for comptibility reasons with V1
				student.WhatIfOutcomeDisplay =
					changedStudent?.grade || changedStudent?.whatIfOutcome || student?.WhatIfOutcome;
			}
			if (adHoc && (changedStudent?.adHocGroup || changedStudent?.viewGroup)) {
				student.AdHocGroup =
					changedStudent?.adHocGroup || changedStudent?.viewGroup || student?.AdHocGroup; // viewGroup property for comptibility reasons with V1
			}
			newStudents[key] = student;
		});
	}

	const newStudentsFilteredByTeachingSet = Object.fromEntries(
		Object.entries(newStudents).filter(([key, value]) =>
			changedStudents.some((item) => item.teachingSet === value.TeachingSet)
		)
	);

	return newStudentsFilteredByTeachingSet;
};

/**
 ** Subjects slice
 */
const subjectsSlice = createSlice({
	name: 'subjectPage',
	initialState,
	reducers: {
		setSecureGradeChange(state: SubjectPage.State, action: PayloadAction<any>) {
			/**
			 * All the students in this group can go up OR go down a grade.
			 * When the go up slider has moved up, students with the highest PA are processed first
			 * as they are more likely to get the grade.
			 * When the drop slider is moved down, students with the lowest PA are processed first
			 * as they are less likely to get the grade
			 * Achieve students are not modified explicitly here as each time the function is run,
			 * the students for the secure group are reset - automatically modifying those students
			 */

			const subjectId = state.currentSubjectId;
			const oldState = current(state);

			const s = oldState.data?.[subjectId]?.Students;
			const students = Object.values(s ? s : []);

			// Reset fine grade group by setting the modified state of all students to false
			let resetSecureFGGroup = oldState.data?.[subjectId]?.FineGradeGroups?.Secure.map((s) => {
				return {
					...s,
					Modified: false,
				};
			});

			// includes students from other groups but reset the fine grades of those in this group
			let studentsToUpdate = students.slice().map((s) => {
				if (s.Outcome.FineGradeStatus === 0) {
					return {
						...s,
						FineGrade: s.Outcome.Value,
					};
				}

				return s;
			});

			// get a list of outcomes
			const outcomes = oldState.data?.[subjectId]?.Outcomes.map((o) => o.Outcome.Value);

			// // find out the number of students each slider's percentage represents
			const numberOfGoUpStudents = Math.round(
				resetSecureFGGroup.length * (action.payload.upPercentage / 100)
			);
			const numberOfDropStudents = Math.round(
				resetSecureFGGroup.length * (action.payload.dropPercentage / 100)
			);

			// // helper function to get the students that we can modify
			// // function also updates the reset list so we dont modify the same
			// // student for a different slider
			const getModifyableStudents = (quantity) => {
				let studentsToModify = [];
				let studentsFromGroup = resetSecureFGGroup.slice();
				for (let i = 0; i < quantity; i++) {
					const index = studentsFromGroup.findIndex((s) => !s.Modified);
					if (index !== -1) {
						studentsFromGroup[index].Modified = true;
						studentsToModify.push(studentsFromGroup[index]);
					}
				}

				// replace the reset group with the new modified values for the students
				resetSecureFGGroup = studentsFromGroup;

				return studentsToModify;
			};

			/**
			 * GO UP STUDENTS
			 */
			const goUpStudentsToModify = getModifyableStudents(numberOfGoUpStudents);

			// note: direction is irrelevant as all students have been reset at the start of this reducer
			// it is set to up as we want those that have NOT been modified
			studentsToUpdate = modifyFineGradesForStudents(
				goUpStudentsToModify,
				studentsToUpdate,
				outcomes,
				Direction.UP
			);

			/**
			 * DROP STUDENTS
			 */
			const dropStudentsToModify = getModifyableStudents(numberOfDropStudents);

			// note: direction is irrelevant as all students have been reset at the the start of this reducer
			// it is set to up as we want those that have NOT been modified
			studentsToUpdate = modifyFineGradesForStudents(
				dropStudentsToModify,
				studentsToUpdate,
				outcomes,
				Direction.UP,
				true
			);

			return {
				...state,
				data: {
					[subjectId]: {
						...state.data?.[subjectId],
						Students: studentsToUpdate,
						FineGradeGroups: {
							...state.data?.[subjectId]?.FineGradeGroups,
							Secure: resetSecureFGGroup,
						},
					},
				},
			};
		},
		setAtRiskStudents(state: SubjectPage.State, action: PayloadAction<any>) {
			/**
			 * All the students in this group can go down a grade.
			 * When the slider has moved up, students with the lowest PA are processed first
			 * as they are least likely to get the grade.
			 * When the slider is moved down, students with the highest PA are processed first
			 * as they are more likely to get the grade
			 */

			// if we have no students to modify, bail
			if (action.payload.studentCountDiff === 0) {
				return;
			}

			const subjectId = state.currentSubjectId;
			const oldState = current(state);
			const sliderDirection = action.payload.riskAction;
			const s = oldState.data?.[subjectId]?.Students;
			const students = Object.values(s ? s : []);
			const outcomes = oldState.data?.[subjectId]?.Outcomes.map((o) => o.Outcome.Value);
			const fineGroupStudents = oldState.data?.[subjectId]?.FineGradeGroups?.AtRisk.map((s) => {
				return {
					...s,
				};
			});

			let studentsFromGroup = fineGroupStudents.slice();
			let studentsToModify = sortAndModifyStudents(
				action.payload.studentCountDiff,
				studentsFromGroup,
				sliderDirection,
				true
			);
			let studentsToUpdate = students.slice().map((s) => {
				return {
					...s,
				};
			});

			// go through each of the students to modify and drop their fine grades
			studentsToUpdate = modifyFineGradesForStudents(
				studentsToModify,
				studentsToUpdate,
				outcomes,
				sliderDirection,
				true
			);

			// update state
			return {
				...state,
				data: {
					[subjectId]: {
						...state.data?.[subjectId],
						Students: studentsToUpdate,
						FineGradeGroups: {
							...state.data?.[subjectId]?.FineGradeGroups,
							AtRisk: studentsFromGroup,
						},
					},
				},
			};
		},
		resetFineGradeGroups(state: SubjectPage.State) {
			const subjectId = state.currentSubjectId;
			const fineGradeGroups = state.data?.[subjectId]?.FineGradeGroups;
			const students = Object.values(
				state.data?.[subjectId]?.Students ? state.data?.[subjectId]?.Students : []
			);

			if (fineGradeGroups) {
				const resetFineGrades = Object.entries(fineGradeGroups).reduce((obj, curr) => {
					// get the students for the current fine grade group
					const groupStudents = curr[1];

					// set the modified status to false
					const resetStudents = groupStudents.map((s) => {
						return {
							...s,
							Modified: false,
						};
					});

					return {
						...obj,
						[curr[0]]: resetStudents,
					};
				}, {});

				// reset all of the finegrades for all of the students, regardless of what group they're in
				const resetStudents = students.map((stu) => {
					return {
						...stu,
						FineGrade: stu.Outcome.Value,
					};
				});

				return {
					...state,
					data: {
						[subjectId]: {
							...state.data?.[subjectId],
							Students: resetStudents,
							FineGradeGroups: resetFineGrades,
						},
					},
				};
			}
			return state;
		},
		setStretchStudents(state: SubjectPage.State, action: PayloadAction<any>) {
			/**
			 * All the students in this group can go up a grade.
			 * When the slider has moved up, students with the highest PA are processed first
			 * as they are more likely to get the grade.
			 * When the slider is moved down, students with the lowest PA are processed first
			 * as they are least likely to get the grade
			 */

			// if we have no students to modify, bail
			if (action.payload.studentCountDiff === 0) {
				return;
			}

			const subjectId = state.currentSubjectId;
			const oldState = current(state);
			const sliderDirection = action.payload.stretchAction;
			const s = oldState.data?.[subjectId]?.Students;
			const students = Object.values(s ? s : []);
			const outcomes = oldState.data?.[subjectId]?.Outcomes.map((o) => o.Outcome.Value);
			const fineGroupStudents = oldState.data?.[subjectId]?.FineGradeGroups?.Stretch.map((s) => {
				return {
					...s,
				};
			});

			let studentsFromGroup = fineGroupStudents.slice();
			let studentsToModify = sortAndModifyStudents(
				action.payload.studentCountDiff,
				studentsFromGroup,
				sliderDirection
			);
			let studentsToUpdate = students.slice().map((s) => {
				return {
					...s,
				};
			});

			// go through each of the students to modify and increase the fine grades
			studentsToUpdate = modifyFineGradesForStudents(
				studentsToModify,
				studentsToUpdate,
				outcomes,
				sliderDirection
			);

			// update state
			return {
				...state,
				data: {
					[subjectId]: {
						...state.data?.[subjectId],
						Students: studentsToUpdate,
						FineGradeGroups: {
							...state.data?.[subjectId]?.FineGradeGroups,
							Stretch: studentsFromGroup,
						},
					},
				},
			};
		},
		setWhatIf(state: SubjectPage.State, action: PayloadAction<boolean>) {
			return {
				...state,
				whatIf: action.payload,
			};
		},
		setAdHoc(state: SubjectPage.State, action: PayloadAction<boolean>) {
			return {
				...state,
				adHoc: action.payload,
			};
		},
		setCurrentSubjectId(state: SubjectPage.State, action: PayloadAction<string | null>) {
			return {
				...state,
				currentSubjectId: action.payload ? decodeURIComponent(action.payload) : null,
			};
		},
		setExportId(state: SubjectPage.State, action: PayloadAction<string>) {
			return {
				...state,
				exportId: action.payload,
				loading: { ...state.loading, ['SubjectData']: false },
			};
		},
		setCurrentSubjectUdf(
			state: SubjectPage.State,
			action: PayloadAction<{ type: string; value: string }>
		) {
			return {
				...state,
				currentSubjectUdfType: action.payload.type ? decodeURIComponent(action.payload.type) : '',
				currentSubjectUdfValue: action.payload.value
					? decodeURIComponent(action.payload.value)
					: '',
			};
		},
		setLoading(state: SubjectPage.State, action: PayloadAction<SubjectPage.LoadingType>) {
			return {
				...state,
				loading: { ...state.loading, [action.payload]: true },
				errorMessage: null,
			};
		},
		setSavedSubjectReports(state: SubjectPage.State, action: PayloadAction<Report.SubjectReports>) {
			return {
				...state,
				loading: { ...state.loading, ['SavedSubjectReports']: false },
				errorMessage: null,
				data: {
					...state.data,
				},
				savedReports: action.payload,
			};
		},
		setAddSubjectReport(state: SubjectPage.State, action: PayloadAction<Report.SubjectReport>) {
			const newReport = action.payload;
			const oldState = current(state);
			let updatedReports = oldState.savedReports?.map((r: Report.SubjectReport) => r);
			updatedReports[updatedReports.length] = newReport;

			return {
				...state,
				loading: { ...state.loading, ['AddSubjectReport']: false },
				errorMessage: null,
				data: {
					...state.data,
				},
				savedReports: updatedReports,
				savedReportTitle: newReport?.title,
			};
		},
		setReportId(state: SubjectPage.State, action: PayloadAction<string>) {
			const reportId = action.payload;

			return {
				...state,
				savedReportToLoadId: reportId,
			};
		},
		setSavedReportTitle(state: SubjectPage.State, action: PayloadAction<string | null>) {
			const reportTitle = action.payload;
			const oldState = current(state);
			const currentReport = oldState.savedReports?.find(
				(r: Report.SubjectReport) => r.title === reportTitle
			);

			return {
				...state,
				savedReportTitle: currentReport?.title,
			};
		},
		setLoadSavedReport(state: SubjectPage.State, action: PayloadAction<Report.SubjectReport>) {
			const { whatIf, adHoc, title, Entity: subjectId } = action.payload;

			const oldState = current(state);
			const initialStudents = oldState.data?.[subjectId]?.InitialStudents;
			let newStudents = { ...oldState.data?.[subjectId]?.InitialStudents };

			newStudents = getSavedReportStudents(action.payload, initialStudents);

			return {
				...state,
				loading: { ...state.loading, ['LoadSavedSubjectReport']: false },
				errorMessage: null,
				data: {
					...state.data,
					[subjectId]: {
						...state.data?.[subjectId],
						Students: newStudents,
					},
				},
				adHoc: adHoc || false,
				whatIf: whatIf || false,
				savedReportTitle: title,
			};
		},
		setUpdateSubjectReport(state: SubjectPage.State, action: PayloadAction<Report.SubjectReport>) {
			const updatedReport = action.payload;
			const oldState = current(state);
			let updatedReports = oldState.savedReports?.filter(
				(r: Report.SubjectReport) => r.id !== updatedReport.id
			);

			updatedReports[updatedReports.length] = updatedReport;

			return {
				...state,
				loading: { ...state.loading, ['RenameSubjectReport']: false },
				errorMessage: null,
				data: {
					...state.data,
				},
				savedReports: updatedReports,
				savedReportTitle: updatedReport.title,
			};
		},
		setDeleteSubjectReport(state: SubjectPage.State, action: PayloadAction<string>) {
			const deletedReportId = action.payload;
			const oldState = current(state);
			const updatedReports = oldState.savedReports?.filter(
				(r: Report.SubjectReport) => r.id !== deletedReportId
			);

			return {
				...state,
				loading: { ...state.loading, ['DeleteSubjectReport']: false },
				errorMessage: null,
				data: {
					...state.data,
				},
				savedReports: updatedReports,
				savedReportTitle: null,
			};
		},
		setSubjectData(state: SubjectPage.State, action: PayloadAction<Report.SubjectPage>) {
			const {
				DisplayName,
				HasDataForGP,
				HasInYearDataForSGP,
				StudentGroupScores,
				UDFOverviewGroups,
				UDFOverviewMissing,
				Students,
				BandSetKey,
				Benchmark,
				ExamLevel,
				OutcomeSet,
				TargetSet,
				VAMultiplier,
				FineGradeSettings,
				PriorAchievmentType,
			} = action.payload;

			if (!HasDataForGP) {
				return {
					...state,
					loading: { ...state.loading, ['SubjectData']: false },
					errorMessage: null,
					data: null, // clear data
				};
			}

			const students = Students.sort((a, b) => a.ExternalId.localeCompare(b.ExternalId)).reduce(
				(acc: SubjectPage.Students, curr: Report.Students) => formatStudents(acc, curr),
				{}
			);

			// load saved report if id to load exists
			const previousState = current(state);
			const savedReportId = previousState?.savedReportToLoadId;
			const loadingSavedReport =
				savedReportId && previousState?.savedReports?.find((r: any) => r.id === savedReportId);

			const newStudents = !!loadingSavedReport
				? getSavedReportStudents(loadingSavedReport, students)
				: students;

			return {
				...state,
				loading: { ...state.loading, ['SubjectData']: false },
				errorMessage: null,
				data: {
					...state.data,
					[DisplayName]: {
						Id: DisplayName,
						HasUdfData: false,
						HasDataForGP,
						HasInYearDataForSGP,
						Gradepoints: Students.reduce((acc, curr) => {
							return {
								...acc,
								...curr.GradepointScores,
							};
						}, {}),
						StudentGroups: StudentGroupScores.Rows ? formatGroup(StudentGroupScores.Rows) : {},
						TeachingSetGroups: UDFOverviewGroups.TeachingSetOverview.Rows
							? formatGroup(
									// Add teaching set groups
									UDFOverviewGroups.TeachingSetOverview.Rows,
									// Add missing teaching set groups, if we have any
									UDFOverviewMissing['TeachingSetOverview']
							  )
							: {},
						TeacherGroups: UDFOverviewGroups.TeachersOverview.Rows
							? formatGroup(
									// Add teacher groups
									UDFOverviewGroups.TeachersOverview.Rows,
									// Add missing teacher groups, if we have any
									UDFOverviewMissing['TeachersOverview']
							  )
							: {},
						InitialStudents: students, // DO NOT TRY TO MODIFY the intial students array content
						Students: newStudents,
						BandSetKey,
						Benchmark,
						ExamLevel,
						FineGradeGroups: FineGradeSettings
							? setFineGradeGroups(Students, FineGradeSettings)
							: null,
						OutcomeGroups: OutcomeSet.OutcomeGroups,
						Outcomes: OutcomeSet.Outcomes,
						UdfData: null,
						TargetSet,
						PercentileSet: Benchmark
							? Benchmark.reduce((acc, curr) => {
									return {
										...acc,
										[`P${curr.Percentile}`]: curr.Value,
									};
							  }, {})
							: {},
						VAMultiplier,
						FineGradeSettings: FineGradeSettings ? FineGradeSettings : null,
						ViewGroups: setStudentViewGroups(Students),
						PriorAchievmentType: PriorAchievmentType,
					},
				},
				savedReportTitle: null,
			};
		},
		clearSubjectData(state: SubjectPage.State) {
			return {
				...state,
				data: null,
			};
		},
		setSubjectUdfData(state: SubjectPage.State, action: PayloadAction<Report.SubjectPage>) {
			const {
				DisplayName,
				HasDataForGP,
				HasInYearDataForSGP,
				StudentGroupScores,
				UDFOverviewGroups,
				UDFOverviewMissing,
				Students,
				BandSetKey,
				Benchmark,
				ExamLevel,
				OutcomeSet,
				TargetSet,
				VAMultiplier,
				FineGradeSettings,
				PriorAchievmentType,
			} = action.payload;

			const students = Students.sort((a, b) => a.ExternalId.localeCompare(b.ExternalId)).reduce(
				(acc: SubjectPage.Students, curr: Report.Students) => formatStudents(acc, curr),
				{}
			);

			// load saved report if id to load exists
			const previousState = current(state);
			const savedReportId = previousState?.savedReportToLoadId;
			const loadingSavedReport =
				savedReportId && previousState?.savedReports?.find((r: any) => r.id === savedReportId);
			const newStudents = !!loadingSavedReport
				? getSavedReportStudents(loadingSavedReport, students)
				: students;

			const udfValue = state.currentSubjectUdfValue;

			// depending on the entry method, we may not have the subject data loaded into state
			if (!state.data[DisplayName]) {
				return {
					...state,
					loading: { ...state.loading, ['SubjectData']: false },
					errorMessage: null,
					data: {
						...state.data,
						[DisplayName]: {
							Id: DisplayName,
							HasUdfData: false,
							HasDataForGP,
							HasInYearDataForSGP,
							Gradepoints: Students.reduce((acc, curr) => {
								return {
									...acc,
									...curr.GradepointScores,
								};
							}, {}),
							StudentGroups: StudentGroupScores.Rows ? formatGroup(StudentGroupScores.Rows) : {},
							TeachingSetGroups: UDFOverviewGroups?.TeachingSetOverview?.Rows
								? formatGroup(
										// Add teaching set groups
										UDFOverviewGroups.TeachingSetOverview.Rows,
										// Add missing teaching set groups, if we have any
										UDFOverviewMissing['TeachingSetOverview']
								  )
								: {},
							TeacherGroups: UDFOverviewGroups?.TeachersOverview?.Rows
								? formatGroup(
										// Add teacher groups
										UDFOverviewGroups.TeachersOverview.Rows,
										// Add missing teacher groups, if we have any
										UDFOverviewMissing['TeachersOverview']
								  )
								: {},
							InitialStudents: students, // DO NOT TRY TO MODIFY the intial students array content
							Students: newStudents,
							BandSetKey,
							Benchmark,
							ExamLevel,
							FineGradeGroups: FineGradeSettings
								? setFineGradeGroups(Students, FineGradeSettings)
								: null,
							OutcomeGroups: OutcomeSet.OutcomeGroups,
							Outcomes: OutcomeSet.Outcomes,
							UdfData: {
								[udfValue]: StudentGroupScores.Rows ? formatGroup(StudentGroupScores.Rows) : {},
							},
							TargetSet,
							PercentileSet: Benchmark
								? Benchmark.reduce((acc, curr) => {
										return {
											...acc,
											[`P${curr.Percentile}`]: curr.Value,
										};
								  }, {})
								: {},
							VAMultiplier,
							FineGradeSettings: FineGradeSettings ? FineGradeSettings : null,
							ViewGroups: setStudentViewGroups(Students),
							PriorAchievmentType: PriorAchievmentType,
						},
					},
				};
			}

			return {
				...state,
				loading: { ...state.loading, ['SubjectData']: false },
				errorMessage: null,
				data: {
					...state.data,
					[DisplayName]: {
						//@ts-ignore
						...state.data[DisplayName],
						HasUdfData: true,
						UdfData: {
							//@ts-ignore
							...state.data[DisplayName].UdfData,
							[udfValue]: StudentGroupScores.Rows ? formatGroup(StudentGroupScores.Rows) : {},
						},
						Students: Students.sort((a, b) => a.ExternalId.localeCompare(b.ExternalId)).reduce(
							(acc: SubjectPage.Students, curr: Report.Students) => formatStudents(acc, curr),
							{}
						),
					},
				},
			};
		},
		setErrorMessage(state: SubjectPage.State, action: PayloadAction<string>) {
			return {
				...state,
				loading: {},
				errorMessage: action.payload,
				data: null,
			};
		},
		clearErrorMessage(state: SubjectPage.State) {
			return {
				...state,
				errorMessage: null,
			};
		},
		setThermometerGradeDrop(state: SubjectPage.State, action?: PayloadAction<any>) {
			const { droppedGrade, groupTitle, originalGrade } = action?.payload;

			return {
				...state,
				thermoSlider: {
					droppedGrade,
					groupTitle,
					originalGrade,
				},
			};
		},
		resetReportControlHistory(state: SubjectPage.State) {
			// roll back history
			const updatedState: SubjectPage.State = state.reportControls.history.back.reduce((s) => {
				return subjectsSlice.caseReducers.stepReportHistoryBack(s);
			}, state);

			return {
				...updatedState,
				reportControls: {
					history: {
						// make sure we can't jump forward or back any more
						back: [],
						forward: [],
						hasChanged: false,
					},
				},
			};
		},
		setStudentViewGroup(
			state: SubjectPage.State,
			action: PayloadAction<{
				studentId: string;
				subjectId: string;
				adHocGroup: SubjectPage.AdHocGroup;
			}>
		) {
			const { studentId, adHocGroup } = action.payload;

			let subjectId = decodeURIComponent(action.payload.subjectId);

			return {
				...state,
				reportControls: {
					history: {
						back: [
							...state.reportControls.history.back,
							{
								studentId,
								subjectId,
								adHocGroup: state.data?.[subjectId]?.Students?.[studentId].AdHocGroup,
							},
						],
						forward: [],
					},
				},
				data: {
					[subjectId]: {
						...state.data?.[subjectId],
						Students: {
							...state.data?.[subjectId]?.Students,
							[studentId]: {
								...state.data?.[subjectId]?.Students?.[studentId],
								AdHocGroup: adHocGroup,
							},
						},
					},
				},
			};
		},
		setStudentWhatIfGrade(
			state: SubjectPage.State,
			action: PayloadAction<{ studentId: string; subjectId: string; grade: string }>
		) {
			const { studentId, grade } = action.payload;

			let subjectId = decodeURIComponent(action.payload.subjectId);

			return {
				...state,
				reportControls: {
					history: {
						back: [
							...state.reportControls.history.back,
							{
								studentId,
								subjectId,
								grade: state.data?.[subjectId]?.Students?.[studentId].WhatIfOutcome,
							},
						],
						forward: [],
					},
				},
				data: {
					[subjectId]: {
						...state.data?.[subjectId],
						Students: {
							...state.data?.[subjectId]?.Students,
							[studentId]: {
								...state.data?.[subjectId]?.Students?.[studentId],
								WhatIfOutcome: grade,
								WhatIfOutcomeDisplay: grade,
							},
						},
					},
				},
			};
		},
		stepReportHistoryBack(state: SubjectPage.State) {
			const copy = [...state.reportControls.history.back];

			const prev = copy.pop();

			if (!prev) {
				return state;
			}

			const { studentId, subjectId, grade, adHocGroup } = prev;

			return {
				...state,
				reportControls: {
					history: {
						back: copy,
						forward: [
							...state.reportControls.history.forward,
							{
								studentId,
								subjectId,
								...(grade
									? {
											grade: state.data?.[subjectId]?.Students?.[studentId].WhatIfOutcome,
									  }
									: {}),
								...(adHocGroup
									? {
											adHocGroup: state.data?.[subjectId]?.Students?.[studentId].AdHocGroup,
									  }
									: {}),
							},
						],
						hasChanged: true,
					},
				},
				data: {
					...state.data,
					[subjectId]: {
						...state.data?.[subjectId],
						Students: {
							...state.data?.[subjectId]?.Students,
							[studentId]: {
								...state.data?.[subjectId]?.Students?.[studentId],
								...(grade
									? {
											WhatIfOutcome: grade,
											WhatIfOutcomeDisplay: grade,
									  }
									: {}),
								...(adHocGroup
									? {
											AdHocGroup: adHocGroup,
									  }
									: {}),
							},
						},
					},
				},
			};
		},
		stepReportHistoryForward(state: SubjectPage.State) {
			const copy = [...state.reportControls.history.forward];

			const next = copy.pop();

			if (!next) {
				return state;
			}

			const { studentId, subjectId, grade, adHocGroup } = next;

			return {
				...state,
				reportControls: {
					history: {
						back: [
							...state.reportControls.history.back,
							{
								studentId,
								subjectId,
								...(grade
									? {
											grade: state.data?.[subjectId]?.Students?.[studentId]?.WhatIfOutcome,
									  }
									: {}),
								...(adHocGroup
									? {
											adHocGroup: state.data?.[subjectId]?.Students?.[studentId].AdHocGroup,
									  }
									: {}),
							},
						],
						forward: copy,
						hasChanged: true,
					},
				},
				data: {
					[subjectId]: {
						...state.data?.[subjectId],
						Students: {
							...state.data?.[subjectId]?.Students,
							[studentId]: {
								...state.data?.[subjectId]?.Students?.[studentId],
								...(grade
									? {
											WhatIfOutcome: grade,
											WhatIfOutcomeDisplay: grade,
									  }
									: {}),
								...(adHocGroup
									? {
											AdHocGroup: adHocGroup,
									  }
									: {}),
							},
						},
					},
				},
			};
		},
		clearLoading(state: SubjectPage.State, action: PayloadAction<Report.SubjectReport>) {
			return {
				...state,
				loading: { ...state.loading, [action.payload]: false },
				errorMessage: null,
			};
		},
	},
});

/**
 ** Export Reducers
 */
export default subjectsSlice.reducer;

/**
 ** Export Actions
 */
export const {
	setAdHoc,
	setWhatIf,
	setLoading,
	setReportId,
	setSavedSubjectReports,
	setAddSubjectReport,
	setLoadSavedReport,
	setSavedReportTitle,
	setDeleteSubjectReport,
	setUpdateSubjectReport,
	setSubjectData,
	clearSubjectData,
	setSubjectUdfData,
	setErrorMessage,
	clearErrorMessage,
	setCurrentSubjectId,
	setExportId,
	setCurrentSubjectUdf,
	setThermometerGradeDrop,
	setStudentWhatIfGrade,
	resetReportControlHistory,
	stepReportHistoryBack,
	stepReportHistoryForward,
	setStudentViewGroup,
	setStretchStudents,
	setAtRiskStudents,
	setSecureGradeChange,
	resetFineGradeGroups,
	clearLoading,
} = subjectsSlice.actions;
