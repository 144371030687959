import { translate } from '../../../utils/locale';

/**
 ** Connect Routes
 */
export const connectMonitoring: (
	params: { [key: string]: string },
	isLoading: boolean
) => PrimaryNavigation.Route[] = (params, isLoading) => {
	const isVisible = (p: string): boolean => params.monitoringPage !== p;

	return [
		{
			name: translate('primaryNavigation.connect.monitoring.DIFFERENCE') as string,
			path: '/difference',
			isDisabled: isLoading,
			isVisible: isVisible('difference'),
			type: 'monitoringPage',
		},
		{
			name: translate('primaryNavigation.connect.monitoring.VARIANCE') as string,
			path: '/variance',
			isDisabled: isLoading,
			isVisible: isVisible('variance'),
			type: 'monitoringPage',
		},
		{
			name: translate('primaryNavigation.connect.monitoring.EXAM_VS') as string,
			path: '/exam-vs-monitoring',
			isDisabled: isLoading,
			isVisible: isVisible('exam-vs-monitoring'),
			type: 'monitoringPage',
		},
	];
};
