import * as React from 'react';
import { FunctionComponent } from 'react';

import { IconButton, Flex } from 'basecamp';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

type Props = {
	onClick: (
		schoolId: number,
		groupAccessId: number | undefined,
		trendId: number | undefined,
		clientBm: boolean
	) => void;
	params: ICellRendererParams;
	icon: string;
	groupAccessId: number | undefined;
	trendId: number | undefined;
	clientBm: boolean;
};

const ViewAsSchoolRenderer: FunctionComponent<Props> = ({
	onClick,
	icon,
	groupAccessId,
	trendId,
	clientBm,
	params: {
		data: { id },
	},
}) => {
	//placeholder
	return (
		<Flex setAs="row" withAlign="center" height="100%">
			<IconButton
				withIcon={icon}
				setAs="accent"
				withSize="small"
				onClick={() => onClick(id, groupAccessId, trendId, clientBm)}
			/>
		</Flex>
	);
};

export default ViewAsSchoolRenderer;
