import { translate } from '../../../utils/locale';

const BASE_URL = '/manage-users';

export const manageUsers = (access: boolean | undefined): SidebarLinks[] => {
	return [
		{
			title: translate('manageUsers.sidebar.HEADING') as string,
			links: [
				{
					isVisible: true,
					withInternalUrl: `${BASE_URL}/all-users`,
					icon: 'users',
					link: translate('manageUsers.sidebar.links.ALL_USERS') as string,
				},
				{
					isVisible: true,
					withInternalUrl: `${BASE_URL}/add-user`,
					icon: 'user-plus',
					link: translate('manageUsers.sidebar.links.ADD_USER') as string,
				},
				{
					isVisible: true,
					withInternalUrl: `${BASE_URL}/bulk-add-users`,
					icon: 'users-medical',
					link: translate('manageUsers.sidebar.links.BULK_ADD_USERS') as string,
				},
				{
					isVisible: true,
					withInternalUrl: `${BASE_URL}/deactivated-user-accounts`,
					icon: 'users-slash',
					link: translate('manageUsers.sidebar.links.DISABLED_USERS') as string,
				},
			],
		},
	];
};
