import { setLoading, setErrorMessage, setStudentInformation } from '../slice';
import { getStudentInformation } from '../../../../api/reportAPI';
import subjectSort from '../../../../utils/subjectOrderStringSort';
import { getBedrockSubjectById } from 'features/subjectLookUp/selectors/bedrockLookUp';

export const fetchStudentInformation = (id: number): AppThunk => async (dispatch, getState) => {
	dispatch(setLoading());

	try {
		const state = getState();

		const response = await getStudentInformation(id);

		const data = response.reports[0];

		const subjects = data.SubjectTrend.map((s) => {
			return getBedrockSubjectById(s.id)(state);
		}).reduce((obj, subject) => {
			if (!subject) {
				return obj;
			}

			return {
				...obj,
				[subject.id]: subject,
			};
		}, {});

		const sortedData = {
			...data,
			SubjectTrend: data.SubjectTrend.sort((a, b) => {
				return subjectSort(
					subjects[a.SubjectName]?.order ?? 0,
					subjects[b.SubjectName]?.order ?? 0
				);
			}),
		};

		dispatch(setStudentInformation({ data: sortedData, id }));
	} catch (err) {
		dispatch(setErrorMessage(err.message));
	}
};
