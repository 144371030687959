import { getContractInfo } from '../../../../../api/groupsSetupAPI';
import { setSchoolContractsSuccess, setSetupErrorMessage, setSetupLoading } from '..';

export const fetchGroupSchoolContracts = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	try {
		const res = await getContractInfo();

		if (res.HasError) {
			dispatch(setSetupErrorMessage(res.Error[0].Message));
		}

		dispatch(setSchoolContractsSuccess(res.ResponseObject));
	} catch (err) {
		dispatch(setSetupErrorMessage(err));
	}
};
