import { CountryEnum, BenchmarkCountryEnum } from '../types/enum';

/**
 * Converts a country string value to Enum value
 * @param {string} country The country value as string
 * @returns {CountryEnum}
 */
export const ConvertCountryToEnum = (country: string): CountryEnum => {
	switch (country?.toLowerCase()) {
		case 'england':
		case 'united kingdom':
			return CountryEnum.England;
		case 'wales':
			return CountryEnum.Wales;
		case 'ireland':
			return CountryEnum.Ireland;
		case 'jersey':
			return CountryEnum.Jersey;
		default:
			return CountryEnum.International;
	}
};

/**Takes a benchmark file name and determines the country for it
 * @param {string} bmInput
 * @returns {string | null}
 */
export function benchmarkToCountry(bmInput?: string): string | null {
	if (!bmInput || !bmInput.toLowerCase) {
		return null;
	}

	// Extract country from bm name eg. Set-2020-Eng/Set-2020-Eng.xml -> Eng
	const benchmark = bmInput
		.toLowerCase()
		.replace('.xml', '')
		.split('-')
		.join(' ')
		.split('/')
		.join(' ')
		.split(' ');

	// Check which country benchmark includes
	if (benchmark.includes('eng')) {
		return BenchmarkCountryEnum.England;
	} else if (benchmark.includes('int')) {
		return BenchmarkCountryEnum.International;
	} else if (benchmark.includes('wal')) {
		return BenchmarkCountryEnum.Wales;
	} else if (benchmark.includes('nir')) {
		return BenchmarkCountryEnum.Northernireland;
	} else if (benchmark.includes('jer')) {
		return BenchmarkCountryEnum.Jersey;
	} else {
		return BenchmarkCountryEnum.England;
	}
}

/**
 * Converts a country Enum value to the country abbreviation
 * @param {CountryEnum} country The country enum value
 * @returns {'eng' | 'wal' | 'int' | 'nir'}
 */
export const ConvertEnumToCountryAbbreviation = (
	country: CountryEnum
): 'eng' | 'wal' | 'int' | 'nir' | 'jer' => {
	switch (country) {
		case CountryEnum.England:
			return 'eng';
		case CountryEnum.Wales:
			return 'wal';
		case CountryEnum.Ireland:
			return 'nir';
		case CountryEnum.Jersey:
			return 'jer';
		default:
			return 'int';
	}
};

/**
 * To be used with PDFReports only
 * Converts a country Enum value to the country abbreviation
 * @param {CountryEnum} country The country enum value
 * @returns {'eng' | 'wal' | 'int' | 'nir'}
 */
export const ConvertEnumToCountryAbbreviationWithJerseyOverride = (
	country: CountryEnum
): 'eng' | 'wal' | 'int' | 'nir' | 'jer' => {
	switch (country) {
		case CountryEnum.England:
			return 'eng';
		case CountryEnum.Wales:
			return 'wal';
		case CountryEnum.Ireland:
			return 'nir';
		case CountryEnum.Jersey:
			return 'eng';
		default:
			return 'int';
	}
};
