import * as React from 'react';
import { FunctionComponent } from 'react';

import { IconButton, Flex } from 'basecamp';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

type Props = {
	onClick: (client: any, username: string) => void;
	params: ICellRendererParams;
	icon: string;
	admin: AlpsAdmin.User;
	client: AlpsAdmin.Search[] | undefined;
};

const ImpersonateUserRenderer: FunctionComponent<Props> = ({ onClick, icon, admin, client }) => {
	return (
		<Flex setAs="row" withAlign="centerLeft" height="100%">
			<IconButton
				withIcon={icon}
				setAs="accent"
				withSize="small"
				onClick={() => onClick(client, admin.username)}
			/>
		</Flex>
	);
};

export default ImpersonateUserRenderer;
