import * as commonUtils from './common';
import * as rounding from './rounding';

/*
 Get an AlpercentileSet grade from a score for the given immutable percentileSet
 */
export function getGrade(percentileSet: { [key: string]: number }, score: number) {
	if (percentileSet === null) {
		return null;
	}

	if (
		!commonUtils.exists(percentileSet) ||
		!commonUtils.exists(score) ||
		!commonUtils.isNumeric(score)
	) {
		throw Error('PercentileSet.getGrade expects a numeric score');
	}

	// Because we print our bm literature to 2 d.p. it is important that we round a
	// score to 2 d.p. before grading it so that the result will match the result
	// that a user gets if they calculate the grade themselves
	const roundedScore = rounding.round(score);

	if (isReversed(percentileSet)) {
		return gradeSelectorAscending(percentileSet, roundedScore);
	} else {
		return gradeSelectorDescending(percentileSet, roundedScore);
	}
}

/*
 Returns true if the supplied percentileSet is reversed, false otherwise
 */
function isReversed(percentileSet: { [key: string]: number }) {
	return percentileSet.P100 < percentileSet.P0;
}

/*
 Grade selector for ascending percentile sets
 */
function gradeSelectorAscending(percentileSet: { [key: string]: number }, score: number) {
	if (score <= percentileSet.P100) {
		return 1;
	} else if (score <= percentileSet.P90) {
		return 2;
	} else if (score <= percentileSet.P75) {
		return 3;
	} else if (score <= percentileSet.P60) {
		return 4;
	} else if (score <= percentileSet.P40) {
		return 5;
	} else if (score <= percentileSet.P25) {
		return 6;
	} else if (score <= percentileSet.P10) {
		return 7;
	} else if (score <= percentileSet.P0) {
		return 8;
	} else {
		return 9;
	}
}

/*
 Grade selector for descending percentile sets
 */
function gradeSelectorDescending(percentileSet: { [key: string]: number }, score: number) {
	if (score >= percentileSet.P100) {
		return 1;
	} else if (score >= percentileSet.P90) {
		return 2;
	} else if (score >= percentileSet.P75) {
		return 3;
	} else if (score >= percentileSet.P60) {
		return 4;
	} else if (score >= percentileSet.P40) {
		return 5;
	} else if (score >= percentileSet.P25) {
		return 6;
	} else if (score >= percentileSet.P10) {
		return 7;
	} else if (score >= percentileSet.P0) {
		return 8;
	} else {
		return 9;
	}
}
