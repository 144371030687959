import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';

import { useAgGrid } from '../../agGrid/hooks';

import { AgGridReact } from '@ag-grid-community/react';
import { CellClassParams } from '@ag-grid-enterprise/all-modules';
import { AgGridReactProps } from '@ag-grid-community/react';
import { TrendlineRenderer } from '../../agGrid/cellRenderers';
import { createDefaultGridOptions } from '../../agGrid';

import { Modal, Box, Heading, Flex, Text } from 'basecamp';
import { StudentModalInformation, StudentModalFilterBar } from './components';

import { translate } from '../../utils/locale';

import { useDispatch, useSelector } from 'react-redux';
import { closeModal, getStudentPriorAch, getStudentInformation, getStudentRowData } from './redux/';

import { getAppliedGradepoints, getPrimaryGradepoint } from 'features/app/redux/context';
import normalizeKey from '../../utils/normalizeKey';

import {
	handleDiffCellStyles,
	excelDiffCellRules,
	handleNumberSorting,
	calculateGradeDiff,
	gradesToNumericValue,
	handleAvPaCellStyles,
	handleGpSorting,
	GradepointRenderer,
	excelComparisonCellRules,
	excelStyles,
} from '../../agGrid/utils';

type Props = {};

const StudentModal: FunctionComponent<Props> = () => {
	/**
	 * Handle dispatch
	 */
	const dispatch = useDispatch();

	/**
	 * Get applied gradepoints
	 */
	const appliedGradepoints: Context.Gradepoint[] = useSelector(getAppliedGradepoints);
	const primaryGradepoint: Context.Gradepoint = useSelector(getPrimaryGradepoint);
	const filterGradepoints = (appGP, pgp) => {
		return appGP.filter((gp) => normalizeKey(gp.name) !== normalizeKey(pgp.name));
	};

	/**
	 * Get Student Information
	 */
	const priorAch = useSelector(getStudentPriorAch);
	const studentInformation = useSelector(getStudentInformation);
	const rowData = useSelector(getStudentRowData);

	/**
	 * Handle state
	 */
	const [gradepointOptions, setGradepointOptions] = useState<StudentModal.Option[]>([]);
	const [selectedComparison, setSelectedComparison] = useState<StudentModal.Option>();
	const [comparisonGradepoints, setComparisonGradepoints] = useState(
		filterGradepoints(appliedGradepoints, primaryGradepoint)
	);

	/**
	 * Set the gradepoint options values
	 * All applied gradepoints minus the primary as we always compare to this
	 */

	useEffect(() => {
		setComparisonGradepoints(filterGradepoints(appliedGradepoints, primaryGradepoint));
	}, [appliedGradepoints, primaryGradepoint]);

	useEffect(() => {
		if (appliedGradepoints.length > 1) {
			// Set the comparison gp dropdown options
			setGradepointOptions(
				comparisonGradepoints.map((gp) => {
					return {
						label: gp.name,
						value: `gp${normalizeKey(gp.name)}`,
					};
				})
			);
			// Get and set the initial comparison gp
			const initalComparison = comparisonGradepoints[comparisonGradepoints.length - 1];
			setSelectedComparison({
				label: initalComparison && initalComparison.name,
				value: initalComparison && `gp${normalizeKey(initalComparison.name)}`,
			});
		}
	}, [appliedGradepoints]);

	/**
	 * Set the initial dropdown value
	 */
	useEffect(() => {
		if (!selectedComparison) {
			setSelectedComparison(gradepointOptions[gradepointOptions.length - 1]);
		}
	}, [gradepointOptions]);

	/**
	 ** Handle  Ag Grid Api
	 */
	const gridOptions = useAgGrid();

	/**
	 ** Handle  lifecycle updates
	 */
	// Resize columns on mount
	useEffect(() => {
		gridOptions.columnApi?.autoSizeColumn('subject', true);
	}, [gridOptions.api, gridOptions.columnApi]);

	/**
	 ** Define  column defs
	 */
	const columnDefs = [
		{
			headerName: translate('subjectPage.studentModal.colDefs.SUBJECT') as string,
			field: 'subject',
			suppressSizeToFit: true,
			pinned: true,
		},
		...priorAch.map((pa: StudentModal.PriorAchievement) => {
			return {
				headerName: `${pa.Type} - ${translate('subjectPage.studentModal.colDefs.PA')}`,
				field: `pa${pa.Type}`,
				cellStyle: (params: any) => handleAvPaCellStyles(params),
				cellClass: `pa${pa.Type}`,
				unSortIcon: true,
				minWidth: 230,
			};
		}),
		{
			headerName: translate('subjectPage.studentModal.colDefs.MEG') as string,
			field: 'meg',
			cellRenderer: 'gradepointRenderer',
			comparator: handleGpSorting,
			cellClass: 'Meg',
			unSortIcon: true,
			minWidth: 120,
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.PT') as string,
			field: 'pt',
			cellClass: 'PT',
			unSortIcon: true,
			cellRenderer: 'gradepointRenderer',
			comparator: handleGpSorting,
			minWidth: 220,
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.TREND_LINE') as string,
			field: 'trendline',
			cellClass: 'withCellRenderer',
			cellRenderer: 'TrendlineRenderer',
			width: 160,
			suppressSizeToFit: true,
			pinned: 'right',
		},
		...appliedGradepoints.map((gp) => {
			const gpkey = normalizeKey(gp.name);
			const pgp = normalizeKey(primaryGradepoint.name);
			const selected = selectedComparison?.label ? normalizeKey(selectedComparison?.label) : '';

			return {
				headerName: gpkey,
				field: `gp${gpkey}`,
				cellStyle: () => {
					/**
					 * We always want the primary gp column to be highlighted
					 * because we always compare to this column, unless we only have
					 * a single gradepoint
					 */
					return gpkey === pgp && appliedGradepoints.length > 1 && { background: '#f1f1f1' };
				},
				cellClass: (params: any) => {
					if (appliedGradepoints.length === 1) {
						return 'Comparison';
					}

					return excelComparisonCellRules({
						params: params,
						selected: selected,
						pgp,
					});
				},
				cellRenderer: 'gradepointRenderer',
				unSortIcon: true,
				pinned: 'right',
				comparator: handleGpSorting,
				minWidth: 200,
			};
		}),
		...comparisonGradepoints.map((gp, i) => {
			const gpkey = normalizeKey(gp.name);
			const pgp = normalizeKey(primaryGradepoint.name);

			return {
				headerName: translate('subjectPage.studentModal.colDefs.DIFF') as string,
				field: `diff${gpkey}`,
				pinned: 'right',
				unSortIcon: true,
				suppressColumnsToolPanel: true,
				hide: i < comparisonGradepoints.length - 1,
				suppressSizeToFit: true,
				valueGetter: (params: any) => {
					return calculateGradeDiff(
						params.data.outcomes,
						params.data[`gp${pgp}`],
						params.data[`gp${gpkey}`]
					);
				},
				cellClass: (params: any) => excelDiffCellRules(params),
				cellStyle: (params: any) => handleDiffCellStyles(params),
				comparator: handleNumberSorting,
				maxWidth: 160,
			};
		}),
		{
			field: 'outcomes',
			suppressColumnsToolPanel: true,
			hide: true,
		},
	];

	/**
	 ** Default grid options
	 */
	const defaultGridOptions: AgGridReactProps = createDefaultGridOptions({
		sideBar: false,
		domLayout: 'default',
		alwaysShowVerticalScroll: true,
		frameworkComponents: {
			TrendlineRenderer: (params: CellClassParams) => {
				const outcomes = [...params.data.outcomes].reverse();
				// Convert each grade to a numeric value to pass to the renderer
				const gradesToNumeric: number[] = appliedGradepoints.map((gp) => {
					const grade = params.data[`gp${normalizeKey(gp.name)}`];

					return grade ? gradesToNumericValue(outcomes, grade.value) + 1 : 0;
				});

				// Count how many entries we have
				const gradeCount = gradesToNumeric.filter((item) => item > 0).length;

				// Don't show the trendline if only 1 entry is not equal to 0, don't display the trendline or
				// if all values are zero or we have less than 2 gp's
				return gradeCount === 1 ||
					gradesToNumeric.every((grade: number) => grade === 0) ||
					gradesToNumeric.length < 2 ? (
					<Flex withAlign="centerLeft" height="100%" bg="status.error.1" pl="17px">
						<Text setAs="small" m={0}>
							{translate('subjectPage.trends.NO_TREND')}
						</Text>
					</Flex>
				) : (
					<TrendlineRenderer range={gradesToNumeric} />
				);
			},
			gradepointRenderer: (params: any) => GradepointRenderer(params),
		},
	});

	/**
	 ** Handle column style update when comparison gp changes
	 */
	useEffect(() => {
		if (
			comparisonGradepoints.length > 0 &&
			selectedComparison &&
			!!gridOptions.api &&
			!!gridOptions.columnApi
		) {
			/**
			 * Get all diff columns and filter
			 */
			const hideColumns = gradepointOptions
				.map((option: StudentModal.Option) => `diff${normalizeKey(option.label)}`)
				.filter((column: string) => {
					return column !== `diff${normalizeKey(selectedComparison.label)}`;
				});

			/**
			 * Set diff column visibility
			 */
			gridOptions.columnApi.setColumnsVisible(hideColumns, false);
			gridOptions.columnApi.setColumnsVisible(
				[`diff${normalizeKey(selectedComparison.label)}`],
				true
			);

			/**
			 * Get all comparison columns
			 */
			const comparisonColumns = comparisonGradepoints.map((gp) => `gp${normalizeKey(gp.name)}`);

			let comparisonColumn;

			/**
			 * Handle highlighting of comparison as user switches
			 */
			comparisonColumns.forEach((col) => {
				if (col === selectedComparison?.value) {
					comparisonColumn = gridOptions?.columnApi?.getColumn(selectedComparison?.value);
					//@ts-ignore
					if (comparisonColumn) {
						comparisonColumn.colDef.cellStyle = { background: '#f1f1f1' };
						//@ts-ignore - update the excel cell styles to reflect the UI
						comparisonColumn.colDef.cellClass = 'SelectedComparison';
					}
				} else {
					comparisonColumn = gridOptions?.columnApi?.getColumn(col);
					if (comparisonColumn) {
						//@ts-ignore
						comparisonColumn.colDef.cellStyle = { background: 'inherit' };
						//@ts-ignore - update the excel cell styles to reflect the UI
						comparisonColumn.colDef.cellClass = 'Comparison';
					}
				}
			});

			gridOptions?.api?.refreshCells({
				force: true,
				columns: comparisonColumns,
			});
		}
	}, [gridOptions.columnApi, gridOptions.api, selectedComparison, comparisonGradepoints]);

	/**
	 * Export to excel
	 */
	const handleExcelExport = (): void => {
		const { studentName } = studentInformation;

		return gridOptions.api?.exportDataAsExcel({
			fileName: `${studentName} - subject export`,
			sheetName: `${studentName} - subject export`,
			processCellCallback: (params: any) => {
				if (!params.value) {
					return '-';
				}

				const { colId } = params.column;
				const value = params.value;

				if (!value) {
					return '-';
				}

				/**
				 * Gradepoints, PT & MEG all use a custom renderer so it is
				 * necessary to pull out the display value for the excel
				 */
				if (colId.includes('gp') || colId === 'pt' || colId === 'meg') {
					return value.display;
				}

				return value.toString();
			},
		});
	};

	/**
	 ** Render Component
	 */

	return (
		<Modal setAs="large" close={() => dispatch(closeModal())}>
			{/* Student Name */}
			<Heading setAs="large" withColor="black">
				{studentInformation.studentName ?? ''}
			</Heading>

			{/* Student Info */}
			<StudentModalInformation studentInformation={studentInformation} />

			{/* Filter Bar */}
			<StudentModalFilterBar
				handleExcelExport={() => handleExcelExport()}
				gradepointOptions={gradepointOptions}
				selectedComparison={selectedComparison}
				setSelectedComparison={(option: StudentModal.Option) => setSelectedComparison(option)}
			/>

			{/* Student Subjects */}
			<Box className="ag-theme-alps" height="261px">
				<AgGridReact
					{...defaultGridOptions}
					onGridReady={(params) => gridOptions.onGridReady(params)}
					columnDefs={columnDefs}
					rowData={rowData}
					excelStyles={excelStyles}
				/>
			</Box>
		</Modal>
	);
};

export default StudentModal;
