import {
	getAvailableSubjectComparisons,
	getClientCountry,
	getPrimaryGradepoint,
} from '../../../app/redux/context';
import { ExamLevels, CountryEnum, PriorAchievementType } from '../../../../types/enum';
import { Report } from 'features/reports/types';
import {
	groupStudentsByViewGroups,
	sortComparisonStudentViewGroups,
} from '../../../../utils/studentGroup';
import { getComparisonsForSubjects } from 'features/analysisSettings/redux';
import { getFeature } from 'features/app/redux/features';

/**
 ** Get subjects grid data
 */
export const getSubjectsGridData = (state: RootState) => {
	if (!state.subjectPage.data || !state.subjectPage.data[state.subjectPage.currentSubjectId]) {
		return [];
	}

	const country = getClientCountry(state);

	const data = state.subjectPage.data[state.subjectPage.currentSubjectId];

	const isKS5 = data.ExamLevel !== ExamLevels.KS4;

	const pts = getPersonalisedTargets(state);

	const availableComparisons = getAvailableSubjectComparisons(state);

	const appliedComparisons = state.context.appliedComparisons;

	const customComparisons = getComparisonsForSubjects(state);

	const usePt = getFeature('trends_pt')(state);

	// go through the comparisions/filters that have been applied and find the matching item from the custom comparisons
	const customAppliedComparisons = appliedComparisons.filter(
		(appliedComparison: any) => customComparisons[appliedComparison.value]
	);
	const isCAT4 =
		data.PriorAchievmentType === PriorAchievementType.CAT4MeanSAS ||
		data.PriorAchievmentType === PriorAchievementType.CAT4MeanSAS_KS5;

	/**
	 * Get the udf type & value and format and format
	 */
	const udfType = state.subjectPage.currentSubjectUdfType;
	const udfValue = state.subjectPage.currentSubjectUdfValue;

	const studentViewGroups = groupStudentsByViewGroups(
		Object.values(data.Students),
		customAppliedComparisons
	);
	const studentsGroupedByComparisons = sortComparisonStudentViewGroups(
		availableComparisons,
		studentViewGroups,
		appliedComparisons,
		customComparisons
	);
	const outcomeSetWithFineGrades = getOutcomeSetForSubjectWithFineGrades(state);
	const studentsGridData = Object.entries(studentsGroupedByComparisons).reduce(
		(preVal, currVal) => {
			const label = currVal[0];
			const students = currVal[1];

			let filteredStudents = mapStudents(
				students,
				pts,
				isKS5,
				country,
				outcomeSetWithFineGrades,
				isCAT4,
				usePt
			);
			if (udfType && udfValue) {
				filteredStudents = filteredStudents.filter((data) => {
					const formatUdfType =
						udfType.charAt(0).toLowerCase() + udfType.slice(1).replace(/ /g, '');

					return (
						!!data[formatUdfType] && data[formatUdfType].toLowerCase() === udfValue.toLowerCase()
					);
				});
			}

			// return pre value first to retain the ordering of the groups
			return {
				...preVal,
				[label]: filteredStudents,
			};
		},
		{}
	);

	return studentsGridData;
};

export const getFineGrades = (state: RootState): SubjectPage.FineGrades => {
	// Get data for the selected page
	const data: SubjectPage.Data = state.subjectPage.data;
	const selectedSubject = data && data[state.subjectPage.currentSubjectId];

	return selectedSubject.FineGradeSettings?.setUp;
};

function getOutcomeSetForSubject(state: any) {
	// get all outcomes include fine grades for subject
	if (
		!state.subjectPage.data ||
		!state.subjectPage.data[state.subjectPage.currentSubjectId] ||
		!state.report.personalisedTargets
	) {
		return [];
	}

	const { Outcomes } = state.subjectPage.data[state.subjectPage.currentSubjectId];
	return Outcomes.map((outcome: SubjectPage.Outcome) => outcome.Outcome);
}

export const getOutcomeSetForSubjectWithFineGrades = (
	state: RootState
): StudentModal.AllOutcomesWithFineGrades[] => {
	const allOutcomes = getOutcomeSetForSubject(state);
	const fineGrades = getFineGrades(state);

	return allOutcomes
		.flatMap((outcome: SubjectPage.Outcome) => {
			if (fineGrades) {
				return [
					...fineGrades.stretch.map((fg: string) => ({
						...outcome,
						Display: `${outcome.Display}${fg}`,
					})),
					...fineGrades.secure.map((fg: string) => ({
						...outcome,
						Display: `${outcome.Display}${fg}`,
					})),
					outcome,
					...fineGrades.atRisk.map((fg: string) => ({
						...outcome,
						Display: `${outcome.Display}${fg}`,
					})),
				];
			}

			return outcome;
		})
		.map((outcome: SubjectPage.Outcome, i: number) => ({
			display: outcome.Display,
			value: outcome.Value,
			order: outcome.Points === 0 ? allOutcomes.length + i : i,
		}))
		.sort(
			(a: StudentModal.AllOutcomesWithFineGrades, b: StudentModal.AllOutcomesWithFineGrades) => {
				return a.order - b.order;
			}
		);
};

export function mapStudents(
	students: any,
	pts: any,
	isKS5: any,
	country: any,
	outcomeSetWithFineGrades: any,
	isCAT4: number,
	usePt: boolean = false
) {
	return students.map((student: any) => {
		const pt = pts?.filter((t) => t.StudentId === student.StudentId)[0]?.PersonalTarget;
		const ptValue = pts?.filter((t) => t.StudentId === student.StudentId)[0]?.PersonalTargetValue;
		const ptOrder = pts?.filter((t) => t.StudentId === student.StudentId)[0]?.Order;
		return Object.assign(
			{
				id: student.StudentId,
				name: student.Fullname,
				upn: student.Upn,
				gender: student.Gender,
				teachingSet: student.TeachingSet,
				pa: student.PriorAttainment['KS5']?.Score ?? student.PriorAttainment['KS4']?.Score,
				paType: student.PriorAttainment['KS5']?.Type ?? student.PriorAttainment['KS4']?.Type,
				paTypeDisplayName:
					student.PriorAttainment['KS5']?.PriorAchievementTypeDisplayName ??
					student.PriorAttainment['KS4']?.PriorAchievementTypeDisplayName,
				teacher: student.Teacher,
				meg: student.Target,
				megDetails: { display: student.Target, order: student.TargetPoints },
				pt: pt,
				ptValue: ptValue,
				ptDetails: { display: pt, order: ptOrder },
				eal: student.Eal,
				send: student.Send,
				learningBias: student.LearningBias,
				meanSAS: student.MeanSAS,
				verbalSAS: student.VerbalSAS,
				nonVerbalSAS: student.NonVerbalSAS,
				spatialSAS: student.SpatialSAS,
				quantitativeSAS: student.QuantitativeSAS,
				grade: student.Outcome.Display,
				gradeValue: student.Outcome.Value,
				disadvantaged: student.Disadvantaged,
				whatIfGrade: student.WhatIfOutcome,
				viewGroup: student.ViewGroup,
				adhocGroup: student.AdHocGroup,
				gcseScore: isCAT4
					? undefined
					: isKS5 && country === CountryEnum.Wales
					? student.GCSEScore
					: undefined,
			},
			...student.GradepointScores.map((gp: SubjectPage.GradepointScore, i: number) => {
				return {
					[gp.IsDefault ? 'primaryGp' : usePt ? `comparison${i + 2}` : `comparison${i + 1}`]: {
						value: gp.Value,
						display: gp.Display || '-',
						order: getOutcomeOrder(outcomeSetWithFineGrades, gp.Display)
							? getOutcomeOrder(outcomeSetWithFineGrades, gp.Display)
							: 0,
					},
				};
			})
		);
	});
}

function getOutcomeOrder(outcomes: any, outcome: any) {
	if (outcome !== undefined) {
		return outcomes.find((o: any) => {
			return o.display === outcome;
		})?.order;
	}
}

export const getPersonalisedTargets = (state: RootState) => {
	if (!state.subjectPage.data || !state.subjectPage.data[state.subjectPage.currentSubjectId]) {
		return [];
	}

	const year = getPrimaryGradepoint(state)?.year;

	return (
		(state.report.personalisedTargets.length > 0 &&
			state.report.personalisedTargets[0][year]?.filter(
				(tg) =>
					tg.Subject === state.subjectPage.currentSubjectId ||
					tg.CompactDisplay === state.subjectPage.currentSubjectId ||
					tg.DisplayName === state.subjectPage.currentSubjectId
			)) ||
		[]
	);
};

export const getPersonalisedTargetsForStudent = (id: string) => (state: RootState) => {
	if (
		!state.subjectPage.data ||
		!state.subjectPage.data[state.subjectPage.currentSubjectId] ||
		!state.report.personalisedTargets
	) {
		return [];
	}

	const pts = getPersonalisedTargets(state);

	return pts.find((pt: Report.PersonalisedTarget) => pt.StudentId === id);
};
