import fetch from './';

export const getSubscriptionPricing = (useOldPricing: boolean): any => {
	return fetch('/api/Subscriptions/Pricing?useOldPricing=' + useOldPricing).then((response) => {
		return {
			...response,
			ResponseObject: response.ResponseObject as ManageAccount.SubscriptionPrices,
		};
	});
};
