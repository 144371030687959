/**
 * Selectors for creating the row data for the main top level grids
 */
import { SubjectArea } from 'features/subjectArea/types';
import { SubjectAreaGridData } from 'features/subjectArea/types/common';
import { getAllComparisonData, getAllRowData, getAppliedPageComparisons } from './';

/**
 * Get the row data with comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows with comparisons rows
 */
export const getRowDataWithComparisons = (page: SubjectArea.AnalysisPage, rowIds: string[]) => (
	state: RootState
): (SubjectAreaGridData.GridRow | SubjectAreaGridData.ComparisonRow)[] | null => {
	const rows = getAllRowData(page)(state);
	const comparisons = getAllComparisonData(page)(state);

	return !!rows
		? rowIds.flatMap((id) => {
				const comparisonIds = rows.byId[id].comparisonIds;
				return [
					rows.byId[id],
					...comparisonIds.map((compId) => {
						return comparisons!.byId[compId];
					}),
				];
		  })
		: null;
};

/**
 * Get the row data without comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows
 */
export const getRowDataWithoutComparisons = (page: SubjectArea.AnalysisPage, rowIds: string[]) => (
	state: RootState
): SubjectAreaGridData.GridRow[] | null => {
	const rows = getAllRowData(page)(state);

	return !!rows ? rowIds.flatMap((id) => rows.byId[id]) : null;
};
/**
 * Creates the row data for the main grids
 * @param rowIds row ids to map over
 * @returns Formatted row data, with or without comparisons
 */
export const createGridData = (page: SubjectArea.AnalysisPage, rowIds: string[]) => (
	state: RootState
): (SubjectAreaGridData.GridRow | SubjectAreaGridData.ComparisonRow)[] | null => {
	const rows = getAllRowData(page)(state);
	const rowsWithComparisons = getRowDataWithComparisons(page, rowIds)(state);
	const rowsWithoutComparisons = getRowDataWithoutComparisons(page, rowIds)(state);
	const appliedComparisons = getAppliedPageComparisons(page)(state);
	const rowComparisonsIds = rows?.byId[rowIds[0]].comparisonIds;
	const hasComparisonsForRow =
		rowComparisonsIds &&
		rowComparisonsIds.some((id) => {
			const applied = appliedComparisons[0]
				? appliedComparisons[0].value.split('|').join(' - ')
				: '';
			return id.includes(applied);
		});
	// If applied comparisons
	if (!!appliedComparisons && appliedComparisons.length > 0) {
		// Check if first grid row has the comparison ids populated, else return null
		return !!hasComparisonsForRow ? rowsWithComparisons : null;
	}

	// Else if no applied comparisons just fetch the all row
	return rowsWithoutComparisons;
};
