import * as React from 'react';
import { FunctionComponent, useEffect, useContext } from 'react';
import { useAgGrid } from '../../../agGrid/hooks';
import { AgGridReact } from '@ag-grid-community/react';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { AgGridReactProps } from '@ag-grid-community/react';
import { SpoPillRenderer, SpoGradesAchieved } from '../../../agGrid/cellRenderers';
import { createDefaultGridOptions } from '../../../agGrid';
import { Box } from 'basecamp';
import { SPOContext } from '../spo';
import { translate } from '../../../utils/locale';
import { isLoading, getPriorAttainmentTypes } from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { Report } from 'features/reports/types';
import { fetchStudentInformation } from 'features/studentModal/redux';
import AgGridFixedHeightWrapper from '../../../agGrid/agGridFixedHeightWrapper';
import * as outcomeUtils from '../../../utils/outcomes';
import { useWindowSize } from '../../../utils/hooks';
import {
	adjustPriorAttainmentPrecision,
	getPriorAttainmentPrecision,
} from '../../../utils/priorAttainment';
import { getFeature } from 'features/app/redux/features';
import { spoStatusValues, calculateNumberOfStatusOnTarget } from '../../../utils/spoHelpers';

type Props = {
	isMultiGrid?: boolean;
	data: {
		students: SPO.Student[];
	};
	personalisedTargets: {
		[key: string]: Report.PersonalisedTarget[];
	};
};

const AgGrid: FunctionComponent<Props> = ({ isMultiGrid = false, data, personalisedTargets }) => {
	/**
	 ** Handle  Ag Grid Api
	 */
	const gridOptions = useAgGrid();
	const dispatch = useDispatch();

	/**
	 * Selectors
	 */
	const priorAttainmentTypes = useSelector(getPriorAttainmentTypes);
	const paDisplayFeature = useSelector(getFeature('pa_display_precision'));
	const showMegCount = useSelector(getFeature('spo_meg_count_columns'));
	const showStudentVas = useSelector(getFeature('student_vas'));

	/**
	 ** Context
	 * Allows get / set subject overview page state
	 */
	const { search, focusOn, showCAT4, setShowStatusToggle, togglePts } = useContext(SPOContext);

	//Loading state
	const loading = useSelector(isLoading);

	const handleStudentModal = (id: number) => {
		dispatch(fetchStudentInformation(id));
	};

	/**
	 ** Handle  lifecycle updates
	 */
	// Resize columns on mount
	useEffect(() => {
		gridOptions.columnApi?.autoSizeColumns(['name', 'upn'], true);
		// Get the max number of subjects
		const getMaxSubjectLength = data?.students?.reduce((acc, curr) => {
			if (Object.values(curr.Subjects).length > acc) {
				acc = Object.values(curr.Subjects).length;
			}

			return acc;
		}, 0);
		// Calculate the width of the column based on the number of subjects (pills)
		const getColumnWidth = getMaxSubjectLength >= 3 ? 785 : getMaxSubjectLength === 2 ? 527 : 268;
		// Set the column width
		gridOptions.columnApi?.setColumnWidth('displayName', getColumnWidth);
	}, [gridOptions.columnApi]);

	// Handle search
	useEffect(() => {
		gridOptions.api?.setQuickFilter(search);
	}, [search, data, gridOptions.api]);

	// Handle loading/no rows overlay
	useEffect(() => {
		if (loading) {
			gridOptions.api?.showLoadingOverlay();
		} else if (!(data && data.students && data.students.length > 0)) {
			gridOptions.api?.showNoRowsOverlay();
		} else {
			gridOptions.api?.hideOverlay();
		}
	}, [loading, data, gridOptions.api]);

	/**
	 ** Transform data into correct format for table
	 */
	const rowData = data?.students?.map((student) => {
		const name = `${student.Surname}, ${student.Forename}`;
		const pa = student.AvgPriorAch.map((x) => {
			const precision = getPriorAttainmentPrecision(x.PAType, priorAttainmentTypes);
			return paDisplayFeature ? adjustPriorAttainmentPrecision(x.Score, precision) : x.Score;
		}).join(', ');
		const paType = student.AvgPriorAch.map((x) => x.PAType).join(', ');
		const tutorGroup = student.TutorGroup;
		const id = student.ExternalId;
		const upn = student.UPN;
		const send = student.SEND;
		const eal = student.EAL;
		const learningBias = student.LearningBias;
		const meanSAS = student.MeanSAS;
		const quantitativeSAS = student.QuantitativeSAS;
		const verbalSAS = student.VerbalSAS;
		const nonVerbalSAS = student.NonVerbalSAS;
		const spatialSAS = student.SpatialSAS;
		const targets = (personalisedTargets ?? {})[student.ExternalId];
		const disadvantaged = student.Disadvantaged;
		const gender = student.Gender;
		const aLevelStudentVas = student.ALevelStudentVAS;
		const ks4StudentVas = student.Ks4StudentVAS;
		let subjects = Object.values(student.Subjects).map((x) => {
			return {
				subjectName: x.Subject.CompactName,
				displayName: x.Subject.DisplayName,
				status: x.Status,
				//TODO: Remove ternary when dot net 6 is dev
				ptStatus: x.PTStatus ? x.PTStatus : 'No Colour',
				meg: x.MEG,
				grade: x.Outcome.Display,
				pt: targets?.find((y) => y.Subject === x.Subject.Name)?.PersonalTarget,
			};
		});

		if (focusOn) {
			subjects.sort((a, b) => {
				if (b.subjectName === (focusOn.label as string)) {
					return 1;
				}
				if (a.subjectName === (focusOn.label as string)) {
					return -1;
				}

				return 0;
			});
		}

		return {
			name,
			pa,
			paType,
			id,
			tutorGroup,
			upn,
			eal,
			send,
			learningBias,
			meanSAS,
			verbalSAS,
			nonVerbalSAS,
			quantitativeSAS,
			spatialSAS,
			disadvantaged,
			gender,
			aLevelStudentVas,
			ks4StudentVas,
			subjects,
		};
	});

	useEffect(() => {
		const data = rowData?.flatMap((x) => x.subjects.map((y) => y.pt)) ?? [];
		const allUndefined = (element, index, array) => {
			return element === undefined;
		};

		if (data.every(allUndefined)) {
			setShowStatusToggle(false);
		} else {
			setShowStatusToggle(true);
		}
	});

	/**
	 * Handle window size
	 * Used for pinning / un-pinning columns on smaller screens
	 */
	const { width } = useWindowSize();
	const isPinned = width && width < 1400 ? null : 'left';

	/**
	 ** Define  column defs
	 */
	const columnDefs = [
		{
			field: 'id',
			hide: true,
		},
		{
			headerName: translate('spo.agGrid.headers.NAME') as string,
			field: 'name',
			onCellClicked: (params: any) => handleStudentModal(params.data.id),
			cellStyle: { cursor: 'pointer' },
			pinned: 'left',
		},
		{
			headerName: translate('spo.agGrid.headers.PA') as string,
			field: 'pa',
			tooltipField: 'paType',
			pinned: isPinned,
			minWidth: 200,
			comparator: outcomeUtils.handleNumberSorting,
		},
		{
			headerName: translate('spo.agGrid.headers.TUTOR_GROUP') as string,
			field: 'tutorGroup',
			minWidth: 160,
		},
		{
			headerName: translate('spo.agGrid.headers.SUBJECTS') as string,
			field: 'displayName',
			cellRenderer: 'SpoPillRenderer',
			cellClass: 'withCellRenderer',
			suppressSizeToFit: true,
			resizable: false,
			minWidth: 785,
			pinned: isPinned,
		},
		...(showStudentVas
			? [
					{
						headerName: (translate('spo.agGrid.headers.A_LEVEL_VAS') as string) as string,
						field: 'aLevelStudentVas',
						cellStyle: { textAlign: 'center' },
						pinned: isPinned,
						minWidth: 200,
					},
					{
						headerName: (translate('spo.agGrid.headers.KS4_LEVEL_VAS') as string) as string,
						field: 'ks4StudentVas',
						cellStyle: { textAlign: 'center' },
						pinned: isPinned,
						minWidth: 200,
					},
			  ]
			: []),
		...(showMegCount
			? [
					{
						headerName: togglePts
							? (translate('spo.agGrid.headers.toggledHeaders.pt.HIT') as string)
							: (translate('spo.agGrid.headers.toggledHeaders.meg.HIT') as string),
						field: 'totalAbove',
						minWidth: 165,
						cellRenderer: 'SpoGradesAchieved',
						cellClass: 'withCellRenderer',
						target: 'totalAbove',
					},
					{
						headerName: togglePts
							? (translate('spo.agGrid.headers.toggledHeaders.pt.ABOVE') as string)
							: (translate('spo.agGrid.headers.toggledHeaders.meg.ABOVE') as string),
						field: 'above',
						minWidth: 190,
						cellRenderer: 'SpoGradesAchieved',
						cellClass: 'withCellRenderer',
						target: 'above',
					},
					{
						headerName: togglePts
							? (translate('spo.agGrid.headers.toggledHeaders.pt.BELOW') as string)
							: (translate('spo.agGrid.headers.toggledHeaders.meg.BELOW') as string),
						field: 'oneBelow',
						minWidth: 190,
						cellRenderer: 'SpoGradesAchieved',
						cellClass: 'withCellRenderer',
						target: 'oneBelow',
					},
					{
						headerName: togglePts
							? (translate('spo.agGrid.headers.toggledHeaders.pt.WELL_BELOW') as string)
							: (translate('spo.agGrid.headers.toggledHeaders.meg.WELL_BELOW') as string),
						field: 'wellAbove',
						minWidth: 190,
						cellRenderer: 'SpoGradesAchieved',
						cellClass: 'withCellRenderer',
						target: 'wellAbove',
					},
			  ]
			: []),
		{
			headerName: translate('spo.agGrid.headers.UPN') as string,
			field: 'upn',
			hide: true,
		},
		{
			headerName: translate('spo.agGrid.headers.GENDER') as string,
			field: 'gender',
			minWidth: 120,
		},
		{
			headerName: translate('spo.agGrid.headers.DISADVANTAGED') as string,
			field: 'disadvantaged',
			minWidth: 180,
		},
		{
			headerName: translate('spo.agGrid.headers.EAL') as string,
			field: 'eal',
		},
		{
			headerName: translate('spo.agGrid.headers.SEND') as string,
			field: 'send',
		},
		...(showCAT4
			? [
					{
						headerName: translate('spo.agGrid.headers.LEARNING_BIAS') as string,
						field: 'learningBias',
						minWidth: 180,
					},
					{
						headerName: translate('spo.agGrid.headers.MEAN_SAS') as string,
						field: 'meanSAS',
						minWidth: 140,
						comparator: outcomeUtils.handleNumberSorting,
					},
					{
						headerName: translate('spo.agGrid.headers.VERBAL_SAS') as string,
						field: 'verbalSAS',
						minWidth: 140,
						comparator: outcomeUtils.handleNumberSorting,
					},
					{
						headerName: translate('spo.agGrid.headers.NON_VERBAL_SAS') as string,
						field: 'nonVerbalSAS',
						minWidth: 160,
						comparator: outcomeUtils.handleNumberSorting,
					},
					{
						headerName: translate('spo.agGrid.headers.SPATIAL_SAS') as string,
						field: 'spatialSAS',
						minWidth: 140,
						comparator: outcomeUtils.handleNumberSorting,
					},
					{
						headerName: translate('spo.agGrid.headers.QUANTITATIVE_SAS') as string,
						field: 'quantitativeSAS',
						minWidth: 180,
						comparator: outcomeUtils.handleNumberSorting,
					},
			  ]
			: []),
	];

	const defaultGridOptions: AgGridReactProps = createDefaultGridOptions({
		alwaysShowVerticalScroll: true,
		domLayout: 'normal',
		frameworkComponents: {
			SpoPillRenderer: (params: ICellRendererParams) => {
				return <SpoPillRenderer params={params} />;
			},
			SpoGradesAchieved: (params: ICellRendererParams) => {
				const target = params.colDef.target;
				return <SpoGradesAchieved params={params} target={target} />;
			},
		},
		getRowHeight: (params: ICellRendererParams) => {
			return params.data.subjects?.length > 3
				? Math.ceil(params.data.subjects.length / 3) * 78 + 8
				: 86;
		},
		onRowDataChanged: () => {
			gridOptions.api?.refreshCells({ force: true });
			gridOptions.api?.resetRowHeights();
		},
		tooltipShowDelay: 500,
	});

	//Add on statusCounts onto the rowdata for ag grids internal sort
	const mappedRowData = rowData.map((data) => {
		const megArr = data.subjects.map((x) => x.status);
		const ptArr = data.subjects.map((x) => x.ptStatus);
		return {
			...data,
			totalAbove: !togglePts
				? calculateNumberOfStatusOnTarget(spoStatusValues.totalAbove, megArr)
				: calculateNumberOfStatusOnTarget(spoStatusValues.totalAbove, ptArr),
			above: !togglePts
				? calculateNumberOfStatusOnTarget(spoStatusValues.above, megArr)
				: calculateNumberOfStatusOnTarget(spoStatusValues.above, ptArr),
			oneBelow: !togglePts
				? calculateNumberOfStatusOnTarget(spoStatusValues.oneBelow, megArr)
				: calculateNumberOfStatusOnTarget(spoStatusValues.oneBelow, ptArr),
			wellAbove: !togglePts
				? calculateNumberOfStatusOnTarget(spoStatusValues.wellAbove, megArr)
				: calculateNumberOfStatusOnTarget(spoStatusValues.wellAbove, ptArr),
		};
	});

	return (
		<Box className={isMultiGrid ? 'ag-theme-alps is-multi-grid' : 'ag-theme-alps'} width={1}>
			<AgGridFixedHeightWrapper>
				<AgGridReact
					{...defaultGridOptions}
					onGridReady={(params) => gridOptions.onGridReady(params)}
					columnDefs={columnDefs}
					rowData={mappedRowData}
				/>
			</AgGridFixedHeightWrapper>
		</Box>
	);
};

export default AgGrid;
