/**
 * Check if the current user is undertaking any kind of impersonation
 * @param {string} currentMode The current impersonation mode from the context
 */
export function isImpostor(currentMode: string): boolean {
	return currentMode !== 'None';
}

/**
 * Check if the current user is undertaking client impersonation
 * @param {string} currentMode The current impersonation mode from the context
 */
export function isClientImpostor(currentMode: string): boolean {
	return currentMode === 'Client';
}

/**
 * Check if the current user is undertaking full user impersonation
 * @param {string} currentMode The current impersonation mode from the context
 */
export function isFullImpostor(currentMode: string): boolean {
	return currentMode === 'Full';
}

/**
 * Check if the current user is undertaking MAT 'view as school' impersonation
 * @param {string} currentMode The current impersonation mode from the context
 */
export function isMATImpostor(currentMode: string): boolean {
	return currentMode === 'MAT';
}

/**
 * Add session storage variables for MAT View As impersonation
 * @param {string} groupAccessId MAT groupAccessId
 * @param {string} clientId id of client to View As
 * @param {string} trendId id of the currently applied tremd
 * @param {string} useClientBenchmark is the client benchmark toggle on, this derives from a bool
 */
export function startMATViewAs(
	groupAccessId: string,
	clientId: string,
	trendId: string,
	useClientBenchmark: string
): void {
	window.sessionStorage.setItem('groupAccessId', groupAccessId);
	window.sessionStorage.setItem('matImpersonateClientId', clientId);
	window.sessionStorage.setItem('trendId', trendId);
	window.sessionStorage.setItem('useClientBenchmark', useClientBenchmark);
}

/**
 * Get current session storage variables for MAT View As impersonation
 */
export function getMATViewAs(): { groupAccessId: string; matImpersonateClientId: string } {
	return {
		groupAccessId: window.sessionStorage.getItem('groupAccessId'),
		matImpersonateClientId: window.sessionStorage.getItem('matImpersonateClientId'),
	};
}

/**
 * Remove session storage variables for MAT View As impersonation
 */
export function endMATViewAs(): void {
	window.sessionStorage.removeItem('groupAccessId');
	window.sessionStorage.removeItem('matImpersonateClientId');
	window.sessionStorage.removeItem('useClientBenchmark');
	window.location.reload();
}
