import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlpsAdmin } from '../types';

/**
 * Initial State
 */
const initialState: AlpsAdmin.State = {
	loading: false,
	users: undefined,
	message: undefined,
	appliedClientId: null,
	adminClientDetails: null,
	adminClients: [],
	adminEntitlements: [],
	providers: undefined,
	config: null,
	datasets: [],
	enable: false,
	showReset: false,
	addSubscriptionError: false,
	adminSubscriptions: [],
	subscriptionSizes: [],
	contracts: [],
	activatingClient: false,
	success: false,
	error: false,
};

/**
 * AdminUsers Slice
 */

const AlpsAdminSlice = createSlice({
	name: 'admin users',
	initialState,
	reducers: {
		listUsersPending(state: AlpsAdmin.State) {
			state.loading = true;
		},
		listUsersSuccess(state: AlpsAdmin.State, action: PayloadAction<Array<AlpsAdmin.User>>) {
			state.users = action.payload;
			state.loading = false;
		},
		listFail(state: AlpsAdmin.State, action: PayloadAction<AlpsAdmin.Message>) {
			state.loading = false;
			state.message = action.payload;
		},
		editUserSucess(state: AlpsAdmin.State, action: PayloadAction<AlpsAdmin.User>) {
			const index = state.users?.findIndex((users) => users.id === action.payload.id) ?? -1;

			if (index >= 0 && state.users) {
				let userArr = [...state.users];

				let singleUser = {
					...userArr[index],
					...action.payload,
				};
				userArr[index] = singleUser;
				state.users = userArr;
			}
		},
		listEditUserSuccess(state: AlpsAdmin.State, action: PayloadAction<AlpsAdmin.User>) {
			state.loading = false;
			state.users = [action.payload];
		},
		setAdminSearch: (state: AlpsAdmin.State, action: PayloadAction<Array<AlpsAdmin.Search>>) => {
			state.adminClients = action.payload;
		},
		setClientId: (state: AlpsAdmin.State, action: PayloadAction<number | null>) => {
			state.appliedClientId = action.payload;
		},
		setAdminClientDetails: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.ClientDetails>
		) => {
			state.loading = false;
			state.adminClientDetails = action.payload;
		},
		setAdminEntitlements: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.ConnectEntitlements[]>
		) => {
			state.loading = false;
			state.adminEntitlements = action.payload;
		},
		setEditAdminEntitlements: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.ConnectEntitlements[]>
		) => {
			state.loading = false;
			state.adminEntitlements = action.payload;
		},
		setAddAdminEntitlements: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.ConnectEntitlements>
		) => {
			state.loading = false;
			state.adminEntitlements = [...state.adminEntitlements, action.payload];
		},
		setConfigAdminEntitlements: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.Configuration>
		) => {
			state.loading = false;
			state.config = action.payload;
		},
		setDatasetsAdminEntitlements: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.DataSets[]>
		) => {
			state.loading = false;
			state.datasets = action.payload;
		},
		setUpdateConfigAdminEntitlements: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.Configuration>
		) => {
			state.loading = false;
			state.config = action.payload;
		},
		isLoading(state: AlpsAdmin.State) {
			state.loading = true;
		},
		activatingClient(state: AlpsAdmin.State) {
			state.activatingClient = true;
		},
		browseSucess(state: AlpsAdmin.State, action: PayloadAction<AlpsAdmin.Providers>) {
			state.loading = false;
			state.providers = action.payload;
		},
		setSubscriptionSizes: (state: AlpsAdmin.State, action: any) => {
			state.loading = false;
			state.subscriptionSizes = action.payload;
		},
		setSubscriptionsForClient: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.Subscription[]>
		) => {
			state.loading = false;
			state.adminSubscriptions = action.payload;
		},
		setAddSubscription: (state: AlpsAdmin.State, action: PayloadAction<AlpsAdmin.Subscription>) => {
			state.loading = false;
			state.adminSubscriptions = [...state.adminSubscriptions, action.payload];
		},
		setAddSubscriptionFailed: (state: AlpsAdmin.State) => {
			state.loading = false;
			state.addSubscriptionError = true;
		},
		setResetAddSubscriptionError: (state: AlpsAdmin.State) => {
			state.addSubscriptionError = false;
		},
		setContractsForClient: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.ContractsHistory>
		) => {
			state.loading = false;
			state.contracts = action.payload;
		},
		setActivationMessage: (state: AlpsAdmin.State, action: PayloadAction<boolean>) => {
			if (state.adminClientDetails !== null) {
				state.activatingClient = false;
				state.adminClientDetails = {
					...state.adminClientDetails,
					activateCodeSuccess: action.payload.ResponseObject,
					showActivationMessage: action.payload,
				};
			}
		},
		setAcademicYearsWithYearGroups: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.PriorAchievementInformation>
		) => {
			state.loading = false;
			state.paTypeConfig = action.payload;
		},

		setPriorAttainmentTypes: (
			state: AlpsAdmin.State,
			action: PayloadAction<AlpsAdmin.PriorAttainmentType>
		) => {
			state.loading = false;
			state.priorAttainmentType = action.payload;
		},

		setPriorAttainmentSuccess: (state: AlpsAdmin.State, action: PayloadAction<boolean>) => {
			state.success = action.payload;
		},

		setPriorAttainmentError: (state: AlpsAdmin.State, action: PayloadAction<boolean>) => {
			state.error = action.payload;
		},
	},
});

/**
 * Export Actions
 */

export const {
	listUsersPending,
	listUsersSuccess,
	listFail,
	listEditUserSuccess,
	editUserSucess,
	setClientId,
	setAdminSearch,
	setAdminClientDetails,
	setAdminEntitlements,
	setEditAdminEntitlements,
	setAddAdminEntitlements,
	isLoading,
	browseSucess,
	setUpdateConfigAdminEntitlements,
	setConfigAdminEntitlements,
	setDatasetsAdminEntitlements,
	setSubscriptionSizes,
	setSubscriptionsForClient,
	setAddSubscription,
	setAddSubscriptionFailed,
	setResetAddSubscriptionError,
	setContractsForClient,
	setActivationMessage,
	activatingClient,
	setAcademicYearsWithYearGroups,
	setPriorAttainmentTypes,
	setPriorAttainmentSuccess,
	setPriorAttainmentError,
} = AlpsAdminSlice.actions;

export default AlpsAdminSlice.reducer;
