/**
 * Filters selectors
 */

import { SubjectArea } from 'features/subjectArea/types';

/**
 * Gets the default report level selected filters
 * @param state Redux state
 * @returns Array of selected filters
 */
export const getSelectedDefaultFilters = (state: RootState): SubjectArea.FilterOption => {
	return state.subjectArea.selectedDefaultFilters;
};
/**
 * Gets the individual page level selected filters
 * @param state Redux state
 * @returns Array of selected filters
 * Once the default report level filters
 */
export const getSelectedPageFilters = (page: SubjectArea.AnalysisPage) => (
	state: RootState
): SubjectArea.FilterOption => {
	const selectedPageFilters = state.subjectArea.selectedPageFilters;

	return selectedPageFilters[page] ?? [];
};
/**
 * Gets the individual page level applied filters
 * @param state Redux state
 * @returns Array of applied filters
 */
export const getAppliedPageFilters = (page: SubjectArea.AnalysisPage) => (
	state: RootState
): SubjectArea.FilterOption => {
	const appliedPageFilters = state.subjectArea.appliedPageFilters;

	return appliedPageFilters[page] ?? [];
};
