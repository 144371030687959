import fetch from './';
/**
 * Reset the users password
 * On success get the email address the email was sent to
 * On failure, get any response builder errors
 * @param {string} emailAddress
 */
export const resetPassword = (
	emailAddress: string
): Promise<ResponseBuilder<Auth.ForgottenPasswordResponse>> => {
	return fetch(
		'/api/Auth/ResetPassword',
		{
			body: {
				EmailAddress: emailAddress,
			},
		},
		true
	);
};

export const resetPasswordConfirm = (
	password: string,
	confirm: string,
	token: string
): Promise<ResponseBuilder<Auth.ForgottenPasswordConfirmResponse>> => {
	return fetch(
		'/api/Auth/ResetPassword/Confirm',
		{
			body: {
				NewPassword: password,
				ConfirmPassword: confirm,
				Token: token,
			},
		},
		true
	);
};

export const createPassword = (
	password: string,
	confirm: string,
	token: string
): Promise<ResponseBuilder<Auth.ForgottenPasswordConfirmResponse>> => {
	return fetch(
		'/api/Auth/CreateAccount/CreatePassword',
		{
			body: {
				NewPassword: password,
				ConfirmPassword: confirm,
				Token: token,
			},
		},
		true
	);
};

/**
 * Activate the client
 * On succeess get the client/organisation details
 * On failure, get any response builder errors
 * @param {string} activationCode
 * @param {string} postcode
 */
export const activateClient = (
	activationCode: string,
	postcode: string
): Promise<ResponseBuilder<Auth.ActivationResponse>> => {
	return fetch(
		'/api/Auth/Activate',
		{
			body: {
				activationCode: activationCode,
				PostCode: postcode,
			},
		},
		true
	);
};

/**
 * Attempts to create an account.
 * If successful an email will be sent to the user with instructions, otherwise an error message will be returned
 * @param {Auth.CreateAccount} createAccount
 */
export const createAccount = (
	createAccount: Auth.CreateAccount
): Promise<ResponseBuilder<string>> => {
	return fetch(
		'/api/Auth/CreateAccount',
		{
			body: {
				ActivationCode: createAccount.activationCode,
				PostCode: createAccount.postCode,
				EmailAddress: createAccount.emailAddress,
				ConfirmEmailAddress: createAccount.confirmEmailAddress,
				JobTitle: createAccount.jobTitle,
				FullName: createAccount.fullName,
				Organisation: createAccount.organisation,
				ConfirmPassword: createAccount.confirmPassword,
				Password: createAccount.password,
				TermsAccepted: createAccount.termsAccepted,
				MailingListSoftwareUpdates: createAccount.mailingListSoftwareUpdates,
				MailingListGeneral: createAccount.mailingListGeneral,
			},
		},
		true
	);
};

/**
 * Attemps to confirm the account
 * @param {Auth.ConfirmAccount} confirmAccount
 */
export const confirmAccount = (
	confirmAccount: Auth.ConfirmAccount
): Promise<ResponseBuilder<string>> => {
	return fetch(
		'/api/Auth/CreateAccount/ConfirmAccount',
		{
			body: {
				ActivationCode: confirmAccount.activationCode,
				PostCode: confirmAccount.postCode,
				Token: confirmAccount.token,
			},
		},
		true
	);
};

/**
 * Send a confirmation email
 * @param emailAddress The email address to send the confirmation
 */
export const resendConfirmationEmail = (emailAddress: string): Promise<ResponseBuilder<string>> => {
	return fetch(
		'/api/Settings/Users/ResendPasswordEmail',
		{
			body: {
				EmailAddress: emailAddress,
			},
		},
		true
	);
};

/**
 * Change Password
 */
export const changePassword = (
	change: ManageAccount.Change
): Promise<ResponseBuilder<ManageAccount.Change>> => {
	return fetch(
		'/api/Auth/MyAccount/ChangePassword',
		{
			body: {
				ConfirmNewPassword: change.confirmNewPassword,
				CurrentPassword: change.currentPassword,
				NewPassword: change.newPassword,
			},
		},
		true
	);
};

/**
 * Send a extend trial request
 * @param emailAddress The email address to send the confirmation
 */
export const sendExtendTrial = (
	emailAddress: string
): Promise<ResponseBuilder<Auth.TrialExtendResponse>> => {
	return fetch(
		'/api/Settings/Users/ExtendTrial',
		{
			body: {
				EmailAddress: emailAddress,
			},
		},
		true
	);
};
