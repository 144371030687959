/**
 * Comparison selectors
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { getSlice } from './';
import { appliedAndSelectedAreEqual } from 'features/strategicAnalysis/utils';

/**
 * Gets the default report level selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedDefaultComparisons = (state: RootState) => {
	return state.strategicAnalysis.selectedDefaultComparisons;
};

/**
 * Gets all the loaded comparison data
 * @param state redux state
 * @returns An object byId / allIds for the loaded comparison data
 */
export const getAllComparisonData = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicGridData.Comparisons<StrategicGridData.ComparisonRow> | undefined => {
	const slice = getSlice(page)(state);

	return slice.comparisons;
};

/**
 * Gets the individual page level applied comparisons
 * @param state Redux state
 * @returns Array of applied comparisons
 */
export const getAppliedPageComparisons = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.ComparisonOption => {
	const appliedPageComparisons = state.strategicAnalysis.appliedPageComparisons;

	return appliedPageComparisons[page] ?? [];
};

/**
 * Gets and array of all the loaded comparison ids
 * @param state redux state
 * @returns An array of all the loaded comparison ids
 */
export const getAllComparisonIds = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): string[] => {
	const data = getAllComparisonData(page)(state);
	return data ? data.allIds : [];
};
/**
 * Get the row ids for the loaded comparisons, arse way round of checking as the comparison id is currently a guid and has no meaning
 * @param state redux state
 * @returns An array of row ids thats correspond to the loaded comparisons. Shows us where the comparison belongs in the grid row order.
 */
export const getRowIdsForLoadedComparisons = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): string[] => {
	const comparisons = getAllComparisonData(page)(state);
	const ids = comparisons && comparisons.allIds;
	const rowIds = comparisons && ids!.map((id) => comparisons!.byId[id].rowId);

	return rowIds ? rowIds : [];
};

/**
 * Gets and array of all the loaded comparison ids for a given page
 * @param state redux state
 * @returns An array of all the loaded comparison ids
 */
export const getAllComparisonIdsForActivePage = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): string[] => {
	const slice = getSlice(page)(state);
	const data = slice.comparisons;

	return data ? data.allIds : [];
};

/**
 * Gets the individual page level selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedPageComparisons = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.ComparisonOption => {
	const selectedPageComparisons = state.strategicAnalysis.selectedPageComparisons;

	return selectedPageComparisons[page] ?? [];
};

/**
 * Check if we have comparisons applied
 * @param page Analysis page
 * @returns boolean
 */
export const hasAppliedPageComparisons = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): boolean => {
	const appliedPageComparisons = getAppliedPageComparisons(page)(state);

	return appliedPageComparisons.length > 0;
};

/**
 * Determine if the comparisons have changed
 * @param page strategic analysis page
 * @returns boolean
 */
export const checkComparisonStatesAreEqual = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): boolean => {
	const appliedComparisons = getAppliedPageComparisons(page)(state);
	const selectedComparisons = getSelectedPageComparisons(page)(state);

	return appliedAndSelectedAreEqual<{ label: string; value: string }>(
		appliedComparisons,
		selectedComparisons
	);
};

/**
 * Get the available comparisons for the strategic analysis pdf reports
 * @param state Root state
 * @returns a filtered array of comparisons formatted
 */
export const getStrategicPdfReportComparisons = (state: RootState) => {
	return state.context.availableComparisons
		.filter((comparison) => !!comparison.allowInSubjectAnalysis)
		.map(({ name, values }) => ({
			label: name,
			options: values.map(({ value, type }) => ({
				name: name,
				type,
				label: value,
				value,
			})),
		}));
};
