/**
 * Thermometer selectors
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { getAppliedPageComparisons, getSlice } from './';
import { GetKeyValueFromComparisonOption } from 'features/strategicAnalysis/utils';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';

/**
 * Gets the state to display the overview thermometer
 * @param state Redux state
 * @returns boolean
 */
export const showAnalysisThermometer = (state: RootState) => {
	return state.strategicAnalysis.thermometerDisplayState;
};

/* Show Thermometer Toggle
 * @param Analysis page currently on
 * @param state Redux state
 * @returns Boolean
 * Simple flag used to determine if a thermometer toggle should display
 */
export const getShowThermometerToggle = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
) => {
	const slice = getSlice(page)(state);
	return !!slice.showThermometerToggle;
};

/**
 * Gets the quality indicator thermometer data which includes main and comparison data if set
 * @param page the active analysis page
 * @param state Redux state
 * @returns StrategicAnalysisQualityIndicator.ThermometerDTO
 */
export const getThermometerData = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysisQualityIndicator.ThermometerDTO | undefined => {
	const slice = getSlice(page)(state);

	return slice.thermometerData;
};

/**
 * Get a unique key that combines the main thermometer data id and the comparison thermometer
 * @param page the active analysis page
 * @param state Redux state
 * data id to see if the data has changed for cache purposes
 * @returns String
 */
export const getThermometerMemoisedId = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): string => {
	const thermometerData = getThermometerData(page)(state);

	return `${thermometerData?.mainData?.id}-${thermometerData?.comparisonData?.id}`;
};

/**
 * Get the thermometer data for quality indicator
 * @param state Redux state
 * @returns StrategicAnalysisQualityIndicator.Thermometer
 */
export const getMainThermometerDataByExamLevel = (
	page: StrategicAnalysis.AnalysisPage,
	examLevel: StrategicAnalysis.ExamLevels
) => (state: RootState): StrategicAnalysisQualityIndicator.Thermometer | undefined => {
	const slice = getSlice(page)(state);
	const thermometerData = slice.thermometerData;

	return thermometerData?.mainData?.byId[examLevel];
};

/**
 * Attempt to get any comparison thermometer data based on the selected page and exam level.
 * @param examLevel The selected/applied exam level
 * @param page The active page the user has selected
 * @returns A label and the thermometer data will be returned
 */
export const getComparisonThermometerDataByExamLevel = (
	page: StrategicAnalysis.AnalysisPage,
	examLevel: StrategicGridData.ExamLevels
) => (
	state: RootState
): { label: string; thermometer: StrategicAnalysisQualityIndicator.Thermometer }[] | undefined => {
	const thermometerData = state[`strategicAnalysis${page}`].thermometerData;

	// No need to do any more if we do not have comparison thermometer data
	if (!thermometerData || !thermometerData.comparisonData) return;

	const appliedComparisons = getAppliedPageComparisons(page)(state);
	const formattedComparisons = GetKeyValueFromComparisonOption(appliedComparisons);

	// Now we have the applied comparisons lets try get the thermometer data for them
	return Object.entries(thermometerData?.comparisonData?.byId)
		.filter(([key]) => formattedComparisons.some((x) => `${examLevel}-${x.Value}-${x.Key}` === key))
		.map((x) => {
			return {
				label: x[0].split('-').slice(1).join(' '),
				thermometer: x[1] as StrategicAnalysisQualityIndicator.Thermometer,
			};
		});
};
