/**
 * Fetch state of the subject area Count or percent toggle
 * @param state
 * @returns
 */
export const fetchToggleState = (state: RootState): boolean => {
	return state.subjectArea.showCount;
};

/**
 * Fetch Applied Subject id for subject area Subject Area
 * @param state
 * @returns
 */
export const fetchSubjectAreaSubjectId = (state: RootState): number | undefined => {
	return state.subjectArea.currentSubjectId;
};

/**
 * Fetch Applied examlevel for subject area Subject Area
 * @param state
 * @returns
 */
export const fetchSubjectAreaActiveExamLevel = (state: RootState): number | undefined => {
	return state.subjectArea.activeExamlevel;
};
