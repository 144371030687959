import * as React from 'react';
import { FunctionComponent } from 'react';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { Flex, Link } from 'basecamp';
import { targetProximity } from '../../utils/agGridUtils/subjectPillColours';

type Props = {
	params: ICellRendererParams;
};

const SubjectPillRenderer: FunctionComponent<Props> = ({ params }) => {
	/**
	 ** Render Component
	 */
	return (
		// Overall container
		<Flex setAs="flex" flexWrap="wrap" pt={2} pl={2}>
			{params.data.subjects?.spoValue.map((subject: any, i: number) => {
				const status = targetProximity(subject.status);
				return (
					<Flex
						setAs="column"
						flex="0 0 250px"
						mb={2}
						mr={2}
						bg={status.bg}
						borderRadius={3}
						overflow="hidden"
						key={i}
						height="70px"
					>
						{/* Link to subject */}
						<Link
							setAs="wrapping"
							marginTop={0}
							withInternalUrl={`/subjects/${subject.subjectName}/`}
							height="100%"
						>
							{/* Targets / Grade */}
							<Flex withAlign="distribute" bg="UIAlpha.white.3" height="calc(50% - 2px)">
								{/* Minimum Expected Grade */}
								<Flex color={status.color} withAlign="center" height="100%" flex={1}>
									MEG - {subject.meg}
								</Flex>
								{/* Personalised Target */}
								<Flex color={status.color} withAlign="center" height="100%" flex={1}>
									{subject.personalisedTarget && (
										<React.Fragment>PT - {subject.personalisedTarget}</React.Fragment>
									)}
								</Flex>
								{/* Grade */}
								<Flex color={status.color} height="100%" flex={1} withAlign="center">
									Grade - {subject.grade}
								</Flex>
							</Flex>
							{/* Subject Name */}
							<Flex withAlign="center" height="calc(50% - 2px)" color={status.color}>
								{subject.subjectName}
							</Flex>
						</Link>
					</Flex>
				);
			})}
		</Flex>
	);
};

export default SubjectPillRenderer;
