import fetch from './';
import { store } from '../store/store';
import {
	getDataSetItem,
	getYetiToken,
	getGroupAccessId,
	getUseClientBenchmark,
} from 'features/app/redux/context';
import { getGroupSelectedTrendId } from 'features/groups/analysis/redux';
import { fetchReport } from './reportAPI';
import { withGroupAccessOptions } from '../utils/apiHelpers';

/**
 * Get the Group Data
 */
export const getGroupData = () => {
	const state = store.getState();
	const token = getYetiToken(state);
	const dataItem = getDataSetItem(state);
	const groupTrendId = getGroupSelectedTrendId(state);
	const groupAccessId = getGroupAccessId(state);
	const useClientBenchmark = getUseClientBenchmark(state);

	return fetch(
		'/rpt/groupreq/frame',
		{
			credentials: 'same-origin',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token,
				dataItem,
			},
			body: {
				versionId: null,
				useClientBenchmark: useClientBenchmark,
				groupTrendId,
				groupAccessId,
				dataItemId: 0,
			},
			showErrorResponse: true,
		},
		true
	);
};

/**
 * Get the trends from Groups/Trends
 */
export const getTrends = withGroupAccessOptions(
	(options: any = {}): Promise<any> => {
		return fetch('/api/Groups/Trends', options, true);
	}
);

/**
 * Get the schools from the Group/Schools
 */
export const getSchools = (options: any = {}): Promise<any> => {
	return fetch('/api/Groups/SchoolGroup/Schools', options);
};

/**
 * Get School Contract info
 */
export const getContractInfo = (options: any = {}): Promise<any> => {
	return fetch('/api/Groups/SchoolContract/Schools', options);
};

/**
 * Get a list of custom comparisons
 */
export const getCustomComparisons = (
	options: any = {}
): Promise<ResponseBuilder<Array<Groups.Setup.ComparisonGroup>>> =>
	fetch('/api/Groups/Comparisons', options, true);

/**
 * Get the Group Qualification Types
 */
export const getQualifcationTypes = (type: any) => {
	const state = store.getState();
	const token = getYetiToken(state);
	const dataItem = getDataSetItem(state);

	const url =
		type === 'Strategic'
			? '/rpt/ExamLevel?filterResults=true'
			: 'BBPA'
			? '/rpt/ExamLevel?filterResults=true'
			: '/rpt/ExamLevel';

	return fetch(url, {
		credentials: 'same-origin',
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			token,
			dataItem: dataItem,
		},
		showErrorResponse: true,
	});
};

type UDFKeyValue = { key: string; value: string };
type RequestedUDF = Array<UDFKeyValue>;

const getGPObjectFromGPKey = (gradePointKey: string) => {
	return {
		key: gradePointKey,
		year: Number(gradePointKey.split(',')[0]),
		name: gradePointKey.split(',')[2],
		type: Number(gradePointKey.split(',')[1]) === 2 ? 'InYear' : 'EndOfYear',
		order: null,
	};
};

/**
 * Get the subject page data
 * TODO - After some investigation it appears this is not needed immediately but will be when we hook up the thermometer
 */
export const getSubjectPage = (subjectName: string, requestedUDF: RequestedUDF): Promise<any> => {
	const state: any = store.getState();

	const options: ReportAPIOptions = {
		gradepoints: state.groupSetup.trends
			.filter((x: Groups.Setup.ConnectAPITrend) => x.id === state.groupAnalysis.selectedTrendId)
			.map((x: Groups.Setup.ConnectAPITrend) =>
				x.gradepoints.map((y) => getGPObjectFromGPKey(y.key))
			)
			.flat(),
	};

	return fetchReport(['Interactive.SubjectPage', 'Interactive.SubjectSummary'], 'Subject', {
		...options,
		parameters: [
			...requestedUDF.map((udf: UDFKeyValue, _, arr: RequestedUDF) => {
				if (!arr.length) {
					return;
				}

				return {
					key: udf.key,
					value: udf.value,
				};
			}),
			{
				key: 'reqSubjectPageName',
				value: subjectName,
			},
		],
	});
};

/**
 * Get the Band Data
 */
export const getBandData = (examLevel: number) => {
	const state = store.getState();
	const token = getYetiToken(state);
	const dataItem = getDataSetItem(state);
	const groupTrendId = getGroupSelectedTrendId(state);
	const groupAccessId = getGroupAccessId(state);
	const useClientBenchmark = getUseClientBenchmark(state);

	return fetch(
		'/rpt/band/frame',
		{
			credentials: 'same-origin',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token,
				dataItem,
			},
			body: {
				versionId: null,
				useClientBenchmark: useClientBenchmark,
				groupTrendId,
				groupAccessId,
				dataItemId: 0,
				examLevel,
			},
			showErrorResponse: true,
		},
		true
	);
};

/**
 * Get the Band Data
 */
export const getStrategicData = (examLevel: number) => {
	const state = store.getState();
	const token = getYetiToken(state);
	const dataItem = getDataSetItem(state);
	const groupTrendId = getGroupSelectedTrendId(state);
	const groupAccessId = getGroupAccessId(state);
	const useClientBenchmark = getUseClientBenchmark(state);

	return fetch(
		'/rpt/strategic/frame',
		{
			credentials: 'same-origin',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				token,
				dataItem,
			},
			body: {
				versionId: null,
				useClientBenchmark: useClientBenchmark,
				groupTrendId,
				groupAccessId,
				dataItemId: 0,
				examLevel,
			},
			showErrorResponse: true,
		},
		true
	);
};
