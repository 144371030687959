import { getBedrockSubjectInformation } from 'features/subjectLookUp/selectors/bedrockLookUp';
import { setBedrockSubjectLookUp, setErrorMessage } from '../slice';
import { getSubjectsById } from 'features/utils/subjects';

/**
 * Fetchs the current subjectIds Bedrock look up data
 */
export const fetchBedrockSubjectsThunk = (subjectIds: number[]): AppThunk => async (
	dispatch,
	getState
) => {
	//State
	const state = getState();

	//Check to see if we have data
	const bedrockData = getBedrockSubjectInformation(state);

	if (bedrockData != undefined) {
		//if we do bail
		return;
	}

	//if we dont have any data fetch it from bedrock
	try {
		const response = await getSubjectsById(subjectIds);

		//No data returned
		if (response.length === 0) {
			dispatch(setErrorMessage('Unable to fetch Bedrock look up data'));
		}

		//populate state
		dispatch(setBedrockSubjectLookUp(response));
	} catch (error) {
		//throw errors
		dispatch(setErrorMessage('Unable to fetch Bedrock look up data'));
	}
	//fin
};
