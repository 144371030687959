import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, Loading } from 'basecamp';

const IsLoading: FunctionComponent = () => {
	return (
		<Flex setAs="column" withAlign="center" height="calc(100vh - 303px)">
			<Loading />
		</Flex>
	);
};

export default IsLoading;
