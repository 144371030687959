import { translate } from '../../../utils/locale';
import moment from 'moment';
import { ExamLevels } from '../../../types/enum';
import { whitespaceRegex } from '../../../utils/regex';
import { getAllGradepoints, getAppliedGradepointKeys } from '../../app/redux/context';
import { getGradepointTypeFromKey } from '../../../utils/gradepointSelector';
import { SubjectPage } from '../types';

export const getErrorMessage = (state: RootState) => state.subjectPage.errorMessage;

export const hasError = (state: RootState) => {
	return getErrorMessage(state) !== null;
};

export const getLoadingStatus = (state: RootState): Boolean => state.subjectPage.loading;

export const getGradepoints = (state: RootState) => {
	if (!state.subjectPage.data || !state.subjectPage.data[state.subjectPage.currentSubjectId]) {
		return [];
	}

	const data = state.subjectPage.data[state.subjectPage.currentSubjectId];

	const availableGradepoints = getAllGradepoints(state);

	// Only return gradepoints from the subjectPage that exists
	// and is not a target setting (type 1)
	return Object.values(data.Gradepoints)
		.filter((gp: any) => {
			const gradePoint = availableGradepoints.find((x) => x.name === gp.Name);
			return gradePoint && Number(getGradepointTypeFromKey(gradePoint.key)) !== 1;
		})
		.map((gradepoint: any) => gradepoint);
};

export const isLoading = (state: RootState) => {
	return Object.values(state.subjectPage.loading).some((x) => x);
};

/**
 ** Get matching udf students
 */
export const getGroupedMatchingUdfStudents = ({
	subjectPage,
}: RootState):
	| { [p: string]: any[] }
	| { [p: string]: any[] }
	| { [p: string]: any[]; Unknown: any[] } => {
	// Get data for the selected page
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];
	const adHoc = subjectPage.adHoc;

	// Get the udf type / value
	const udftype: string = subjectPage.currentSubjectUdfType.replace(whitespaceRegex, '');
	const udfvalue: string = subjectPage.currentSubjectUdfValue;

	if (!selectedSubject || !selectedSubject.Students) {
		return {};
	}

	const filteredStudents = Object.values(selectedSubject.Students).filter(
		(student: SubjectPage.Student) => {
			if (!student[udftype]) {
				return [];
			}

			return (
				student[udftype].replace(whitespaceRegex, '').toLowerCase() ===
				udfvalue.replace(whitespaceRegex, '').toLowerCase()
			);
		}
	);

	return filteredStudents.reduce((acc: { [k: string]: SubjectPage.Student[] }, curr) => {
		/**
		 * Get adHoc status and AdHoc/View group values
		 */
		const adhocGroup = adHoc && curr.AdHocGroup;
		const viewGroup = !adHoc && curr.ViewGroup;

		const unknown = translate(`subjectPage.students.contextMenu.UNKNOWN`) as string;

		/**
		 * If adHoc is toggled show the AdHocGroup values
		 */
		if (adhocGroup) {
			let group = translate(`subjectPage.students.contextMenu.${adhocGroup.toUpperCase()}`, {
				_: adhocGroup,
			}) as string;

			return {
				...acc,
				[group]: [...(acc[group] || []), curr],
			};
		}
		/**
		 * If adHoc is not toggled and we have a comparison(s) applied,
		 * show the comparsion view group values
		 */
		if (viewGroup) {
			return {
				...acc,
				[viewGroup]: [...(acc[viewGroup] || []), curr],
			};
		}
		/**
		 * Otherwise group as unknown
		 */
		return {
			...acc,
			[unknown]: [...(acc[unknown] || []), curr],
		};
	}, {});
};

/**
 ** Gets a UDF type from the overview page group key
 * and formats it for use in ag grid, e,g 'TeachingSetGroups' becomes 'Teaching Set'
 * this is then stored in a hidden column and used in the routings
 * @param key - state key
 */
const getUdfFromKey = (key: string) => {
	const udfType = key.split(/(?=[A-Z])/);
	return udfType.slice(0, udfType.length - 1).join(' ');
};

/**
 ** Get the overview ag grid row data
 */
const getRowData = (
	overviewGroup: SubjectPage.Group,
	key: SubjectPage.OverviewGroups
): SubjectPage.OverviewGridData => {
	return {
		group: overviewGroup.Name,
		udfType: getUdfFromKey(key),
		...Object.entries(overviewGroup).reduce((acc, [key, val]) => {
			if (key === 'Name') {
				return acc;
			}

			return {
				...acc,
				[`gp${key}Entries`]: val.Entries,
				[`gp${key}Score`]: val.Score,
				[`gp${key}Grade`]: val.Grade,
				[`gp${key}AvgPA`]: val.AvgPa,
				[`gp${key}AvgPAType`]: val.AvgPaType,
				[`gp${key}AvgPADisplay`]: val.AvgPaDisplay,
			};
		}, {}),
	};
};

/**
 ** Get the grid data for the subject overview page
 */
export const getOverviewGridData = ({
	subjectPage,
}: RootState): SubjectPage.OverviewGridDataGroups => {
	// Get data for the selected page
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	// If we have no data, bail
	if (!data || !selectedSubject) {
		return {};
	}

	/**
	 ** Get a list of the overview page groups
	 * StudentGroups
	 * TeachingSetGroups
	 * TeacherGroups
	 **/
	const overviewGroups: SubjectPage.OverviewGroups[] = Object.keys(selectedSubject).filter(
		(key): key is SubjectPage.OverviewGroups => {
			const groups = ['StudentGroups', 'TeachingSetGroups', 'TeacherGroups'];

			return groups.includes(key);
		}
	);

	// Format the data & filter out 'Unknown' Groups
	return overviewGroups.reduce((acc, curr) => {
		return {
			...acc,
			[curr]: Object.values(selectedSubject[curr])
				.map((group) => getRowData(group, curr))
				.filter((el) => el.group !== 'Unknown'),
		};
	}, {});
};

/**
 ** Get the udf grid data for the subject overview page
 */
export const getOverviewUdfGridData = ({
	subjectPage,
}: RootState): SubjectPage.OverviewGridDataGroups => {
	// Get data for the selected page
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];
	const udfForSelectedSubject =
		selectedSubject &&
		selectedSubject.UdfData &&
		selectedSubject.UdfData[subjectPage.currentSubjectUdfValue];

	// If we have no data, bail
	if (!data || !selectedSubject || !selectedSubject.UdfData || !udfForSelectedSubject) {
		return {};
	}

	// Format the data then filter out 'Unknown' Groups
	return {
		StudentGroups: Object.values(udfForSelectedSubject)
			.map((group) => getRowData(group, 'StudentGroups'))
			.filter((el) => el.group !== 'Unknown'),
	};
};

export const getSavedSubjectReports = (state: RootState): SubjectPage.SavedReport[] => {
	const subjectId = state.subjectPage?.currentSubjectId;

	if (!state.subjectPage.data || !state.subjectPage.data[subjectId]) {
		return [];
	}

	const reports =
		state.subjectPage.savedReports &&
		Object.values(state.subjectPage.savedReports)
			.filter((f: any) => f.Entity == subjectId)
			.map((report: any) => {
				return Object.assign({
					collectionName: report.CollectionName || report.collectionName || report.collection,
					createdBy: { ...report.createdBy },
					lastUpdated: moment(report.LastUpdated.$date).format('YYYY-MM-DD hh:mm'),
					shared: report.shared,
					title: report.title,
					id: report.id,
					entity: report.Entity,
					gradepoint: report.gradepoint,
				});
			});

	return reports;
};

export const getSavedSubjectReport = (state: RootState) => {
	const reports = getSavedSubjectReports(state);
	const reportToLoadId = state.subjectPage?.savedReportToLoadId;

	const report = reports?.find((r: any) => r.id === reportToLoadId);

	return report;
};

/**
 * Attempt to get the current saved report id
 * @param state Root state
 * @returns The saved report id (GUID) if set otherwise undefined
 */
export const getSavedReportToLoadId = (state: RootState): string | undefined =>
	state.subjectPage?.savedReportToLoadId;

/**
 ** Get currently loaded saved report title
 ** It is used to determine whether a report should be saved as new or update the currently viewed saved report
 */
export const getSavedReportTitle = (state: RootState): string | null =>
	state.subjectPage?.savedReportTitle;

/**
 ** Get currently selected subject
 */
export const getCurrentSubjectId = ({ subjectPage }: RootState) => {
	return subjectPage.currentSubjectId;
};

/**
 ** Get a list of all stored subjects
 */
export const getAllFetchedSubjects = ({ subjectPage }: RootState): string[] => {
	// Get data for the selected page
	const data: SubjectPage.Data = subjectPage.data;

	if (!data) {
		return [];
	}
	return Object.keys(subjectPage.data).map((subject) => subject);
};

/**
 ** Has current subject got data loaded for current udf value?
 */
export const getAllUdfForSelectedSubject = ({ subjectPage }: RootState): string[] => {
	// Get data for the selected page
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];
	if (!selectedSubject || !selectedSubject.UdfData) {
		return [];
	}

	return Object.keys(selectedSubject.UdfData).map((udf) => udf);
};

/**
 ** Has current subject got data loaded for current
 */
export const getAllSelectedSubjectGradepoints = ({
	subjectPage,
}: RootState): Array<SubjectPage.Gradepoint> => {
	// Get data for the selected page
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	return selectedSubject.Gradepoints;
};

/**
 ** Get what if value
 */
export const getWhatIf = ({ subjectPage }: RootState): boolean => {
	return subjectPage.whatIf;
};

export const getWhatIfOptions = ({ subjectPage }: RootState) => {
	// Get data for the selected page
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!selectedSubject) {
		return [];
	}

	return selectedSubject.Outcomes.filter((x) => x.IsWhatIfAble)
		.sort((a, b) => a.Order - b.Order)
		.map((x) => ({
			label: x.Outcome.Display,
			value: x.Outcome.Value,
		}));
};

export const getAdHoc = ({ subjectPage }: RootState): boolean => {
	return subjectPage.adHoc;
};

export const getReportControlsCanGoBack = (state: RootState): boolean => {
	return state.subjectPage.reportControls.history.back.length > 0;
};

export const getReportControlsCanGoForward = (state: RootState): boolean => {
	return state.subjectPage.reportControls.history.forward.length > 0;
};

export const hasHistoryChanged = ({ subjectPage }: RootState): any => {
	return subjectPage.reportControls.history.hasChanged;
};

export const getFineGrades = (state: RootState): SubjectPage.FineGrades => {
	// Get data for the selected page
	const data: SubjectPage.Data = state.subjectPage.data;

	let fineGradeSettings = {};
	if (data === null) {
		fineGradeSettings = state.spo.fineGradesSettings.setUp;
	} else {
		fineGradeSettings = data && data[state.subjectPage.currentSubjectId].FineGradeSettings.setUp;
	}

	return fineGradeSettings;
};

/**
 * Get the exam level of the current subject
 * @param state Root State
 * @returns {Number} Returns the exam level in the form of a number or undefined if a subject is not present
 */
export const getCurrentSubjectExamLevel = (state: RootState): Number | undefined => {
	if (!state.subjectPage.data || !state.subjectPage.data[state.subjectPage.currentSubjectId]) {
		return undefined;
	}

	const subject = state.subjectPage.data[state.subjectPage.currentSubjectId];

	return subject.ExamLevel;
};

/**
 * Find out if the current subject is a KS4 subject
 * @param state  Root State
 * @returns Returns true if the current subject is KS4, otherwise it returns false
 */
export const isCurrentSubjectKS4 = (state: RootState): boolean => {
	const subjectExamLevel = getCurrentSubjectExamLevel(state);

	return subjectExamLevel == ExamLevels.KS4;
};

/**
 * Gets the teaching sets present for current subject
 * used to create pdf reports that include teaching sets
 */
export const getCurrentSubjectTeachingSets = ({
	subjectPage,
}: RootState): { [key: string]: SubjectPage.Group } => {
	if (
		!subjectPage.data ||
		!subjectPage.currentSubjectId ||
		!subjectPage.data[subjectPage.currentSubjectId]
	) {
		return {};
	}
	return subjectPage.data[subjectPage.currentSubjectId].TeachingSetGroups;
};

/**
 * Get the teaching sets present for the current subject used to create a pdf report
 * This looks in both the  subject page data (which may or may not exist) and the subjects
 * overview data, which is incomplete in terms of SubjectPage.Group, but contains the required
 * data for PDF generation
 *
 * See: https://dev.azure.com/alpsva/Alps%20Engineering%20Sprint%20Board/_workitems/edit/17818
 *
 */
export const getCurrentSubjectTeachingSetsWithNameOnly = (
	state: RootState
): { [key: string]: Partial<SubjectPage.Group> } => {
	/*
		This selector gets the information from the subject page section, which is ideal for what we want
		However, its not guaranteed that the data will be there because the Interactive.SubjectSummary report doesn't
		return the required data if you pass in a UDF value.There might be a better way to do this, this might do for now.
	 */
	const idealData = getCurrentSubjectTeachingSets(state);
	if (Object.keys(idealData).length) {
		return idealData;
	}

	const { subjectPage, subjectsOverview } = state;

	// check we have data in subjects overview, and a current subject ID
	if (!subjectPage.currentSubjectId || !subjectsOverview.data) {
		return {};
	}

	// get the applied gradepoints to scope our results
	const appliedGradepoints = getAppliedGradepointKeys(state) ?? [];

	// find the subject which matches the current subject ID, and get the gradepoints key from it
	const { Gradepoints = {} } =
		subjectsOverview.data?.Subjects.find((s) => s.Subject.Name === subjectPage.currentSubjectId) ??
		{};

	// filter data to applied gradepoints only
	return appliedGradepoints.reduce((obj, gp) => {
		// get the teaching sets for the currently applied gradepoint, if it exists
		const { TeachingSets = {} } = Gradepoints[gp] ?? {};

		// rebuild the objecet to only include the name property
		const reducedData = Object.entries<{ Name: string }>(TeachingSets).reduce(
			(struct, [teachingSet, value]) => {
				return {
					...struct,
					[teachingSet]: {
						Name: value.Name,
					},
				};
			},
			{}
		);

		// merge the reduced data into the blob of teaching set data
		return {
			...obj,
			...reducedData,
		};
	}, {});
};

/**
 * Gets the WhatIf data for all the students in the current subject
 * Used for pdf exports
 * @returns Array<{id: string, outcome: string}>
 */
export const getStudentWhatIf = (state: RootState) => {
	if (
		!state.subjectPage.data ||
		!state.subjectPage.currentSubjectId ||
		!state.subjectPage.data[state.subjectPage.currentSubjectId]
	) {
		return [];
	}

	const data = state.subjectPage.data[state.subjectPage.currentSubjectId];
	return Object.entries(data.Students).map(([id, value]) => ({ id, outcome: value.WhatIfOutcome }));
};

export const idForExport = (state: RootState) => {
	return state.subjectPage.exportId;
};
