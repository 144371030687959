import { createSlice } from "@reduxjs/toolkit";

/**
 ** Initial State
 */
const initialState: StandardPage.State = { gpSelectorIsOpen: false };

/**
 ** Standard Page Slice
 */
const standardPage = createSlice({
  name: "standardPage",
  initialState,
  reducers: {
    toggleGpSelector(state) {
      state.gpSelectorIsOpen = !state.gpSelectorIsOpen;
    },
    closeGpSelector(state) {
      state.gpSelectorIsOpen = false;
    },
  },
});

/**
 ** Export Actions
 */
export const { toggleGpSelector, closeGpSelector } = standardPage.actions;

/**
 ** Export Selectors
 */
export const getGpSelectorState = ({ standardPage }: RootState) =>
  standardPage.gpSelectorIsOpen;

/**
 ** Export Reducers
 */
export default standardPage.reducer;
