import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalysisSettings } from '../types';
/**
 * Initial State
 */
const initialState: AnalysisSettings.State = {
	comparisons: {},
};

/**
 * Slice
 */
const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		updateComparisons(
			state: AnalysisSettings.State,
			action: PayloadAction<{ [key: string]: AnalysisSettings.CustomComparison }>
		) {
			const comparisons: { [key: string]: AnalysisSettings.CustomComparison } = {};

			Object.keys(action.payload).forEach((key: string) => {
				comparisons[key] = {
					...action.payload[key],
					isEdited: false,
				};
			});

			return {
				...state,
				comparisons,
			};
		},
	},
});

/**
 * Export Action
 */
export const { updateComparisons } = settingsSlice.actions;

/**
 * Selectors
 */
export const getComparisons = ({ settings }: RootState) => settings.comparisons;

export const getComparisonsFor = (area: 'Subject' | 'Student') => ({ settings }: RootState) => {
	const areas = [area, 'Both'];
	const initial: { [key: string]: AnalysisSettings.CustomComparison } = {};
	return Object.entries(settings.comparisons)
		.filter(([_, value]) => areas.includes(value.appliesTo as string))
		.reduce((previous, [key, value]) => {
			return {
				...previous,
				[key]: value,
			};
		}, initial);
};
export const getComparisonsForSubjects = (state: RootState) => getComparisonsFor('Subject')(state);
export const getComparisonsForStudents = (state: RootState) => getComparisonsFor('Student')(state);

export const getComparison = (key: string) => ({ settings }: RootState) =>
	settings.comparisons[key];
export const getComparisonOptions = (area: 'Subject' | 'Student', clientId?: number) => ({
	settings,
}: RootState) => {
	const areas = [area, 'Both'];
	return {
		label: 'My Comparisons',
		options: Object.values(settings.comparisons)
			.filter((x) => areas.includes(x.appliesTo as string) && x.ownerId === clientId)
			.map((x) => ({ label: x.name, value: x.id }))
			.sort((a, b) => a.label.localeCompare(b.label)), // sort alphabetically
	};
};

export default settingsSlice.reducer;
