export class SubscriptionAgreements {
	subscriptionAgreements: Array<ManageAccount.SubscriptionAgreement>;

	/**
	 * Create a new instance of SubscriptionAgreements
	 * @param subscriptionAgreements An array of existing subscription agreements
	 */
	constructor(subscriptionAgreements: Array<ManageAccount.SubscriptionAgreement>) {
		this.subscriptionAgreements = subscriptionAgreements;
	}

	/**
	 * Determine if the subscription level is applicable to the user based on the existing subscription agreements
	 * @param subscriptionLevel The type of subscription to check if it is applicable
	 */
	public IsApplicable(subscriptionLevel: ManageAccount.SubscriptionLevel): boolean {
		let isApplicable: boolean = true;

		this.subscriptionAgreements?.forEach((x: ManageAccount.SubscriptionAgreement) => {
			if (x.subscriptionLevel === subscriptionLevel && !x.canResubscribe) isApplicable = false;

			// If ks4ks5 is selected and it can not be re subscribed, hide all options
			if (x.subscriptionLevel === 'KS4KS5' && !x.canResubscribe) isApplicable = false;
		});

		// We only want to show ks4ks5 if no other option is selected, unless both can resubscribe
		if (
			this.subscriptionAgreements?.length &&
			subscriptionLevel === 'KS4KS5' &&
			!this.subscriptionAgreements.every((x) => x.canResubscribe)
		)
			isApplicable = false;

		return isApplicable;
	}

	/**
	 * Checks if any existing subscription agreements exists
	 * @returns True if at least one subscription agreements exists, otherwise false
	 */
	public HasAgreements = (): boolean => {
		const anyResubscribe = this.subscriptionAgreements.find((sub) => sub.canResubscribe);
		return this.subscriptionAgreements && this.subscriptionAgreements.length > 0 && !anyResubscribe;
	};

	public HasSubscriptionsForRenewal = (): boolean => {
		return this.subscriptionAgreements.find((sub) => sub.canResubscribe) ? true : false;
	};
}
