export const spoStatusValues = {
	totalAbove: ['Hit', 'Above', 'HalfHit'],
	above: ['Above'],
	oneBelow: ['OneBelow'],
	wellAbove: ['WellBelow'],
};

///Calculate number of Meg or Pt status values that meet the supplied target
export const calculateNumberOfStatusOnTarget = (
	target: string[],
	subjectsStatus: string[]
): number => {
	var countAchieved = subjectsStatus.map((status) => target.includes(status));

	return countAchieved.filter(Boolean).length;
};
