//get the bedrock look up info for subjects
export const getBedrockSubjectInformation = (state: RootState): SubjectBedrock[] | undefined => {
	return state.subjectLookUpStore.bedrockSubjects;
};

//get a single bedrock subject
export const getBedrockSubjectById = (subjectId: number) => (
	state: RootState
): SubjectBedrock | undefined => {
	return state.subjectLookUpStore.bedrockSubjects?.find((x) => x.alpsSubjectId === subjectId);
};
