import {
	setSetupLoading,
	setSetupErrorMessage,
	setMonitoringTimeline,
	addMonitoringTimeline,
	deleteMonitoringTimeline,
} from '..';
import {
	getGroupGradepoints as getGroupGradepointsAPI,
	updateGroupGradepoints as updateGroupGradepointsAPI,
	addGroupGradepoints as addGroupGradepointsAPI,
	deleteGroupGradepoints as deleteGroupGradepointsAPI,
	lockUpdateGroupGradepoints as lockUpdateGroupGradepointsAPI,
} from '../../../../../api/groupsSetupAPI';
import { getGroupMonitoringPoints } from '../selectors';

/**
 * Fetch Group Monitoring Points and store them in Redux
 */
export const fetchGroupMonitoringPoints = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	try {
		const responseBuilder = await getGroupGradepointsAPI();

		// Handle response builder errors
		if (responseBuilder.HasError) dispatch(setSetupErrorMessage(responseBuilder.Errors[0].Message));

		// Map api response to a structure suitable for Redux
		const monitoringPoints: Array<Groups.Setup.MonitoringPoint> = responseBuilder.ResponseObject.map(
			(x: Groups.Setup.MonitoringPoint) => {
				return {
					...x,
					isNew: false,
					isEdited: false,
					isDeleted: false,
					lockHasChanged: false,
				} as Groups.Setup.MonitoringPoint;
			}
		);

		dispatch(setMonitoringTimeline(monitoringPoints));
	} catch (err) {
		dispatch(setSetupErrorMessage(err.message));
	}
};

/**
 * Add or update a monitoring point in Redux
 * @param monitoringPoint The monitoring point to create
 */
export const addMonitoringPoint = (
	monitoringPoint: Groups.Setup.MonitoringPoint
): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const existing = getGroupMonitoringPoints(state)?.find(({ id }) => id === monitoringPoint.id);

	const mp: Groups.Setup.MonitoringPoint = {
		...monitoringPoint,
		isNew: existing ? existing.isNew || false : true,
		isEdited: true,
		isDeleted: false,
		lockHasChanged: existing && existing.locked != monitoringPoint.locked,
	};

	dispatch(addMonitoringTimeline(mp));
};

/**
 * Mark a monitoring point as deleted in Redux
 * @param monitoringPoint The monitoring point to delete
 */
export const deleteMonitoringPoint = (
	monitoringPoint: Groups.Setup.MonitoringPoint
): AppThunk => async (dispatch) => {
	dispatch(
		deleteMonitoringTimeline({
			...monitoringPoint,
			isDeleted: true,
		})
	);
};

/**
 * Thunk that will save all monitoring point changes in one go.
 * Including new grade points, updated grade points and deleted grade points
 */
export const SaveMonitoringPointChanges = (): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const monitoringPoints = getGroupMonitoringPoints(state);

	// Newly created groups that need to be created
	const newMonitoringPointGroups: Array<Groups.Setup.MonitoringPoint> | undefined =
		monitoringPoints?.filter((x) => x.isNew && !x.isDeleted) ?? [];

	// Any existing items to be saved
	const existingMonitoringPoints: Array<Groups.Setup.MonitoringPoint> | undefined =
		monitoringPoints?.filter((x) => x.isEdited && !x.isDeleted && !x.isNew && !x.lockHasChanged) ??
		[];

	// Items where lock status has changed
	const deletedMonitoringPointGroups: Array<Groups.Setup.MonitoringPoint> | undefined =
		monitoringPoints?.filter((x) => x.isDeleted) ?? [];

	// Items where lock status has changed
	const lockUpdateMonitoringPointGroups = monitoringPoints?.filter((x) => x.lockHasChanged) ?? [];

	// Make all requests in one go
	const [create, update, remove] = await Promise.all([
		newMonitoringPointGroups.length ? addGroupGradepointsAPI(newMonitoringPointGroups) : null,

		existingMonitoringPoints.length ? updateGroupGradepointsAPI(existingMonitoringPoints) : null,

		lockUpdateMonitoringPointGroups.length
			? lockUpdateGroupGradepointsAPI(lockUpdateMonitoringPointGroups)
			: null,

		deletedMonitoringPointGroups.length
			? deleteGroupGradepointsAPI(deletedMonitoringPointGroups.map((x) => x.id))
			: null,
	]);

	if ((create && create.HasError) || (update && update.HasError) || (remove && remove.HasError)) {
		const errors = [
			...(create ? create.Errors : []),
			...(update ? update.Errors : []),
			...(remove ? remove.Errors : []),
		];

		dispatch(setSetupErrorMessage(errors[0].Message));
	} else {
		dispatch(fetchGroupMonitoringPoints());
	}
};
