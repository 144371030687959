import {
	getSubjectsOverviewData,
	isLoading as isloadingSVAO,
} from 'features/subjectsOverview/redux';
import { isLoading as isLoadingSubject } from 'features/subjectPage/redux';

const isVisible = (p: string, param: string): boolean => {
	return param !== p;
};

/**
 * Get the list of available subjects for use in the primary nav
 */
export const getSubjectsList = (params: { [key: string]: string }) => (
	state: RootState
): PrimaryNavigation.Route[] => {
	const subjectsOverviewData = getSubjectsOverviewData(state);

	const loadingSVAO = isloadingSVAO(state);
	const loadingSubject = isLoadingSubject(state);

	if (!subjectsOverviewData) {
		return [];
	}

	return subjectsOverviewData.All?.map(
		({ subjectDisplay }: { subjectId: string; subjectDisplay: string }) => {
			return {
				name: subjectDisplay,
				compactName: subjectDisplay,
				path: `/${encodeURIComponent(subjectDisplay)}`,
				type: 'subject',
				isVisible: isVisible(subjectDisplay, decodeURIComponent(params.subject)),
				isDisabled: loadingSVAO || loadingSubject,
			};
		}
	);
};

/**
 * If available, get a list of teaching sets for use in the primary nav
 */
export const getTeachingSetsList = (params: { [key: string]: string }) => (
	state: RootState
): PrimaryNavigation.Route[] => {
	const data: SubjectPage.Data = state.subjectPage.data;
	const selectedSubject = data && data[state.subjectPage.currentSubjectId];

	const loadingSubject = isLoadingSubject(state);

	if (!data || !selectedSubject || !selectedSubject.TeachingSetGroups) {
		return [];
	}
	return Object.keys(selectedSubject.TeachingSetGroups).map((teachingSet: string) => {
		return {
			name: teachingSet,
			path: `/${encodeURIComponent(teachingSet)}`,
			type: 'udfvalue',
			isVisible: isVisible(teachingSet, decodeURIComponent(params.udfvalue)),
			isDisabled: loadingSubject,
		};
	});
};

/**
 * If available, get a list of teachers for use in the primary nav
 */
export const getTeachersList = (params: { [key: string]: string }) => (
	state: RootState
): PrimaryNavigation.Route[] => {
	const data: SubjectPage.Data = state.subjectPage.data;
	const selectedSubject = data && data[state.subjectPage.currentSubjectId];

	const loadingSubject = isLoadingSubject(state);

	if (!data || !selectedSubject || !selectedSubject.TeacherGroups) {
		return [];
	}

	return Object.keys(selectedSubject.TeacherGroups).map((teacher: string) => {
		return {
			name: teacher,
			path: `/${encodeURIComponent(teacher)}`,
			type: 'udfvalue',
			isVisible: isVisible(teacher, params.udfvalue),
			isDisabled: loadingSubject,
		};
	});
};

export const getCurrentSubjectUdfType = (state: RootState) => {
	return encodeURIComponent(state.subjectPage.currentSubjectUdfType);
};

export const getCurrentSubjectUdfValue = (state: RootState) => {
	return encodeURIComponent(state.subjectPage.currentSubjectUdfValue);
};
