import React from 'react';
import { FunctionComponent } from 'react';
import { ButtonWithIcon } from 'basecamp';
import { translate } from '../utils/locale';
import { useSelector } from 'react-redux';
import { getClient } from 'features/app/redux/context';

type Props = {
	area: string;
};

const EarlyAccess: FunctionComponent<Props> = ({ area }) => {
	const client = useSelector(getClient);
	const clientName = client?.name;
	const clientId = client?.id;
	const clientRef = client?.reference;
	const recipient = translate('earlyAccess.RECIPIENT');
	const emailSubject = translate('earlyAccess.EMAIL_SUBJECT', { clientName, clientRef, clientId });
	const emailBody = translate('earlyAccess.EMAIL_BODY', { clientName, clientRef, clientId, area });
	const mailTo = `mailto:${recipient}?subject=${emailSubject}&body=${emailBody}`;
	/**
	 * Handle feature sign up
	 */
	const handleFeatureSignUp = () => {
		window.location.href = mailTo;
	};

	return (
		<ButtonWithIcon
			withIcon="envelope-open"
			setAs="accent"
			width={160}
			onClick={() => handleFeatureSignUp()}
			justifyContent="flex-end"
			withRightAlignment
		>
			{translate('earlyAccess.BUTTON_TEXT')}
		</ButtonWithIcon>
	);
};

export default EarlyAccess;
