import React, { useState } from 'react';
import { Modal, Heading, Text } from 'basecamp';
import { acceptTermsThunk, logoutThunk } from 'features/app/redux/context';
import { useDispatch } from 'react-redux';
import { translate } from '../../utils/locale';

const TermsAcceptance: React.FunctionComponent = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState<boolean>(false);

	return (
		<Modal
			setAs="small"
			withTitle={translate('termsAcceptance.TITLE')}
			andBodyPadY={0}
			withActions={
				loading
					? []
					: [
							{
								title: translate('termsAcceptance.ACCEPT_TERMS'),
								type: 'accent',
								onClick() {
									setLoading(true);
									dispatch(acceptTermsThunk());
								},
							},
					  ]
			}
			withCancelText={translate('termsAcceptance.DECLINE_TERMS')}
			close={(e: React.MouseEvent<HTMLElement>) => {
				e.preventDefault();
				dispatch(logoutThunk());
			}}
		>
			<Heading setAs={'xlarge'} withColor="black">
				{translate('termsAcceptance.HEADER')}
			</Heading>
			<Text>{translate('termsAcceptance.BODY')}</Text>
			<Text>{translate('termsAcceptance.BODY1')}</Text>
			<Text>{translate('termsAcceptance.BODY2')}</Text>
		</Modal>
	);
};

export default TermsAcceptance;
