import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/*
 ** Initial State
 */
const initialState: SPO.State = {
	loading: false,
	studentViewGroups: {},
	subjectData: {},
	studentMetrics: [],
	progress8Metrics: [],
	appliedMetrics: { label: 'Summary', value: [148, 149, 164, 165] },
	appliedComparisons: [{ label: 'All', value: 'All|All' }],
	priorAttainmentTypes: [],
};

/*
 ** Slice
 */
const spo = createSlice({
	name: 'spo',
	initialState,
	reducers: {
		setStudentViewGroupsPending(state: SPO.State) {
			return {
				...state,
				loading: true,
			};
		},
		setStudentViewGroupsFailed(state: SPO.State) {
			return {
				...state,
				loading: false,
			};
		},
		setStudentViewGroupsSuccess(
			state: SPO.State,
			action: PayloadAction<{
				reports: {
					Entries: SPO.Student[];
					TutorGroups: { Name: string }[];
				}[];
			}>
		) {
			let studentViewGroups = groupStudentsByViewGroup(action.payload.reports[0].Entries);
			let tutorGroups = action.payload.reports[0].TutorGroups.map((x) => x.Name);
			return {
				...state,
				loading: false,
				studentViewGroups,
				tutorGroups,
			};
		},
		setFineGradeSettings(state: SPO.State, action: PayloadAction<any>) {
			return {
				...state,
				fineGradesSettings: action.payload.FineGradeSettings,
			};
		},
		setLoading(state: SPO.State, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setStudentMetricsPending(state: SPO.State) {
			return {
				...state,
				loading: true,
			};
		},
		setStudentMetricsSuccess(
			state: SPO.State,
			action: PayloadAction<PerformanceMetrics.Metrics[]>
		) {
			return {
				...state,
				loading: false,
				studentMetrics: action.payload,
			};
		},
		setStudentMetricsFailed(state: SPO.State) {
			return {
				...state,
				loading: false,
			};
		},
		setAppliedMetrics(state: SPO.State, action: PayloadAction<{ label: string; value: number[] }>) {
			return {
				...state,
				appliedMetrics: action.payload,
			};
		},
		setAppliedComparisons(
			state: SPO.State,
			action: PayloadAction<
				{
					label: string;
					value: string;
				}[]
			>
		) {
			const { payload } = action;

			return {
				...state,
				appliedComparisons: payload ? payload : [{ label: 'All', value: 'All|All' }],
			};
		},
		setPriorAttainmentTypesPending(state: SPO.State) {
			return {
				...state,
				loading: true,
			};
		},
		setPriorAttainmentTypesFailed(state: SPO.State) {
			return {
				...state,
				loading: false,
			};
		},
		setPriorAttainmentTypesSuccess(
			state: SPO.State,
			action: PayloadAction<SPO.PriorAttainmentType[]>
		) {
			return {
				...state,
				priorAttainmentTypes: action.payload,
			};
		},
		setProgress8MetricsPending(state: SPO.State) {
			return {
				...state,
				loading: true,
			};
		},
		setProgress8MetricsSuccess(state: SPO.State, action: PayloadAction<SPO.Progress8Metrics[]>) {
			return {
				...state,
				loading: false,
				progress8Metrics: action.payload,
			};
		},
		setProgress8MetricsFailed(state: SPO.State) {
			return {
				...state,
				loading: false,
			};
		},
	},
});

/*
 ** Helpers
 */
const groupStudentsByViewGroup = (students: SPO.Student[]) => {
	let studentViewGroups: SPO.StudentViewGroups = {};

	studentViewGroups['All Students'] = {
		viewGroupInfo: {
			Name: 'All Students',
		},
		students,
	};

	//foreach unique view group
	new Set(students.flatMap((x) => x.ViewGroups)).forEach((vg) => {
		studentViewGroups[vg] = {
			viewGroupInfo: {
				Name: vg,
			},
			students: students.filter((x) => x.ViewGroups.includes(vg)),
		};
	});

	return studentViewGroups;
};

/*
 ** Export reducer
 */
export default spo.reducer;

/*
 ** Actions
 */
export const {
	setStudentViewGroupsPending,
	setStudentViewGroupsFailed,
	setStudentViewGroupsSuccess,
	setFineGradeSettings,
	setStudentMetricsPending,
	setStudentMetricsSuccess,
	setStudentMetricsFailed,
	setLoading,
	setAppliedMetrics,
	setAppliedComparisons,
	setPriorAttainmentTypesPending,
	setPriorAttainmentTypesFailed,
	setPriorAttainmentTypesSuccess,
	setProgress8MetricsPending,
	setProgress8MetricsSuccess,
	setProgress8MetricsFailed,
} = spo.actions;
