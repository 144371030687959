import * as React from 'react';
import { FunctionComponent } from 'react';
import { useWindowSize } from '../utils/hooks';

import { Box } from 'basecamp';

type Props = {
	classNames?: string;
	isMultiGrid?: boolean;
	hasFooter?: boolean;
	isTabbedGrid?: boolean;
};

const AgGridFixedHeightWrapper: FunctionComponent<Props> = ({
	isMultiGrid,
	isTabbedGrid,
	children,
	hasFooter,
	classNames,
}) => {
	/**
	 ** Handle window resize
	 */
	const { height } = useWindowSize();
	const heightWithFooter = height && height - 383;
	const heightWithoutFooter = height && height - 303;
	const sizeToFitToTabs = height && height - 358;
	const handleHeight = isTabbedGrid
		? sizeToFitToTabs
		: hasFooter
		? heightWithFooter
		: heightWithoutFooter;

	/**
	 ** Render Component
	 */
	return (
		<Box
			className={
				isMultiGrid ? `ag-theme-alps is-multi-grid ${classNames}` : `ag-theme-alps  ${classNames}`
			}
			height={handleHeight}
			width={1}
		>
			{children}
		</Box>
	);
};

export default AgGridFixedHeightWrapper;
