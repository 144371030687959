import { setLoading, setErrorMessage } from '..';
import moment from 'moment';
import { Report, Context } from 'features/reports/types';
import * as share from '../../../../utils/share';
import {
	fetchReports,
	fetchReport,
	addReport,
	loadReport,
	deleteReport,
	updateReport,
} from '../../../../api/shareAPI';
import {
	setSavedSubjectReports,
	setAddSubjectReport,
	setLoadSavedReport,
	setSavedReportTitle,
	setUpdateSubjectReport,
	setDeleteSubjectReport,
	resetReportControlHistory,
} from '../slice';
import { changePrimaryGradepointThunk } from 'features/app/redux/context';

export const fetchSubjectReportsThunk = (
	collectionName: string,
	personal: boolean,
	shared: boolean
): AppThunk => async (dispatch, getState) => {
	dispatch(setLoading('SavedSubjectReports'));

	try {
		const state = getState();

		// Create the document for DB request
		const doc = new share.Document(
			state.context.reportContext.currentDataset?.datasetItem,
			state.context.user?.id,
			state.context.yetiToken
		);
		//doc.entity = entity;

		const [reports] = await Promise.all([fetchReports(collectionName, doc, personal, shared)]);
		/**
		 * Merge the reports into one object
		 */
		let subjectReportsData = reports.map((r: string) => JSON.parse(r));

		subjectReportsData = subjectReportsData.map((r: Report.SubjectReport) => {
			r.CollectionName = collectionName;
			r.id = !r.id ? r._id.$oid : r.id;
			return r;
		});

		dispatch(setSavedSubjectReports(subjectReportsData));
	} catch (err) {
		dispatch(setErrorMessage(err.message));
	}
};

export const addSubjectReportThunk = (collectionName: string): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const context = state.context;
	const subjectPage = state.subjectPage;

	const entityId = subjectPage?.currentSubjectId;
	const hasNonTargetedChanges = subjectPage?.reportControls?.hasNonTargetedChanges;

	const changedStudentIds = Object.values(state.subjectPage?.data?.[entityId]?.Students)
		.filter((s: any, key: number) => {
			return s.Outcome?.Value !== s.WhatIfOutcome || s.AdHocGroup !== 'orange';
		})
		.map((h: any) => h.StudentId);

	const changedStudents = Object.values(state.subjectPage?.data?.[entityId]?.Students)
		.filter((s: any, key: number) => {
			return changedStudentIds.includes(s.StudentId);
		})
		.map((s: any) => {
			return {
				subjectId: entityId,
				id: s.StudentId,
				viewGroup: s.AdHocGroup,
				whatIfOutcome: s.WhatIfOutcome,
				teachingSet: s.TeachingSet,
			};
		});

	const innerData = {
		adHoc: subjectPage?.adHoc,
		whatIf: subjectPage?.whatIf,
		changedStudents: changedStudents,
		createdBy: context.user,
		gradepoint: context.reportContext.currentDataset?.primaryGradepoint?.key,
		isCustomComparison: context.isCustomComparison,
		hasNonTargetedChanges: !!hasNonTargetedChanges ? hasNonTargetedChanges : false,
		CollectionName: collectionName,
	};

	const doc = new share.Document(
		context.reportContext?.currentDataset?.datasetItem,
		context.user?.id,
		context.yetiToken,
		innerData /* innerData */,
		false /* shared */,
		entityId /* entity */,
		null
	);

	const body = doc.FormatRequestBody(collectionName);

	await Promise.all([addReport(body, doc)]).then((response) => {
		const timestamp = moment(response[0].LastUpdated);

		const report: Report.SubjectReport = Object.assign(doc.innerData);

		report.Entity = entityId;
		report.createdTimestamp = timestamp;
		report.id = response[0].ObjectId;
		report.title = `${context.user?.username}, ${moment(timestamp).format('DD/MM/YYYY HH:mm')}`;

		return serializeAndUpdate(collectionName, report, context)
			.then((res) => Promise.resolve(res))
			.then((response) => {
				/**
				 * Need to add the newly saved report to the savedReports reducer
				 */
				fetchReport(collectionName, doc).then((res: any) => {
					let parsedRes = JSON.parse(res);

					switch (collectionName) {
						case 'subject': {
							dispatch(setAddSubjectReport(parsedRes));
						}
					}
				});

				return Promise.resolve(response);
			});
	});
};

export const updateSubjectReportThunk = (collectionName: string): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const subjectPage = state.subjectPage;

	const entityId = subjectPage?.currentSubjectId;
	const reportToUpdate = subjectPage?.savedReports?.find(
		(r: any) => r.title == subjectPage?.savedReportTitle
	);

	const changedStudentIds = Object.values(state.subjectPage?.data?.[entityId]?.Students)
		.filter((s: any, key: number) => {
			return s.Outcome?.Value !== s.WhatIfOutcome || s.AdHocGroup !== 'orange';
		})
		.map((h: any) => h.StudentId);

	const changedStudents = Object.values(state.subjectPage?.data?.[entityId]?.Students)
		.filter((s: any, key: number) => {
			return changedStudentIds.includes(s.StudentId);
		})
		.map((s: any) => {
			return {
				subjectId: entityId,
				id: s.StudentId,
				viewGroup: s.AdHocGroup,
				whatIfOutcome: s.WhatIfOutcome,
			};
		});

	const report = {
		...reportToUpdate,
		changedStudents,
		whatIf: state.subjectPage?.whatIf,
		adHoc: state.subjectPage?.adHoc,
	};

	if (report) {
		serializeAndUpdate(report.CollectionName, report, state.context);
	}
};

export const loadSubjectReportThunk = (
	collectionName: string,
	id: string,
	push: any = null
): AppThunk => async (dispatch, getState) => {
	dispatch(setLoading('LoadSavedSubjectReport'));

	try {
		const state = getState();
		const doc = new share.Document();
		doc.objectId = id;
		doc.user = state.context.user?.id;
		doc.dataItem = state.context.reportContext.currentDataset?.datasetItem;
		doc.yetiToken = state.context.yetiToken;

		const body = doc.FormatRequestBody(collectionName);

		/**
		 * Needs to follow specific order. This order will allow the saved subject page to be loaded
		 * without having the comparisons cleared by the clearComparisons method called in componentWillMount.
		 * This order allows for the subjectPage/index to make another request for subject page data following
		 * a gradepoint change
		 * 1) Retrieve report and load data into subject page state
		 * 2) Change the gradepoint
		 * 3) Redirect
		 * 4) Set custom view groups
		 */

		await Promise.all([loadReport(body)])
			.then((res: any) => {
				const parsedRes = JSON.parse(res);

				switch (collectionName) {
					case 'subject':
						/**
						 * Reset history so we get Students array with no changes applied
						 */
						dispatch(resetReportControlHistory());

						/**
						 * Load desired report
						 */
						dispatch(setLoadSavedReport(parsedRes));
				}

				return Promise.resolve(parsedRes);
			})
			.then((parsedRes) => {
				let primaryGradepoint = state.context.reportContext.currentDataset?.primaryGradepoint;

				/**
				 * If the gradepoint for the saved report does not match the report in the context, need to update
				 */
				if (parsedRes.gradepoint !== primaryGradepoint?.key) {
					dispatch(
						changePrimaryGradepointThunk({
							key: parsedRes.gradepoint,
							gradepointSelection: parsedRes.gradepoint,
							isAppStartSelection: false,
							rerunAutoGpPopulation: false,
						})
					);
				}

				return Promise.resolve(parsedRes);
			})
			.then((parsedRes: any) => {
				/**
				 * Redirect the user to the subject page
				 */
				if (collectionName === 'subject') {
					if (push) {
						push(`/subjects/${parsedRes.Entity}/students`);
					}
				}

				return Promise.resolve(parsedRes);
			})
			.then((parsedRes) => {
				/**
				 * Comparisons are cleared each time a new subject page is created.
				 *
				 * Set the custom comparisons flag to true if the saved data contains custom
				 * comparisons
				 */

				if (parsedRes.isCustomComparison) {
					// If the saved subject report has custom comparisons, dispatch the below
					// to set the isCustomComparison flag to true in the context state
					//TODO: to do if we get a case of report being a custom comparison
					// dispatch({
					// 	type: redux.getActionType(appConstants, 'VIEW_APPLY_CUSTOM'),
					// 	preventCustomSet: true
					// });
				}

				return Promise.resolve(parsedRes);
			});
	} catch (err) {
		dispatch(setErrorMessage(err.message));
	}
};

/**
 * Update the title of the given report
 * @param  {string} id Report id
 * @param  {string} title New report title
 */
export const renameSubjectReportThunk = (id: string, title: string): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const existingReports = state.subjectPage?.savedReports;
	const isArray = Array.isArray(existingReports);
	let existingReport = existingReports?.find((r: any) => {
		return r.id === id;
	});

	if (existingReport?.title !== title) {
		const report = { ...existingReport, title };

		if (report) {
			serializeAndUpdate(report.CollectionName, report, state.context);
		}

		dispatch(setUpdateSubjectReport(report));
	}
};

export const toggleSharedReportThunk = (id: any, shared: boolean): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	let existingReport = state.subjectPage.savedReports.find((r: any) => r.id === id);

	const report = { ...existingReport, shared: shared };

	if (report) {
		serializeAndUpdate(report.CollectionName, report, state.context);
	}

	dispatch(setUpdateSubjectReport(report));
};

export const deleteSubjectReportThunk = (collectionName: string, id: string): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();

	const doc = new share.Document();
	doc.objectId = id;
	doc.dataItem = state.context.reportContext.currentDataset?.datasetItem;
	doc.yetiToken = state.context.yetiToken;

	const body = doc.FormatRequestBody(collectionName);

	await Promise.all([deleteReport(body)]).then((response) => {
		dispatch(setDeleteSubjectReport(id));
	});
};

/**
 * Update document in the DB to match latest local version
 * @param  {function} getState Redux getState function
 * @param  {object} doc Document object describing mongo doc (see sharingUtils.Document)
 */
const serializeAndUpdate = (
	collectionName: string,
	report: Report.SubjectReport,
	context: Context.State
) => {
	const doc = new share.Document(
		context.reportContext?.currentDataset?.datasetItem,
		report.createdBy?.id,
		context.yetiToken,
		report /* innerData */,
		report.shared /* shared */,
		report.Entity || null /* entity */,
		report.id
	);

	const body = doc.FormatRequestBody(collectionName);
	return updateReport(body);
};
