import { SubscriptionAgreements } from './SubscriptionAgreement';
import { translate } from '../../../utils/locale';
import { formatLongNumbers } from '../../../utils/display';

export class Subscription {
	private _subscriptionAgreements: SubscriptionAgreements;
	private _subscriptions: ManageAccount.Subscription;

	public SubscriptionPrices: ManageAccount.SubscriptionPrices;

	constructor(
		subscriptionAgreements: SubscriptionAgreements,
		subscriptions: ManageAccount.Subscription,
		subscriptionPrices: ManageAccount.SubscriptionPrices
	) {
		this._subscriptionAgreements = subscriptionAgreements;
		this._subscriptions = subscriptions;
		this.SubscriptionPrices = subscriptionPrices;
	}

	/**
	 * Checks if a price should be displayed to the user
	 * If the user has an active subscription, they need to get in touch for an accurate price
	 * @returns
	 */
	public CanShowPrice = (): boolean => {
		return !this._subscriptionAgreements.HasAgreements();
	};

	/**
	 * Calculate cost per level
	 * @param type The type of subscription
	 * @returns The cost of the specified subscription based
	 */
	public CostPerYear = (type: ManageAccount.SubscriptionLevel): number | null => {
		const duration = this.GetSubscriptionDurationIndex(type);
		const size = this.GetSubscriptionSize(type);

		if (duration === undefined || size === undefined) return null;

		return this.SubscriptionPrices[type][size]![duration] as number;
	};

	/**
	 * Get the from cost which is the cheapest subscription option
	 * @returns The cheapest subscription cost
	 */
	public GetCheapestCost = (type: ManageAccount.SubscriptionLevel): number => {
		return this.SubscriptionPrices[type]['small']![0] as number;
	};

	/**
	 * Get the display value of the subscription cost
	 * Takes into consideration if the cost should be shown and if the user has selected a subscription
	 * @param type The type of subscription
	 * @returns The display value to show for the specified subscription type
	 */
	public GetCostDisplayValue = (type: ManageAccount.SubscriptionLevel): string => {
		// Attempt to get the cost per year
		const costPerYear = this.CostPerYear(type);

		// If we have a cost per year value, use it
		if (costPerYear)
			return translate('manageAccount.subscriptions.selector.COST_PER_YEAR', {
				cost: formatLongNumbers(costPerYear),
			}) as string;

		// We can show a price but nothing is selected so show the cheapest from option
		return translate('manageAccount.subscriptions.selector.NO_FROM_COST', {
			fromCost: formatLongNumbers(this.GetCheapestCost(type)),
		}) as string;
	};

	/**
	 * If selected, get the duration of the subscription
	 * @param type The type of subscription
	 * @returns Either the duration of the selected subscription or undefined
	 */
	public GetSubscriptionDurationIndex = (
		type: ManageAccount.SubscriptionLevel
	): number | undefined => {
		return this._subscriptions[type].duration?.value;
	};

	/**
	 * If selected, get the duration of the subscription
	 * @param type The type of subscription
	 * @returns The duration of the selected subscription in years
	 */
	public GetSubscriptionDuration = (type: ManageAccount.SubscriptionLevel): number => {
		return (this.GetSubscriptionDurationIndex(type) ?? 0) + 1;
	};

	/**
	 * If selected, get the size value of the subscription
	 * @param type The type of subscription
	 * @returns Either small, medium or large if a subscription is set, otherwise returns undefined
	 */
	public GetSubscriptionSize = (type: ManageAccount.SubscriptionLevel): string | undefined => {
		return this._subscriptions[type].size?.value;
	};

	/**
	 * Get the selected subscription size id
	 * @param type The type of subscription
	 * @returns The selected subscription size id
	 */
	public GetSubscriptionSizeId = (type: ManageAccount.SubscriptionLevel): number => {
		// KS4 only supports small
		if (type === 'KS4') return 1;

		const selectedSize = this.GetSubscriptionSize(type);
		if (!selectedSize) return 0;

		return Object.keys(this.SubscriptionPrices[type]).indexOf(selectedSize);
	};

	/**
	 * Calculate the total cost of the selected subscription
	 * This is the per year cost so it will take into consideration student numbers and duration
	 * @param type The type of subscription
	 * @returns The total cost of the selected subscription
	 */
	public CalculateTotal = (type: ManageAccount.SubscriptionLevel): number => {
		// return 0 if no type is selected
		if (!type) return 0;

		const size = this.GetSubscriptionSize(type);
		const duration = this.GetSubscriptionDuration(type);
		const durationIndex = this.GetSubscriptionDurationIndex(type);

		if (!!size && !!duration) {
			/**
			 ** Get the price from the subscription data
			 * Per year cost is based on the number of students (small, medium & large)
			 * and the length of the subscription level (1, 2 or  3).
			 * Total is per year x the length of the subscription level
			 */
			return this.SubscriptionPrices[type][size]![durationIndex] * duration;
		}

		return 0;
	};
}
