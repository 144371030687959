import { AgGridReactProps } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import NoRowsOverlay from './overlays/noRowsOverlay';
import { PriorAttainmentRenderer, ScoreRenderer, DefaultRenderer } from './cellRenderers';
import QualTypeFilter from './filters/qualTypeFilter';
import * as React from 'react';
import { AlpsGradeClass } from './cellStyle';

type Options = AgGridReactProps & {
	errorSelector?: any;
	onErrorRetry?: any;
};

export const createDefaultGridOptions = ({
	errorSelector,
	onErrorRetry,
	frameworkComponents = {},
	...rest
}: Options = {}) => {
	/**
	 ** Default grid options
	 */
	const defaultGridOptions: AgGridReactProps = {
		defaultColDef: {
			resizable: true,
			sortable: true,
			suppressMenu: false,
			cellRenderer: 'DefaultRenderer',
			cellClass: AlpsGradeClass,
			/**
			 * The config below handles Grade, Score, Entries and Avg Pa columns
			 * Please override for all other columns in the relevant grid
			 */
			minWidth: 100,
			flex: 1,
		},
		modules: AllModules,
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressPivotMode: true,
						suppressRowGroups: true,
						suppressValues: true,
					},
				},
			],
		},
		suppressCellSelection: true,
		suppressContextMenu: true,
		suppressRowTransform: true,
		alwaysShowVerticalScroll: true,
		suppressHorizontalScroll: false,
		noRowsOverlayComponentFramework: (_params: any) => {
			return <NoRowsOverlay errorSelector={errorSelector} onRetry={onErrorRetry} />;
		},
		frameworkComponents: {
			QualTypeFilter: QualTypeFilter,
			...frameworkComponents,
		},
		components: {
			DefaultRenderer,
			ScoreRenderer,
			PriorAttainmentRenderer,
		},
		domLayout: 'autoHeight',
		...rest,
	};

	return defaultGridOptions;
};
