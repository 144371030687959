import * as React from 'react';
import { FunctionComponent } from 'react';
import { Switch } from 'react-router-dom';
import RouteWithSubRoutes from './components/routeWithSubRoutes';

type Props = {
	routes: Routes.Route[];
};

const GetRoutes: FunctionComponent<Props> = ({ routes }) => {
	/**
	 ** Render Component
	 */
	return (
		<Switch>
			{routes &&
				routes.map((route: Routes.Route) => <RouteWithSubRoutes key={route.path} {...route} />)}
		</Switch>
	);
};

export default GetRoutes;
