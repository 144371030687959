import * as React from 'react';
import { FunctionComponent, useContext } from 'react';
import { updatePrimaryContact } from '../../features/manageUsers/redux/users';
import { useDispatch } from 'react-redux';
import { ManageUsersContext } from 'features/manageUsers/manageUsers';

import { Switch, Flex } from 'basecamp';

type Props = {
	user: ManageUsers.User;
};

const DataContactRenderer: FunctionComponent<Props> = (props) => {
	const manageUsersContext = useContext<ManageUsers.SaveState>(ManageUsersContext);
	const dispatch = useDispatch();
	const toggleContact = () => {
		manageUsersContext.setSaveDisabled(false);
		dispatch(updatePrimaryContact(props.user));
	};

	return (
		<Flex setAs="row" withAlign="centerLeft" height="100%">
			<Switch
				onClick={() => {
					toggleContact();
				}}
				setAs="accent"
				withSize="small"
				isOn={props.user.isPrimaryDataContact}
			/>
		</Flex>
	);
};

export default DataContactRenderer;
