import * as React from 'react';
import { FunctionComponent } from 'react';

import { Modal, Heading, Text } from 'basecamp';

import { translate } from '../../../utils/locale';

type Props = {
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	handleNavigation: () => void;
};

const UnsavedChangeModal: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<Modal
			setAs="small"
			close={() => props.setShowModal(false)}
			withActions={[
				{
					title: translate('manageUsers.modals.unsavedChanges.ACTION_TITLE') as string,
					type: 'error',
					onClick: () => props.handleNavigation(),
				},
			]}
			andBodyPadY={4}
			dataTest="unSavedChangeModal"
		>
			<Heading setAs="large" withColor="error" dataTest="unSavedChangeModalTitle">
				{translate('manageUsers.modals.unsavedChanges.WARNING_ONE') as string}
			</Heading>
			<Text mb={3} dataTest="unSavedChangeModalBody">
				{translate('manageUsers.modals.unsavedChanges.WARNING_TWO') as string}
			</Text>
		</Modal>
	);
};

export default UnsavedChangeModal;
