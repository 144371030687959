import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MonitoringPage } from '../types';
import { Context } from '../../../features/app/types/index';

/**
 ** Initial State
 */
const initialState: MonitoringPage.State = {
	loading: false,
	difference: {
		primaryGradepoint: undefined,
		secondaryGradepoint: undefined,
		rowData: [],
	},
	variance: {
		gradepoints: [],
		rowData: [],
	},
	overview: {
		rowData: [],
		teachers: [],
		teachingSets: [],
	},
	examVsMonitoring: {
		rowData: [],
		primaryGradepoint: undefined,
		selectedComparisonGradepoint: undefined,
		availableComparisonGradepoints: undefined,
	},
	error: undefined,
};

/**
 ** Monitoring Slice
 */
const monitoringSlice = createSlice({
	name: 'monitoring',
	initialState,
	reducers: {
		updateMonitoringLoading(state: MonitoringPage.State, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setMonitoringDifferenceSuccess(
			state: MonitoringPage.State,
			action: PayloadAction<MonitoringPage.Difference>
		) {
			state.loading = true;
			state.difference = action.payload;
			state.error = undefined;
		},
		setMonitoringDifferenceFailed(
			state: MonitoringPage.State,
			action: PayloadAction<MonitoringPage.Error>
		) {
			state.loading = false;
			state.error = action.payload;
			state.difference = {
				primaryGradepoint: undefined,
				secondaryGradepoint: undefined,
				rowData: [],
			};
		},
		setMonitoringVarianceSuccess(
			state: MonitoringPage.State,
			action: PayloadAction<MonitoringPage.Variance>
		) {
			state.loading = false;
			state.variance = action.payload;
			state.error = undefined;
		},
		setMonitoringVarianceFailed(
			state: MonitoringPage.State,
			action: PayloadAction<MonitoringPage.Error>
		) {
			state.loading = false;
			state.error = action.payload;
			state.variance = {
				gradepoints: [],
				rowData: [],
			};
		},
		setMonitoringOverviewSuccess(
			state: MonitoringPage.State,
			action: PayloadAction<MonitoringPage.Overview>
		) {
			state.overview = action.payload;
		},
		setMonitoringOverviewFailed(
			state: MonitoringPage.State,
			action: PayloadAction<MonitoringPage.Error>
		) {
			state.loading = false;
			state.error = action.payload;
			state.overview = {
				rowData: [],
				teachers: [],
				teachingSets: [],
			};
		},
		setExamVsMonitoringGradepoints(
			state: MonitoringPage.State,
			action: PayloadAction<{
				primaryGradepoint: Context.Gradepoint;
				selectedComparisonGradepoint: Context.Gradepoint;
				availableComparisonGradepoints: Array<Context.Gradepoint>;
			}>
		) {
			state.examVsMonitoring.primaryGradepoint = action.payload.primaryGradepoint;
			state.examVsMonitoring.selectedComparisonGradepoint =
				action.payload.selectedComparisonGradepoint;
			state.examVsMonitoring.availableComparisonGradepoints =
				action.payload.availableComparisonGradepoints;
		},
		setExamVsMonitoringDataSuccess(
			state: MonitoringPage.State,
			action: PayloadAction<Array<MonitoringPage.DifferenceRow>>
		) {
			state.examVsMonitoring.rowData = action.payload;
			state.error = undefined;
		},
		setExamVsMonitoringDataFailed(
			state: MonitoringPage.State,
			action: PayloadAction<MonitoringPage.Error>
		) {
			state.loading = false;
			state.error = action.payload;
			state.examVsMonitoring = {
				...state.examVsMonitoring,
				rowData: [],
			};
		},
	},
});

/**
 ** Export Actions
 */
export const {
	updateMonitoringLoading,
	setMonitoringDifferenceSuccess,
	setMonitoringDifferenceFailed,
	setMonitoringVarianceSuccess,
	setMonitoringVarianceFailed,
	setMonitoringOverviewSuccess,
	setMonitoringOverviewFailed,
	setExamVsMonitoringGradepoints,
	setExamVsMonitoringDataSuccess,
	setExamVsMonitoringDataFailed,
} = monitoringSlice.actions;

/**`
 ** Export Reducers
 */
export default monitoringSlice.reducer;
