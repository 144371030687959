import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { GridApi, ColumnApi } from '@ag-grid-community/all-modules';

type Grid = {
	onGridReady: (params: any) => void;
	api?: GridApi;
	columnApi?: ColumnApi;
};
const useAgGrid = (): Grid => {
	/**
	 ** Handle State
	 */
	const [gridApi, setGridApi] = useState<{ api: GridApi; columnApi: ColumnApi }>();

	const getWidth = () =>
		window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	const [width, setWidth] = useState(getWidth());

	/**
	 ** On Grid Ready
	 */
	const onGridReady = useCallback((params) => {
		const { api, columnApi } = params;

		setGridApi({ api, columnApi });
	}, []);

	return {
		onGridReady,
		...gridApi,
	};
};

export default useAgGrid;
