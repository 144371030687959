import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { acknowledgeMessage, getMessages } from '../../../api/rtmAPI';
import { setupRtmConnection } from '../../../api/rtmAPI';
import { hasDataBeenUpdated, setUpdatedGp } from 'features/performanceMeasures/redux';
import { getClientId } from 'features/app/redux/context';
/*
 ** Initial state
 */
const initialState: RTM.State = {
	messages: [],
	loading: false,
	errors: [],
};

/**
 ** Auth Slice
 */
const context = createSlice({
	name: 'rtm',
	initialState,
	reducers: {
		setupRtmPending(state: RTM.State) {
			return {
				...state,
				loading: true,
			};
		},
		setupRtmSuccess(state: RTM.State) {
			return {
				...state,
				loading: false,
			};
		},
		setupRtmFailure(state: RTM.State) {
			//errors???
			return {
				...state,
				loading: false,
			};
		},
		getRtmMessagesPending(state: RTM.State) {
			return {
				...state,
				loading: true,
			};
		},
		getRtmMessagesSuccess(state: RTM.State, action: PayloadAction<Array<RTM.Message>>) {
			return {
				...state,
				loading: false,
				messages: action.payload,
			};
		},
		getRtmMessagesFailure(state: RTM.State) {
			//errors???
			return {
				...state,
				loading: false,
			};
		},
		rtmUpdate(state: RTM.State, action: PayloadAction<RTM.Message>) {
			if (action.payload.acknowledged) {
				//we should already have unread messsage in state
				//remove the messsage from state now it's read
				const messages = state.messages.filter((x) => x.id != action.payload.id);
				return {
					...state,
					messages,
				};
			} else {
				return {
					...state,
					messages: [...state.messages, action.payload],
				};
			}
		},
		removeTRMMessageFromState(state: RTM.State, action: PayloadAction<number[]>) {
			// Remove selected id's from the current message list
			const filteredMessages = state.messages.filter(
				(message) => !action.payload.includes(message.id)
			);

			return {
				...state,
				messages: filteredMessages,
			};
		},
	},
});

/**
 ** Export Actions
 */
export const {
	setupRtmPending,
	setupRtmSuccess,
	setupRtmFailure,
	rtmUpdate,
	getRtmMessagesPending,
	getRtmMessagesFailure,
	getRtmMessagesSuccess,
	removeTRMMessageFromState,
} = context.actions;

/**
 ** Export Selectors
 */

// Get RTM messages
export const getRtmMessages = ({ rtm }: RootState) => rtm.messages;

/**
 ** Export Reducers
 */
export default context.reducer;

/*
 ** Export Thunks
 */
export const rtmSetupThunk = (): AppThunk => async (dispatch, getState) => {
	dispatch(setupRtmPending());

	const state = getState();
	const clientId = getClientId(state);

	try {
		await setupRtmConnection((notification: RTM.Message) => {
			//KS4 data updated
			if (notification.type.id === 6) {
				dispatch(hasDataBeenUpdated());
				dispatch(setUpdatedGp(notification.data.gradepointId));
				dispatch(rtmUpdate(notification));
			} else {
				dispatch(rtmUpdate(notification));
			}
		}, clientId);
	} catch {
		dispatch(setupRtmFailure());
		return;
	}

	dispatch(setupRtmSuccess());
};

export const getRtmMessagesThunk = (): AppThunk => async (dispatch) => {
	dispatch(getRtmMessagesPending());

	try {
		let messages: RTM.Message[] = await getMessages();
		dispatch(getRtmMessagesSuccess(messages));
	} catch {
		dispatch(getRtmMessagesFailure());
	}
};

export const acknowledgeMessageThunk = (id: number): AppThunk => async (dispatch) => {
	await acknowledgeMessage(id);

	// resync state
	dispatch(getRtmMessagesThunk());
};
