import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { academicYearStandardFormat } from '../../../utils/academicYear';
import { Context } from '../../app/types';
import { Report } from '../types';

import {
	getPersonalisedTargets,
	fetchMyReports,
	fetchReportData,
	fetchAdditional,
	fetchAdditionalData,
} from '../../../api/reportAPI';

/**
 ** Initial State
 */
const initialState: Report.State = {
	personalisedTargets: [],
	availableYears: [],
	selectedYear: undefined,
	myReports: [],
	reportData: {},
	additional: [],
	additionalData: {},
	loading: false,
	loadingMyReports: false,
};

/**
 ** Report Slice
 */
const report = createSlice({
	name: 'report',
	initialState,
	reducers: {
		setPersonalisedTargetsPending(state: Report.State) {
			return {
				...state,
				loading: true,
			};
		},
		setPersonalisedTargetsSuccess(
			state: Report.State,
			action: PayloadAction<Array<Report.PersonalisedTargets>>
		) {
			const personalisedTargets: Array<Report.PersonalisedTargets> = action.payload;

			return {
				...state,
				personalisedTargets: personalisedTargets,
				loading: false,
			};
		},
		setPersonalisedTargetsFailed(state: Report.State) {
			return {
				...state,
				loading: false,
			};
		},
		setMyReportsPending(state: Report.State) {
			return {
				...state,
				loadingMyReports: true,
			};
		},
		setMyReportsFailed(state: Report.State) {
			//error state?
			return {
				...state,
				loadingMyReports: false,
			};
		},
		setMyReportsSuccess(
			state: Report.State,
			action: PayloadAction<{ Reports: Report.Report[]; AvailableYears: number[] }>
		) {
			//custom report ordering
			const sortOrder = (name: string): number => {
				const split = name.split('/');

				const level = ['A', 'AS', 'BTEC', 'BTECCert', 'BTEC90Cred', 'KS4'].indexOf(split[0]);
				const validLevel = level > -1 ? level : 99;

				const report = [
					'Provider',
					'Disadvantaged',
					'Ethnicity',
					'Gender',
					'Governors',
					'HighGrades',
				].indexOf(split[1]);
				const validReport = report > -1 ? report : 9;

				return validLevel + validReport;
			};

			let reports = action.payload.Reports.map((report) => ({
				report,
				order: sortOrder(report.Name),
			})).sort((a, b) => a.order - b.order);
			let availableYears = action.payload.AvailableYears.sort((a, b) => b - a);
			let selectedYear =
				state.selectedYear && availableYears.find((x) => x == state.selectedYear)
					? state.selectedYear
					: availableYears[0];

			return {
				...state,
				loadingMyReports: false,
				myReports: reports.map((x) => x.report),
				availableYears: action.payload.AvailableYears,
				selectedYear: selectedYear,
			};
		},
		setReportDataPending(state: Report.State) {
			return {
				...state,
				loading: true,
			};
		},
		setReportDataFailed(state: Report.State) {
			//error state?
			return {
				...state,
				loading: false,
			};
		},
		setReportDataSuccess(
			state: Report.State,
			action: PayloadAction<{ Report: Report.Report; PreviousReports: Report.Report[] }>
		) {
			let reportData = {
				...state.reportData,
				[action.payload.Report.EntityKey]: {
					report: action.payload.Report,
					previousReports: action.payload.PreviousReports,
				},
			};

			return {
				...state,
				loading: false,
				reportData,
			};
		},
		setAdditionalPending(state: Report.State) {
			return {
				...state,
				loading: true,
			};
		},
		setAdditionalSuccess(
			state: Report.State,
			action: PayloadAction<{ EnquiryJobs: Report.Additional[]; AvailableYears: number[] }>
		) {
			return {
				...state,
				loading: false,
				additional: action.payload.EnquiryJobs,
			};
		},
		setAdditionalFailed(state: Report.State) {
			return {
				...state,
				loading: false,
			};
		},
		setAdditionalDataPending(state: Report.State) {
			return {
				...state,
				loading: true,
			};
		},
		setAdditionalDataFailed(state: Report.State) {
			return {
				...state,
				loading: false,
			};
		},
		setAdditionalDataSuccess(
			state: Report.State,
			action: PayloadAction<
				Report.Additional & { Attachments: Report.Attachment[]; Comments: Report.Comment[] }
			>
		) {
			return {
				...state,
				loading: false,
				additionalData: {
					...state.additionalData,
					[action.payload.Id]: {
						attachments: action.payload.Attachments,
						comments: action.payload.Comments,
					},
				},
			};
		},
		setSelectedYear(state: Report.State, action: PayloadAction<number>) {
			return {
				...state,
				selectedYear: action.payload,
			};
		},
		setAvailableYears(state: Report.State, action: PayloadAction<any>) {
			return {
				...state,
				loading: false,
				availableYears: action.payload,
			};
		},
		setIsPending(state: Report.State) {
			state.loading = true;
		},
		setHasLoaded(state: Report.State) {
			state.loading = false;
		},
		clearReports(state: Report.State) {
			state.reportData = [];
		},
	},
});

/**
 ** Export Actions
 */
export const {
	setPersonalisedTargetsPending,
	setPersonalisedTargetsSuccess,
	setPersonalisedTargetsFailed,
	setMyReportsPending,
	setMyReportsSuccess,
	setMyReportsFailed,
	setReportDataPending,
	setReportDataSuccess,
	setReportDataFailed,
	setAdditionalPending,
	setAdditionalSuccess,
	setAdditionalFailed,
	setSelectedYear,
	setAdditionalDataPending,
	setAdditionalDataFailed,
	setAdditionalDataSuccess,
	setAvailableYears,
	setIsPending,
	setHasLoaded,
	clearReports,
} = report.actions;

/**
 ** Export Selectors
 */
// get personalised targets
export const personalisedTargets = ({ report }: RootState) => report.personalisedTargets?.[0] ?? {};

export const getSelectedYear = ({ report }: RootState): Report.Option => ({
	label: report.selectedYear ? academicYearStandardFormat(report.selectedYear) : '',
	value: report.selectedYear ?? '',
});
export const getAvailableYears = ({ report }: RootState): Report.Option[] =>
	report.availableYears.map((year) => ({ label: academicYearStandardFormat(year), value: year }));

export const getMyReports = ({ report }: RootState) => report.myReports;
export const getMyReportsForSelectedYear = ({ report }: RootState) =>
	report.myReports
		.filter((x) => x.AcademicYear === report.selectedYear)
		.sort((a, b) => {
			if (a.Name < b.Name) {
				return -1;
			}
			if (a.Name > b.Name) {
				return 1;
			}
			return 0;
		});
export const getReportData = (id: number) => ({ report }: RootState) => report.reportData[id];

export const getAdditional = ({ report }: RootState) => report.additional;
export const getAdditionalData = (id: number) => ({ report }: RootState) =>
	report.additionalData[id];

export const isLoading = ({ report }: RootState) => report.loading;

export const isLoadingMyReports = ({ report }: RootState) => report.loadingMyReports;

/**
 ** Export Reducers
 */
export default report.reducer;

/**
 * Export Thunks
 */
// thunk that gets personalised targets from yeti reporting
export const fetchPersonalisedTargets = (): AppThunk => async (dispatch, getState) => {
	dispatch(setPersonalisedTargetsPending());

	// Get all target setting gradepoints from state
	const targetSettingGradepoints =
		getState().context.reportContext.currentDataset?.targetSettingGradepoints ?? [];

	getPersonalisedTargets(targetSettingGradepoints)
		.then((response: any) => {
			let personalisedTargets = [];
			if (response && response.reports) {
				personalisedTargets = response.reports.map((x: any) => x);
			}
			dispatch(setPersonalisedTargetsSuccess(personalisedTargets));
		})
		.catch(() => {
			dispatch(setPersonalisedTargetsFailed());
		});
};

//thunk to get reports and available academic years from api
export const fetchMyReportsThunk = (): AppThunk => async (dispatch) => {
	dispatch(setMyReportsPending());

	fetchMyReports()
		.then((result) => {
			dispatch(setMyReportsSuccess(result));
		})
		.catch(() => {
			dispatch(setMyReportsFailed());
		});
};

//thunk to get report for id and previous reports
export const fetchReportDataThunk = (id: number): AppThunk => async (dispatch) => {
	dispatch(setReportDataPending());

	fetchReportData(id)
		.then((result) => {
			dispatch(setReportDataSuccess(result));
		})
		.catch(() => {
			dispatch(setReportDataFailed());
		});
};

export const fetchAdditionalThunk = (): AppThunk => (dispatch) => {
	dispatch(setAdditionalPending());

	fetchAdditional()
		.then((result) => {
			dispatch(setAdditionalSuccess(result));
		})
		.catch(() => {
			dispatch(setAdditionalFailed());
		});
};

export const fetchAdditionalDataThunk = (id: number): AppThunk => async (dispatch) => {
	dispatch(setAdditionalDataPending());

	fetchAdditionalData(id)
		.then((result) => {
			dispatch(setAdditionalDataSuccess(result));
		})
		.catch(() => {
			dispatch(setAdditionalDataFailed());
		});
};
