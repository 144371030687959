import { SagaIterator } from 'redux-saga';
import { call, cancel, put, select } from 'redux-saga/effects';
import { getConnectPage } from '../../../../utils/sagaHelpers';
import {
	getPasscode,
	hasSecretBeenDispatchedToDb,
	cancelActiveSagaFlow,
} from '../../../../sharedSelectors/mfa/common';
import { MfaType } from '../../../../utils/mfa';
import { authenticatePasscodeFlow, validatePasscodeFlow } from './validatePasscodeSaga';
import { activateMfaTypeFlow } from './activateMfaTypeSaga';
import { getToken } from 'features/app/redux/context';
import { triggerOneTimePasswordAuthentication } from '../mfa/slice';

/**
 * Saga that validates the one time password
 */
export function* oneTimePasswordFlow(): SagaIterator<void> {
	//A what page are we hitting this flow from
	const connectPage = yield call(getConnectPage);

	//Has the user decided to cancel mid way through the saga loop
	const cancelSagaStatus = yield select(cancelActiveSagaFlow(connectPage));

	//If user has canceled the flow
	if (cancelSagaStatus) {
		//Reset cancel flag so user can restart flow at discretion
		yield put({
			type: `${connectPage}/setCancelFlow`,
			payload: false,
		});

		//bail out of flow
		yield cancel();
	}

	//Have we already started the saga and have dispatched the request to get a QR code
	const hasSecretBeenSent = yield select(
		hasSecretBeenDispatchedToDb(connectPage, MfaType.ONE_TIME_PASSWORD)
	);

	//If we are in the Manage Account and we HAVE NOT dispatched the secret
	//ie this is the first time the saga is looping
	if (connectPage === 'mfaSetup' && !hasSecretBeenSent) {
		//Pass in true to activate the Mfa type in the db
		yield call(
			activateMfaTypeFlow,
			MfaType.ONE_TIME_PASSWORD,
			true,
			'OneTimePasswordAuthentication'
		);
	}

	//Get the passcode the user has just inputted - this can be undefined as the saga might beat them too it
	//will need to be cleared after we have set it to the backend
	const passcode = yield select(getPasscode(connectPage, MfaType.ONE_TIME_PASSWORD));

	//If the passcode isnt set then the user has not input it yet so cancel and break out of the saga
	if (passcode === undefined) {
		//the password hasnt been updated so retrigger the saga
		yield put(triggerOneTimePasswordAuthentication);

		//bail out
		yield cancel();
	}

	//Is the a set up or login attempt
	if (connectPage === 'mfaSetup') {
		//if this is just a step up validate that is has gone ahead
		yield call(
			validatePasscodeFlow,
			MfaType.ONE_TIME_PASSWORD,
			passcode,
			'OneTimePasswordAuthentication'
		);

		//Call Mfa set up saga
		yield put({
			type: `mfa/startSetUp`,
		});
	} else {
		//if its not a set up its the real deal and we need to authenticate it
		const token = yield select(getToken);
		yield call(
			authenticatePasscodeFlow,
			'OneTimePasswordAuthentication',
			token,
			MfaType.ONE_TIME_PASSWORD,
			passcode
		);

		//retrigger the execute mfa auth saga
		yield put({
			type: 'context/triggerSagaLoop',
		});
	}
}
