/**
 * Returns a style based on the students grade proximity, which is generated server side
 * @param status
 * @returns
 */
export const targetProximity = (status: string) => {
	switch (status) {
		case 'Above': {
			return { bg: 'thermometer.tRed.4', color: 'UI.white' };
		}
		case 'Hit': {
			return { bg: 'thermometer.tRed.4', color: 'UI.white' };
		}
		case 'HalfHit': {
			return { bg: 'thermometer.tRed.1', color: 'thermometer.tRed.0' };
		}
		case 'OneBelow': {
			return { bg: 'UI.secondary.2', color: 'UI.black' };
		}
		case 'WellBelow': {
			return { bg: 'thermometer.tBlue.2', color: 'thermometer.tBlue.8' };
		}
		default:
			return { bg: 'UI.secondary.2', color: 'UI.black' };
	}
};
