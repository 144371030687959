import * as React from 'react';

import { Box } from 'basecamp';

/**
 ** Props
 */
type Props = {
	onSelectorClose: () => void;
};

const GradepointSelectorBase: React.FC<Props> = props => {
	/**
	 ** Render Component
	 */
	return (
		<>
			{/* Box element for positioning of the gp selector menu*/}
			<Box
				position="fixed"
				top={60}
				left={0}
				right={0}
				zIndex={999}
				dataTest="gradepointSelector"
				boxShadow="xxl"
			>
				{props.children}
			</Box>
			{/* Close on click overlay */}
			<Box
				position="fixed"
				top={0}
				right={0}
				bottom={0}
				left={0}
				bg="transparent"
				onClick={() => props.onSelectorClose()}
				zIndex={700}
			></Box>
		</>
	);
};

export default GradepointSelectorBase;
