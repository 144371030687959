import { setGroupAccessRecords, setSetupErrorMessage, setSetupLoading } from '..';
import { getGroupAccessRecords } from '../../../../../api/groupsSetupAPI';

export const fetchGroupAccessRecords = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	try {
		const res = await getGroupAccessRecords();
		dispatch(setGroupAccessRecords(res.ResponseObject));
	} catch (err) {
		dispatch(setSetupErrorMessage(err.message));
	}
};
