import * as React from 'react';
import { FunctionComponent, Fragment } from 'react';

import { manageUsers } from '../routes';
import { SecondaryNavGroup, SecondaryNavLink } from 'basecamp';
import { getClient } from 'features/app/redux/context';
import { useSelector } from 'react-redux';

const ManageUsersSidebar: FunctionComponent = () => {
	const clients = useSelector(getClient);
	const hasGroupAccess = clients?.hasGroupAccess;
	const userLinks: SidebarLinks[] = manageUsers(hasGroupAccess);
	/**
	 ** Render Component
	 */
	return (
		<Fragment>
			{userLinks.map((area, i) => {
				return (
					<SecondaryNavGroup key={i} title={area.title} dataTest="manageUsersSidebarGroup">
						{area.links.map((links, j) => {
							if (!links.isVisible) {
								return null;
							}

							return (
								<SecondaryNavLink
									key={j}
									dataTest="manageUsersSidebarLink"
									withInternalUrl={links.withInternalUrl}
									withExternalUrl={links.withExternalUrl && links.withExternalUrl}
									andTarget={links.withExternalUrl && '_blank'}
									withIcon={links.icon}
									setAs="primary"
								>
									{links.link}
								</SecondaryNavLink>
							);
						})}
					</SecondaryNavGroup>
				);
			})}
		</Fragment>
	);
};

export default ManageUsersSidebar;
