/**
 * Selector for getting the acitve page slice
 * This allows use t re-use the same selectors for all pages
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';

/**
 * Gets the redux slice for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns Strategic Analysis state slice for the active page
 */
export const getSlice = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicGridData.State => {
	const slice = `strategicAnalysis${page}` as keyof RootState;

	return state[slice];
};

