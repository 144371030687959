import * as React from 'react';
import { FunctionComponent } from 'react';

import { Modal, Heading, Text } from 'basecamp';

import { translate } from '../../../utils/locale';

type Props = {
	setShowResetModal: React.Dispatch<React.SetStateAction<boolean>>;
	handleReset: () => void;
};

const ResetChangesModal: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<Modal
			setAs="small"
			close={() => props.setShowResetModal(false)}
			withActions={[
				{
					title: translate('manageUsers.modals.resetChangesModal.ACTION_TITLE') as string,
					type: 'error',
					onClick: () => props.handleReset(),
				},
			]}
			andBodyPadY={4}
			dataTest="resetChangesModal"
		>
			<Heading setAs="large" withColor="error" dataTest="resetChangesModalTitle">
				{translate('manageUsers.modals.resetChangesModal.CURRENT_CHANGES') as string}
			</Heading>
			<Text mb={3} dataTest="resetChangesModalBody">
				{translate('manageUsers.modals.resetChangesModal.DELETED_MESSAGE') as string}
			</Text>
		</Modal>
	);
};

export default ResetChangesModal;
