import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import flagsmith from 'flagsmith';
import { init as initApm } from '@elastic/apm-rum';
import { apm } from '@elastic/apm-rum';
import { StandardPage } from 'features/layouts';

import GetRoutes from '../../routes';
import { routes, Loader } from '../../routes/routeConfig';
import {
	isAuthenticated,
	fetchAppContext,
	requiresTermsAcceptance,
	getEmbargoPeriod,
	getContextEntitlements,
} from 'features/app/redux/context';
import MaintenancePage from './maintenance';
import { rtmSetupThunk } from '../rtmMessaging/redux';
import {
	getFeature,
	getInitialFlags,
	getInitialConfig,
	setLoadingFlags,
	getLoadingFlags,
	setBulkFlagsAndConfig,
} from './redux/features';
import ErrorBoundary from './errorBoundary';
import { withTransaction } from '@elastic/apm-rum-react';

// Theming
import { ThemeProvider, GlobalStyles, theme } from 'basecamp';
import { getTheme } from '../../utils/theming';
import { hasGroupAccess } from './redux/context';
import TermsAcceptance from 'features/app/termsAcceptance';
import {
	getPdfExportModalAcknowledgment,
	getPdfExportModalIsOpen,
} from '../app/redux/notificationModals';
import PDFExportModal from '../app/pdfExportModal';
import { useHistory, useLocation } from 'react-router-dom';

type Mode = 'connect' | 'summit';

const App: React.FunctionComponent = () => {
	const dispatch = useDispatch();

	/**
	 ** Handle Selectors
	 */
	const maintenance = useSelector(getFeature('maintenance_mode'));
	const isGroup = useSelector(hasGroupAccess);
	// Check if the user is authenticated
	const isUserAuthenticated = useSelector(isAuthenticated);
	const termsAcceptance = useSelector(requiresTermsAcceptance);
	const loadingFlags = useSelector(getLoadingFlags);
	const entitlements = useSelector(getContextEntitlements);
	const hasEmbargoChanged = useSelector(getFeature('embargo_feature'));

	const history = useHistory();

	const location = useLocation();
	const page = location.pathname.split('/').pop() as string;
	/**
	 * Handle Export modal
	 */
	//PDF MODAL SELECTORS
	const canViewNewExports = useSelector(getFeature('home_page_export_modal'));
	const showExportModalAck = useSelector(getPdfExportModalAcknowledgment);
	const showExportModalIsOpen = useSelector(getPdfExportModalIsOpen);
	const showModal = !canViewNewExports
		? false
		: showExportModalAck
		? false
		: showExportModalIsOpen
		? false
		: true;

	// TODO: handle cookies being disabled

	// Configure APM
	useEffect(() => {
		if (!window.environmentName || !window.apmServer) {
			return;
		}

		initApm({
			// Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
			serviceName: 'connect-interactive',

			// Set custom APM Server URL
			serverUrl: window.apmServer,

			// Set the service version (required for source map feature)
			serviceVersion: window.serviceVersion,

			// Set the service environment
			environment: window.environmentName,

			// ignore transactions including these URLs
			// see: https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html#ignore-transactions
			ignoreTransactions: [/.*signalr\.net.*/, /.*appcues\.com.*/, /.*googletagmanager\.com.*/],

			// distributed tracing options
			pageLoadTraceId: window.elasticPageLoadTraceId,
			pageLoadSpanId: window.elasticPageLoadSpanId,
		});
	}, []);

	// Configure Flagsmith
	useEffect(() => {
		if (window.flagsmithAPIKey && window.flagsmithServer) {
			flagsmith
				.init({
					api: window.flagsmithServer,
					environmentID: window.flagsmithAPIKey,
					defaultFlags: getInitialFlags(),

					/*
						Prevent initial fetch of flags until the user has been identified
						Flagsmith will automatically get updated flags when flagsmith.identify() is called
						as part of connectctx call response handling

						If connectctx fails, we manually call flagsmith.getFlags to update the flags
					 */
					preventFetch: true,

					onChange: (_oldFlags, params) => {
						if (params.flagsChanged) {
							/*
							When remote config has changed, get all initial flags & config and loop over
							them, resetting each one in reduce with either the matching remote config,
							or the initial value (in the remote config has been removed)
						 */

							const flags = flagsmith.getAllFlags();

							dispatch(
								setBulkFlagsAndConfig({
									flags: Object.entries(getInitialFlags()).reduce((config, [id, { enabled }]) => {
										return {
											...config,
											[id]: flags[id]?.enabled ?? enabled,
										};
									}, {}),

									config: Object.entries(getInitialConfig()).reduce((config, [id, { value }]) => {
										return {
											...config,
											[id]: flags[id]?.value ?? value,
										};
									}, {}),
								})
							);
						}
					},
				})
				.then(() => {
					flagsmith.startListening(300000); // poll every 5 minutes
				})
				.catch((err) => {
					console.error(err.detail || err.message || err);

					if (apm.isActive()) {
						apm.captureError(err);
					}
				});
		}

		dispatch(setLoadingFlags(false));
	}, []);

	useEffect(() => {
		if (hasEmbargoChanged) {
			dispatch(getEmbargoPeriod());
		}
	}, [entitlements, hasEmbargoChanged]);

	useEffect(() => {
		if (!isUserAuthenticated) {
			dispatch(fetchAppContext());
			//If the user is not authenticated, and we are half way through mfa push them back to the login
			if (page === 'mfaValidation') {
				history.push('/auth/login');
			}
		}
	}, [isUserAuthenticated]);

	let rtmSetupHasRun = false;
	useEffect(() => {
		if (!rtmSetupHasRun && isUserAuthenticated) {
			rtmSetupHasRun = true;
			dispatch(rtmSetupThunk());
		}
		return;
	}, [isUserAuthenticated]);

	const [mode, setMode] = useState<Mode>('connect');

	useEffect(() => {
		if (!!isGroup) {
			setMode('summit');

			return;
		}

		setMode('connect');
	}, [isGroup]);

	/**
	 ** Render Component and Routes
	 */
	let component = null;

	if (loadingFlags) {
		component = Loader();
	} else if (maintenance) {
		component = <MaintenancePage />;
	}

	return (
		<ThemeProvider getTheme={getTheme(mode, theme)}>
			<GlobalStyles />
			{/* Catch all components, such as loaders, V2 warning or maintenance */}
			{component}

			{termsAcceptance && <TermsAcceptance />}

			{showModal && isUserAuthenticated && <PDFExportModal />}

			{/* App */}
			{!component && isUserAuthenticated && (
				<ErrorBoundary>
					<StandardPage>
						<GetRoutes routes={routes} />
					</StandardPage>
				</ErrorBoundary>
			)}

			{/* Auth */}
			{!component && !isUserAuthenticated && <GetRoutes routes={routes} />}
		</ThemeProvider>
	);
};

export default withTransaction('App', 'component')(App);
