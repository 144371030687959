import { PayloadAction } from '@reduxjs/toolkit';
import { AnalysisPages } from 'features/strategicAnalysis/enums';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { call, put, select, all, spawn } from 'redux-saga/effects';
import {
	getAppliedDefaultExamLevels,
	getSelectedDefaultComparisons,
	setSessionDefaults,
	setStrategicLoading,
} from '..';
import { fetchAllComparisonRows } from './comparisonDataFlow';
import { fetchGridData } from './mainGridDataFlow';
import { History } from 'history';
import { handleThermometerChange } from './thermometerDataFlow';

export function* startSession(
	action: PayloadAction<{
		history: History<unknown>;
	}>
) {
	// 1. set loading to true
	yield put(setStrategicLoading(true));

	// 2. set the session defaults based user choice on summary page
	yield put(setSessionDefaults());

	// 3. fetch main grid (all rows) data
	const appliedExamLevels: StrategicAnalysis.ExamLevelOption = yield select(
		getAppliedDefaultExamLevels
	);
	const tab: { examLevel: StrategicAnalysis.ExamLevels; index: number } = {
		examLevel: appliedExamLevels[0].value,
		index: 0,
	};

	// Call the API - this a seperate saga flow - see mainGridDataFlow.ts
	// And call the thermometer flow - see thermometerDataFlow.ts
	yield all([
		call(fetchGridData, AnalysisPages.OVERVIEW, tab),
		spawn(handleThermometerChange, AnalysisPages.OVERVIEW, tab.examLevel),
	]);

	// 4. If the user has applied some comparisons on the summary page fetch those too
	const selectedComparisons: StrategicAnalysis.ComparisonOption = yield select(
		getSelectedDefaultComparisons
	);

	if (selectedComparisons.length > 0) {
		// Call the API - this a seperate saga flow - see comparisonDataFlow.ts
		yield call(fetchAllComparisonRows, AnalysisPages.OVERVIEW, tab);
	}

	// 5. set loading to false
	yield put(setStrategicLoading(false));

	// 6. redirect the user to the overview page
	action.payload.history.push('/strategic-analysis/Overview');
}
