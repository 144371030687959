import * as React from 'react';
import { FunctionComponent } from 'react';

import { Switch, Flex } from 'basecamp';

type Props = {
	value: boolean;
	id: number;
	onClick: (id: number) => void;
};

const SwitchRenderer: FunctionComponent<Props> = ({ value, id, onClick }) => {
	return (
		<Flex setAs="row" withAlign="centerLeft" height="100%">
			<Switch isOn={value} setAs="accent" withSize="small" onClick={() => onClick(id)} />
		</Flex>
	);
};

export default SwitchRenderer;
