import { Context } from 'features/app/types';
import { setAllGraphComparisonDataSuccess, setGraphLoading, setChartComparisonsFailure } from '..';
import { getPerformanceMeasureChartsComparisons } from '../../../../api/ks5PerformanceMeasuresAPI';
import { GetKeyValueFromComparisonOption } from '../../utils';
import { PerformanceMeasuresTypes } from '../../../../types/enum';

export const getIBComparisonsChartsThunk = (
	GradePoints: Context.Gradepoint[],
	Comparisons: Ks5PerformanceMeasures.ComparisonOption
): AppThunk => async (dispatch) => {
	dispatch(setGraphLoading(true));

	const formattedComparisons = GetKeyValueFromComparisonOption(Comparisons);
	try {
		const response = await getPerformanceMeasureChartsComparisons(
			GradePoints,
			PerformanceMeasuresTypes.IB,
			formattedComparisons
		);
		const data = response.responseObject;
		dispatch(setAllGraphComparisonDataSuccess(data.comparisons));
		dispatch(setGraphLoading(false));
	} catch (ex) {
		dispatch(
			setChartComparisonsFailure(
				`The following error occurred whilst fetching the overview data - ${ex}`
			)
		);
	}
};
