import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//Initial State
const initialState: SubjectLookUp.State = {
	bedrockSubjects: undefined,
	errorMessage: undefined,
};

//Create Slice
const subjectLookUpStore = createSlice({
	name: 'subjectLookUpStore',
	initialState,
	reducers: {
		setBedrockSubjectLookUp(state: SubjectLookUp.State, action: PayloadAction<SubjectBedrock[]>) {
			return {
				...state,
				bedrockSubjects: action.payload,
				errorMessage: undefined,
			};
		},
		setErrorMessage(state: SubjectLookUp.State, action: PayloadAction<string>) {
			return {
				...state,
				errorMessage: action.payload,
			};
		},
	},
});

//Export Actions
export const { setBedrockSubjectLookUp, setErrorMessage } = subjectLookUpStore.actions;

//Export Reducer
export default subjectLookUpStore.reducer;
