import * as React from 'react';
import { FunctionComponent, Fragment, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import PageWithSidebar from 'features/layouts/pageWithSidebar';
import PrimaryNavigation from 'features/primaryNavigation';
import ManageUsersSidebar from './components';
import { UnsavedChangeModal, ResetChangesModal } from './components';

import GetRoutes from '../../routes';

import { ContentBlock } from 'basecamp';
import { listUsersThunk } from './redux/users';
import { useDispatch } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';

type Props = {
	routes: Routes.Route[];
};

// @ts-ignore
export const ManageUsersContext = createContext<ManageUsers.ManageUsersContext>();

const ManageUsers: FunctionComponent<Props> = (props) => {
	/**
	 ** Handle State
	 */
	const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [showResetModal, setShowResetModal] = useState<boolean>(false);
	const [nextRoute, setNextRoute] = useState<string>('');
	const [resetData, setResetData] = useState<boolean>(false);

	const dispatch = useDispatch();
	/**
	/**
	 * Pass state values via context
	 * Values are available to all page components
	 */
	const ManageUsersContextValues: ManageUsers.ManageUsersContext = {
		saveDisabled,
		setSaveDisabled,
		showResetModal,
		setShowResetModal,
		resetData,
		setResetData,
		setShowUnsavedModal,
		showUnsavedModal,
	};

	/**
	 ** Handle History
	 */
	const history = useHistory();
	// Block the route if user tries to leave without saving
	const unblock = history.block(({ pathname }): any => {
		if (!saveDisabled && !!pathname) {
			setShowUnsavedModal(true);
			setNextRoute(pathname);
		}

		return saveDisabled;
	});

	/**
	 ** Handle Navigation
	 */
	const handleNavigation = (): void => {
		setSaveDisabled(true);
		unblock();
		setShowUnsavedModal(false);
		history.push(nextRoute);
		setNextRoute('');
		setResetData(true);
		dispatch(listUsersThunk());
	};

	/**
	 ** Handle page reset
	 */
	const handleReset = () => {
		setShowResetModal(false);
		setResetData(true);
	};

	/**
	 ** Render Component
	 */
	return (
		<Fragment>
			{/* Navigation modal */}
			{showUnsavedModal && (
				<UnsavedChangeModal
					setShowModal={() => setShowUnsavedModal(false)}
					handleNavigation={() => handleNavigation()}
				/>
			)}

			{/* Reset modal */}
			{showResetModal && (
				<ResetChangesModal
					setShowResetModal={() => setShowResetModal(false)}
					handleReset={() => handleReset()}
				/>
			)}

			{/* Top content */}
			<ContentBlock py={1} dataTest="manageUsersPrimaryNav">
				<PrimaryNavigation />
			</ContentBlock>

			{/* Page Layout */}
			<ManageUsersContext.Provider value={ManageUsersContextValues}>
				<PageWithSidebar sidebarContent={<ManageUsersSidebar />} dataTest="manageUsers">
					<GetRoutes routes={props.routes} />
				</PageWithSidebar>
			</ManageUsersContext.Provider>
		</Fragment>
	);
};

export default withTransaction('Manage Users', 'component')(ManageUsers);
