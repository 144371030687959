/**
 * Exam Levels Selectors
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { appliedAndSelectedAreEqual } from 'features/strategicAnalysis/utils';
import { getSlice, getAppliedGradepoints } from './';
import { ExamLevels } from '../../../../types/enum';
import { createQualTypeFilter } from '../../../../utils/filters';

/**
 * Gets the default report level selected Exam Levels
 * @param state Redux state
 * @returns Array of selected Exam Levels
 */
export const getSelectedDefaultExamLevels = (state: RootState) => {
	return state.strategicAnalysis.selectedDefaultExamLevels;
};
/**
 * Gets the default report level applied Exam Levels
 * @param state Redux state
 * @returns Array of applied Exam Levels
 */
export const getAppliedDefaultExamLevels = (state: RootState) => {
	return state.strategicAnalysis.appliedDefaultExamLevels;
};

/**
 * Gets all the loaded exam level data
 * @param state redux state
 * @returns An object byId / allIds for the loaded exam level data
 */
export const getAllExamLevelData = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicGridData.ExamLevels | undefined => {
	const slice = getSlice(page)(state);

	return slice.examLevels;
};

/* Get All exam levels
 * @param Analysis page currently on
 * @param active examlevel
 * @param state Redux state
 * @returns Boolean
 * checks to see if data for specific page and level has been fetched
 */
export const getActiveExamLevelData = (
	page: StrategicAnalysis.AnalysisPage,
	examLevelId: number
) => (state: RootState): boolean | undefined => {
	const examLevels = getAllExamLevelData(page)(state);
	// TODO - Check the exam levels types looks like we have a mis match string vs number
	//@ts-ignore
	return examLevels?.allIds.includes(`${examLevelId}`);
};

/**
 * Gets the individual page level selected Exam Levels
 * @param state Redux state
 * @returns Array of selected Exam Levels
 */
export const getSelectedPageExamLevels = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.ExamLevelOption => {
	// The default report exam level(s) cannot be changed so include here
	const defaultExamLevels = getAppliedDefaultExamLevels(state);
	const pageExamLevels = state.strategicAnalysis.selectedPageExamLevels[page] ?? [];

	return Array.from(new Set([...defaultExamLevels, ...pageExamLevels]));
};

/**
 * Gets the individual page level applied Exam Levels
 * @param state Redux state
 * @returns Array of applied Exam Levels
 */
export const getAppliedPageExamLevels = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.ExamLevelOption => {
	const appliedPageExamLevels = state.strategicAnalysis.appliedPageExamLevels;

	return appliedPageExamLevels[page] ?? [];
};

/**
 * Determine if the exam levels have changed
 * @param page strategic analysis page
 * @returns boolean
 */
export const checkExamLevelStatesAreEqual = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): boolean => {
	const appliedExamLevels = getAppliedPageExamLevels(page)(state);
	const selectedExamLevels = getSelectedPageExamLevels(page)(state);

	return appliedAndSelectedAreEqual<{ label: string; value: number }>(
		appliedExamLevels,
		selectedExamLevels
	);
};

/**
 * Active exam level tab
 */
export const getActiveExamLevelTab = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.ActiveExamLevelTab | undefined => {
	return state.strategicAnalysis.activeExamLevelTab[page];
};

/**
 * Gets and array of all the loaded exam levels
 * @param state redux state
 * @returns An array of all the loaded exam levels
 */
export const getAllExamLevelIds = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.ExamLevels[] => {
	const data = getAllExamLevelData(page)(state);
	return data ? data.allIds : [];
};

/**
 * Gets the available exam levels from context
 * @param state redux state
 * @returns An array of all availble exam levels
 */
// All BTECs are merged for strategic analysis so we just need the BTEC option and Cert or 90Cred
const remove = [ExamLevels.BTEC90Cred, ExamLevels.BTECCert, ExamLevels.IB, ExamLevels.PreU];
export const getAvailableExamLevels = (state: RootState) => {
	return state.context.examLevels.filter(
		(examLevel) => !remove.includes(examLevel)
	) as StrategicAnalysis.ExamLevels[];
};

/**
 * Gets the exam levels for the applied gradepoint.
 * @param state redux state
 * @returns Array of available exam levels.
 * If any gradepoints exam level array is null we return all exam levels
 * as we cannot be 100% sure what exam levels are in the overall trend
 */
export const getExamLevelsInAppliedGradepointTrend = (state: RootState) => {
	const gradepoints = getAppliedGradepoints(state);
	const availableExamLevels = getAvailableExamLevels(state);
	const examLevelsInTrend = gradepoints.reduce((acc, curr, _, self) => {
		if (!self.length || !curr.examLevel) return availableExamLevels;

		return Array.from(new Set([...acc, ...curr.examLevel]));
	}, [] as StrategicAnalysis.ExamLevels[]);
	// TODO - sort this type error
	//@ts-ignore
	return examLevelsInTrend.filter((level) => !remove.includes(level));
};

/**
 * Gets a list of every applied exam level across the applied session
 * @param state redux state
 * @returns An array of all the distinct exam levels applied acroos every
 */
export const getSessionsAppliedExamLevels = (state: RootState) => {
	const appliedPageExamLevels = Object.values(state.strategicAnalysis.appliedPageExamLevels) ?? [];
	const appliedDefaultExamLevels =
		Object.values(state.strategicAnalysis.appliedDefaultExamLevels) ?? [];
	const allPageExamLevels = appliedPageExamLevels.flatMap((vals) => vals.map((val) => val.value));

	return allPageExamLevels.length > 0
		? Array.from(new Set(allPageExamLevels))
		: appliedDefaultExamLevels.length > 0
		? [appliedDefaultExamLevels[0].value]
		: [];
};

/**
 * Get the exam level options for the select components in strategic summary and filter bar
 * @param state Redux state
 * @returns Formatted array of exam level options
 */
export const getExamLevelOptions = (state: RootState) => {
	const applied = getAppliedDefaultExamLevels(state).map(
		(examLevel) => examLevel.value
	) as StrategicAnalysis.ExamLevels[];
	const examLevels = getExamLevelsInAppliedGradepointTrend(state);
	const filtered = examLevels.filter((el) => !applied.includes(el));
	// TODO - sort this type error
	//@ts-ignore
	return createQualTypeFilter(filtered);
};

/**
 * Gets the filtered select list for select components in strategic summary and filter bar
 * @param state
 * @returns
 */
export const getExamLevelOptionsStrategic = (state: RootState): Filters.QualType => {
	const applied = getAppliedDefaultExamLevels(state).map(
		(examLevel) => examLevel.value
	) as StrategicAnalysis.ExamLevels[];
	const examLevels = getExamLevelsInAppliedGradepointTrend(state);
	const filtered = examLevels.filter((el) => !applied.includes(el));

	return createQualTypeFilter(state.context.examLevels.filter((el) => filtered.includes(el)));
};
