import { setSetupLoading, setSetupErrorMessage, setSetupSchools } from '../';
import { getSchools } from '../../../../../api/groupsSetupAPI';

/**
 * Fetch Group Schools
 */
export const fetchGroupSchools = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	try {
		const res = await getSchools();

		dispatch(setSetupSchools(res.ResponseObject));
	} catch (err) {
		dispatch(setSetupErrorMessage(err.message));
	}
};
