import { fetchPriorAttainmentTypes } from '../../../../api/spoAPI';
import {
	setPriorAttainmentTypesPending,
	setPriorAttainmentTypesFailed,
	setPriorAttainmentTypesSuccess,
	getPriorAttainmentTypes,
} from '../';
/*
 **  Thunks
 */

/**
 * Fetches prior attainment type data if it hasn't already been loaded
 * @returns
 */
export const fetchPriorAttainmentTypesThunk = (): AppThunk => async (dispatch, getState) => {
	if (getPriorAttainmentTypes(getState()).length <= 0) {
		dispatch(setPriorAttainmentTypesPending());

		try {
			const response = await fetchPriorAttainmentTypes();
			if (response.HasError) {
				dispatch(setPriorAttainmentTypesFailed());
				console.error(`Error fetching Prior Attainment Types, ${response.Errors[0].Message}`);
			}

			dispatch(setPriorAttainmentTypesSuccess(response.ResponseObject));
		} catch {
			dispatch(setPriorAttainmentTypesFailed());
			console.error('Error fetching Prior Attainment Types');
		}
	}
};
