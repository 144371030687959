import { StrategicAnalysis } from '../types';
import { CountryEnum, ExamLevels } from '../../../types/enum';
import { Context } from 'features/app/types';
import { ConvertEnumToCountryAbbreviationWithJerseyOverride } from '../../../utils/country';

/**
 * Values for each report template
 */
export enum ReportTemplate {
	MAIN_PROVIDER = 1,
	GOVERNOR = 2,
	CUSTOM = 3,
}

/**
 * Main provider page ids by exam level
 */
//[key in StrategicAnalysis.ExamLevels]: number[]
type ReportPages = {
	[key in ReportTemplate]?: {
		[key in StrategicAnalysis.ExamLevels]?: number[];
	};
};

export const reportPages: ReportPages = {
	[ReportTemplate.MAIN_PROVIDER]: {
		1: [1, 2, 4, 5, 9, 10, 13, 14, 15],
		2: [1, 2, 4, 5, 9, 10, 13, 14, 15],
		4: [17, 16, 2, 18, 20, 19, 7, 8, 9, 21, 10, 22, 13, 14, 15],
		8: [9, 15],
		16: [9, 15],
		32: [1, 23, 5, 9, 11, 12, 14, 15],
	},
	[ReportTemplate.GOVERNOR]: {
		1: [1, 4, 9, 15],
		2: [1, 4, 9, 15],
		4: [17, 18, 9, 15],
		8: [9, 15],
		16: [], // BTEC 90 Cred not supported for governor reports
		32: [1, 23, 9, 15],
	},
	[ReportTemplate.CUSTOM]: {
		1: [1, 2, 4, 5, 9, 10, 13, 14, 15],
		2: [1, 2, 4, 5, 9, 10, 13, 14, 15],
		4: [17, 16, 2, 18, 20, 19, 7, 8, 9, 21, 10, 22, 13, 14, 15],
		8: [9, 15],
		16: [9, 15],
		32: [1, 23, 5, 9, 11, 12, 14, 15],
	},
};

/**
 * Copy of the harded report definitions from the old Strategic Analysis
 */
type ReportDefs = {
	overallReports: ReportDef[];
};

type ReportDef = {
	id: number;
	exportSettings: {
		[ExamLevels.A]?: ExamLevelSettings;
		[ExamLevels.AS]?: ExamLevelSettings;
		[ExamLevels.BTEC]?: ExamLevelSettings;
		[ExamLevels.BTECCert]?: ExamLevelSettings;
		[ExamLevels.BTEC90Cred]?: ExamLevelSettings;
		[ExamLevels.KS4]?: ExamLevelSettings;
	};
	name: string;
	numberOfGPs: number;
	examLevels: ExamLevels[];
};

type ExamLevelSettings = {
	[CountryEnum.International]?: string;
	[CountryEnum.England]?: string;
	[CountryEnum.Wales]?: string;
	[CountryEnum.Jersey]?: string;
	reportEngineRef?: string;
	params: {
		key: string;
		value: string;
	}[];
	name: string;
};

export const reportDefinitions: ReportDefs = {
	overallReports: [
		{
			id: 15,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'Wal/KS5/RawResults',
					[CountryEnum.England]: 'KS5/RawResults',
					[CountryEnum.Wales]: 'Wal/KS5/RawResults',
					[CountryEnum.Jersey]: 'KS5/RawResults',
					reportEngineRef: 'RawResults',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'Wal/KS5/RawResults',
					[CountryEnum.England]: 'KS5/RawResults',
					[CountryEnum.Wales]: 'Wal/KS5/RawResults',
					[CountryEnum.Jersey]: 'KS5/RawResults',
					reportEngineRef: 'RawResults',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'Wal/KS5/RawResults',
					[CountryEnum.England]: 'KS5/RawResults',
					[CountryEnum.Wales]: 'Wal/KS5/RawResults',
					[CountryEnum.Jersey]: 'KS5/RawResults',
					reportEngineRef: 'RawResults',
					params: [],
					name: '',
				},
				[ExamLevels.BTECCert]: {
					[CountryEnum.International]: 'Wal/KS5/RawResults',
					[CountryEnum.England]: 'KS5/RawResults',
					[CountryEnum.Wales]: 'Wal/KS5/RawResults',
					[CountryEnum.Jersey]: 'KS5/RawResults',
					reportEngineRef: 'RawResults',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC90Cred]: {
					[CountryEnum.International]: 'KS5/RawResults',
					[CountryEnum.England]: 'KS5/RawResults',
					[CountryEnum.Wales]: 'KS5/RawResults',
					[CountryEnum.Jersey]: 'KS5/RawResults',
					reportEngineRef: 'RawResults',
					params: [],
					name: '',
				},
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'KS4/RawResults',
					[CountryEnum.England]: 'KS4/RawResults',
					[CountryEnum.Wales]: 'KS4/RawResults',
					[CountryEnum.Jersey]: 'KS4/RawResults',
					reportEngineRef: 'RawResults',
					params: [],
					name: '',
				},
			},
			name: 'Attainment Profile',
			numberOfGPs: 1,
			examLevels: [
				ExamLevels.A,
				ExamLevels.AS,
				ExamLevels.BTEC,
				ExamLevels.BTECCert,
				ExamLevels.BTEC90Cred,
				ExamLevels.KS4,
			],
		},
		{
			id: 1,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'Wal/A/ProviderStrategicOverview',
					[CountryEnum.England]: 'Eng/A/ProviderStrategicOverview',
					[CountryEnum.Wales]: 'Wal/A/ProviderStrategicOverview',
					[CountryEnum.Jersey]: 'Eng/A/ProviderStrategicOverview',
					reportEngineRef: 'StrategicOverview',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'Wal/AS/ProviderStrategicOverview',
					[CountryEnum.England]: 'Eng/AS/ProviderStrategicOverview',
					[CountryEnum.Wales]: 'Wal/AS/ProviderStrategicOverview',
					[CountryEnum.Jersey]: 'Eng/AS/ProviderStrategicOverview',
					reportEngineRef: 'StrategicOverview',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'Wal/BTEC/ProviderStrategicOverview',
					[CountryEnum.England]: 'Eng/BTEC/ProviderStrategicOverview',
					[CountryEnum.Wales]: 'Wal/BTEC/ProviderStrategicOverview',
					[CountryEnum.Jersey]: 'Eng/BTEC/ProviderStrategicOverview',
					reportEngineRef: 'StrategicOverview',
					params: [],
					name: '',
				},
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'Eng/KS4/ProviderStrategicOverview',
					[CountryEnum.England]: 'Eng/KS4/ProviderStrategicOverview',
					[CountryEnum.Wales]: 'Wal/KS4/ProviderStrategicOverview',
					[CountryEnum.Jersey]: 'Eng/KS4/ProviderStrategicOverview',
					reportEngineRef: 'StrategicOverview',
					params: [],
					name: '',
				},
			},
			name: 'Strategic overview',
			numberOfGPs: 4,
			examLevels: [ExamLevels.A, ExamLevels.AS, ExamLevels.BTEC, ExamLevels.KS4],
		},
		{
			id: 17,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'Wal/BTEC/ProviderStrategicOverview/2016Report.cshtml',
					[CountryEnum.England]: 'Eng/BTEC/ProviderStrategicOverview/2016Report.cshtml',
					[CountryEnum.Wales]: 'Wal/BTEC/ProviderStrategicOverview/2016Report.cshtml',
					[CountryEnum.Jersey]: 'Eng/BTEC/ProviderStrategicOverview/2016Report.cshtml',
					reportEngineRef: 'StrategicOverview',
					params: [
						{
							key: 'BTECType',
							value: '2016',
						},
					],
					name: '',
				},
			},
			name: '2016 - Strategic overview',
			numberOfGPs: 4,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 16,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'Wal/BTEC/ProviderStrategicOverview/2010Report.cshtml',
					[CountryEnum.England]: 'Eng/BTEC/ProviderStrategicOverview/2010Report.cshtml',
					[CountryEnum.Wales]: 'Wal/BTEC/ProviderStrategicOverview/2010Report.cshtml',
					[CountryEnum.Jersey]: 'Eng/BTEC/ProviderStrategicOverview/2010Report.cshtml',
					reportEngineRef: 'StrategicOverview',
					params: [
						{
							key: 'BTECType',
							value: '2010',
						},
					],
					name: '',
				},
			},
			name: '2010 - Strategic overview',
			numberOfGPs: 4,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 2,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'Wal/KS5/BandedByPriorAttainmentCurrentYear',
					[CountryEnum.England]: 'Eng/KS5/BandedByPriorAttainmentCurrentYear',
					[CountryEnum.Wales]: 'Wal/KS5/BandedByPriorAttainmentCurrentYear',
					[CountryEnum.Jersey]: 'Eng/KS5/BandedByPriorAttainmentCurrentYear',
					reportEngineRef: 'BandedByAbilityCurrentYear',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'Wal/KS5/BandedByPriorAttainmentCurrentYear',
					[CountryEnum.England]: 'Eng/KS5/BandedByPriorAttainmentCurrentYear',
					[CountryEnum.Wales]: 'Wal/KS5/BandedByPriorAttainmentCurrentYear',
					[CountryEnum.Jersey]: 'Eng/KS5/BandedByPriorAttainmentCurrentYear',
					reportEngineRef: 'BandedByAbilityCurrentYear',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]:
						'Wal/BTEC/BandedByPriorAttainmentCurrentYear/2010Report.cshtml',
					[CountryEnum.England]: 'Eng/BTEC/BandedByPriorAttainmentCurrentYear/2010Report.cshtml',
					[CountryEnum.Wales]: 'Wal/BTEC/BandedByPriorAttainmentCurrentYear/2010Report.cshtml',
					[CountryEnum.Jersey]: 'Eng/BTEC/BandedByPriorAttainmentCurrentYear/2010Report.cshtml',
					reportEngineRef: 'BandedByAbilityCurrentYear',
					params: [
						{
							key: 'BTECType',
							value: '2010',
						},
					],
					name: 'Banded by prior attainment - Current year',
				},
			},
			name: 'Banded by prior attainment - Current year',
			numberOfGPs: 1,
			examLevels: [ExamLevels.A, ExamLevels.AS, ExamLevels.BTEC],
		},
		{
			id: 3,
			exportSettings: {
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'AS/MustardAndAbility',
					[CountryEnum.England]: 'AS/MustardAndAbility',
					[CountryEnum.Wales]: 'AS/MustardAndAbility',
					[CountryEnum.Jersey]: 'AS/MustardAndAbility',
					reportEngineRef: 'MustardAndAbility',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/MustardAndAbility/2010ReportWales.cshtml',
					[CountryEnum.England]: 'BTEC/MustardAndAbility/2010Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/MustardAndAbility/2010ReportWales.cshtml',
					[CountryEnum.Jersey]: 'BTEC/MustardAndAbility/2010Report.cshtml',
					reportEngineRef: 'MustardAndAbility',
					params: [
						{
							key: 'BTECType',
							value: '2010',
						},
					],
					name: '2010 - Banded by prior attainment',
				},
			},
			name: 'BBPA - Multi GP summary',
			numberOfGPs: 4,
			examLevels: [ExamLevels.AS, ExamLevels.BTEC],
		},
		{
			id: 18,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'Wal/BTEC/BandedPointsPerSubject',
					[CountryEnum.England]: 'Eng/BTEC/BandedPointsPerSubject',
					[CountryEnum.Wales]: 'Wal/BTEC/BandedPointsPerSubject',
					[CountryEnum.Jersey]: 'Eng/BTEC/BandedPointsPerSubject',
					reportEngineRef: 'BandedPointsPerSubject',
					params: [],
					name: '',
				},
			},
			name: 'BBPA - Multi GP summary',
			numberOfGPs: 4,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 4,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'Wal/KS5/BandedOverTime',
					[CountryEnum.England]: 'Eng/KS5/BandedOverTime',
					[CountryEnum.Wales]: 'Wal/KS5/BandedOverTime',
					[CountryEnum.Jersey]: 'Eng/KS5/BandedOverTime',
					reportEngineRef: 'BandedOverTime',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'Wal/KS5/BandedOverTime',
					[CountryEnum.England]: 'Eng/KS5/BandedOverTime',
					[CountryEnum.Wales]: 'Wal/KS5/BandedOverTime',
					[CountryEnum.Jersey]: 'Eng/KS5/BandedOverTime',
					reportEngineRef: 'BandedOverTime',
					params: [],
					name: '',
				},
			},
			name: 'BBPA - Multi-GP summary',
			numberOfGPs: 4,
			examLevels: [ExamLevels.A, ExamLevels.AS],
		},
		{
			id: 23,
			exportSettings: {
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'Eng/KS4/BandedOverTime',
					[CountryEnum.England]: 'Eng/KS4/BandedOverTime',
					[CountryEnum.Wales]: 'Wal/KS4/BandedOverTime',
					[CountryEnum.Jersey]: 'Eng/KS4/BandedOverTime',
					reportEngineRef: 'KS4.BandedOverTime',
					params: [],
					name: '',
				},
			},
			name: 'BBPA - Multi-GP summary',
			numberOfGPs: 4,
			examLevels: [ExamLevels.KS4],
		},
		{
			id: 5,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'Common/OverallSubjectVAS',
					[CountryEnum.England]: 'Common/OverallSubjectVAS',
					[CountryEnum.Wales]: 'Common/OverallSubjectVAS',
					[CountryEnum.Jersey]: 'Common/OverallSubjectVAS',
					reportEngineRef: 'OverallSubjectVAS',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'Common/OverallSubjectVAS',
					[CountryEnum.England]: 'Common/OverallSubjectVAS',
					[CountryEnum.Wales]: 'Common/OverallSubjectVAS',
					[CountryEnum.Jersey]: 'Common/OverallSubjectVAS',
					reportEngineRef: 'OverallSubjectVAS',
					params: [],
					name: '',
				},
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'KS4/OverallSubjectVAS',
					[CountryEnum.England]: 'KS4/OverallSubjectVAS',
					[CountryEnum.Wales]: 'KS4/OverallSubjectVAS',
					[CountryEnum.Jersey]: 'KS4/OverallSubjectVAS',
					reportEngineRef: 'OverallSubjectVAS',
					params: [],
					name: '',
				},
			},
			name: 'Quality indicator',
			numberOfGPs: 1,
			examLevels: [ExamLevels.A, ExamLevels.AS, ExamLevels.KS4],
		},
		{
			id: 6,
			exportSettings: {
				[ExamLevels.KS4]: {
					[CountryEnum.England]: 'KS4/Alps8',
					reportEngineRef: 'Alps8',
					params: [],
					name: '',
				},
			},
			name: 'QI8',
			numberOfGPs: 1,
			examLevels: [ExamLevels.KS4],
		},
		{
			id: 20,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/CorrectedOverallSubjectVas/2016Report.cshtml',
					[CountryEnum.England]: 'BTEC/CorrectedOverallSubjectVas/2016Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/CorrectedOverallSubjectVas/2016Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/CorrectedOverallSubjectVas/2016Report.cshtml',
					reportEngineRef: 'CorrectedQIReport',
					params: [
						{
							key: 'BTECType',
							value: '2016',
						},
					],
					name: '2016 - Quality Indicator',
				},
			},
			name: '2016 - Quality Indicator',
			numberOfGPs: 1,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 19,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/SubjectCorrectionFactor/2016Report.cshtml',
					[CountryEnum.England]: 'BTEC/SubjectCorrectionFactor/2016Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/SubjectCorrectionFactor/2016Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/SubjectCorrectionFactor/2016Report.cshtml',
					reportEngineRef: 'SubjectCorrectionFactor',
					params: [
						{
							key: 'BTECType',
							value: '2016',
						},
					],
					name: '',
				},
			},
			name: '2016 - Quality Indicator curriculum balance factor',
			numberOfGPs: 1,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 7,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/CorrectedOverallSubjectVas/2010Report.cshtml',
					[CountryEnum.England]: 'BTEC/CorrectedOverallSubjectVas/2010Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/CorrectedOverallSubjectVas/2010Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/CorrectedOverallSubjectVas/2010Report.cshtml',
					reportEngineRef: 'CorrectedOverallSubjectVas',
					params: [
						{
							key: 'BTECType',
							value: '2010',
						},
					],
					name: '2010 - Calculation of provider value added score',
				},
			},
			name: '2010 - Calculation of provider value added score',
			numberOfGPs: 1,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 8,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/SubjectCorrectionFactor/2010Report.cshtml',
					[CountryEnum.England]: 'BTEC/SubjectCorrectionFactor/2010Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/SubjectCorrectionFactor/2010Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/SubjectCorrectionFactor/2010Report.cshtml',
					reportEngineRef: 'SubjectCorrectionFactor',
					params: [
						{
							key: 'BTECType',
							value: '2010',
						},
					],
					name: '',
				},
			},
			name: '2010 - Provider value added score curriculum balance factor',
			numberOfGPs: 1,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 9,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'A/SubjectVASList',
					[CountryEnum.England]: 'A/SubjectVASList',
					[CountryEnum.Wales]: 'A/SubjectVASList',
					[CountryEnum.Jersey]: 'A/SubjectVASList',
					reportEngineRef: 'SubjectVASList',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'A/SubjectVASList',
					[CountryEnum.England]: 'A/SubjectVASList',
					[CountryEnum.Wales]: 'A/SubjectVASList',
					[CountryEnum.Jersey]: 'A/SubjectVASList',
					reportEngineRef: 'SubjectVASList',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/SubjectVASList',
					[CountryEnum.England]: 'BTEC/SubjectVASList',
					[CountryEnum.Wales]: 'BTEC/SubjectVASList',
					[CountryEnum.Jersey]: 'BTEC/SubjectVASList',
					reportEngineRef: 'SubjectVASList',
					params: [],
					name: '',
				},
				[ExamLevels.BTECCert]: {
					[CountryEnum.International]: 'BTECCert/SubjectVASList',
					[CountryEnum.England]: 'BTECCert/SubjectVASList',
					[CountryEnum.Wales]: 'BTECCert/SubjectVASList',
					[CountryEnum.Jersey]: 'BTECCert/SubjectVASList',
					reportEngineRef: 'SubjectVASList',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC90Cred]: {
					[CountryEnum.International]: 'BTEC90Cred/SubjectVASList',
					[CountryEnum.England]: 'BTEC90Cred/SubjectVASList',
					[CountryEnum.Wales]: 'BTEC90Cred/SubjectVASList',
					[CountryEnum.Jersey]: 'BTEC90Cred/SubjectVASList',
					reportEngineRef: 'SubjectVASList',
					params: [],
					name: '',
				},
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'KS4/SubjectVASList',
					[CountryEnum.England]: 'KS4/SubjectVASList',
					[CountryEnum.Wales]: 'KS4/SubjectVASList',
					[CountryEnum.Jersey]: 'KS4/SubjectVASList',
					reportEngineRef: 'SubjectVASList',
					params: [],
					name: '',
				},
			},
			name: 'Subject value added overview',
			numberOfGPs: 4,
			examLevels: [
				ExamLevels.A,
				ExamLevels.AS,
				ExamLevels.BTEC,
				ExamLevels.BTECCert,
				ExamLevels.BTEC90Cred,
				ExamLevels.KS4,
			],
		},
		{
			id: 21,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/QualityOfTeaching/2016Report.cshtml',
					[CountryEnum.England]: 'BTEC/QualityOfTeaching/2016Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/QualityOfTeaching/2016Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/QualityOfTeaching/2016Report.cshtml',
					reportEngineRef: 'QualityOfTeaching',
					params: [
						{
							key: 'BTECType',
							value: '2016',
						},
					],
					name: '2016 - Quality of teaching and learning',
				},
			},
			name: 'Quality of teaching and learning',
			numberOfGPs: 4,
			examLevels: [ExamLevels.BTEC],
		},
		// if the id of quality of T&L is altered here it must be updated
		// in components/overall/qualityOfTeaching/index endpointSwitch()
		// Update for all three quality of T&L reports
		{
			id: 10,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'A/QualityOfTeaching',
					[CountryEnum.England]: 'A/QualityOfTeaching',
					[CountryEnum.Wales]: 'A/QualityOfTeaching',
					[CountryEnum.Jersey]: 'A/QualityOfTeaching',
					reportEngineRef: 'QualityOfTeaching',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'AS/QualityOfTeaching',
					[CountryEnum.England]: 'AS/QualityOfTeaching',
					[CountryEnum.Wales]: 'AS/QualityOfTeaching',
					[CountryEnum.Jersey]: 'AS/QualityOfTeaching',
					reportEngineRef: 'QualityOfTeaching',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/QualityOfTeaching/2010Report.cshtml',
					[CountryEnum.England]: 'BTEC/QualityOfTeaching/2010Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/QualityOfTeaching/2010Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/QualityOfTeaching/2010Report.cshtml',
					reportEngineRef: 'QualityOfTeaching',
					params: [
						{
							key: 'BTECType',
							value: '2010',
						},
					],
					name: '2010 - Quality of teaching and learning',
				},
			},
			name: 'Quality of teaching and learning',
			numberOfGPs: 4,
			examLevels: [ExamLevels.A, ExamLevels.AS, ExamLevels.BTEC],
		},
		{
			id: 11,
			exportSettings: {
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'KS4/QualityOfTeaching/ReportGCSE.cshtml',
					[CountryEnum.England]: 'KS4/QualityOfTeaching/ReportGCSE.cshtml',
					[CountryEnum.Wales]: 'KS4/QualityOfTeaching/ReportGCSE.cshtml',
					[CountryEnum.Jersey]: 'KS4/QualityOfTeaching/ReportGCSE.cshtml',
					reportEngineRef: 'SpecialisedQualityOfTeaching',
					params: [
						{
							key: 'measureName',
							value: 'KS4.GCSERedBlueTeachingMeasure',
						},
					],
					name: '',
				},
			},
			name: 'Quality of teaching and learning - GCSE',
			numberOfGPs: 4,
			examLevels: [ExamLevels.KS4],
		},
		{
			id: 12,
			exportSettings: {
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'KS4/QualityOfTeaching/ReportBTEC.cshtml',
					[CountryEnum.England]: 'KS4/QualityOfTeaching/ReportBTEC.cshtml',
					[CountryEnum.Wales]: 'KS4/QualityOfTeaching/ReportBTEC.cshtml',
					[CountryEnum.Jersey]: 'KS4/QualityOfTeaching/ReportBTEC.cshtml',
					reportEngineRef: 'SpecialisedQualityOfTeaching',
					params: [
						{
							key: 'measureName',
							value: 'KS4.VocRedBlueTeachingMeasure',
						},
					],
					name: '',
				},
			},
			name: 'Quality of teaching and learning - vocational',
			numberOfGPs: 4,
			examLevels: [ExamLevels.KS4],
		},
		{
			id: 22,
			exportSettings: {
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/TScoreThreeYear/2016Report.cshtml',
					[CountryEnum.England]: 'BTEC/TScoreThreeYear/2016Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/TScoreThreeYear/2016Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/TScoreThreeYear/2016Report.cshtml',
					reportEngineRef: 'TScoreThreeYear',
					params: [
						{
							key: 'BTECType',
							value: '2016',
						},
					],
					name: '2016 - T score',
				},
			},
			name: '2016 - T score',
			numberOfGPs: 3,
			examLevels: [ExamLevels.BTEC],
		},
		{
			id: 13,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'A/TScoreThreeYear',
					[CountryEnum.England]: 'A/TScoreThreeYear',
					[CountryEnum.Wales]: 'A/TScoreThreeYear',
					[CountryEnum.Jersey]: 'A/TScoreThreeYear',
					reportEngineRef: 'TScoreThreeYear',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'AS/TScoreThreeYear',
					[CountryEnum.England]: 'AS/TScoreThreeYear',
					[CountryEnum.Wales]: 'AS/TScoreThreeYear',
					[CountryEnum.Jersey]: 'AS/TScoreThreeYear',
					reportEngineRef: 'TScoreThreeYear',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'BTEC/TScoreThreeYear/2010Report.cshtml',
					[CountryEnum.England]: 'BTEC/TScoreThreeYear/2010Report.cshtml',
					[CountryEnum.Wales]: 'BTEC/TScoreThreeYear/2010Report.cshtml',
					[CountryEnum.Jersey]: 'BTEC/TScoreThreeYear/2010Report.cshtml',
					reportEngineRef: 'TScoreThreeYear',
					params: [
						{
							key: 'BTECType',
							value: '2010',
						},
					],
					name: '2010 - T score',
				},
			},
			name: '2010 - T score',
			numberOfGPs: 3,
			examLevels: [ExamLevels.A, ExamLevels.AS, ExamLevels.BTEC],
		},
		{
			id: 14,
			exportSettings: {
				[ExamLevels.A]: {
					[CountryEnum.International]: 'Common/StudentsNotIncluded',
					[CountryEnum.England]: 'Common/StudentsNotIncluded',
					[CountryEnum.Wales]: 'Common/StudentsNotIncluded',
					[CountryEnum.Jersey]: 'Common/StudentsNotIncluded',
					reportEngineRef: 'StudentsNotIncluded',
					params: [],
					name: '',
				},
				[ExamLevels.AS]: {
					[CountryEnum.International]: 'Common/StudentsNotIncluded',
					[CountryEnum.England]: 'Common/StudentsNotIncluded',
					[CountryEnum.Wales]: 'Common/StudentsNotIncluded',
					[CountryEnum.Jersey]: 'Common/StudentsNotIncluded',
					reportEngineRef: 'StudentsNotIncluded',
					params: [],
					name: '',
				},
				[ExamLevels.BTEC]: {
					[CountryEnum.International]: 'Common/StudentsNotIncluded',
					[CountryEnum.England]: 'Common/StudentsNotIncluded',
					[CountryEnum.Wales]: 'Common/StudentsNotIncluded',
					[CountryEnum.Jersey]: 'Common/StudentsNotIncluded',
					reportEngineRef: 'StudentsNotIncluded',
					params: [],
					name: '',
				},
				[ExamLevels.KS4]: {
					[CountryEnum.International]: 'Common/StudentsNotIncluded',
					[CountryEnum.England]: 'Common/StudentsNotIncluded',
					[CountryEnum.Wales]: 'Common/StudentsNotIncluded',
					[CountryEnum.Jersey]: 'Common/StudentsNotIncluded',
					reportEngineRef: 'StudentsNotIncluded',
					params: [],
					name: '',
				},
			},
			name: 'Students not included',
			numberOfGPs: 1,
			examLevels: [ExamLevels.A, ExamLevels.AS, ExamLevels.BTEC, ExamLevels.KS4],
		},
	],
};

/**
 * Report template dropdown options
 */
export const reportTemplateDropdownOptions: StrategicAnalysis.PdfReportTemplateOption[] = [
	{
		label: 'Main Provider',
		value: ReportTemplate.MAIN_PROVIDER,
	},
	{
		label: 'Governor',
		value: ReportTemplate.GOVERNOR,
	},
	{
		label: 'Custom',
		value: ReportTemplate.CUSTOM,
	},
];

/**
 * Not all pages are available for all exam levels, so we need to filter the options the user
 * can select based on the exam level they have chosen
 * @param examLevel the exam level to do the lookup for
 * @returns a filtered list of page options
 */
export const filteredCustomPageDropdownOptions = (
	examLevels: StrategicAnalysis.ExamLevels[]
): StrategicAnalysis.PdfReportCustomPageOption[] => {
	const pagesAvailableForExamLevel = Array.from(
		new Set(examLevels.flatMap((examLevel) => reportPages[ReportTemplate.CUSTOM]![examLevel]))
	);

	return pagesAvailableForExamLevel?.map((page) => {
		const pageOption = reportDefinitions.overallReports.find((option) => option.id === page);

		return {
			label: pageOption!.name,
			value: pageOption!.id,
		};
	});
};

/**
 * Create report page
 */
export const createReportPage = (
	benchmarkSet: string,
	user: Context.User,
	client: Context.Client,
	gradepoints: Context.Gradepoint[],
	page: ReportDef,
	dataSetId: string,
	countryEnumValue: 0 | 1 | 3,
	pageExamLevelSettings: ExamLevelSettings,
	examLevelInfo: { key: string },
	comparison?: { label: string; value: string }
): StrategicAnalysis.PdfReportPages => {
	return {
		tag: '',
		benchmarkSet,
		subjectGroupSet: '',
		username: user!.username,
		client: client!.name,
		dataSpec: {
			gradepoints: gradepoints.slice(-page!.numberOfGPs),
			dataItems: [
				{
					type: 'ClientDataset',
					id: dataSetId,
				},
			],
			filters: !!comparison ? [comparison] : [],
		},
		params: [
			{
				key: 'country',
				value: ConvertEnumToCountryAbbreviationWithJerseyOverride(countryEnumValue),
			},
			...pageExamLevelSettings!.params,
		],
		output: 'PDF',
		report: pageExamLevelSettings!.reportEngineRef as string,
		formatting: pageExamLevelSettings![countryEnumValue] as string,
		examLevel: examLevelInfo.key,
		comparisonGroups: [],
	};
};
