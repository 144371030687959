import { getProgress8MeasuresSpo } from '../../../../api/performanceMetricAPI';
import { setProgress8MetricsPending, setProgress8MetricsSuccess } from '../';

export const getProgress8MeasuresThunk = (gp: number[]): AppThunk => async (dispatch) => {
	dispatch(setProgress8MetricsPending());

	try {
		const response = await getProgress8MeasuresSpo(gp);

		if (response.HasError) {
			throw new Error(`Error fetching SPO Progress 8 Metrics, ${response.Errors[0].Message}`);
		}

		dispatch(setProgress8MetricsSuccess(response.ResponseObject));
	} catch (err: any) {
		throw new Error(`Error fetching SPO Progress 8 Metrics, ${err.message}`);
	}
};
