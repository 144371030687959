import fetch from './';
import { Context } from 'features/app/types';
import { SubjectAreaGridData } from 'features/subjectArea/types/common';
import { SubjectAreaAttainmentProfile } from 'features/subjectArea/types/attainmentProfile';

/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 */
export const fetchMainGridData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	versionNumber: number = 2
): Promise<ResponseBuilder<SubjectAreaGridData.GridData>> => {
	return fetch(
		`/bigfoot/v${versionNumber}/SubjectArea/${area}/GetSubjectPageAttainmentProfileGridData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: subjectId,
			},
		},
		true
	);
};

/**
 * Get the main Graph data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 */
export const fetchChartData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	versionNumber: number = 2
): Promise<ResponseBuilder<SubjectAreaGridData.GridData>> => {
	return fetch(
		`/bigfoot/V${versionNumber}/SubjectArea/${area}/GetSubjectPageAttainmentProfileChartData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: subjectId,
			},
		},
		true
	);
};

/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 * @param comparisons
 */
export const fetchAllComparisonGridData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	comparisons: Context.ReportAppliedComparisons[]
): Promise<
	ResponseBuilder<SubjectAreaGridData.Comparisons<SubjectAreaAttainmentProfile.ResponseRow>>
> => {
	return fetch(
		`/bigfoot/V2/SubjectArea/${area}/GetSubjectPageAttainmentProfileGridData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: [subjectId],
				AppliedComparisons: comparisons,
			},
		},
		true
	);
};

/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 * @param comparisons
 */
export const fetchAllComparisonChartData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	comparisons: Context.ReportAppliedComparisons[]
): Promise<
	ResponseBuilder<SubjectAreaGridData.Comparisons<SubjectAreaAttainmentProfile.ResponseRow>>
> => {
	return fetch(
		`/bigfoot/V2/SubjectArea/${area}/GetSubjectPageAttainmentProfileChartData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: [subjectId],
				AppliedComparisons: comparisons,
			},
		},
		true
	);
};

//#region Private methods

const formattedComparisons = (
	selectedComparisons:
		| {
				Key: string;
				Value: string;
		  }[]
		| undefined
) => {
	return selectedComparisons?.map((comparison) => {
		if (comparison.Value === 'Non-Disadvantaged') {
			return {
				...comparison,
				Value: 'No',
			};
		}

		if (comparison.Value === 'Disadvantaged') {
			return {
				...comparison,
				Value: 'Yes',
			};
		}
		return comparison;
	});
};

//#endregion
