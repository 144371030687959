import * as React from 'react';
import { FunctionComponent } from 'react';
import { Footer as BCFooter, Button } from 'basecamp';

type Props = {
	saveDisabled: boolean;
	handleCancel: () => void;
	handleSave: () => void;
};

const Footer: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<BCFooter>
			<Button
				setAs="secondary"
				disabled={props.saveDisabled}
				onClick={() => props.handleCancel()}
				withSize="medium"
			>
				Cancel
			</Button>
			<Button
				setAs="accent"
				disabled={props.saveDisabled}
				onClick={() => props.handleSave()}
				withSize="medium"
				ml={4}
			>
				Save
			</Button>
		</BCFooter>
	);
};

export default Footer;
