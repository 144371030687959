import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';
import { MfaType } from '../../utils/mfa';

//These will be used for manage account mfa set up anf the auth login mfa
/**
 * Check to see if the secret has been sent to the db
 * @param page Page the saga flow is required to get data for
 * @param mfaType The type of authentication being added to the db
 * @returns
 */
export const hasSecretBeenDispatchedToDb = (page: string, mfaType: MfaType) => (
	state: RootState
): boolean => {
	if (mfaType === MfaType.ONE_TIME_PASSWORD) {
		return state[`${page}OneTimePasswordAuthentication`].hasSecretBeenDispatched;
	} else {
		return state[`${page}EmailAuthentication`].hasSecretBeenDispatched;
	}
};

/**
 * Get the passcode the user has put in
 * @param page Page the saga flow is required to get data for
 * @param mfaType The type of authentication being added to the db
 * @returns
 */
export const getPasscode = (page: string, mfaType: MfaType) => (
	state: RootState
): string | undefined => {
	if (mfaType === MfaType.ONE_TIME_PASSWORD) {
		return state[`${page}OneTimePasswordAuthentication`]?.password;
	} else {
		return state[`${page}EmailAuthentication`]?.password;
	}
};

/**
 * Fetch the errors for a validation type
 * @param page Page the saga flow is required to get data for
 * @param mfaType The type of authentication being added to the db
 * @returns
 */
export const getErrors = (page: string, mfaType: MfaType) => (
	state: RootState
): string | undefined => {
	if (mfaType === MfaType.ONE_TIME_PASSWORD) {
		return state[`${page}OneTimePasswordAuthentication`].error;
	} else {
		return state[`${page}EmailAuthentication`].error;
	}
};

/**
 * Get the activated authentication types
 * @param page Page the saga flow is required to get data for
 */
//TODO: - MFA create a validation types enum - this is a nice to have
export const availableAndUserActiveMfaAuthenticationTypes = (page: string) => (
	state: RootState
): MultiFactorAuthentication.UserDataMfaResponse[] | [] => {
	const availableAuthentications = state[`${page}`].arrayOfActiveMfaTypes?.map((x) => x.id);

	return state[`${page}`].userDefinedConfiguration?.filter((x) =>
		availableAuthentications.includes(x.mfaTypeId)
	);
};

/**
 * Get the state of the area saga bail out
 * @param page Page the saga flow is required to get data for
 */
export const cancelActiveSagaFlow = (page: string) => (state: RootState): boolean => {
	return state[`${page}`].cancelFlow;
};

/**
 * Get if the desired authentication type has been validated
 */
export const hasAuthenticationTypeBeenValidated = (page: string, mfaType: MfaType) => (
	state: RootState
): boolean => {
	if (mfaType === MfaType.ONE_TIME_PASSWORD) {
		return state[`${page}OneTimePasswordAuthentication`].hasBeenValidated;
	} else {
		return state[`${page}EmailAuthentication`].hasBeenValidated;
	}
};

/**
 * Get the loading status for the modals/authentication validation
 */
export const getMfaTypeLoading = (page: string, mfaType: MfaType) => (
	state: RootState
): boolean => {
	if (mfaType === MfaType.ONE_TIME_PASSWORD) {
		return state[`${page}OneTimePasswordAuthentication`].modalLoading;
	} else {
		return state[`${page}EmailAuthentication`].modalLoading;
	}
};

/**
 * Currently select Mfa type
 */
export const getCurrentActiveMfa = (page: string) => (state: RootState): MfaType => {
	return state[`${page}`].currentMfaType;
};
