import { putContractInfo } from '../../../../../api/groupsSetupAPI';
import { setSetupErrorMessage, setSetupLoading } from '..';
import { fetchGroupSchoolContracts } from './fetchGroupSchoolContracts';

export const updateGroupSchoolContracts = (year: Groups.Setup.SchoolContract[]): AppThunk => async (
	dispatch
) => {
	dispatch(setSetupLoading());

	try {
		const res = await putContractInfo(year);

		dispatch(fetchGroupSchoolContracts());

		if (res.HasError) {
			dispatch(setSetupErrorMessage(res.Error[0].Message));
		}
	} catch (err) {
		dispatch(setSetupErrorMessage(err));
	}
};
