import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { context, fetchAppContext } from '../../app/redux/context';
import { getSchoolList, selectClient } from '../../../api/contextAPI';
import { translate } from '../../../utils/locale';
import { fetchGroupAccessRecords } from 'features/groups/setup/redux';

/**
 * ; State
 */
const initialState: Select.State = {
	loading: false,
	linkedSchools: undefined,
	message: undefined,
};

/**
 * Select Slice
 */
const selectSlice = createSlice({
	name: 'select',
	initialState,
	reducers: {
		updateDefaultClient(state: Select.State, action: PayloadAction<Select.LinkedSchool>) {
			const payload = action.payload;
			state.linkedSchools = state.linkedSchools?.map((linkedSchool: Select.LinkedSchool) => {
				return {
					...linkedSchool,
					isDefault: payload.id === linkedSchool.id,
				};
			});
		},
		updateLoading(state: Select.State, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		loadingSuccess(state: Select.State) {
			state.loading = false;
		},
		selectSchoolFail(state: Select.State, action: PayloadAction<Select.Message>) {
			state.loading = false;
			state.message = action.payload;
		},
		getSchoolsPending(state: Select.State) {
			state.loading = true;
		},
		getSchoolsSuccess(state: Select.State, action: PayloadAction<Array<Select.LinkedSchool>>) {
			state.linkedSchools = action.payload;
			state.loading = false;
		},
		getSchoolFail(state: Select.State, action: PayloadAction<Select.Message>) {
			state.loading = false;
			state.message = action.payload;
		},
	},
});

/**
 * Export Action
 */
export const {
	getSchoolsPending,
	getSchoolsSuccess,
	getSchoolFail,
	updateLoading,
	selectSchoolFail,
	updateDefaultClient,
	loadingSuccess,
} = selectSlice.actions;

/**
 * Export Selectors
 */

//list of schools client is linked too
export const GetLinkedSchools = (context: RootState): Select.LinkedSchool[] | undefined =>
	context.switchOrganisation.linkedSchools;

//get the loading status
export const loading = (context: RootState): boolean => {
	return context.switchOrganisation.loading;
};

/**
 * Export Reducer
 */
export default selectSlice.reducer;

/**
 * Returns School data
 */

export const listSchoolDataThunk = (): AppThunk => async (dispatch) => {
	dispatch(getSchoolsPending());

	getSchoolList()
		.then((response) => {
			dispatch(getSchoolsSuccess(response));
		})
		.catch((e) => {
			dispatch(
				getSchoolFail({
					text: translate('switchOrganisation.page.REDUX'),
				} as Select.Message)
			);
		});
};

//handle Selection thunk

export const selectSchoolThunk = (linkedSchool: Select.LinkedSchool): AppThunk => async (
	dispatch
) => {
	dispatch(getSchoolsPending());

	return selectClient(linkedSchool)
		.then((response) => {
			dispatch(fetchAppContext());
			dispatch(fetchGroupAccessRecords());
			return response;
		})
		.catch((e) => {
			dispatch(
				selectSchoolFail({
					text: translate('switchOrganisation.page.REDUX'),
				} as Select.Message)
			);
		});
};
