import { MonitoringPage } from '../types';
import { RootState } from '../../../store/rootReducer';
import { createQualTypeFilter } from '../../../utils/filters';

/**
 ** Export Selectors
 */
export const isLoading = (state: RootState) => {
	return state.monitoring.loading;
};
// Get monitoring difference data
export const getMonitoringDifferenceData = (
	context: RootState
): MonitoringPage.Difference | undefined => context.monitoring.difference;

// Get monitoring variance data
export const getMonitoringVarianceData = (
	context: RootState
): MonitoringPage.Variance | undefined => context.monitoring.variance;

// Get monitoring overview data
export const getMonitoringOverviewData = (
	context: RootState
): MonitoringPage.Overview | undefined => context.monitoring.overview;

// Get exam vs monitoring data
export const getExamVsMonitoringData = (context: RootState): MonitoringPage.ExamVsMonitoring =>
	context.monitoring.examVsMonitoring;

// Get monitoring error
export const getMonitoringError = (context: RootState): MonitoringPage.Error | undefined =>
	context.monitoring.error;

// Get the loading value
export const getLoadingStatus = (context: RootState): Boolean => context.monitoring.loading;

export const getMonitoringDifferenceQualTypeFilter = ({ context }: RootState): Filters.QualType => {
	return createQualTypeFilter(context.examLevels);
};
