import fetch from './';

/**
 * Get all active subscription agreements from the API including subscription records and any contracted based ones
 * @returns A response builder object that contains an array of subscription agreements
 */
export const getCurrentSubscriptionAgreementsAPI = (): Promise<
	ResponseBuilder<Array<ManageAccount.SubscriptionAgreement>>
> => {
	return fetch('/api/Subscriptions/Agreements').then((x: any) => {
		return {
			...x,
			ResponseObject: x.ResponseObject.map((x: any) => {
				return {
					subscriptionLevel: x.SubscriptionLevel,
					canResubscribe: x.CanResubscribe,
					autoRenewEnabled: x.AutoRenewEnabled,
					start: x.Start,
					end: x.End,
					isContracted: x.IsContracted,
					isSubscription: x.IsSubscription,
					contractAccess: x.IsContracted
						? ({
								id: x.ContractAccess.ContractId,
								name: x.ContractAccess.ContractName,
								description: x.ContractAccess.ContractDescription,
								allowed: x.ContractAccess.Allowed,
						  } as ManageAccount.ContractAccess)
						: null,
					subscriptionStatus: x.SubscriptionStatus,
					subscriptionSizeId: x.SubscriptionSizeId,
					id: x.Id,
					price: x.Price,
					length: x.Length,
				} as ManageAccount.SubscriptionAgreement;
			}),
		};
	});
};

/**
 * Get the start and end date that is calculated from the API based on the duration of the new subscription.
 * API logic takes into account existing subscriptions so the end date may not be inline with the actual duration.
 * @param duration Number of years for the subscription
 * @returns A response builder object that contains the start and end date of a subscription based on the specified duration.
 */
export const getSubscriptionDatesAPI = (
	duration: number
): Promise<ResponseBuilder<{ start: string; end: string }>> => {
	return fetch(`/api/Subscriptions/Dates/${duration}`).then((x) => {
		return {
			...x,
			ResponseObject: { start: x.ResponseObject.Start, end: x.ResponseObject.End },
		};
	});
};

/**
 * Get the organisation details for subscriptions which includes the primary contact for subscriptions
 * @returns A response builder object that contains the organisation details and primary contact information
 */
export const getOrganisationDetailsAPI = (): Promise<
	ResponseBuilder<ManageAccount.OrganisationDetails>
> => {
	return fetch('/api/Subscriptions/OrganisationDetails').then((x) => {
		const { ClientDetails, PrimaryContactDetails, IsMAT, MATName } = x.ResponseObject;

		const client = {
			name: ClientDetails.Name,
			addressLine1: ClientDetails.AddressLine1,
			addressLine2: ClientDetails.AddressLine2,
			addressLine3: ClientDetails.AddressLine3,
			city: ClientDetails.City,
			postcode: ClientDetails.Postcode,
			country: ClientDetails.Country,
		} as ManageAccount.Client;

		const primaryContactDetails = {
			fullName: PrimaryContactDetails.FullName,
			emailAddress: PrimaryContactDetails.EmailAddress,
			contactNumber: PrimaryContactDetails.ContactNumber,
			jobTitle: PrimaryContactDetails.JobTitle,
		} as ManageAccount.PrimaryContactDetails;

		const organisationDetails = {
			client: client,
			primaryContactDetails: primaryContactDetails,
			isMAT: IsMAT,
			matName: MATName,
		} as ManageAccount.OrganisationDetails;

		return {
			...x,
			ResponseObject: organisationDetails,
		};
	});
};

/**
 * Create a new subscription and the corresponding entitlements via the API
 * @param createSubscription The subscription creation object
 */
export const createSubscriptionAPI = (createSubscription: any): void => {
	fetch('/api/Subscriptions', { body: createSubscription });
};

/**
 *
 * Update an existing subscription
 * @param updateSubscription The subscription update object
 */
export const updateSubscriptionAPI = (
	updateSubscription: any
): Promise<ResponseBuilder<boolean>> => {
	return fetch('/api/Subscriptions/Update', { body: updateSubscription });
};

/**
 * Gets the agreement expiry window details for subscriptions and contracts
 */
export const getAgreementExpiry = () => {
	return fetch('/api/Subscriptions/AgreementExpiry/');
};

/**
 * Get all existing reports for the current academic year, this includes both filter and provider reports
 * @returns A response builder object that contains an array of filter reports for each qualification type
 */
export const getCurrentReportsAPI = (): Promise<ResponseBuilder<Array<ManageAccount.Report>>> => {
	return fetch('/api/Subscriptions/Reports').then((x: any) => {
		const reports = x.HasResponse
			? Object.entries(x.ResponseObject).map(([key, value]) => {
					return {
						type: key as ManageAccount.Qualifications,
						selectedFilterReports: value as Array<ManageAccount.FilterReports>,
					} as ManageAccount.Report;
			  })
			: [];

		return {
			...x,
			ResponseObject: reports,
		};
	});
};

/**
 * Update the organisation details via the API
 * @param organisationDetails The organiation details to update
 */
export const updateOranisationDetailsAPI = (organisationDetails: any): void => {
	fetch('/api/Subscriptions/OrganisationDetails', { body: organisationDetails });
};

/** Update the auto renew status of a subscription
 * @param autoRenewStatus The subscriptions auto renewal status
 */
export const updateAutoRenewStatus = (autoRenewStatus: ManageAccount.AutoRenewStatus): void => {
	fetch('/api/subscriptions/updateautorenewalstatus', { body: autoRenewStatus });
};
