/**
 * Selectors for creating the row data for the main top level grids
 */
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { getAllComparisonData, getAllRowData, getAppliedPageComparisons } from './';

/**
 * Get the row data with comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows with comparisons rows
 */
export const getRowDataWithComparisons = (
	page: StrategicAnalysis.AnalysisPage,
	rowIds: string[]
) => (state: RootState): (StrategicGridData.GridRow | StrategicGridData.ComparisonRow)[] | null => {
	const rows = getAllRowData(page)(state);
	const comparisons = getAllComparisonData(page)(state);

	return !!rows
		? rowIds.flatMap((id) => {
				const comparisonIds = rows.byId[id].comparisonIds;
				return [
					rows.byId[id],
					...comparisonIds.map((compId) => {
						return comparisons!.byId[compId];
					}),
				];
		  })
		: null;
};

/**
 * Get the row data without comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows
 */
export const getRowDataWithoutComparisons = (
	page: StrategicAnalysis.AnalysisPage,
	rowIds: string[]
) => (state: RootState): StrategicGridData.GridRow[] | null => {
	const rows = getAllRowData(page)(state);

	return !!rows ? rowIds.flatMap((id) => rows.byId[id]) : null;
};
/**
 * Creates the row data for the main grids
 * @param rowIds row ids to map over
 * @returns Formatted row data, with or without comparisons
 */
export const createGridData = (
	page: StrategicAnalysis.AnalysisPage,
	rowIds: string[],
	noComparisonsRequired: boolean = false
) => (state: RootState): (StrategicGridData.GridRow | StrategicGridData.ComparisonRow)[] | null => {
	const rows = getAllRowData(page)(state);
	const rowsWithComparisons = getRowDataWithComparisons(page, rowIds)(state);
	const rowsWithoutComparisons = getRowDataWithoutComparisons(page, rowIds)(state);
	const appliedComparisons = getAppliedPageComparisons(page)(state);
	const rowComparisonsIds = rows?.byId[rowIds[0]].comparisonIds;
	const hasComparisonsForRow =
		rowComparisonsIds &&
		rowComparisonsIds.some((id) => {
			const applied = appliedComparisons[0] ? appliedComparisons[0].value.split('|').join('-') : '';
			return id.includes(applied);
		});
	// If applied comparisons
	if (!!appliedComparisons && appliedComparisons.length > 0 && !noComparisonsRequired) {
		// Check if first grid row has the comparison ids populated, else return null
		return !!hasComparisonsForRow ? rowsWithComparisons : null;
	}

	// Else if no applied comparisons just fetch the all row
	return rowsWithoutComparisons;
};
