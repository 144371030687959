import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MfaAuthentication } from '../../../../types/mfaAuthentication';

/**
 * Initial State
 */
const initialState: MfaAuthentication.State = {
	modalLoading: false,
	password: undefined,
	qrCode: undefined,
	startAndContinueFlow: false,
	hasSecretBeenDispatched: false,
	error: undefined,
	hasBeenValidated: false,
};

/**
 * Reducers
 */
const mfaValidationOneTimePasswordAuthentication = createSlice({
	name: 'mfaValidationOneTimePasswordAuthentication',
	initialState,
	reducers: {
		setMfaModalLoading(state: MfaAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				modalLoading: action.payload,
			};
		},
		setPassword(state: MfaAuthentication.State, action: PayloadAction<string>) {
			return {
				...state,
				password: action.payload,
			};
		},
		setQrCode(state: MfaAuthentication.State, action: PayloadAction<string>) {
			return {
				...state,
				qrCode: action.payload,
			};
		},
		setStartAndContinueFlow(state: MfaAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				startAndContinueFlow: action.payload,
			};
		},
		setConfirmationOfSecretStorage(state: MfaAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				hasSecretBeenDispatched: action.payload,
			};
		},
		setMfaValidationError(state: MfaAuthentication.State, action: PayloadAction<string>) {
			return {
				...state,
				error: action.payload,
			};
		},
		setHasBeenValidated(state: MfaAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				hasBeenValidated: action.payload,
			};
		},
	},
});

/**
 * Actions
 */
export const {
	setMfaModalLoading,
	setPassword,
	setQrCode,
	setStartAndContinueFlow,
	setConfirmationOfSecretStorage,
	setMfaValidationError,
	setHasBeenValidated,
} = mfaValidationOneTimePasswordAuthentication.actions;

export default mfaValidationOneTimePasswordAuthentication.reducer;
