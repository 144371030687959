import { Context } from 'features/app/types';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { StrategicAnalysisOverview } from 'features/strategicAnalysis/types/overview';
import fetch from '.';

//#region Public methods
/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 */
export const fetchMainGridData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	versionNumber: number = 1
): Promise<ResponseBuilder<StrategicGridData.GridData>> => {
	return fetch(
		`/bigfoot/V${versionNumber}/StrategicAnalysis/${area}/GetStrategic${area}/${examLevel}`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
			},
		},
		true
	);
};

/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 * @param comparisons
 */
export const fetchAllComparisonData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	comparisons: {
		Key: string;
		Value: string;
	}[]
): Promise<
	ResponseBuilder<StrategicGridData.Comparisons<StrategicAnalysisOverview.ResponseRow>>
> => {
	return fetch(
		`/bigfoot/V1/StrategicAnalysis/${area}/GetStrategic${area}/${examLevel}`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				Comparisons: formattedComparisons(comparisons),
			},
		},
		true
	);
};

/**
 * Get the quality indicator thermometer data
 * @param examLevel number
 * @param benchmarkSet string
 * @param gradepoints Context.Gradepoint[]
 */
export const fetchQualityIndicatorThermometerData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	selectedComparisons?: {
		Key: string;
		Value: string;
	}[]
): Promise<ResponseBuilder<StrategicAnalysisQualityIndicator.Thermometer>> => {
	return fetch(
		`/bigfoot/V1/StrategicAnalysis/${area}/GetThermometerData/${examLevel}`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				Comparisons: formattedComparisons(selectedComparisons),
			},
		},
		true
	);
};

/**
 * Get the student information for a given set of student entry ids and subjects.
 * This is primarily suitable for embedded tabled where more context on a cell click is needed
 * @param benchmarkSet string
 * @param gradepoints Context.Gradepoint[]
 * @param studentEntryIds number[]
 * @param versionNumber number
 * @returns
 */
export const fetchStudentInformation = (
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	studentEntryIds: number[],
	subjectIds: string[],
	examLevel: number,
	rowId: string,
	versionNumber: number = 2
): Promise<ResponseBuilder<StrategicGridData.GridData>> => {
	return fetch(
		`/bigfoot/V${versionNumber}/StrategicAnalysis/StudentInformation/GetStrategicStudentInformation/${examLevel}`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				StudentEntryIds: studentEntryIds,
				SubjectIds: [...subjectIds],
				RowId: rowId,
			},
		},
		true
	);
};

/**
 * Get the Student Information with an embedded SPO subject data
 * @param benchmarkSet
 * @param gradepoints
 * @param studentEntryIds
 * @param versionNumber
 * @returns
 */
export const fetchBbpaStudentInformation = (
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	studentEntryIds: number[],
	subjectIds: string[],
	examLevel: number,
	rowId: string,
	column: string[],
	versionNumber: number = 2
): Promise<ResponseBuilder<StrategicGridData.GridData>> => {
	return fetch(
		`/bigfoot/V${versionNumber}/StrategicAnalysis/StudentInformation/GetStrategicBbpaStudentInformation/${examLevel}`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				StudentEntryIds: studentEntryIds,
				SubjectIds: subjectIds,
				RowId: rowId,
				SelectedValue: column,
			},
		},
		true
	);
};

//#endregion

//#region Private methods

const formattedComparisons = (
	selectedComparisons:
		| {
				Key: string;
				Value: string;
		  }[]
		| undefined
) => {
	return selectedComparisons?.map((comparison) => {
		if (comparison.Value === 'Non-Disadvantaged') {
			return {
				...comparison,
				Value: 'No',
			};
		}

		if (comparison.Value === 'Disadvantaged') {
			return {
				...comparison,
				Value: 'Yes',
			};
		}
		return comparison;
	});
};

//#endregion
