import { isNullOrUndefined } from './common';

// An arbitrary threshold for where fine score should end and scaled scores begin
const scoreThreshold = 60;
const fineScorePrecision = 2;
const scaledScorePrecision = 1;

/**
 * Format a score to a specific precision. If no precision is given, manually assign it
 * @param value Score to format
 * @param precision Precision to format to
 * @returns Formatted score
 */
export const adjustPriorAttainmentPrecision = (
	value: number | null,
	precision: number | null
): string => {
	return value?.toFixed(precision ? precision : manuallyAssignPrecision(value ?? 0)) ?? '';
};

/**
 * Gets the display precision from an array of types, based on a variable input
 * @param paTypeId The id to search for, can be a name or an id number
 * @param paTypes List of PriorAttainmentTypes to look through
 * @returns A precision. Will return null if nothing can be found or no paTypeId given
 */
export const getPriorAttainmentPrecision = (
	paTypeId: number | string | null | undefined,
	paTypes: SPO.PriorAttainmentType[] | undefined
): number | null => {
	if (isNullOrUndefined(paTypeId) || isNullOrUndefined(paTypes)) {
		return null;
	}

	const searchSubject = typeof paTypeId === 'number' ? 'PATypeId' : 'PATypeName';

	const result = paTypes.filter((paType) => {
		return paType[searchSubject] === paTypeId;
	});

	return result.length === 1 ? result[0].DisplayDp : null;
};

/**
 * Manually assigns a precision
 * @param value Score to format
 * @returns Formatted score
 */
const manuallyAssignPrecision = (value: number): number => {
	return value < scoreThreshold ? fineScorePrecision : scaledScorePrecision;
};
