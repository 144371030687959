import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Initial State
 */

const initialState: Ks5PerformanceMeasures.State = {
	loading: false,
	exportExcel: false,
	// Filter bar
	selectedFilters: [],
	appliedFilters: [],
	selectedComparisons: [],
	appliedComparisons: [],
	// Measures
	examLevels: undefined,
	grids: undefined,
	rows: undefined,
	comparisons: undefined,
	error: '',
	// Toggle
	valueToggle: true,
	// Tabs
	activeTab: 0,
};

const ks5PerformanceSlice = createSlice({
	name: 'Ks5PerformanceMeasures',
	initialState,
	reducers: {
		updateSelectedFilters(
			state: Ks5PerformanceMeasures.State,
			action: PayloadAction<Ks5PerformanceMeasures.FilterOption>
		) {
			return {
				...state,
				selectedFilters: action.payload ?? [],
			};
		},
		updateSelectedComparisons(
			state: Ks5PerformanceMeasures.State,
			action: PayloadAction<Ks5PerformanceMeasures.ComparisonOption>
		) {
			return {
				...state,
				selectedComparisons: action.payload ?? [],
			};
		},
		applyFilterBarChanges(state: Ks5PerformanceMeasures.State) {
			return {
				...state,
				appliedComparisons: state.selectedComparisons,
			};
		},
		setMetricsPending(state: Ks5PerformanceMeasures.State) {
			return {
				...state,
				loading: true,
				error: '',
			};
		},
		setMetricsSuccess(
			state: Ks5PerformanceMeasures.State,
			action: PayloadAction<Ks5PerformanceMeasures.GridData>
		) {
			const { examLevels, grids, rows } = action.payload;
			return {
				...state,
				examLevels,
				grids,
				rows,
				loading: false,
				error: '',
			};
		},
		setMetricsFailure(state: Ks5PerformanceMeasures.State, action: PayloadAction<string>) {
			return {
				...state,
				metrics: undefined,
				error: action.payload,
				loading: false,
			};
		},
		setComparisonsSuccess(
			state: Ks5PerformanceMeasures.State,
			action: PayloadAction<
				Ks5PerformanceMeasures.Comparisons<Ks5PerformanceMeasures.ComparisonRow>
			>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				comparisons: !state.comparisons
					? payload
					: {
							...state.comparisons,
							id: payload.id,
							byId: {
								...state.comparisons.byId,
								...payload.byId,
							},
							// @ts-ignore spread error
							allIds: Array.from(new Set([...state.comparisons.allIds, ...payload.allIds])),
					  },
				rows: {
					...state.rows,
					byId: Object.entries(state.rows.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: Array.from(
									new Set(
										Object.values(payload.byId)
											.filter((val) => val.rowId === key)
											.map((comparison) => comparison.comparisonId)
									)
								),
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		setComparisonsFailure(state: Ks5PerformanceMeasures.State, action: PayloadAction<string>) {
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		},
		resetComparisons(state: Ks5PerformanceMeasures.State) {
			if (!state.rows) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				selectedComparisons: [],
				appliedComparisons: [],
				comparisons: undefined,
				rows: {
					...state.rows,
					byId: Object.entries(state.rows.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: [],
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		handleExcelExport(state: Ks5PerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				exportExcel: action.payload,
			};
		},
		handleValueToggle(state: Ks5PerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				valueToggle: action.payload,
			};
		},
		setActiveTab(state: Ks5PerformanceMeasures.State, action: PayloadAction<number>) {
			return {
				...state,
				activeTab: action.payload,
			};
		},
		resetSelectedFilters(state: Ks5PerformanceMeasures.State) {
			return {
				...state,
				selectedFilters: [],
			};
		},
	},
});

export const {
	setMetricsPending,
	setMetricsSuccess,
	setMetricsFailure,

	updateSelectedFilters,
	updateSelectedComparisons,
	applyFilterBarChanges,
	handleExcelExport,

	setComparisonsSuccess,
	setComparisonsFailure,
	resetComparisons,

	handleValueToggle,

	setActiveTab,

	resetSelectedFilters,
} = ks5PerformanceSlice.actions;

/**
 * Export Reducer
 */
export default ks5PerformanceSlice.reducer;
