import { SagaIterator } from 'redux-saga';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';
import { put, retry } from 'redux-saga/effects';
import { validateMfaSetUpSuccess, validateAuthenticationOnSignIn } from '../../../../api/mfaAPI';
import { MfaType } from '../../../../utils/mfa';
import { sagaFlowConfigurations } from '../../../../constants';
/**
 * A Saga to handle the validation of configuring Mfa
 * @param mfaType mfa type thats being validated
 * @param code the one time use passcode
 * @param area the slice we width to dispatch the actions from
 */
export function* validatePasscodeFlow(
	mfaType: MfaType,
	code: string,
	area: string
): SagaIterator<void> {
	try {
		const response: ResponseBuilder<MultiFactorAuthentication.AuthenticationResponse> = yield retry(
			sagaFlowConfigurations.apiRetry, // Retry
			sagaFlowConfigurations.milliSeconds, // delay between retry
			validateMfaSetUpSuccess,
			mfaType,
			code
		);

		//if the response returns empty or it has not been validated
		if (!response || !response.ResponseObject.isValidated || response.HasError) {
			//Set the error
			yield put({
				type: `mfaSetup${area}/setMfaValidationError`,
				payload: response.HasError ? response.Errors[0].Message : 'Validation Fail',
			});

			//empty the password from state
			yield put({
				type: `mfaSetup${area}/setPassword`,
				payload: undefined,
			});

			//Set loading to false
			yield put({
				type: `mfaSetup${area}/setMfaModalLoading`,
				payload: false,
			});
		}

		if (response.ResponseObject.isValidated) {
			yield put({
				type: `mfaSetup${area}/setHasBeenValidated`,
				payload: true,
			});

			//Set loading to false
			yield put({
				type: `mfaSetup${area}/setMfaModalLoading`,
				payload: false,
			});

			//if previous fail resent the error
			yield put({
				type: `mfaSetup${area}/setMfaValidationError`,
				payload: undefined,
			});

			//empty the password from state - covers edge case if turning off and back on in same session
			yield put({
				type: `mfaSetup${area}/setPassword`,
				payload: undefined,
			});
		}
	} catch (e) {
		//Set the error
		yield put({
			type: `mfaSetup${area}/setMfaValidationError`,
			payload: e,
		});

		//empty the password from state
		yield put({
			type: `mfaSetup${area}/setPassword`,
			payload: undefined,
		});

		//Set loading to false
		yield put({
			type: `mfaSetup${area}/setMfaModalLoading`,
			payload: false,
		});
	}
}

/**
 * Saga flow to validate the passcode for mfa - either from an authenticator app or an emailed passcode
 * @param area MfaSetUP or MfaValidation
 * @param token jwt token to be passed in an then subsequently updated
 * @param mfaType
 * @param code
 */
export function* authenticatePasscodeFlow(
	area: string,
	token: string,
	mfaType: MfaType,
	code: string
): SagaIterator<void> {
	try {
		const response: ResponseBuilder<MultiFactorAuthentication.AuthenticationResponse> = yield retry(
			sagaFlowConfigurations.apiRetry, // Retry
			sagaFlowConfigurations.milliSeconds, // delay between retry
			validateAuthenticationOnSignIn,
			mfaType,
			code,
			token
		);

		//if the response returns empty or it has not been validated
		if (!response || !response.ResponseObject.isValidated) {
			//Set the error
			yield put({
				type: `mfaValidation${area}/setMfaValidationError`,
				payload: 'Validation Fail',
			});

			//Reset passcode from mfa to undefined
			yield put({
				type: `mfaValidation${area}/setPassword`,
				payload: undefined,
			});
		}

		if (response.ResponseObject.isValidated) {
			//update token with new token
			yield put({
				type: 'context/updateMfaTokenAndValidation',
				payload: response.ResponseObject,
			});
			//the passcode has been validated
			yield put({
				type: `mfaValidation${area}/setHasBeenValidated`,
				payload: true,
			});

			//Set loading to false
			yield put({
				type: `mfaValidation${area}/setMfaModalLoading`,
				payload: false,
			});
		}
	} catch (e) {
		//Set the error
		yield put({
			type: `mfaValidation${area}/setMfaValidationError`,
			payload: e,
		});

		//Set loading to false
		yield put({
			type: `mfaValidation${area}/setMfaModalLoading`,
			payload: false,
		});
	}
}
