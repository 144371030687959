import { MfaType } from '../utils/mfa';
import fetch from './';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';

/**
 * Fetch the available authentication types to be iterated over
 */
export const fetchAvailableAuthenticationTypes = (): Promise<
	ResponseBuilder<Array<MultiFactorAuthentication.AvailableMfaResponse>>
> => {
	return fetch('/api/Admin/mfaadmin/GetActiveMfaList', {
		method: 'GET',
	}).then((response) => {
		return {
			...response,
			ResponseObject: response.ResponseObject.map((mfa: any) => {
				return {
					id: mfa.Id,
					mfaTypeName: mfa.MfaTypeName,
				} as MultiFactorAuthentication.AvailableMfaResponse;
			}),
		};
	}) as Promise<ResponseBuilder<Array<MultiFactorAuthentication.AvailableMfaResponse>>>;
};

/**
 * Fetch Current users preferred mfa options
 */
export const fetchUserAuthenticationData = (): Promise<
	ResponseBuilder<MultiFactorAuthentication.UserDataMfaResponse[]>
> => {
	return fetch('/api/Admin/mfaadmin/GetUsersMfaConfig', {
		method: 'GET',
	}).then((response) => {
		return {
			...response,
			ResponseObject: response.ResponseObject.map((mfa: any) => {
				return {
					mfaTypeId: mfa.MfaTypeId,
					isActive: mfa.IsActive,
					mfaTypeName: mfa.MfaTypeName,
				} as MultiFactorAuthentication.UserDataMfaResponse;
			}),
		};
	}) as Promise<ResponseBuilder<MultiFactorAuthentication.UserDataMfaResponse[]>>;
};

/**
 * Activate or deactivate mfa in db for desired authentication
 * @param activateMfa enable or disable the secret in the db
 * @param mfaType the mfaType that is desired to be updated
 */

export const setAuthenticationMfaType = (
	activateMfa: boolean,
	mfaType: MfaType
): Promise<ResponseBuilder<MultiFactorAuthentication.ActivationOfMfaResponse>> => {
	return fetch('/api/Admin/mfaadmin/ActivateMfaType', {
		method: 'POST',
		body: {
			ActivateMfa: activateMfa,
			MfaTypeId: mfaType,
		},
	}).then((response) => {
		return {
			...response,
			ResponseObject: {
				message: response.ResponseObject.Message,
				result: response.ResponseObject.Result,
			} as MultiFactorAuthentication.ActivationOfMfaResponse,
		};
	}) as Promise<ResponseBuilder<MultiFactorAuthentication.ActivationOfMfaResponse>>;
};

/**
 * Validate the Authentication Code - to be called in the manage account one time validation
 */
export const validateMfaSetUpSuccess = (
	mfaType: MfaType,
	code: string
): Promise<ResponseBuilder<MultiFactorAuthentication.ValidationResponse>> => {
	return fetch('/api/Admin/mfaadmin/ValidateOneTimeCode', {
		method: 'POST',
		body: {
			MfaTypeId: mfaType,
			PassCode: code,
		},
	}).then((response) => {
		return {
			...response,
			ResponseObject: {
				isValidated: response.ResponseObject.IsValidated,
			} as MultiFactorAuthentication.ValidationResponse,
		};
	}) as Promise<ResponseBuilder<MultiFactorAuthentication.ValidationResponse>>;
};

/**
 * Validate the Authentication Code - on sign in
 */
export const validateAuthenticationOnSignIn = (
	mfaType: MfaType,
	code: string,
	token: string
): Promise<ResponseBuilder<MultiFactorAuthentication.AuthenticationResponse>> => {
	return fetch('/api/Auth/mfa/ValidateMfa', {
		headers: {
			MfaAuthentication: token,
		},
		method: 'POST',
		body: {
			MfaTypeId: mfaType,
			PassCode: code,
		},
	}).then((response) => {
		return {
			...response,
			ResponseObject: {
				isValidated: response.ResponseObject.IsValidated,
				mfaToken: response.ResponseObject.MfaToken,
				mfaTypeId: response.ResponseObject.MfaTypeId,
			} as MultiFactorAuthentication.AuthenticationResponse,
		};
	}) as Promise<ResponseBuilder<MultiFactorAuthentication.AuthenticationResponse>>;
};

/**
 * Send Email for Authentication sign in
 */
export const sendEmailForValidationRequest = (
	mfaType: MfaType,
	token: string
): Promise<ResponseBuilder<boolean>> => {
	return fetch('/api/Auth/mfa/GenerateEmailValidationRequest', {
		headers: {
			MfaAuthentication: token,
		},
		method: 'POST',
		body: {
			MfaTypeId: mfaType,
		},
	});
};

/**
 * Active Multi factor Authentication as a feature
 */
export const enableMultiFactorAuthentication = (
	enableMfa: boolean
): Promise<ResponseBuilder<boolean>> => {
	return fetch('/api/Admin/mfaadmin/EnableDisableMfa', {
		method: 'POST',
		body: {
			ActivateMfa: enableMfa,
		},
	});
};
