import { getFieldComparisonsByApi } from './../../api/bedrockAPI';

export const getFieldComparisons = async (
	benchmarkId: number,
	field: string
): Promise<{ name: string; value: string }[]> => {
	try {
		const response = await getFieldComparisonsByApi(benchmarkId, field);

		if (response && response.responseObject && Array.isArray(response.responseObject)) {
			return response.responseObject.map((comps) => ({ name: comps.name, value: comps.value }));
		} else {
			throw new Error('Invalid response from API');
		}
	} catch (err) {
		console.error('Error fetching field comparisons:', err);
		throw new Error('BENCHMARK_NOT_FOUND');
	}
};
