type Mode = 'connect' | 'summit';

export const getTheme = (mode: Mode, theme: any) => {
	const variants = {
		accent: theme.colors[mode].accent,
		primary: theme.colors[mode].primary,
	};

	const t = Object.assign(theme, {
		colors: {
			...theme.colors,
			UI: {
				...theme.colors.UI,
				...variants,
			},
			NAV: {
				...theme.colors.NAV,
				...variants,
			},
			UIAlpha: {
				...theme.colors.UIAlpha,
				accent: theme.colors[`${mode}Alpha`].accent,
				primary: theme.colors[`${mode}Alpha`].primary,
			},
			DATAVIS: {
				...theme.colors.DATAVIS,
				...variants,
			},
		},
	});

	return { ...t };
};
