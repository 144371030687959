/**
 * Gets all outcomes available for subject
 */
const getAllOutcomes = (state: RootState) => {
	if (!state.subjectPage.data || !state.subjectPage.data[state.subjectPage.currentSubjectId]) {
		return [];
	}

	const data = state.subjectPage.data[state.subjectPage.currentSubjectId];

	return data.Outcomes;
};

/**
 * Gets array of outcomes that are measurable by points
 */
export const getOutcomes = (state: RootState) => {
	if (!state.subjectPage.data || !state.subjectPage.data[state.subjectPage.currentSubjectId]) {
		return [];
	}

	const data = state.subjectPage.data[state.subjectPage.currentSubjectId];

	const allOutcomes = getAllOutcomes(state);

	return allOutcomes
		.filter((outcome: SubjectPage.Outcomes) => outcome.Outcome.Points && outcome.Outcome.Points > 0)
		.map((outcome: SubjectPage.Outcomes) => {
			return outcome.Outcome.Value;
		});
};

/**
 * Gets the numeric value of the grade factoring in zero value grades such as U and X
 */
const diffValue = (outcomes: string[], grade: string): number => {
	const zeroValueGrades = ['U', 'X'];

	return zeroValueGrades.includes(grade) ? outcomes.length : outcomes.indexOf(grade);
};

/**
 * Handle excel diff cell styling
 * @param params
 */
export const excelDiffCellRules = (params: any) => {
	if (params.value.includes('+')) {
		return 'DiffPositive';
	}

	if (params.value.includes('-') && params.value !== '-') {
		return 'DiffNegative';
	}

	return 'Diff';
};

/**
 * Handle excel comparison cell styling
 * @param props params, selected comaprison
 */
export const excelComparisonCellRules = (props: { params: any; selected: string | undefined }) => {
	const { params, selected } = props;

	if (params.colDef.field === selected || params.colDef.field === 'primaryGp') {
		return 'SelectedComparison';
	}

	return 'Comparison';
};

/**
 * Cell styling helper for grade diff
 */
export const handleDiffCellStyles = (params: any) => {
	if (params.value.includes('+')) {
		return { color: '#FF9595', backgroundColor: '#FEE6DD' };
	}

	if (params.value.includes('-')) {
		return { color: '#AAA0F7', backgroundColor: '#DFE3F2' };
	}

	return;
};

/**
 * Calculates the diff of two grades
 * @param outcomes Current outcome set
 * @param grade Grade we're comapring to
 * @param comparisonGrade The comparison grade
 */
type Grade = {
	display: string;
	value: string;
};
export const calculateGradeDiff = (
	outcomes: string[],
	grade: Grade,
	comparisonGrade: Grade
): string => {
	/**
	 * Get the numeric value of the grade
	 */
	const actual = !grade ? 0 : diffValue(outcomes, grade.value);
	const comparison = !comparisonGrade ? 0 : diffValue(outcomes, comparisonGrade.value);

	/**
	 * We ignore Q grades, so if the grade or comparison is a Q grade return ''
	 * Also check for empty grades in table data
	 */
	if (
		!grade ||
		!comparisonGrade ||
		(grade && grade.value === 'Q') ||
		(comparisonGrade && comparisonGrade.value === 'Q') ||
		grade.value === '' ||
		comparisonGrade.value === ''
	) {
		return '';
	}

	/**
	 * Grades are equal so return 0
	 */
	if (actual === comparison) {
		return '0';
	}

	/**
	 * Diff is minus
	 */
	if (actual > comparison) {
		return `-${actual - comparison}`;
	}

	/**
	 * Diff is plus
	 */
	return `+${comparison - actual}`;
};

/** Handles sorting of gps.
 * @param current The current value
 * @param next The next value
 */
export const handleGpSorting = (current: { order: number }, next: { order: number }) => {
	if (!current) {
		return 1;
	}

	if (!next) {
		return -1;
	}

	return current.order - next.order;
};

/**
 * Handles number sorting with types to prevent unwanted string sorting.
 * @param current The current numeric value
 * @param next The next numeric value
 */
export const handleNumberSorting = (a: string, b: string) => {
	const current = a === '' ? Number.NEGATIVE_INFINITY : Number(a);
	const next = b === '' ? Number.NEGATIVE_INFINITY : Number(b);

	return current - next;
};
