export const subjectOverviewConfig = (showInOneTable?: boolean) => ({
	search: 250,
	filterQual: 250,
	applyFilters: 250,
	applyComparisons: 250,
	teachingSets: 160,
	showInOneTable: showInOneTable ? 160 : 0,
	downloadPdf: 75,
	downloadXlxs: 75,
});

export const subjectPageConfig = (
	showThermometerToggle: boolean,
	showFiltersComparisons: boolean,
	isTrends: boolean,
	showAdhoc: boolean,
	showWhatIf: boolean,
	showReportControls: boolean,
	showExportXlxs: boolean,
	showSimplifyView: boolean,
	showGpDropDown: boolean,
	showToggleCountPercent: boolean,
	showAgGridExcelExport: boolean
) => ({
	toggleThermometer: showThermometerToggle ? 150 : 0,
	applyFilters: showFiltersComparisons ? 250 : 0,
	applyComparisons: showFiltersComparisons ? 250 : 0,
	adhocGroups: showAdhoc ? 100 : 0,
	whatIf: showWhatIf ? 60 : 0,
	manageReport: showReportControls ? 200 : 0,
	downloadPdf: 75,
	downloadXlxs: showExportXlxs ? 75 : 0,
	simplifyView: showSimplifyView ? 160 : 0,
	instructionalText: isTrends ? 400 : 0,
	comparisonGp: isTrends ? 250 : 0,
	showCat4: isTrends ? 160 : 0,
	gpDropDown: showGpDropDown ? 250 : 0,
	showToggleCountPercent: showToggleCountPercent ? 150 : 0,
	showAgGridExcelExport: showAgGridExcelExport ? 75 : 0,
});

export const studentAnalysisConfig = () => ({
	search: 250,
	tutorGroup: 250,
	focusOn: 250,
	applyFilters: 250,
	applyComparisons: 250,
	showCat4: 160,
	toggleStatus: 160,
	downloadXlxs: 75,
	downloadPdf: 75,
});

export const monitoringConfig = (isExamVsMonitoring: boolean) => ({
	filterSubjects: 250,
	filterQual: 250,
	comparisonGp: isExamVsMonitoring ? 250 : 0,
	instructionalText: 400,
});

export const monitoringOverviewConfig = () => ({
	teachingSets: 250,
	teachers: 250,
	instructionalText: 400,
	viewSubject: 100,
	showExamGp: 150,
});

export const ks4PerformanceMeasuresConfig = () => ({
	mainGradepoint: 250,
	comparisonGradepoint: 250,
	comparisons: 250,
	button: 100,
});

export const SPOPerformanceMeasuresConfig = () => ({
	metrics: 250,
	comparisons: 250,
	button: 100,
	downloadXlxs: 75,
});

export const SPOProgress8Config = () => ({
	comparisons: 250,
	button: 100,
	downloadXlxs: 75,
});

export const ks5ALevelPerformanceMeasuresConfig = () => ({
	academicYear: 250,
	comparisons: 250,
	hmlFilter: 250,
	button: 100,
});

export const ibPerformanceMeasuresConfig = () => ({
	mainGradepoint: 250,
	comparisonGradepoint: 250,
	comparisons: 250,
	button: 100,
});

export const strategicAnalysisConfig = () => ({
	filterQual: 250,
	applyComparisons: 250,
	applyFilters: 250,
	applyButton: 150,
	excelExport: 100,
	toggleThermometer: 100,
});

export const ks5PerformanceMeasuresConfig = () => ({
	filterQual: 250,
	applyComparisons: 250,
	applyFilters: 250,
	applyButton: 150,
	excelExport: 100,
	toggleThermometer: 100,
	valueToggle: 100,
});
