import * as React from 'react';
import { FunctionComponent } from 'react';

import { translate } from '../utils/locale';
import { Modal, Heading, Text } from 'basecamp';

type Props = {
	click: () => void;
};
const ReportHandlerModal: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<Modal
			setAs="small"
			close={() => props.click()}
			withActions={[
				{
					title: translate('subjectsOverview.pdfRequestedModal.OK'),
					type: 'accent',
					onClick: () => props.click(),
				},
			]}
			andBodyPadY={4}
			dataTest="ReportHandlerModal"
		>
			<Heading setAs="large" withColor="accent" dataTest="ReportHandlerModalTitle">
				{translate('subjectsOverview.pdfRequestedModal.HEADING') as string}
			</Heading>
			<Text mb={3} dataTest="ReportHandlerModalBody">
				{translate('subjectsOverview.pdfRequestedModal.BODY') as string}
			</Text>
		</Modal>
	);
};

export default ReportHandlerModal;
