import { SagaIterator } from 'redux-saga';
import { put, retry, select, call } from 'redux-saga/effects';
import { enableMultiFactorAuthentication } from '../../../../api/mfaAPI';
import { sagaFlowConfigurations } from '../../../../constants';
import { getConnectPage } from '../../../../utils/sagaHelpers';
import { getIsMfaEnabled } from 'features/app/redux/context';
/**
 * Saga that enables or disables the option of mfa
 */
export function* enableMultiFactorAuthenticationFlow(): SagaIterator<void> {
	const connectPage = yield call(getConnectPage);

	try {
		//Wether the user has enabled the mfa feature
		const enableMfa = yield select(getIsMfaEnabled);

		//Update the db for isMfaEnabled
		const response: ResponseBuilder<boolean> = yield retry(
			sagaFlowConfigurations.apiRetry, // Retry
			sagaFlowConfigurations.milliSeconds, // delay between retry
			enableMultiFactorAuthentication,
			enableMfa
		);

		//if the response returns empty or it has not been validated
		if (!response || !response.ResponseObject) {
			//Set the error
			yield put({
				type: `${connectPage}/setMfaSetUpError`,
				payload: 'Unable to update user',
			});
		}

		//if no error then - this value matches what is being passed to the api
		yield put({
			type: `${connectPage}/setHasUserEnabledMfa`,
			payload: enableMfa,
		});
	} catch (e) {
		yield put({
			type: `${connectPage}/setMfaSetUpError`,
			payload: e,
		});
	}
}
