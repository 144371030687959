import fetch from './';
import { Document } from '../utils/share';

/**
 * List Subject Saved reports
 */
export const fetchReports = (
	collectionName: string,
	doc: Document,
	personal: boolean,
	shared: boolean
): Promise<any> => {
	if (!(doc instanceof Document) || !doc.IsValidDocument()) {
		return Promise.reject('Invalid Document supplied to retrieveAll function');
	} else {
		return fetch('/api/strategic/share/retrieveall', {
			body: {
				Personal: personal,
				Shared: shared,
				...doc.FormatRequestBody(collectionName),
			},
		});
	}
};

export const fetchReport = (collectionName: string, doc: Document): Promise<any> => {
	if (!(doc instanceof Document) || !doc.IsValidDocument()) {
		return Promise.reject('Invalid Document supplied to retrieve function');
	} else {
		return fetch('/api/strategic/share/retrieve', {
			body: {
				...doc.FormatRequestBody(collectionName),
			},
		});
		//.catch(() => warn('Failed to retrieve document to collection'));
	}
};

export const addReport = (body: any, doc: any): Promise<any> => {
	return fetch('/api/strategic/share/add', {
		body: body,
	}).then((response) => {
		doc.objectId = response.ObjectId;
		return response;
	});
	//.catch(() => warn('Failed to save document to collection'));
};

export const loadReport = (body: any): Promise<any> => {
	return fetch('/api/strategic/share/retrieve', {
		body: body,
	});
};

/**
 * Remove Saved report from Subject page
 * @param body object
 */
export const deleteReport = (body: any): Promise<any> => {
	return fetch('/api/strategic/share/delete', {
		body: body,
	});
};

/**
 * Remove Saved report from Subject page
 * @param body object
 */
export const updateReport = (body: any): Promise<any> => {
	return fetch('/api/strategic/share/update', {
		body: body,
	});
};
