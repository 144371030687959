import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { StrategicAnalysisQualityIndicator } from 'features/strategicAnalysis/types/qualityIndicator';
import { resetSessionOnGradepointChange, clearSessionData, setStrategicLoading } from '../slice';

/**
 * Initial State
 */
const initialState: StrategicAnalysisQualityIndicator.State = {
	showThermometerToggle: true,
	examLevels: null,
	grids: null,
	rows: null,
	comparisons: null,
	error: undefined,
	loading: false,
	thermometerData: undefined,
};

/**
 * Reducers / Actions
 */
const strategicAnalysisQualityIndicator = createSlice({
	name: 'strategicAnalysisQualityIndicator',
	initialState,
	reducers: {
		setMainGridDataSuccess(
			state: StrategicAnalysisQualityIndicator.State,
			action: PayloadAction<StrategicGridData.GridData>
		) {
			const { examLevels, grids, rows } = action.payload;
			// If this is the first fetch just set the data
			if (!state.examLevels) {
				return {
					...state,
					examLevels,
					grids,
					rows,
				};
			}
			// If this is a subsequent fetch update the data
			return {
				...state,
				examLevels: {
					...state.examLevels,
					id: examLevels.id,
					byId: {
						...state.examLevels!.byId,
						...examLevels.byId,
					},
					//@ts-ignore bloody spread error again
					allIds: [...state.examLevels.allIds, ...examLevels.allIds],
				},
				grids: {
					...state.grids,
					id: grids.id,
					byId: {
						...state.grids?.byId,
						...grids.byId,
					},
					allIds: [...state.grids!.allIds, ...grids.allIds],
				},
				rows: {
					...state.rows,
					id: rows.id,
					byId: {
						...state.rows!.byId,
						...rows.byId,
					},
					allIds: [...state.rows!.allIds, ...rows.allIds],
				},
				loading: false,
			};
		},
		setMainGridDataFail(
			state: StrategicAnalysisQualityIndicator.State,
			action: PayloadAction<string>
		) {
			const { payload } = action;

			return {
				...state,
				error: payload,
				loading: false,
			};
		},
		setAllComparisonDataSuccess(
			state: StrategicAnalysisQualityIndicator.State,
			action: PayloadAction<
				StrategicGridData.Comparisons<StrategicAnalysisQualityIndicator.ComparisonRow>
			>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				comparisons: !state.comparisons
					? payload
					: {
							...state.comparisons,
							id: payload.id,
							byId: {
								...state.comparisons.byId,
								...payload.byId,
							},
							allIds: Array.from(new Set([...state.comparisons.allIds, ...payload.allIds])),
					  },
				rows: {
					...state.rows,
					byId: Object.entries(state.rows.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: Array.from(
									new Set(
										Object.values(payload.byId)
											.filter((val) => val.rowId === key)
											.map((comparison) => comparison.comparisonId)
									)
								),
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		setAllRowComparisonIds(
			state: StrategicAnalysisQualityIndicator.State,
			action: PayloadAction<string[]>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				rows: {
					...state.rows,
					byId: Object.entries(state.rows!.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds:
									payload.length > 0
										? payload.filter((comparisonId) => comparisonId.includes(`${val.rowId}-`))
										: [],
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		setComparisonDataFail(
			state: StrategicAnalysisQualityIndicator.State,
			action: PayloadAction<string>
		) {
			const { payload } = action;

			return {
				...state,
				error: payload,
				loading: false,
			};
		},
		setMainThermometerData(
			state: StrategicAnalysisQualityIndicator.State,
			action: PayloadAction<
				StrategicAnalysisQualityIndicator.ThermometerById<
					StrategicAnalysisQualityIndicator.Thermometer
				>
			>
		) {
			const { id, byId, allIds } = action.payload;

			// If this is the first fetch just set the data
			if (!state.thermometerData?.mainData) {
				return {
					...state,
					thermometerData: {
						...state.thermometerData,
						mainData: action.payload,
					},
				};
			}

			// It is not the first time so lets update it
			return {
				...state,
				thermometerData: {
					...state.thermometerData,
					mainData: {
						id: id,
						byId: {
							...state.thermometerData?.mainData?.byId,
							...byId,
						},
						//@ts-ignore bloody spread error again
						allIds: [...state.thermometerData?.mainData?.allIds, ...allIds],
					},
				},
			};
		},
		setComparisonThermometerData(
			state: StrategicAnalysisQualityIndicator.State,
			action: PayloadAction<
				StrategicAnalysisQualityIndicator.ThermometerById<
					StrategicAnalysisQualityIndicator.Thermometer
				>
			>
		) {
			const { id, byId, allIds } = action.payload;

			// If this is the first fetch just set the data
			if (!state.thermometerData?.comparisonData) {
				return {
					...state,
					thermometerData: {
						...state.thermometerData,
						comparisonData: action.payload,
					},
				};
			}

			// It is not the first time so lets update it
			return {
				...state,
				thermometerData: {
					...state.thermometerData,
					comparisonData: {
						id: id,
						byId: {
							...state.thermometerData?.comparisonData?.byId,
							...byId,
						},
						//@ts-ignore bloody spread error again
						allIds: [...state.thermometerData?.comparisonData?.allIds, ...allIds],
					},
				},
			};
		},
		setError(state: StrategicAnalysisQualityIndicator.State, action: PayloadAction<string>) {
			return {
				...state,
				error: action.payload,
			};
		},
		setLoading(state: StrategicAnalysisQualityIndicator.State, action: PayloadAction<bool>) {
			return {
				...state,
				loading: action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			setStrategicLoading,
			(state: StrategicAnalysisQualityIndicator.State, action: PayloadAction<boolean>) => {
				return {
					...state,
					loading: action.payload,
				};
			}
		);
		builder.addCase(resetSessionOnGradepointChange, () => {
			return initialState;
		});
		builder.addCase(clearSessionData, () => {
			return initialState;
		});
	},
});

export const {
	setMainGridDataSuccess,
	setMainGridDataFail,
	setAllComparisonDataSuccess,
	setAllRowComparisonIds,
	setComparisonDataFail,
	setMainThermometerData,
	setComparisonThermometerData,
	setError,
	setLoading,
} = strategicAnalysisQualityIndicator.actions;

export default strategicAnalysisQualityIndicator.reducer;
