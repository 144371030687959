import { SagaIterator } from 'redux-saga';

/**
 * Gets the current connect page
 * @returns string
 */
export function* getConnectPage(): SagaIterator<string> {
	const pathname = window.location.pathname;
	const page = pathname.split('/').pop() as string;

	return page;
}
