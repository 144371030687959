import {
	setAnalysisTrends,
	setAnalysisLoading,
	setAnalysisErrorMessage,
} from 'features/groups/analysis/redux';

import { getTrends } from '../../../../../api/groupsAPI';

/**
 * Fetch Group Trends
 */
export const fetchGroupTrends = (): AppThunk => async (dispatch) => {
	// Populate analysis state
	dispatch(setAnalysisLoading('AnalysisTrends'));

	const res = await getTrends();

	try {
		if (res.HasError) {
			dispatch(setAnalysisErrorMessage(res.Errors[0].message));
			return;
		}
		dispatch(setAnalysisTrends(res.ResponseObject));
	} catch (err) {
		dispatch(setAnalysisErrorMessage(res.Errors[0].message));
	}
};
