import * as React from 'react';
import { FunctionComponent } from 'react';

type Props = {
	condition: boolean;
	wrapper: (children: React.ReactNode) => JSX.Element;
	children: JSX.Element;
};

/**
 * Conditional wrapper
 * Pass it a boolean and JSX wrapping element like a Flex
 * Wrap the inner node with a Fragment and if the condition is true it'll apply
 * the wrapping element if not it will fallback to using the Fragment
 *
 * <ConditionalWrapper
 * 		condition={evaluateThisFella}
 *		wrapper={(children) => <Flex>{children}</Flex>}
 * >
 *		<React.Fragment>
 *			{children}
 *		</React.Fragment>
 * </ConditionalWrapper
 */
const ConditionalWrapper: FunctionComponent<Props> = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;

export default ConditionalWrapper;
