//@ts-nocheck
import * as React from 'react';
import { components } from 'react-select';
import { Flex } from 'basecamp';
/**
 ** Custom Value Container
 * displays - "valuename, value1name & XX more"
 */
const ValueContainer: React.ReactElement = ({ children, getValue, ...props }) => {
	var values = getValue();
	var valueLabel = '';

	if (values.length === 1) {
		valueLabel += props.selectProps.getOptionLabel(values[0]);
	}

	if (values.length > 1) {
		valueLabel += props.selectProps.getOptionLabel(values[0]).substr(0, 12);
		valueLabel += ` & ${values.length - 1} more`;
	}

	// Keep standard placeholder and input from react-select
	const childrenToRender = React.Children.toArray(children).filter(
		(child) => ['Input', 'DummyInput', 'Placeholder'].indexOf(child.type.name) >= 0
	);

	return (
		<components.ValueContainer {...props}>
			{!props.selectProps.inputValue && <Flex data-test="valueRendererLabel">{valueLabel}</Flex>}
			{childrenToRender}
		</components.ValueContainer>
	);
};

export default ValueContainer;
