import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectOption } from '../../../../utils/groups';
/**
 * Initial State
 */
const initialLoadState: Groups.Analysis.LoadState = {
	AnalysisData: false,
	AnalysisSubjectData: false,
	AnalysisTrends: false,
	AnalysisQualTypes: false,
	AnalysisStrategicData: false,
	AnalysisBandData: false,
};
const initialState: Groups.Analysis.State = {
	qualifications: [],
	comparisons: {},
	gradepoints: {},
	filterTypes: {},
	filterValues: [],
	groups: {},
	subjects: {},
	indicators: {},
	bands: {},
	schools: {},
	isDataUpdateAvailable: false,
	versionId: null,
	recalculationOrigin: null,
	trends: [],
	loading: initialLoadState,
	errorMessage: null,
	selectedQualification: [],
	selectedTrendId: null,
	selectedSubject: '',
	selectedFilterOptions: {
		selectedExamLevels: [],
	},
	trendSelector: {
		availableYears: undefined,
		selectedYear: undefined,
		availableKeyStages: undefined,
		selectedKeyStage: undefined,
	},
};

/**
 * Group Analysis Slice
 */
const groupAnalysisSlice = createSlice({
	name: 'groupAnalysis',
	initialState,
	reducers: {
		setAnalysisLoading(
			state: Groups.Analysis.State,
			action: PayloadAction<keyof Groups.Analysis.LoadState>
		) {
			return {
				...state,
				loading: { ...state.loading, [action.payload]: true },
				error: null,
			};
		},
		setAnalysisErrorMessage(state: Groups.Analysis.State, action: PayloadAction<string>) {
			return {
				...state,
				loading: initialLoadState,
				errorMessage: action.payload,
				data: null,
			};
		},
		setAnalysisTrends(state: Groups.Analysis.state, action: PayloadAction<Groups.Setup.Trend[]>) {
			const payload: Groups.Setup.Trend[] = action.payload;

			// No need to try select anything if we do not have trends setup
			if (!payload || payload.length === 0) {
				return { ...state, loading: { ...state.loading, AnalysisTrends: false } };
			}

			// Sort the trends ascending
			const sortedTrends: Groups.Setup.Trend[] = [...payload].sort((a, b) => {
				return a.id - b.id;
			});
			// Get selected trend id
			const selectedTrendId =
				state.selectedTrendId || (sortedTrends.length && sortedTrends[0].id) || [];

			return {
				...state,
				loading: { ...state.loading, AnalysisTrends: false },
				trends: sortedTrends,
				selectedTrendId,
				errorMessage: null,
			};
		},
		setGroupTrend(state: Groups.Analysis.State, action: PayloadAction<number>) {
			const selectedTrendId: number = action.payload;
			return {
				...state,
				selectedTrendId,
			};
		},
		clearGroupTrend(state: Groups.Analysis.State) {
			return {
				...state,
				selectedTrendId: null,
			};
		},
		setAnalysisData(state: Groups.Analysis.State, action: PayloadAction<any>) {
			const {
				versionId,
				recalculationOrigin,
				filterTypes,
				filterValues,
				gradepoints,
				comparisons,
				groups,
				subjects,
				schools,
				bands,
			} = action.payload;
			return {
				...state,
				isDataUpdateAvailable: state.versionId !== null && versionId !== state.versionId,
				versionId: versionId,
				recalculationOrigin: recalculationOrigin,
				filterTypes: filterTypes,
				filterValues: filterValues,
				gradepoints: gradepoints,
				comparisons: comparisons,
				groups: groups,
				subjects: subjects,
				schools: schools,
				bands: bands,
				loading: { ...state.loading, AnalysisData: false },
				errorMessage: null,
			};
		},
		setAnalysisQualTypes(state: Groups.Analysis.State, action: PayloadAction<any>) {
			return {
				...state,
				qualifications: action.payload,
				loading: { ...state.loading, AnalysisQualTypes: false },
				errorMessage: null,
			};
		},
		setAnalysisSubjectData(state: Groups.Analysis.State, action: PayloadAction<any>) {
			// TODO - After some investigation it appears this is not needed immediately but will be when we hook up the thermometer
			return {
				...state,
				loading: { ...state.loading, AnalysisSubjectData: false },
				errorMessage: null,
			};
		},
		setAnalysisBandData(state: Groups.Analysis.State, action: PayloadAction<any>) {
			const {
				versionId,
				recalculationOrigin,
				filterTypes,
				filterValues,
				gradepoints,
				comparisons,
				groups,
				schools,
				bands,
			} = action.payload;

			return {
				...state,
				isDataUpdateAvailable: state.versionId !== null && versionId !== state.versionId,
				versionId,
				recalculationOrigin,
				filterTypes,
				filterValues,
				gradepoints,
				comparisons,
				groups,
				schools,
				bands,
				loading: { ...state.loading, AnalysisBandData: false },
				errorMessage: null,
			};
		},
		setAnalysisStrategicData(state: Groups.Analysis.State, action: PayloadAction<any>) {
			const {
				versionId,
				recalculationOrigin,
				filterTypes,
				filterValues,
				gradepoints,
				comparisons,
				indicators,
				groups,
				schools,
				bands,
			} = action.payload;

			return {
				...state,
				isDataUpdateAvailable: state.versionId !== null && versionId !== state.versionId,
				versionId,
				recalculationOrigin,
				filterTypes,
				filterValues,
				gradepoints,
				comparisons,
				indicators,
				groups,
				schools,
				bands,
				loading: { ...state.loading, AnalysisStrategicData: false },
				errorMessage: null,
			};
		},
		setSelectedQualification(state: Groups.Analysis.State, action: PayloadAction<SelectOption[]>) {
			return {
				...state,
				selectedQualification: action.payload ?? [],
			};
		},
		setAnalysisLoadingToFalse(
			state: Groups.Analysis.State,
			action: PayloadAction<keyof Groups.Analysis.LoadState>
		) {
			return {
				...state,
				loading: { ...state.loading, [action.payload]: false },
				error: null,
			};
		},
		setSelectedSubject(state: Groups.Analysis.State, action: PayloadAction<string>) {
			return {
				...state,
				selectedSubject: action.payload,
			};
		},
		setSelectedFilterOptions(
			state: Groups.Analysis.State,
			action: PayloadAction<Groups.Analysis.SelectedFilterOptions>
		) {
			return {
				...state,
				selectedFilterOptions: action.payload,
			};
		},
		setTrendSelector(
			state: Groups.Analysis.State,
			action: PayloadAction<Groups.Analysis.TrendSelector>
		) {
			return {
				...state,
				trendSelector: action.payload,
			};
		},
	},
});

/**
 ** Export Reducers
 */
export default groupAnalysisSlice.reducer;

/**
 ** Export Actions
 */
export const {
	setAnalysisLoading,
	setAnalysisErrorMessage,
	setAnalysisData,
	setAnalysisTrends,
	setAnalysisQualTypes,
	setAnalysisSubjectData,
	setAnalysisBandData,
	setAnalysisStrategicData,
	setSelectedQualification,
	setGroupTrend,
	clearGroupTrend,
	setAnalysisLoadingToFalse,
	setSelectedSubject,
	setSelectedFilterOptions,
	setTrendSelector,
} = groupAnalysisSlice.actions;
