import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { AnalysisPages } from 'features/strategicAnalysis/enums';
import { History } from 'history';

/**
 * Initial State
 */
const initialState: StrategicAnalysis.State = {
	loading: false,
	exportExcel: false,
	selectedDefaultExamLevels: [],
	appliedDefaultExamLevels: [],
	selectedPageExamLevels: {},
	appliedPageExamLevels: {},
	activeExamLevelTab: {},
	selectedDefaultFilters: [],
	selectedPageFilters: {},
	appliedPageFilters: {},
	selectedDefaultComparisons: [],
	selectedPageComparisons: {},
	appliedPageComparisons: {},
	expandedComparisonRows: [],
	thermometerDisplayState: true,
	hasActiveAnalysisSession: false,
	showStrategicSessionModal: false,
	showStrategicPdfReportModal: false,
	pdfReport: {
		examLevel: undefined,
		reportTemplate: undefined,
		customReportPages: undefined,
		comparisons: undefined,
	},
};

/**
 * Helper function for setting default comparisons and filters to all pages
 */
const setDefaultsToAllPages = <T>(defaults: T): StrategicAnalysis.AnalysisOption<T> => {
	return Object.values(AnalysisPages).reduce((acc, curr) => {
		return {
			...acc,
			[curr]: defaults,
		};
	}, {});
};

/**
 * Strategic Analysis Slice
 */
const strategicAnalysis = createSlice({
	name: 'strategicAnalysis',
	initialState,
	reducers: {
		// This has to be setStrategicLoading as all the slices listen for this action type to trigger their loading states
		setStrategicLoading(state: StrategicAnalysis.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},
		handleExcelExport(state: StrategicAnalysis.State, action: PayloadAction<boolean>) {
			return {
				...state,
				exportExcel: action.payload,
			};
		},
		setSelectedDefaultExamLevels(
			state: StrategicAnalysis.State,
			action: PayloadAction<StrategicAnalysis.ExamLevelOption>
		) {
			return {
				...state,
				selectedDefaultExamLevels: action.payload ?? [],
			};
		},
		setActiveExamLevelTab(
			state: StrategicAnalysis.State,
			action: PayloadAction<{
				strategicAnalysisPage: StrategicAnalysis.AnalysisPage;
				tab: StrategicAnalysis.ActiveExamLevelTab;
			}>
		) {
			const { strategicAnalysisPage, tab } = action.payload;

			return {
				...state,
				activeExamLevelTab: {
					...state.activeExamLevelTab,
					[strategicAnalysisPage]: tab,
				},
			};
		},
		updateSelectedPageExamLevels(
			state: StrategicAnalysis.State,
			action: PayloadAction<{
				strategicAnalysisPage: StrategicAnalysis.AnalysisPage;
				option: StrategicAnalysis.ExamLevelOption;
			}>
		) {
			const { strategicAnalysisPage, option } = action.payload;

			return {
				...state,
				selectedPageExamLevels: {
					...state.selectedPageExamLevels,
					[strategicAnalysisPage]: option ?? [],
				},
			};
		},
		setSelectedDefaultFilters(
			state: StrategicAnalysis.State,
			action: PayloadAction<StrategicAnalysis.FilterOption>
		) {
			return {
				...state,
				selectedDefaultFilters: action.payload ?? [],
			};
		},
		updateSelectedPageFilters(
			state: StrategicAnalysis.State,
			action: PayloadAction<{
				strategicAnalysisPage: StrategicAnalysis.AnalysisPage;
				option: StrategicAnalysis.FilterOption;
			}>
		) {
			const { strategicAnalysisPage, option } = action.payload;

			return {
				...state,
				selectedPageFilters: {
					...state.selectedPageFilters,
					[strategicAnalysisPage]: option ?? [],
				},
			};
		},
		resetSelectedPageFilters(
			state: StrategicAnalysis.State,
			action: PayloadAction<{
				strategicAnalysisPage: StrategicAnalysis.AnalysisPage;
			}>
		) {
			const { strategicAnalysisPage } = action.payload;

			return {
				...state,
				selectedPageFilters: {
					...state.selectedPageFilters,
					[strategicAnalysisPage]: [],
				},
			};
		},
		setSelectedDefaultComparisons(
			state: StrategicAnalysis.State,
			action: PayloadAction<StrategicAnalysis.ComparisonOption>
		) {
			return {
				...state,
				selectedDefaultComparisons: action.payload ?? [],
			};
		},
		updateSelectedPageComparisons(
			state: StrategicAnalysis.State,
			action: PayloadAction<{
				strategicAnalysisPage: StrategicAnalysis.AnalysisPage;
				option: StrategicAnalysis.ComparisonOption;
			}>
		) {
			const { strategicAnalysisPage, option } = action.payload;

			return {
				...state,
				selectedPageComparisons: {
					...state.selectedPageComparisons,
					[strategicAnalysisPage]: option,
				},
			};
		},
		setSessionDefaults(state: StrategicAnalysis.State) {
			return {
				...state,
				appliedDefaultExamLevels: state.selectedDefaultExamLevels,
				selectedPageExamLevels: setDefaultsToAllPages<StrategicAnalysis.ExamLevelOption>(
					state.selectedDefaultExamLevels
				),
				appliedPageExamLevels: setDefaultsToAllPages<StrategicAnalysis.ExamLevelOption>(
					state.selectedDefaultExamLevels
				),
				activeExamLevelTab: setDefaultsToAllPages<StrategicAnalysis.ActiveExamLevelTab>({
					examLevel: state.selectedDefaultExamLevels[0].value,
					index: 0,
				}),
				selectedPageFilters: setDefaultsToAllPages<StrategicAnalysis.FilterOption>(
					state.selectedDefaultFilters
				),
				appliedPageFilters: setDefaultsToAllPages<StrategicAnalysis.FilterOption>(
					state.selectedDefaultFilters
				),
				selectedPageComparisons: setDefaultsToAllPages<StrategicAnalysis.ComparisonOption>(
					state.selectedDefaultComparisons
				),
				appliedPageComparisons: setDefaultsToAllPages<StrategicAnalysis.ComparisonOption>(
					state.selectedDefaultComparisons
				),
				hasActiveAnalysisSession: true,
			};
		},
		updatePageConfiguration(state: StrategicAnalysis.State) {
			return {
				...state,
				appliedPageComparisons: state.selectedPageComparisons,
				appliedPageExamLevels: state.selectedPageExamLevels,
				appliedPageFilters: state.selectedPageFilters,
			};
		},
		setExpandedComparisonRows(state: StrategicAnalysis.State, action: PayloadAction<string[]>) {
			const { expandedComparisonRows } = state;
			const { payload } = action;
			const shouldClose = expandedComparisonRows.some((row) => payload.includes(row));
			const rows = shouldClose
				? expandedComparisonRows.filter((row) => !payload.includes(row))
				: //@ts-ignore
				  [...expandedComparisonRows, ...payload];

			return {
				...state,
				expandedComparisonRows: rows,
			};
		},
		setAllExpandedComparisonRows(state: StrategicAnalysis.State, action: PayloadAction<string[]>) {
			return {
				...state,
				expandedComparisonRows: action.payload ?? [],
			};
		},
		toggleThermometerDisplayState(state: StrategicAnalysis.State) {
			return {
				...state,
				thermometerDisplayState: !state.thermometerDisplayState,
			};
		},
		/**
		 * If the gradepoint is changed we need to clear out everything from state
		 * apart from the applied default session exam levels
		 */
		resetSessionOnGradepointChange(state: StrategicAnalysis.State) {
			return {
				...state,
				exportExcel: false,
				selectedPageExamLevels: {},
				appliedPageExamLevels: {},
				selectedDefaultFilters: [],
				selectedPageFilters: {},
				appliedPageFilters: {},
				selectedDefaultComparisons: [],
				selectedPageComparisons: {},
				appliedPageComparisons: {},
				expandedComparisonRows: [],
				showStrategicSessionModal: false,
			};
		},
		resetSummaryOnGradepointChange(state: StrategicAnalysis.State) {
			return {
				...state,
				loading: false,
				selectedDefaultExamLevels: [],
				selectedDefaultComparisons: [],
			};
		},
		clearSessionData() {
			return initialState;
		},
		showStrategicSessionModal(state: StrategicAnalysis.State) {
			return {
				...state,
				showStrategicSessionModal: !state.showStrategicSessionModal,
			};
		},
		showStrategicPdfReportModal(state: StrategicAnalysis.State) {
			return {
				...state,
				showStrategicPdfReportModal: !state.showStrategicPdfReportModal,
			};
		},
		resetPdfReport(state: StrategicAnalysis.State) {
			return {
				...state,
				pdfReport: {
					examLevel: [],
					reportTemplate: [],
					customReportPages: [],
					comparisons: [],
				},
			};
		},
		setPdfReportExamLevel(
			state: StrategicAnalysis.State,
			action: PayloadAction<StrategicAnalysis.ExamLevelOption>
		) {
			return {
				...state,
				pdfReport: {
					...state.pdfReport,
					examLevel: !!action.payload ? action.payload : [],
					reportTemplate: [],
					customReportPages: [],
					comparisons: [],
				},
			};
		},
		setPdfReportTemplate(
			state: StrategicAnalysis.State,
			action: PayloadAction<StrategicAnalysis.PdfReportTemplateOption>
		) {
			return {
				...state,
				pdfReport: {
					...state.pdfReport,
					reportTemplate: !!action.payload ? [action.payload] : [],
					customReportPages: [],
					comparisons: [],
				},
			};
		},
		setPdfReportCustomReportPages(
			state: StrategicAnalysis.State,
			action: PayloadAction<StrategicAnalysis.PdfReportCustomPageOption[]>
		) {
			return {
				...state,
				pdfReport: {
					...state.pdfReport,
					customReportPages: !!action.payload ? action.payload : [],
					comparisons: [],
				},
			};
		},
		setPdfReportComparison(
			state: StrategicAnalysis.State,
			action: PayloadAction<StrategicAnalysis.ComparisonOption>
		) {
			return {
				...state,
				pdfReport: {
					...state.pdfReport,
					comparisons: !!action.payload ? action.payload : [],
				},
			};
		},
	},
});
/**
 * Saga watch actions
 * The following actions act as triggers for saga flows.
 * They're here because they don't directly update state, so we don't want a corresponding reducer
 */
export const startNewStrategicSession = createAction<{ history: History<unknown> }>(
	'strategicAnalysis/startNewStrategicSession'
);

export const changeActiveExamLevelTab = createAction<{
	tab?: { examLevel: StrategicAnalysis.ExamLevels; index: number };
}>('strategicAnalysis/changeActiveExamLevelTab');

export const changeGradepointTrend = createAction('strategicAnalysis/changeGradepointTrend');

export const changePageConfiguration = createAction<{
	examLevelStatesAreEqual: boolean;
	comparisonStatesAreEqual: boolean;
	isReset?: boolean;
}>('strategicAnalysis/changePageConfiguration');

export const fetchNewPageData = createAction<{
	strategicAnalysisPage: string;
	tab: { examLevel: StrategicAnalysis.ExamLevels; index: number };
}>('strategicAnalysis/fetchNewPageData');

/**
 * These actions are standard redux actions
 */
export const {
	setStrategicLoading,
	handleExcelExport,
	setSelectedDefaultExamLevels,
	updateSelectedPageExamLevels,
	setActiveExamLevelTab,
	setSelectedDefaultFilters,
	updateSelectedPageFilters,
	resetSelectedPageFilters,
	setSelectedDefaultComparisons,
	updateSelectedPageComparisons,
	setSessionDefaults,
	updatePageConfiguration,
	setExpandedComparisonRows,
	setAllExpandedComparisonRows,
	toggleThermometerDisplayState,
	resetSessionOnGradepointChange,
	resetSummaryOnGradepointChange,
	clearSessionData,
	showStrategicSessionModal,
	showStrategicPdfReportModal,
	resetPdfReport,
	setPdfReportExamLevel,
	setPdfReportTemplate,
	setPdfReportCustomReportPages,
	setPdfReportComparison,
} = strategicAnalysis.actions;

export default strategicAnalysis.reducer;
