import { translate } from '../../../utils/locale';

const isVisible = (p: string, param: string): boolean => param !== p;

/**
 ** Connect Routes
 */
export const connectPerformanceMetrics: (
	params: { [key: string]: string },
	isLoading: boolean,
	canViewKS4SubjectPages: boolean,
	canViewProgress8Measures: boolean
) => PrimaryNavigation.Route[] = (
	params,
	isLoading,
	canViewKS4SubjectPages,
	canViewProgress8Measures
) => {
	return [
		{
			name: translate('primaryNavigation.connect.ks4Measures.ATTAINMENT') as string,
			path: '/attainment-measures',
			isDisabled: isLoading,
			isVisible:
				canViewKS4SubjectPages && isVisible('attainment-measures', params.performanceMetrics),
			type: 'performanceMetrics',
		},
		{
			name: translate('primaryNavigation.connect.ks4Measures.PROGRESS') as string,
			path: '/progress-8-measures',
			isDisabled: isLoading,
			isVisible:
				canViewProgress8Measures && isVisible('progress-8-measures', params.performanceMetrics),
			type: 'performanceMetrics',
		},
	];
};
