import { SagaIterator } from '@redux-saga/types';
import { put, select, delay, cancel, call, retry } from 'redux-saga/effects';
import { getSubjectAreaPage } from './sagaUtils';
import {
	fetchSubjectAreaSubjectId,
	fetchSubjectAreaActiveExamLevel,
	getSelectedSubjectAreaComparisons,
} from 'features/subjectArea/redux/selectors';
import { SubjectAreaPages } from 'features/subjectArea/enums';
import {
	getAppliedGradepoints,
	getBenchmarkSetName,
	getRawAvailableComparisonsByAppliedKeys,
} from 'features/app/redux/context';
import {
	fetchAllComparisonChartData,
	fetchAllComparisonGridData,
} from '../../../../api/subjectAreaAPI';
import { CommonAgGridChartStateTypes } from 'types/commonAgGridTypes';
import { Context } from 'features/app/types/index';
import { flattenResponse } from '../../../../utils/agGridUtils/agGridComparisonsHelpers';
import { setsubjectAreaLoading } from '../slice';

/**
 * Saga workflow to handle the changing of the comparisons within
 */
export function* changeComprisonsFlow(): SagaIterator<void> {
	//Set Subject Area Loading
	yield put(setsubjectAreaLoading(true));

	//Figure out what page we are on
	const page = yield call(getSubjectAreaPage);

	//Find out what the current subject area Id
	const subjectAreaIdSet = yield select(fetchSubjectAreaSubjectId);

	//If the subject Id is undefined or we arent on the subject area pages cancel
	if (subjectAreaIdSet === undefined && page !== SubjectAreaPages) {
		yield put(setsubjectAreaLoading(false));
		yield cancel();
	}

	//Find out what examlevel we are on - this will be 0 but will allow future reusuablity with the selectors
	const currentExamLevel = yield select(fetchSubjectAreaActiveExamLevel);

	//Fetch the comparisons applied to the subject area - this will match the Subject overview and Subject page (depricated)
	const appliedComparisons = yield select(getSelectedSubjectAreaComparisons);

	//Format the applied the comparisons to have comparison ids
	const availableComparisons = yield select(
		getRawAvailableComparisonsByAppliedKeys(appliedComparisons)
	);

	//Benchmark set
	const benchmarkSet: string = yield select(getBenchmarkSetName);

	//Applied Gradepoints
	const gradepoints: Context.Gradepoint[] = yield select(getAppliedGradepoints);

	const SECOND = 1000;
	try {
		//Fetch the Grid Data
		const gridResponse: ResponseBuilder<{
			comparisons: CommonAgGridChartStateTypes.Comparisons<CommonAgGridChartStateTypes.ResponseRow>;
		}> = yield retry(
			3, // Try calling 3 times
			10 * SECOND, // with a delay of 10 seconds
			fetchAllComparisonGridData, // API to call
			page, // With these args
			currentExamLevel,
			benchmarkSet,
			gradepoints,
			subjectAreaIdSet,
			availableComparisons
		);

		//Fetch the Graph data
		const graphResponse: ResponseBuilder<{
			comparisons: CommonAgGridChartStateTypes.XAxisValues<
				CommonAgGridChartStateTypes.ResponseXAxisValues
			>;
		}> = yield retry(
			3, // Try calling 3 times
			10 * SECOND, // with a delay of 10 seconds
			fetchAllComparisonChartData, // API to call
			page, // With these args
			currentExamLevel,
			benchmarkSet,
			gradepoints,
			subjectAreaIdSet,
			availableComparisons
		);

		//Check the response builder to see if any errors occurred or response is empty
		if (!graphResponse || graphResponse.hasError) {
			//If theres a problem deal with it
			yield put({
				type: `subjectArea${page}/setComparisonDataFail`,
				payload: !graphResponse ? 'Graph data is empty' : graphResponse.Errors[0],
			});
			yield put(setsubjectAreaLoading(false));
			yield cancel();
		}

		//Add data if successful
		yield put({
			type: `subjectArea${page}/setAllGraphComparisonDataSuccess`,
			payload: graphResponse.responseObject.comparisons,
		});

		//Check the response builder to see if any errors occurred or response is empty
		if (!gridResponse || gridResponse.hasError) {
			//If theres a problem deal with it
			yield put({
				type: `subjectArea${page}/setComparisonDataFail`,
				payload: !gridResponse ? 'Grid data is empty' : gridResponse.Errors[0],
			});
			yield put(setsubjectAreaLoading(false));
			yield cancel();
		}

		// Flatten the gridValues object in the response
		const flatten = flattenResponse(gridResponse.responseObject.comparisons);

		// put the data in redux
		yield put({ type: `subjectArea${page}/setAllComparisonDataSuccess`, payload: flatten });
		//  set the expanded rows
		yield put({
			type: `subjectArea${page}/setAllExpandedComparisonRows`,
			payload: flatten.allIds,
		});
	} catch (e) {
		//Error handling
		yield put({
			type: `subjectArea${page}/setComparisonDataFail`,
			payload: e,
		});
	}

	//Allow state to update
	yield delay(1000);

	//set loading to false
	yield put(setsubjectAreaLoading(false));
}
