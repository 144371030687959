import { translate } from '../../../../utils/locale';
import { getSubscriptionPricing } from '../../../../api/subscriptionPricingAPI';
import { updateErrorMessage, updateSubscriptionPrices } from '../slice';
import { getFeature } from 'features/app/redux/features';

export const getSubscriptionPricingThunk = (): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const useOldPricing = getFeature('use_old_pricing')(state);

	// retrieve the subscription pricing from the API
	const subscriptionPricing = await getSubscriptionPricing(useOldPricing);
	if (subscriptionPricing.HasError) {
		dispatch(
			updateErrorMessage(
				translate('manageAccount.subscriptions.errors.FAILED_TO_FETCH_DATA') as string
			)
		);
	} else {
		dispatch(updateSubscriptionPrices(subscriptionPricing.ResponseObject));
	}
};
