import fetch from './';
import { store } from '../store/store';
import { getGroupAccessId } from 'features/groups/setup/redux';
import {
	getSchools as getSchoolsBase,
	getTrends as getTrendsBase,
	getContractInfo as getContractInfoBase,
	getCustomComparisons as getCustomComparisonsBase,
} from './groupsAPI';
import { withGroupAccessOptions as utilGroupAccessOption } from '../utils/apiHelpers';

/*
 ** Adds the groupAccessId header for the current selected academic year
 ** Func must take an options object as its first param, which is then used to fetch
 ** Stupidly overengineered, may replace
 */
//TODO: This function has been moved to a util, please update the below with the imported funtion from apiHelpers - utilGroupAccessOption
const withGroupAccessOptions = <T, TArgs extends any[]>(
	func: (options: any, ...args: TArgs) => T
) => {
	return (...args: TArgs) => {
		const state = store.getState();
		const groupAccessId = getGroupAccessId(state);

		return func(
			{
				headers: {
					groupAccessId,
				},
			},
			...args
		);
	};
};

/**
 ** Get group access records
 */
export const getGroupAccessRecords = () => {
	return fetch('/api/groups/access');
};

/**
 ** Get access summary
 */
export const getGroupSetupPreviousSummary = withGroupAccessOptions((options: any) => {
	return fetch('/api/groups/rollover/previous', options);
});

/**
 ** Get access summary
 */
export const getGroupSetupSummary = withGroupAccessOptions((options: any) => {
	return fetch('/api/groups/rollover/current', options);
});

/**
 * Perform rollover into selected group
 */
export const postRollover = withGroupAccessOptions((options: any) => {
	return fetch('/api/groups/rollover', {
		...options,
		body: {},
	});
});

/**
 * Get the schools from the Group/Schools
 */
export const getSchools = withGroupAccessOptions(getSchoolsBase);

/**
 * Get School Contract info
 */
export const getContractInfo = withGroupAccessOptions(getContractInfoBase);

/**
 * Put School Contract info
 */
export const putContractInfo = withGroupAccessOptions(
	(options: any, year: Groups.Setup.SchoolContract[]): Promise<any> => {
		return fetch(
			'/api/Groups/SchoolContract/Schools',
			{
				...options,
				method: 'PUT',
				body: year,
			},
			true
		);
	}
);

/**
 * Get the trends from Groups/Trends
 */
export const getTrends = utilGroupAccessOption(getTrendsBase);

/**
 ** Save group trends
 */
// Handle saving of new trends
export const saveNewTrends = withGroupAccessOptions(
	(
		options: any,
		newTrends: Groups.Setup.Trend[]
	): Promise<ResponseBuilder<Array<Groups.Setup.Trend[]>>> => {
		return fetch('/api/Groups/Trends', {
			method: 'POST',
			body: newTrends,
			...options,
		});
	}
);

// Handle saving of edited trends
export const saveEditedTrends = withGroupAccessOptions(
	(
		options: any,
		editedTrends: Groups.Setup.Trend[]
	): Promise<ResponseBuilder<Array<Groups.Setup.Trend[]>>> => {
		return fetch('/api/Groups/Trends', {
			method: 'PUT',
			body: editedTrends,
			...options,
		});
	}
);

// Handle saving of deleted trends
export const saveDeletedTrends = withGroupAccessOptions(
	(
		options: any,
		deletedTrends: Groups.Setup.Trend[]
	): Promise<ResponseBuilder<Array<Groups.Setup.Trend[]>>> => {
		return fetch('/api/Groups/Trends', {
			method: 'DELETE',
			body: deletedTrends.map((item) => item.id),
			...options,
		});
	}
);

//#region Group Monitoring Points
/**
 * Get group monitoring points
 */
export const getGroupGradepoints = withGroupAccessOptions(
	(options: any): Promise<ResponseBuilder<Array<Groups.Setup.MonitoringPoint>>> =>
		fetch('/api/Groups/Gradepoints', options, true)
);

/**
 * Add new monitoring points
 * @param groupMonitoringPoints The monitoring points to add
 */
export const addGroupGradepoints = withGroupAccessOptions(
	(
		options: any,
		groupMonitoringPoints: Array<Groups.Setup.MonitoringPoint>
	): Promise<ResponseBuilder<Array<Groups.Setup.MonitoringPoint>>> => {
		return fetch(
			'/api/Groups/Gradepoints',
			{
				body: groupMonitoringPoints,
				method: 'POST',
				...options,
			},
			true
		);
	}
);

/**
 * Update existing monitoring points
 * @param groupMonitoringPoints The monitoring points to update
 */
export const updateGroupGradepoints = withGroupAccessOptions(
	(
		options: any,
		groupMonitoringPoints: Array<Groups.Setup.MonitoringPoint>
	): Promise<ResponseBuilder<Array<Groups.Setup.MonitoringPoint>>> => {
		return fetch(
			'/api/Groups/Gradepoints',
			{
				body: groupMonitoringPoints,
				method: 'PUT',
				...options,
			},
			true
		);
	}
);

/**
 * Delete monitoring points
 * @param groupMonitoringPointIds A list of ids belonging to monitoring points that will be deleted
 */
export const deleteGroupGradepoints = withGroupAccessOptions(
	(
		options: any,
		groupMonitoringPointIds: Array<number>
	): Promise<ResponseBuilder<Array<Groups.Setup.MonitoringPoint>>> => {
		return fetch(
			'/api/Groups/Gradepoints',
			{
				body: groupMonitoringPointIds,
				method: 'DELETE',
				...options,
			},
			true
		);
	}
);

/**
 * Update the lock on existing monitoring points
 * @param groupMonitoringPoints The monitoring points to update the lock value
 */
export const lockUpdateGroupGradepoints = withGroupAccessOptions(
	(
		options: any,
		groupMonitoringPoints: Array<Groups.Setup.MonitoringPoint>
	): Promise<ResponseBuilder<Array<Groups.Setup.MonitoringPoint>>> => {
		return fetch(
			'/api/Groups/Gradepoints/Locking',
			{
				body: groupMonitoringPoints,
				method: 'PUT',
				...options,
			},
			true
		);
	}
);
//#endregion Group Monitoring Points

//#region Custom Columns
/**
 * Get a array of custom columns for the current MAT
 */
export const getCustomColumns = withGroupAccessOptions(
	(options: any): Promise<ResponseBuilder<Array<Groups.Setup.CustomColumn>>> =>
		fetch('/api/Groups/CustomColumns', options, true)
);

/**
 * Add new custom columns
 * @param groupCustomColumns The custom columns to add
 */
export const addCustomColumns = withGroupAccessOptions(
	(
		options: any,
		groupCustomColumns: Array<Groups.Setup.CustomColumn>
	): Promise<ResponseBuilder<Array<Groups.Setup.CustomColumn>>> => {
		return fetch(
			'/api/Groups/CustomColumns',
			{
				body: groupCustomColumns,
				method: 'POST',
				...options,
			},
			true
		);
	}
);

/**
 * Update existing custom columns
 * @param groupCustomColumns The custom columns to update
 */
export const updateCustomColumns = withGroupAccessOptions(
	(
		options: any,
		groupCustomColumns: Array<Groups.Setup.CustomColumn>
	): Promise<ResponseBuilder<Array<Groups.Setup.CustomColumn>>> => {
		return fetch(
			'/api/Groups/CustomColumns',
			{
				body: groupCustomColumns,
				method: 'PUT',
				...options,
			},
			true
		);
	}
);

/**
 * Delete custom columns
 * @param groupCustomColumnsIds A list of ids belonging to custom column that will be deleted
 */
export const deleteCustomColumns = withGroupAccessOptions(
	(
		options: any,
		groupCustomColumnsIds: Array<number>
	): Promise<ResponseBuilder<Array<Groups.Setup.CustomColumn>>> => {
		return fetch(
			'/api/Groups/CustomColumns',
			{
				body: groupCustomColumnsIds,
				method: 'DELETE',
				...options,
			},
			true
		);
	}
);
//#endregion Custom Columns

//#region School Groups
/**
 * Get all school groups
 */
export const getSchoolGroups = withGroupAccessOptions(
	(options: any): Promise<ResponseBuilder<Array<Groups.Setup.SchoolGroup>>> =>
		fetch('/api/Groups/SchoolGroup', options, true)
);

/**
 * Add new school groups
 * @param schoolGroups The school groups to add
 */
export const addSchoolGroups = withGroupAccessOptions(
	(
		options: any,
		schoolGroups: Array<Groups.Setup.SchoolGroup>
	): Promise<ResponseBuilder<Array<Groups.Setup.SchoolGroup>>> => {
		return fetch(
			'/api/Groups/SchoolGroup',
			{
				body: schoolGroups,
				method: 'POST',
				...options,
			},
			true
		);
	}
);

/**
 * Update existing school groups
 * @param schoolGroups The school groups to update
 */
export const updateSchoolGroups = withGroupAccessOptions(
	(
		options: any,
		schoolGroups: Array<Groups.Setup.SchoolGroup>
	): Promise<ResponseBuilder<Array<Groups.Setup.SchoolGroup>>> => {
		return fetch(
			'/api/Groups/SchoolGroup',
			{
				body: schoolGroups,
				method: 'PUT',
				...options,
			},
			true
		);
	}
);

/**
 * Delete school groups
 * @param schoolGroupIds A list of ids belonging to school groups that will be deleted
 */
export const deleteSchoolGroups = withGroupAccessOptions(
	(
		options: any,
		schoolGroupIds: Array<Number>
	): Promise<ResponseBuilder<Array<Groups.Setup.SchoolGroup>>> => {
		return fetch(
			'/api/Groups/SchoolGroup',
			{
				body: schoolGroupIds,
				method: 'DELETE',
				...options,
			},
			true
		);
	}
);
//#endregion School Groups

//#region Custom Comparisons
/**
 * Get a list of custom comparisons
 */
export const getCustomComparisons = utilGroupAccessOption(getCustomComparisonsBase);

/**
 * Add a new custom comparison
 * @param customComparisons The custom comparisons to add
 */
export const addCustomComparisons = withGroupAccessOptions(
	(
		options: any,
		customComparisons: Array<Groups.Setup.ComparisonGroup>
	): Promise<ResponseBuilder<Array<Groups.Setup.ComparisonGroup>>> => {
		return fetch(
			'/api/Groups/Comparisons',
			{
				body: customComparisons,
				method: 'POST',
				...options,
			},
			true
		);
	}
);

/**
 * Update existing custom comparisons
 * @param customComparisons The custom comparisons to update
 */
export const updateCustomComparisons = withGroupAccessOptions(
	(
		options: any,
		customComparisons: Array<Groups.Setup.ComparisonGroup>
	): Promise<ResponseBuilder<Array<Groups.Setup.ComparisonGroup>>> => {
		return fetch(
			'/api/Groups/Comparisons',
			{
				body: customComparisons,
				method: 'PUT',
				...options,
			},
			true
		);
	}
);

/**
 * Delete custom comparisons
 * @param customComparisonIds A list of ids belonging to custom comparisons that will be deleted
 */
export const deleteCustomComparisons = withGroupAccessOptions(
	(
		options: any,
		customComparisonIds: Array<Number>
	): Promise<ResponseBuilder<Array<Groups.Setup.ComparisonGroup>>> => {
		return fetch(
			'/api/Groups/Comparisons',
			{
				body: customComparisonIds,
				method: 'DELETE',
				...options,
			},
			true
		);
	}
);
//#endregion Custom Comparisons
