/**
 * All Srategic Analysis Pages
 * Maps to route params
 */
// export enum AnalysisPages {
// 	OVERVIEW = 'overview',
// 	BBPA_CURRENT_YEAR = 'bbpa-current-year',
// 	BBPA_MULTI_YEAR = 'bbpa-multi-year',
// 	ATTAINMENT_PROFILE = 'raw-results',
// 	QUALITY_INDICATOR = 'quality-indicator',
// 	SUBJECT_VALUE_ADDED = 'subject-value-added',
// 	QUALITY_OF_TEACHING = 'quality-of-teaching',
// 	T_SCORE = 't-score',
// 	GAP_ANALYSIS = 'gap-analysis',
// 	STUDENTS_NOT_INCLUDED = 'students-not-included',
// 	CURRICULUM_BALANCE_FACTOR = 'curriculum-balance-factor',
// }
// TODO - change this back to an enum when we upgrade Typescript
export const AnalysisPages = {
	SUMMARY: 'Summary',
	OVERVIEW: 'Overview',
	BBPA_CURRENT_YEAR: 'BbpaCurrentYear',
	BBPA_MULTI_YEAR: 'BbpaMultiYear',
	ATTAINMENT_PROFILE: 'AttainmentProfile',
	QUALITY_INDICATOR: 'QualityIndicator',
	SUBJECT_VALUE_ADDED: 'SubjectValueAdded',
	QUALITY_OF_TEACHING: 'QualityOfTeaching',
	T_SCORE: 'Tscore',
	STUDENTS_NOT_INCLUDED: 'StudentsNotIncluded',
} as const;

/**
 * Ag grid row type
 */
export enum RowType {
	ALL = 1,
	COMPARISON = 2,
	EMBEDDED = 3,
	FULL_WIDTH = 4,
	TOTAL = 5,
}

/**
 * Grid Types
 */
export enum GridType {
	A_LEVEL_AND_LIBF_DIP = 1,
	WBQ = 2,
	EPQ = 3,
	L_3_CORE_MATHS = 4,
	AS = 5,
	LIBF_CERT = 6,
	'16_EXT_DIPS' = 7,
	'16_DIPS' = 8,
	'16_EXT_CERTS' = 9,
	'10_12_EXT_DIPS' = 10,
	'10_12_DIPS' = 11,
	'10_12_SUB_INT_DIPS' = 12,
	CACHE_AWARD = 13,
	CACHE_CERTS = 14,
	CACHE_DIPS_TECH_DIPS = 15,
	CACHE_EXT_DIP_TECH_EXT_DIP = 16,
	UAL_EXT_DIP = 17,
	WJEC_CERT_A_G = 18,
	WJEC_DIP_A_G = 19,
	WJEC_CERT_D_P = 20,
	WJEC_DIP_D_P = 21,
	'16_CERT' = 22,
	'10_12_CERT' = 23,
	'16_FOUND_DIP' = 24,
	'16_CT_FOUND_DIP' = 25,
	'10_12_90_CRED_CT_SUB_DIP' = 26,
	GCSE_9_1 = 27,
	GCSE_DOUBLE_AWARD_9_1 = 28,
	BTEC_FIRST_AWARD = 29,
	'10_FIRST' = 30,
	BTEC_FIRST_CERT_EXT_CERT = 31,
	CAM_NAT_AWARD = 32,
	CAM_NAT_CERT_DIP = 33,
	GCSE_A_G = 34,
	GCSE_SHORT_COURSE = 35,
	GCSE_DOUBLE_AWARD_A_G = 36,
	BTEC_TECH_AWARD = 37,
	WBQ_SKILLS_CHALLENGE = 38,
}

export enum AgGridExcelExportType {
	SPO = 1,
	DEFAULT = 2,
}
