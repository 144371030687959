import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State
 */

const initialState: NotificationModals.State = {
	pdfModalExportAcknowledge: false,
	pdfModalExportIsOpen: false,
};

/**
 * Reducer
 */
//this is for one time pop up modals
const modalMessages = createSlice({
	name: 'modalMessages',
	initialState,
	reducers: {
		setExportPDFAcknowledgement(state: NotificationModals.State) {
			state.pdfModalExportAcknowledge = true;
		},
		setExportPDFIsOpen(state: NotificationModals.State) {
			state.pdfModalExportIsOpen = true;
		},
	},
});

/**
 * Export actions
 */

export const { setExportPDFAcknowledgement, setExportPDFIsOpen } = modalMessages.actions;

/**
 * Export Reducers
 */

export default modalMessages.reducer;

/**
 * Export Selectors
 */

export const getPdfExportModalAcknowledgment = (state: RootState): boolean =>
	state.modalMessages.pdfModalExportAcknowledge;

export const getPdfExportModalIsOpen = (state: RootState): boolean =>
	state.modalMessages.pdfModalExportIsOpen;
