import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSubjectsOverview } from '../../../api/reportAPI';
import {
	getAppliedGradepoints,
	getAvailableSubjectComparisons,
	getPrimaryGradepoint,
} from '../../app/redux/context';
import { createQualTypeFilter } from '../../../utils/filters';
import subjectSort from '../../../utils/subjectOrderStringSort';
import normalizeKey from '../../../utils/normalizeKey';
import { SubjectsOverview } from '../types';
import { getComparisonsForSubjects } from 'features/analysisSettings/redux';
import { sortComparisonStudentViewGroups } from '../../../utils/studentGroup';
import { SubjectArea } from 'features/subjectArea/types';
import { fetchBedrockSubjectsThunk } from 'features/subjectLookUp/redux/thunks/bedrockLookUpThunk';

/* Basically just waiting on Sue to make a final decision on this, but for now
 *  this is togglable to resolve this:
 *  https://dev.azure.com/alpsva/Alps%20Software/_workitems/edit/13083
 *
 *  When everyone is happy, this flag can be removed and just access the key directly
 * where needed
 *  */
const SUBJECT_NAME_KEY: 'CompactName' | 'DisplayName' = 'CompactName';

const initialState: SubjectsOverview.State = {
	loading: false,
	data: null,
	errorMessage: null,
	showTeachingSets: false,
	showInOneTable: false,
	hasNoInYearComparisons: false,
};

const subjectsOverviewSlice = createSlice({
	name: 'subjectsOverview',
	initialState,
	reducers: {
		setShowInOneTable(state: SubjectsOverview.State, action: PayloadAction<boolean>) {
			return {
				...state,
				showInOneTable: action.payload,
			};
		},
		setShowTeachingSets(state: SubjectsOverview.State, action: PayloadAction<boolean>) {
			return {
				...state,
				showTeachingSets: action.payload,
			};
		},
		setLoading(state: SubjectsOverview.State) {
			return {
				...state,
				loading: true,
				errorMessage: null,
			};
		},
		setSubjectsOverviewData(
			state: SubjectsOverview.State,
			action: PayloadAction<Report.SubjectsOverview>
		) {
			return {
				...state,
				loading: false,
				errorMessage: null,
				data: action.payload,
			};
		},
		setErrorMessage(state: SubjectsOverview.State, action: PayloadAction<string>) {
			return {
				...state,
				loading: false,
				errorMessage: action.payload,
				data: null,
			};
		},
		clearErrorMessage(state: SubjectsOverview.State) {
			return {
				...state,
				errorMessage: null,
			};
		},
		clearLoading(state: SubjectsOverview.State) {
			state.loading = false;
		},
		setNoInYearComparisons(state: SubjectsOverview.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: false,
				hasNoInYearComparisons: action.payload,
			};
		},
	},
});

/**
 ** Export Reducers
 */
export default subjectsOverviewSlice.reducer;

/**
 ** Export Actions
 */

export const {
	setSubjectsOverviewData,
	setLoading,
	clearErrorMessage,
	setErrorMessage,
	setShowTeachingSets,
	setShowInOneTable,
	clearLoading,
	setNoInYearComparisons,
} = subjectsOverviewSlice.actions;

/**
 ** Export Selectors
 */
export const isLoading = (state: RootState) => state.subjectsOverview.loading;

export const getErrorMessage = (state: RootState) => state.subjectsOverview.errorMessage;

export const hasError = (state: RootState) => getErrorMessage(state) !== null;

export const getShowTeachingSets = (state: RootState) => state.subjectsOverview.showTeachingSets;

export const getShowInOneTable = (state: RootState) => state.subjectsOverview.showInOneTable;

export const getCurrentSubjectNumericId = (subjectName: string) => (state: RootState): number => {
	return state.subjectsOverview?.data?.Subjects.filter(
		(subject: any) =>
			subject.Subject.Name === subjectName || subject.Subject.DisplayName === subjectName
	)[0]?.Subject?.SubjectId;
};

export const getSubjectAreaActiveExamLevel = (subjectName: string) => (
	state: RootState
): SubjectArea.ExamLevels => {
	return state.subjectsOverview?.data?.Subjects.filter(
		(subject: any) =>
			subject.Subject.Name === subjectName || subject.Subject.DisplayName === subjectName
	)[0]?.ExamLevel;
};

/* The data that comes into this function looks like:
 * ActualPoints: 780
 * ExpectedPoints: 765.89
 * GradepointKey: "2019,2,20.Y12 Feb"
 * Name: "20.Y12 Feb"
 * Scores:
 * 		All:
 * 			AvgPA: 4.63
 * 			Display: "1.01"
 * 			DisplayUnadorned: "1.01"
 * 			Entries: 10
 * 			Grade: 2
 * 			StudentPoints: {1:120, 2:120, 3:100, 4:80...}
 * 			Students: 10
 * 			Type: "GCSEScore"
 * 			Value: 1.01411
 * 		Female:
 * 			...
 * TeachingSets: {}
 */
const reduceGroup = (
	{ obj, key, match, studentGroup, includeSuffix = false }: any /* TODO: types */
) => {
	const normalizedKey = normalizeKey(key);

	return {
		...obj,
		[`gp${normalizedKey}Entries${includeSuffix ? studentGroup : ''}`]: match?.Scores?.[studentGroup]
			?.Entries,
		[`gp${normalizedKey}Score${includeSuffix ? studentGroup : ''}`]: match?.Scores?.[studentGroup]
			?.Display,
		[`gp${normalizedKey}Grade${includeSuffix ? studentGroup : ''}`]: match?.Scores?.[studentGroup]
			?.Grade,
		[`gp${normalizedKey}AvgPA${includeSuffix ? studentGroup : ''}`]: match?.Scores?.[studentGroup]
			?.AvgPA,
		[`gp${normalizedKey}Type${includeSuffix ? studentGroup : ''}`]: match?.Scores?.[studentGroup]
			?.Type,
		[`gp${normalizedKey}AvgPAType${includeSuffix ? studentGroup : ''}`]: match?.Scores?.[
			studentGroup
		]?.OverallPriorAchType,
		[`gp${normalizedKey}AvgPADisplay${includeSuffix ? studentGroup : ''}`]: match?.Scores?.[
			studentGroup
		]?.OverallPriorAchTypeDisplayName,
		teachingSets: Array.from(
			new Set([obj.teachingSets, Object.keys(match?.TeachingSets || {})]).values()
		).flat(),
		hasScores: match?.Scores?.[studentGroup] !== undefined,
	};
};

export const getSubjectGroups = (context: RootState): SubjectsOverview.Data => {
	const overview = context.subjectsOverview.data;

	if (!overview) {
		return {};
	}

	const showInOneTable = getShowInOneTable(context);
	const showTeachingSets = getShowTeachingSets(context);
	const primaryGradepoint = getPrimaryGradepoint(context);

	const subjects = overview.Subjects.filter(({ Gradepoints }) => {
		return Gradepoints.hasOwnProperty(primaryGradepoint.key);
	});

	const gradepoints = showInOneTable ? [primaryGradepoint] : getAppliedGradepoints(context);

	let teachingSets = new Set<string>();
	const subjectViewGroups = [
		'All',
		...Object.values(overview.StudentGroups).map((sg) => sg.Name),
	].reduce((struct, studentGroup) => {
		return {
			...struct,
			[studentGroup]: [
				// map standard subjects on gradepoints
				...subjects
					.map(({ ExamLevel, Subject, Gradepoints }) => {
						return gradepoints.reduce(
							(obj, { key }) => {
								const match = Gradepoints[key];

								// add the teaching set to the unique list
								Object.keys(match?.TeachingSets ?? {}).forEach((t) => teachingSets.add(t));

								return reduceGroup({
									obj,
									key,
									match,
									studentGroup,
								});
							},
							{
								hierarchy: [Subject[SUBJECT_NAME_KEY]],
								display: Subject[SUBJECT_NAME_KEY],
								subjectId: Subject.Name,
								subjectDisplay: Subject.DisplayName,
								qualType: ExamLevel,
								teachingSets: [],
							}
						);
					})
					.filter((subject) => subject.hasScores),

				// map the teaching set data if required
				...(showTeachingSets
					? subjects
							.map(({ ExamLevel, Subject, Gradepoints }) => {
								return Array.from(teachingSets.values())
									.sort()
									.map((teachingSet) => {
										return gradepoints.reduce(
											(obj, { key }) => {
												const match = Gradepoints[key];
												const hasTeachingSet = match?.TeachingSets?.[teachingSet] != undefined;

												return {
													...reduceGroup({
														obj,
														key,
														studentGroup,
														match: match?.TeachingSets?.[teachingSet],
													}),
													hasTeachingSet: hasTeachingSet,
												};
											},
											{
												hierarchy: [Subject[SUBJECT_NAME_KEY], teachingSet],
												display: teachingSet,
												subjectId: Subject.Name,
												subjectDisplay: Subject.DisplayName,
												qualType: ExamLevel,
												teachingSets: [],
											}
										);
									})
									.filter((x: any) => x.hasTeachingSet);
							})
							.flat()
					: []),
			],
		};
	}, {});

	const availableComparisons = getAvailableSubjectComparisons(context);
	const customComparisons = getComparisonsForSubjects(context);
	const appliedComparisons = context.context.appliedComparisons;
	const sortedStudentViewGroups = sortComparisonStudentViewGroups(
		availableComparisons,
		subjectViewGroups,
		appliedComparisons,
		customComparisons
	);
	return sortedStudentViewGroups as SubjectsOverview.Data;
};

export const getSubjectsOverviewData = (context: RootState): SubjectsOverview.Data => {
	const overview = context.subjectsOverview.data;

	if (!overview) {
		return {};
	}

	const showInOneTable = getShowInOneTable(context);
	const showTeachingSets = getShowTeachingSets(context);
	const primaryGradepoint = getPrimaryGradepoint(context);

	const subjects = overview.Subjects.filter(({ Gradepoints }) => {
		return Gradepoints.hasOwnProperty(primaryGradepoint.key);
	});

	const gradepoints = showInOneTable ? [primaryGradepoint] : getAppliedGradepoints(context);

	let teachingSets = new Set<string>();
	if (showInOneTable) {
		return {
			All: [
				// map standard subjects on gradepoints
				...subjects.map(({ ExamLevel, Subject, Gradepoints }) => {
					return ['All', ...Object.values(overview.StudentGroups).map((sg) => sg.Name)].reduce(
						(struct, studentGroup) => {
							return gradepoints.reduce((obj, { key }) => {
								const match = Gradepoints[key];

								// add the teaching set to the unique list
								Object.keys(match?.TeachingSets ?? {}).forEach((t) => teachingSets.add(t));

								return reduceGroup({
									obj,
									key,
									match,
									studentGroup,
									includeSuffix: true,
								});
							}, struct);
						},
						{
							hierarchy: [Subject[SUBJECT_NAME_KEY]],
							display: Subject[SUBJECT_NAME_KEY],
							subjectId: Subject.Name,
							subjectDisplay: Subject.DisplayName,
							qualType: ExamLevel,
							teachingSets: [],
						}
					);
				}),

				...(showTeachingSets
					? subjects.map(({ ExamLevel, Subject, Gradepoints }) => {
							return Array.from(teachingSets.values())
								.sort()
								.map((teachingSet) => {
									return [
										'All',
										...Object.values(overview.StudentGroups).map((sg) => sg.Name),
									].reduce(
										(struct, studentGroup) => {
											return gradepoints.reduce((obj, { key }) => {
												const match = Gradepoints[key];

												const hasTeachingSet = match?.TeachingSets?.[teachingSet] != undefined;

												return {
													...reduceGroup({
														obj,
														key,
														studentGroup,
														includeSuffix: true,
														match: match?.TeachingSets?.[teachingSet],
													}),
													hasTeachingSet: hasTeachingSet,
												};
											}, struct);
										},
										{
											hierarchy: [Subject[SUBJECT_NAME_KEY], teachingSet],
											display: teachingSet,
											subjectId: Subject.Name,
											subjectDisplay: Subject.DisplayName,
											qualType: ExamLevel,
											teachingSets: [],
										}
									);
								})
								.filter((x: any) => x.hasTeachingSet);
					  })
					: []),
			].flat(),
		};
	}

	return getSubjectGroups(context);
};

export const getInYearComparison = (context: RootState): boolean => {
	return context.subjectsOverview.hasNoInYearComparisons;
};

export const getSubjectOverviewStudentGroups = (context: RootState): string[] => {
	const overview = context.subjectsOverview.data;

	if (!overview) {
		return [];
	}

	return [
		'All',
		...Object.values(overview.StudentGroups).map((sg: { Name: string }) => {
			return sg.Name;
		}),
	];
};

export const getSubjectsOverviewQualTypeFilter = ({ context }: RootState): Filters.QualType => {
	return createQualTypeFilter(context.examLevels);
};

/**
 ** Export Thunks
 */

export const fetchSubjectsOverview = (): AppThunk => async (dispatch) => {
	dispatch(setLoading());

	try {
		const res = await getSubjectsOverview();

		const [overview] = res.reports;

		const subjectIds = overview.Subjects.map((x) => x.Subject.SubjectId);

		dispatch(fetchBedrockSubjectsThunk(subjectIds));

		dispatch(
			setSubjectsOverviewData({
				...overview,
				Subjects: overview.Subjects.sort((a, b) => {
					return (
						subjectSort(a.Subject.Order, b.Subject.Order) ||
						a.Subject.DisplayName.localeCompare(b.Subject.DisplayName)
					);
				}),
			})
		);
	} catch (err) {
		dispatch(setNoInYearComparisons(true));
		dispatch(setErrorMessage(err.message));
	}
};
