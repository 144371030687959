/**
 * Gradepoints selectors
 */
import { Context } from 'features/app/types';
import { getSessionsAppliedExamLevels } from './';

/**
 * Gets the gradepoints from state using the applied gradepoints key
 * @param state redux state
 * @returns Array of applied gradepoints
 */
export const getAppliedGradepoints = (state: RootState) => {
	const currentDataset = state.context.reportContext.currentDataset;
	const gradepoints = currentDataset?.gradepoints;

	return gradepoints
		? currentDataset.appliedGradepoints.flatMap((appliedGp) => {
				return gradepoints.filter((gp) => gp.key === appliedGp);
		  })
		: [];
};

/**
 * Gets a filtered gradepoints list
 * @param state Redux state
 * @returns An array of gradepoints filtered by the exam
 * levels available in the active analysis session
 */
export const getGradepointsFilteredByAvailableExamLevels = (state: RootState) => {
	const examLevels = getSessionsAppliedExamLevels(state);
	const currentDataset: Context.CurrentDataset | undefined =
		state.context.reportContext.currentDataset;
	const gradepoints = currentDataset?.gradepoints;
	const pgpType = currentDataset?.primaryGradepoint?.type;

	return gradepoints?.filter((gradepoint) => {
		return (
			// TODO - sort this type error
			//@ts-ignore
			examLevels.every((examLevel) => gradepoint.examLevel.includes(examLevel)) &&
			gradepoint.type === pgpType
		);
	});
};

