import { setSetupLoading, setSetupErrorMessage, setSetupTrends } from '../';

import { getTrends } from '../../../../../api/groupsSetupAPI';

/**
 * Fetch Group Trends
 */
export const fetchGroupTrends = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	const res = await getTrends();

	try {
		if (res.HasError) {
			dispatch(setSetupErrorMessage(res.Errors[0].Message));
			return;
		}
		dispatch(setSetupTrends(res.ResponseObject));
	} catch (err) {
		dispatch(setSetupErrorMessage(res.Errors[0].Message));
	}
};
