import { ICellRendererParams } from '@ag-grid-community/all-modules';
import defaultRenderer from './defaultRenderer';
import padLeft from '../../utils/padLeft';
import {
	adjustPriorAttainmentPrecision,
	getPriorAttainmentPrecision,
} from '../../utils/priorAttainment';

type Options = {
	priorAttainmentType?: 'KS2ScaledScore' | 'GCSEScore' | string;
	gcseScore?: number | string; // show an additional GCSE score in parenthesis after the PA
	priorAttainmentTypes?: SPO.PriorAttainmentType[]; // List of possible Prior attainment types
	paDisplayFeature?: boolean; // Flag to show decimalisation TODO: Remove when work is live
	paOverallType?: number; //Overall display type for PA = 99 -> is Mixed Pa types, so display 'char.
};

const PriorAttainmentRenderer = (
	params: ICellRendererParams,
	{
		priorAttainmentType,
		gcseScore,
		priorAttainmentTypes,
		paDisplayFeature,
		paOverallType,
	}: Options = {}
) => {
	var paScore = params.value;

	if (paOverallType && paOverallType === 99) {
		return '#';
	}
	if (paScore && gcseScore) {
		return `${paScore} (${padLeft(gcseScore, 1)})`;
	}

	priorAttainmentType = priorAttainmentType === 'GCSEScore' ? 'AvgGcseScore' : priorAttainmentType;

	if (paDisplayFeature) {
		const precision = getPriorAttainmentPrecision(priorAttainmentType, priorAttainmentTypes);
		paScore = adjustPriorAttainmentPrecision(paScore, precision);
	} else {
		let rounding = 2;

		if (priorAttainmentType === 'KS2ScaledScore') {
			rounding = 0;
		}

		if (priorAttainmentType === 'GCSEScore') {
			rounding = 1;
		}
		paScore = paScore ? padLeft(paScore, rounding) : paScore;
	}

	return defaultRenderer({
		...params,
		value: paScore,
	});
};

export default PriorAttainmentRenderer;
