/**
 * Errors selectors
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { getSlice } from './slice';

/**
 * Gets error messages from state for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns string
 */
export const getErrors = (
	page: StrategicAnalysis.AnalysisPage,
	examLevel: StrategicAnalysis.ExamLevels
) => (state: RootState): string | undefined => {
	const slice = getSlice(page)(state);
	const errors = slice.error;

	return errors?.[examLevel];
};

