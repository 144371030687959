import {
	setSetupLoading,
	setSetupErrorMessage,
	setCustomColumns,
	addCustomColumn,
	deleteCustomColumn,
} from '..';
import {
	getCustomColumns as getCustomColumnsAPI,
	addCustomColumns as addCustomColumnsAPI,
	updateCustomColumns as updateCustomColumnsAPI,
	deleteCustomColumns as deleteCustomColumnsAPI,
} from '../../../../../api/groupsSetupAPI';
import { getCustomColumns as getCustomColumnsSelector } from '../selectors';
import { getBenchmarkSetId } from 'features/app/redux/context';
import { getFieldComparisons } from '../../../../utils/comparisons';

import { setFieldComparisons } from '../slice';

/**
 * Gets gender, disadvantaged, ethnicity and HML field comparisons from bedrock API and adds them to redux
 */
export const fetchFieldComparisons = (): AppThunk => async (dispatch, getState) => {
	const rootState = getState();
	const benchmarkId = getBenchmarkSetId(rootState);

	const genderScheme = await getFieldComparisons(benchmarkId, 'Gender', false);
	const disadvantageScheme = await getFieldComparisons(benchmarkId, 'Disadvantaged', false);
	const ethnicityScheme = await getFieldComparisons(benchmarkId, 'Ethnicity', false);

	const fieldComparisons = [
		genderScheme?.reduce(
			(obj: any, item: any) => ({
				...obj,
				values: [...obj.values, item],
			}),
			{ name: 'Gender', type: 'Gender', values: [] }
		),

		disadvantageScheme?.reduce(
			(obj: any, item: any) => ({
				...obj,
				values: [...obj.values, item],
			}),
			{ name: 'Disadvantaged', type: 'Disadvantaged', values: [] }
		),

		ethnicityScheme?.reduce(
			(obj: any, item: any) => ({
				...obj,
				values: [...obj.values, item],
			}),
			{ name: 'Ethnicity', type: 'Ethnicity', values: [] }
		),

		// Hard-code HML values as these do not exist as comparison schemes in reporting-config
		// (due to them being subject-specific)
		{
			name: 'High/Middle/Low PA',
			type: 'HML',
			values: [
				{ name: 'High', value: 'High' },
				{ name: 'Mid', value: 'Mid' },
				{ name: 'Low', value: 'Low' },
			],
		},
	].filter((x) => x !== undefined);

	dispatch(setFieldComparisons(fieldComparisons));
};

/**
 * Fetch Group Custom Columns and store them in Redux
 */
export const fetchGroupCustomColumnsThunk = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	try {
		const responseBuilder = await getCustomColumnsAPI();

		// Handle response builder errors
		if (responseBuilder.HasError) dispatch(setSetupErrorMessage(responseBuilder.Errors[0].Message));

		// Map api response to a structure suitable for Redux
		const customColumns: Array<Groups.Setup.CustomColumn> = responseBuilder.ResponseObject.map(
			(x: Groups.Setup.CustomColumn) => {
				return {
					...x,
					isNew: false,
					isEdited: false,
					isDeleted: false,
				} as Groups.Setup.CustomColumn;
			}
		);

		dispatch(setCustomColumns(customColumns));
	} catch (err) {
		dispatch(setSetupErrorMessage(err.message));
	}
};

/**
 * Add a custom column to Redux.
 * @param customColumn The custom column to add to Redux
 */
export const addCustomColumnThunk = (customColumn: Groups.Setup.CustomColumn): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();
	const existing = getCustomColumnsSelector(state)?.find(({ id }) => id === customColumn.id);

	const meta: Setup.CustomColumnMeta = {};

	if (existing) {
		if (existing.name !== customColumn.name) {
			meta.previousName = existing.name;
		}

		const mergedValues = new Set([
			...customColumn.allowedValues.map(({ value }) => value),
			...existing.allowedValues.map(({ value }) => value),
		]);

		// If the set of all values doesn't match the length of the current values
		// something, somewhere has changed
		if (mergedValues.size !== customColumn.allowedValues.length) {
			meta.previousAllowedValues = existing.allowedValues;
		}
	}

	dispatch(
		addCustomColumn({
			...customColumn,
			isNew: existing ? existing.isNew || false : true, // ensures the value is a boolean
			isEdited: true,
			meta,
		})
	);
};

/**
 * Mark a custom column as deleted in Redux
 * @param customColumn The custom column to delete
 */
export const deleteCustomColumnThunk = (
	customColumn: Groups.Setup.CustomColumn
): AppThunk => async (dispatch) => {
	dispatch(
		deleteCustomColumn({
			...customColumn,
			isDeleted: true,
		})
	);
};

/**
 * Save all changes to group connect custom columns
 */
export const saveCustomColumnChangesThunk = (): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const existingCustomColumns = getCustomColumnsSelector(state) ?? [];

	// Newly created groups that need to be created
	const newCustomColumns = existingCustomColumns.filter(({ isNew }) => isNew);

	// Any existing items to be saved
	const editedCustomColumns = existingCustomColumns.filter(
		({ isEdited, isNew }) => isEdited && !isNew
	);

	// Existing items that have been deleted
	const deletedCustomColumns = existingCustomColumns.filter(({ isDeleted }) => isDeleted);

	// Make all requests in one go
	const [create, update, remove] = await Promise.all([
		newCustomColumns.length ? addCustomColumnsAPI(newCustomColumns) : null,

		editedCustomColumns.length ? updateCustomColumnsAPI(editedCustomColumns) : null,

		deletedCustomColumns.length
			? deleteCustomColumnsAPI(deletedCustomColumns.map((item) => item.id))
			: null,
	]);

	if ((create && create.HasError) || (update && update.HasError) || (remove && remove.HasError)) {
		const errors = [
			...(create ? create.Errors : []),
			...(update ? update.Errors : []),
			...(remove ? remove.Errors : []),
		];

		dispatch(setSetupErrorMessage(errors[0].Message));
	} else {
		return dispatch(fetchGroupCustomColumnsThunk());
	}
};
