import React, { FunctionComponent } from 'react';
import { Link, Box, Icon } from 'basecamp';
import { translate } from '../../utils/locale';

type Props = {
	link: string;
};
const SupportHubLink: FunctionComponent<Props> = ({ link }) => {
	return (
		<Link
			setAs="button"
			withType="accent"
			withSize="large"
			withExternalUrl={link}
			andTarget="_blank"
			width={150}
			m={0}
		>
			{translate('supportHub.SUPPORT_HUB_LINK')}
			<Box fontSize="16px" color="UI.white" ml={3}>
				<Icon icon="circle-caret-right" />
			</Box>
		</Link>
	);
};

export default SupportHubLink;
