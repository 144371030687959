import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAppContext } from '../../../app/redux/context';
import { getSchoolList, selectClient } from '../../../../api/contextAPI';
import { getSchools } from '../../../../api/groupsAPI';
import { getSchoolFail } from 'features/selectOrganisation/redux/switch';
import { getSelectedTrend } from 'features/groups/analysis/redux';

/**
 * Initial State
 */
const initialState: ViewAsSchool.State = {
	loading: false,
	error: null,
	schools: undefined,
	message: undefined,
};

/**
 * View As School Slice
 */

const viewSchoolsSlice = createSlice({
	name: 'viewAsSchool',
	initialState: initialState,
	reducers: {
		setSchoolsPending(state: ViewAsSchool.State) {
			state.loading = true;
		},
		setSchoolsSuccess(state: ViewAsSchool.State, action: PayloadAction<ViewAsSchool.Schools>) {
			(state.loading = false), (state.schools = action.payload);
		},
		setSchoolsFail(state: ViewAsSchool.State, action: PayloadAction<ViewAsSchool.Message>) {
			(state.loading = false), (state.message = action.payload);
		},
	},
});

/**
 * Export Action
 */

export const { setSchoolsPending, setSchoolsSuccess, setSchoolsFail } = viewSchoolsSlice.actions;

/**
 * Export Reducer
 */

export default viewSchoolsSlice.reducer;

/**
 * Export Selectors
 */

export const getClientSchools = (state: RootState): ViewAsSchool.Schools | undefined => {
	return state.viewAsSchool.schools;
};

/**
 * Export Thunks
 */

//Thunk to get a list of client schools linked to the MAT account and display them using response builder and setting them to state
export const getClientSchoolsThunk = (): AppThunk => async (dispatch, getState) => {
	dispatch(setSchoolsPending());

	const state = getState();
	const trendId = getSelectedTrend(state);

	try {
		const res = await getSchools();
		const mappedSchoolData = res.ResponseObject.map((value: any) => {
			return {
				id: value.id,
				name: value.name,
				effectiveStartDate: value.effectiveStartDate,
				trendId: trendId.id,
			};
		});
		dispatch(setSchoolsSuccess(mappedSchoolData));

		if (res.HasError) {
			dispatch(setSchoolsFail(res.Error[0].Message));
		}
	} catch (e) {
		dispatch(
			setSchoolsFail({
				text: 'Please speak to customer service',
			} as ViewAsSchool.Message)
		);
	}
};
