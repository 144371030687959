import * as React from 'react';
import { FunctionComponent } from 'react';

import { IconButton, Flex } from 'basecamp';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

type Props = {
	onClick: (id: number, academicYear: number | undefined) => void;
	params: ICellRendererParams;
	icon: string;
};

const IconContractButtonRenderer: FunctionComponent<Props> = ({
	onClick,
	icon,
	params: {
		data: { id, academicYear },
	},
}) => {
	return (
		<Flex setAs="row" withAlign="centerLeft" height="100%">
			<IconButton
				withIcon={icon}
				setAs="accent"
				withSize="small"
				onClick={() => onClick(id, academicYear)}
			/>
		</Flex>
	);
};

export default IconContractButtonRenderer;
