import { AnalysisPages } from 'features/strategicAnalysis/enums';
import {
	changePageConfiguration,
	hasActiveAnalysisSession,
	resetSummaryOnGradepointChange,
	setSessionDefaults,
} from 'features/strategicAnalysis/redux';
import { put, select, delay, cancel, call, spawn } from 'redux-saga/effects';
import { resetSessionOnGradepointChange, setStrategicLoading } from '..';
import { SagaIterator } from '@redux-saga/types';
import { getStrategicAnalysisPage, getExamLevelTab } from './sagaUtils';
import { handleThermometerChange } from './thermometerDataFlow';

/**
 * Saga workflow to handle the changing of the gradepoint trend whilst
 * the user has an active strategic analysis session
 */
export function* changeGradepoints(): SagaIterator<void> {
	// 1. If we're on the the summary page we need to reset any selected exam levels and comaprisons, then cancel the task
	const page = yield call(getStrategicAnalysisPage);
	if (page === AnalysisPages.SUMMARY) {
		yield put(resetSummaryOnGradepointChange());
		yield cancel();
	}
	// 2. check if there is a session running, if not cancel the task
	const activeAnalysisSession = yield select(hasActiveAnalysisSession);

	if (!activeAnalysisSession) {
		yield cancel();
	}

	// 3. set loading
	yield put(setStrategicLoading(true));

	// 4. Reset state, but leave the default levels intact
	yield put(resetSessionOnGradepointChange());

	// 5. fetch data
	yield put(
		changePageConfiguration({
			examLevelStatesAreEqual: false,
			comparisonStatesAreEqual: false,
			isReset: true,
		})
	);

	// 6. Allow state to update
	yield delay(1000);

	/**
	 * Get the current page and exam level tab
	 * Tab is obtained from state
	 */
	const strategicAnalysisPage = yield call(getStrategicAnalysisPage);
	const tab = yield call(getExamLevelTab);

	yield put(setSessionDefaults());
	// 7. Call the thermometer change flow now the grade point has changed
	yield spawn(handleThermometerChange, strategicAnalysisPage, tab.examLevel);

	// 8. set loading to false
	yield put(setStrategicLoading(false));
}
