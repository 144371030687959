import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, ButtonWithIcon } from 'basecamp';
import { useSelector } from 'react-redux';
import { getConfig } from 'features/app/redux/features';
import { translate } from '../../../../utils/locale';

type Props = {};

const ZohoHelpBtn: FunctionComponent<Props> = () => {
	const zohoLink = useSelector(getConfig('zoho_link'));

	/**
	 ** Handle Selectors
	 */
	const handleZohoHelp = () => {
		window.open(`${zohoLink}/en/kb/alps-education`, '_blank');
	};

	/**
	 ** Render Component
	 */
	return (
		<Flex withAlign="center" ml={3}>
			<ButtonWithIcon
				setAs="accent"
				withIcon="circle-question"
				withSize="medium"
				onClick={() => handleZohoHelp()}
			>
				{translate('supportHub.MAIN_TEXT')}
			</ButtonWithIcon>
		</Flex>
	);
};

export default ZohoHelpBtn;
