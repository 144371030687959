import { Context } from 'features/app/types';
import normalizeKey from '../../../../utils/normalizeKey';
import { appliedAndSelectedAreEqual } from 'features/ks5PerformanceMeasures/utils';

/**
 * Gets loading status
 * @param state Redux state
 * @returns Boolean
 */
export const getLoading = (state: RootState) => {
	return state.ks5PerformanceMeasures.loading;
};
/**
 * Gets any errors message
 * @param state Redux state
 * @returns string
 */
export const getErrors = (state: RootState) => {
	return state.ks5PerformanceMeasures.error;
};
/**
 * Gets any errors message
 * @param state Redux state
 * @returns string
 */
export const getExportExcel = (state: RootState) => {
	return state.ks5PerformanceMeasures.exportExcel;
};
/**
 * Gets the KS5 performance measures selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedComparisons = (
	state: RootState
): Ks5PerformanceMeasures.ComparisonOption => {
	return state.ks5PerformanceMeasures.selectedComparisons;
};
/**
 * Gets the KS5 performance measures applied comparisons
 * @param state Redux state
 * @returns Array of applied comparisons
 */
export const getAppliedComparisons = (
	state: RootState
): Ks5PerformanceMeasures.ComparisonOption => {
	return state.ks5PerformanceMeasures.appliedComparisons;
};
/**
 * Gets the KS5 performance measures selected filters
 * @param state Redux state
 * @returns Array of selected filters
 */
export const getSelectedFilters = (state: RootState) => {
	return state.ks5PerformanceMeasures.selectedFilters;
};
/**
 * Gets the KS5 performance measures applied filters
 * @param state Redux state
 * @returns Array of applied filters
 */
export const getAppliedFilters = (state: RootState) => {
	return state.ks5PerformanceMeasures.appliedFilters;
};
/**
 * Gets all the loaded exam level data
 * @param state redux state
 * @returns An object byId / allIds for the loaded exam level data
 */
export const getAllExamLevelData = (state: RootState) => {
	return state.ks5PerformanceMeasures.examLevels;
};
/**
 * Gets all the loaded grid data
 * @param state redux state
 * @returns An object byId / allIds for the loaded grid data
 */
export const getAllGridData = (state: RootState) => {
	return state.ks5PerformanceMeasures.grids;
};
/**
 * Gets all the loaded comparison data
 * @param state redux state
 * @returns An object byId / allIds for the loaded comparison data
 */
export const getAllComparisonData = (state: RootState) => {
	return state.ks5PerformanceMeasures.comparisons;
};
/**
 * Gets all the loaded row data
 * @param state redux state
 * @returns An object byId / allIds for the loaded row data
 */
export const getAllRowData = (state: RootState) => {
	return state.ks5PerformanceMeasures.rows;
};

/**
 * Gets state of value toggle
 * @param state Redux state
 * @returns bool
 */
export const getValueToggle = (state: RootState) => {
	return state.ks5PerformanceMeasures.valueToggle;
};

/**
 * Gets currently active tab
 * @param state Redux state
 * @returns index of selected tab
 */
export const getActiveTab = (state: RootState): number => {
	return state.ks5PerformanceMeasures.activeTab;
};

/**
 * get grid data
 */
export const getGridDataForExamLevel = (gridIds: string[]) => (state: RootState) => {
	const gridData = getAllGridData(state);
	const getGridsForExamLevel = gridData
		? gridIds.flatMap((id) => {
				return Object.values(gridData.byId).filter((data) => {
					return data.gridId === id;
				});
		  })
		: [];

	return getGridsForExamLevel.length > 0 ? { id: gridData!.id, grid: getGridsForExamLevel } : null;
};
/**
 * Helper function to retrieve and show a count or percentage value
 * @param gridValues grid values to iterate over
 * @param showCount boolean flag to determine which value to display
 * @returns
 */
const getCountOrPercentageValue = (
	gridValues: { [key: string]: Ks5PerformanceMeasures.GridValues },
	showCount: boolean
) =>
	Object.entries(gridValues).reduce((acc, [key, gridValue]) => {
		return {
			...acc,
			[normalizeKey(key)]: {
				value: showCount ? Number(gridValue.value.Percentage).toFixed(2) : gridValue.value.Value,
				cellClass: gridValue.cellClass,
			},
		};
	}, {});
/**
 * Get the overview row data with comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows with comparisons rows
 */
export const getRowDataWithComparisons = (rowIds: string[], showCount: boolean) => (
	state: RootState
) => {
	const rows = getAllRowData(state);
	const comparisons = getAllComparisonData(state);

	return !!rows
		? rowIds.flatMap((id) => {
				const comparisonIds = rows.byId[id].comparisonIds;

				return [
					{
						...rows.byId[id],
						...getCountOrPercentageValue(rows.byId[id].gridValues, showCount),
					},
					...comparisonIds!.map((compId) => {
						return {
							...comparisons!.byId[compId],
							...getCountOrPercentageValue(comparisons!.byId[compId].gridValues, showCount),
						};
					}),
				];
		  })
		: null;
};
/**
 * Get the row data without comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows
 */
export const getRowDataWithoutComparisons = (rowIds: string[], showCount: boolean) => (
	state: RootState
) => {
	const rows = getAllRowData(state);

	return !!rows
		? rowIds.flatMap((id) => {
				return {
					...rows.byId[id],
					...getCountOrPercentageValue(rows.byId[id].gridValues, showCount),
				};
		  })
		: null;
};

/**
 * Creates the row data for the overview grid(s)
 * @param rowIds row ids to map over
 * @returns Formatted row data, with or without comparisons
 */
export const createGridData = (rowIds: string[], showCount: boolean) => (state: RootState) => {
	const rows = getAllRowData(state);
	const rowsWithComparisons = getRowDataWithComparisons(rowIds, showCount)(state);
	const rowsWithoutComparisons = getRowDataWithoutComparisons(rowIds, showCount)(state);
	const appliedComparisons = getAppliedComparisons(state);
	const rowComparisonsIds = rows?.byId[rowIds[0]].comparisonIds;
	const hasComparisonsForRow =
		rowComparisonsIds &&
		rowComparisonsIds.some((id) => {
			const applied = appliedComparisons[0] ? appliedComparisons[0].value.split('|').join('-') : '';
			return id.includes(applied);
		});

	// If applied comparisons
	if (!!appliedComparisons && appliedComparisons.length > 0) {
		// Check if first grid row has the comparison ids populated, else return null
		return !!hasComparisonsForRow ? rowsWithComparisons : null;
	}

	// Else if no appled comparisons just fetch the all row
	return rowsWithoutComparisons;
};

/**
 * Determine if the comparisons have changed
 * @returns boolean
 */
export const checkComparisonStatesAreEqual = (state: RootState): boolean => {
	const appliedComparisons = getAppliedComparisons(state);
	const selectedComparisons = getSelectedComparisons(state);

	return appliedAndSelectedAreEqual<{ label: string; value: string }>(
		appliedComparisons,
		selectedComparisons
	);
};

/**
 * Used for simple barchart displaying single values for Grade Summary
 * @returns Array of of objects to use for the series
 */
export const getChartDataGradeSummary = (
	gradepoints: Context.Gradepoint[],
	metricId: number,
	metricName: string = ''
) => (state: RootState) => {
	const rows = getAllRowData(state);

	if (rows == undefined) return;

	const chartVals = gradepoints.map((gp) => {
		if (gp?.key == undefined) return {};

		const data: any | undefined = Object.values(rows?.byId).find((x) => x.metricId == metricId);

		if (data) {
			const avgPoints = Number(data.gridValues[gp.key]?.value.Value);

			var chartData: any = { paKey: gp.name, All: avgPoints };

			const comparisonsRows = getAllComparisonData(state);
			const appliedComparisons = getAppliedComparisons(state).map((comparison) => {
				return {
					...comparison,
					value: comparison.value.replace('|', '-'),
				};
			});
			if (comparisonsRows !== undefined) {
				const compData = Object.values(comparisonsRows?.byId)
					.filter((x) => x.metricId == metricId)
					.filter((chartComp) => {
						let formattedIndicator = chartComp.indicator.slice(0, chartComp.indicator.indexOf(' '));

						return appliedComparisons.some((x) => x.value.includes(formattedIndicator));
					});

				compData.map((comp: any) => {
					var label = comp.indicator.replace(metricName, '');
					chartData[label] = Number(comp.gridValues[gp.key]?.value.Value);
				});
			}
			return chartData;
		} else return {};
	});

	return chartVals;
};

/**
 * Creates a key/value list of distinct grades/points taken from the queryed data.
 * Used for Average Grade Summary chart
 * @returns returns a list of key/value pairs
 */
export const getChartDataKeyValueForGradeSummary = (gradepoints: Context.Gradepoint[]) => (
	state: RootState
) => {
	const rows = getAllRowData(state);

	if (rows == undefined) return;

	const chartArray: any = [];
	gradepoints.map((gp) => {
		if (gp?.key == undefined) return {};

		const dataGrades: any | undefined = Object.values(rows?.byId).find((x) => x.metricId == 1701); //gets the grades
		const dataPoints: any | undefined = Object.values(rows?.byId).find((x) => x.metricId == 170); //gets the points

		if (dataGrades) {
			// check for dupes
			const grade = dataGrades.gridValues[gp.key]?.value.Value;
			const points = Number(dataPoints.gridValues[gp.key]?.value.Value);

			var result = Object.values(chartArray).find((x) => x.grade === grade && x.value === points);
			if (result === undefined) {
				chartArray.push({ grade: grade, value: points });
			}

			//comparison list
			const comparisonsRows = getAllComparisonData(state);
			if (comparisonsRows !== undefined) {
				const compData = Object.values(comparisonsRows?.byId).filter((x) => x.metricId == 1701);
				const compDataValue = Object.values(comparisonsRows?.byId).filter((x) => x.metricId == 170);

				compData.map((compGrade?: any) => {
					compDataValue.map((compPoints?: any) => {
						if (compGrade.indicator === compPoints.indicator.replace('Point Score', 'Grade')) {
							const grade: any = compGrade.gridValues[gp.key]?.value.Value;
							const points = Number(compPoints.gridValues[gp.key]?.value.Value);

							var result: any | undefined = Object.values(chartArray).find(
								(x: any) => x.grade === grade && x.value === points
							);

							if (result === undefined) chartArray.push({ grade: grade, value: points });
						}
					});
				});
			} else return {};
		} else return {};
	});

	return chartArray;
};

/**
 * Creates series data for a line chart for the Prior Attainment metric.
 * @returns Array of of objects to use for the series
 */
export const getChartDataPAData = (gradepoints: Context.Gradepoint[], metricId: number) => (
	state: RootState
) => {
	const rows = getAllRowData(state);

	if (rows == undefined) return;

	const data = Object.values(rows?.byId).filter((x) => x.metricId == metricId);

	if (data == undefined) return;
	const chartArray = [];

	// loop for the number of PA's 2 - 9
	for (let index = 9; index >= 2; index--) {
		const pointData: any = Object.values(rows?.byId).find(
			(x) => x.metricId == metricId && x.indicator == index.toString()
		);

		var keyName = pointData?.indicator;
		var chartData: any = { paKey: keyName };
		gradepoints.forEach((gp) => {
			var key = normalizeKey(gp.key);
			var keyValue = pointData[key]?.value == undefined ? 0 : pointData[key].value;
			chartData[gp.name] = Number(keyValue);

			// comparisons
			const comparisonsRows = getAllComparisonData(state);
			if (comparisonsRows !== undefined) {
				const compData = Object.values(comparisonsRows?.byId).filter(
					(x) => x.metricId == metricId && x.indicator == index.toString()
				);

				compData.map((comp: any) => {
					chartData[comp.indicator] = Number(comp[key]?.value);
				});
			}
		});

		chartArray.push(chartData);
	}
	return chartArray;
};

/**
 * Creates series data for a line chart for the Student Achieving metric.
 * Uses the GP's as on the X-Axis
 * @returns Array of of objects to use for the series
 */
export const getChartStudentAchievingData = (gradepoints: Context.Gradepoint[]) => (
	state: RootState
) => {
	const rows = getAllRowData(state);
	if (rows == undefined) return;

	const chartArray: any | undefined = [];
	//chartArray.push({ paKey:'' });

	gradepoints.forEach((gp) => {
		var key = gp.key;
		var chartData: any = { paKey: gp.name };

		for (let metricId = 188; metricId <= 196; metricId++) {
			const pointData: any = Object.values(rows?.byId).find((x) => x.metricId == metricId);

			if (pointData == undefined) continue;

			var keyName = pointData?.indicator.replace('Students Achieving', '');
			var keyValue =
				pointData.gridValues[key]?.value.Percentage === undefined
					? 0
					: pointData.gridValues[key].value.Percentage;
			chartData[keyName] = Number(keyValue);
		}

		chartArray.push(chartData);
	});
	return chartArray;
};

/**
 * Formats teh data for the series property in ag-chart
 * @returns Array of of objects
 */
export const getSeriesMaker = (data: any, chartType: string = 'column') => {
	var seriesData = [];
	if (data)
		for (let k of Object.keys(data)) {
			if (k !== 'paKey') {
				seriesData.push({ type: chartType, xKey: 'paKey', yKey: k, yName: k });
			}
		}
	return seriesData;
};

/**
 * Formats teh data for the series property in ag-chart
 * Adds label to the charts
 * @returns Array of of objects
 */
export const getSeriesMakerWithLabel = (
	data: any,
	formatter: any,
	chartType: string = 'column'
) => {
	var seriesData = [];
	if (data)
		for (let k of Object.keys(data)) {
			if (k !== 'paKey') {
				seriesData.push({
					type: chartType,
					xKey: 'paKey',
					yKey: k,
					yName: k,
					label: { formatter, fontWeight: 'bold', fontSize: 14 },
				});
			}
		}
	return seriesData;
};

export const getSeriesMakerWithLabelEnableDisable = (data: any, chartType: string = 'column') => {
	var seriesData = [];
	var isVisible = true;
	if (data)
		for (let k of Object.keys(data)) {
			if (k !== 'paKey') {
				seriesData.push({
					type: chartType,
					xKey: 'paKey',
					yKey: k,
					yName: k,
					visible: isVisible,
				});
				isVisible = false;
			}
		}
	return seriesData;
};
