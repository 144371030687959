import * as React from 'react';
import { FunctionComponent } from 'react';

// TODO - Remove after client testing
import { useSelector } from 'react-redux';
import { getFeature } from 'features/app/redux/features';

import { Page, Content, SideBar } from 'basecamp';

type Props = {
	dataTest?: string;
	sidebarContent: any;
	pr?: number;
	mb?: number;
	excludeFooter?: boolean;
};

const PageWithSidebar: FunctionComponent<Props> = ({
	dataTest,
	sidebarContent,
	mb,
	pr,
	excludeFooter,
	children,
}) => {
	// TODO - Remove after client testing
	const canViewSidebar = useSelector(getFeature('can_view_sidebar'));
	const defaultHeight = 'calc(100vh - 164px)';
	/**
	 ** Render Component
	 */
	return (
		<Page dataTest={dataTest}>
			{canViewSidebar && (
				<SideBar zIndex={5} dataTest={dataTest} pb={4} height={defaultHeight}>
					{sidebarContent}
				</SideBar>
			)}
			<Content
				dataTest={dataTest}
				pt={3}
				mb={mb ?? 0}
				pr={pr ?? 2}
				position="relative"
				height={!!excludeFooter ? 'auto' : defaultHeight}
			>
				{children}
			</Content>
		</Page>
	);
};

export default PageWithSidebar;
