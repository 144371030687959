import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex } from 'basecamp';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

type Props = {
	params: ICellRendererParams;
};

const ActualPointsRenderer: FunctionComponent<Props> = ({ params }) => {
	return params.rowIndex === 0 ? (
		<Flex withAlign="center">{params.value}</Flex>
	) : (
		<Flex withAlign="center" bg="UI.secondary.1" height="100%">
			{`Total Actual Points = ${params.data.totalActual}`}
		</Flex>
	);
};

export default ActualPointsRenderer;
