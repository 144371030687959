import { fetchSpo } from '../../../../api/spoAPI';
import { getFineGradesSettings } from '../../../../api/reportAPI';
import {
	setStudentViewGroupsPending,
	setStudentViewGroupsSuccess,
	setFineGradeSettings,
	setStudentViewGroupsFailed,
} from '../';
import { fetchBedrockSubjectsThunk } from 'features/subjectLookUp/redux/thunks/bedrockLookUpThunk';
/*
 **  Thunks
 */

export const fetchStudentViewGroupsThunk = (options: SPO.FetchOptions): AppThunk => (dispatch) => {
	dispatch(setStudentViewGroupsPending());

	// Fetch student view groups data
	fetchSpo(options)
		.then((result) => {
			// Extract subject IDs from the response
			const subjectIds = result.reports[0].SubjectIdList;

			//Fetch subject look up info
			dispatch(fetchBedrockSubjectsThunk(subjectIds));

			dispatch(
				setStudentViewGroupsSuccess({
					...result,
				})
			);

			return getFineGradesSettings().then((result) => {
				const fineGradesSettings = JSON.parse(result.payload).fineGrades;

				dispatch(
					setFineGradeSettings({
						FineGradeSettings: fineGradesSettings,
					})
				);
			});
		})
		.catch((error) => {
			dispatch(setStudentViewGroupsFailed());
		});
};
