import { CellClassParams } from '@ag-grid-community/all-modules';

/**
 * CSS styling for AGgrid
 * @param params row params
 * @returns css class
 */
const AlpsGradeClass = (params: CellClassParams): string => {
	// get the key only
	// Regex will take a string such as
	// 		gp2022223Y13NovGradeEffort Median Grade - Unknown
	// and will return
	// match - gp2022223Y13NovGradeEffort
	// The gradepoint in group 1 - 2022223Y13Nov
	// Stuff we don't care about in group 2 - Grade
	const [, key] = /^gp(.*?)(Grade|Entries|Score|AvgPA)/g.exec(params.colDef.field ?? '') || [];

	// If we can't get a gradepoint key, don't do any styling
	if (!key) {
		return '';
	}

	// Regex will take a string such as
	// 		gp2022223Y13NovGradeEffort Median Grade - Unknown
	// and will return
	// match - Effort Median Grade - Unknown
	// group 1 - Effort Median Grade - Unknown
	// or it may return undefined where there is no suffix
	let [, suffix] =
		/gp[a-zA-Z0-9-\/]*(?:Grade|Entries|Score|AvgPA)(.+)/g.exec(params.colDef.field ?? '') || [];

	// if there is no suffix, then just set it to an empty string
	if (!suffix) {
		suffix = '';
	}

	/**
	 * Get the grade from the row data
	 */
	const grade = params.data[`gp${key}Grade${suffix}`];
	const entries = params.data[`gp${key}Entries${suffix}`];
	const score = params.data[`gp${key}Score${suffix}`];

	/**
	 * Define the color boundaries
	 */
	const redBoundry = grade >= 1 && grade <= 3;
	const blueBoundry = grade >= 7 && grade <= 9;

	/**
	 * Return the cell style
	 */
	return !entries && !grade && !score
		? 'alps-grade-no-data'
		: redBoundry
		? 'alps-grade-red'
		: blueBoundry
		? 'alps-grade-blue'
		: 'alps-grade-transparent';
};

export default AlpsGradeClass;
