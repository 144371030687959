import * as React from 'react';
import { FunctionComponent, useContext } from 'react';
import { updateIsAlpsChampion } from '../../features/manageUsers/redux/users';
import { useDispatch } from 'react-redux';
import { ManageUsersContext } from 'features/manageUsers/manageUsers';
import { Switch, Flex } from 'basecamp';

type Props = {
	user: ManageUsers.User;
};

const AlpsChampionsRenderer: FunctionComponent<Props> = (props) => {
	const manageUsersContext = useContext<ManageUsers.SaveState>(ManageUsersContext);
	const dispatch = useDispatch();
	const toggleChampion = () => {
		dispatch(updateIsAlpsChampion(props.user));
		manageUsersContext.setSaveDisabled(false);
	};
	return (
		<Flex setAs="row" withAlign="centerLeft" height="100%">
			<Switch
				isOn={props.user.isChamp}
				setAs="accent"
				withSize="small"
				onClick={() => {
					toggleChampion();
				}}
			/>
		</Flex>
	);
};

export default AlpsChampionsRenderer;
