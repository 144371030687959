import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IbPerformanceMeasures, IbPerformanceMeasuresGraphData } from '../types';
import { CommonAgGridChartStateTypes } from 'types/commonAgGridTypes';

/**
 * Initial State
 */

const initialState: IbPerformanceMeasures.State = {
	loading: true,
	exportExcel: false,
	// Filter bar
	selectedFilters: [],
	appliedFilters: [],
	selectedComparisons: [],
	appliedComparisons: [],
	// Measures
	examLevels: undefined,
	grids: undefined,
	rows: undefined,
	comparisons: undefined,
	error: '',
	// Toggle
	valueToggle: true,
	// Tabs
	activeTab: 0,

	graphLoading: true,
	graphExamLevels: undefined,
	graphs: undefined,
	xAxis: undefined,
	graphComparisons: undefined,
	graphError: undefined,
};

const ibPerformanceSlice = createSlice({
	name: 'ibPerformanceMeasures',
	initialState,
	reducers: {
		updateSelectedFilters(
			state: IbPerformanceMeasures.State,
			action: PayloadAction<IbPerformanceMeasures.FilterOption>
		) {
			return {
				...state,
				selectedFilters: action.payload ?? [],
			};
		},
		updateSelectedComparisons(
			state: IbPerformanceMeasures.State,
			action: PayloadAction<IbPerformanceMeasures.ComparisonOption>
		) {
			return {
				...state,
				selectedComparisons: action.payload ?? [],
			};
		},
		applyFilterBarChanges(state: IbPerformanceMeasures.State) {
			return {
				...state,
				appliedComparisons: state.selectedComparisons,
			};
		},
		setMetricsPending(state: IbPerformanceMeasures.State) {
			return {
				...state,
				loading: true,
				error: '',
			};
		},
		setMetricsSuccess(
			state: IbPerformanceMeasures.State,
			action: PayloadAction<IbPerformanceMeasures.GridData>
		) {
			const { examLevels, grids, rows } = action.payload;
			return {
				...state,
				examLevels,
				grids,
				rows,
				loading: false,
				error: '',
			};
		},
		setMainGraphDataSuccess(
			state: IbPerformanceMeasures.State,
			action: PayloadAction<IbPerformanceMeasuresGraphData.GraphData>
		) {
			const { examLevels, graphs, xAxis } = action.payload;

			// If this is the first fetch just set the data
			if (!state.graphs || !state.graphExamLevels) {
				return {
					...state,
					examLevels,
					graphs,
					xAxis,
				};
			}
			// If this is a subsequent fetch update the data
			return {
				...state,
				graphError: undefined,
				graphExamLevels: {
					...state.graphExamLevels,
					id: examLevels.id,
					byId: {
						...state.graphExamLevels!.byId,
						...examLevels.byId,
					},
					//@ts-ignore
					allIds: [...state.graphExamLevels!.allIds, ...examLevels.allIds],
				},
				graphs: {
					...state.graphs,
					id: graphs.id,
					byId: {
						...state.graphs?.byId,
						...graphs.byId,
					},
					allIds: [...state.graphs!.allIds, ...graphs.allIds],
				},
				xAxis: {
					...state.xAxis,
					id: xAxis.id,
					byId: {
						...state.xAxis!.byId,
						...xAxis.byId,
					},
					allIds: [...state.xAxis!.allIds, ...xAxis.allIds],
				},
			};
		},
		setMetricsFailure(state: IbPerformanceMeasures.State, action: PayloadAction<string>) {
			return {
				...state,
				metrics: undefined,
				error: action.payload,
				loading: false,
			};
		},
		setMainGraphDataFail(state: IbPerformanceMeasures.State, action: PayloadAction<string>) {
			const { payload } = action;

			return {
				...state,
				graphError: payload,
				loading: false,
				graphs: undefined,
			};
		},
		setComparisonsSuccess(
			state: IbPerformanceMeasures.State,
			action: PayloadAction<IbPerformanceMeasures.Comparisons<IbPerformanceMeasures.ComparisonRow>>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				comparisons: !state.comparisons
					? payload
					: {
							...state.comparisons,
							id: payload.id,
							byId: {
								...state.comparisons.byId,
								...payload.byId,
							},
							// @ts-ignore spread error
							allIds: Array.from(new Set([...state.comparisons.allIds, ...payload.allIds])),
					  },
				rows: {
					...state.rows,
					byId: Object.entries(state.rows.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: Array.from(
									new Set(
										Object.values(payload.byId)
											.filter((val) => val.rowId === key)
											.map((comparison) => comparison.comparisonId)
									)
								),
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		setAllGraphComparisonDataSuccess(
			state: ibPerformanceMeasures.State,
			action: PayloadAction<
				CommonAgGridChartStateTypes.Comparisons<CommonAgGridChartStateTypes.ComparisonXAxis>
			>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
				};
			}

			return {
				...state,
				graphComparisons: !state.graphComparisons
					? payload
					: {
							...state.graphComparisons,
							id: payload.id,
							byId: {
								...state.graphComparisons.byId,
								...payload.byId,
							},
							allIds: Array.from(new Set([...state.graphComparisons.allIds, ...payload.allIds])),
					  },
				xAxis: {
					...state.xAxis,
					byId: Object.entries(state.xAxis.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: Array.from(
									new Set(
										Object.values(payload.byId)
											.filter((val) => val.graphId === key)
											.map((comparison) => comparison.comparisonId)
									)
								),
							},
						};
					}, {}),
				},
			};
		},
		setChartComparisonsFailure(state: IbPerformanceMeasures.State, action: PayloadAction<string>) {
			return {
				...state,
				graphComparisons: action.payload,
				graphLoading: false,
			};
		},
		setComparisonsFailure(state: IbPerformanceMeasures.State, action: PayloadAction<string>) {
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		},
		resetComparisons(state: IbPerformanceMeasures.State) {
			if (!state.rows) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				selectedComparisons: [],
				appliedComparisons: [],
				comparisons: undefined,
				rows: {
					...state.rows,
					byId: Object.entries(state.rows.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: [],
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		handleExcelExport(state: IbPerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				exportExcel: action.payload,
			};
		},
		handleValueToggle(state: IbPerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				valueToggle: action.payload,
			};
		},
		setActiveTab(state: IbPerformanceMeasures.State, action: PayloadAction<number>) {
			return {
				...state,
				activeTab: action.payload,
			};
		},
		resetSelectedFilters(state: IbPerformanceMeasures.State) {
			return {
				...state,
				selectedFilters: [],
			};
		},
		setGraphLoading(state: IbPerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				graphLoading: action.payload,
			};
		},
	},
});

export const {
	setMetricsPending,
	setMetricsSuccess,
	setMetricsFailure,

	setMainGraphDataSuccess,
	setMainGraphDataFail,
	setAllGraphComparisonDataSuccess,
	setChartComparisonsFailure,
	setGraphLoading,

	updateSelectedFilters,
	updateSelectedComparisons,
	applyFilterBarChanges,
	handleExcelExport,

	setComparisonsSuccess,
	setComparisonsFailure,
	resetComparisons,

	handleValueToggle,

	setActiveTab,

	resetSelectedFilters,
} = ibPerformanceSlice.actions;

/**
 * Export Reducer
 */
export default ibPerformanceSlice.reducer;
