import { SubjectAreaPages } from 'features/subjectArea/enums';
import { SubjectArea } from 'features/subjectArea/types';
import { select, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

/**
 * Gets the current subject area page
 * @returns string
 */
export function* getSubjectAreaPage(): SagaIterator<SubjectArea.SubjectAnalysisPages> {
	const pathname = window.location.pathname;
	const page = pathname.split('/').pop() as SubjectArea.SubjectAnalysisPages;

	return page;
}
