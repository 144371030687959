// Takes Mountaineer exam level flag as a key
// and  returns relevant exam level information
const levels = {
	1: {
		key: 'A',
		display: 'A level',
		compactDisplay: 'A',
		level: 'KS5',
	},
	2: {
		key: 'AS',
		display: 'AS level',
		compactDisplay: 'AS',
		level: 'KS5',
	},
	4: {
		key: 'BTEC',
		display: 'KS5 vocational',
		compactDisplay: 'BTEC',
		level: 'KS5',
	},
	8: {
		key: 'BTECCert',
		display: 'BTEC Certificate',
		compactDisplay: 'BTEC Cert',
		level: 'KS5',
	},
	16: {
		key: 'BTEC90Cred',
		display: 'BTEC 90-credit Diploma',
		compactDisplay: 'BTEC 90-cred',
		level: 'KS5',
	},
	32: {
		key: 'KS4',
		display: 'KS4',
		compactDisplay: 'KS4',
		level: 'KS4',
	},
	64: {
		key: 'PreU',
		display: 'Pre-U',
		compactDisplay: 'Pre-U',
		level: 'KS5',
	},
	128: {
		key: 'IB',
		display: 'International Baccalaureate',
		compactDisplay: 'IB',
		level: 'KS5',
	},
};

export default levels;

/**
 * Get all exam level keys
 */
export function getAllExamLevelKeys() {
	return Object.values(levels).map((level) => level.key);
}

/**
 * Get the corresponding report engine flag for the
 * @param key string
 * @returns number
 */
export function getExamLevelFlagFromKey(key: string) {
	let flag = 0;

	Object.keys(levels).forEach((k: any) => {
		if (levels[k].key === key) {
			flag = k;
		}
	});

	return flag;
}

/**
 * Returns examl level based on exam key
 * @param key string
 * @returns string
 */
export function getKeyStageLevelFromKey(key: string) {
	let examLevel = 0;

	Object.keys(levels).forEach((k: any) => {
		if (levels[k].key === key) {
			examLevel = levels[k].level;
		}
	});

	return examLevel;
}

/**
 * Returns exam level key based on exam flag
 * @param flag
 * @returns string
 */
export function getExamLevelKeyFromFlag(flag: number | string) {
	if (typeof flag === 'number') {
		flag = `${flag}`;
	}

	let examLevelKey = 0;

	Object.keys(levels).forEach((k) => {
		if (k === flag) {
			examLevelKey = levels[k].key;
		}
	});

	return examLevelKey;
}

/**
 * Get the exam level ie KS4 or KS5 from the enum/flag value
 * @param flag The exam level enum
 * @returns KS4 or KS5 in the form of a string
 */
export const getExamLevelFromFlag = (flag: number): string => levels[flag].level;

const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

/**
 * Get the corresponding report engine flag for the supplied list of
 * exam levels, using the bitwise OR operator
 * @param {Array} levels Numeric exam levels to merge into a flag
 */
export function getExamLevelFlag(levels: number[]) {
	let flag = 0;

	levels.forEach((l) => {
		if (isNumeric(l)) {
			flag = flag | l;
		}
	});

	return flag;
}
