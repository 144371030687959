import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'features/app/redux/context';

import { Flex, BaseElement, Icon, Link } from 'basecamp';

type Props = {
	dataTest?: string;
	welcomeText?: string;
	loadingText?: string;
	username?: string;
	height: number;
};

/**
 ** Handles the rendering of the home icon / welcome text on homepage
 */
const PrimaryNavHome: FunctionComponent<Props> = ({
	dataTest,
	welcomeText,
	height,
	loadingText,
}) => {
	/**
	 ** Handle Selectors
	 */
	const user = useSelector(getUser);

	/**
	 ** Render Component
	 */
	return (
		<Flex setAs="row" withAlign="center" height={height}>
			{welcomeText ? (
				<>
					{/* Welcome Text */}
					<BaseElement
						data-test={
							dataTest ? `primaryNavigationHomeWelcome ${dataTest}` : 'primaryNavigationHomeWelcome'
						}
						fontFamily="headings"
						fontSize={6}
						color="UI.typography.0"
						zIndex={20}
						mr={3}
						as="span"
					>
						{welcomeText}
					</BaseElement>
					{/* username */}
					<BaseElement
						data-test={
							dataTest ? `primaryNavigationHomeWelcome ${dataTest}` : 'primaryNavigationHomeWelcome'
						}
						fontFamily="headings"
						fontSize={6}
						color="UI.white"
						zIndex={20}
						mr={3}
						as="span"
					>
						{user?.fullname}
					</BaseElement>
				</>
			) : (
				<>
					<Link setAs="wrapping" withInternalUrl="/home" mr={4}>
						<Flex
							width={height}
							height={height}
							withAlign="center"
							bg="UI.accent.0"
							borderRadius={height / 2}
							color="UI.white"
							fontSize={5}
						>
							<Icon icon="house" />
						</Flex>
					</Link>
					{loadingText && (
						<BaseElement
							data-test={
								dataTest ? `primaryNavigationLoading ${dataTest}` : 'primaryNavigationLoading'
							}
							fontFamily="headings"
							fontSize={6}
							color="UI.accent.0"
							zIndex={20}
							mr={3}
							as="span"
						>
							{loadingText}
						</BaseElement>
					)}
				</>
			)}
		</Flex>
	);
};

export default PrimaryNavHome;
