export const SubjectAreaPages = {
	//Commented out for now to avoid unwanted network calls
	//OVERVIEW: 'Overview',
	ATTAINMENT_PROFILE: 'AttainmentProfile',
} as const;

/**
 * Ag grid row type
 */
//Leaving in unused to match strat row types
export enum RowType {
	ALL = 1,
	COMPARISON = 2,
	EMBEDDED = 3,
	FULL_WIDTH = 4,
	TOTAL = 5,
}
