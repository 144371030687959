import * as React from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, ButtonWithIcon } from 'basecamp';
import { translate } from '../../../../utils/locale';
import { useSelector } from 'react-redux';
import { getPermissions, getUserRoles, hasGroupAccess } from 'features/app/redux/context';

type Props = {
	useClientBm: boolean;
};

const ClientBenchmarkActiveWarning: FunctionComponent<Props> = ({ useClientBm }) => {
	/**
	 ** Handle History
	 */
	const history = useHistory();

	//Are we a MAT or a Admin user
	const groupAccess = useSelector(hasGroupAccess);
	const canSwitchBenchmarks = useSelector(getPermissions)?.canSwitchClientBenchmark;
	const isAdmin = useSelector(getUserRoles)?.includes('Settings_Users', 0);

	const pushToBenchmark = () => {
		//If we are a MAT all users can access both toggle and selector page
		if (groupAccess) {
			return history.push('/analysis-settings/client-benchmarks');
		}
		//If we have the permission to switch the session push the user there
		if (canSwitchBenchmarks) {
			return history.push('/analysis-settings/client-benchmarks');
		}
		//edge case but if a user is admin but does not have persmission to change the session toggle then push them to the selector page
		if (isAdmin && !canSwitchBenchmarks) {
			return history.push('/analysis-settings/benchmark-selector');
		}
	};

	return (
		<Flex withAlign="center" ml={3}>
			<ButtonWithIcon
				setAs="accent"
				withIcon="eye"
				withSize="medium"
				disabled={groupAccess ? false : isAdmin ? false : !canSwitchBenchmarks}
				onClick={() => pushToBenchmark()}
			>
				{useClientBm
					? translate('standardPage.warnings.CLIENT_BENCHMARK_ACTIVE')
					: translate('standardPage.warnings.NATION_BENCHMARK_ACTIVE')}
			</ButtonWithIcon>
		</Flex>
	);
};

export default ClientBenchmarkActiveWarning;
