import {
	createSubscriptionAPI,
	updateOranisationDetailsAPI,
	updateAgreementAPI,
} from '../../../../api/subscriptionAPI';

import { fetchAppContext } from '../../../app/redux/context';
import { Subscription } from '../../classes/Subscription';
import { SubscriptionAgreements } from '../../classes/SubscriptionAgreement';
import { updateLoading } from '../slice';
import {
	getOrganisationDetails,
	getSelectedReports,
	getSelectedSubscriptionLevel,
	getConsent,
	getAllSubscriptions,
	getCurrentSubscriptionAgreement,
	getOrderTotal,
	getAllReports,
	getSubscritionPricing,
} from '../selectors';

/**
 * Create a new subscription and/or filter reports based on the selected options.
 */
export const completeOrderThunk = (): AppThunk => async (dispatch, getState) => {
	await dispatch(updateLoading(true));

	const state = getState();
	const organisationDetails = getOrganisationDetails(state);
	const selectedReports = getSelectedReports(state);
	const selectedSubscriptionLevel = getSelectedSubscriptionLevel(state);
	const subscriptionPricing = getSubscritionPricing(state);

	const organisationDetailsPayload = {
		ClientDetails: {
			Name: organisationDetails?.client.name,
			AddressLine1: organisationDetails?.client.addressLine1,
			AddressLine2: organisationDetails?.client.addressLine2,
			AddressLine3: organisationDetails?.client.addressLine3,
			City: organisationDetails?.client.city,
			Postcode: organisationDetails?.client.postcode,
			Country: organisationDetails?.client.country,
		},
		PrimaryContactDetails: {
			FullName: organisationDetails?.primaryContactDetails.fullName,
			EmailAddress: organisationDetails?.primaryContactDetails.emailAddress,
			ContactNumber: organisationDetails?.primaryContactDetails.contactNumber,
			JobTitle: organisationDetails?.primaryContactDetails.jobTitle,
		},
		IsMAT: organisationDetails?.isMAT,
		MATName: organisationDetails?.matName ?? '',
	};

	if (selectedSubscriptionLevel !== undefined) {
		const consent = getConsent(state);
		const subscriptions = getAllSubscriptions(state);

		const subscription = new Subscription(
			new SubscriptionAgreements(getCurrentSubscriptionAgreement(state) ?? []),
			subscriptions,
			subscriptionPricing
		);

		const payload = {
			OrganisationDetails: organisationDetailsPayload,
			SubscriptionSizeId: subscription.GetSubscriptionSizeId(selectedSubscriptionLevel),
			NumberOfYears: subscription.GetSubscriptionDuration(selectedSubscriptionLevel),
			NetCost: getOrderTotal(state),
			Level: selectedSubscriptionLevel,
			AcceptTermsAndConditions: consent.terms,
		};

		await createSubscriptionAPI(payload);
	}

	if (selectedReports?.length > 0) {
		const allReports = getAllReports(state);
		let combinedReports = {};

		selectedReports?.forEach((qualification: ManageAccount.Qualifications) => {
			combinedReports[qualification] = [
				...(combinedReports[qualification] ?? []),
				...allReports[qualification].selectedFilterReports,
			];
		});

		allReports.existingReports?.forEach((x) => {
			combinedReports[x.type] = [...(combinedReports[x.type] ?? []), ...x.selectedFilterReports];
		});

		const payload = {
			Reports: combinedReports,
		};

		await createReportAPI(payload);

		// Only update if the subscription has not done it already
		if (selectedSubscriptionLevel === undefined)
			await updateOranisationDetailsAPI(organisationDetailsPayload);
	}

	await dispatch(fetchAppContext());
	await dispatch(updateLoading(false));
};
