import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMatGroupReport } from '../../../api/reportAPI';

const initialState: MAT.State = {
	selectedYear: undefined,
	loading: false,
	clients: undefined,
	selectedClient: undefined,
	message: undefined,
};

const matSlice = createSlice({
	name: 'mat',
	initialState,
	reducers: {
		setChildPending(state: MAT.State) {
			state.loading = true;
		},
		setChildFail(state: MAT.State, action: PayloadAction<MAT.Message>) {
			state.loading = false;
			state.message = action.payload;
		},
		setChildSuccess(state: MAT.State, action: PayloadAction<MAT.Clients>) {
			state.loading = false;
			state.clients = action.payload;
		},
		setSelectedYearSuccess(state: MAT.State, action: PayloadAction<MAT.SelectedYear>) {
			return {
				...state,
				loading: false,
				selectedYear: action.payload,
			};
		},
		setSelectedYearFail(state: MAT.State, action: PayloadAction<MAT.Message>) {
			state.loading = false;
			state.message = action.payload;
		},
		setSelectedClient(state: MAT.State, action: PayloadAction<MAT.SelectedClient>) {
			state.loading = false;
			state.selectedClient = action.payload;
		},
		setClientSelectedYear(state: MAT.State, action: PayloadAction<string>) {
			state.loading = false;
			if (state.selectedClient) state.selectedClient.selectedYear = action.payload;
		},
		unsetSelectedClient(state: MAT.State, action: PayloadAction<undefined>) {
			state.loading = false;
			state.selectedClient = action.payload;
		},
	},
});

/**
 * Export Actions
 */
export const {
	setChildFail,
	setChildPending,
	setChildSuccess,
	setSelectedYearSuccess,
	setSelectedYearFail,
	setSelectedClient,
	setClientSelectedYear,
	unsetSelectedClient,
} = matSlice.actions;

/**
 * Export Slice
 */

export default matSlice.reducer;

/**
 * Export Selectors
 */

//Get Client Info
export const getclient = (state: RootState) => {
	return state.mat.clients;
};

//pulls clinets based on the selected acedmeic year, then

//Selected Year
export const getYear = (state: RootState) => {
	return state.mat.selectedYear;
};

//selectedclient
export const selectedClient = (state: RootState) => {
	return state.mat.selectedClient;
};

//getReports
export const getReports = (state: RootState) => {
	return state.mat.selectedClient?.reports;
};

export const isLoading = (state: RootState) => {
	return state.mat.loading;
};

/**
 * Export Thunks
 */
export const childThunk = (): AppThunk => async (dispatch) => {
	dispatch(setChildPending());

	getMatGroupReport()
		.then((response) => {
			dispatch(setChildSuccess(response));
		})
		.catch(() => {
			dispatch(
				setChildFail({
					text: 'Please speak to customer services',
				} as MAT.Message)
			);
		});
};
