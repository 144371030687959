import * as React from 'react';
import { FunctionComponent } from 'react';

import { translate } from '../../../utils/locale';

import { Flex, Text, ButtonWithIcon } from 'basecamp';

type Props = {
	removeMessages: (messageIds: number[]) => void;
	rtmMessages: RTM.Message[];
	timeperiod: string;
};

const RtmMessageWrap: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		// Message wrapping element
		<Flex setAs="column" width={1} dataTest="rtmMessageWrap">
			{/* Message time period grouping */}
			<Flex setAs="row" withAlign="distribute">
				{/* Time period - e.g. 3 days ago */}
				<Text setAs="h5" withColor="white" my={3} ml="17px" dataTest="rtmMessageTimePeriod">
					{props.timeperiod}
				</Text>
				{/* Clear all grouped messages btn */}
				<ButtonWithIcon
					setAs="nav"
					withSize="small"
					withIcon="trash"
					onClick={() =>
						props.removeMessages(props.rtmMessages.map((message: RTM.Message) => message.id))
					}
					dataTest="rtmRemoveGroupedMessages"
				>
					{translate('rtm.clearMessage')}
				</ButtonWithIcon>
			</Flex>
			{props.children}
		</Flex>
	);
};

export default RtmMessageWrap;
