import * as React from 'react';
import { lazy } from 'react';

import { Loading, Flex, Text } from 'basecamp';

/**
 ** Show whilst lazy loading the components
 */
export const Loader = () => (
	<Flex setAs="column" withAlign="center" width={1} height="calc(100vh - 60px)" zIndex={999}>
		<Loading />
		<Text setAs="h5">Loading</Text>
	</Flex>
);

/**
 ** Define the dropdownmenus shown in the Primary Nav
 */
const subjectPageDropdown = (hasUdf: boolean): string[] => {
	return hasUdf
		? ['root', 'subject', 'udftype', 'subjectPage']
		: ['root', 'subject', 'subjectPage'];
};
const strategic: string[] = ['root'];
const spo: string[] = ['root', 'studentPage'];
const spoTutorGroup: string[] = ['root'];
const subjectOverview: string[] = ['root'];
const settingsDropdowns: string[] = ['settingsRoot'];
const monitoringDropdowns: string[] = ['root', 'monitoringPage'];
const reportsDropdowns: string[] = ['root', 'reportsPage'];
const groupsRootDropDowns: string[] = ['groupsRoot'];
const groupsReportsDropDowns: string[] = ['groupsRoot', 'groupsReports'];
const ks4PerformanceMeasures: string[] = ['root', 'performanceMetrics'];
const ks5PerformanceMeasures: string[] = ['root', 'ks5PerformanceMeasuresPage'];
const strategicDropdowns: string[] = ['root', 'strategicAnalysisPage'];
const ibPerformanceMeasures: string[] = ['root', 'ibPerformanceMeasuresPage'];

/**
 ** Subject page routes
 */
const subjectsSubRoutes = (hasUdf: boolean = false): Routes.Route[] => [
	{
		path: '/subjects/:subject/share/:collection/:id', // Report share - for compatiblity with V1
		exact: false,
		displayName: 'Share',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		redirect: '/subjects/:subject/students/:id',
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/overview' : 'overview'}`,
		displayName: 'Overview',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		exact: true,
		component: lazy(() => import('features/subjectPage/pages/overview')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/students' : 'students'}/:reportId`, // Report share - new format instead of subject/share used in V1
		exact: true,
		displayName: 'Students',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/students')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/students' : 'students'}`,
		exact: true,
		displayName: 'Students',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/students')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/outcomes' : 'outcomes'}`,
		exact: true,
		displayName: 'Outcomes',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/outcomes')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/trends' : 'trends'}`,
		exact: true,
		displayName: 'Trends',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/trends')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/fine-grades' : 'fine-grades'}`,
		exact: true,
		displayName: 'Fine Grades',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/fineGrades')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/saved' : 'saved'}`,
		exact: true,
		displayName: 'Saved',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/saved')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/gap-analysis' : 'gap-analysis'}`,
		exact: true,
		displayName: 'Gap Analysis',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/gapAnalysis')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${
			hasUdf ? ':udftype/:udfvalue/AttainmentProfile' : 'AttainmentProfile'
		}`,
		exact: true,
		displayName: 'Attainment',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectArea/pages/attainmentProfile/attainmentProfile')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/cohort-profile' : 'cohort-profile'}`,
		exact: true,
		displayName: 'Cohort Profile',
		baseName: 'Subjects',
		dropdownMenus: subjectPageDropdown(hasUdf),
		component: lazy(() => import('features/subjectPage/pages/cohortProfile')),
		fallback: Loader(),
	},
	{
		path: `/subjects/:subject/${hasUdf ? ':udftype/:udfvalue/*' : '*'}`,
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/page-not-found',
		fallback: '',
	},
];

export const routes: Routes.Route[] = [
	/**
	 ** Home
	 */
	{
		path: '/',
		exact: true,
		displayName: 'Home',
		baseName: 'Home',
		redirect: '/home',
		fallback: '',
	},
	// Redirect users who have connect v1 home bookmarked
	{
		path: '/connect/home',
		exact: true,
		displayName: 'Home',
		baseName: 'Home',
		redirect: '/home',
		fallback: '',
	},

	// Home redirects
	{
		path: '/home',
		exact: true,
		displayName: 'Home',
		baseName: 'Home',
		component: lazy(() => import('features/home')),
		fallback: Loader(),
	},
	/**
	 * Select Organisation
	 */
	{
		path: '/select-organisation',
		exact: true,
		displayName: 'Select Organisation',
		baseName: 'Select Organisation',
		component: lazy(() => import('features/selectOrganisation')),
		fallback: Loader(),
		private: false,
	},

	/**
	 * Auth
	 */
	// Login
	{
		path: '/auth/login',
		exact: true,
		displayName: 'Login',
		baseName: 'Login',
		component: lazy(() => import('features/auth/pages/Login')),
		fallback: Loader(),
		private: false,
	},
	// mfa
	{
		path: '/auth/mfaValidation',
		exact: true,
		displayName: 'Multi Factor Authentication',
		baseName: 'Multi Factor Authentication',
		component: lazy(() => import('features/auth/pages/Mfa')),
		fallback: Loader(),
		private: false,
	},
	// Activate
	{
		path: '/auth/activate',
		exact: true,
		displayName: 'Activate',
		baseName: 'Activate',
		component: lazy(() => import('features/auth/pages/Activate')),
		fallback: Loader(),
		private: false,
	},
	// Create Account
	{
		path: '/auth/create-account',
		exact: true,
		displayName: 'Create Account',
		baseName: 'Create Account',
		component: lazy(() => import('features/auth/pages/CreateAccount')),
		fallback: Loader(),
		private: false,
	},
	// Forgotten Password
	{
		path: '/auth/forgotten-password',
		exact: true,
		displayName: 'Forgotten Password',
		baseName: 'Forgotten Password',
		component: lazy(() => import('features/auth/pages/ForgottenPassword')),
		fallback: Loader(),
		private: false,
	},
	// Forgotten Password Complete
	{
		path: '/auth/forgotten-password/:token',
		exact: true,
		displayName: 'Forgotten Password',
		baseName: 'Forgotten Password',
		component: lazy(() => import('features/auth/pages/CompleteForgottenPassword')),
		fallback: Loader(),
		private: false,
	},
	// Account confirmation set password
	{
		path: '/auth/CreatePassword/:token',
		exact: true,
		displayName: 'Create Password',
		baseName: 'Create Password',
		component: lazy(() => import('features/auth/pages/CompleteNewPassword')),
		fallback: Loader(),
		private: false,
	},
	// Confirm Account
	{
		path: '/auth/confirm-account/:activationcode/:postcode/:token',
		exact: true,
		displayName: 'Confirm Account',
		baseName: 'Confirm Account',
		component: lazy(() => import('features/auth/pages/ConfirmAccount')),
		fallback: Loader(),
		private: false,
	},
	// Redirect Confirm account redirect
	{
		path: '/Auth/ConfirmAccount/:activationcode/:postcode/:token',
		exact: false,
		displayName: 'Confirm Account',
		baseName: 'Confirm Account',
		redirect: '/auth/confirm-account/:activationcode/:postcode/:token',
		fallback: Loader(),
	},
	// Forgotten Password Complete redirect
	{
		path: '/Auth/CompleteResetPassword/:token',
		exact: false,
		displayName: 'Forgotten Password',
		baseName: 'Forgotten Password',
		redirect: '/auth/forgotten-password/:token',
		fallback: Loader(),
	},
	/**
	 ** Student
	 */
	{
		path: '/students/:studentPage/:tutorGroup',
		exact: true,
		displayName: 'StudentAnalysis',
		baseName: 'Student Analysis',
		dropdownMenus: spo,
		component: lazy(() => import('features/spo/')),
		fallback: Loader(),
		routes: [
			{
				path: '/students/tutor-group/:tutorGroup',
				exact: true,
				displayName: 'Tutor Group',
				baseName: 'Student Analysis',
				dropdownMenus: spoTutorGroup,
				component: lazy(() => import('features/spo/pages/overview')),
			},
		],
	},
	{
		path: '/students/:studentPage',
		exact: true,
		displayName: 'Student Analysis',
		baseName: 'Student Analysis',
		dropdownMenus: spo,
		component: lazy(() => import('features/spo/')),
		fallback: Loader(),
		routes: [
			{
				path: '/students/overview',
				exact: true,
				displayName: 'Overview',
				baseName: 'Student Analysis',
				dropdownMenus: spo,
				component: lazy(() => import('features/spo/pages/overview')),
			},
			{
				path: '/students/performance-measures',
				exact: true,
				displayName: 'Student Level Attainment Measures',
				baseName: 'Student Analysis',
				permissions: 'ks4_perf_measures_spo',
				dropdownMenus: spo,
				component: lazy(() => import('features/spo/pages/performanceMeasures')),
			},
			{
				path: '/students/progress8',
				exact: true,
				displayName: 'Student Level Progress 8 Measures',
				baseName: 'Student Analysis',
				permissions: 'ks4_progress_8_spo',
				dropdownMenus: spo,
				component: lazy(() => import('features/spo/pages/progress8')),
			},
		],
	},
	// SPO redirects
	{
		path: '/students/:studentPage',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/students/overview',
		fallback: '',
	},
	{
		path: '/students',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/students/overview',
		fallback: '',
	},
	/**
	 ** Subjects Overview
	 */
	{
		path: '/subjects',
		exact: true,
		displayName: 'Subject Analysis',
		baseName: 'Subject Analysis',
		dropdownMenus: subjectOverview,
		component: lazy(() => import('features/subjectsOverview/')),
		fallback: Loader(),
	},
	/**
	 ** Subjects Landing Page
	 */
	{
		path: '/subjects/:subject/home',
		exact: true,
		displayName: 'Home',
		baseName: 'Subjects',
		component: lazy(() => import('features/subjectPage/subjectLandingPage')),
		fallback: Loader(),
	},
	/**
	 ** Subjects Page Teaching Sets
	 */
	{
		path: '/subjects/:subject/:udftype/:udfvalue/:subjectPage',
		exact: false,
		displayName: 'Subject',
		baseName: 'Subjects',
		component: lazy(() => import('features/subjectPage/')),
		fallback: Loader(),
		routes: subjectsSubRoutes(true),
	},
	{
		path: '/subjects/:subject/:udftype/:udfvalue',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/subjects/:subject/:udftype/:udfvalue/overview',
		fallback: '',
	},
	/**
	 ** Subjects Page
	 */
	{
		path: '/share/subjects/:subject/:subjectPage/:reportId',
		exact: true,
		displayName: 'Subject',
		baseName: 'Subjects',
		component: lazy(() => import('features/subjectPage')),
		fallback: Loader(),
		routes: subjectsSubRoutes(),
	},
	/**
	 ** Subjects Page
	 */
	{
		path: '/subjects/:subject/:subjectPage',
		exact: false,
		displayName: 'Subject',
		baseName: 'Subjects',
		component: lazy(() => import('features/subjectPage/')),
		fallback: Loader(),
		routes: subjectsSubRoutes(),
	},
	// Subject page redirects
	{
		path: '/subjects/:subject',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/subjects/:subject/overview',
		fallback: '',
	},
	/**
	 ** KS4 Performance Measures
	 */
	{
		path: '/performance-measures/:performanceMetrics',
		exact: true,
		displayName: '',
		baseName: 'KS4 Performance Measures',
		dropdownMenus: ks4PerformanceMeasures,
		component: lazy(() => import('features/performanceMeasures/ks4PerformanceMeasures')),
		fallback: Loader(),
		routes: [
			{
				path: '/performance-measures/attainment-measures',
				exact: true,
				displayName: 'Attainment Measures',
				dropdownMenus: ks4PerformanceMeasures,
				permissions: 'ks4_perf_measures',
				baseName: 'KS4 Performance Measures',
				component: lazy(() => import('features/performanceMeasures/pages/performanceMeasures')),
			},
			{
				path: '/performance-measures/progress-8-measures',
				exact: true,
				displayName: 'Progress 8 Measures',
				dropdownMenus: ks4PerformanceMeasures,
				permissions: 'ks4_progress_8',
				baseName: 'KS4 Performance Measures',
				component: lazy(() => import('features/performanceMeasures/pages/progress8Metrics')),
			},
		],
	},
	{
		path: '/performance-measures-student-metrics',
		exact: true,
		displayName: '',
		baseName: 'KS4 Performance Measures',
		component: lazy(() => import('features/performanceMeasures/studentMetrics')),
		fallback: Loader(),
	},
	/**
	 ** KS5 Performance Measures
	 */
	{
		path: '/Ks5PerformanceMeasures/:ks5PerformanceMeasuresPage',
		exact: true,
		displayName: 'KS5 Performance Measures',
		baseName: 'KS5 Performance Measures',
		component: lazy(() => import('features/ks5PerformanceMeasures/')),
		fallback: Loader(),
		routes: [
			{
				path: '/Ks5PerformanceMeasures/Alevel',
				exact: true,
				displayName: 'A Level',
				dropdownMenus: ks5PerformanceMeasures,
				baseName: 'KS5 Performance Measures',
				component: lazy(() => import('features/ks5PerformanceMeasures/pages/aLevel/aLevel')),
				permissions: 'ks5_a_level_perf_measures',
			},
			{
				path: '/Ks5PerformanceMeasures/*',
				exact: true,
				displayName: '',
				redirect: '/home',
				fallback: '',
			},
		],
	},
	{
		path: '/ib-performance-measures',
		exact: true,
		displayName: 'International Baccalaureate Performance Measures',
		baseName: 'International Baccalaureate Performance Measures',
		component: lazy(() => import('features/ibPerformanceMeasures')),
		fallback: Loader(),
		routes: [
			{
				path: '/ib-performance-measures',
				exact: true,
				displayName: 'International Baccalaureate Performance Measures',
				dropdownMenus: ibPerformanceMeasures,
				baseName: 'International Baccalaureate Performance Measures',
				component: lazy(() => import('features/ibPerformanceMeasures/pages/ibBase')),
				permissions: 'ks5_ib_perf_measures',
			},
		],
	},
	/**
	 ** Strategic
	 */
	{
		path: '/strategic',
		exact: true,
		displayName: 'Strategic Analysis',
		baseName: 'Strategic Analysis',
		component: lazy(() => import('features/strategic')),
		fallback: Loader(),
		routes: [
			{
				path: '/strategic',
				exact: true,
				displayName: 'Strategic Analysis',
				baseName: 'Strategic Analysis',
				dropdownMenus: strategic,
				component: lazy(() => import('features/strategic/pages/strategic')),
			},
		],
	},
	{
		path: '/strategic/builder',
		exact: true,
		displayName: 'Strategic Analysis',
		baseName: 'Strategic Analysis',
		component: lazy(() => import('features/strategic')),
		fallback: Loader(),
		routes: [
			{
				path: '/strategic/builder',
				exact: true,
				displayName: 'Builder',
				baseName: 'Strategic',
				dropdownMenus: strategic,
				component: lazy(() => import('features/strategic/pages/builder')),
			},
		],
	},
	{
		path: '/strategic/builder/:builderId',
		exact: true,
		displayName: 'Strategic Analysis',
		baseName: 'Strategic Analysis',
		component: lazy(() => import('features/strategic')),
		fallback: Loader(),
		routes: [
			{
				path: '/strategic/builder/:builderId',
				exact: true,
				displayName: 'Builder',
				baseName: 'Strategic',
				dropdownMenus: strategic,
				component: lazy(() => import('features/strategic/pages/builder')),
			},
		],
	},
	/**
	 ** Page
	 */
	// Overview
	{
		path: '/monitoring/:monitoringPage/:subject',
		exact: true,
		displayName: 'Monitoring',
		baseName: 'Monitoring',
		component: lazy(() => import('features/monitoring/')),
		fallback: Loader(),
		routes: [
			{
				path: '/monitoring/overview/:subject',
				exact: true,
				displayName: 'Overview',
				baseName: 'Monitoring',
				component: lazy(() => import('features/monitoring/pages/overview')),
				fallback: Loader(),
			},
			{
				path: '/monitoring/difference/:subject',
				exact: true,
				displayName: '',
				redirect: '/monitoring/difference',
				fallback: '',
			},
			{
				path: '/monitoring/variance/:subject',
				exact: true,
				displayName: '',
				redirect: '/monitoring/variance',
				fallback: '',
			},
			{
				path: '/monitoring/exam-vs-monitoring/:subject',
				exact: true,
				displayName: '',
				redirect: '/monitoring/exam-vs-monitoring',
				fallback: '',
			},
		],
	},
	// Monitoring pages
	{
		path: '/monitoring/:monitoringPage',
		exact: true,
		displayName: 'Monitoring Accuracy',
		baseName: 'Monitoring Accuracy',
		component: lazy(() => import('features/monitoring/')),
		fallback: Loader(),
		routes: [
			{
				path: '/monitoring/difference',
				exact: true,
				displayName: 'Difference',
				baseName: 'Monitoring Accuracy',
				dropdownMenus: monitoringDropdowns,
				component: lazy(() => import('features/monitoring/pages/difference')),
				fallback: Loader(),
			},
			{
				path: '/monitoring/variance',
				exact: true,
				displayName: 'Variance',
				baseName: 'Monitoring Accuracy',
				dropdownMenus: monitoringDropdowns,
				component: lazy(() => import('features/monitoring/pages/variance')),
				fallback: Loader(),
			},
			{
				path: '/monitoring/exam-vs-monitoring',
				exact: true,
				displayName: 'Exam Vs Monitoring',
				baseName: 'Monitoring Accuracy',
				dropdownMenus: monitoringDropdowns,
				component: lazy(() => import('features/monitoring/pages/examVsMonitoring')),
				fallback: Loader(),
			},
			{
				path: '/monitoring/*',
				exact: true,
				displayName: '',
				baseName: '',
				redirect: '/page-not-found',
				fallback: '',
			},
		],
	},
	// Monitoring redirects
	{
		path: '/monitoring',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/monitoring/difference',
		fallback: '',
	},
	/**
	 ** Analysis Settings
	 */
	{
		path: '/analysis-settings/:settingsPage',
		exact: false,
		displayName: 'Analysis Settings',
		baseName: 'Analysis Settings',
		component: lazy(() => import('features/analysisSettings/')),
		fallback: Loader(),
		routes: [
			{
				path: '/analysis-settings/my-comparisons',
				exact: true,
				displayName: 'My Comparisons',
				baseName: 'Analysis Settings',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/analysisSettings/pages/customComparisons')),
				fallback: Loader(),
			},
			{
				path: '/analysis-settings/client-benchmarks',
				exact: true,
				displayName: 'Client Benchmarks',
				baseName: 'Analysis Settings',
				dropdownMenus: settingsDropdowns,
				component: lazy(
					() => import('features/analysisSettings/pages/benchmarks/sessionBenchmarkToggle')
				),
				fallback: Loader(),
			},
			{
				path: '/analysis-settings/benchmark-selector',
				exact: true,
				displayName: 'Benchmark Selector',
				baseName: 'Analysis Settings',
				dropdownMenus: settingsDropdowns,
				component: lazy(
					() => import('features/analysisSettings/pages/benchmarks/benchmarkSelector')
				),
				fallback: Loader(),
			},
			{
				path: '/analysis-settings/*',
				exact: true,
				displayName: '',
				baseName: '',
				redirect: '/page-not-found',
				fallback: '',
			},
		],
	},
	// Analysis Settings redirects
	{
		path: '/analysis-settings',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/analysis-settings/my-comparisons',
		fallback: '',
	},
	/**
	 ** Manage Users
	 */
	{
		path: '/manage-users/:usersPage/:id',
		exact: false,
		displayName: 'Manage Users',
		baseName: 'Manage Users',
		component: lazy(() => import('features/manageUsers/')),
		fallback: Loader(),
		routes: [
			{
				path: '/manage-users/edit-user/:id',
				exact: true,
				displayName: 'Edit User',
				baseName: 'Manage Users',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageUsers/pages/editUser')),
				fallback: Loader(),
			},
		],
	},
	{
		path: '/manage-users/:usersPage',
		exact: true,
		displayName: 'Manage Users',
		baseName: 'Manage Users',
		component: lazy(() => import('features/manageUsers/')),
		fallback: Loader(),
		routes: [
			{
				path: '/manage-users/all-users',
				exact: true,
				displayName: 'Active Users',
				baseName: 'Manage Users',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageUsers/pages/allUsers')),
				fallback: Loader(),
			},
			{
				path: '/manage-users/add-user',
				exact: true,
				displayName: 'Add User',
				baseName: 'Manage Users',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageUsers/pages/addUser')),
				fallback: Loader(),
			},
			{
				path: '/manage-users/bulk-add-users',
				exact: true,
				displayName: 'Bulk Add Users',
				baseName: 'Manage Users',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageUsers/pages/bulkAddUsers')),
				fallback: Loader(),
			},
			{
				path: '/manage-users/deactivated-user-accounts',
				exact: true,
				displayName: 'Deactivated User Accounts',
				baseName: 'Manage Users',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageUsers/pages/disabledUserAccounts')),
				fallback: Loader(),
			},
			{
				path: '/manage-users/*',
				exact: true,
				displayName: '',
				redirect: '/page-not-found',
				fallback: '',
			},
		],
	},
	// Manage Users redirects
	{
		path: '/manage-users',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/manage-users/all-users',
		fallback: '',
	},
	/**
	 ** Manage Account
	 */
	{
		path: '/manage-account/:accountPage',
		exact: true,
		displayName: 'Manage Account',
		baseName: 'Manage Account',
		component: lazy(() => import('features/manageAccount/')),
		fallback: Loader(),
		routes: [
			{
				path: '/manage-account/change-password',
				exact: true,
				displayName: 'Change Password',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/changePassword')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/subscribe-step-1',
				exact: true,
				displayName: 'Subscribe (Step 1 of 3)',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/subscribeStep1')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/subscribe-step-2',
				exact: true,
				displayName: 'Subscribe (Step 2 of 3)',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/subscribeStep2')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/subscribe-step-3',
				exact: true,
				displayName: 'Subscribe (Step 3 of 3)',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/subscribeStep3')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/auto-renew-unsubscribe',
				exact: true,
				displayName: 'Auto Renew Unsubscribe',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/autoRenewUnsubscribe')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/data-sharing',
				exact: true,
				displayName: 'Data Sharing',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/dataSharing')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/organisation-management',
				exact: true,
				displayName: 'Organisation Management',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/organisationManagement')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/mfaSetup',
				exact: true,
				displayName: 'Multi-Factor Authentication',
				baseName: 'Manage Account',
				dropdownMenus: settingsDropdowns,
				component: lazy(() => import('features/manageAccount/pages/mfa')),
				fallback: Loader(),
			},
			{
				path: '/manage-account/*',
				exact: true,
				displayName: '',
				redirect: '/page-not-found',
				fallback: '',
			},
		],
	},
	// Manage Account redirects
	{
		path: '/manage-account',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/manage-account/change-password',
		fallback: '',
	},
	/**
	 ** Page
	 */
	{
		path: '/groups/reports/:reportsPage/:groupReportsId',
		exact: false,
		displayName: 'Reports',
		baseName: 'Reports',
		component: lazy(() => import('features/reports/')),
		dropdownMenus: groupsReportsDropDowns,
		fallback: Loader(),
		routes: [
			{
				path: '/groups/reports/school-and-college-reports/:groupReportsId',
				exact: true,
				displayName: 'School and College Reports',
				baseName: 'Reports',
				dropdownMenus: groupsReportsDropDowns,
				component: lazy(() => import('features/groups/pages/schoolAndCollegeGroupReport')),
				fallback: Loader(),
			},
		],
	},
	{
		path: '/groups/reports/:reportsPage/',
		exact: false,
		displayName: 'Reports',
		baseName: 'Reports',
		component: lazy(() => import('features/reports/')),
		dropdownMenus: groupsReportsDropDowns,
		fallback: Loader(),
		routes: [
			{
				path: '/groups/reports/school-and-college-reports',
				exact: true,
				displayName: 'School and College Reports',
				baseName: 'Reports',
				dropdownMenus: groupsReportsDropDowns,
				// TODO - Move this to groups rather than connect?
				component: lazy(() => import('features/reports/pages/schoolAndCollege')),
				fallback: Loader(),
			},
			{
				path: '/groups/reports/group-reports/',
				exact: true,
				displayName: 'Group Reports',
				baseName: 'Reports',
				dropdownMenus: groupsReportsDropDowns,
				component: lazy(() => import('features/reports/pages/myReports')),
				fallback: Loader(),
			},
			{
				path: '/groups/reports/additional-downloads',
				exact: true,
				displayName: 'Additional Downloads',
				baseName: 'Reports',
				dropdownMenus: groupsReportsDropDowns,
				component: lazy(() => import('features/reports/pages/additionalDownloads')),
				fallback: Loader(),
			},
		],
	},
	{
		path: '/reports/:reportsPage',
		exact: true,
		displayName: 'Reports',
		baseName: 'Reports',
		component: lazy(() => import('features/reports/')),
		fallback: Loader(),
		routes: [
			{
				path: '/reports/meg-reports',
				exact: true,
				displayName: 'MEG Reports',
				baseName: 'Reports',
				dropdownMenus: reportsDropdowns,
				component: lazy(() => import('features/reports/pages/megReports')),
				fallback: Loader(),
			},
			{
				path: '/reports/my-reports',
				exact: true,
				displayName: 'My Reports',
				baseName: 'Reports',
				dropdownMenus: reportsDropdowns,
				component: lazy(() => import('features/reports/pages/myReports')),
				fallback: Loader(),
			},
			{
				path: '/reports/additional-downloads',
				exact: true,
				displayName: 'Additional Downloads',
				baseName: 'Reports',
				dropdownMenus: reportsDropdowns,
				component: lazy(() => import('features/reports/pages/additionalDownloads')),
				fallback: Loader(),
			},
			{
				path: '/reports/*',
				exact: true,
				displayName: '',
				redirect: '/page-not-found',
				fallback: '',
			},
		],
	},
	// Reports redirects
	{
		path: '/reports',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/reports/meg-reports',
		fallback: '',
	},
	// Reports redirects
	{
		path: '/groups/reports',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/groups/reports/school-and-college-reports',
		fallback: '',
	},
	// Reports redirects
	{
		path: '/connect/targets',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/reports/meg-reports',
		fallback: '',
	},
	/**
	 ** Alps Admin
	 */
	{
		path: '/alps-admin',
		exact: true,
		displayName: 'Alps Admin',
		baseName: 'Alps Admin',
		component: lazy(() => import('features/alpsAdmin/alpsAdmin')),
		fallback: Loader(),
	},
	/**
	 ** Alps Admin
	 */
	{
		path: '/admin-search',
		exact: false,
		displayName: 'Admin Search',
		baseName: 'Admin Search',
		component: lazy(() => import('features/alpsAdmin/alpsAdminSearch')),
		fallback: Loader(),
	},
	{
		path: '/reporting-season/:reportingSeasonPage',
		exact: false,
		displayName: 'Reporting Season',
		baseName: 'Reporting Season',
		component: lazy(() => import('features/alpsAdmin/reportingSeason/reportingSeason')),
		fallback: Loader(),
		routes: [
			{
				path: '/reporting-season/client-info',
				exact: true,
				displayName: 'Client Info',
				baseName: 'Reporting Season',
				component: lazy(() => import('features/alpsAdmin/reportingSeason/pages/clientInfo')),
				fallback: Loader(),
			},
		],
	},
	{
		path: '/reporting-season',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/reporting-season/client-info',
		fallback: '',
	},
	{
		path: '/admin/:adminPage/:userId',
		exact: false,
		displayName: 'Admin',
		baseName: 'Admin',
		component: lazy(() => import('features/alpsAdmin/alpsAdmin')),
		fallback: Loader(),
		routes: [
			{
				path: '/admin/edit-entitlements/:id',
				exact: true,
				displayName: 'Edit Entitlement',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/editEntitlements')),
				fallback: Loader(),
			},
			{
				path: '/admin/edit-subscriptions/:id',
				exact: true,
				displayName: 'Edit Subscriptions',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/editSubscriptions')),
				fallback: Loader(),
			},
			{
				path: '/admin/edit-contract/:id/:academicYear',
				exact: true,
				displayName: 'Edit Contract',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/editContract')),
				fallback: Loader(),
			},
		],
	},
	{
		path: '/admin/:adminPage',
		exact: true,
		displayName: 'Alps Admin',
		baseName: 'Admin',
		component: lazy(() => import('features/alpsAdmin/alpsAdmin')),
		fallback: Loader(),
		routes: [
			{
				path: '/admin/details',
				exact: true,
				displayName: 'Details',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/details')),
				fallback: Loader(),
			},
			{
				path: '/admin/all-users',
				exact: true,
				displayName: 'Users',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/allUsers')),
				fallback: Loader(),
			},
			{
				path: '/admin/entitlements',
				exact: true,
				displayName: 'Entitlements',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/entitlements')),
				fallback: Loader(),
			},
			{
				path: '/admin/config-entitlements',
				exact: true,
				displayName: 'Config Entitlements',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/configurationEntitlements')),
				fallback: Loader(),
			},
			{
				path: '/admin/subscriptions',
				exact: true,
				displayName: 'Subscriptions',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/subscriptions')),
				fallback: Loader(),
			},
			{
				path: '/admin/contracts',
				exact: true,
				displayName: 'Contracts',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/contracts')),
				fallback: Loader(),
			},
			{
				path: '/admin/view-as-client',
				exact: true,
				displayName: 'View as Client',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/viewAsClient')),
				fallback: Loader(),
			},
			{
				path: '/admin/patype-config',
				exact: true,
				displayName: 'Prior Attainment Type Config',
				baseName: 'Admin',
				component: lazy(() => import('features/alpsAdmin/pages/paTypeConfiguration')),
				fallback: Loader(),
			},
			{
				path: '/admin/*',
				exact: true,
				displayName: '',
				redirect: '/page-not-found',
				fallback: '',
			},
		],
	},
	// Admin redirects
	{
		path: '/admin',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/admin/all-users',
		fallback: '',
	},
	/**
	 * Groups
	 */
	// School Selector
	{
		path: '/groups/view-as-school/',
		exact: true,
		displayName: 'View Connect for your schools/colleges',
		baseName: 'View Connect for your schools/colleges',
		component: lazy(() => import('features/groups/viewAs/viewAs')),
		fallback: Loader(),
	},
	{
		path: '/groups/school/start/:contractId/:schoolId/:trendId/:clientBm',
		exact: true,
		displayName: 'View As School/College',
		baseName: 'View As School/College',
		component: lazy(() => import('features/groups/viewAsStart')),
		fallback: Loader(),
	},
	// Subjects
	{
		path: '/groups/subjects/',
		exact: true,
		displayName: 'Subject Value Added Overview',
		baseName: 'Subject Value Added Overview',
		dropdownMenus: groupsRootDropDowns,
		component: lazy(() => import('features/groups/analysis/subjects/subjectsOverview')),
		fallback: Loader(),
	},
	{
		path: '/groups/subjects/:subjectId/',
		exact: true,
		displayName: 'Subject Overview',
		baseName: 'Subject Overview',
		dropdownMenus: groupsRootDropDowns,
		component: lazy(() => import('features/groups/analysis/subjects/subjectPage')),
		fallback: Loader(),
	},
	{
		path: '/groups/subjects/:subjectId/:groupId',
		exact: true,
		displayName: 'Subject Overview',
		baseName: 'Subject Overview',
		dropdownMenus: groupsRootDropDowns,
		component: lazy(() => import('features/groups/analysis/subjects/subjectPage')),
		fallback: Loader(),
	},
	// BBPA
	{
		path: '/groups/bbpa/',
		exact: true,
		displayName: 'BBPA Analysis',
		baseName: 'BBPA Analysis',
		dropdownMenus: groupsRootDropDowns,
		component: lazy(() => import('features/groups/analysis/bbpa/bbpa')),
		fallback: Loader(),
	},
	// Strategic
	{
		path: '/groups/strategic/',
		exact: true,
		displayName: 'Strategic Analysis',
		baseName: 'Strategic Analysis',
		dropdownMenus: groupsRootDropDowns,
		component: lazy(() => import('features/groups/analysis/strategic/strategic')),
		fallback: Loader(),
	},
	// Group Setup
	{
		path: '/groups/setup/:groupSetupPage',
		exact: true,
		displayName: 'Group Setup',
		baseName: 'Group Setup',
		component: lazy(() => import('features/groups/setup/setup')),
		fallback: Loader(),
		routes: [
			{
				path: '/groups/setup/custom-columns',
				exact: true,
				displayName: 'Custom Columns',
				baseName: 'Group Setup',
				dropdownMenus: groupsRootDropDowns,
				component: lazy(() => import('features/groups/setup/customColumns/customColumns')),
				fallback: Loader(),
			},
			{
				path: '/groups/setup/custom-comparisons',
				exact: true,
				displayName: 'Custom Comparisons',
				baseName: 'Group Setup',
				dropdownMenus: groupsRootDropDowns,
				component: lazy(() => import('features/groups/setup/customComparisons/customComparisons')),
				fallback: Loader(),
			},
			{
				path: '/groups/setup/school-groups',
				exact: true,
				displayName: 'School/College Groups',
				baseName: 'Group Setup',
				dropdownMenus: groupsRootDropDowns,
				component: lazy(() => import('features/groups/setup/schoolGroups/schoolGroups')),
				fallback: Loader(),
			},
			{
				path: '/groups/setup/monitoring-timeline',
				exact: true,
				displayName: 'Monitoring Timeline',
				baseName: 'Group Setup',
				dropdownMenus: groupsRootDropDowns,
				component: lazy(
					() => import('features/groups/setup/monitoringTimeline/monitoringTimeline')
				),
				fallback: Loader(),
			},
			{
				path: '/groups/setup/trends',
				exact: true,
				displayName: 'Trends',
				baseName: 'Group Setup',
				dropdownMenus: groupsRootDropDowns,
				component: lazy(() => import('features/groups/setup/trends/manageTrends')),
				fallback: Loader(),
			},

			{
				path: '/groups/setup/my-schools',
				exact: true,
				displayName: 'My Schools/Colleges',
				baseName: 'Group Setup',
				dropdownMenus: groupsRootDropDowns,
				component: lazy(() => import('features/groups/setup/mySchools/mySchools')),
				fallback: Loader(),
			},
			{
				path: '/groups/setup/rollover',
				exact: true,
				displayName: 'Rollover',
				baseName: 'Group Setup',
				dropdownMenus: groupsRootDropDowns,
				component: lazy(() => import('features/groups/setup/rollover/rollover')),
				fallback: Loader(),
			},
		],
	},
	// Group setup redirects
	{
		path: '/groups/setup/',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/groups/setup/custom-columns',
		fallback: '',
	},
	// Group benchmark redirects
	{
		path: '/groups/benchmark-setup/',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/groups/benchmark-setup/benchmark-selector',
		fallback: '',
	},
	// Strategic V2
	{
		path: '/strategic-analysis/Summary',
		exact: true,
		displayName: 'Strategic Analysis Summary',
		baseName: 'Strategic Analysis',
		dropdownMenus: strategic,
		component: lazy(() => import('features/strategicAnalysis/pages/summary')),
		fallback: Loader(),
	},
	{
		path: '/strategic-analysis/:strategicAnalysisPage',
		exact: true,
		displayName: 'Strategic Analysis',
		baseName: 'Strategic Analysis',
		component: lazy(() => import('features/strategicAnalysis')),
		fallback: Loader(),
		routes: [
			{
				path: '/strategic-analysis/Overview',
				exact: true,
				displayName: 'Overview',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(() => import('features/strategicAnalysis/pages/overview/overview')),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/BbpaCurrentYear',
				exact: true,
				displayName: 'BBPA Current Year',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(
					() => import('features/strategicAnalysis/pages/bbpaCurrentYear/bbpaCurrentYear')
				),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/BbpaMultiYear',
				exact: true,
				displayName: 'BBPA Multi Year',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(
					() => import('features/strategicAnalysis/pages/bbpaMultiYear/bbpaMultiYear')
				),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/QualityIndicator',
				exact: true,
				displayName: 'Quality Indicator',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(
					() => import('features/strategicAnalysis/pages/qualityIndicator/qualityIndicator')
				),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/QualityOfTeaching',
				exact: true,
				displayName: 'Quality of Teaching',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(
					() => import('features/strategicAnalysis/pages/qualityOfTeaching/qualityOfTeaching')
				),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/AttainmentProfile',
				exact: true,
				displayName: 'Attainment Profile',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(
					() => import('features/strategicAnalysis/pages/attainmentProfile/attainmentProfile')
				),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/StudentsNotIncluded',
				exact: true,
				displayName: 'Students not Included',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(
					() => import('features/strategicAnalysis/pages/studentsNotIncluded/studentsNotIncluded')
				),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/SubjectValueAdded',
				exact: true,
				displayName: 'Subject Value Added',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(
					() => import('features/strategicAnalysis/pages/subjectValueAdded/subjectValueAdded')
				),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/Tscore',
				exact: true,
				displayName: 'T Score',
				baseName: 'Strategic Analysis',
				permissions: 'strategic_analysis',
				dropdownMenus: strategicDropdowns,
				component: lazy(() => import('features/strategicAnalysis/pages/tScore/tScore')),
				fallback: Loader(),
			},
			{
				path: '/strategic-analysis/*',
				exact: true,
				displayName: '',
				redirect: '/strategicAnalysis/start',
				fallback: '',
			},
		],
	},
	// Strategic V2 redirects
	{
		path: '/strategic-analysis/',
		exact: true,
		displayName: '',
		baseName: '',
		redirect: '/strategic-analysis/summary',
		fallback: '',
	},
	/**
	 ** 404
	 */
	{
		path: '/page-not-found',
		exact: true,
		displayName: 'Page Not Found',
		baseName: 'Page Not Found',
		component: lazy(() => import('features/pageNotFound/')),
		fallback: Loader(),
	},
	// 404 redirects
	{
		path: '*',
		exact: false,
		redirect: '/page-not-found',
		displayName: 'Page Not Found',
		baseName: 'Page Not Found',
		fallback: '',
	},
];
