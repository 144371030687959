import { totalActualPoints } from './outcomeSet';
import { subjectVAS } from './measures';
import { totalExpectedPoints } from './targetSet';
import { getGrade } from './percentileSet';
import { whitespaceRegex } from './regex';
import { SubjectPage } from 'features/subjectPage/types';

/**
 * Check if groups has included students
 */
export function hasIncludedStudents(studentGroup: SubjectPage.Student[]) {
	return studentGroup.some((student) => student.IsIncluded);
}

/**
 * Get the current actual (i.e. fixed, non-What-If? results) VAS for this
 * group of students
 */
export function VAS(
	studentGroup: SubjectPage.Student[],
	subjectInformation: Thermometer.SubjectInformation,
	whatIf: boolean = false,
	title: string = '',
	fineGrades: boolean = false
): { score: number; grade: number; title: string } {
	// ensure only included students are in the VAS calculation
	const filteredStudents = whatIf
		? studentGroup.filter((s) => s.IsIncluded)
		: studentGroup.filter((s) => s.IsIncluded && !s.IsQGrade);

	const score = subjectVAS(
		totalActualPoints(subjectInformation.outcomeSet, filteredStudents, whatIf, fineGrades),
		/**
		 * Added whatIf flag to allow 'Q' grades to be factored into the equation
		 * if whatIf is toggled
		 */
		totalExpectedPoints(subjectInformation.targetSet, filteredStudents, whatIf),
		/**
		 * Users can now move from a 'Q' grade to other grades
		 * if this is the case we no longer filter out the 'Q' grades
		 * this is filter at a higher level so we only need to get the length here
		 */
		filteredStudents.length,
		subjectInformation.entryMultiplier
	);

	return { score, grade: getGrade(subjectInformation.percentileSet, score) as number, title };
}

/**
 * Gets a breakdown of the VAS calculation details
 * Returns an object structured as
 * { score: 1.22, grade: 1, totalExpectedPoints: 600, totalActualPoints: 430, studentCount: 6, multiplier: 100 }
 */
export function VASCalculationDetails(
	studentGroup: SubjectPage.Student[],
	subjectInformation: Thermometer.SubjectInformation,
	whatIf: boolean = false,
	fineGrades: boolean = false
): {
	score: number;
	grade: number;
	totalExpectedPoints: number;
	totalActualPoints: number;
	studentCount: number;
	multiplier: number;
} {
	const actualPoints = parseFloat(
		totalActualPoints(subjectInformation.outcomeSet, studentGroup, whatIf, fineGrades).toFixed(2)
	);

	const expectedPoints = parseFloat(
		totalExpectedPoints(subjectInformation.targetSet, studentGroup, whatIf).toFixed(2)
	);

	const studentCount = studentGroup.length;
	const multiplier = subjectInformation.entryMultiplier;

	const score = parseFloat(
		subjectVAS(
			actualPoints,
			/**
			 * Added whatIf flag to allow 'Q' grades to be factored into the equation
			 * if whatIf is toggled
			 */
			expectedPoints,
			/**
			 * Users can now move from a 'Q' grade to other grades
			 * if this is the case we no longer filter out the 'Q' grades
			 * this is filter at a higher level so we only need to get the length here
			 */
			studentCount,
			multiplier
		).toFixed(2)
	).toFixed(2); // parsefloat removes the trailing 0 so re-add it

	return {
		score,
		grade: getGrade(subjectInformation.percentileSet, score) as number,
		totalExpectedPoints: expectedPoints,
		totalActualPoints: actualPoints,
		studentCount,
		multiplier,
	};
}

/**
 ** Get the total number of students
 */
export function totalStudents(
	studentGroup: SubjectPage.Student[],
	whatIf: boolean = false
): number {
	return whatIf
		? studentGroup.filter((s) => s.IsIncluded).length
		: studentGroup.filter((s) => s.IsIncluded && !s.IsQGrade).length;
}

/**
 ** Returns the average prior attainment scores for a group of students
 */
export const getAveragePA = (students: SubjectPage.Student[]) => {
	let total = 0;
	let studentCount = 0;
	students.forEach((s) => {
		if (s.IsIncluded) {
			const obj = s.PriorAttainment;
			for (const [key, value] of Object.entries(obj)) {
				total += value.Score;
			}
			studentCount++;
		}
	});

	return total / studentCount;
};

/**
 ** Returns the average prior attainment scores for a group of students
 */
export const getAverageGCSEPoints = (students: SubjectPage.Student[]) => {
	let total = 0;
	let studentCount = 0;
	students.forEach((s) => {
		if (s.IsIncluded && s.GCSEScore) {
			total += s.GCSEScore;
			studentCount++;
		}
	});

	return total / studentCount;
};

/**
 ** Returns a list of students present in each comparison group:
 ** { male: { studentId: 1, firstname: "bob", lastname: "smith" ...}, 31 - White - British White: { studentId: 2, firstname: "steve": lastname: "jones" ...}}
 ** includeOverallGroup param is whether or not to return all of the students in an all group in addition to the comparison gorups
 */
export const groupStudentsByViewGroups = (students: any, customAppliedComparisons: any) => {
	// go through the students and create view groups from the students
	let studentsGroupedByComparisons = students.reduce((prev: { [k: string]: any[] }, student) => {
		// go through the viewgroups of the student
		let viewGroupsWithStudents = student.ViewGroups.reduce(
			(obj, curr) => ({
				...obj,
				[curr]: [...(obj[curr] || []), student],
			}),
			{}
		);
		return Object.entries(viewGroupsWithStudents).reduce(
			(obj, [key, students]) => ({
				...obj,
				[key]: [...(obj[key] || []), ...students],
			}),
			prev
		);
	}, {});

	return {
		All: students,
		...studentsGroupedByComparisons,
	};
};

export const filterStudentsByUdfType = (students: any, udfType: string, udfValue: string) => {
	if (!students) return;

	const udfTypeSanitized = udfType.replace(whitespaceRegex, '');

	return Object.values(students).filter((student: SubjectPage.Student) => {
		if (udfType && !student[udfTypeSanitized]) {
			return false;
		} else if (!student[udfTypeSanitized]) {
			return [];
		}

		return (
			student[udfTypeSanitized].toLowerCase() ===
			udfValue.replace(whitespaceRegex, '').toLowerCase()
		);
	});
};

/**
 * This function takes comparison groups of students and orders it so that
 * the All Students group is at the top, preserving the ordering of the remaining groups
 */
export const groupAndSortComparisonGroupsWithAllGroup = (comparisonGroups: any) => {
	return comparisonGroups.sort((comparisonGroupA: any, comparisonGroupB: any) => {
		const a = comparisonGroupA[0];
		const b = comparisonGroupB[0];

		// Sort the All group so it is at the top
		if (a.includes('All')) {
			return -1;
		} else if (b.includes('All')) {
			return 1;
		}

		// Sort the Unknown so that group is at the bottom - Note: This group should only exist for custom comparisons
		if (a === 'Unknown') {
			return 1;
		} else if (b === 'Unknown') {
			return -1;
		}

		// important - leave everything else in position
		return 0;
	});
};

/**
 * This function takes comparison groups of students and orders them so that the
 * All Students group is at the top and that the unknown groups are with their
 * respective siblings e.g.
 * All Students
 * Female
 * Male
 * Gender - Unknown
 * Disadvantaged
 * Disadvantaged - Unknown
 */
export const sortComparisonStudentViewGroups = (
	availableComparisons: any,
	studentViewGroups: any,
	appliedComparisons: any,
	customComparisons: any
) => {
	const appliedComparisonStudentGroups = sortComparisonGroupsByUnknown(
		availableComparisons,
		studentViewGroups
	);

	// Add the all group
	const allGroup = Object.entries(studentViewGroups).find((entry) => {
		return entry[0].includes('All'); // supports "All Students" SPO and "All" grouping elsewhere
	});

	let combinedGroups = Object.values(appliedComparisonStudentGroups).flat();

	combinedGroups.push(allGroup);

	addCustomComparisonGroups(
		appliedComparisons,
		customComparisons,
		studentViewGroups,
		combinedGroups
	);

	// format the data
	return groupAndSortComparisonGroupsWithAllGroup(combinedGroups).reduce((pre, curr) => {
		return {
			...pre,
			[curr[0]]: curr[1],
		};
	}, {});
};

function addCustomComparisonGroups(
	appliedComparisons: any,
	customComparisons: any,
	studentViewGroups: any,
	combinedGroups: unknown[]
) {
	// go through the comparisions/filters that have been applied and find the matching item from the custom comparisons
	const customAppliedComparisons = appliedComparisons.filter(
		(appliedComparison: any) => customComparisons[appliedComparison.value]
	);

	if (customAppliedComparisons) {
		// get the custom comparison labels
		let customComparisonLabels = customAppliedComparisons.flatMap(
			(customComparison: any) => customComparison.label
		);

		// find all the matching custom comparison view groups in the student view groups
		const customComparisonViewGroups = Object.entries(studentViewGroups).filter((entry) => {
			return customComparisonLabels.find((e) => e === entry[0]);
		});

		if (customComparisonViewGroups) {
			customComparisonViewGroups.forEach((ccG) => {
				combinedGroups.push(ccG);
			});
		}
	}
}

/**
 * This function takes an array of student view groups e.g
 * Male: { StuA... StuB... }, Female: { StuA... StuB... }
 * Identifies their parent group e.g. Gender and then groups
 * them so that the Unknown view group is displayed at the bottom
 * For example: Male, Gender - Unknown, Female, Disadvantaged will be returned as
 * Female, Male, Gender-Unknown, Disadvantaged
 */
export const sortComparisonGroupsByUnknown = (
	availableComparisons: any,
	studentViewGroups: any
) => {
	return availableComparisons.reduce((object: any, currGroup: any) => {
		// get all of the labels for the current group
		const groupOptionLabels = currGroup.options.map((val: any) => val.label);

		// check if there is an unknown entry for the current comparison group
		const unknownGroup = Object.keys(studentViewGroups).find((key) => {
			return key.includes(currGroup.label) && key.includes('Unknown');
		});

		// if there is an unknown group, add it to the list of group option labels
		if (unknownGroup) {
			groupOptionLabels.push(unknownGroup);
		}

		// go through the labels and get the student view group
		const comparisonGroups = groupOptionLabels.reduce((obj: any, currLabel: string) => {
			// if the label exists in the student view groups, add it
			if (studentViewGroups[currLabel] && Object.entries(studentViewGroups[currLabel]).length > 0) {
				let label = currLabel;

				if (/\bhigh\b|\bmid\b|\blow\b/i.test(currLabel.toLowerCase())) {
					label = currLabel + ' PA';
				}

				return {
					...obj,
					[label]: studentViewGroups[currLabel],
				};
			}

			// else just return the obj
			return obj;
		}, {});

		if (Object.values(comparisonGroups).length > 0) {
			const sortedComparisonGroups = Object.entries(comparisonGroups).sort(
				(comparisonGroupA, comparisonGroupB) => {
					const a = comparisonGroupA[0];
					const b = comparisonGroupB[0];

					if (currGroup.label === 'High/Middle/Low PA') {
						if (a.includes('High')) {
							return 1;
						} else if (b.includes('Low')) {
							return -1;
						} else {
							return 0;
						}
					}

					if (a === b) {
						return 0;
					}
					// sorts unknowns last and alls first
					else if (a.includes('Unknown')) {
						return 1;
					} else if (b.includes('Unknown')) {
						return -1;
					} else {
						return a < b ? -1 : 1;
					}
				}
			);

			return {
				...object,
				[currGroup.label]: sortedComparisonGroups,
			};
		}

		return object;
	}, {});
};
