import ExamLevels from './examLevel';
import { ExamLevels as ExamLevelsEnum } from '../types/enum';

type QualTypesByLevel = {
	[level: string]: Filters.QualType[];
};

export const createQualTypeFilter = (examLevels: ExamLevelsEnum[] = []): Filters.QualType => {
	const uniqueLevels = Array.from(new Set(examLevels));

	const filtersByLevel: QualTypesByLevel = uniqueLevels.reduce((obj, el) => {
		const key: string = ExamLevels[el]?.level;

		if (key) {
			return {
				...obj,
				[key]: [
					...(obj[key] || []),
					{
						label: ExamLevels[el]?.compactDisplay,
						value: el,
					},
				],
			};
		}

		return {
			...obj,
		};
	}, {});

	return Object.entries(filtersByLevel).map(([level, options]) => {
		if (options.length === 1) {
			return options[0];
		}

		return {
			label: level,
			options,
		};
	});
};

/**
 * This is used to correctly order Alps standard comparisons in the filter bar for summit
 * @param value of comparions
 */
//TODO: IF FUTHER COMPARISONS ARE ADDED THIS WILL NEED UPDATING
export const sortedComparisons = (value: number) => {
	if (window.environmentName === 'test') {
		return testEnvironmentSummitSorting(value);
	}
	if (window.environmentName === 'staging') {
		return stagingEnvironmentSummitSorting(value);
	}
	if (window.environmentName === 'production') {
		return productionEnvironmentSummitSorting(value);
	}
	if (window.environmentName === 'development') {
		return developmentEnvironmentSummitSort(value);
	}
};

//for ease of development - all possibleIds will be comparied
const developmentEnvironmentSummitSort = (id: number) => {
	switch (id) {
		//Gender
		case 23:
			return 0;
		case 9:
			return 0;
		case 2:
			return 0;
		//Disadvantaged
		case 22:
			return 1;
		case 5:
			return 1;
		case 1:
			return 1;
		//Ethnicity
		case 24:
			return 2;
		case 10:
			return 2;
		case 3:
			return 2;
		//HML PA
		case 8:
			return 3;
		case 35:
			return 3;
		case 4:
			return 3;
		//EAL
		case 36:
			return 4;
		case 7:
			return 4;
		case 52:
			return 4;
		//SEND
		case 17:
			return 5;
		case 67:
			return 5;
		case 53:
			return 5;
	}
};

const testEnvironmentSummitSorting = (id: number) => {
	switch (id) {
		/**
		 * Test comparisonIds
		 */
		//Gender
		case 9:
			return 0;

		//Disadvantaged
		case 5:
			return 1;

		//Ethnicity
		case 10:
			return 2;

		//HML PA
		case 35:
			return 3;

		//EAL
		case 7:
			return 4;

		//SEND
		case 67:
			return 5;
	}
};

const stagingEnvironmentSummitSorting = (id: number) => {
	switch (id) {
		/**
		 * Staging comparisonIds
		 */
		//Gender
		case 23:
			return 0;

		//Disadvantaged
		case 22:
			return 1;

		//Ethnicity
		case 24:
			return 2;

		//HML PA
		case 8:
			return 3;

		//EAL
		case 36:
			return 4;

		//SEND
		case 17:
			return 5;
	}
};

const productionEnvironmentSummitSorting = (id: number) => {
	switch (id) {
		/**
		 * Production comparison Ids
		 */
		//Gender
		case 2:
			return 0;

		//Disadvantaged
		case 1:
			return 1;

		//Ethnicity
		case 3:
			return 2;

		//HML PA
		case 4:
			return 3;

		//EAL
		case 52:
			return 4;

		//SEND
		case 53:
			return 5;
	}
};
