/**
 * Loading selectors
 */

import { SubjectArea } from 'features/subjectArea/types';
import { getSlice } from './slice';

/**
 * Gets the loading status for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns Boolean
 */
export const getLoading = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): boolean => {
	const slice = getSlice(page)(state);

	return slice.loading;
};

/* Gets the loading status for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns Boolean
 */
export const getOverAllSubjectAreaLoading = (state: RootState): boolean => {
	return state.subjectArea.loading;
};
