import * as React from 'react';
import { FunctionComponent, useState } from 'react';

import { Flex, Box, Icon, Heading, Text, IconButton, Link } from 'basecamp';
import { useHistory } from 'react-router-dom';
import { fetchPDF } from '../../../api/fileDownloadAPI';
import { notificationTypes } from '../../../api/rtmAPI';
import { translate } from '../../../utils/locale';

type Props = {
	message: RTM.Message;
	removeMessage: () => void;
	acknowledgeMessage: () => void;
};

const RtmMessage: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	const history = useHistory();

	const [pdfDownloading, setPDfDownloading] = useState<boolean>(false);

	//Message click handling logic for different notification types
	const onClick = async () => {
		if (props.message.type.id === notificationTypes.MEG) {
			if ((props.message.data as RTM.MEGData).success) history.push('/reports/meg-reports');
		}

		if (props.message.type.id === notificationTypes.PDF) {
			setPDfDownloading(true);

			await fetchPDF(
				(props.message.data as RTM.PDFReportData).pdfReference,
				(props.message.data as RTM.PDFReportData).pdfFilename
			);
		}

		// If the message type is summit navigate to summit subjects page
		if (
			props.message.type.id === notificationTypes.SUMMIT &&
			(props.message.data as RTM.SummitData).success === true
		) {
			history.push('/groups/subjects');
		}

		// always acknowledge the message on click
		props.acknowledgeMessage();
	};

	return (
		// Message Wrapper
		<Flex
			setAs="column"
			bg="UI.white"
			borderRadius={2}
			width={1}
			mb={4}
			overflow="hidden"
			boxShadow="sm"
			dataTest="rtmMessage"
		>
			{/* Message type */}
			<Flex
				setAs="row"
				withAlign="distribute"
				bg="UI.secondary.4"
				height="30px"
				px={3}
				color="UI.white"
				backgroundColor={props.message.type.color}
				dataTest="rtmMessageTypeWrap"
			>
				<Flex height="30px" withAlign="center">
					{/* message type icon */}
					<Box mt="2px" dataTest="rtmMessageTypeIcon">
						<Icon icon={props.message.type.icon as any} />
					</Box>
					{/* message type text */}
					<Text setAs="p" withColor="black" my={0} ml={2} dataTest="rtmMessageType">
						{props.message.type.name.toLocaleUpperCase()}
					</Text>
				</Flex>
				<Flex height="30px" withAlign="centerRight">
					{/* NEW message indicator */}
					{!props.message.acknowledged && (
						<Flex
							bg="UI.accent.0"
							height="20px"
							px={2}
							borderRadius={5}
							mr={2}
							withAlign="center"
							dataTest="rtmMessageNewIndicator"
						>
							<Text setAs="small" withColor="white" my={0} dataTest="rtmMessageNewIndicatorText">
								NEW
							</Text>
						</Flex>
					)}
					{/* Remove single message btn */}
					<IconButton
						setAs="nav"
						withSize="small"
						withIcon="xmark"
						onClick={() => props.removeMessage()}
						dataTest="rtmRemoveMessageBtn"
					/>
				</Flex>
			</Flex>
			{/* Message title and body */}
			{!pdfDownloading && (
				<Flex setAs="column" p={3} pt={2} onClick={onClick}>
					<Heading setAs="small" withColor="black" fontSize={3} mb={1} dataTest="rtmMessageTitle">
						{props.message.title}
					</Heading>
					<Link setAs="text" withSize="medium">
						<Text setAs="p" mb={0} dataTest="rtmMessageBody">
							{props.message.body}
						</Text>
					</Link>
				</Flex>
			)}

			{pdfDownloading && (
				<Flex setAs="column" p={3} pt={2} onClick={onClick}>
					<Heading setAs="small" withColor="black" fontSize={3} mb={1} dataTest="rtmMessageTitle">
						{props.message.title}
					</Heading>
					<Text setAs="p" mb={0} dataTest="rtmMessageBody">
						{translate('rtm.downloading')}
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

export default RtmMessage;
