import { getMetrics } from '../../../../api/performanceMetricAPI';
import { QueryType } from '../../types/enum';
import { setStudentMetricsSuccess, setStudentMetricsPending } from '../';

// list performance metrics
// TODO - Remove after testing
export const getStudentMetricsThunk = (
	queryType: QueryType,
	gp: number[] | null,
	metricGroup: string | null,
	metricId: number | null,
	comparisonId: number | null,
	comparisonValueId: number | null
): AppThunk => async (dispatch) => {
	dispatch(setStudentMetricsPending());

	try {
		const response = await getMetrics(
			queryType,
			gp,
			metricGroup,
			metricId,
			comparisonId,
			comparisonValueId
		);

		if (response.HasError) {
			return console.error(
				'Oh noes, the super secret Mahvish table has blown up!',
				response.Errors[0].Message
			);
		}

		dispatch(setStudentMetricsSuccess(response.ResponseObject));
	} catch (err) {
		console.error('Oh noes, the super secret Mahvish table has blown up!', err.message);
	}
};
