import { store } from '../store/store';
import {
	getAppliedComparisons,
	getAppliedFilters,
	getAppliedGradepoints,
	hasKS4Entitlement,
	hasKS5Entitlement,
	getAppliedExamLevels,
	getAvailableExamLevels,
	getBenchmarkSetCountry,
	getTargetSettingGradepoints,
	getPrimaryGradepoint,
	getBenchmarkSetName,
} from 'features/app/redux/context';

import ExamLevels from '../utils/examLevel';

//TODO: TypeScriptfy this file!!

/**
 * Constant used to get available filters from Reporting API
 */
export const ALLOW_FILTERS_FOR = {
	overall: 'AllowInOverallAnalysis',
	student: 'AllowInStudentAnalysis',
	subject: 'AllowInSubjectAnalysis',
};

/**
 * Create request base from context
 * @param {object} context
 * @param {array} gradepoints
 */
function APIReportBase(state: any, gradepoints: any) {
	return {
		tag: '',
		benchmarkSet: getBenchmarkSetName(state),
		subjectGroupSet: '',
		username: state.context.user.username,
		client: state.context.client.name,

		dataSpec: {
			gradepoints: gradepoints,

			dataItems: [
				{
					type: 'ClientDataset',
					id: state.context.reportContext.currentDataset.datasetItem.split(':')[1],
				},
			],

			filters: [],
		},

		params: [],
	};
}

const getExamLevel = (state: RootState) => {
	let examLevel = null;
	const hasKS4 = hasKS4Entitlement(state);
	const hasKS5 = hasKS5Entitlement(state);

	if (hasKS4 && hasKS5) {
		examLevel = 'All';
	} else if (hasKS4 && !hasKS5) {
		examLevel = 'KS4';
	} else if (!hasKS4 && hasKS5) {
		examLevel = 'KS5_Extended';
	}

	return examLevel;
};

//If exam filters applied will pass the correct format to API
const hasChanged = (state: RootState) => {
	const applied = getAppliedExamLevels(state).map((x) => x.label);
	const display = Object.values(ExamLevels).filter((x) => applied.includes(x.compactDisplay));
	return display.map((x) => x.key);
};

/**
 * Format one or more report references into a Reporting API request object
 * @param ref
 * @param passedOptions
 */
export function formatReportRequest(
	ref: string | string[],
	appliedFilters: { label: string; value: string }[],
	appliedComparisons: { label: string; value: string }[],
	passedOptions?: ReportAPIOptions,
	includeTargetSetting?: boolean,
	includeTeachingSets?: boolean,
	excelFormatting?: boolean,
	usePtValues?: boolean
) {
	const state = store.getState();
	const filters = Object.entries(
		appliedFilters.reduce((obj: { [key: string]: any }, { value }) => {
			const [Name, Value] = value.split('|');

			return {
				...obj,
				[Name]: [Value, ...(obj[Name] || [])],
			};
		}, {})
	)
		.map(([label, value]) => {
			const match = state.context.availableFilters.find(({ name }) => name === label);

			if (!match) return null;

			return {
				...match.filter,
				value,
			};
		})
		.filter(Boolean);

	const comparisonGroups = Object.entries(
		appliedComparisons.reduce((obj: { [key: string]: any }, { value }) => {
			const [Name, Value] = value.split('|');

			return {
				...obj,
				[Name]: [Value, ...(obj[Name] || [])],
			};
		}, {})
	)
		.map(([label, value]) => {
			const match = state.context.availableComparisons.find(({ name }) => name === label);

			if (match) {
				return value.map((v: any) => {
					const filter = match.values.find((x) => x.label === v);
					const labelName = v === 'Unknown' ? label + ' - Unknown' : v;
					return {
						name: labelName,
						filters: [
							{
								name: match.name,
								value: filter?.value || 'Unknown',
								type: filter?.type || 'StudentEntry',
							},
						],
					};
				});
			} else {
				//check custom comparisons for a match there
				const custom = state.settings.comparisons[label];
				if (!custom) return null;

				const filters = custom.items.map((item) => {
					const [parentName, childName] = item.value.split('|');
					const compMatch = state.context.availableComparisons.find(
						({ name }) => name === parentName
					);
					if (compMatch) {
						const option = compMatch?.values.find((x) => x.label === childName);
						return {
							name: compMatch?.name,
							value: option?.value,
							type: option?.type,
						};
					}

					return {
						name: parentName,
						value: childName,
						type: custom.appliesTo + 'Entry',
					};
				});

				return {
					name: custom.name,
					filters,
				};
			}
		})
		.flat()
		.filter(Boolean);

	let targetGp = null;
	if (includeTargetSetting) {
		const primaryGradepoint = getPrimaryGradepoint(state);
		const targetGradepoints = getTargetSettingGradepoints(state);
		targetGp = targetGradepoints.find((x) => x.year === primaryGradepoint.year);
	}

	const gradepoints =
		passedOptions && passedOptions.gradepoints
			? passedOptions.gradepoints
			: getAppliedGradepoints(state).map((x) => ({
					...x,
					order: null,
			  }));

	const parameters = [
		{
			key: 'country',
			value: getBenchmarkSetCountry(state),
		},
		{
			key: 'reqSubjectsOverviewIncludeTeachingSets',
			value: includeTeachingSets,
		},
		{
			key: 'reqUsePtValues',
			value: usePtValues,
		},
	];

	const allGradepoints = [...(targetGp ? [targetGp] : []), ...gradepoints];
	const defaultOptions = {
		output: 'JSON',
		formatting: '',
		filters,
		comparisonGroups,
		parameters,
	};

	const options = {
		...defaultOptions,
		...passedOptions,
		gradepoints: allGradepoints,
		filters: [...defaultOptions.filters, ...(passedOptions?.filters ?? [])],
		parameters: [...defaultOptions.parameters, ...(passedOptions?.parameters ?? [])],
	};

	const requests: any = {};
	requests.reportingAPIToken = state.context.yetiToken;
	requests.output = options.output;
	requests.filename = options.filename;
	requests.reports = [ref].flat().map((r) => {
		const report: any = APIReportBase(state, options.gradepoints);
		report.output = options.output;
		report.report = r;
		report.formatting = options.formatting;

		//if exam level not changed pass entitlement in, other wise pass in new array of exam levels
		report.examLevel = !excelFormatting ? getExamLevel(state) : hasChanged(state);

		report.dataSpec.filters = options.filters;
		report.comparisonGroups = options.comparisonGroups;

		report.params = options.parameters;

		return report;
	});

	return requests;
}
