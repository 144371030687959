import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubjectArea } from 'features/subjectArea/types';

/**
 * Initial State
 */
const initialState: SubjectArea.State = {
	currentSubjectId: undefined,
	activeExamlevel: undefined,
	showCount: true,
	loading: false,
	exportExcel: false,
	selectedDefaultExamLevels: [],
	appliedDefaultExamLevels: [],
	selectedPageExamLevels: {},
	appliedPageExamLevels: {},
	activeExamLevelTab: {},
	selectedDefaultFilters: [],
	selectedPageFilters: {},
	appliedPageFilters: {},
	selectedDefaultComparisons: [],
	selectedPageComparisons: {},
	selectedSubjectAreaComparisons: {},
	appliedPageComparisons: {},
	expandedComparisonRows: [],
};

/**
 * Subject Area Slice
 */
const subjectArea = createSlice({
	name: 'subjectArea',
	initialState,
	reducers: {
		toggleCount(state: SubjectArea.State) {
			return {
				...state,
				showCount: !state.showCount,
			};
		},
		setCurrentSubjectIdSubjectArea(state: SubjectArea.State, action: PayloadAction<number>) {
			return {
				...state,
				currentSubjectId: action.payload,
			};
		},
		setSubjectAreaActiveExamLevel(state: SubjectArea.State, action: PayloadAction<number>) {
			return {
				...state,
				activeExamlevel: action.payload,
			};
		},
		handleExcelExport(state: SubjectArea.State, action: PayloadAction<boolean>) {
			return {
				...state,
				exportExcel: action.payload,
			};
		},
		setSelectedSubjectAreaComparisons(
			state: SubjectArea.State,
			action: PayloadAction<CommonFeatureTypes.ComparisonOption>
		) {
			return {
				...state,
				selectedSubjectAreaComparisons: action.payload,
			};
		},
		setsubjectAreaLoading(state: SubjectArea.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},
	},
});

/**
 * Sagas
 */
export const changeSubjectId = createAction('subjectArea/changeSubjectId');

export const {
	toggleCount,
	setCurrentSubjectIdSubjectArea,
	setSubjectAreaActiveExamLevel,
	handleExcelExport,
	setSelectedSubjectAreaComparisons,
	setsubjectAreaLoading,
} = subjectArea.actions;

export default subjectArea.reducer;
