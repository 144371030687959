import { Context } from 'features/app/types';

/**
 *
 * @param embargoFeature Over feature flag for the embargo periods
 * @param embargoPeriod Array of permissions KS4 and KS5 early access
 * @param canViewAsSchool For MATs do they have the can view as permission
 * @returns
 */
export const withinEmbargoAndViewAsSchool = (
	embargoFeature: boolean,
	embargoPeriod: Context.EmbargoInformation[] | undefined,
	canViewAsSchool: boolean
) => {
	if (!embargoFeature) return false;

	// If embargo is on but the embargo period undefined, return false
	if (embargoPeriod === undefined) return false;

	// If there is an embargo period (could just be an empty array, meaning no actual values)
	if (embargoPeriod?.some((x) => x.value === true)) {
		// If we can view as school, return false
		if (canViewAsSchool) return false;

		// Otherwise we are in embargo and there is an embargo period and we are restricted by embargo
		return true;
	}

	// Otherwise embargo is on, a period is specified but no actual values, so return false
	return false;
};

/**
 *
 * Filter School and college reports to under specific embargo conditions
 * @param matReports School and college reports for specifc year
 * @param currentAcademicYear Value of the current academic year
 * @param selectedYear User Select academic year they wish to view
 * @param embargoPeriod If we are within the embargo period
 * @param canViewAsSchool Does user have the canViewAsSchool permisson
 * @returns
 */
export const schoolAndCollegeReportsEmbargoFilter = (
	matReports: MAT.Report[],
	currentAcademicYear: number,
	selectedYear: number,
	embargoPeriod: Context.EmbargoInformation[] | undefined,
	canViewAsSchool: boolean
): MAT.Report[] => {
	return matReports.filter((vals) => {
		/**
		 * Have we selected the current academic year in the drop down menu, if we have and the
		 * user DOESNT have canViewAsSchool permission, and we are IN the embargo period of KS5 filter
		 */
		if (
			currentAcademicYear === selectedYear &&
			embargoPeriod?.find((x) => x.keyStage === 'ks5')?.value &&
			!canViewAsSchool
		) {
			return vals.reportName === ' ';
		}

		/**
		 * Have we selected the current academic year in the drop down menu, if we have and the
		 * user DOESNT have canViewAsSchool permission, and we are IN the embargo period of KS4 filter
		 */
		if (
			currentAcademicYear === selectedYear &&
			embargoPeriod?.find((x) => x.keyStage === 'ks4')?.value &&
			!canViewAsSchool
		) {
			return vals.reportName !== 'KS4/Provider';
		}

		// No filtering required as either we arent in embargo, the current academic year ISNT selected or user has canViewAsSchool permission
		else {
			return vals;
		}
	});
};
