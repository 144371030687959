import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, Label, Text, IconButton, Select } from 'basecamp';

import { translate } from '../../../utils/locale';

type Props = {
	gradepointOptions: StudentModal.Option[];
	selectedComparison?: StudentModal.Option;
	setSelectedComparison: (option: StudentModal.Option) => void;
	handleExcelExport: () => void;
};

const StudentModalFilterBar: FunctionComponent<Props> = ({
	gradepointOptions,
	selectedComparison,
	setSelectedComparison,
	handleExcelExport,
}) => {
	/**
	 ** Render Component
	 */
	return (
		<Flex setAs="flex" withAlign="none" flexWrap="wrap">
			<Flex
				setAs="row"
				withAlign="centerLeft"
				flex="1 1 600px"
				bg="UI.white"
				py={2}
				px={3}
				borderRadius={3}
				mr={[0, 0, 0, 4, 4]}
				mb={4}
			>
				<Text mb={1}>{translate('subjectPage.studentModal.filterBar.INSTRUCTIONAL_TEXT')}</Text>
			</Flex>

			<Flex zIndex={3} setAs="column" flex="1 1 300px" mb={4}>
				<Label>{translate('subjectPage.studentModal.filterBar.labels.COMPARISON_GP')}</Label>
				<Select
					options={gradepointOptions}
					onChange={(option: StudentModal.Option) => setSelectedComparison(option)}
					value={selectedComparison}
					width="100%"
					isDisabled={gradepointOptions.length === 0}
				/>
			</Flex>

			<Flex setAs="column" withAlign="topLeft" flex="1 1 80px" flexGrow={[1, 1, 1, 1, 0]} mb={4}>
				<Label>{translate('subjectPage.studentModal.filterBar.labels.DOWNLOADS')}</Label>
				<IconButton withIcon="file-excel" setAs="accent" onClick={() => handleExcelExport()} />
			</Flex>
		</Flex>
	);
};

export default StudentModalFilterBar;
