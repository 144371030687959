import * as Polyglot from 'node-polyglot';
import * as React from 'react';
import createDOMPurify from 'dompurify';
import { ReactElement } from 'react';

let phrases = {};
let DOMPurify = {
	sanitize: (html: string, options: any) => html,
};

if (typeof window !== 'undefined') {
	/*
        Browser language loading
    */
	DOMPurify = createDOMPurify(window);
	let languages = window.navigator.languages
		? window.navigator.languages
		: [window.navigator.language];

	// Try load the language file for the browsers supported language
	languages.forEach((language, i, arr) => {
		// language file is loaded
		if (Object.keys(phrases || {}).length) return;

		try {
			phrases = require(`../../res/${language}`).default;
		} catch (err) {
			// If we've just tried to load in the last supported language file and
			// we're still getting an error, default to our known supported language
			if (i === arr.length - 1) {
				phrases = require('../../res/en-GB').default;
			}
		}
	});
} else {
	/*
        Server language loading - only support en-GB for now
     */
	phrases = require('../../res/en-GB').default;
}

const instance = new Polyglot.default({ phrases });

export { instance as i18n };

/**
 * Translation helper. Given an key from the i18n file, loads the given translation.
 * If the sanitized translation contains valid HTML, the string is returned embedded
 * in a span tag, otherwise the string is returned as-is
 *
 * @param {string} key - i18n key
 * @param {number|object} options - Options to pass to polyglot
 * @returns {string|HTMLSpanElement} Sanitised translation
 */
export function translate(key: string, options = {}): string | ReactElement {
	// Using the given key, check if it exists. If it doesn't exist as a string, but
	// does have a sub-key called '_' then change the key
	const k = !instance.has(key) && instance.has(`${key}._`) ? `${key}._` : key;

	// Get any stored default options if we're using a phrase._ key
	// @ts-ignore
	const { _, ...defaultOptions } =
		key === k ? {} : key.split('.').reduce((obj, key) => obj[key] || {}, phrases);

	// Construct the options object
	const opts = {
		...(defaultOptions || {}),
		...(typeof options === 'object' ? options : { smart_count: options }),
	};

	// Purify the string for DOM usage
	const str = DOMPurify.sanitize(instance.t(k, opts), {
		ADD_ATTR: ['target'], // allow target attribute so links can open in a new window
	});

	// If there's still valid HTML return the text wrapped in a span
	if (/<[a-z][\s\S]*>/i.test(str)) {
		return <span dangerouslySetInnerHTML={{ __html: str }} />;
	}

	// If the purified string contains no DOM elements then return the string
	return instance.t(k, opts);
}
