/**
 * Rows selectors
 */

import { SubjectArea } from 'features/subjectArea/types';
import { SubjectAreaGraphData } from 'features/subjectArea/types/common';
import { getSlice, getAllGraphComparisonData, getAppliedPageComparisons } from './';

/**
 * Gets all the loaded x axis data
 * @param state redux state
 * @returns An object byId / allIds for the loaded row data
 */
export const getAllXAxisData = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): SubjectAreaGraphData.Rows<SubjectAreaGraphData.XAxis> | undefined => {
	const slice = getSlice(page)(state);
	return slice.xAxis;
};

/* Get the x axis data with comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows with comparisons rows
 */
export const getXAxisDataWithComparisons = (
	page: SubjectArea.SubjectAnalysisPages,
	rowIds: string[]
) => (state: RootState): (SubjectAreaGraphData.xAxis | SubjectAreaGraphData.Xa)[] | null => {
	//ren
	const rows = getAllXAxisData(page)(state);
	const comparisons = getAllGraphComparisonData(page)(state);

	return !!rows
		? rowIds.flatMap((id) => {
				const comparisonIds = rows.byId[id].comparisonIds;
				return [
					rows.byId[id],
					...comparisonIds.map((compId) => {
						return comparisons!.byId[compId];
					}),
				];
		  })
		: null;
};

/**
 * Gets a single x axis id for the active exam level
 * @param page the active page
 * @param activeExamLevel active page exam level
 * @returns string array
 */
export const getXAxisIdsByExamLevel = (
	page: SubjectArea.SubjectAnalysisPages,
	activeExamLevel: CommonFeatureTypes.ExamLevels
) => (state: RootState): string[] => {
	const data = getAllXAxisData(page)(state);

	return data
		? Object.values(data.byId)
				.filter((axis) => axis.examLevelId === activeExamLevel)
				.map((axis) => axis.dataPointId)
		: [];
};

/**
 * Get the x axis data without comparisons added
 * @param xAxisIds row ids to map over
 * @returns top level (all) axis
 */
export const fetchAllIds = (page: any, xAxisIds: string[]) => (state: RootState): any[] | null => {
	const axis = getAllXAxisData(page)(state);

	return !!axis ? xAxisIds.flatMap((id) => axis.byId[id]) : null;
};

/**
 * Creates the graphs data for the main grids
 * @param xAxis row ids to map over
 * @returns Formatted row data, with or without comparisons
 */
export const createGraphData = (page: SubjectArea.SubjectAnalysisPages, axisIds: string[]) => (
	state: RootState
): (SubjectAreaGraphData.xAxis | SubjectAreaGraphData.ComparisonXAxis)[] | null => {
	const axis = getAllXAxisData(page)(state);
	const axisWithComparisons = getXAxisDataWithComparisons(page, axisIds)(state);
	//rename
	const axisWithoutComparisons = fetchAllIds(page, axisIds)(state);
	const appliedComparisons = getAppliedPageComparisons(page)(state);
	const axisComparisonsIds = axis?.byId[axisIds[0]].comparisonIds;
	const hasComparisonsForRow =
		axisComparisonsIds &&
		axisComparisonsIds.some((id) => {
			const applied = appliedComparisons[0]
				? appliedComparisons[0].value.split('|').join(' - ')
				: '';
			return id.includes(applied);
		});
	// If applied comparisons
	if (!!appliedComparisons && appliedComparisons.length > 0) {
		// Check if first grid row has the comparison ids populated, else return null
		return !!hasComparisonsForRow ? axisWithComparisons : null;
	}

	// Else if no applied comparisons just fetch the all row
	return axisWithoutComparisons;
};
