export class Document {
	constructor(
		dataItem: string,
		user: string,
		yetiToken: string,
		innerData = null,
		shared: boolean = false,
		entity: string,
		objectId: string | null
	) {
		this.dataItem = dataItem;
		this.user = user;
		this.yetiToken = yetiToken;
		this.innerData = innerData;
		this.shared = shared ? true : false;
		this.entity = entity;
		this.objectId = objectId;
	}

	/*
	Check the minimum information required for the API to work
	has been supplied
	 */
	IsValidDocument() {
		return this.dataItem !== undefined;
	}

	FormatRequestBody(collectionName: string) {
		return {
			ObjectId: this.objectId,
			DataItem: this.dataItem,
			ReportingAPIToken: this.yetiToken,
			User: this.user,
			CollectionName: collectionName,
			DocumentData: window.btoa(
				JSON.stringify({
					shared: this.shared,
					Entity: this.entity,
					...this.innerData,
				})
			),
		};
	}
}
