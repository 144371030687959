import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { StrategicAnalysisOverview } from 'features/strategicAnalysis/types/overview';
import { resetSessionOnGradepointChange, clearSessionData, setStrategicLoading } from '../slice';
/**
 * Initial State
 */
const initialState: StrategicAnalysisOverview.State = {
	examLevels: undefined,
	grids: undefined,
	rows: undefined,
	comparisons: undefined,
	error: undefined,
	loading: false,
	showThermometerToggle: true,
	thermometerData: undefined,
};
/**
 * Reducers / Actions
 */
const strategicAnalysisOverview = createSlice({
	name: 'strategicAnalysisOverview',
	initialState,
	reducers: {
		setMainGridDataSuccess(
			state: StrategicAnalysisOverview.State,
			action: PayloadAction<StrategicGridData.GridData>
		) {
			const { examLevels, grids, rows } = action.payload;
			// If this is the first fetch just set the data
			if (!state.examLevels) {
				return {
					...state,
					examLevels,
					grids,
					rows,
				};
			}
			// If this is a subsequent fetch update the data
			return {
				...state,
				examLevels: {
					...state.examLevels,
					id: examLevels.id,
					byId: {
						...state.examLevels!.byId,
						...examLevels.byId,
					},
					//@ts-ignore bloody spread error again
					allIds: [...state.examLevels.allIds, ...examLevels.allIds],
				},
				grids: {
					...state.grids,
					id: grids.id,
					byId: {
						...state.grids?.byId,
						...grids.byId,
					},
					allIds: [...state.grids!.allIds, ...grids.allIds],
				},
				rows: {
					...state.rows,
					id: rows.id,
					byId: {
						...state.rows!.byId,
						...rows.byId,
					},
					allIds: [...state.rows!.allIds, ...rows.allIds],
				},
				loading: false,
			};
		},
		setMainGridDataFail(
			state: StrategicAnalysisOverview.State,
			action: PayloadAction<{ examLevel: StrategicAnalysis.ExamLevels; errorMsg: string }>
		) {
			const { examLevel, errorMsg } = action.payload;

			return {
				...state,
				error: {
					...state.error,
					[examLevel]: errorMsg,
				},
				loading: false,
			};
		},
		setAllComparisonDataSuccess(
			state: StrategicAnalysisOverview.State,
			action: PayloadAction<StrategicGridData.Comparisons<StrategicAnalysisOverview.ComparisonRow>>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				comparisons: !state.comparisons
					? payload
					: {
							...state.comparisons,
							id: payload.id,
							byId: {
								...state.comparisons.byId,
								...payload.byId,
							},
							allIds: Array.from(new Set([...state.comparisons.allIds, ...payload.allIds])),
					  },
				rows: {
					...state.rows,
					byId: Object.entries(state.rows.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: Array.from(
									new Set(
										Object.values(payload.byId)
											.filter((val) => val.rowId === key)
											.map((comparison) => comparison.comparisonId)
									)
								),
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		setAllRowComparisonIds(
			state: StrategicAnalysisOverview.State,
			action: PayloadAction<string[]>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				rows: {
					...state.rows,
					byId: Object.entries(state.rows!.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds:
									payload.length > 0
										? payload.filter((comparisonId) => comparisonId.includes(`${val.rowId}-`))
										: [],
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		setComparisonDataFail(
			state: StrategicAnalysisOverview.State,
			action: PayloadAction<{ examLevel: StrategicAnalysis.ExamLevels; errorMsg: string }>
		) {
			const { examLevel, errorMsg } = action.payload;

			return {
				...state,
				error: {
					...state.error,
					[examLevel]: errorMsg,
				},
				loading: false,
			};
		},
		setMainThermometerData(
			state: StrategicAnalysisOverview.State,
			action: PayloadAction<
				StrategicAnalysisOverview.ThermometerById<StrategicAnalysisOverview.Thermometer>
			>
		) {
			const { id, byId, allIds } = action.payload;

			// If this is the first fetch just set the data
			if (!state.thermometerData?.mainData) {
				return {
					...state,
					thermometerData: {
						...state.thermometerData,
						mainData: action.payload,
					},
				};
			}

			// It is not the first time so lets update it
			return {
				...state,
				thermometerData: {
					...state.thermometerData,
					mainData: {
						id: id,
						byId: {
							...state.thermometerData?.mainData?.byId,
							...byId,
						},
						//@ts-ignore bloody spread error again
						allIds: [...state.thermometerData?.mainData?.allIds, ...allIds],
					},
				},
			};
		},
		setComparisonThermometerData(
			state: StrategicAnalysisOverview.State,
			action: PayloadAction<
				StrategicAnalysisOverview.ThermometerById<StrategicAnalysisOverview.Thermometer>
			>
		) {
			const { id, byId, allIds } = action.payload;

			// If this is the first fetch just set the data
			if (!state.thermometerData?.comparisonData) {
				return {
					...state,
					thermometerData: {
						...state.thermometerData,
						comparisonData: action.payload,
					},
				};
			}

			// It is not the first time so lets update it
			return {
				...state,
				thermometerData: {
					...state.thermometerData,
					comparisonData: {
						id: id,
						byId: {
							...state.thermometerData?.comparisonData?.byId,
							...byId,
						},
						//@ts-ignore bloody spread error again
						allIds: [...state.thermometerData?.comparisonData?.allIds, ...allIds],
					},
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			setStrategicLoading,
			(state: StrategicAnalysisOverview.State, action: PayloadAction<boolean>) => {
				return {
					...state,
					loading: action.payload,
				};
			}
		);
		builder.addCase(resetSessionOnGradepointChange, () => {
			return initialState;
		});
		builder.addCase(clearSessionData, () => {
			return initialState;
		});
	},
});

export const {
	setMainGridDataSuccess,
	setMainGridDataFail,
	setAllComparisonDataSuccess,
	setAllRowComparisonIds,
	setComparisonDataFail,
	setMainThermometerData,
	setComparisonThermometerData,
} = strategicAnalysisOverview.actions;

export default strategicAnalysisOverview.reducer;
