import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toggleGpSelector } from 'features/layouts/standardPage/redux/standardPage';
import {
	getAllGradepoints,
	getAppliedGradepointKeys,
	isGradepointTrendAutoPopulated,
	changePrimaryGradepointThunk,
} from 'features/app/redux/context';

import { GradepointSelectorComponent, WarningMessage, GradepointSelectorBase } from './components';
import {
	getGradepointsFilteredByAvailableExamLevels,
	hasActiveAnalysisSession,
} from 'features/strategicAnalysis/redux/';

import { Context } from 'features/app/types';

const GradepointSelector: FunctionComponent = () => {
	/**
	 ** Handle state
	 */
	// Can the user save and close the selector?
	const [userCanCloseAndSave, setUserCanCloseAndSave] = useState<boolean>(true);
	// Should we display a warning message?
	const [shouldDisplayWarningMessage, setShouldDisplayWarningMessage] = useState<boolean>(false);

	/**
	 ** Handle Dispatch
	 */
	const dispatch = useDispatch();
	// Change primary gradepoint
	const changePGP = (
		key: string,
		gradepointSelection: string[],
		isAppStartSelection: boolean,
		rerunAutoGpPopulation: boolean
	) => {
		dispatch(
			changePrimaryGradepointThunk({
				key,
				gradepointSelection,
				isAppStartSelection,
				rerunAutoGpPopulation,
			})
		);
	};
	// Change primary gradepoint
	const toggleSelector = () => dispatch(toggleGpSelector());

	/**
	 ** Handle Selectors
	 */
	// Get selected Gradepoints
	const selectedGradepoints = useSelector(getAppliedGradepointKeys);
	/**
	 * Get gradepoints based on whether the user has an active strategic analysis session running
	 *
	 * If they have an active session the gradepoints should be filtered according to the
	 * exam levels contained within the active sessions configuration
	 *
	 * If they haven't got an active session just revert to using all the available gradepoints as normal
	 */
	const useStandardGradepoints = useSelector(getAllGradepoints);
	const useStrategicAnalysisGradepoints = useSelector(getGradepointsFilteredByAvailableExamLevels);
	const hasAnalysisSession = useSelector(hasActiveAnalysisSession);
	const allGradepoints = hasAnalysisSession
		? useStrategicAnalysisGradepoints
		: useStandardGradepoints;
	// Check if the GP is auto populated
	const isGPTrendAutoPopulated = useSelector(isGradepointTrendAutoPopulated);
	// All available gradepoint keys, for use in GP selector filters
	const gradepointKeys = allGradepoints?.map((gp: any) => gp.key);
	// All Year Groups from the available gradepoints, for use in GP selector filters
	const gpYearGroups: { [gp: string]: string[] } = allGradepoints?.reduce(
		(gpYearGroups: any, curr: Context.Gradepoint) => {
			gpYearGroups[curr.key] = curr.meta!.yearGroups;
			return gpYearGroups;
		},
		{}
	);

	/**
	 ** Save gradepoint trend to local storage and redux
	 */
	const onGradepointSave = (
		selectedGPKeys: string[],
		rerunAutoGpPopulation: boolean,
		canCloseAndSave: boolean = true
	) => {
		/**
		 * If the user has not applied the gp trend correctly
		 * Stop them closing of the selector and display a message
		 */
		if (!canCloseAndSave) {
			setUserCanCloseAndSave(canCloseAndSave);

			return;
		}

		// Otherwise, clear the flag and save
		setUserCanCloseAndSave(canCloseAndSave);
		setShouldDisplayWarningMessage(false);
		// Change the primary gradepoints
		changePGP(selectedGPKeys[0], selectedGPKeys.reverse(), true, rerunAutoGpPopulation);
	};

	/**
	 ** Handle the closing of the selector
	 */
	const onSelectorClose = () => {
		// If GP trend has been populated correctly
		if (userCanCloseAndSave) {
			// Allow toggling of the selector
			toggleSelector();
		} else {
			// Other wise show a warning message
			setShouldDisplayWarningMessage(true);
		}
	};

	/**
	 ** Render Component
	 */
	return (
		<GradepointSelectorBase onSelectorClose={() => onSelectorClose()}>
			{/* Gradepoint selector component */}
			<GradepointSelectorComponent
				selectedGradepoints={selectedGradepoints as string[]}
				gps={gradepointKeys!}
				gpYearGroups={gpYearGroups}
				onGradepointSave={(selectedGPKeys, rerunAutoGpPopulation, canCloseAndSave) =>
					onGradepointSave(selectedGPKeys, rerunAutoGpPopulation, canCloseAndSave)
				}
				isGradepointTrendAutoPopulated={isGPTrendAutoPopulated}
				hasAnalysisSession={hasAnalysisSession}
				onClose={() => onSelectorClose()}
			/>
			{/* Display a warning message if the user has not selected any Gradepoints */}
			{!userCanCloseAndSave && shouldDisplayWarningMessage && <WarningMessage />}
		</GradepointSelectorBase>
	);
};

export default GradepointSelector;
