import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Navigation.State = {
	loading: false,
	accordionToOpen: [false],
	index: -1,
};

/**
 * Navigation Slice
 */

const navigationSlice = createSlice({
	name: 'navSlice',
	initialState,
	reducers: {
		setAccordionLength(state: Navigation.State, action: PayloadAction<number>) {
			state.loading = false;
			state.accordionToOpen = new Array(action.payload).fill(false);
		},
		updateAccordionForceOpen(state: Navigation.State, action: PayloadAction<any>) {
			state.loading = false;

			if (action.payload?.isNav) {
				state.accordionToOpen[action.payload.id] = true;
			} else if (action.payload?.isOpen) {
				// did we pass in a state to force it too, if so set it to that
				state.accordionToOpen[action.payload.id] = action.payload.isOpen;
			} else {
				state.accordionToOpen[action.payload.id] = !state.accordionToOpen[action.payload.id];
			}
		},
		clearAccordionForceOpen(state: Navigation.State) {
			state.loading = false;
			state.accordionToOpen = [false];
		},
		setIndex(state: Navigation.State, action: PayloadAction<number>) {
			// TODO - review this, nothing seems to actually update this
			state.loading = false;
			state.index = action.payload;
		},
	},
});

/**
 * Export Reducers
 */

export default navigationSlice.reducer;

/**
 * Export Actions
 */

export const {
	setAccordionLength,
	updateAccordionForceOpen,
	clearAccordionForceOpen,
	setIndex,
} = navigationSlice.actions;

/**
 * Export Selectors
 */

//Get with the accordion should open
export const shouldTheAccordionOpen = (state: RootState): Array<boolean> =>
	state.navigation.accordionToOpen;

//Get the index Pos
export const getTheIndexPosition = (state: RootState): number => state.navigation.index;
