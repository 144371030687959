import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from '../../types/index';

/**
 * Initial State
 */
const initialState: Groups.Setup.State = {
	loading: false,
	error: null,
	customComparisons: [],
	fieldComparisons: [],
	schoolGroups: [],
	schools: [],
	monitoringPoints: [],
	groupAccessId: undefined,
	groupAccessRecords: [],
	currentKeyStageContext: null,
	trends: [],
	customColumns: [],
	availableYearGroups: [7, 8, 9, 10, 11, 12, 13],
	schoolContracts: [],
	previousYearData: undefined,
	currentData: undefined,
	rolloverSuccess: undefined,
	message: undefined,
};

/**
 * Group Analysis Slice
 */
const groupSetupSlice = createSlice({
	name: 'groupSetup',
	initialState,
	reducers: {
		setSetupLoading(state: Groups.Setup.State) {
			return {
				...state,
				loading: true,
				error: null,
			};
		},
		setSetupErrorMessage(state: Groups.Setup.State, action: PayloadAction<string>) {
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null,
			};
		},
		clearError(state: Groups.Setup.State) {
			return {
				...state,
				error: null,
			};
		},
		setSetupSchools(state: Groups.Setup.State, action: PayloadAction<Groups.Setup.School[]>) {
			const payload: Groups.Setup.School[] = action.payload;
			return {
				...state,
				schools: payload,
				loading: false,
				error: null,
			};
		},
		setSetupSchoolGroups(
			state: Groups.Setup.State,
			action: PayloadAction<Groups.Setup.SchoolGroup[]>
		) {
			const payload: Groups.Setup.SchoolGroup[] = action.payload;
			return {
				...state,
				schoolGroups: payload,
				loading: false,
				error: null,
			};
		},
		setSetupTrends(state: Groups.Setup.State, action: PayloadAction<Groups.Setup.Trend[]>) {
			const payload: Groups.Setup.Trend[] = action.payload;
			// No need to try select anything if we do not have trends setup
			if (!payload || payload.length === 0) {
				return { ...state, loading: false, trends: [] };
			}
			// Sort the trends ascending
			const sortedTrends = [...payload].sort((a, b) => a.id - b.id);

			return {
				...state,
				loading: false,
				trends: sortedTrends,
				error: null,
			};
		},
		createTrend(state: Groups.Setup.State, action: PayloadAction<Groups.Setup.Trend>) {
			const trend = action.payload;
			// Check if trend exists
			const exists = state.trends.find(({ id }) => id === trend.id);
			// Get all exiting trends
			const existingTrends = state.trends.filter(({ id }) => id !== trend.id);
			// Trend to be updated
			const updatedTrend = {
				...trend,
				isNew: exists ? exists.isNew || false : true,
				isEdited: true,
			};

			return {
				...state,
				trends: [...existingTrends, updatedTrend],
			};
		},
		removeTrend(state: Groups.Setup.State, action: PayloadAction<number>) {
			const id = action.payload;

			return {
				...state,
				trends: state.trends.map((trend) => {
					if (trend.id === id) {
						return {
							...trend,
							isDeleted: true,
						};
					}

					return trend;
				}),
			};
		},
		setGroupAccessId(state: Groups.Setup.State, action: PayloadAction<number | undefined>) {
			return {
				...state,
				rolloverSuccess: undefined, //reset this when we switch years
				groupAccessId: action.payload,
			};
		},
		setGroupAccessRecords(
			state: Groups.Setup.State,
			action: PayloadAction<Groups.Setup.GroupAccess[]>
		) {
			return {
				...state,
				loading: false,
				groupAccessRecords: action.payload,
			};
		},
		setSchoolContractsSuccess(state: Groups.Setup.State, action: PayloadAction<any>) {
			state.loading = false;
			state.schoolContracts = action.payload;
		},
		setSchoolContractsFail(state: Groups.Setup.State, action: PayloadAction<string>) {
			state.loading = false;
			state.message = action.payload;
		},
		setEffectiveAcademicYear(state: Groups.Setup.State, action: PayloadAction<any>) {
			state.loading = false;
			const payload = action.payload;

			const index = state.schoolContracts?.findIndex((year) => year.id === payload.id) ?? -1;

			if (index >= 0 && state.schoolContracts) {
				let yearArr = [...state.schoolContracts];
				let updateYear = {
					...yearArr[index],
					effectiveAcademicYear: payload.effectiveAcademicYear,
				};
				yearArr[index] = updateYear;
				state.schoolContracts = yearArr;
			}
		},
		unsetSchoolContracts(state: Groups.Setup.State, action: PayloadAction<[]>) {
			state.loading = false;
			state.schoolContracts = action.payload;
		},
		updateGroupSchoolContract(state: Groups.Setup.State, action: PayloadAction<any>) {
			state.loading = false;
			state.schoolContracts = action.payload;
		},
		setSummaryData(
			state: Groups.Setup.State,
			action: PayloadAction<{
				previous: Groups.Setup.GroupAccessSummary;
				current: Groups.Setup.GroupAccessSummary;
			}>
		) {
			return {
				...state,
				loading: false,
				previousYearData: action.payload.previous,
				currentData: action.payload.current,
			};
		},
		setRolloverSuccess(state: Groups.Setup.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: false,
				rolloverSuccess: action.payload,
			};
		},
		setMonitoringTimeline(
			state: Groups.Setup.State,
			action: PayloadAction<Array<Groups.Setup.MonitoringPoint>>
		) {
			return {
				...state,
				monitoringPoints: action.payload,
				loading: false,
				error: null,
			};
		},
		addMonitoringTimeline(
			state: Groups.Setup.State,
			action: PayloadAction<Groups.Setup.MonitoringPoint>
		) {
			const monitoringPoint: Groups.Setup.MonitoringPoint = action.payload;
			const otherMonitoringPoints = state.monitoringPoints.filter(
				(x: Groups.Setup.MonitoringPoint) => x.id !== monitoringPoint.id
			);

			return {
				...state,
				monitoringPoints: [...otherMonitoringPoints, monitoringPoint],
			};
		},
		deleteMonitoringTimeline(
			state: Groups.Setup.State,
			action: PayloadAction<Groups.Setup.MonitoringPoint>
		) {
			const monitoringPoint: Groups.Setup.MonitoringPoint = action.payload;
			const otherMonitoringPoints = state.monitoringPoints.filter(
				(x: Groups.Setup.MonitoringPoint) => x.id !== monitoringPoint.id
			);

			return {
				...state,
				monitoringPoints: [...otherMonitoringPoints, monitoringPoint],
			};
		},
		setCustomColumns(
			state: Groups.Setup.State,
			action: PayloadAction<Array<Groups.Setup.CustomColumn>>
		) {
			return {
				...state,
				customColumns: action.payload,
				loading: false,
				error: null,
			};
		},
		addCustomColumn(state: Groups.Setup.State, action: PayloadAction<Groups.Setup.CustomColumn>) {
			const customColumn: Groups.Setup.CustomColumn = action.payload;
			const otherCustomColumns = state.customColumns.filter(
				(x: Groups.Setup.MonitoringPoint) => x.id !== customColumn.id
			);

			return {
				...state,
				customColumns: [...otherCustomColumns, customColumn],
			};
		},
		deleteCustomColumn(
			state: Groups.Setup.State,
			action: PayloadAction<Groups.Setup.CustomColumn>
		) {
			const customColumn: Groups.Setup.CustomColumn = action.payload;
			const otherCustomColumns = state.customColumns.filter(
				(x: Groups.Setup.MonitoringPoint) => x.id !== customColumn.id
			);

			return {
				...state,
				customColumns: [...otherCustomColumns, customColumn],
			};
		},
		setSchoolGroups(
			state: Groups.Setup.State,
			action: PayloadAction<Array<Groups.Setup.SchoolGroup>>
		) {
			return {
				...state,
				schoolGroups: action.payload,
				loading: false,
				error: null,
			};
		},
		addSchoolGroup(state: Groups.Setup.State, action: PayloadAction<Groups.Setup.SchoolGroup>) {
			const schoolGroup: Groups.Setup.SchoolGroup = action.payload;
			const otherSchoolGroups = state.schoolGroups.filter(
				(x: Groups.Setup.SchoolGroup) => x.id !== schoolGroup.id
			);

			return {
				...state,
				schoolGroups: [...otherSchoolGroups, schoolGroup],
			};
		},
		deleteSchoolGroup(state: Groups.Setup.State, action: PayloadAction<Groups.Setup.SchoolGroup>) {
			const schoolGroup: Groups.Setup.SchoolGroup = action.payload;
			const otherSchoolGroups = state.schoolGroups.filter(
				(x: Groups.Setup.SchoolGroup) => x.id !== schoolGroup.id
			);

			return {
				...state,
				schoolGroups: [...otherSchoolGroups, schoolGroup],
			};
		},
		setCustomComparisons(
			state: Groups.Setup.State,
			action: PayloadAction<Array<Groups.Setup.ComparisonGroup>>
		) {
			return {
				...state,
				customComparisons: action.payload,
				loading: false,
				error: null,
			};
		},
		addCustomComparison(
			state: Groups.Setup.State,
			action: PayloadAction<Groups.Setup.ComparisonGroup>
		) {
			const customComparison: Groups.Setup.ComparisonGroup = action.payload;
			const otherCustomComparions = state.customComparisons.filter(
				(x: Groups.Setup.ComparisonGroup) => x.id !== customComparison.id
			);

			return {
				...state,
				customComparisons: [...otherCustomComparions, customComparison],
			};
		},
		deleteCustomComparison(
			state: Groups.Setup.State,
			action: PayloadAction<Groups.Setup.ComparisonGroup>
		) {
			const customComparison: Groups.Setup.ComparisonGroup = action.payload;
			const otherCustomComparions = state.customComparisons.filter(
				(x: Groups.Setup.ComparisonGroup) => x.id !== customComparison.id
			);

			return {
				...state,
				customComparisons: [...otherCustomComparions, customComparison],
			};
		},
		setFieldComparisons(
			state: Groups.Setup.State,
			action: PayloadAction<Array<Groups.Setup.FieldComparison>>
		) {
			return {
				...state,
				fieldComparisons: action.payload,
			};
		},
		setCurrentKeyStage(
			state: Groups.Setup.State,
			action: PayloadAction<{ label: string; value: number } | undefined>
		) {
			return {
				...state,
				currentKeyStageContext: action.payload,
			};
		},
	},
});

/**
 ** Export Reducers
 */
export default groupSetupSlice.reducer;

/**
 ** Export Actions
 */
export const {
	setSetupLoading,
	setSetupErrorMessage,
	setSetupSchools,
	setSetupSchoolGroups,
	setSetupTrends,
	setGroupAccessId,
	setGroupAccessRecords,
	setSchoolContractsSuccess,
	setSchoolContractsFail,
	setEffectiveAcademicYear,
	unsetSchoolContracts,
	updateGroupSchoolContract,
	setSummaryData,
	setRolloverSuccess,
	createTrend,
	removeTrend,
	clearError,
	setMonitoringTimeline,
	addMonitoringTimeline,
	deleteMonitoringTimeline,
	setCustomColumns,
	addCustomColumn,
	deleteCustomColumn,
	setSchoolGroups,
	addSchoolGroup,
	deleteSchoolGroup,
	addCustomComparison,
	setCustomComparisons,
	deleteCustomComparison,
	setFieldComparisons,
	setCurrentKeyStage,
} = groupSetupSlice.actions;
