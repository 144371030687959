import moment from 'moment';

/**
 * Mimics exam level enum bit flags in backend
 * @type {Object}
 */
const examLevelEnum = {
	A: 1,
	AS: 2,
	BTEC: 4,
	BTECCert: 8,
	BTEC90Cred: 16,
	KS4: 32,
	PreU: 64,
	IB: 128,
};

/**
 * Structure containing end of year exam levels - to switch to true/false
 * depending on whether bit values are in end of year values returned from db
 * @type {Object}
 */
const endOfYearLevelsObj = {
	A: null,
	AS: null,
	BTEC: null,
	BTECCert: null,
	BTEC90Cred: null,
	KS4: null,
	PreU: null,
	IB: null,
};

/**
 *
 * @param	{int}	 	endOfYear			end of year bit flags from db
 * @param	{Object}	tempEndOfYearObj	empty struct to store bool values for each exam level
 * @return	{Object}						structure containing bool values for each exam level
 */
const checkForExamFlag = (endOfYear, tempEndOfYearObj) => {
	Object.keys(tempEndOfYearObj).forEach((key) => {
		if (endOfYear & examLevelEnum[key]) {
			tempEndOfYearObj[key] = true;
		} else {
			tempEndOfYearObj[key] = false;
		}
	});
	return tempEndOfYearObj;
};

/**
 * Converts bit flag value into true/false values for each exam level
 * @param	{Array}	entitlements	all entitlements from server
 * @return	{Array}					entitlements with new EndOfYearLevels as object
 */
export const convertBitFlagToObj = (entitlements: any) => {
	let tempEndOfYearObj;

	if (Array.isArray(entitlements)) {
		return entitlements.map((e) => {
			tempEndOfYearObj = Object.assign({}, endOfYearLevelsObj);
			e.LevelSelections = checkForExamFlag(e.EndOfYear, tempEndOfYearObj);
			return e;
		});
	} else {
		tempEndOfYearObj = Object.assign({}, endOfYearLevelsObj);
		entitlements.LevelSelections = checkForExamFlag(entitlements.EndOfYear, tempEndOfYearObj);
		return entitlements;
	}
};

export const checkStatus = (start: string, end: string, now: string): number => {
	const nowMoment = moment(now).format('yyyy-MM-DD');
	const startMoment = moment(start).format('yyyy-MM-DD');
	const endMoment = moment(end).format('yyyy-MM-DD');

	if (endMoment < nowMoment) {
		return 1;
	} else if (startMoment <= now && endMoment >= nowMoment) {
		return 2;
	} else {
		return 3;
	}
};

export const checkForCurrentEntitlement = (entitlements: Array<Context.Entitlement>) => {
	return entitlements.some((entitlement: Context.Entitlement) => {
		const now = moment().toString();
		return checkStatus(entitlement.Start, entitlement.End, now) === 2;
	});
};
