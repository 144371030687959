import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { put, select, retry, cancel, call } from 'redux-saga/effects';
import { setLoading, setError } from '../qualityIndicator/slice';
import { Context } from 'features/app/types';
import { getSelectedPageComparisons, getMainThermometerDataByExamLevel } from '../';
import { getBenchmarkSetName } from 'features/app/redux/context';
import { fetchQualityIndicatorThermometerData } from '../../../../api/strategicAnalysisAPI';
import { getAppliedGradepoints } from '../';
import { AnalysisPages } from 'features/strategicAnalysis/enums';
import { GetKeyValueFromComparisonOption } from 'features/strategicAnalysis/utils';
import { ExamLevels } from '../../../../types/enum';
import { setStrategicLoading } from '../slice';

/**
 * A function that works out what thermometer data needs fetching and sets the active thermometer values
 * @param examLevel The selected exam level
 */
export function* fetchMainThermometerData(
	examLevel: StrategicAnalysis.ExamLevels,
	page: StrategicAnalysis.AnalysisPage
) {
	if (page === AnalysisPages.T_SCORE && examLevel === ExamLevels.KS4) {
		yield put(setStrategicLoading(false));
		yield cancel();
	}

	try {
		// 1. Attempt to get the main thermometer data
		const mainThermometerData: StrategicAnalysisQualityIndicator.Thermometer | null = yield select(
			getMainThermometerDataByExamLevel(page, examLevel)
		);

		// 1a. Bail if the main thermometer data already exists in Redux for this exam level
		if (mainThermometerData != null) {
			return;
		}

		// 2. set loading to true as we know we need to do stuff
		yield put(setLoading(true));

		const callQualityIndicatorEndpoint =
			page === AnalysisPages.OVERVIEW ? AnalysisPages.QUALITY_INDICATOR : page;
		// 3. fetch the API data
		const gradepoints: Context.Gradepoint[] = yield select(getAppliedGradepoints);
		const benchmarkSet: string = yield select(getBenchmarkSetName);

		const response: ResponseBuilder<StrategicAnalysisQualityIndicator.ThermometerDTO> = yield retry(
			3, // Try calling 3 times
			5 * 1000, // with a delay of 5 seconds
			fetchQualityIndicatorThermometerData, // API to call
			callQualityIndicatorEndpoint,
			examLevel,
			benchmarkSet,
			gradepoints
		);

		// Check the response builder to see if any errors occurred
		if (response.hasError) throw response.Errors[0];

		yield put({
			type: `strategicAnalysis${page}/setMainThermometerData`,
			payload: response.responseObject.mainData,
		});
	} catch (exception) {
		// Set the error message
		yield put(setError(exception as string));
	} finally {
		// 4. set loading to false
		yield put(setLoading(false));
	}
}

/**
 * A function that works out what thermometer data needs fetching and sets the active thermometer values
 * @param examLevel The selected exam level
 */
export function* fetchComparisonThermometerData(
	strategicAnalysisPage: StrategicAnalysis.AnalysisPage,
	examLevel: StrategicAnalysis.ExamLevels
) {
	if (strategicAnalysisPage === AnalysisPages.T_SCORE && examLevel === ExamLevels.KS4) {
		yield put(setStrategicLoading(false));
		yield cancel();
	}
	try {
		// 2. Check if a comparison is even applied
		const selectedComparisons: StrategicAnalysis.ComparisonOption = yield select(
			getSelectedPageComparisons(strategicAnalysisPage)
		);

		// 2b. Bail here if no comparisons are selected
		if (!selectedComparisons?.length) yield cancel();

		// 3. fetch the API data
		const gradepoints: Context.Gradepoint[] = yield select(getAppliedGradepoints);
		const benchmarkSet: string = yield select(getBenchmarkSetName);

		const formattedComparisons = GetKeyValueFromComparisonOption(selectedComparisons);
		const callQualityIndicatorEndpoint =
			strategicAnalysisPage === AnalysisPages.OVERVIEW
				? AnalysisPages.QUALITY_INDICATOR
				: strategicAnalysisPage;
		const response: ResponseBuilder<StrategicAnalysisQualityIndicator.ThermometerDTO> = yield retry(
			3, // Try calling 3 times
			5 * 1000, // with a delay of 5 seconds
			fetchQualityIndicatorThermometerData, // API to call
			callQualityIndicatorEndpoint,
			examLevel,
			benchmarkSet,
			gradepoints,
			formattedComparisons?.length ? formattedComparisons : undefined
		);

		// Check the response builder to see if any errors occurred
		if (response.hasError) throw response.Errors[0];

		yield put({
			type: `strategicAnalysis${strategicAnalysisPage}/setComparisonThermometerData`,
			payload: response.responseObject.comparisonData,
		});
	} catch (exception) {
		// Set the error message
		yield put(setError(exception as string));
	}
}

/**
 * A function to determine if new thermometer data needs to be fetched from the API or if we can use Redux data
 * @param strategicAnalysisPage Current analysis page
 * @param examLevel The selected exam level
 */
export function* handleThermometerChange(
	strategicAnalysisPage: StrategicAnalysis.AnalysisPage,
	examLevel: StrategicAnalysis.ExamLevels
) {
	const thermometerPages = [
		AnalysisPages.OVERVIEW,
		AnalysisPages.QUALITY_INDICATOR,
		AnalysisPages.QUALITY_OF_TEACHING,
		AnalysisPages.T_SCORE,
	];

	// We only want to continue if the page is overview, quality indicator or quality of teaching
	// @ts-ignore
	if (!thermometerPages.includes(strategicAnalysisPage)) yield cancel();

	// Lets give first refusal to the main thermometer data
	yield call(fetchMainThermometerData, examLevel, strategicAnalysisPage);

	//Bail if we are on the Quality of teaching page
	if (strategicAnalysisPage === AnalysisPages.QUALITY_OF_TEACHING) yield cancel();

	// Now we have the main thermometer guaranteed, lets get the comparison ones if applicable
	yield call(fetchComparisonThermometerData, strategicAnalysisPage, examLevel);
}

