import { sortedComparisons } from '../../../../utils/filters';
import { getExamLevelKeyFromFlag } from '../../../../utils/examLevel';
import { getUseClientBenchmark } from 'features/app/redux/context';
import { getFeature } from 'features/app/redux/features';

/**
 * Get loading status
 * @param state
 */
export const getAnalysisLoading = (state: RootState) => {
	return state.groupAnalysis.loading;
};

/**
 * Get error message
 * @param state
 */
export const getAnalysisError = (state: RootState) => {
	return state.groupAnalysis.errorMessage;
};

/**
 * Get selected trend Id
 * @param state
 */
export const getGroupSelectedTrendId = (state: RootState) => {
	return state.groupAnalysis.selectedTrendId;
};

/**
 * Get All Group Trends
 * @param state
 */
export const getAllGroupTrends = (state: RootState): Groups.Analysis.Trends[] => {
	return state.groupAnalysis.trends;
};

/**
 * Get the selected trend
 * @param state
 */
export const getSelectedTrend = (state: RootState): Groups.Anaysis.Trends => {
	const selectedTrendId = getGroupSelectedTrendId(state);

	return state.groupAnalysis.trends?.find((x: Groups.Anaysis.Trends) => x.id === selectedTrendId);
};

/**
 * Get version Id. If client BM is toggled on, it will use the client BM version ID
 * @param state
 */
export const getVersionId = (state: RootState) => {
	return state.groupAnalysis.versionId;
};

/**
 * Get Custom Comparisons
 * @param state
 */
export const getCustomComparisons = (state: RootState): Groups.Analysis.Comparisons => {
	return Object.values(state.groupAnalysis.comparisons);
};

/**
 * Get Custom Comparison options
 * @param state
 */
export const getCustomComparisonOptions = (state: RootState) => {
	const comparisons: Groups.Analysis.Comparison = getCustomComparisons(state);

	return Object.values(comparisons)
		.filter((comparison) => comparison.isCustom)
		.map((comparison) => {
			return {
				label: comparison.name,
				value: comparison.id.toString(),
				parent: comparison.id,
				isCustom: comparison.isCustom,
			};
		});
};

/**
 * Get Filter Values
 * @param state
 */
export const getFilterValues = (state: RootState): Groups.Analysis.FilterValue[] => {
	return state.groupAnalysis.filterValues;
};

/**
 * Get Alps Comparison Options
 * @param state
 */
export const getAlpsComparisonOptions = (state: RootState): Groups.Analysis.Comparisons => {
	const comparisons: Groups.Analysis.Comparison = getCustomComparisons(state);

	return Object.values(comparisons)
		.filter((comparison) => !comparison.isCustom)
		.map((comparison) => {
			return {
				label: comparison.name,
				value: comparison.id,
				isCustom: comparison.isCustom,
			};
		});
};

/**
 * Get all alps comparisons and corresponding values in a label and value structure for react select
 * @param state
 */
export const getAlpsComparisonOptionsAndValues = (state: RootState) => {
	const comparisons: Groups.Analysis.Comparisons = getAlpsComparisonOptions(state);
	const filterValues: Groups.Analysis.FilterValue[] = getFilterValues(state);

	return comparisons
		.map((x: Groups.Analysis.Comparison) => {
			return {
				label: x.label,
				value: x.value,
				order: sortedComparisons(x.value),
				options: filterValues
					.filter((f) => f.value.filterType === x.value)
					.map((y) => {
						return {
							value: y.value.value,
							label: y.value.value,
							parent: x.value,
							isCustom: false,
						};
					})
					.concat({
						value: 'all',
						label: `All - ${x.label}`,
						parent: x.value,
						isCustom: false,
					})
					.sort((a, b) => {
						if (a.value !== 'all' && b.value === 'all') return 1;

						if (a.value === 'all' && b.value !== 'all') return -1;

						return 0;
					}),
			};
		})
		.sort((a: Groups.Analysis.Comparison, b: Groups.Analysis.Comparison) => {
			return a.order - b.order;
		});
};

/**
 * Get All Qualifications
 * @param state
 */
export const getAllQualifications = (state: RootState): Groups.Analysis.FilterValue[] => {
	return state.groupAnalysis.qualifications;
};

/**
 * Get Qualification Options
 * @param state
 */
export const getQualificationOptions = (state: RootState) => {
	const qualifications = getAllQualifications(state);

	return Object.values(qualifications).map((qualifications) => {
		return {
			label: qualifications.name,
			value: qualifications.id,
		};
	});
};

/**
 * Get selected qualification
 * @param state
 */
export const getSelectedQualification = (state: RootState) => {
	return state.groupAnalysis.selectedQualification;
};

/**
 * Get All Schools
 * @param state
 */
export const getAllSchools = (state: RootState): Groups.Analysis.Schools => {
	return state.groupAnalysis.schools;
};

/**
 * Get Schools Options
 * @param state
 */
export const getSchoolOptions = (state: RootState) => {
	const schools: Groups.Analysis.Schools = getAllSchools(state);

	return Object.values(schools)
		.sort((a: Groups.Analysis.School, b: Groups.Analysis.School) => a.name.localeCompare(b.name))
		.map((school) => {
			return {
				label: school.name,
				value: school.id,
			};
		});
};

/**
 * Get All Groups
 * @param state
 */
export const getAllGroups = (state: RootState): Groups.Analysis.Groups => {
	return state.groupAnalysis.groups;
};

/**
 * Get Group Options
 * @param state
 */
export const getGroupOptions = (state: RootState) => {
	const groups: Groups.Analysis.Groups = getAllGroups(state);

	return Object.values(groups).map((group) => {
		return {
			label: group.name,
			value: group.id,
		};
	});
};

/**
 * Get all the subjects available to a individual summit school
 * @param schoolIds
 * @returns a list of subjects available to the school
 */
export const getAvailableSubjects = (schoolIds: Array<number>) => (state: RootState): number[] => {
	if (schoolIds && schoolIds.length > 0) {
		const schools: Groups.Analysis.School[] = Object.values(state.groupAnalysis.schools);
		const allSubjects: number[] = schools.reduce((list: number[], school) => {
			if (schoolIds.includes(school.id)) {
				const subjectIds = Object.keys(school.subjects).map((id) => Number(id));

				return [...list, ...subjectIds];
			}

			return list;
		}, []);

		return Array.from(new Set(allSubjects));
	}

	return Object.values(state.groupAnalysis.subjects).map((x) => x.id);
};

/**
 * Get All Subjects
 * @param state
 */
export const getAllSubjects = (state: RootState): Groups.Analysis.Subjects => {
	return state.groupAnalysis.subjects;
};

/**
 * Get selected subject
 * Used to display the subject name in the primary nav
 * @param state
 */
export const getSelectedSubject = (state: RootState): string => {
	return state.groupAnalysis.selectedSubject;
};

/**
 * Get Exam Levels
 * @param state
 */
export const getExamLevels = (state: RootState) => {
	const subjects: Groups.Analysis.Subjects = getAllSubjects(state);

	return Array.from(
		new Set(Object.values(subjects).map((subject) => subject.examLevel)).values()
	).sort();
};

/**
 * Get Exam Levels
 * @param state
 */
export const getExamLevelOptions = (state: RootState): Groups.Analysis.Subjects => {
	const examLevels: Groups.Analysis.Subjects = getExamLevels(state);

	return examLevels.map((examLevel) => {
		return {
			label: getExamLevelKeyFromFlag(examLevel),
			value: examLevel,
		};
	});
};

/**
 * Get Gradepoints
 * @param state
 */
export const getGradepoints = (state: RootState): Groups.Analysis.Gradepoint[] => {
	return Object.values(state.groupAnalysis.gradepoints);
};

/**
 * Get All Bands
 * @param state
 */
export const getAllBands = (state: RootState): Groups.Analysis.Bands => {
	return state.groupAnalysis.bands;
};

/**
 * Get All Indicators
 * @param state
 */
export const getAllIndicators = (state: RootState): Groups.Analysis.Indicators => {
	return state.groupAnalysis.indicators;
};

/**
 * Returns a list of previously selected filters
 * @param state
 */
export const getSelectedFilterOptions = (
	state: RootState
): Groups.Analysis.SelectedFilterOptions => {
	return state.groupAnalysis.selectedFilterOptions;
};

/**
 * Returns the defualt keystage value
 */
export const getDefualtKeyStage = (state: RootState): Groups.Analysis.SelectOption => {
	return state.groupAnalysis.trendSelector?.selectedKeyStage;
};
/**
 * Returns the default keystage options
 */
export const getDefualtKeyStageOptions = (
	state: RootState
): Array<Groups.Analysis.SelectOption> => {
	return state.groupAnalysis.trendSelector?.availableKeyStages;
};

/**
 * Returns the default academic year
 */
export const getDefualtAcademicYear = (state: RootState): Groups.Analysis.SelectOption => {
	return state.groupAnalysis.trendSelector?.selectedYear;
};

/**
 * Returns the list of academic years available
 */
export const getDefualtAcademicYearOptions = (
	state: RootState
): Array<Groups.Analysis.SelectOption> => {
	return state.groupAnalysis.trendSelector?.availableYears;
};
