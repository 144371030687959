import * as React from 'react';
import { ChangeEvent, FunctionComponent, useLayoutEffect, useState, useEffect } from 'react';
import { hasHistoryChanged } from 'features/subjectPage/redux/selectors';
import { useSelector } from 'react-redux';
import { HTMLSelect, Flex, IconButton, Tooltip, Icon } from 'basecamp';
import { translate } from '../../utils/locale';
import { isNullOrUndefined } from '../../utils/common';
import { getWhatIfBgColour } from '../utils/index';

type Props = {
	options: SubjectPage.Option[];
	grade: string;
	value: string;
	onChange: (grade: string) => void;
	previousGrade: string;
};

const getInitialIndex = (props: Props): number =>
	props.options.findIndex(({ value }) => value === (props.value || props.grade));

const WhatIfRenderer: FunctionComponent<Props> = (props) => {
	/**
	 ** Handle State
	 */
	const [index, setIndex] = useState<number>(getInitialIndex(props));
	// If our grade has no value, it is invalid
	const [isInvalidWhatIfGrade, setIsInvalidWhatIfGrade] = useState<boolean>(
		isNullOrUndefined(props.options[getInitialIndex(props)]?.value)
	);
	const [whatIfValue, setWhatIfValue] = useState<string>(
		props.options[getInitialIndex(props)]?.value as string
	);
	const [options, setOptions] = useState<SubjectPage.Option[]>(props.options);

	const changed = useSelector(hasHistoryChanged);

	// grade up sets the index value, because that's easy to keep track of
	const gradeUp = () => {
		index > 0 && setWhatIfValue(options[index - 1].value as string);
	};

	// grade down also sets the index value, because that's easy to keep track of
	const gradeDown = () => {
		index < options.length - 1 && setWhatIfValue(options[index + 1].value as string);
	};

	// what the text value changes, sync the index (if the drop down was used) and fire the onChange
	useLayoutEffect(() => {
		setIndex(options.findIndex(({ value }) => value === whatIfValue));
		whatIfValue !== props.value && props.onChange(whatIfValue);

		// If the what if value is undefined, then it is invalid
		setIsInvalidWhatIfGrade(isNullOrUndefined(whatIfValue));
	}, [whatIfValue]);

	/*
	 * If the validity of the what if grade changes then we need to modify the options in the drop down
	 * Ideally this should only ever move from invalid to valid and in doing so can use the original options in the props
	 */
	useEffect(() => {
		// If the grade is invalid, we need to add that option to the dropdown so it can be seen by the user. Only ever do this if the options are the prop values for options
		if (isInvalidWhatIfGrade && props.options.length === options.length) {
			const invalidGrade = { label: props.grade, value: undefined };
			// The invalid grade must go first as the option will will default to the first index
			const newOptions = [invalidGrade, ...props.options];
			setOptions(newOptions);
		} else {
			setOptions(props.options);
		}
	}, [isInvalidWhatIfGrade]);

	useEffect(() => {
		setWhatIfValue(props.previousGrade);
	}, [changed, []]);

	const getBgColour = getWhatIfBgColour(whatIfValue === props.grade, isInvalidWhatIfGrade);

	/**
	 ** Renderer
	 */
	return (
		<Flex setAs="row" withAlign="center" height="100%" bg={getBgColour}>
			{/* Down control */}
			<IconButton
				withIcon="arrow-down"
				withSize="small"
				setAs={index < options.length - 1 ? 'accent' : 'disabled'}
				mr={2}
				onClick={gradeDown}
			/>
			{/* Select */}
			<HTMLSelect
				options={options}
				value={whatIfValue}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => setWhatIfValue(e.target.value)}
			/>
			{/* Up Control */}
			<IconButton
				withIcon="arrow-up"
				withSize="small"
				setAs={index > 0 ? 'accent' : 'disabled'}
				ml={2}
				onClick={gradeUp}
			/>
			{/* Adds a warning indicator if the grade is invalid */}
			<Flex pl={2}>
				{isInvalidWhatIfGrade && (
					<Tooltip
						withMessage={translate('subjectPage.outcomes.whatIf.GRADE_WARNING')}
						withSize="medium"
						withAlign="left"
					>
						<Icon icon="exclamation" color="status.danger.0" />
					</Tooltip>
				)}
			</Flex>
		</Flex>
	);
};

export default WhatIfRenderer;
