import { ExamLevels } from '../types/enum';
import { SelectOption } from './groups';
export const calculateSummitKeyStage = (keyStage: number): string => {
	const key = ExamLevels[keyStage];
	switch (key) {
		case 'ks5' ||
			'A' ||
			'AS' ||
			'BTEC' ||
			'BTEC90Cred' ||
			'BTECCert' ||
			'IB' ||
			'Ks5Extended' ||
			'Ks5Voc' ||
			'PreU':
			return 'KS5';
		case 'KS4':
			return 'KS4';
		default:
			//Probably not needed but leaving in for belt and braces
			return 'KS5';
	}
};

// Filter the qual type options in the Summit analysis filterBar - forces correct keystage appropate options
export const filterQualTypeOptions = (keyStage: string, options: SelectOption[]) => {
	if (keyStage === 'KS5') {
		return options.filter((x) => x.label !== 'KS4');
	}
	if (keyStage === 'KS4') {
		return options.filter((x) => x.label === 'KS4');
	}

	return options;
};
