import { translate } from '../../../../utils/locale';
import { updateLoading, updateErrorMessage, updateOrganisationDetails } from '../slice';

import { getOrganisationDetailsAPI } from '../../../../api/subscriptionAPI';

/**
 * Get the organisation details from the API and store them in Redux
 */
export const getOrganisationDetailsThunk = (): AppThunk => async (dispatch) => {
	await dispatch(updateLoading(true));

	const organisationDetails = await getOrganisationDetailsAPI();

	if (organisationDetails.HasError) {
		dispatch(
			updateErrorMessage(
				translate('manageAccount.subscriptions.errors.FAILED_TO_FETCH_DATA') as string
			)
		);
	} else {
		dispatch(updateOrganisationDetails(organisationDetails.ResponseObject));
	}

	await dispatch(updateLoading(false));
};
