import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getClient } from 'features/app/redux/context';

import { IconButton, Text, Flex } from 'basecamp';

// TODO - import from connect utils in prod
import * as impersonationUtils from '../../../../utils/impersonation';

type Props = {
	toggleMenu: () => void;
};

const UserProfileMenuBtn: FunctionComponent<Props> = (props) => {
	/**
	 ** Handle Selectors
	 */
	// Get user information
	const user = useSelector(getUser);
	// Get client information
	const client = useSelector(getClient);
	// Get client impersonation type
	const isImpostor = impersonationUtils.isImpostor(user.impersonationMode);

	/**
	 ** Render Component
	 */
	return (
		<Flex withAlign="center" ml={3}>
			<Text mb={0} mt={0} lineHeight={0} withColor="black" dataTest="userProfileClientName">
				{client?.name}
			</Text>
			<IconButton
				withIcon={isImpostor ? 'user-secret' : 'user'}
				setAs={isImpostor ? 'warning' : 'accent'}
				withSize="medium"
				ml={2}
				onClick={() => props.toggleMenu()}
				dataTest="userProfileBtn"
			/>
		</Flex>
	);
};

export default UserProfileMenuBtn;
