import * as React from 'react';
import { FunctionComponent } from 'react';

import { VictoryChart, VictoryLine, VictoryGroup, VictoryAxis } from 'victory';
import dataVisTheme from '../../resources/dataVisTheme';

type Props = {
	range: number[];
	invert?: boolean;
};

const TrendlineRenderer: FunctionComponent<Props> = ({ range, invert }) => {
	return (
		<VictoryChart width={160} height={40} padding={0} theme={dataVisTheme}>
			<VictoryAxis invertAxis={invert} dependentAxis style={{ axis: { stroke: 'none' } }} />
			<VictoryGroup padding={0}>
				<VictoryLine data={range} />
			</VictoryGroup>
		</VictoryChart>
	);
};

export default TrendlineRenderer;
