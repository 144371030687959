import moment from 'moment';
import { constants } from '../constants';

/**
 * Get an icon for the supplied gradepoint type
 * @param {string} type Gradepoint type
 */
export const getGradepointIcon = (type: string | undefined) => {
	let iconName = 'inyear';

	if (type === '4') {
		iconName = 'endofyear';
	}
	if (type === '1') {
		iconName = 'startofyear';
	}
	return iconName;
};

/**
 * Get display text for an gradepoint type
 * @param {string} type Gradepoint type
 */
export const getGradepointDisplay = (type: string | undefined) => {
	let display = 'Monitoring';

	if (type === '4' || type === 'endofyear') {
		display = 'Exam results';
	}
	if (type === '1' || type === 'startofyear') {
		display = 'Target setting';
	}
	return display;
};

/**
 * Split a gradepoint key into constituent parts, throwing error if incorrect
 * format encounterd. Expects: (year, type, name)
 * @param {string} gpKey Gradepoint key to split
 */
const splitGPKey = (gpKey: string) => {
	const splitKey = gpKey.split(',');

	/**
	 * This covers legacy data where the gp name could be of unlimited length and comma seperated
	 */
	if (splitKey.length > 3) {
		return splitKey.slice(0, 3);
	}

	if (splitKey.length < 3) {
		throw Error(`Unexpected gradepoint key format: ${gpKey}`);
	}

	return splitKey;
};

/**
 * Get the name of a gradepoint from the comma separated gradepoint key
 * (year, type, name)
 * @param {string} gpKey Gradepoint key to retrieve name from
 */
export const getGradepointNameFromKey = (gpKey: string) => {
	if (typeof gpKey !== 'string') {
		return;
	}
	return splitGPKey(gpKey)[2];
};

/**
 * Get the type of a gradepoint from the comma separated gradepoint key
 * (year, type, name)
 * @param {string} gpKey Gradepoint key to retrieve type from
 */
export const getGradepointTypeFromKey = (gpKey: string) => {
	if (typeof gpKey !== 'string') {
		return;
	}
	return splitGPKey(gpKey)[1];
};

/**
 * Get the Academic Year of a gradepoint from the comma separated gradepoint key
 * (year, type, name)
 * @param {string} gpKey Gradepoint key to retrieve name from
 */
export const getGradepointYearFromKey = (gpKey: string | undefined) => {
	if (typeof gpKey !== 'string') {
		return;
	}
	return splitGPKey(gpKey)[0];
};

/**
 * Filters an array of objects by custom predicates.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export const filterArray = (array: any[], filters: any) => {
	const filterKeys = Object.keys(filters);
	return array.filter((item) => {
		// validates all filter criteria
		return filterKeys.every((key) => {
			// ignores non-function predicates
			if (typeof filters[key] !== 'function') return true;
			return filters[key](item[key]);
		});
	});
};

/**
 * Returns the standard display name for the Academic Year - eg. 2017/18
 * The function attempts to convert argument to a number if one is not passed.
 * @param {number} year
 * @returns {string} Academic Year 'yyyy/yy'
 */
export function academicYearStandardFormat(year: number) {
	var academicYear = year;

	// If the argument is not a number, try convert it to a number
	if (typeof academicYear !== 'number') {
		academicYear = Number(academicYear);
		// If the year is still not a number, we can not format the data
		if (isNaN(academicYear)) return;
	}

	return academicYear + '/' + (academicYear + 1).toString().substring(2);
}

/**
 * Function to sort array by object key
 * @param arr Arr to sort
 */
export const sortByKeyDescending = (arr: any, key: any) =>
	arr.sort((a: any, b: any) => (a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0));

const getCurrentYear: string = new Date().getFullYear().toString();

/**
 * Returns a formatted Gradepoint option
 * @param {string} gpKey
 * @param {string[]} ygs
 */
export const getSelectorObjectFromKey = (gpKey: string, ygs: string[] = []) => {
	let type = getGradepointTypeFromKey(gpKey) as any;

	return {
		key: gpKey,
		label: `${getGradepointNameFromKey(gpKey)} -- ${
			getGradepointDisplay(type) === 'Monitoring' ? 'monitoring point' : 'exam results'
		} ${
			getGradepointYearFromKey(gpKey) === getCurrentYear
				? 'for ' + academicYearStandardFormat(getGradepointYearFromKey(gpKey) as any)
				: 'from ' + academicYearStandardFormat(getGradepointYearFromKey(gpKey) as any)
		}`,
		gradepointLabel: getGradepointNameFromKey(gpKey),
		value: getGradepointNameFromKey(gpKey),
		icon: getGradepointIcon(type) as 'inyear' | 'endofyear' | 'startofyear',
		type: getGradepointDisplay(type),
		ac: getGradepointYearFromKey(gpKey),
		ygs,
	};
};

export function autoGradepointPopulation(
	pgp: Context.Gradepoint,
	availableGradepoints: Context.Gradepoint[]
): string[] {
	const { yearGroups } = pgp.meta;
	const gps =
		yearGroups.length && pgp.type === 'InYear'
			? availableGradepoints.filter(({ meta }) =>
					meta.yearGroups.some((yg) => pgp.meta.yearGroups.includes(yg))
			  )
			: availableGradepoints;

	return gps
		.filter((gp) => {
			if (gp.type === 'InYear') {
				return (
					gp.type === pgp.type &&
					gp.year === pgp.year && // if type is "in year" only allow current ac years to be used
					gp.order <= pgp.order
				);
			} else {
				return (
					gp.type === pgp.type &&
					gp.year <= pgp.year && // if type is "end of year" allow current & previous ac years to be used
					gp.order <= pgp.order
				);
			}
		})
		.sort((a, b) => {
			// Sort year
			if (a.year < b.year) {
				return 1;
			} else if (a.year > b.year) {
				return -1;
			}

			// make sure that the primary gradepoint is always in the correct position (the first element in the array)
			if (a.gradepointId === pgp.gradepointId || b.gradepointId === pgp.gradepointId) {
				return -1;
			}

			// then by order
			if (a.order < b.order) {
				// shift right
				return 1;
			} else if (a.order > b.order) {
				// shift left
				return -1;
			}

			// put the primary gradepoint last (or first however you wish to look at it)

			return 0;
		})
		.map((gradepoint) => gradepoint.key)
		.splice(0, 4)
		.reverse(); // reverse the array so 1,2,3,4 becomes 4,3,2,1 for UI reasons
}

/**
 * Handle saving of users gradepoint trend to local storage
 * @param {Array<string>} selectedGPKeys An array of grade point keys
 */
export const saveGPTrendToLocalStorage = (selectedGPKeys: Array<string>): void => {
	/**
	 * Set the expiry date
	 */
	const expiry = moment().add(60, 'day').format('YYYY-MM-DD hh:mm:ss');

	/**
	 * Object to be set in local storage
	 */
	const LSObj = { value: selectedGPKeys, expiry };

	/**
	 * Set the gp trend in local storage
	 */
	localStorage.setItem(constants.AlpsSavedGradepointTrend, JSON.stringify(LSObj));
};
