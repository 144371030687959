/*
Calculate the subject vas score from the supplied information
*/
export function subjectVAS(
	actual: number,
	expected: number,
	entries: number,
	multiplier: number
): number {
	if (entries !== 0 && multiplier !== 0) {
		return (actual - expected) / (entries * multiplier) + 1;
	}

	return 0;
}
