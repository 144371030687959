/**
 * Standard display functionality for a percentage (input to function is
 * percentage expressed between 0 and 1)
 * @param {number} x
 * @param {string} [optional] Academic Year
 * @returns {string}
 */
export function percentage(x: number, ay?: string): string {
	if (ay === '') {
		return '-';
	}
	return x !== null ? (x * 100).toFixed(1) + '%' : '-';
}

/**
 * Alps Color rules specifically for strings
 * @param grade Alps Grade
 * @returns Color
 */
export const alpsColorsForText = (grade: number) => {
	return grade <= 3 ? 'TRed' : grade >= 7 && grade <= 9 ? 'TBlue' : 'Black';
};

/**
 * Standard display functionality for number of students
 * @param {number} x
 * @returns {string}
 */
export function numStudents(x: number): string {
	return x !== null ? formatLongNumbers(Number(x).toFixed(0)) : '-';
}

/**
 * Formats long numbers to match Alps report - e.g. 1,000 / 20,000
 * SOURCE: http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript?page=1&tab=votes#tab-top
 * @param {string} x
 * @returns {string}
 */
export function formatLongNumbers(x: string): string {
	if (x === null) {
		return '-';
	}
	// Do not want to format numbers after the decimal
	let parts = x.toString().split('.');
	// Use first item in array - in this instance, number before decimal
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return parts.join('.');
}

/**
 * Standard display functionality for an Alps score
 * @param {number | string} x
 * @returns {string}
 */
export function score(x: number | string): string {
	if (x === null || x === '-') {
		return '-';
	} else {
		return roundScore(x);
	}
}

/**
 * Standard rounding for score variable with optional precision (default is 2)
 * @param {number | string} x
 * @param {number?} precision
 * @returns {string}
 */
export function roundScore(x: number | string, precision: number = 2): string {
	if (precision < 0) precision = 0;

	return Number(x).toFixed(precision);
}

/**
 * Get the correct score calc type for the supplied year and country
 * @param {number} yearGroup The Year Group the wizard is for
 * @param {string} country The country of the client
 * @param {number} academicYear The Academic Year the wizard is for
 */
export function getCalcType(yearGroup: number, country: string, academicYear: number = 0) {
	let countryLower = country.toLowerCase ? country.toLowerCase() : '';

	// KS5 use GCSE calc
	if (yearGroup > 11) {
		return 'GCSE';
	}

	// KS4
	if (countryLower === 'wales') {
		return 'WNT';
	}

	// AY16Y7, AY17Y7-8, AY18Y7-9, AY19Y7-10, etc...
	if (academicYear >= 2016 && yearGroup <= academicYear - 2009) {
		return 'KS2Scaled';
	}

	return 'KTS';
}

/**
 * Standard display functionality for a numeric prior achievemnet score
 * @param {number} score
 * @param {any} options
 * @returns string
 */
export function priorAch(score: number | string, options: any = {}): string {
	if (score === null || score === undefined) {
		const defaultValue = options.valueIfNull ? options.valueIfNull : null;
		return defaultValue !== null && defaultValue !== undefined ? defaultValue : '-';
	}

	const calcType = getCalcType(options.yearGroup, options.country || '', options.academicYear);
	const isKS2Scaled = options.type === 'KS2ScaledScore' || calcType === 'KS2Scaled';

	// Scaled scores should be 0 decimal places
	return isKS2Scaled ? roundScore(score, 0) : roundScore(score);
}

/**
 * Get the background and foreground classes as a single string for
 * a given alps grade (in numeric form)
 * @param {number} g
 * @returns {string}
 */
export function gradeBGFG(g: number): string {
	return `${gradeBGClass(g)} ${gradeFGClass(g)}`;
}

/**
 * Get the foreground class name for a given alps grade (in numeric form)
 * @param {number} g
 * @returns {string}
 */
export function gradeFGClass(g: number): string {
	switch (g) {
		case 1:
		case 2:
		case 3:
			return 'alps-grade-red-fg';
		case 4:
		case 5:
		case 6:
			return 'alps-grade-black-fg';
		case 7:
		case 8:
		case 9:
			return 'alps-grade-blue-fg';
		default:
			return '';
	}
}

/**
 *  Get the background class name for a given alps grade (in numeric form)
 * @param {number} g
 * @returns {string}
 */
export function gradeBGClass(g: number): string {
	switch (g) {
		case 1:
		case 2:
		case 3:
			return 'alps-grade-red-bg';
		case 4:
		case 5:
		case 6:
			return 'alps-grade-black-bg';
		case 7:
		case 8:
		case 9:
			return 'alps-grade-blue-bg';
		default:
			return '';
	}
}
