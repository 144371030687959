import { takeLatest, throttle } from 'redux-saga/effects';
import { oneTimePasswordFlow } from './oneTimePasswordSaga';
import { emailAuthenticationFlow } from './emailAuthenticationSaga';
import { mfaSetFlow } from './mfaSetUpSaga';
import { deactivateAuthenticationFlow } from './deactivateAuthenticationSaga';
import { enableMultiFactorAuthenticationFlow } from './enableMultiFactorAuthenticationFlow';
import { resendEmailFlow } from './resendEmailSaga';

/**
 * Export all Manage Account Sagas in one array
 * This is imported into a root saga in the store.
 * Root saga is effectively the same as the root reducer.
 */
export const manageAccountSagas = [
	//Flows trigger by specific actions
	takeLatest('mfa/startSetUp', mfaSetFlow),
	takeLatest('mfaSetup/setCurrentMfaType', deactivateAuthenticationFlow),
	takeLatest('mfa/triggerEnableMfaFlow', enableMultiFactorAuthenticationFlow),
	takeLatest('mfa/resendEmail', resendEmailFlow),

	//Flows triggered by other flows automatically - currently will max refire once per second
	throttle(1000, 'mfa/triggerOneTimePasswordAuthentication', oneTimePasswordFlow),
	throttle(1000, 'mfa/triggerEmailAuthentication', emailAuthenticationFlow),
];
