import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportingSeason } from '../types';

/**
 * Initial State
 */
const initialState: ReportingSeason.State = {
	overallTable: undefined,
	groupTable: undefined,
	showMatViewAs: false,
	loading: false,
};

/**
 * ReportingSeason Slice
 */

const ReporingSeasonSlice = createSlice({
	name: 'reporting season',
	initialState,
	reducers: {
		setReportSeasonOverallData(
			state: ReportingSeason.State,
			action: PayloadAction<ReportingSeason.OverallTable>
		) {
			state.overallTable = action.payload;
		},
		setReportSeasonGroupData(
			state: ReportingSeason.State,
			action: PayloadAction<ReportingSeason.GroupTable>
		) {
			state.groupTable = action.payload;
		},
		toggleMatReporting(state: ReportingSeason.State) {
			state.showMatViewAs = !state.showMatViewAs;
		},
		setLoading(state: ReportingSeason.State, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
	},
});

/**
 * Export Actions
 */

export const {
	setReportSeasonOverallData,
	setReportSeasonGroupData,
	toggleMatReporting,
	setLoading,
} = ReporingSeasonSlice.actions;

export default ReporingSeasonSlice.reducer;
