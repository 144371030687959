import { OrganisationDetailsManagement } from '../features/manageUsers/types';

import fetch from './';

export const getOrganisationDetails = (): Promise<
	ResponseBuilder<OrganisationDetailsManagement.OrganisationDetails>
> => {
	return fetch('/api/admin/organisationdetails');
};

export const updateOrganisationDetails = (
	organisationDetails: any
): Promise<ResponseBuilder<OrganisationDetailsManagement.OrganisationDetails>> => {
	return fetch('/api/admin/organisationdetails', {
		body: {
			HeadteacherPrincipalContactDetails: organisationDetails.HeadteacherPrincipalContactDetails,
			SchoolContactDetails: organisationDetails.SchoolContactDetails,
			FinanceContactDetails: organisationDetails.FinanceContactDetails,
			MISInformation: organisationDetails.MISInformation,
			DFEInformation: organisationDetails.DFEInformation,
			EmergencyContactNumber: organisationDetails.EmergencyContactNumber,
		},
	}).then((response) => {
		return response;
	});
};
