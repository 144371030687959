/**
 * Comparison selectors
 */

import { SubjectArea } from 'features/subjectArea/types';
import { SubjectAreaGridData } from 'features/subjectArea/types/common';
import { getSlice } from './';
import { appliedAndSelectedAreEqual } from '../../../../utils/agGridUtils/agGridComparisonsHelpers';

/**
 * Gets the default report level selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedDefaultComparisons = (state: RootState) => {
	return state.subjectArea.selectedDefaultComparisons;
};

/**
 * Gets all the loaded comparison data
 * @param state redux state
 * @returns An object byId / allIds for the loaded comparison data
 */
export const getAllComparisonData = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): SubjectAreaGridData.Comparisons<SubjectAreaGridData.ComparisonRow> | undefined => {
	const slice = getSlice(page)(state);

	return slice.comparisons;
};

/**
 * Gets all the loaded comparison data
 * @param state redux state
 * @returns An object byId / allIds for the loaded comparison data
 */
export const getAllGraphComparisonData = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): SubjectAreaGridData.Comparisons<SubjectAreaGridData.ComparisonRow> | undefined => {
	const slice = getSlice(page)(state);

	return slice.graphComparisons;
};

/**
 * Gets the individual page level applied comparisons
 * @param state Redux state
 * @returns Array of applied comparisons
 */
export const getAppliedPageComparisons = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): CommonFeatureTypes.ComparisonOption => {
	const appliedPageComparisons = state.subjectArea.selectedSubjectAreaComparisons;

	return appliedPageComparisons ?? [];
};

/**
 * Gets and array of all the loaded comparison ids
 * @param state redux state
 * @returns An array of all the loaded comparison ids
 */
export const getAllComparisonIds = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): string[] => {
	const data = getAllComparisonData(page)(state);
	return data ? data.allIds : [];
};
/**
 * Get the row ids for the loaded comparisons, arse way round of checking as the comparison id is currently a guid and has no meaning
 * @param state redux state
 * @returns An array of row ids thats correspond to the loaded comparisons. Shows us where the comparison belongs in the grid row order.
 */
export const getRowIdsForLoadedComparisons = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): string[] => {
	const comparisons = getAllComparisonData(page)(state);
	const ids = comparisons && comparisons.allIds;
	const rowIds = comparisons && ids!.map((id) => comparisons!.byId[id].rowId);

	return rowIds ? rowIds : [];
};

/**
 * Gets and array of all the loaded comparison ids for a given page
 * @param state redux state
 * @returns An array of all the loaded comparison ids
 */
export const getAllComparisonIdsForActivePage = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): string[] => {
	const slice = getSlice(page)(state);
	const data = slice.comparisons;

	return data ? data.allIds : [];
};

/**
 * Gets the individual page level selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedPageComparisons = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): SubjectArea.ComparisonOption => {
	const selectedPageComparisons = state.subjectArea.selectedPageComparisons;

	return selectedPageComparisons[page] ?? [];
};

/**
 * Check if we have comparisons applied
 * @param page Analysis page
 * @returns boolean
 */
export const hasAppliedPageComparisons = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): boolean => {
	const appliedPageComparisons = getAppliedPageComparisons(page)(state);

	return appliedPageComparisons.length > 0;
};

/**
 * Check if we have comparisons applied
 * @param page Analysis page
 * @returns boolean
 */
export const getSelectedSubjectAreaComparisons = (
	state: RootState
): CommonFeatureTypes.ComparisonOption[] => {
	return state.subjectArea.selectedSubjectAreaComparisons;
};

/**
 * Determine if the comparisons have changed
 * @param page subject area analysis page
 * @returns boolean
 */
export const checkComparisonStatesAreEqual = (page: SubjectArea.SubjectAnalysisPages) => (
	state: RootState
): boolean => {
	const appliedComparisons = getAppliedPageComparisons(page)(state);
	const selectedComparisons = getSelectedPageComparisons(page)(state);

	return appliedAndSelectedAreEqual<{ label: string; value: string }>(
		appliedComparisons,
		selectedComparisons
	);
};
