import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ManageAccount.State = {
	loading: false,
	errorMessage: undefined,
	selectedSubscription: undefined,
	subscriptions: {
		KS4: {
			size: { label: '', value: 'small' },
			duration: null,
		},
		KS5: {
			size: null,
			duration: null,
		},
		KS4KS5: {
			size: null,
			duration: null,
		},
		start: null,
		end: null,
		total: 0,
	},
	selectedReports: [],
	reportsData: {
		qualifications: ['A', 'AS', 'BTEC'],
		reports: ['Gender', 'Disadvantaged', 'Ethnicity', 'TeachingSets', 'HighGrades'],
		price: 250,
	},
	reports: {
		A: {
			type: 'A',
			selectedFilterReports: [],
		},
		AS: {
			type: 'AS',
			selectedFilterReports: [],
		},
		BTEC: {
			type: 'BTEC',
			selectedFilterReports: [],
		},
		total: 0,
		existingReports: [],
	},
	organisationDetails: undefined,
	consent: {
		terms: false,
		dataSharing: false,
	},
	agreements: undefined,
	autoRenewCancellationCandidateId: undefined,
	enabled: false,
};

/**
 * Accounts Slice
 * TODO - Persist the relevant data so the form can be completed at different times
 */
const subscriptionsSlice = createSlice({
	name: 'subscriptions',
	initialState,
	reducers: {
		updateLoading(state: ManageAccount.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},
		updateErrorMessage(state: ManageAccount.State, action: PayloadAction<string>) {
			return {
				...state,
				errorMessage: action.payload,
			};
		},
		updateSelectedSubscriptionLevel(
			state: ManageAccount.State,
			action: PayloadAction<ManageAccount.SubscriptionLevel>
		) {
			return {
				...state,
				selectedSubscription: action.payload,
			};
		},
		updateSelectedSubscriptionSize(
			state: ManageAccount.State,
			action: PayloadAction<{
				selectedSubscription: ManageAccount.SubscriptionLevel;
				size: ManageAccount.SizeOption;
			}>
		) {
			const { selectedSubscription, size } = action.payload;

			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					[selectedSubscription as ManageAccount.SubscriptionLevel]: {
						...state.subscriptions[selectedSubscription as ManageAccount.SubscriptionLevel],
						size,
					},
				},
			};
		},
		updateSelectedSubscriptionDuration(
			state: ManageAccount.State,
			action: PayloadAction<{
				selectedSubscription: ManageAccount.SubscriptionLevel;
				duration: ManageAccount.DurationOption;
			}>
		) {
			const { selectedSubscription, duration } = action.payload;

			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					[selectedSubscription as ManageAccount.SubscriptionLevel]: {
						...state.subscriptions[selectedSubscription as ManageAccount.SubscriptionLevel],
						duration,
					},
				},
			};
		},
		updateSelectedReportLevel(
			state: ManageAccount.State,
			action: PayloadAction<ManageAccount.Qualifications>
		) {
			const selectedReports = state.selectedReports;
			const updateSelectedReports = selectedReports.includes(action.payload)
				? selectedReports.filter((rep) => rep !== action.payload)
				: //@ts-ignore
				  [...state.selectedReports, action.payload];

			return {
				...state,
				selectedReports: updateSelectedReports,
			};
		},
		updateSelectedReport(
			state: ManageAccount.State,
			action: PayloadAction<{
				qual: ManageAccount.Qualifications;
				reports: ManageAccount.FilterReports[];
			}>
		) {
			return {
				...state,
				reports: {
					...state.reports,
					[action.payload.qual]: {
						...state.reports[action.payload.qual],
						selectedFilterReports: action.payload.reports,
					},
				},
			};
		},
		resetSubsandReports(state: ManageAccount.State) {
			return {
				...state,
				selectedSubscription: initialState.selectedSubscription,
				selectedReports: initialState.selectedReports,
				subscriptions: initialState.subscriptions,
				reports: initialState.reports,
				consent: initialState.consent,
			};
		},
		resetReports(state: ManageAccount.State) {
			return {
				...state,
				selectedReports: initialState.selectedReports,
				reports: initialState.reports,
			};
		},
		updateOrderTotal(
			state: ManageAccount.State,
			action: PayloadAction<{
				subscriptionTotal: number;
				reportsTotal: number;
			}>
		) {
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					total: action.payload.subscriptionTotal,
				},
				reports: {
					...state.reports,
					total: action.payload.reportsTotal,
				},
			};
		},
		updateCurrentSubscriptions(
			state: ManageAccount.State,
			action: PayloadAction<Array<ManageAccount.SubscriptionAgreement>>
		) {
			return {
				...state,
				agreements: action.payload.map((x, i) => {
					return {
						...x,
						autoRenewEnabled: action.payload[i].autoRenewEnabled ?? true,
					};
				}),
				selectedSubscription:
					action.payload && action.payload.length ? state.selectedSubscription : 'KS4KS5',
			};
		},
		updateOrganisationDetails(
			state: ManageAccount.State,
			action: PayloadAction<ManageAccount.OrganisationDetails>
		) {
			return {
				...state,
				organisationDetails: action.payload,
			};
		},
		updateSubscriptionDates(
			state: ManageAccount.State,
			action: PayloadAction<{ start: string; end: string }>
		) {
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					start: action.payload.start,
					end: action.payload.end,
				},
			};
		},
		updateSubscriptionPrices(state: ManageAccount.State, action: PayloadAction<any>) {
			return {
				...state,
				pricing: action.payload,
			};
		},
		updateConsent(state: ManageAccount.State, action: PayloadAction<ManageAccount.Consent>) {
			return {
				...state,
				consent: action.payload,
			};
		},
		clearSubscriptionDetails(state: ManageAccount.State) {
			return initialState;
		},
		setExistingReports(
			state: ManageAccount.State,
			action: PayloadAction<Array<ManageAccount.Report>>
		) {
			return {
				...state,
				reports: { ...state.reports, existingReports: action.payload },
			};
		},
		setAutoRenewalCancellationCandidateId(
			state: ManageAccount.State,
			action: PayloadAction<number>
		) {
			return {
				...state,
				autoRenewCancellationCandidateId: action.payload,
			};
		},
		clearAutoRenewalCancellationCandidateId(state: ManageAccount.State) {
			return {
				...state,
				autoRenewCancellationCandidateId: undefined,
			};
		},
		setSaveFooter(state: ManageAccount.State, action: PayloadAction<boolean>) {
			state.enable = action.payload;
		},
	},
});

export const {
	updateLoading,
	updateErrorMessage,
	updateSelectedSubscriptionLevel,
	updateSelectedSubscriptionSize,
	updateSelectedSubscriptionDuration,
	updateSelectedReportLevel,
	updateSelectedReport,
	resetSubsandReports,
	updateOrderTotal,
	updateCurrentSubscriptions,
	updateOrganisationDetails,
	updateSubscriptionDates,
	updateSubscriptionPrices,
	updateConsent,
	clearSubscriptionDetails,
	setExistingReports,
	resetReports,
	setAutoRenewalCancellationCandidateId,
	clearAutoRenewalCancellationCandidateId,
	setSaveFooter,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
