import React, { Fragment, FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '../utils/locale';
import { getGradepointDisplay, getGradepointTypeFromKey } from '../utils/gradepointSelector';
import { Flex, Heading, Text, Button } from 'basecamp';
import { getClient, getPrimaryGradepoint } from 'features/app/redux/context';
import { toggleGpSelector } from 'features/layouts/standardPage/redux/standardPage';
import SupportHubLink from './supportHub/supportHubLink';
import { getAllGroupTrends, getGroupSelectedTrendId } from 'features/groups/analysis/redux';

const GradepointContextExplainer: FunctionComponent = () => {
	const dispatch = useDispatch();

	/**
	 * Redux selectors
	 */
	const pgp = useSelector(getPrimaryGradepoint);
	const client = useSelector(getClient);
	const hasGroupAccess = client?.hasGroupAccess;
	const selectedTrendId = useSelector(getGroupSelectedTrendId);
	const trends = useSelector(getAllGroupTrends);

	/**
	 * Handle Summit text
	 */
	const handleSummitText = (): string => {
		const gradepoints = trends.find((trend) => trend.id === selectedTrendId)?.gradepoints;

		// Bail if no data
		if (!selectedTrendId || trends.length === 0 || !gradepoints) {
			return translate('gradepointContextExplainer.NO_DATA') as string;
		}

		const pgp: { key: string; ordinal: number } = gradepoints[gradepoints.length - 1];
		const typeFromKey = getGradepointTypeFromKey(pgp.key);
		const type = getGradepointDisplay(typeFromKey).toLocaleLowerCase();

		// Returns formatted heading text based on the type of gradepoint only
		return translate('gradepointContextExplainer.GP_TREND_SUMMIT', {
			type,
		}) as string;
	};

	/**
	 * Handle Connect text
	 */
	const handleConnectText = (): string => {
		// Bail if no data
		if (!pgp || Object.entries(pgp).length === 0) {
			return translate('gradepointContextExplainer.NO_DATA') as string;
		}

		const gpType = pgp.type.toLowerCase();
		const yearGroups = pgp.meta?.yearGroups;
		const yearGroupsText =
			!!yearGroups && yearGroups.length > 0
				? yearGroups.reduce((acc, curr, i) => {
						const isLast = i === yearGroups.length - 1;
						const removeCommaIfLast = isLast ? curr : `${curr}, `;
						return `${acc} ${removeCommaIfLast}`;
				  }, '')
				: '';
		const type = getGradepointDisplay(gpType).toLocaleLowerCase();

		// Returns formatted heading text based on the type of gradepoint and year groups
		return translate('gradepointContextExplainer.GP_TREND_CONNECT', {
			type,
			yearGroups: yearGroupsText,
			pluralise: !!yearGroups && yearGroups?.length > 1 ? 's' : '',
		}) as string;
	};

	/**
	 * Handles the heading text for the explainer
	 * @returns {string} heading text
	 */
	const getHeading = (): string => {
		return hasGroupAccess ? handleSummitText() : handleConnectText();
	};

	return (
		<Fragment>
			<Heading setAs="large" withColor="black" mt={[4, 4, 4, 4, 0]}>
				{translate('gradepointContextExplainer.HEADING')}
			</Heading>
			<Flex setAs="column" bg="UI.secondary.1" borderRadius={3} p={4} mb={4}>
				<Heading setAs="medium" withColor="black" mt={0}>
					{getHeading()}
				</Heading>

				<Text>{translate('gradepointContextExplainer.TEXT')}</Text>

				<Flex width={1}>
					<Button setAs="accent" onClick={() => dispatch(toggleGpSelector())} width={180} mr={4}>
						{translate('gradepointContextExplainer.BUTTON')}
					</Button>

					<SupportHubLink link={translate('gradepointContextExplainer.LINK') as string} />
				</Flex>
			</Flex>
		</Fragment>
	);
};

export default GradepointContextExplainer;
