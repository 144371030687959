import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';
import { MfaType } from '../../../../utils/mfa';

/**
 * Initial State
 */
const initialState: MultiFactorAuthentication.State = {
	currentMfaId: undefined,
	loading: false,
	isMfaEnabled: false,
	arrayOfActiveMfaTypes: undefined,
	userDefinedConfiguration: undefined,
	error: '',
	cancelFlow: false,
	currentMfaType: undefined,
	hasUserEnabledMfa: false,
};

/**
 * Reducers
 */
const mfaSetup = createSlice({
	name: 'mfaSetup',
	initialState,
	reducers: {
		setCurrentMfaId(state: MultiFactorAuthentication.State, action: PayloadAction<MfaType>) {
			return {
				...state,
				currentMfaId: action.payload,
			};
		},
		setLoading(state: MultiFactorAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},
		setIsMfaEnabled(state: MultiFactorAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				isMfaEnabled: action.payload,
			};
		},
		setAvailableArrayOfMfaTypes(
			state: MultiFactorAuthentication.State,
			action: PayloadAction<MultiFactorAuthentication.AvailableMfaResponse[]>
		) {
			return {
				...state,
				arrayOfActiveMfaTypes: action.payload,
			};
		},
		setMfaSetUpError(state: MultiFactorAuthentication.State, action: PayloadAction<string>) {
			return {
				...state,
				error: action.payload,
			};
		},
		setUserConfiguration(
			state: MultiFactorAuthentication.State,
			action: PayloadAction<MultiFactorAuthentication.UserDataMfaResponse[]>
		) {
			return {
				...state,
				userDefinedConfiguration: action.payload,
			};
		},
		setCancelFlow(state: MultiFactorAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				cancelFlow: action.payload,
			};
		},
		setCurrentMfaType(state: MultiFactorAuthentication.State, action: PayloadAction<MfaType>) {
			return {
				...state,
				currentMfaType: action.payload,
			};
		},
		setHasUserEnabledMfa(state: MultiFactorAuthentication.State, action: PayloadAction<boolean>) {
			return {
				...state,
				hasUserEnabledMfa: action.payload,
			};
		},
	},
});

/**
 * Export empty saga watched actions
 */
export const triggerMfaSet = createAction('mfa/startSetUp');
export const triggerEmailAuthentication = createAction('mfa/triggerEmailAuthentication');
export const triggerOneTimePasswordAuthentication = createAction(
	'mfa/triggerOneTimePasswordAuthentication'
);
export const deactivateAuthentication = createAction('mfaSetup/setCurrentMfaType');

export const triggerEnableMfaFlow = createAction('mfa/triggerEnableMfaFlow');
export const sendEmail = createAction('mfa/resendEmail');

/**
 * Actions
 */
export const {
	setCurrentMfaId,
	setLoading,
	setIsMfaEnabled,
	setAvailableArrayOfMfaTypes,
	setMfaSetUpError,
	setUserConfiguration,
	setCancelFlow,
	setCurrentMfaType,
	setHasUserEnabledMfa,
} = mfaSetup.actions;

export default mfaSetup.reducer;
