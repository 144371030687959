import * as React from 'react';
import { Flex, Button } from 'basecamp';

import { translate } from '../../utils/locale';
import { SubjectPage } from 'features/subjectPage/types';

const OptionWithAddAll = (
	groupName: string,
	options: SubjectPage.Option[],
	selectedOptions: SubjectPage.Option[],
	setter: (options: SubjectPage.Option[]) => void,
	isQualificationFilter: boolean = false,
	shouldDisable: boolean = false
) => {
	/**
	 * Get array of options being applied
	 */
	const allGroups = options?.length > 0 ? options?.map((opt) => opt.label) : [];

	/**
	 * Get array of labels of all applied comparisons / qualtypes.
	 */
	const selectedGroups = isQualificationFilter
		? getQualificationSelectedGroupsLabels(selectedOptions)
		: getNonQualificationSelectedGroupsLabels(selectedOptions, groupName);

	/**
	 * Determine if all comparisons are applied. While allGroups encompasses all potential selections, it doesn't actually include all possible selections.
	 * For example the Qualification filter has a KS5 group of several selections and a separate option of KS4, so while allGroups are every KS5 selections,
	 * selected options can be all of those selections AND KS4. To deal with this, we make sure every potential selection is in the actual selections.
	 * This ignores special cases such as KS4, All and Unknown
	 */
	const allGroupsSelected = allGroups.reduce((acc, group) => {
		return acc && selectedGroups.includes(group);
	}, true);

	/**
	 * Handle Comparison Group Updates
	 * @param options - Array, react select options
	 */
	const handleGroupsUpdate = (options: SubjectPage.Option[]) => {
		// Take all the currently applied comparisons and remove the comparison group
		const removeAll = selectedOptions.filter(
			(selectedOption) => !allGroups.includes(selectedOption.label)
		);
		// If the comparsion group has already been applied remove it, otherwise add to applied comparisons
		const addAll = options.reduce(
			(acc: SubjectPage.Option[], curr) => {
				return selectedGroups.includes(curr.label) ? acc : [...acc, curr];
			},
			// @ts-ignore - spread error
			[...selectedOptions]
		);

		if (shouldDisable && allGroupsSelected) {
			return setter(removeAll);
		}

		return allGroupsSelected ? setter(removeAll) : setter(addAll);
	};

	/**
	 * Render option group
	 */
	return {
		label: (() => {
			return (
				<Flex
					data-test="optionWithAddRemoveAllWrapper"
					setAs="row"
					withAlign="distribute"
					width={1}
				>
					{groupName}
					<Button
						data-test="optionWithAddRemoveAllButton"
						withSize="small"
						setAs="accent"
						disabled={shouldDisable && !allGroupsSelected}
						onClick={() => handleGroupsUpdate(options)}
					>
						{/* Switch context if comparison groups have already been applied */}
						{allGroupsSelected
							? translate('reactSelect.optionWithAddAll.REMOVE_ALL')
							: translate('reactSelect.optionWithAddAll.ADD_ALL')}
					</Button>
				</Flex>
			);
		})(),
		options,
	};
};

/**
 * Return the applied options only for the group being processed
 * @param selectedOptions options that have been selected
 * @param groupName The group name the options belong to
 * @returns an array of selected option labels
 */
const getNonQualificationSelectedGroupsLabels = (
	selectedOptions: SubjectPage.Option[],
	groupName: string
) => {
	return selectedOptions.map((selectedOption) => {
		if (selectedOption.value.toString().includes('|')) {
			const selectedGroupName = selectedOption.value.split('|')[0]; // get the group name of the applied option
			if (selectedGroupName.toLowerCase() === groupName.toLowerCase()) {
				// if the options applied match the group being processed
				return selectedOption.label; // only return the options for the group being processed
			}
		}
	});
};

/**
 * Return the applied options for qualifications. Different as they dont have <group>|<option> values
 * @param selectedOptions options that have been selected
 * @returns an array of selected option labels
 */
const getQualificationSelectedGroupsLabels = (selectedOptions: SubjectPage.Option[]) => {
	return selectedOptions.map((selectedOption) => {
		return selectedOption.label;
	});
};

export default OptionWithAddAll;
