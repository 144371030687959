/**
 * Exports selectors
 */

/**
 * Export excel
 * @param state Redux state
 * @returns Boolean
 * Simple flag used to determine if a strategic page excel can be exported
 */
export const exportExcel = (state: RootState) => {
	return state.strategicAnalysis.exportExcel;
};

/**
 * Determine if the strategic pdf report modal should be shown
 * @param state Root state
 * @returns boolean
 */
export const shouldShowStrategicPdfReportModal = (state: RootState) => {
	return state.strategicAnalysis.showStrategicPdfReportModal;
};

/**
 * Get the pdf report state
 */
export const getPdfReport = (state: RootState) => {
	return state.strategicAnalysis.pdfReport;
};

