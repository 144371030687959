import * as React from 'react';
import { FunctionComponent } from 'react';
import { Flex, Text, Button } from 'basecamp';
import { translate } from '../../utils/locale';
import { useSelector } from 'react-redux';

type Props = {
	errorSelector?: (state: RootState) => any;
	errorMessage?: string | HTMLSpanElement;
	onRetry?: () => any;
};

const NoRowsOverlay: FunctionComponent<Props> = ({
	errorSelector = false,
	onRetry,
	errorMessage,
}) => {
	const hasError = typeof errorSelector === 'function' ? useSelector(errorSelector) : false;

	if (hasError) {
		return (
			<Flex withAlign={'center'} height={'calc(100% - 100px)'} pt={'100px'}>
				<Flex withAlign={'distribute'} setAs={'column'} width={'50%'}>
					<Flex setAs={'row'}>
						<Text>{errorMessage || translate('grid.ERROR_MESSAGE')}</Text>
					</Flex>
					{onRetry ? (
						<Flex setAs={'row'}>
							<Button style={{ pointerEvents: 'all' }} onClick={onRetry}>
								{translate('grid.RETRY')}
							</Button>
						</Flex>
					) : null}
				</Flex>
			</Flex>
		);
	}

	return (
		<Flex height="100%" setAs="row" withAlign={'center'}>
			<Text>{translate('grid.NO_ROWS_MESSAGE')}</Text>
		</Flex>
	);
};

export default NoRowsOverlay;
