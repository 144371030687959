import { translate } from './locale';

export const DEFAULT_FILENAME = translate('exportModal.DEFAULT_FILE_NAME') as string;

export function sanitizeFilename(
	filename: string = DEFAULT_FILENAME,
	extension: string = ''
): string {
	const extensionRegex = new RegExp(`\\.${extension}\\s*$`, 'gmi');

	// Remove any extension from the filename if it matches the one we'll attach
	const extensionlessFilename = extension.length ? filename.replace(extensionRegex, '') : filename;

	// Remove any leading spaces and leading . character
	const leadingDotFilename = extensionlessFilename.replace(/^\s*\./gim, '');

	// Make sure the final filename has a length, otherwise save as the default name
	const sanitizedFileName = leadingDotFilename.trim().length
		? leadingDotFilename.trim()
		: DEFAULT_FILENAME;

	return `${sanitizedFileName}${extension ? `.${extension}` : ''}`;
}
