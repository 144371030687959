import { SagaIterator } from '@redux-saga/types';
import { put, select, delay, cancel, call, spawn } from 'redux-saga/effects';
import { getSubjectAreaPage } from './sagaUtils';
import {
	fetchSubjectAreaSubjectId,
	fetchSubjectAreaActiveExamLevel,
	getSelectedSubjectAreaComparisons,
} from 'features/subjectArea/redux/selectors';
import { SubjectAreaPages } from 'features/subjectArea/enums';
import { mainGridData } from './fetchMainGridData';
import { mainChartData } from './fetchMainGraphData';
import { setsubjectAreaLoading } from '../slice';
import { setComparisonGradepoint } from '../attainmentProfile/slice';
import { changeComprisonsFlow } from './changeComparisonFlow';
import { getAppliedGradepoints } from 'features/app/redux/context';
/**
 * Saga workflow to handle the changing of the gradepoint within the subject area
 */
export function* changeSubjectAreaGradepointData(): SagaIterator<void> {
	//Set subject area loading
	yield put(setsubjectAreaLoading(true));

	//Figure out what page we are on
	const page = yield call(getSubjectAreaPage);

	//Fetch the applied subject id
	const subjectAreaIdSet = yield select(fetchSubjectAreaSubjectId);

	//If the subject Id is undefined or we arent on the subject area pages cancel
	if (subjectAreaIdSet === undefined && page !== SubjectAreaPages) {
		yield cancel();
	}

	//Find out what examlevel we are on - this will be 0 but will allow future reusuablity with the selectors
	const currentExamLevel = yield select(fetchSubjectAreaActiveExamLevel);

	//Fetch applied Subject area comparions
	const subjectAreaComparisons = yield select(getSelectedSubjectAreaComparisons);

	//Fetch applied Gradepoints
	const appliedGradepoints = yield select(getAppliedGradepoints);

	//format the value to add to comparison gp, if theres only one then set as undefined
	const valueToAdd =
		appliedGradepoints.length > 1
			? appliedGradepoints[appliedGradepoints.length - 2].name
			: undefined;

	//Set comparison Gradepoint
	yield put(setComparisonGradepoint(valueToAdd));

	//fetch grid data - spawn this so it can run concurrently
	yield call(mainGridData, page, currentExamLevel, subjectAreaIdSet);

	//fetch graph data - call this to block the loading flag
	yield call(mainChartData, page, currentExamLevel, subjectAreaIdSet);

	//If we have comparisons applied spawn comparison saga
	if (subjectAreaComparisons.length > 0) {
		yield call(changeComprisonsFlow);
	}

	//Allow state to update
	yield delay(1000);

	//set loading to false
	yield put(setsubjectAreaLoading(false));
}
