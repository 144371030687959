import React from 'react';
import { FunctionComponent } from 'react';

import { Flex, BaseElement, Icon, Text } from 'basecamp';
import { useSelector } from 'react-redux';
import { getConfig } from './redux/features';

const MaintenancePage: FunctionComponent = () => {
	const title = useSelector(getConfig('maintenance_title'));
	const message = useSelector(getConfig('maintenance_message'));

	return (
		<Flex
			setAs="column"
			withAlign="center"
			width={1}
			height="100vh"
			bg="UI.primary.0"
			fontSize="100px"
			color="UI.white"
		>
			<Icon icon={'wrench'} />
			<BaseElement
				fontFamily="headings"
				fontSize="72px"
				color="UI.black"
				width={'60%'}
				textAlign={'center'}
			>
				{title}
			</BaseElement>
			<Text setAs={'h5'} withColor={'white'}>
				{message}
			</Text>
		</Flex>
	);
};

export default MaintenancePage;
