import { SubjectArea } from 'features/subjectArea/types';
import { SubjectAreaAttainmentProfile } from 'features/subjectArea/types/attainmentProfile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubjectAreaGridData, SubjectAreaGraphData } from 'features/subjectArea/types/common';
import { CommonAgGridChartStateTypes } from 'types/commonAgGridTypes';

/**
 * Initial State
 */
const initialState: SubjectAreaAttainmentProfile.State = {
	examLevels: undefined,
	grids: undefined,
	rows: undefined,
	comparisons: undefined,
	gridError: undefined,

	graphExamLevels: undefined,
	graphs: undefined,
	xAxis: undefined,
	graphComparisons: undefined,
	graphError: undefined,

	comparisonGradepoint: '',
	loading: false,
	showStudentCount: true,

	overallSagaFailure: false,
};

/**
 * Reducers
 */
const subjectAreaAttainmentProfile = createSlice({
	name: 'subjectAreaAttainmentProfile',
	initialState,
	reducers: {
		setMainGridDataSuccess(
			state: SubjectAreaAttainmentProfile.State,
			action: PayloadAction<SubjectAreaGridData.GridData>
		) {
			const { examLevels, grids, rows } = action.payload;
			// If this is the first fetch just set the data
			if (!state.examLevels || !state.grids) {
				return {
					...state,
					examLevels,
					grids,
					rows,
				};
			}
			// If this is a subsequent fetch update the data
			return {
				...state,
				examLevels: {
					...state.examLevels,
					id: examLevels.id,
					byId: {
						...state.examLevels!.byId,
						...examLevels.byId,
					},
					//@ts-ignore bloody spread error again
					allIds: [...state.examLevels.allIds, ...examLevels.allIds],
				},
				grids: {
					...state.grids,
					id: grids.id,
					byId: {
						...state.grids!?.byId,
						...grids.byId,
					},
					allIds: [...state.grids!?.allIds, ...grids.allIds],
				},
				rows: {
					...state.rows,
					id: rows.id,
					byId: {
						...state.rows!.byId,
						...rows.byId,
					},
					allIds: [...state.rows!.allIds, ...rows.allIds],
				},
				loading: false,
				gridError: undefined,
			};
		},
		setMainGraphDataSuccess(
			state: SubjectAreaAttainmentProfile.State,
			action: PayloadAction<SubjectAreaGraphData.GraphData>
		) {
			const { examLevels, graphs, xAxis } = action.payload;

			// If this is the first fetch just set the data
			if (!state.graphs || !state.graphExamLevels) {
				return {
					...state,
					examLevels,
					graphs,
					xAxis,
				};
			}
			// If this is a subsequent fetch update the data
			return {
				...state,
				graphError: undefined,
				graphExamLevels: {
					...state.graphExamLevels,
					id: examLevels.id,
					byId: {
						...state.graphExamLevels!.byId,
						...examLevels.byId,
					},
					//@ts-ignore bloody spread error again
					allIds: [...state.graphExamLevels!.allIds, ...examLevels.allIds],
				},
				graphs: {
					...state.graphs,
					id: graphs.id,
					byId: {
						...state.graphs?.byId,
						...graphs.byId,
					},
					allIds: [...state.graphs!.allIds, ...graphs.allIds],
				},
				xAxis: {
					...state.xAxis,
					id: xAxis.id,
					byId: {
						...state.xAxis!.byId,
						...xAxis.byId,
					},
					allIds: [...state.xAxis!.allIds, ...xAxis.allIds],
				},
			};
		},
		setMainGridDataFail(state: SubjectAreaAttainmentProfile.State, action: PayloadAction<string>) {
			const { payload } = action;

			return {
				...state,
				gridError: payload,
				loading: false,
			};
		},
		setMainGraphDataFail(state: SubjectAreaAttainmentProfile.State, action: PayloadAction<string>) {
			const { payload } = action;

			return {
				...state,
				graphError: payload,
				loading: false,
				graphs: undefined,
			};
		},
		setAllGraphComparisonDataSuccess(
			state: SubjectAreaAttainmentProfile.State,
			action: PayloadAction<
				CommonAgGridChartStateTypes.Comparisons<CommonAgGridChartStateTypes.ComparisonXAxis>
			>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
				};
			}

			return {
				...state,
				graphComparisons: !state.graphComparisons
					? payload
					: {
							...state.graphComparisons,
							id: payload.id,
							byId: {
								...state.graphComparisons.byId,
								...payload.byId,
							},
							allIds: Array.from(new Set([...state.graphComparisons.allIds, ...payload.allIds])),
					  },
				xAxis: {
					...state.xAxis,
					byId: Object.entries(state.xAxis.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: Array.from(
									new Set(
										Object.values(payload.byId)
											.filter((val) => val.graphId === key)
											.map((comparison) => comparison.comparisonId)
									)
								),
							},
						};
					}, {}),
				},
			};
		},
		setAllComparisonDataSuccess(
			state: SubjectAreaAttainmentProfile.State,
			action: PayloadAction<
				CommonAgGridChartStateTypes.Comparisons<CommonAgGridChartStateTypes.ComparisonRow>
			>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				comparisons: !state.comparisons
					? payload
					: {
							...state.comparisons,
							id: payload.id,
							byId: {
								...state.comparisons.byId,
								...payload.byId,
							},
							allIds: Array.from(new Set([...state.comparisons.allIds, ...payload.allIds])),
					  },
				rows: {
					...state.rows,
					byId: Object.entries(state.rows.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds: Array.from(
									new Set(
										Object.values(payload.byId)
											.filter((val) => val.rowId === key)
											.map((comparison) => comparison.comparisonId)
									)
								),
							},
						};
					}, {}),
				},
			};
		},
		setAllRowComparisonIds(
			state: SubjectAreaAttainmentProfile.State,
			action: PayloadAction<string[]>
		) {
			const { payload } = action;

			if (!state.rows || !payload) {
				return {
					...state,
					loading: false,
				};
			}

			return {
				...state,
				rows: {
					...state.rows,
					byId: Object.entries(state.rows!.byId).reduce((acc, [key, val]) => {
						return {
							...acc,
							[key]: {
								...val,
								comparisonIds:
									payload.length > 0
										? payload.filter((comparisonId) => comparisonId.includes(`${val.rowId}-`))
										: [],
							},
						};
					}, {}),
				},
				loading: false,
			};
		},
		setComparisonDataFail(
			state: SubjectAreaAttainmentProfile.State,
			action: PayloadAction<string>
		) {
			const { payload } = action;

			return {
				...state,
				error: payload,
				loading: false,
			};
		},
		setPageLoading(state: SubjectAreaAttainmentProfile.State, action: PayloadAction<boolean>) {
			const { payload } = action;

			return {
				...state,
				loading: payload,
			};
		},
		toggleBetweenCountAndPercentage(state: SubjectAreaAttainmentProfile.State) {
			return {
				...state,
				showStudentCount: !state.showStudentCount,
			};
		},
		setComparisonGradepoint(
			state: SubjectAreaAttainmentProfile.State,
			action: PayloadAction<string | undefined>
		) {
			return {
				...state,
				comparisonGradepoint: action.payload,
			};
		},
		setSagaFlowStatus(state: SubjectAreaAttainmentProfile.State, action: PayloadAction<boolean>) {
			return {
				...state,
				overallSagaFailure: action.payload,
			};
		},
	},
});

/**
 * Actions
 */
export const {
	setMainGridDataSuccess,
	setMainGraphDataSuccess,
	setPageLoading,
	setMainGridDataFail,
	setMainGraphDataFail,
	setAllComparisonDataSuccess,
	setAllRowComparisonIds,
	setComparisonDataFail,
	toggleBetweenCountAndPercentage,
	setComparisonGradepoint,
	setSagaFlowStatus,
} = subjectAreaAttainmentProfile.actions;

export default subjectAreaAttainmentProfile.reducer;
