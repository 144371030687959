import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, Icon } from 'basecamp';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

type Props = {
	params: ICellRendererParams;
	icon: string;
};

/**
 ** THIS IS ONLY ROUGH FOR THE DEMO
 * We need access to the core / utils
 */

const ComparisonArrowRenderer: FunctionComponent<Props> = ({ params, icon }) => {
	const blueBoundry = params.value >= 1 && params.value <= 3;
	const redBoundry = params.value >= 7 && params.value <= 9;

	const handleGradeStyle = {
		bg: redBoundry ? 'thermometer.tBlue.2' : blueBoundry ? 'thermometer.tRed.2' : 'transparent',
		color: redBoundry ? 'thermometer.tBlue.8' : blueBoundry ? 'thermometer.tRed.8' : 'UI.black',
	};

	return (
		<Flex
			setAs="row"
			withAlign="center"
			height="100%"
			bg={handleGradeStyle.bg}
			color={handleGradeStyle.color}
			px="17px"
		>
			{params.value}

			<Flex bg={handleGradeStyle.bg} color={handleGradeStyle.color} ml={1}>
				<Icon icon={icon as any} prefix="fas" />
			</Flex>
		</Flex>
	);
};

export default ComparisonArrowRenderer;
