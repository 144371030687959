import moment from 'moment';

// Academic Year reference
export const academicYears: { [key: string]: number } = {
	y2000: 2000,
	y2001: 2001,
	y2002: 2002,
	y2003: 2003,
	y2004: 2004,
	y2005: 2005,
	y2006: 2006,
	y2007: 2007,
	y2008: 2008,
	y2009: 2009,
	y2010: 2010,
	y2011: 2011,
	y2012: 2012,
	y2013: 2013,
	y2014: 2014,
	y2015: 2015,
	y2016: 2016,
	y2017: 2017,
	y2018: 2018,
	y2019: 2019,
	y2020: 2020,
	y2021: 2021,
	y2022: 2022,
	y2023: 2023,
	y2024: 2024,
	y2025: 2025,
	y2026: 2026,
	y2027: 2027,
	y2028: 2028,
	y2029: 2029,
	y2030: 2030,
};

const resultsDay: { [key: string]: string } = {
	y2017: '2018-08-16',
	y2018: '2019-08-15',
	y2019: '2020-04-13',
	y2020: '2021-08-12',
};

const yearStartDate = '08-01';
const yearEndDate = '07-31';

/**
 * return the short display name for the Academic Year
 * returns string YY/YY
 * @param {number} year
 * @returns {string} Academic Year 'YY/YY'
 */
export function academicYearShortName(year: number): String {
	return `${year.toString().substring(2)}/${(year + 1).toString().substring(2)}`;
}

/**
 * return the long display name for the Academic Year
 * return string YYYY/YYYY
 * @param {number} year
 * @returns {string} Academic Year 'YYYY/YYYY'
 */
export function academicYearLongName(year: number): String {
	return `${year}/${year + 1}`;
}

export function examLevelToKeyStage(examLevel: number) {
	if (examLevel === 31) return ' (KS5)';

	return ' (KS4)';
}

/**
 * Returns the standard display name for the Academic Year - eg. 2017/18
 * The function attempts to convert argument to a number if one is not passed.
 * @param {number} year
 * @returns {string} Academic Year 'YYYY/YY'
 */
export function academicYearStandardFormat(year: number): String {
	var academicYear = year;

	return academicYear + '/' + (Number(academicYear) + 1).toString().substring(2);
}

/**
 * Returns the Academic Year as a string
 * @param {number} year
 * @returns {string} Academic Year 'YYYY'
 */
export function academicYearString(year: number): String {
	return (year + 1).toString();
}

/**
 * Returns the start date of the year
 * @param {number} year
 * @returns {moment} Academic Year start date
 */
export function getYearStart(year: number): moment.Moment {
	return moment(moment(`${year}-${yearStartDate} 00:00:00`).format('YYYY-MM-DD'));
}

/**
 * get the End of a MAT Academic year for use in the Monitoring Timeline only
 * @param {number} year
 * @returns {moment} MAT Academic Year end date
 */
export function getMatAcademicEnd(year: number): moment.Moment {
	const first = moment(`${year}-${yearEndDate} 00:00:00`);
	return moment(first.format('YYYY-MM-DD') + ' 00:00:00');
}

/**
 * get the start of MAT contract year
 * @param {number} year
 * @returns {moment} MAT Academic Year start date
 */
export function getMatYearStart(year: number): moment.Moment {
	return moment(`${year}-${yearStartDate} 00:00:00`);
}
/**
 * get the moment end of an Academic Year for MAT monitoring points
 * @param {number} year
 * @returns {moment} MAT Academic Year end date
 */
export function getMatYearEnd(year: number): moment.Moment {
	return moment(getMatAcademicEnd(year + 1).format('YYYY-MM-DD') + ' 23:59:59.99');
}

/**
 * get the moment end of an Academic Year
 * @param {number} year
 * @returns {moment} year end date
 */
export function getYearEnd(year: number): moment.Moment {
	return moment(
		getYearStart(year + 1)
			.add(-1, 'days')
			.format('YYYY-MM-DD') + ' 23:59:59.99'
	);
}
/**
 * determine if a given date time is in an Academic Year
 * @param {moment} date to determine is in academic year
 * @param {number} academic year
 * @returns {boolean}
 */
export function isInYear(momentDT: moment.Moment, year: number): boolean {
	return momentDT.isBetween(getYearStart(year), getYearEnd(year), null, '[]');
}

/**
 * return an enumarable array of the Academic Years
 * @returns {array<int>}
 */
export function academicYearsEnumarable(): number[] {
	return Object.keys(academicYears).map((ay) => {
		return academicYears[ay];
	});
}

/**
 * return an enumarable array of the Academic Years
 * @param {moment} academic year to find
 * @returns {number} year
 * @throws {Error} 'no year matching given dateTime'
 */
export function fromDate(momentDT: moment.Moment): number {
	for (let year of academicYearsEnumarable()) {
		if (isInYear(momentDT, year)) {
			return year;
		}
	}
	throw new Error('no year matching given dateTime');
}

/**
 * get the current Academic Year
 * @returns {number} academic year
 */
export function getCurrentAcademicYear(): number {
	return fromDate(moment());
}

/**
 * get an Academic Year from a "YYYY" start string
 * @param {number} academic year to find
 * @returns {number} academic year
 * @throws {Error} 'no such Academic Year'
 */
export function fromStartYearString(year: number): number {
	if (academicYears[`y${year}`]) {
		return academicYears[`y${year}`];
	} else {
		throw new Error('no such Academic Year');
	}
}

/**
 * get Academic Year from a Academic Year short name or long name format
 * @param {string} year in format YYYY/MM/DD
 * @returns {number} academic year
 */
export function fromNameStartYearString(yearString: string): number {
	return fromStartYearString(Number.parseInt(yearString.split('/')[0]));
}

/**
 * create an enumarable within a provided range
 * @param {number} startYear
 * @param {number} lastYear
 * @returns {number[]} academic years
 * @throws {Error} 'firstYear occurs after lastyear'
 * @throws {Error} 'firstYear not in range'
 * @throws {Error} 'lastYear not in range'
 */
export function enumerateYearRange(firstYear: number, lastYear: number): number[] {
	if (firstYear > lastYear) {
		throw new Error('firstYear occurs after lastYear');
	}

	const aye = academicYearsEnumarable();

	if (aye.indexOf(firstYear) === -1) {
		throw new Error('firstYear not in range');
	}
	if (aye.indexOf(lastYear) === -1) {
		throw new Error('lastYear not in range');
	}

	const enumarable = [];
	for (let i = aye.indexOf(firstYear); i < aye.indexOf(lastYear) + 1; i++) {
		enumarable.push(aye[i]);
	}
	return enumarable;
}

/**
 * create an enumerable from the current year back to the supplied number of previous years
 * @param {number} previousYears
 * @param {number?} startYear
 * @returns {number[]} academic years
 */
export function enumerateBackFromCurrentYear(previousYears: number, startYear?: number): number[] {
	const currentYear = getCurrentAcademicYear();
	startYear = !startYear ? currentYear : startYear;
	startYear = startYear - currentYear < 0 ? currentYear : startYear;
	const yearsToEnumerate = startYear - currentYear + previousYears;
	return enumerateYearRange(startYear - yearsToEnumerate, startYear);
}

/**
 * check if a number is a valid Academic Year
 * @param {number} year
 * @returns {boolean}
 */
export function isValidAcademicYear(year: number): boolean {
	const keys = Object.keys(academicYears);
	return year >= academicYears[keys[0]] && year <= academicYears[keys[keys.length - 1]];
}

/**
 * returns the previous year
 * @param {number} year
 * @returns {number} year
 */
export function getPreviousAcademicYear(year: number): number {
	return year - 1;
}

/**
 * Connect specific function
 * The Academic Year runs from 1st August to 31st July - however, we given clients the capability to add
 * data up throughout the month of August
 * At the start of the current Academic Year, we want the Connect dashboard to render data for the previous Academic Year.
 * This prevents users from uploading data for the new Academic Year when they may have meant to upload for the previous
 * Academic Year
 * @returns {number} year
 */
export function showPreviousAcademicYearUntilSeptember(): number {
	const year = getCurrentAcademicYear();

	return moment().isBefore(moment(`${year}-09-01 00:00:00`)) ? getPreviousAcademicYear(year) : year;
}

/**
 * determines if the current date is before exam results day, or a predetermined date before
 * @param {number} year
 * @param {number} daysBefore
 * @returns {boolean}
 */
export function isBeforeResultsDay(year: number, daysBefore: number = 0): boolean {
	if (!resultsDay[`y${year}`]) {
		throw new Error(`${year} has no defined results day`);
	}

	return moment().isBefore(
		// Build the date for the results day, subtracting days as requested
		moment(`${resultsDay[`y${year}`]} 00:00:00`).subtract(daysBefore, 'days')
	);
}
