import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Initial State
 */
const initialState: ManageAccount.State = {
	loading: false,
	changePassword: undefined,
	message: undefined,
};

/**
 * Accounts Slice
 */

const accountsSlice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		updatePasswordPending(state: ManageAccount.State) {
			state.loading = true;
		},
		updatePasswordSuccess(state: ManageAccount.State, action: PayloadAction<ManageAccount.Change>) {
			state.loading = false;
			state.changePassword = action.payload;
		},
		updatePasswordFail(State: ManageAccount.State, action: PayloadAction<ManageAccount.Message>) {
			State.loading = false;
			State.message = action.payload;
		},
	},
});

/**
 * Export Action
 */
export const {
	updatePasswordFail,
	updatePasswordPending,
	updatePasswordSuccess,
} = accountsSlice.actions;

/**
 * Export Selectors
 */
export const isLoading = (state: RootState): boolean => state.accounts.loading;

export default accountsSlice.reducer;
