import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Text } from 'basecamp';
import { setCurrentKeyStage } from 'features/groups/setup/redux';
import { getGroupAccessRecords } from 'features/groups/setup/redux';
import { getSelectedTrend } from 'features/groups/analysis/redux';

import { academicYearStandardFormat } from '../../../../utils/academicYear';
import { calculateSummitKeyStage } from '../../../../utils/summitKeyStage';

const GroupTrendIndicator: FunctionComponent = () => {
	const dispatch = useDispatch();
	/**
	 * Redux
	 */
	const selectedTrend: { groupAccessId: number } = useSelector(getSelectedTrend);
	const accessRecords: { academicYear: number; id: number; examLevel: number }[] = useSelector(
		getGroupAccessRecords
	);

	// Get access from group access id
	const getAccessRecord =
		selectedTrend && accessRecords.find((record) => record.id === selectedTrend.groupAccessId);

	const keyStage = getAccessRecord && calculateSummitKeyStage(getAccessRecord.examLevel);
	const academicYear = getAccessRecord && academicYearStandardFormat(getAccessRecord!.academicYear);

	useEffect(() => {
		if (!localStorage.getItem('keyStageStorage')) {
			dispatch(setCurrentKeyStage({ label: keyStage, value: getAccessRecord?.id }));
		}
	}, [keyStage]);

	/**
	 * Component
	 */
	return (
		<>
			{getAccessRecord && (
				<Flex
					setAs="row"
					withAlign="distribute"
					borderTopRightRadius={99}
					borderBottomRightRadius={99}
					overflow="hidden"
					position="relative"
					left="-20px"
					zIndex={0}
				>
					<Flex withAlign="center" bg="UI.accent.5" flex={1} height="30px" pr={3} pl={4}>
						<Text withColor="white" m={0} ml={2}>
							{keyStage}
						</Text>
					</Flex>
					<Flex withAlign="center" bg="UI.accent.6" flex={1} height="30px" px={3}>
						<Text withColor="white" m={0}>
							{academicYear}
						</Text>
					</Flex>
				</Flex>
			)}
		</>
	);
};

export default GroupTrendIndicator;
