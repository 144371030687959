import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	resetPassword,
	activateClient,
	resendConfirmationEmail,
	resetPasswordConfirm,
	createPassword,
	sendExtendTrial,
} from '../../../api/authAPI';
import { translate } from '../../../utils/locale';
import moment from 'moment';

/**
 ** Initial State
 */
const initialState: Auth.State = {
	loading: false,
	message: undefined,
	activation: undefined,
	passwordResetError: false,
	passwordResetComplete: false,
	trialExtendComplete: false,
};

/**
 ** Auth Slice
 */
const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		clearAuthMessage(state: Auth.State) {
			state.message = undefined;
		},
		setAuthMessage(state: Auth.State, action: PayloadAction<Auth.Message>) {
			state.message = action.payload;
		},
		setActivationDetails(state: Auth.State, action: PayloadAction<Auth.Activation>) {
			state.activation = action.payload;
		},
		setCompleteResetPending(state: Auth.State) {
			return {
				...state,
				loading: true,
				passwordResetError: false,
				passwordResetComplete: false,
			};
		},
		setCompleteResetError(
			state: Auth.State,
			action: PayloadAction<ResponseBuilder<Auth.ForgottenPasswordConfirmResponse>>
		) {
			return {
				...state,
				loading: false,
				passwordResetError: true,
				errorMessage: action.payload.Errors[0].Message,
			};
		},
		setCompleteResetSuccess(state: Auth.State) {
			return {
				...state,
				loading: false,
				passwordResetComplete: true,
			};
		},
		setTrialExtendSuccess(state: Auth.State) {
			return {
				...state,
				trialExtendComplete: true,
			};
		},
	},
});

/**
 ** Export Actions
 */
export const {
	clearAuthMessage,
	setAuthMessage,
	setActivationDetails,
	setCompleteResetPending,
	setCompleteResetError,
	setCompleteResetSuccess,
	setTrialExtendSuccess,
} = authSlice.actions;

/**
 ** Export Selectors
 */
// Is user authenticated
export const getMessage = (context: RootState): Auth.Message | undefined => context.auth.message;

// Get activation details
export const getActivationDetails = (context: RootState): Auth.Activation | undefined =>
	context.auth.activation;

export const getAuthLoading = (context: RootState): boolean => context.auth.loading;

export const getPasswordResetSuccess = (context: RootState): boolean =>
	context.auth.passwordResetComplete;

export const getErrorMessage = (context: RootState): string => context.auth.errorMessage;

export const getPasswordResetErrored = (context: RootState): boolean =>
	context.auth.passwordResetError;

export const getTrialExtend = (context: RootState): boolean => context.auth.trialExtendComplete;

/**`
 ** Export Reducers
 */
export default authSlice.reducer;

/**
 * Export Thunks
 */
/**
 * Thunk that resets the users password and sets the login page message
 * @param {string} emailAddress
 */
export const resetPasswordThunk = (emailAddress: string): AppThunk => async (dispatch) => {
	return resetPassword(emailAddress)
		.then((response) => {
			if (response.HasError) {
				dispatch(
					setAuthMessage({
						value: `${response.Errors[0].Message}, ${translate('auth.login.messages.PLEASE_TRY')}`,
						type: 1,
					} as Auth.Message)
				);
			} else {
				dispatch(
					setAuthMessage({
						value: translate('auth.forgottenPassword.messages.PASSWORD_RESET_MESSAGE', {
							username: response.ResponseObject.EmailAddress,
						}),
						type: 0,
					} as Auth.Message)
				);
			}
		})
		.catch(() => {
			dispatch(
				setAuthMessage({
					value: translate('auth.forgottenPassword.messages.FAILED') as string,
					type: 1,
				} as Auth.Message)
			);
		});
};

/**
 * Activates the client and returns the client/organisation details if successful
 * @param {string} activationCode
 * @param {string} postcode
 */
export const activateClientThunk = (activationCode: string, postcode: string): AppThunk => async (
	dispatch
) => {
	return activateClient(activationCode, postcode).then((response) => {
		if (response.HasError) {
			dispatch(setAuthMessage({ value: response.Errors[0].Message, type: 1 } as Auth.Message));
		} else {
			return response.ResponseObject;
		}
	});
};

/**
 * Send a confirmation email address. If successful set a successful auth message, otherwise set an error auth message
 * @param emailAddress The email address to send the confirmation
 */
export const resendConfirmationEmailThunk = (emailAddress: string): AppThunk => async (
	dispatch
) => {
	resendConfirmationEmail(emailAddress).then((response) => {
		if (response.HasError) {
			dispatch(setAuthMessage({ value: response.Errors[0].Message, type: 1 } as Auth.Message));
		} else {
			dispatch(
				setAuthMessage({
					value: translate('auth.login.messages.CONFIRMATION_EMAIL', {
						emailAddress: response.ResponseObject,
					}),
					type: 0,
				} as Auth.Message)
			);
		}
	});
};

export const completeForgottenPasswordThunk = (
	password: string,
	confirmPassword: string,
	token: string
): AppThunk => async (dispatch) => {
	dispatch(setCompleteResetPending());
	const response = await resetPasswordConfirm(password, confirmPassword, token);

	if (response.HasError) {
		dispatch(setCompleteResetError(response));
	} else {
		dispatch(setCompleteResetSuccess());
	}
};

export const completeCreatePasswordThunk = (
	password: string,
	confirmPassword: string,
	token: string
): AppThunk => async (dispatch) => {
	dispatch(setCompleteResetPending());
	const response = await createPassword(password, confirmPassword, token);

	if (response.HasError) {
		dispatch(setCompleteResetError(response));
	} else {
		dispatch(setCompleteResetSuccess());
	}
};

export const sendExtendTrialThunk = (emailAddress: string): AppThunk => async (dispatch) => {
	sendExtendTrial(emailAddress).then((response) => {
		if (response.HasError) {
			dispatch(setAuthMessage({ value: response.Errors[0].Message, type: 1 } as Auth.Message));
		} else {
			dispatch(
				setAuthMessage({
					value: translate('auth.login.messages.TRIAL_EXTENDED', {
						endDate: moment(response.User.EndDate).format('DD/MM/YYYY'),
					}),
					type: 0,
				} as Auth.Message)
			);
			dispatch(setTrialExtendSuccess());
		}
	});
};
