import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex } from 'basecamp';

const InfoBox: FunctionComponent = (props) => {
	return (
		<Flex
			setAs="column"
			minWidth="400px"
			width="400px"
			bg="UI.secondary.1"
			borderRadius={3}
			p={4}
			ml={4}
			display={['none', 'none', 'none', 'none', 'flex']}
		>
			{props.children}
		</Flex>
	);
};

export default InfoBox;
