import * as React from 'react';
import { FunctionComponent } from 'react';
import { selectSchoolThunk } from 'features/selectOrganisation/redux/switch';
import {
	clearSubjectData,
	resetReportControlHistory,
	setAdHoc,
	setWhatIf,
} from 'features/subjectPage/redux/slice';
import { clearGroupTrend } from 'features/groups/analysis/redux';
import {
	clearAppliedComparisons,
	clearAppliedFilters,
	setUseClientBenchmark,
} from 'features/app/redux/context';
import { useDispatch } from 'react-redux';
import { Flex, Link } from 'basecamp';
import { clearSubscriptionDetails } from 'features/manageAccount/redux';
import { resetPerformanceMeasures } from 'features/performanceMeasures/redux/';
import { clearSubscriptionMessage } from 'features/home/redux';
import { clearSessionData } from 'features/strategicAnalysis/redux';

type Props = {
	linkedSchool: Select.LinkedSchool;
};

const SelectOrganisationRenderer: FunctionComponent<Props> = (props) => {
	const dispatch = useDispatch();
	const school = () => {
		dispatch(clearSessionData());
		dispatch(clearAppliedComparisons());
		dispatch(clearAppliedFilters());
		dispatch(setUseClientBenchmark(false));
		dispatch(resetReportControlHistory());
		dispatch(setAdHoc(false));
		dispatch(setWhatIf(false));
		dispatch(clearSubjectData());
		dispatch(clearGroupTrend());
		dispatch(clearSubscriptionDetails());
		dispatch(resetPerformanceMeasures());
		dispatch(clearSubscriptionMessage());
		localStorage.getItem('summitGp') ? localStorage.removeItem('summitGp') : [];
		localStorage.getItem('keyStageStorage') ? localStorage.removeItem('keyStageStorage') : [];

		dispatch(selectSchoolThunk(props.linkedSchool));
	};

	return (
		<Flex setAs="column" withAlign="center" height="100%">
			<Link
				setAs="button"
				withType="accent"
				withInternalUrl={'/home'}
				withSize="small"
				dataTest="selectSchool"
				onClick={() => school()}
			>
				Select
			</Link>
		</Flex>
	);
};

export default SelectOrganisationRenderer;
