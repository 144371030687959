import { translate } from '../../../../utils/locale';
import { updateLoading, updateErrorMessage, updateSubscriptionDates } from '../slice';

import moment from 'moment';

import { getSelectedSubscriptionDuration } from '../selectors';

import { getSubscriptionDatesAPI } from '../../../../api/subscriptionAPI';

/**
 * Get the start and end date of the new subscription from the API and store them in Redux
 */
export const getSubscriptionDatesThunk = (): AppThunk => async (dispatch, getState) => {
	await dispatch(updateLoading(true));

	const state = getState();
	const duration = getSelectedSubscriptionDuration(state);
	const subscriptionDates = await getSubscriptionDatesAPI(duration ?? 1);

	if (subscriptionDates.HasError) {
		dispatch(
			updateErrorMessage(
				translate('manageAccount.subscriptions.errors.FAILED_TO_FETCH_DATA') as string
			)
		);
	} else {
		dispatch(
			updateSubscriptionDates({
				start: moment.utc(subscriptionDates.ResponseObject.start).locale('en-gb').format('L'),
				end: moment.utc(subscriptionDates.ResponseObject.end).locale('en-gb').format('L'),
			})
		);
	}

	await dispatch(updateLoading(false));
};
