import { ICellRendererParams } from '@ag-grid-community/all-modules';

const defaultRenderer = (params: ICellRendererParams) => {
	// try get the gradepoint key from the column definition
	const [, key, suffix = ''] =
		/gp(.+)(?:Grade|Entries|Score|AvgPA)(.+)?/g.exec(params.colDef.field ?? '') || [];
	// check we have a value
	const hasValue = !!params.value?.toString()?.length ?? false;

	if (key) {
		const grade = params.data[`gp${key}Grade${suffix}`];
		const entries = params.data[`gp${key}Entries${suffix}`];
		const score = params.data[`gp${key}Score${suffix}`];

		if (!entries && !grade && !score) {
			return (hasValue ? params.value : null) ?? '-';
		}
	}
	return (hasValue ? params.value : null) ?? '-';
};

export default defaultRenderer;
