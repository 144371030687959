//import { Object.assign } from 'lodash';
import { theme } from 'basecamp';

/**
 ** Colors
 */
const colors = [
	theme.colors.DATAVIS.connect[0],
	theme.colors.DATAVIS.connect[1],
	theme.colors.DATAVIS.connect[2],
	theme.colors.DATAVIS.connect[3],
	theme.colors.DATAVIS.connect[4],
	theme.colors.DATAVIS.connect[5],
];

const black = theme.colors.UI.black[0];
const grey = theme.colors.UI.secondary[1];

/**
 ** Typography
 */
const sansSerif = theme.fonts.body;
const letterSpacing = 'normal';
const fontSize = 12;

/**
 ** Layout
 */
const baseProps = {
	width: 450,
	height: 300,
	padding: 100,
	colorScale: colors,
};

/**
 ** Labels
 */
const baseLabelStyles = {
	fontFamily: sansSerif,
	fontSize,
	letterSpacing,
	padding: 10,
	fill: black,
	stroke: 'transparent',
};

const centeredLabelStyles = Object.assign({ textAnchor: 'middle' }, baseLabelStyles);

/**
 ** Strokes
 */
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

export default {
	area: Object.assign(
		{
			style: {
				data: {
					fill: black,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	axis: Object.assign(
		{
			style: {
				axis: {
					fill: 'transparent',
					stroke: black,
					strokeWidth: 1,
					strokeLinecap,
					strokeLinejoin,
				},
				axisLabel: Object.assign({}, centeredLabelStyles, {
					padding: 50,
				}),
				grid: {
					fill: 'none',
					stroke: theme.colors.UI.white[0],
					pointerEvents: 'painted',
				},
				ticks: {
					fill: 'transparent',
					size: 5,
					stroke: black,
				},
				tickLabels: baseLabelStyles,
			},
		},
		baseProps
	),
	bar: Object.assign(
		{
			style: {
				data: {
					fill: black,
					padding: 8,
					strokeWidth: 0,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	boxplot: Object.assign(
		{
			style: {
				max: { padding: 8, stroke: black, strokeWidth: 1 },
				maxLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
				median: { padding: 8, stroke: black, strokeWidth: 1 },
				medianLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
				min: { padding: 8, stroke: black, strokeWidth: 1 },
				minLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
				q1: { padding: 8, fill: grey },
				q1Labels: Object.assign({}, baseLabelStyles, { padding: 3 }),
				q3: { padding: 8, fill: grey },
				q3Labels: Object.assign({}, baseLabelStyles, { padding: 3 }),
			},
			boxWidth: 20,
		},
		baseProps
	),
	candlestick: Object.assign(
		{
			style: {
				data: {
					stroke: black,
					strokeWidth: 1,
				},
				labels: Object.assign({}, baseLabelStyles, { padding: 5 }),
			},
			candleColors: {
				positive: '#ffffff',
				negative: black,
			},
		},
		baseProps
	),
	chart: baseProps,
	errorbar: Object.assign(
		{
			borderWidth: 8,
			style: {
				data: {
					fill: 'transparent',
					stroke: black,
					strokeWidth: 2,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	group: Object.assign(
		{
			colorScale: colors,
		},
		baseProps
	),
	histogram: Object.assign(
		{
			style: {
				data: {
					fill: grey,
					stroke: black,
					strokeWidth: 2,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	legend: {
		colorScale: colors,
		gutter: 10,
		orientation: 'vertical',
		titleOrientation: 'top',
		style: {
			data: {
				type: 'circle',
			},
			labels: baseLabelStyles,
			title: Object.assign({}, baseLabelStyles, { padding: 5 }),
		},
	},
	line: Object.assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: black,
					strokeWidth: 3,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	pie: {
		style: {
			data: {
				padding: 10,
				stroke: 'transparent',
				strokeWidth: 1,
			},
			labels: Object.assign({}, baseLabelStyles, { padding: 20 }),
		},
		colorScale: colors,
		width: 400,
		height: 400,
		padding: 50,
	},
	scatter: Object.assign(
		{
			style: {
				data: {
					fill: black,
					stroke: 'transparent',
					strokeWidth: 0,
				},
				labels: baseLabelStyles,
			},
		},
		baseProps
	),
	stack: Object.assign(
		{
			colorScale: colors,
		},
		baseProps
	),
	tooltip: {
		style: Object.assign({}, baseLabelStyles, { padding: 0, pointerEvents: 'none' }),
		flyoutStyle: {
			stroke: black,
			strokeWidth: 1,
			fill: '#f0f0f0',
			pointerEvents: 'none',
		},
		flyoutPadding: 5,
		cornerRadius: 5,
		pointerLength: 10,
	},
	voronoi: Object.assign(
		{
			style: {
				data: {
					fill: 'transparent',
					stroke: 'transparent',
					strokeWidth: 0,
				},
				labels: Object.assign({}, baseLabelStyles, { padding: 5, pointerEvents: 'none' }),
				flyout: {
					stroke: black,
					strokeWidth: 1,
					fill: '#f0f0f0',
					pointerEvents: 'none',
				},
			},
		},
		baseProps
	),
};
