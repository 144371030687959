/**
 * Get the  loading flag
 */
export const isLoading = (state: RootState) => state.homepage.loading;

/**
 * Have we got any errors?
 */
export const getErrorMessage = (state: RootState) => state.homepage.errorMessage;
export const hasError = (state: RootState) => {
	return getErrorMessage(state) !== null;
};

/**
 * Get  all the agreements contracts & subscriptions
 */
export const getAllAgreements = (state: RootState) => state.homepage.agreements;

/**
 * Get all the contracts
 */
export const getAllContracts = (state: RootState) => {
	const agreements = getAllAgreements(state);
	const contracts = agreements?.filter((el: Homepage.Agreement) => !!el.HasContract);

	return contracts;
};

/**
 * Get all the subscriptions
 */
export const getAllSubscriptions = (state: RootState) => {
	const agreements = getAllAgreements(state);
	const subscriptions = agreements?.filter((el: Homepage.Agreement) => !!el.HasSubscription);

	return subscriptions;
};

/**
 * Are we in the agreement window
 */
export const isInSubscriptionWindow = (state: RootState) => {
	const agreements = getAllAgreements(state);
	const isInWindow = agreements?.some((el: Homepage.Agreement) => !!el.CanViewAdminPanel);

	return isInWindow;
};

/**
 * Get all the auto renewing subscriptions
 */
export const getAutoRenewedSubscriptions = (state: RootState) => {
	const agreements = getAllAgreements(state);
	return agreements?.filter((agreement) => {
		return (
			agreement.CanViewAdminPanel &&
			agreement.AutoRenewEnabled &&
			agreement.RemainingDays <= 90 &&
			agreement.RemainingDays >= 0
		);
	});
};

/**
 * Get cancelled auto renewing subscriptions
 */
export const getNoAutoRenewedSubscriptions = (state: RootState) => {
	const agreements = getAllAgreements(state);
	return agreements?.filter((agreement) => {
		return (
			agreement.CanViewAdminPanel &&
			!agreement.AutoRenewEnabled &&
			agreement.RemainingDays <= 90 &&
			agreement.RemainingDays >= 0
		);
	});
};

export const getEarliestExpiry = (state: RootState) => {
	const autoRenewedSubs = getAutoRenewedSubscriptions(state);
	const noAutoRewenedSubs = getNoAutoRenewedSubscriptions(state);

	const subscriptions = [
		...(autoRenewedSubs ? autoRenewedSubs : []),
		...(noAutoRewenedSubs ? noAutoRewenedSubs : []),
	];
	return subscriptions
		? Math.min(...subscriptions.map((subscription) => subscription.RemainingDays))
		: -1;
};
