import { getMetrics } from '../../../../api/performanceMetricAPI';
import { QueryType } from '../../types/enum';
import { setMetricsFail, setMetricsPending, setMetricsSuccess } from '../';

// list performance metrics
export const listDemMetricsThunk = (
	queryType: QueryType,
	gp: number[] | null,
	metricGroup: string | null,
	metricId: number | null,
	comparisonId: number | null,
	comparisonValueId: number | null
): AppThunk => async (dispatch) => {
	dispatch(setMetricsPending());

	getMetrics(queryType, gp, metricGroup, metricId, comparisonId, comparisonValueId)
		.then((response) => {
			dispatch(setMetricsSuccess(response.ResponseObject));
		})
		.catch(() => {
			dispatch(
				setMetricsFail({
					text: 'Please speak to customer services',
				} as PerformanceMetrics.Message)
			);
		});
};
