import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Switch, useLocation, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	isAuthenticated,
	hasContext,
	getUser,
	getClient,
	getAppliedGradepoints,
	getAppliedFilters,
	getAppliedComparisons,
	getAppliedExamLevels,
	getEmbargo,
	hasGroupAccess,
	getSubscriptionStatus,
	getViewAsSchool,
} from 'features/app/redux/context';
import { apm } from '@elastic/apm-rum';
// import { ApmRoute as Route } from '@elastic/apm-rum-react';
import { getFeature, getLoadingFlags } from 'features/app/redux/features';
import { getLoadingPermissions } from 'features/app/redux/permissions';
import { withinEmbargoAndViewAsSchool } from '../../utils/embargoHelpers';
import { isMATImpostor, isFullImpostor } from '../../utils/impersonation';

const RouteWithSubRoutes = (route: Routes.Route) => {
	/**
	 ** Handle location
	 */
	const location = useLocation();

	/**
	 ** Authenticated flag
	 */
	const authenticated: boolean = useSelector(isAuthenticated);
	const contextLoaded: boolean = useSelector(hasContext);
	const user = useSelector(getUser);
	const client = useSelector(getClient);
	const selectedGp = useSelector(getAppliedGradepoints);
	const comparisons = useSelector(getAppliedComparisons);
	const filters = useSelector(getAppliedFilters);
	const examsLevels = useSelector(getAppliedExamLevels);
	const ff = useSelector(getFeature(route.permissions as keyof Features.Flags));
	const embargoFeature = useSelector(getFeature('embargo_feature'));
	const isLoadingFeatures = useSelector(getLoadingFlags);
	const isLoadingPermissions = useSelector(getLoadingPermissions);
	const applyEmbargo = useSelector(getEmbargo);
	const groupAccess = useSelector(hasGroupAccess);
	const [hasAccess, setHasAccess] = useState<boolean>(!!route.permissions && ff);
	const [returnUrl, setReturnUrl] = useState<string | null>(null);
	const subscriptionStatus = useSelector(getSubscriptionStatus);
	const canViewAsSchool = useSelector(getViewAsSchool);

	const impersonation =
		user && (isMATImpostor(user.impersonationMode) || isFullImpostor(user.impersonationMode));

	const embargoRedirect = withinEmbargoAndViewAsSchool(
		embargoFeature,
		applyEmbargo,
		canViewAsSchool
	);

	useEffect(() => {
		setHasAccess(!!route.permissions && ff);
	}, [ff, isLoadingFeatures, isLoadingPermissions]);

	// Default to private unless explicitly set
	route = {
		...route,
		private: route.private === undefined ? true : route.private,
	};

	useEffect(() => {
		// set the return url if we've tried to land somewhere that isn't a /auth route
		const { pathname = '' } = window.location;
		if (pathname && pathname.indexOf('/auth/') !== 0) {
			setReturnUrl(pathname);
		}
	}, []);

	useEffect(() => {
		if (authenticated && window.dataLayer instanceof Array) {
			window.dataLayer.push({
				event: 'login',
				userId: user?.id, // Current user's account ID
				name: user?.fullname, // current user's first name
				email: user?.username, // Current user's email
				organisationName: client?.name, // organisation name
				jobTitle: user?.jobTitle, // users job title
			});
		}

		if (apm.isActive()) {
			apm.setUserContext({
				id: user?.id,
				username: user?.fullname,
				email: user?.username,
			});

			client &&
				apm.addLabels({
					z_reference: client.reference,
					client_id: client.id.toString(),
					client_name: client.name,
				});

			client?.groupAccessId &&
				apm.addLabels({
					group_access_id: client.groupAccessId.toString(),
				});
		}
	}, [user, authenticated, client]);

	useEffect(() => {
		if (apm.isActive()) {
			apm.setCustomContext({
				appliedExamLevel: examsLevels.map((x) => x.label),
				appliedFilter: filters.map((x) => x.value),
				appliedComparisons: comparisons.map((x) => x.label),
				appliedGradepoints: selectedGp.map((x) => x.name),
			});
		}
	}, [comparisons, filters, selectedGp, examsLevels]);

	const routesToBlock = [
		'Subjects',
		'Student Analysis',
		'Subject Analysis',
		'KS4 Performance Measures',
		'KS5 Performance Measures (A-levels)',
		'International Baccalaureate Performance Measures',
		'Strategic',
		'Monitoring',
		'Monitoring Accuracy',
		!groupAccess ? 'Reports' : '',
		//Summit?
		'Subject Overview',
		'BBPA Analysis',
		'Strategic Analysis',
	];

	const allowedRoutesForInValidSubscriptionOrContract = [
		'Home',
		'Manage Users',
		'Manage Account',
		'View As School/College',
	];
	return (
		<Suspense fallback={route.fallback}>
			<Route
				path={route.path}
				render={(props) => {
					/**
					 ** Handle redirects
					 */
					if (route.redirect) {
						return (
							<Switch>
								<Redirect
									to={{
										pathname: route.redirect,
										state: { referrer: location.pathname },
									}}
								/>
							</Switch>
						);
					} // && withinKs4Embargo || withinKs5Embargo

					/**
					 * Handle redirection for embargo period
					 */ if (embargoFeature && routesToBlock.includes(route.baseName) && embargoRedirect) {
						return <Redirect to={'/home'} />;
					}

					/**
					 ** Handle private routes
					 */
					if (route.private) {
						/*
							Don't do anything if we're still loading the connectctx call, otherwise
							we'll fall into the "not authenticated" route and redirect to the home page
							causing deep links not to work
						 */
						if (!contextLoaded || isLoadingFeatures || isLoadingPermissions) {
							return null;
						}

						/**
						 * If the user is  not authenticated send them to the login screen
						 */
						if (!authenticated) {
							return <Redirect to={`/auth/login${returnUrl ? `?ReturnUrl=${returnUrl}` : ''}`} />;
						}

						/**
						 * Handle redirection for subscription
						 */
						if (
							!subscriptionStatus.hasValidSubscriptionOrContract &&
							!allowedRoutesForInValidSubscriptionOrContract.includes(route.baseName) &&
							!impersonation &&
							user !== undefined &&
							window.sessionStorage.getItem('isImpersonation') !== 'true'
						) {
							return <Redirect to={'/home'} />;
						}

						/**
						 * If we have a route component and
						 * a/ the route does not require permissions
						 * b/ the route requires permissions and the user has access
						 */
						if ((route.component && !route.permissions) || (route.component && hasAccess)) {
							return (
								<route.component {...props} display={route.displayName} routes={route.routes} />
							);
						}

						/**
						 * If the user does not have access to the feature
						 */
						return (
							<Redirect
								to={{ pathname: '/page-not-found', state: { referrer: location.pathname } }}
							/>
						);
					}

					/**
					 ** Handle standard routes
					 */
					return (
						route.component && (
							<route.component {...props} display={route.displayName} routes={route.routes} />
						)
					);
				}}
			/>
		</Suspense>
	);
};

export default RouteWithSubRoutes;
