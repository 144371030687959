import * as React from 'react';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../utils/locale';
import { Modal, Heading, Text } from 'basecamp';

import {
	showStrategicSessionModal,
	clearSessionData,
	getAppliedDefaultExamLevels,
} from 'features/strategicAnalysis/redux';
import levels from '../../utils/examLevel';

const EndStrategicSessionModal: FunctionComponent = () => {
	/**
	 * Redux
	 */
	const dispatch = useDispatch();
	const getExamLevels = useSelector(getAppliedDefaultExamLevels).map((level) => level.value);

	/**
	 * Format the indicator wording
	 */
	const examLevels = getExamLevels.map((examLevel) => {
		return levels[examLevel].display;
	});
	const sessionType = [examLevels.slice(0, -1).join(', '), examLevels.slice(-1)[0]].join(
		examLevels.length < 2 ? '' : ' & '
	);

	return (
		<Modal
			setAs="small"
			close={() => dispatch(showStrategicSessionModal())}
			withActions={[
				{
					title: translate('strategicAnalysis.modal.ACTION') as string,
					type: 'error',
					onClick: () => dispatch(clearSessionData()),
				},
			]}
			andBodyPadY={4}
			dataTest="endStrategicSessionModal"
		>
			<Heading setAs="large" withColor="error" dataTest="endStrategicSessionTitle">
				{translate('strategicAnalysis.modal.HEADING', { sessionType }) as string}
			</Heading>
			<Text mb={3} dataTest="endStrategicSessionModalBody">
				{translate('strategicAnalysis.modal.MESSAGE', { sessionType }) as string}
			</Text>
		</Modal>
	);
};

export default EndStrategicSessionModal;
