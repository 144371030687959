import {
	setSetupErrorMessage,
	setSetupLoading,
	fetchGroupTrends,
	getTrends,
	getDeletedTrends,
} from '../';
import {
	saveEditedTrends,
	saveDeletedTrends,
	saveNewTrends,
} from '../../../../../api/groupsSetupAPI';

export const saveGroupTrends = (): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const existingTrends = getTrends(state);

	// Set loading
	dispatch(setSetupLoading());

	// Newly created trends that need to be created in DB
	const newTrends = existingTrends
		.filter(({ isNew }) => isNew)
		.map(({ isNew, isEdited, isDeleted, ...rest }) => rest);

	// Any existing items to be saved
	const editedTrends = existingTrends
		.filter(({ isEdited, isNew }) => isEdited && !isNew)
		.map(({ isNew, isEdited, isDeleted, ...rest }) => rest);

	// Existing items that have been deleted
	const deletedTrends = getDeletedTrends(state).map(
		({ isNew, isEdited, isDeleted, ...rest }) => rest
	);

	// Make all requests in one go
	const [create, update, remove] = await Promise.all([
		newTrends.length ? await saveNewTrends(newTrends) : null,
		editedTrends.length ? await saveEditedTrends(editedTrends) : null,
		deletedTrends.length ? await saveDeletedTrends(deletedTrends) : null,
	]);

	// Handle errors
	if ((create && create.HasError) || (update && update.HasError) || (remove && remove.HasError)) {
		const errors = [
			...(create ? create.Errors : []),
			...(update ? update.Errors : []),
			...(remove ? remove.Errors : []),
		];

		dispatch(setSetupErrorMessage(errors[0].message));
	} else {
		// Populates trends in setup and analysis state
		dispatch(fetchGroupTrends());
	}
};
