import React from 'react';
import { FunctionComponent } from 'react';
import { Link, Box, Icon } from 'basecamp';

type Props = {
	link: string;
	buttonText: string;
	width?: number;
};

const ExternalLink: FunctionComponent<Props> = ({ link, buttonText, width }) => {
	return (
		<Link
			setAs="button"
			withSize="large"
			withType="accent"
			withExternalUrl={link}
			andTarget="_blank"
			mb={3}
			width={width ?? 240}
		>
			{buttonText}
			<Box fontSize="16px" color="UI.white" ml={3}>
				<Icon icon="circle-caret-right" />
			</Box>
		</Link>
	);
};

export default ExternalLink;
