import * as React from 'react';
import { FunctionComponent } from 'react';

import { Modal, Heading, Text } from 'basecamp';

import { translate } from '../../../utils/locale';

type Props = {
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	result: boolean;
};

const ConfirmationModal: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<Modal
			setAs="small"
			close={() => props.setShowModal(false)}
			withActions={[
				{
					title: translate('manageUsers.modals.generic.OK_ACTION'),
					onClick: () => props.setShowModal(false),
					type: 'accent',
				},
			]}
			andBodyPadY={4}
			dataTest="unSavedChangeModal"
		>
			<Heading
				setAs="large"
				withColor={props.result ? 'success' : 'error'}
				dataTest="unSavedChangeModalTitle"
			>
				{props.result
					? translate('manageUsers.modals.confirmationModal.SUCCESS_TITLE')
					: translate('manageUsers.modals.confirmationModal.ERROR_TITLE')}
			</Heading>
			<Text mb={3} dataTest="unSavedChangeModalBody">
				{props.result
					? translate('manageUsers.modals.confirmationModal.SUCCESS')
					: translate('manageUsers.modals.confirmationModal.ERROR')}
			</Text>
		</Modal>
	);
};

export default ConfirmationModal;
