import * as React from 'react';
import { FunctionComponent } from 'react';

import { translate } from '../../../utils/locale';

import { Flex, Box, Icon, IconButton, Text, Heading } from 'basecamp';

type Props = {
	close: () => void;
};

const RtmNoMessage: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<Flex setAs="column" withAlign="center" dataTest="rtmNoMessageWrap">
			{/* Close */}
			<Flex setAs="row" withAlign="topRight" width={1}>
				<IconButton
					setAs="nav"
					withIcon="xmark"
					withSize="small"
					onClick={() => props.close()}
					dataTest="rtmNoMessageClose"
				/>
			</Flex>
			{/* No message  title and close icon */}
			<Box fontSize="100px" color="white" dataTest="rtmNoMessageIcon">
				<Icon icon="grin" prefix="fad" />
			</Box>
			{/* Message Heading */}
			<Heading setAs="xlarge" withColor="white" mt={2} dataTest="rtmNoMessageHeading">
				{translate('rtm.noMessage.HEADING')}
			</Heading>
			{/* Message */}
			<Text setAs="h6" withColor="white" textAlign="center" dataTest="rtmNoMessageBody">
				{translate('rtm.noMessage.BODY')}
			</Text>
		</Flex>
	);
};

export default RtmNoMessage;
