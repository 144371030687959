import { Context } from 'features/app/types';
import { setMetricsPending, setMetricsSuccess, setMetricsFailure } from '..';
import { getPerformanceMeasures } from '../../../../api/ks5PerformanceMeasuresAPI';
import { PerformanceMeasuresTypes } from '../../../../types/enum';

export const getIBMeasuresThunk = (GradePoints: Context.Gradepoint[]): AppThunk => async (
	dispatch
) => {
	dispatch(setMetricsPending());

	try {
		const response = await getPerformanceMeasures(GradePoints, PerformanceMeasuresTypes.IB);
		const data = response.responseObject;

		if (Object.entries(data.grids?.allIds).length !== 0) dispatch(setMetricsSuccess(data));
		else dispatch(setMetricsFailure(`There is no data to display.`));
	} catch (ex) {
		dispatch(
			setMetricsFailure(`The following error occurred whilst fetching the overview data - ${ex}`)
		);
	}
};
