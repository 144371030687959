import { translate } from '../../../../utils/locale';
import {
	updateLoading,
	updateCurrentSubscriptions,
	setExistingReports,
	updateErrorMessage,
} from '../slice';

import {
	getCurrentSubscriptionAgreementsAPI,
	getCurrentReportsAPI,
} from '../../../../api/subscriptionAPI';

/**
 * Get the current subscription agreements from the API and store them in Redux
 * Agreements cover both normal subscriptions and contracts
 */
export const getCurrentSubscriptionAgreementThunk = (): AppThunk => async (dispatch) => {
	await dispatch(updateLoading(true));

	const [currentAgreements] = await Promise.all([getCurrentSubscriptionAgreementsAPI()]);

	if (currentAgreements.HasError) {
		dispatch(
			updateErrorMessage(
				translate('manageAccount.subscriptions.errors.FAILED_TO_FETCH_DATA') as string
			)
		);
	} else {
		dispatch(updateCurrentSubscriptions(currentAgreements.ResponseObject));
	}

	await dispatch(updateLoading(false));
};
