import {
	setSetupLoading,
	setSetupErrorMessage,
	setSchoolGroups,
	addSchoolGroup,
	deleteSchoolGroup,
} from '..';
import {
	getSchoolGroups as getSchoolGroupsAPI,
	addSchoolGroups as addSchoolGroupsAPI,
	updateSchoolGroups as updateSchoolGroupsAPI,
	deleteSchoolGroups as deleteSchoolGroupsAPI,
} from '../../../../../api/groupsSetupAPI';
import { getSchoolGroups as getSchoolGroupsSelector } from '../selectors';

/**
 * Fetch school groups and store them in Redux
 */
export const fetchSchoolGroupsThunk = (): AppThunk => async (dispatch) => {
	dispatch(setSetupLoading());

	try {
		const responseBuilder = await getSchoolGroupsAPI();

		// Handle response builder errors
		if (responseBuilder.HasError) {
			dispatch(setSetupErrorMessage(responseBuilder.Errors[0].Message));
			return;
		}

		// Map api response to a structure suitable for Redux
		const schoolGroups: Array<Groups.Setup.SchoolGroup> = responseBuilder.ResponseObject.map(
			(x: Groups.Setup.SchoolGroup) => {
				return {
					...x,
					isNew: false,
					isEdited: false,
					isDeleted: false,
				} as Groups.Setup.SchoolGroup;
			}
		);

		dispatch(setSchoolGroups(schoolGroups));
	} catch (err) {
		dispatch(setSetupErrorMessage(err.message));
	}
};

/**
 * Add or update a school group in Redux
 * @param schoolGroup The school group to create
 */
export const addSchoolGroupThunk = (schoolGroup: Groups.Setup.SchoolGroup): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();

	const existing = getSchoolGroupsSelector(state)?.find(({ id }) => id === schoolGroup.id);

	const newSchoolGroup: Groups.Setup.SchoolGroup = {
		...schoolGroup,
		isNew: existing ? existing.isNew || false : true,
		isEdited: true,
		isDeleted: false,
	};

	dispatch(addSchoolGroup(newSchoolGroup));
};

/**
 * Mark a school group as deleted in Redux
 * @param schoolGroup The school group to delete
 */
export const deleteSchoolGroupThunk = (schoolGroup: Groups.Setup.SchoolGroup): AppThunk => async (
	dispatch
) => {
	dispatch(
		deleteSchoolGroup({
			...schoolGroup,
			isDeleted: true,
		})
	);
};

/**
 * Thunk that will save all school group changes in one go.
 * Including new school groups, updated school groups and deleted school groups
 */
export const SaveSchoolGroupsChangesThunk = (): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const schoollGroups = getSchoolGroupsSelector(state);

	// Newly created groups that need to be created
	const newSchoolGroups: Array<Groups.Setup.SchoolGroup> | undefined =
		schoollGroups?.filter((x) => x.isNew && !x.isDeleted) ?? [];

	// Any existing items to be saved
	const existingSchoolGroups: Array<Groups.Setup.SchoolGroup> | undefined =
		schoollGroups?.filter((x) => x.isEdited && !x.isDeleted && !x.isNew) ?? [];

	// Items where lock status has changed
	const deletedSchoolGroups: Array<Groups.Setup.SchoolGroup> | undefined =
		schoollGroups?.filter((x) => x.isDeleted) ?? [];

	// Make all requests in one go
	const [create, update, remove] = await Promise.all([
		newSchoolGroups.length ? addSchoolGroupsAPI(newSchoolGroups) : null,

		existingSchoolGroups.length ? updateSchoolGroupsAPI(existingSchoolGroups) : null,

		deletedSchoolGroups.length ? deleteSchoolGroupsAPI(deletedSchoolGroups.map((x) => x.id)) : null,
	]);

	if ((create && create.HasError) || (update && update.HasError) || (remove && remove.HasError)) {
		const errors = [
			...(create ? create.Errors : []),
			...(update ? update.Errors : []),
			...(remove ? remove.Errors : []),
		];

		dispatch(setSetupErrorMessage(errors[0].Message));
	} else {
		dispatch(fetchSchoolGroupsThunk());
	}
};
