import { setAnalysisLoading, setAnalysisErrorMessage, setAnalysisStrategicData } from '../';
import { getStrategicData } from '../../../../../api/groupsAPI';
import { getSelectedTrend } from '../selectors';
import { addOrdinalToGradePoints } from '../../../../../utils/groups';
import { translate } from '../../../../../utils/locale';

/**
 * Fetch Group Data
 */
export const fetchStrategicData = (examLevel: number): AppThunk => async (dispatch, getState) => {
	dispatch(setAnalysisLoading('AnalysisStrategicData'));

	try {
		const selectedTrend = getSelectedTrend(getState());
		const selectedTrendGradePoints = [...selectedTrend?.gradepoints] ?? [];
		const groupRes = await getStrategicData(examLevel);
		const responseObject = groupRes.responseObject;

		responseObject.gradepoints = addOrdinalToGradePoints(
			responseObject.gradepoints,
			selectedTrendGradePoints
		);

		dispatch(setAnalysisStrategicData(responseObject));
	} catch (err: any) {
		dispatch(
			setAnalysisErrorMessage(err?.message ?? translate('groups.errors.FAILED_TO_FETCH_DATA'))
		);
	}
};
