import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import { getAvailableAuthenticationTypesFlow } from './getAvailableAuthenticationTypesSaga';
import { getUserConfigurationFlow } from './getUserAuthenticationConfigSaga';
import { getConnectPage } from '../../../../utils/sagaHelpers';
import { getIsMfaEnabled, updateIsMfaActive } from 'features/app/redux/context';

/**
 * Saga that fetches sets up the mfa page
 */
export function* mfaSetFlow(): SagaIterator<void> {
	//Find the connect page we are on
	const connectPage = yield call(getConnectPage);

	// Set mfa page loading
	yield put({
		type: `${connectPage}/setLoading`,
		payload: true,
	});

	//User has login in using mfa
	const mfaLoginSetUp = yield select(getIsMfaEnabled);

	//Update state with users desired MFA status
	yield put(updateIsMfaActive(mfaLoginSetUp));

	// Fetch the list of available authentication types
	yield call(getAvailableAuthenticationTypesFlow, connectPage);

	// Fetch current users authentication configuration values
	yield call(getUserConfigurationFlow, connectPage);

	//for belt and braces reset the state of the saga flows
	yield put({
		type: `${connectPage}/setCancelFlow`,
		payload: false,
	});

	//set loading
	yield put({
		type: `${connectPage}/setLoading`,
		payload: false,
	});
}
