import * as React from 'react';
import { FunctionComponent, Fragment, createContext, useState, useEffect } from 'react';

import PrimaryNavigation from 'features/primaryNavigation';
import { PageWithoutSidebar } from 'features/layouts';
import { ContentBlock } from 'basecamp';
import { FilterBarOverview, FilterBarPM, FilterBarP8 } from './components';

import { fetchStudentViewGroupsThunk, fetchPriorAttainmentTypesThunk } from './redux';
import {
	getAppliedStudentFilters,
	setAppliedComparisons,
	setAppliedFilters,
	getAppliedFilters,
	getAppliedComparisons,
	getAppliedGradepointKeys,
} from '../app/redux/context';

import { getAppliedStudentComparisons } from '../../utils/selectors/comparisonSelectors';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import GetRoutes from '../../routes';
import { withTransaction } from '@elastic/apm-rum-react';

type Props = {
	routes: Routes.Route[];
};

// @ts-ignore
export const SPOContext = createContext<SPO.Context>();

const SPO: FunctionComponent<Props> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	/**
	 ** Handle State
	 */
	const [search, setSearch] = useState<string>('');
	const [showCAT4, setShowCAT4] = useState<boolean>(false);
	const [comparisonGp, setComparisonGp] = useState<SPO.Option[]>([]);
	const [focusOn, setFocusOn] = useState<SPO.Option | undefined>(undefined);
	const [togglePts, setTogglePts] = useState<boolean>(false);
	const [showStatusToggle, setShowStatusToggle] = useState<boolean>(true);

	const filters = useSelector(getAppliedStudentFilters);
	const comparisons = useSelector(getAppliedStudentComparisons);

	const { tutorGroup, studentPage } = useParams<{
		tutorGroup: string | undefined;
		studentPage: string | undefined;
	}>();
	const setTutorGroup = (value: string | undefined) => {
		const route = value ? `/students/tutor-group/${encodeURIComponent(value)}` : '/students';
		history.push(route);
	};

	/**
	 * Pass state values via context
	 * Values are available to all page components
	 */
	const SPOContextValues: SPO.Context = {
		search,
		setSearch,
		showCAT4,
		setShowCAT4,
		showStatusToggle,
		setShowStatusToggle,
		togglePts,
		setTogglePts,
		filters,
		setFilters: setAppliedFilters,
		comparisons,
		setComparisons: setAppliedComparisons,
		comparisonGp,
		setComparisonGp,
		focusOn,
		setFocusOn,
		tutorGroup,
		setTutorGroup,
	};

	const allFilters = useSelector(getAppliedFilters);
	const allComparisons = useSelector(getAppliedComparisons);
	const gradepoints = useSelector(getAppliedGradepointKeys);

	/**
	 * Handle lifecycle updates
	 */

	useEffect(() => {
		dispatch(fetchPriorAttainmentTypesThunk());
	}, []);

	useEffect(() => {
		dispatch(
			fetchStudentViewGroupsThunk({
				tutorGroup: tutorGroup ? decodeURIComponent(tutorGroup) : undefined,
			})
		);
	}, [allComparisons, gradepoints, allFilters, tutorGroup]);

	/**
	 * Render filter bar
	 */
	const renderFilterBar = () => {
		switch (studentPage) {
			case 'performance-measures':
				return <FilterBarPM />;
			case 'progress8':
				return <FilterBarP8 />;
			default:
				return <FilterBarOverview />;
		}
	};

	/**
	 ** Render Component
	 */
	return (
		<Fragment>
			{/* Top Content */}
			<ContentBlock py={1} dataTest="spoPrimaryNav">
				<PrimaryNavigation />
			</ContentBlock>

			{/* Page Layout */}
			<SPOContext.Provider value={SPOContextValues}>
				<PageWithoutSidebar dataTest="spo" filterBar={renderFilterBar()}>
					<GetRoutes routes={props.routes} />
				</PageWithoutSidebar>
			</SPOContext.Provider>
		</Fragment>
	);
};

export default withTransaction('Student Performance Overview', 'component')(SPO);
