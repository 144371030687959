/**
 * Loading selectors
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { getSlice } from './slice';

/**
 * Gets the loading status for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns Boolean
 */
export const getLoading = (page: StrategicAnalysis.AnalysisPage) => (state: RootState): boolean => {
	const slice = getSlice(page)(state);

	return slice.loading;
};

/**
 * Are any areas of strategic loading? Add to this as we go
 * TODO - see if we can handle this better with extraReducers
 * @param state Redux state
 * @returns Boolean
 */
export const isLoading = (state: RootState): boolean => {
	const loading = [state.strategicAnalysisOverview.loading];
	return loading.some((area) => !!area);
};

