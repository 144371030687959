import { Context } from 'features/app/types';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';

export enum MfaType {
	ONE_TIME_PASSWORD = 1,
	EMAIL_VALIDATION = 2,
}

/**
 * Generate the MFA config values - which is last, initial status etc
 * @param availableConfigs DB defined authentication methods
 * @param userConfigs User values of authentication methods
 * @returns
 */
export const mfaConfigs = (
	availableConfigs: MultiFactorAuthentication.AvailableMfaResponse[],
	userConfigs: MultiFactorAuthentication.UserDataMfaResponse[]
): Auth.AuthenticationMethod[] => {
	return availableConfigs.map((config) => {
		const isEnabled =
			userConfigs && userConfigs.length > 0
				? userConfigs.some((user) => user.mfaTypeId === config.id)
				: false;

		return {
			id: config.id,
			display: mfaFullTypeNames[config.id],
			status: isEnabled ? 1 : 3,
			isCurrentMethod: false,
			isLastMethod: config.id === 2,
		};
	});
};

/**
 * Generate the Mfa Configuration for login
 * @param userConfigs User defined mfa values
 * @param current current step in the mfa validation type
 * @returns
 */
export const mfaAuthenticationConfigs = (
	userConfigs: Context.MfaList[],
	current: MfaType
): Auth.AuthenticationMethod[] => {
	return (
		userConfigs &&
		userConfigs.map((config) => {
			return {
				id: config.mfaTypeId,
				display: mfaFullTypeNames[config.mfaTypeId],
				status: current === config.mfaTypeId ? 0 : config.isValidated ? 1 : 3,
				isCurrentMethod: current === config.mfaTypeId,
				isLastMethod: config.mfaTypeId === userConfigs[userConfigs.length - 1].mfaTypeId,
			};
		})
	);
};

const mfaFullTypeNames = {
	[1]: 'One Time Password (Authenticator app)',
	[2]: 'Email',
};

// Icons to represent each authentication method
const getIcon = {
	1: 'key',
	2: 'envelope',
};

/**
 * Gets the status of the menu item
 * 0: current
 * 1: success
 * 2: error
 * 3: future
 */
export const getAuthMethodStyling = (
	status: Auth.AuthenticationStatus,
	id: Auth.AuthenticationId
): Auth.AuthenticationMethodStyle => {
	switch (status) {
		case 0:
			return {
				bg: 'UI.white',
				color: 'black',
				iconBg: 'UI.accent.0',
				iconColor: 'UI.white',
				icon: getIcon[id],
				switch: 'accent',
			};
		case 1:
			return {
				bg: 'status.success.3',
				color: 'white',
				iconBg: 'status.success.0',
				iconColor: 'UI.white',
				icon: 'check',
				switch: 'success',
			};
		case 2:
			return {
				bg: 'status.error.1',
				color: 'UI.secondary.3',
				iconBg: 'status.error.0',
				iconColor: 'UI.white',
				icon: 'exclamation',
				switch: 'error',
			};
		default:
			return {
				bg: 'UI.secondary.1',
				color: 'UI.secondary.3',
				iconBg: 'UI.secondary.3',
				iconColor: 'UI.white',
				icon: getIcon[id],
				switch: 'secondary',
			};
	}
};
