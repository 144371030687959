import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { activateMfaTypeFlow } from './activateMfaTypeSaga';
import { MfaType } from '../../../../utils/mfa';

/**
 * Saga that that will send/resend email to user
 */
export function* resendEmailFlow(): SagaIterator<void> {
	//Resend email for mfa account set up
	yield call(activateMfaTypeFlow, MfaType.EMAIL_VALIDATION, true, 'EmailAuthentication');
}
