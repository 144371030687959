export function totalExpectedPoints(
	targetSet: SubjectPage.TargetSet[],
	studentGroup: SubjectPage.Student[],
	whatIf: boolean = false
): number {
	return studentGroup.reduce((acc, curr) => {
		if (!targetSet) {
			return acc;
		}

		const matchTarget = targetSet.find((tb) => tb.Id === curr.TargetBandId);

		/**
		 * Users can now move from a 'Q' grade to other grades
		 * if this is the case we no longer filter out the 'Q' grades
		 * if whatIf is toggled.
		 */
		if (!whatIf && curr.IsIncluded && !curr.IsQGrade && matchTarget) {
			return (acc += matchTarget.Target);
		}

		/**
		 * At the point 'whatIf' is toggled no visual changes should be displayed on the thermometer or grade calcs
		 * This means if the student has a 'Q' grade, we must do a further check against
		 * the selected 'whatIf value'. Only when the 'whatIf value'is
		 * something other than a 'Q' grade should further calculations be run
		 */
		if (!!whatIf && curr.IsIncluded && curr.WhatIfOutcome !== 'Q' && matchTarget) {
			return (acc += matchTarget.Target);
		}

		return acc;
	}, 0);
}
