import * as React from 'react';
import { FunctionComponent } from 'react';
import { updateDefaultClient } from 'features/selectOrganisation/redux/switch';
import { useDispatch } from 'react-redux';
import { Checkbox, Flex } from 'basecamp';

type Props = {
	linkedSchool: Select.LinkedSchool;
};

const SwitchOrganisationRenderer: FunctionComponent<Props> = (props) => {
	//TODO: change type from any
	const dispatch = useDispatch();
	const toggleSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			dispatch(updateDefaultClient(props.linkedSchool));
		}
	};

	return (
		<Flex setAs="left" flex={1} withAlign="left">
			<Flex mt="10px" mb="12px">
				<Checkbox onChange={toggleSelected} checked={props.linkedSchool.isDefault} />
			</Flex>
		</Flex>
	);
};

export default SwitchOrganisationRenderer;
