import { getGroupAccessId, getTrendAccessId } from 'features/app/redux/context';
import { store } from '../store/store';

//Used to pass in the groupAccessId into the headers
export const withGroupAccessOptions = <T, TArgs extends any[]>(
	func: (options: any, ...args: TArgs) => T
) => {
	return (...args: TArgs) => {
		const state = store.getState();
		const accessId = getGroupAccessId(state);
		//Used in analysis set up for trend selectors;
		const trendAccessId = getTrendAccessId(state);

		const groupAccessId = trendAccessId === undefined ? accessId : trendAccessId;
		return func(
			{
				headers: {
					groupAccessId,
				},
			},
			...args
		);
	};
};
