import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box } from 'basecamp';

import { useAgGrid } from '../../../agGrid/hooks';
import { createDefaultGridOptions } from '../../../agGrid';

import { AgGridReact } from '@ag-grid-community/react';
import { AgGridReactProps } from '@ag-grid-community/react';

import { translate } from '../../../utils/locale';
import { getPriorAttainmentTypes } from '../../spo/redux';
import {
	adjustPriorAttainmentPrecision,
	getPriorAttainmentPrecision,
} from '../../../utils/priorAttainment';

import { getStudentPriorAch } from '../redux/';
import { getFeature } from 'features/app/redux/features';

type Props = {
	studentInformation: {
		studentId?: string;
		gender?: string;
		dob?: string;
		ethnicity?: string;
		tutorGroup?: string;
		disadvantaged?: string;
		eal?: string;
		send?: string;
	};
};

const StudentModalInformation: FunctionComponent<Props> = ({ studentInformation }) => {
	/**
	 ** Handle  Ag Grid Api
	 */
	const gridOptions = useAgGrid();

	/**
	 * Get students PA
	 */
	const priorAch = useSelector(getStudentPriorAch);
	const priorAttainmentTypes = useSelector(getPriorAttainmentTypes);
	const paDisplayFeature = useSelector(getFeature('pa_display_precision'));

	// handle prior achievement types
	var paInfo = new Object();
	const pa = priorAch.length
		? priorAch.reduce((acc, curr: StudentModal.PriorAchievement, i: number) => {
				const commaSeparate = priorAch.length > 1 && i === 0;

				const precision = getPriorAttainmentPrecision(curr.PAType, priorAttainmentTypes);
				const score =
					paDisplayFeature && curr.PAType != null
						? adjustPriorAttainmentPrecision(curr.Score, precision)
						: curr.Score;
				paInfo = {
					paDesc: `${curr.Type} - ${score}${commaSeparate ? ', ' : ''}`,
					paType: curr.PAType,
				};

				return paInfo;
		  }, {})
		: (paInfo = { paDesc: '-', paType: 0 });

	/**
	 ** Get student Information
	 */
	const {
		studentId,
		gender,
		dob,
		ethnicity,
		tutorGroup,
		disadvantaged,
		eal,
		send,
	} = studentInformation;

	/**
	 ** Handle  lifecycle updates
	 */
	// Resize columns on mount
	useEffect(() => {
		gridOptions.api?.sizeColumnsToFit();
	}, [gridOptions.api, gridOptions.columnApi]);

	/**
	 ** Define  column defs
	 */
	const columnDefs = [
		{
			headerName: translate('subjectPage.studentModal.colDefs.STUDENT_ID') as string,
			field: 'studentId',
			suppressSizeToFit: true,
			pinned: true,
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.GENDER') as string,
			field: 'gender',
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.DOB') as string,
			field: 'dob',
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.ETHNICITY') as string,
			field: 'ethnicity',
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.PA') as string,
			field: 'pa.paDesc',
			tooltipField: 'pa.paType',
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.TUTOR_GROUP') as string,
			field: 'tutorGroup',
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.DISADVANTAGED') as string,
			field: 'disadvantaged',
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.EAL') as string,
			field: 'eal',
		},
		{
			headerName: translate('subjectPage.studentModal.colDefs.SEND') as string,
			field: 'send',
		},
	];

	/**
	 ** Default grid options
	 */
	const defaultGridOptions: AgGridReactProps = createDefaultGridOptions({
		defaultColDef: {
			suppressMenu: true,
		},
		sideBar: false,
		domLayout: 'default',
		tooltipShowDelay: 500,
	});

	/**
	 ** Render Component
	 */
	return (
		<Box className="ag-theme-alps" height="93px" mb={4}>
			<AgGridReact
				{...defaultGridOptions}
				onGridReady={(params) => gridOptions.onGridReady(params)}
				columnDefs={columnDefs}
				rowData={[
					{
						studentId,
						gender,
						dob,
						pa,
						ethnicity,
						tutorGroup,
						disadvantaged,
						eal,
						send,
					},
				]}
			/>
		</Box>
	);
};

export default StudentModalInformation;
