// Round a floating point number in the standard manner (useful for scores etc)
// Pass optional param of precision
export function round(toRound: number, precision: number = 2): number {
	const roundingFactor = Math.pow(10, precision);
	return Math.round(toRound * roundingFactor) / roundingFactor;
}

/**
 * Check if number is integer
 * @param  {number}  n Number to test
 * @return {Boolean} True if integer, false otherwise
 */
export function isInteger(n: number): boolean {
	return typeof n === 'number' && isFinite(n) && Math.floor(n) === n;
}
