import fetch from './';
import { QueryType } from '../utils/performanceMeasures';

export const getComparisons = (): Promise<PerformanceMetrics.Comparison[]> => {
	return fetch<YetiAPI.KS4Performance.Comparison[]>(
		'/api/KS4Performance/Comparisons',
		{},
		true
	).then((response) => {
		return response.ResponseObject.map((comparison) => ({
			comparisonId: comparison.ComparisonId,
			comparisonDisplay: comparison.ComparisonName,
			comparisonValueId: comparison.ComparisonValueId,
			comparisonValueDisplay: comparison.ComparisonDisplay,
		}));
	});
};

export const getMetrics = (
	queryType: QueryType = QueryType.STUDENT_DATA,
	gradepointId: number[] | null = null,
	metricGroup: string | null = null,
	metricId: number | null = null,
	comparisonId: number | null = null,
	comparisonValueId: number | null = null
): Promise<any> => {
	return fetch(`/api/KS4Performance/KS4Metrics`, {
		body: {
			queryType,
			gradepointId,
			metricGroup,
			metricId,
			comparisonId,
			comparisonValueId,
		},
	}).then((response) => {
		return {
			ResponseObject: response.ResponseObject.map((x: any) => {
				return {
					acYear: x.AcYear,
					comparisonId: x.ComparisonId,
					comparisonName: x.ComparisonName,
					comparisonValue: x.ComparisonValue,
					comparisonValueId: x.ComparisonValueId,
					comparisonDisplay: x.ComparisonDisplay,
					datasetId: x.DatasetId,
					gradepointId: x.GradepointId,
					ks4PriorAttainment: x.KS4PriorAttainment,
					priorAttainmentDisplay: x.PriorAttainmentDisplay,
					metricDisplayname: x.MetricDisplayname,
					metricGroup: x.MetricGroup,
					metricId: x.MetricId,
					metricValue: x.MetricValue,
					studentEntryId: x.StudentEntryId,
					studentName: x.StudentName,
					studentExternalId: x.ExternalId,
					metricType: x.MetricType,
					metricPercentage: x.MetricPercentage,
					dateCreated: x.DateCreated,
				} as PerformanceMetrics.Metrics;
			}),
		};
	});
};

export const getProgress8Measures = (
	gradepointId: number[],
	queryType: QueryType = QueryType.STUDENT_DATA,
	metricGroup: string | null = null,
	metricId: number | null = null,
	comparisons: { ComparisonId: number | null; ComparisonValueId: number[] }[] = [],
	isSpo: boolean = false
): Promise<YetiAPI.KS4Performance.Progress8Metric[]> => {
	return fetch<YetiAPI.KS4Performance.Progress8Metric[]>(
		`/api/KS4Performance/Progress8`,
		{
			body: {
				QueryType: queryType,
				GradepointId: gradepointId,
				MetricGroup: metricGroup,
				MetricId: metricId,
				Comparisons: comparisons,
				IsSpo: isSpo,
			},
		},
		true
	).then((response) => {
		if (response.HasError) {
			throw new Error(response.Errors[0]?.Message);
		}

		return response.ResponseObject;
	});
};

export const getProgress8MeasuresSpo = (
	gradepointId: number[]
): Promise<ResponseBuilder<SPO.Progress8Metrics[]>> => {
	return fetch<SPO.Progress8Metrics[]>(
		`/api/KS4Performance/Progress8Spo`,
		{
			body: {
				GradepointId: gradepointId,
			},
		},
		true
	).then((response) => {
		return {
			ResponseObject: response.ResponseObject.map((x: any) => {
				return {
					comparisonId: x.ComparisonId,
					comparisonName: x.ComparisonName,
					comparisonValue: x.ComparisonValue,
					comparisonValueId: x.ComparisonValueId,
					comparisonDisplay: x.ComparisonDisplay,
					gradepointId: x.GradepointId,
					ks4PriorAttainment: x.KS4PriorAttainment,
					ks4PriorAttainmentDisplay: x.KS4PriorAttainmentDisplay,
					metricDisplayName: x.MetricDisplayName,
					metricValue: x.MetricValue,
					metricValueDisplay: x.MetricValueDisplay,
					studentEntryId: x.StudentEntryId,
					studentName: x.StudentName,
					studentExternalId: x.ExternalId,
				};
			}),
			HasError: response.HasError,
			HasResponse: response.HasResponse,
			Errors: response.Errors,
		};
	});
};
