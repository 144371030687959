import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community';

export default forwardRef<IFilterComp, IFilterParams>((props, ref) => {
	const [qualTypes, setQualTypes] = useState<number[]>([]);

	useEffect(() => {
		props.filterChangedCallback();
	}, [qualTypes]);

	useImperativeHandle(ref, () => {
		return {
			isFilterActive() {
				return qualTypes.length > 0;
			},

			doesFilterPass(params: IDoesFilterPassParams) {
				return qualTypes.includes(params.data.qualType);
			},

			getModel() {
				return qualTypes;
			},

			setModel(model: number[]) {
				setQualTypes(model ?? []);
			},

			/* This function is never called for react components, it's just here
			 * to make typescript happy
			 */
			getGui(): HTMLElement {
				return new HTMLSpanElement();
			},
		};
	});

	return null;
});
