import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, IconButton, Text } from 'basecamp';

type Props = {
	toggleRtmMessaging: () => void;
	unreadMsgs: number;
};

const RtmMessagingBtn: FunctionComponent<Props> = props => {
	/**
	 ** Render Component
	 */
	return (
		<Flex dataTest="rtmMessagingBtnWrap">
			<IconButton
				withIcon="envelope"
				setAs="accent"
				withSize="medium"
				ml={3}
				onClick={() => props.toggleRtmMessaging()}
				dataTest="rtmMessagingBtn"
			/>
			{/* Message count */}
			{props.unreadMsgs > 0 && (
				<Flex
					withAlign="center"
					bg="status.error.0"
					position="absolute"
					width="20px"
					height="20px"
					bottom="-10px"
					left="5px"
					borderRadius={5}
					dataTest="rtmMessagingBtnUnreadWrap"
				>
					<Text m={0} withColor="white" setAs="small" dataTest="rtmMessagingBtnUnreadCount">
						{props.unreadMsgs}
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

export default RtmMessagingBtn;
