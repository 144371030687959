import { SagaIterator } from 'redux-saga';
import { call, cancel, put, select, spawn } from 'redux-saga/effects';
import { getPasscode, hasSecretBeenDispatchedToDb } from '../../../../sharedSelectors/mfa/common';
import { MfaType } from '../../../../utils/mfa';
import { oneTimePasswordFlow } from 'features/manageAccount/redux/sagas/oneTimePasswordSaga';
import { sendEmailAuthenticationFlow } from './sendEmailAuthenticationSaga';
import { emailAuthenticationFlow } from 'features/manageAccount/redux/sagas/emailAuthenticationSaga';
import { getIsMfaEnabled, userDefinedMfaConfiguration } from 'features/app/redux/context';
import { setCurrentMfaType } from '../mfaSignIn/slice';
import { loginFlow } from './loginSaga';

/**
 * Saga that dispatches the available authentication types sequentially at log in
 * This file has been unit tested indirectly as no actions are dispatched here but are
 * dispatched from child sagas which have been unit tested
 */
export function* executeMultiFactorAuthenticationFlow(): SagaIterator<void> {
	//Before we do anything has the user enabled mfa
	const isMfaActive = yield select(getIsMfaEnabled);

	//fetch the active/available authentication types
	const userDefinedConfiguration = yield select(userDefinedMfaConfiguration);

	//If not enabled by user bail out and log in normally
	if (!isMfaActive || !userDefinedConfiguration || userDefinedConfiguration?.length === 0) {
		//authentic as normal
		yield call(loginFlow);

		//Bail out the saga
		yield cancel();
	}

	//Get the passcode if it exists from state
	const getOneTimePasscode = yield select(getPasscode('mfaValidation', MfaType.ONE_TIME_PASSWORD));
	const getEmailPasscode = yield select(getPasscode('mfaValidation', MfaType.EMAIL_VALIDATION));

	//has the the passcode been emailed to the user
	const hasEmailBeenSend = yield select(
		hasSecretBeenDispatchedToDb('mfaValidation', MfaType.EMAIL_VALIDATION)
	);

	//If the available mfa types include a totp AND we havent added a totp passcode
	if (
		userDefinedConfiguration.some((x) => x.mfaTypeId === MfaType.ONE_TIME_PASSWORD) &&
		!getOneTimePasscode
	) {
		yield put(setCurrentMfaType(MfaType.ONE_TIME_PASSWORD));

		yield call(oneTimePasswordFlow);
	}

	//If the available mfa types include a email AND we havent added a email passcode
	if (
		userDefinedConfiguration.some((x) => x.mfaTypeId === MfaType.EMAIL_VALIDATION) &&
		!getEmailPasscode
	) {
		yield put(setCurrentMfaType(MfaType.EMAIL_VALIDATION));

		//send the email if it hasn't been
		if (!hasEmailBeenSend) {
			yield call(sendEmailAuthenticationFlow);
		}
		//Validate the password
		yield call(emailAuthenticationFlow);
	}
}
