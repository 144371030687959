import { getJSDocReturnTag } from 'typescript';

// Get loading status
export const isLoading = (state: RootState) => state.subscriptions.loading;
// Get all subscriptions
export const getAllSubscriptions = (state: RootState) => state.subscriptions.subscriptions;

// Get the currently selected subscription level
export const getSelectedSubscriptionLevel = (state: RootState) =>
	state.subscriptions.selectedSubscription as ManageAccount.SubscriptionLevel;

export const getSubscritionPricing = (state: RootState) =>
	state.subscriptions.pricing as ManageAccount.SubscriptionPrices;

// Get the number of students for the currently selected subscription level
export const getSubscriptionSize = (state: RootState) => {
	const selectedSubscription = getSelectedSubscriptionLevel(state);

	return selectedSubscription && state.subscriptions.subscriptions[selectedSubscription].size;
};

// Get the number of years for the currently selected subscription level
export const getSelectedSubscriptionDuration = (state: RootState): number | undefined => {
	const selectedSubscription = getSelectedSubscriptionLevel(state);
	if (!selectedSubscription) return undefined;

	const durationOption = state.subscriptions.subscriptions[selectedSubscription].duration;
	return durationOption ? durationOption.value + 1 : 0;
};

// Get Subscription data
export const getReportData = (state: RootState) => state.subscriptions.reportsData;

// Get the currently selected subscription level
export const getSelectedReports = (state: RootState) => state.subscriptions.selectedReports;

// Get all report
export const getAllReports = (state: RootState) => state.subscriptions.reports;

// Calc reports total
export const calculateReportTotal = (state: RootState) => {
	const reports = getAllReports(state);
	const selectedReports = getSelectedReports(state);
	const data = getReportData(state);

	const reportsTotal = selectedReports.reduce((acc, curr: ManageAccount.Qualifications) => {
		if (reports[curr].selectedFilterReports && reports[curr].selectedFilterReports.length > 0) {
			return acc + data.price * reports[curr].selectedFilterReports.length;
		}

		return acc;
	}, 0);

	return reportsTotal;
};

// Get order total
export const getOrderTotal = (state: RootState) =>
	state.subscriptions.subscriptions.total + state.subscriptions.reports.total;

// Validate step 1
export const step1Validation = (state: RootState) => {
	const size = getSubscriptionSize(state);
	const duration = getSelectedSubscriptionDuration(state);
	const currentSubscriptions = getCurrentSubscriptionAgreement(state);

	const selectedReports = getSelectedReports(state);
	const reports = getAllReports(state);
	const subscriptionValidation = !!size && !!duration;
	const reportValidation = selectedReports.every(
		(report) =>
			reports[report].selectedFilterReports && reports[report].selectedFilterReports.length > 0
	);

	return (
		(reportValidation && selectedReports?.length && currentSubscriptions?.length) ||
		(subscriptionValidation && reportValidation)
	);
};

// Get client, primary contact and mat details for subscription details
export const getOrganisationDetails = (
	state: RootState
): ManageAccount.OrganisationDetails | undefined => state.subscriptions.organisationDetails;

// Get all subscription agreements including contracted ones
export const getCurrentSubscriptionAgreement = (
	state: RootState
): Array<ManageAccount.SubscriptionAgreement> | undefined => state.subscriptions.agreements;

export const getCurrentSubscriptionById = (
	subscriptionId: number | undefined,
	state: RootState
) => {
	return getCurrentSubscriptionAgreement(state)
		?.map((x: ManageAccount.SubscriptionAgreement) => x)
		.find((subscription) => subscription.id === subscriptionId);
};

// Get the consent values
export const getConsent = (state: RootState): ManageAccount.Consent => state.subscriptions.consent;

export const getCancelledSubscription = (
	state: RootState
): ManageAccount.SubscriptionAgreement | undefined => {
	const cancelledSubscriptionId = state.subscriptions.autoRenewCancellationCandidateId;
	return getCurrentSubscriptionById(cancelledSubscriptionId, state);
};
//#endregion Selectors
