import { Context } from 'features/app/types';
import { setGraphLoading, setMainGraphDataSuccess, setMainGraphDataFail } from '../slice';
import { getPerformanceMeasureCharts } from '../../../../api/ks5PerformanceMeasuresAPI';
import { PerformanceMeasuresTypes } from '../../../../types/enum';

export const getIBMeasuresChartThunk = (GradePoints: Context.Gradepoint[]): AppThunk => async (
	dispatch
) => {
	dispatch(setGraphLoading(true));

	try {
		const response = await getPerformanceMeasureCharts(GradePoints, PerformanceMeasuresTypes.IB);
		const data = response.responseObject;

		if (!response.HasError) {
			dispatch(setMainGraphDataFail(undefined));
			dispatch(setMainGraphDataSuccess(data));
			dispatch(setGraphLoading(false));
		} else {
			dispatch(setMainGraphDataFail(`There is no data to display.`));
			dispatch(setGraphLoading(false));
		}
	} catch (ex) {
		dispatch(
			setMainGraphDataFail(`The following error occurred whilst fetching the overview data - ${ex}`)
		);
		dispatch(setGraphLoading(false));
	}
};
