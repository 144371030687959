import * as React from 'react';
import { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../utils/locale';
import { Modal, Heading, Text } from 'basecamp';
import {
	getAppliedGradepoints,
	getPdfReport,
	resetPdfReport,
	showStrategicPdfReportModal,
} from './redux';
import { StrategicAnalysis } from './types';
import { fetchStrategicPdfReport } from '../../api/reportAPI';
import {
	getBenchmarkSetCountry,
	getBenchmarkSetName,
	getClient,
	getDataSetItem,
	getUser,
	getYetiToken,
} from 'features/app/redux/context';
import { ConvertCountryToEnum } from '../../utils/country';
import {
	reportDefinitions,
	reportPages,
	createReportPage,
	ReportTemplate,
} from './utils/exportSettings';
import levels from '../../utils/examLevel';

type ReportLevels = 1 | 2 | 4 | 8 | 16 | 32;

const StrategicPdfReportModal: FunctionComponent = () => {
	/**
	 * Redux
	 */
	const dispatch = useDispatch();
	// Get the report state
	const { examLevel, reportTemplate, customReportPages, comparisons } = useSelector(getPdfReport);
	/**
	 * Handle the report creation
	 */
	const benchmarkSet = useSelector(getBenchmarkSetName);
	const user = useSelector(getUser);
	const client = useSelector(getClient);
	const gradepoints = useSelector(getAppliedGradepoints);
	const dataSetId = useSelector(getDataSetItem).split(':')[1];
	const country = useSelector(getBenchmarkSetCountry);
	const countryEnumValue = ConvertCountryToEnum(country ?? 'England') as 0 | 1 | 3;
	const reportingAPIToken = useSelector(getYetiToken);

	const handleCreatePdfReport = () => {
		// Get the selected report template
		const selectedTemplate = reportTemplate![0].value;
		// Get the selected exam levels, allows multi level reports
		const selectedExamLevels = examLevel!.map((level) => level.value);
		// Get the exam level / page ids for alps standard reports
		const alpsStandard: {
			examLevel: StrategicAnalysis.ExamLevels;
			report: number[] | undefined;
		}[] = selectedExamLevels.map((level: StrategicAnalysis.ExamLevels) => {
			return { examLevel: level, report: reportPages[selectedTemplate]![level] as number[] };
		});
		// Get the exam level / page ids for custom reports
		const customReport: {
			examLevel: StrategicAnalysis.ExamLevels;
			report: number[];
		}[] = selectedExamLevels.map((level) => {
			return {
				examLevel: level,
				report: customReportPages!.reduce((acc, page) => {
					const filter = reportPages[ReportTemplate.CUSTOM]![level] as number[];

					return filter?.includes(page.value) ? [...acc, page.value] : acc;
				}, [] as number[]),
			};
		});
		// Are we using a custom report or alps standard?
		const pages = selectedTemplate === ReportTemplate.CUSTOM ? customReport : alpsStandard;
		// Create the report pages
		const createReportPages = pages!.reduce((acc, { examLevel, report }) => {
			return [
				...acc,
				...report!.reduce((acc: StrategicAnalysis.PdfReportPages[], pageId) => {
					const page = reportDefinitions.overallReports.find((report) => report.id === pageId)!;
					const pageExamLevelSettings = page.exportSettings[examLevel as ReportLevels];
					const examLevelInfo = levels[examLevel as ReportLevels];

					return [
						...acc,
						// Add the 'all' page
						createReportPage(
							benchmarkSet,
							user!,
							client!,
							gradepoints,
							page,
							dataSetId,
							countryEnumValue,
							pageExamLevelSettings!,
							examLevelInfo
						),
						// Add the 'comparison' page if we have any set
						...(!!comparisons && comparisons.length > 0
							? comparisons!.map((comparison) => {
									return createReportPage(
										benchmarkSet,
										user!,
										client!,
										gradepoints,
										page,
										dataSetId,
										countryEnumValue,
										pageExamLevelSettings!,
										examLevelInfo,
										comparison
									);
							  })
							: []),
					];
				}, [] as StrategicAnalysis.PdfReportPages[]),
			];
		}, [] as StrategicAnalysis.PdfReportPages[]);

		const pdfReport: StrategicAnalysis.PdfReportRequest = {
			output: 'PDF',
			reportingAPIToken,
			reports: createReportPages,
		};

		fetchStrategicPdfReport(pdfReport);
		dispatch(showStrategicPdfReportModal());
	};

	return (
		<Modal
			setAs="small"
			close={() => dispatch(showStrategicPdfReportModal())}
			withActions={[
				{
					title: translate('strategicAnalysis.modal.PDF_ACTION') as string,
					type: 'success',
					onClick: () => {
						handleCreatePdfReport();
						dispatch(resetPdfReport());
					},
				},
			]}
			andBodyPadY={4}
			dataTest="strategicPdfReportModal"
		>
			<Heading setAs="large" withColor="error" dataTest="strategicPdfReportModalTitle">
				{
					translate('strategicAnalysis.modal.PDF_HEADING', {
						examLevel: examLevel!.reduce((acc, level) => {
							return acc ? `${acc}, ${level.label}` : level.label;
						}, ''),
						reportTemplate: reportTemplate!.reduce((acc, template) => {
							return acc ? `${acc}, ${template.label}` : template.label;
						}, ''),
					}) as string
				}
			</Heading>
			<Text mb={3} dataTest="strategicPdfReportModalModalBody">
				{translate('strategicAnalysis.modal.PDF_MESSAGE') as string}
			</Text>
		</Modal>
	);
};

export default StrategicPdfReportModal;
