/**
 * Selector for getting the acitve page slice
 * This allows use t re-use the same selectors for all pages
 */

import { SubjectArea } from 'features/subjectArea/types';
import { SubjectAreaGridData } from 'features/subjectArea/types/common';

/**
 * Gets the redux slice for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns Subject Area state slice for the active page
 */
export const getSlice = (page: SubjectArea.AnalysisPage) => (
	state: RootState
): SubjectAreaGridData.State => {
	const slice = `subjectArea${page}` as keyof RootState;

	return state[slice];
};
