import { createSelector } from 'reselect';
import { academicYearLongName } from '../../../../utils/academicYear';
import { concat } from 'lodash';
import { calculateSummitKeyStage } from '../../../../utils/summitKeyStage';
import { getCurrentAcademicYear } from '../../../../utils/academicYear';

/**
 ** Determine if setup is loading
 */
export const isLoading = (state: RootState) => state.groupSetup.loading;

/**
 ** Determine if setup has errors
 */
export const hasError = (state: RootState) => state.groupSetup.error;

export const getGroupAccessId = ({ groupSetup }: RootState) => groupSetup.groupAccessId;
export const getGroupAccessRecords = ({ groupSetup }: RootState) => groupSetup.groupAccessRecords;

export const getGroupAccessOptions = createSelector([getGroupAccessRecords], (records) => {
	const currentYear = getCurrentAcademicYear();

	const recordsSort = [...records];
	return recordsSort
		.filter((x) => x.academicYear >= currentYear)
		.sort((a, b) => a.academicYear - b.academicYear)
		.map((x) => ({
			label: concat(
				academicYearLongName(x.academicYear),
				' ',
				calculateSummitKeyStage(x.examLevel)
			),
			value: x.id,
		}));
});

export const getSelectedGroupAccessOption = createSelector(
	[getGroupAccessId, getGroupAccessOptions],
	(id, options) => options.find((x) => x.value === id)
);

export const getKeyStageContext = ({ groupSetup }: RootState) => groupSetup.currentKeyStageContext;

//get the list of school contracts
export const getContract = (state: RootState) => {
	return state.groupSetup.schoolContracts;
};
export const getPreviousYearData = ({ groupSetup }: RootState) => groupSetup.previousYearData;

export const getRolloverSuccess = ({ groupSetup }: RootState) => groupSetup.rolloverSuccess;

export const getCurrentYear = ({ groupSetup }: RootState) => groupSetup.currentData?.academicYear;

export const hasSetup = ({ groupSetup }: RootState) => {
	return (
		groupSetup.currentData &&
		(groupSetup.currentData.columnCount > 0 ||
			groupSetup.currentData.comparisonCount > 0 ||
			groupSetup.currentData.gradepointCount > 0 ||
			groupSetup.currentData.trendCount > 0 ||
			groupSetup.currentData.groupCount > 0)
	);
};

/**
 ** Get trends
 */
export const getTrends = (state: RootState) =>
	state.groupSetup.trends.filter(({ isDeleted }) => !isDeleted) || [];

/**
 ** Get deleted trends
 */
export const getDeletedTrends = (state: RootState) =>
	state.groupSetup.trends.filter(({ isDeleted }) => isDeleted) || [];

/**
 ** Determine if trends have changed
 */
export const trendsHaveChanged = (state: RootState) =>
	state.groupSetup.trends.filter(({ isNew, isEdited, isDeleted }) => isNew || isEdited || isDeleted)
		.length > 0;

/**
 ** Get monitoring points
 */
export const getMonitoringPoints = (state: RootState) => state.groupSetup.monitoringPoints;

/**
 ** Get monitoring points
 */
export const getMonitoringPointsGroups = (state: RootState) =>
	state.groupSetup.monitoringPoints
		.filter(({ isDeleted }) => !isDeleted)
		.sort((a, b) => a.id - b.id) || [];
/**
 * Get group connect monitoring points
 * @param context Root context
 * @returns An array group connect monitoring points from Redux
 */
export const getGroupMonitoringPoints = (
	context: RootState
): Array<Groups.Setup.MonitoringPoint> | undefined => context.groupSetup.monitoringPoints;

/**
 *  Get group connect custom columns from Redux
 * @param context Root context
 * @returns An array of group custom columns from Redux
 */
export const getCustomColumns = (
	context: RootState
): Array<Groups.Setup.CustomColumn> | undefined => context.groupSetup.customColumns;

/**
 *  Get group connect school groups from Redux
 * @param context Root context
 * @returns An array of school group from Redux
 */
export const getSchoolGroups = (context: RootState): Array<Groups.Setup.SchoolGroup> | undefined =>
	context.groupSetup.schoolGroups;

/**
 * Get a list of child schools linked to the MAT
 * @param context Root Context
 * @returns An array of available child schools under the MAT
 */
export const getSchools = (context: RootState): Array<Groups.Setup.School> | undefined =>
	context.groupSetup.schools;

/**
 * Get a list of custom comparisons
 * @param context Root Context
 * @returns An array of group connect custom comparisons
 */
export const getCustomComparisons = (
	context: RootState
): Array<Groups.Setup.ComparisonGroup> | undefined => context.groupSetup.customComparisons;

/**
 * Get the field comparisons for gender, ethnicity, HML and disadvantaged
 * @param context Root Context
 */
export const getFieldComparisons = (context: RootState): Array<Groups.Setup.FieldComparison> =>
	context.groupSetup.fieldComparisons;

/**
 * Get a list of custom comparisons with groups custom columns
 * @param context Root Context
 */
export const getAllComparisonFiltersWithGroupCustomColumns = (
	rootState: RootState
): Array<Groups.Setup.FieldComparison> => {
	const customColumns = getCustomColumns(rootState);
	const fieldComparisons = getFieldComparisons(rootState);

	return [
		...fieldComparisons,
		...(customColumns?.map((customColumn) => {
			return {
				name: customColumn.name,
				type: customColumn.name,
				values: customColumn.allowedValues.map((val) => {
					return {
						name: val.value,
						value: val.value,
					};
				}),
			};
		}) ?? []),
	];
};
