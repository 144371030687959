import { setAnalysisLoading, setAnalysisErrorMessage, setAnalysisData } from '../';
import { getGroupData } from '../../../../../api/groupsAPI';
import { getBenchmarkSetId } from 'features/app/redux/context';
import { getSubject, getSubjectsByName } from 'features/utils/subjects';
import subjectSort from '../../../../../utils/subjectOrderStringSort';
import { getSelectedTrend } from '../selectors';
import { addOrdinalToGradePoints } from '../../../../../utils/groups';
import { translate } from '../../../../../utils/locale';
import { getCurrentAcademicYear } from '../../../../../utils/academicYear';

/**
 * Fetch Group Data
 */
export const fetchGroupData = (): AppThunk => async (dispatch, getState) => {
	dispatch(setAnalysisLoading('AnalysisData'));
	const benchmarkSetId = getBenchmarkSetId(getState());

	try {
		const selectedTrend = getSelectedTrend(getState());
		const selectedTrendGradePoints = [...selectedTrend?.gradepoints] ?? [];
		const groupRes = await getGroupData();
		const data = groupRes.responseObject.subjects;
		const responseObject = groupRes.responseObject;

		const allSubjectNames = Object.values(data).map((d: any) => d.subjectName);
		const allSubjectsBedrockResponse = await getSubjectsByName(allSubjectNames, benchmarkSetId, {
			academicYear: getCurrentAcademicYear(),
		});
		const subjects = await Promise.all(
			Object.values(data).map(async (d) => {
				let order: string = '0';

				try {
					order = allSubjectsBedrockResponse.find((x) => x.value === d.subjectName)?.order ?? '999';
				} catch (ex) {
					order = '999';
				}

				return {
					id: d.id,
					order: order,
					examLevel: d.examLevel,
					subjectName: d.subjectName,
				};
			})
		);

		const sortedData = subjects.sort((a, b) => {
			return subjectSort(a.order ?? 0, b.order ?? 0);
		});

		groupRes.responseObject.subjects = sortedData;

		responseObject.gradepoints = addOrdinalToGradePoints(
			responseObject.gradepoints,
			selectedTrendGradePoints
		);

		dispatch(setAnalysisData(groupRes.responseObject));
	} catch (err: any) {
		dispatch(
			setAnalysisErrorMessage(err?.message ?? translate('groups.errors.FAILED_TO_FETCH_DATA'))
		);
	}
};
