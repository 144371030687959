import { Report } from 'features/reports/types';
import { setLoading, setErrorMessage } from '../';
import { getSubjectPage, getFineGradesSettings } from '../../../../api/reportAPI';
import { setSubjectData, setSubjectUdfData } from '../slice';
import { fetchSubjectsOverview } from 'features/subjectsOverview/redux/index';

type UDFKeyValue = { key: string; value: string };
type RequestedUDF = Array<UDFKeyValue>;

export const fetchSubject = (
	subjectName: string,
	requestedUDF: RequestedUDF = [],
	gpHasChanged: Boolean = false
): AppThunk => async (dispatch) => {
	dispatch(setLoading('SubjectData'));

	try {
		let subjectPromises: Array<any> = [
			getSubjectPage(subjectName, requestedUDF),
			getFineGradesSettings(),
		];
		if (gpHasChanged) {
			subjectPromises.push(dispatch(fetchSubjectsOverview()));
		}
		const [reportsRes, fineGradesRes] = await Promise.allSettled(subjectPromises);

		/**
		 ** Are we requesting udf data
		 */
		const isUdfRequest = requestedUDF.length > 0;

		/**
		 * Parse client settings JSON and get fine grades
		 */
		const FineGradeSettings =
			fineGradesRes.status === 'fulfilled'
				? JSON.parse(fineGradesRes.value.payload).fineGrades
				: undefined;
		const subjectPageData: Report.SubjectPage = reportsRes.value.reports.reduce(
			(acc: Report.SubjectPage, curr: Report.SubjectPageStudents | Report.SubjectPageGroups) => {
				return Object.assign(acc, curr);
			},
			{ FineGradeSettings }
		);

		isUdfRequest
			? dispatch(setSubjectUdfData(subjectPageData))
			: dispatch(setSubjectData(subjectPageData));
	} catch (err) {
		dispatch(setErrorMessage(err.message));
	}
};
