import { ManageUsers } from 'features/manageUsers/types';
import fetch from './';

//list users call
export const getUsers = (): Promise<Array<ManageUsers.User>> => {
	return fetch('/api/Settings/Users', {
		body: {},
		abortId: 0,
	}).then((response) => {
		const activeUsers = response.Users.ActiveUsers.map((user: any) => {
			return {
				...user,
				isActive: true,
				isPending: false,
				isChamp: response.Users.AlpsChampions.some((c: any) => c.Id === user.Id),
			};
		});

		const disabledUsers = response.Users.DisabledUsers.map((user: any) => {
			return {
				...user,
				isPending: false,
				isActive: false,
			};
		});

		const pendingUsers = response.Users.PendingUsers.map((user: any) => {
			return {
				...user,
				isPending: true,
				isActive: false,
			};
		});

		return [...activeUsers, ...disabledUsers, ...pendingUsers].map((x: any) => {
			return {
				permissions_MyAlps_Administrator: x.Permissions_MyAlps_Administrator,
				username: x.Username,
				fullname: x.Fullname,
				jobTitle: x.JobTitle,
				id: x.Id,
				isPrimaryDataContact: x.IsPrimaryDataContact,
				isActive: x.isActive,
				isPending: x.isPending,
				isChamp: x.isChamp,
				startDate: x.StartDate,
				endDate: x.EndDate,
				trialExtensions: x.Extensions,
				isConfirmed: x.IsConfirmed,
				KS4PerformanceMeasures: x.KS4PerformanceMeasures,
				KS5PerformanceMeasures: x.KS5PerformanceMeasures,
				earlyAccessPrivilege: x.EarlyAccessPrivilege,
				canSwitchClientBenchmark: x.IsBmToggleOn,
				mfaEnabled: x.IsMfaEnabled,
			} as ManageUsers.User;
		});
	});
};

//Primary Data Contact
export const updateDataContact = (user: ManageUsers.User): Promise<boolean> => {
	return fetch('/api/Settings/Users/UpdatePrimaryContact', {
		abortId: 0,
		body: {
			id: user.id,
		},
	});
};

//Adding a New User
export const addUser = (user: ManageUsers.User): Promise<ManageUsers.User> => {
	return fetch('/api/Settings/Users/AddUser', {
		abortId: 0,
		body: {
			addUser: {
				confirmEmail: user.username,
				fullname: user.fullname,
				jobTitle: user.jobTitle,
				permissions_DataExchange_Mode: user.permissions_DataExchange_Mode,
				permissions_MyAlps_Administrator: user.permissions_MyAlps_Administrator,
				username: user.username,
				permissions_Groups_Setup: user.permissions_Groups_Setup,
				KS4PerformanceMeasures: user.KS4PerformanceMeasures,
				KS5PerformanceMeasures: user.KS5PerformanceMeasures,
				earlyAccessPrivilege:
					user.permissions_MyAlps_Administrator === true ? user.earlyAccessPrivilege : false,
				canSwitchClientBenchmark: false,
			},
		},
	}).then((response) => {
		return {
			id: response.User.Id,
			permissions_MyAlps_Administrator: response.User.Permissions_MyAlps_Administrator,
			username: response.User.Username,
			fullname: response.User.Fullname,
			jobTitle: response.User.JobTitle,
			permissions_DataExchange_Mode: response.User.Permissions_DataExchange_Mode,
			permissions_Groups_Setup: response.User.Permissions_Groups_Setup,
			isActive: response.User.Status_Disabled,
			KS4PerformanceMeasures: response.User.KS4PerformanceMeasures,
			KS5PerformanceMeasures: response.User.KS5PerformanceMeasures,
			earlyAccessPrivilege: response.User.EarlyAccessPrivilege,
			canSwitchClientBenchmark: response.User.IsBmToggleOn,
		} as ManageUsers.User;
	});
};

// Editing a Existing User
export const editUser = (user: ManageUsers.User): Promise<ManageUsers.User> => {
	return fetch('/api/Settings/Users/EditUser', {
		abortId: 0,
		body: {
			editUser: {
				id: user.id,
				fullname: user.fullname,
				jobTitle: user.jobTitle,
				permissions_DataExchange_Mode: user.permissions_DataExchange_Mode,
				permissions_MyAlps_Administrator: user.permissions_MyAlps_Administrator,
				permissions_Groups_Setup: user.permissions_Groups_Setup,
				username: user.username,
				Status_Disabled: !user.isActive,
				KS4PerformanceMeasures: user.KS4PerformanceMeasures,
				KS5PerformanceMeasures: user.KS5PerformanceMeasures,
				earlyAccessPrivilege:
					user.permissions_MyAlps_Administrator === true ? user.earlyAccessPrivilege : false,
				isBmToggleOn: user.canSwitchClientBenchmark,
			},
		},
	}).then((response) => {
		return {
			id: response.User.Id,
			permissions_MyAlps_Administrator: response.User.Permissions_MyAlps_Administrator,
			username: response.User.Username,
			fullname: response.User.Fullname,
			jobTitle: response.User.JobTitle,
			permissions_DataExchange_Mode: response.User.Permissions_DataExchange_Mode,
			permissions_Groups_Setup: response.User.Permissions_Groups_Setup,
			isActive: !response.User.Status_Disabled,
			KS4PerformanceMeasures: response.User.KS4PerformanceMeasures,
			KS5PerformanceMeasures: response.User.KS5PerformanceMeasures,
			earlyAccessPrivilege: response.User.EarlyAccessPrivilege,
			canSwitchClientBenchmark: response.User.IsBmToggleOn,
		} as ManageUsers.User;
	});
};

//Fetch a Users Permissions
export const userPermissions = (id: number): Promise<ManageUsers.User> => {
	return fetch('/api/Settings/Users/SingleUser', {
		abortId: 0,
		body: {
			id: id,
		},
	}).then((response) => {
		return {
			permissions_MyAlps_Administrator: response.User.Permissions_MyAlps_Administrator,
			fullname: response.User.Fullname,
			jobTitle: response.User.JobTitle,
			id: response.User.Id,
			isPrimaryDataContact: response.User.IsPrimaryDataContact,
			permissions_DataExchange_Mode: response.User.Permissions_DataExchange_Mode,
			permissions_Groups_Setup: response.User.Permissions_Groups_Setup,
			KS4PerformanceMeasures: response.User.KS4PerformanceMeasures,
			KS5PerformanceMeasures: response.User.KS5PerformanceMeasures,
			earlyAccessPrivilege: response.User.EarlyAccessPrivilege,
			canSwitchClientBenchmark: response.User.IsBmToggleOn,
		} as ManageUsers.User;
	});
};

//New get contractAccess
export const getDataSharing = (): Promise<Array<ManageUsers.Contract>> => {
	return fetch('/api/ContractAccess/ClientAccess', {
		method: 'GET',
	}).then((response) => {
		return response.ResponseObject.map((x) => {
			return {
				academicYear: x.AcademicYear,
				contractDescription: x.ContractDescription,
				contractName: x.ContractName,
				contractId: x.ContractId,
				id: x.Id,
				schoolName: x.SchoolName,
				allowed: x.Allowed,
				auditId: x.AuditId,
			};
		});
	});
};

// Add a new Alps champion
export const addAlpsChampion = (ids: Array<number>): Promise<void> => {
	return fetch('/api/Settings/Users/AddAlpsChampion', {
		abortId: 0,
		body: {
			ids: ids,
		},
	});
};

/**
 * Remove Alps champion status from the supplied list of user ids
 * @param ids Array of user ids
 */
export const removeAlpsChampion = (ids: Array<number>): Promise<void> => {
	return fetch('/api/Settings/Users/RemoveAlpsChampion', {
		body: {
			ids: ids,
		},
	});
};

//Update Data contact
export const updateContact = (id: number): Promise<ManageUsers.User> => {
	return fetch('/api/Settings/Users/UpdatePrimaryContact', {
		abortId: 0,
		body: {
			id: id,
		},
	}).then((response) => {
		return {
			permissions_MyAlps_Administrator: response.Permissions_MyAlps_Administrator,
			fullname: response.Fullname,
			jobTitle: response.JobTitle,
			id: response.Id,
			isPrimaryDataContact: response.IsPrimaryDataContact,
			isConfirmed: response.IsConfirmed,
			isActive: !response.Status_Disabled,
			message: response.Message,
			username: response.Username,
			canSwitchClientBenchmark: response.IsBmToggleOn,
		} as ManageUsers.User;
	});
};

// Edit the Contract access
export const contractAccess = (
	contract: Array<ManageUsers.Contract>
): Promise<Array<ManageUsers.Contract>> => {
	return fetch('/api/ContractAccess/CreateAccess', {
		abortId: 0,
		body: contract,
	});
};

// Bulk Add Users

export const bulkAddUser = (bulkUsers: Array<ManageUsers.Bulk>): Promise<ManageUsers.User> => {
	return fetch('/api/Settings/Users/BulkAddUsers', {
		abortId: 0,
		body: {
			Users: bulkUsers.map((bulkUser: ManageUsers.Bulk) => {
				return {
					ConfirmEmail: bulkUser.email,
					Fullname: bulkUser.fullName,
					JobTitle: bulkUser.jobTitle,
					Email: bulkUser.email,
					Username: bulkUser.email,
				};
			}),
		},
	}).then((response) => {
		return response.Users.map((x: any) => {
			return {
				id: x.Id,
				permissions_MyAlps_Administrator: x.Permissions_MyAlps_Administrator,
				username: x.Username,
				fullname: x.Fullname,
				jobTitle: x.JobTitle,
				permissions_DataExchange_Mode: x.Permissions_DataExchange_Mode,
				permissions_Groups_Setup: x.Permissions_Groups_Setup,
				isActive: true,
			} as ManageUsers.User;
		});
	});
};
