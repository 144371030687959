import { call, put, select, delay, spawn } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';
import { SubjectArea } from 'features/subjectArea/types';
import { getSubjectAreaPage } from './sagaUtils';
import { getCurrentSubjectId } from 'features/subjectPage/redux';
import {
	getCurrentSubjectNumericId,
	getSubjectAreaActiveExamLevel,
} from 'features/subjectsOverview/redux';
import {
	setCurrentSubjectIdSubjectArea,
	setSubjectAreaActiveExamLevel,
	setsubjectAreaLoading,
} from '../slice';
import { mainGridData } from './fetchMainGridData';
import {
	fetchSubjectAreaActiveExamLevel,
	fetchSubjectAreaSubjectId,
	getSelectedSubjectAreaComparisons,
} from '../selectors';
import { mainChartData } from './fetchMainGraphData';
import { changeComprisonsFlow } from './changeComparisonFlow';

/**
 * Saga that handles the changing of the applied subject
 */
export function* changeSubjectFlow(): SagaIterator<void> {
	// Set loading to true
	yield put(setsubjectAreaLoading(true));

	//Get the Current Subject area page
	const subjectAreaPage: SubjectArea.SubjectAnalysisPages = yield call(getSubjectAreaPage);

	//Fetch the subject page 'id' this is the string name not a numeri id but until more work is done on the subject req we need to use this to get the id
	const subjectPageCurrentSubject = yield select(getCurrentSubjectId);

	//use the subject page 'id' to get the actual numeric id to be applied to the subject area redux
	const subjectOverviewStringId = yield select(
		getCurrentSubjectNumericId(subjectPageCurrentSubject)
	);

	//Get the subject page exam level
	const currentSubjectActiveExamlevel = yield select(
		getSubjectAreaActiveExamLevel(subjectPageCurrentSubject)
	);

	//Fetch subject area comparisons
	const subjectAreaComparisons = yield select(getSelectedSubjectAreaComparisons);

	//update the subject area examlevel  - this will be 0 when fetched/ applied but will allow future reusuablity with the selectors
	yield put(setSubjectAreaActiveExamLevel(currentSubjectActiveExamlevel));

	//update the subject area subject id
	yield put(setCurrentSubjectIdSubjectArea(subjectOverviewStringId));

	//fetch the current subject area examlevel
	const currentExamLevel = yield select(fetchSubjectAreaActiveExamLevel);

	//Fetch Subject area Id
	const subjectId = yield select(fetchSubjectAreaSubjectId);

	//Fetch grid data make this a spawn to allow both ot be made at the same time
	yield call(mainGridData, subjectAreaPage, currentExamLevel, subjectId);

	//fetch chart data
	yield call(mainChartData, subjectAreaPage, currentExamLevel, subjectId);

	//if theres comaprisons spawn comparison flow
	if (subjectAreaComparisons.length > 0) {
		yield call(changeComprisonsFlow);
	}

	//Allow state to update
	yield delay(1000);

	//Set subject area loading to false
	yield put(setsubjectAreaLoading(false));
}
