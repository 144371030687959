import { translate } from '../../../utils/locale';

const isVisible = (p: string, param: string): boolean => param !== p;

/**
 ** Connect Routes
 */
export const connectReports: (
	params: { [key: string]: string },
	isLoading: boolean
) => PrimaryNavigation.Route[] = (params, isLoading) => {
	return [
		{
			name: translate('primaryNavigation.connect.reports.ADDITIONAL') as string,
			path: '/additional-downloads',
			isDisabled: isLoading,
			isVisible: isVisible('additional-downloads', params.reportsPage),
			type: 'reportsPage',
		},
		{
			name: translate('primaryNavigation.connect.reports.MY_REPORTS') as string,
			path: '/my-reports',
			isDisabled: isLoading,
			isVisible: isVisible('my-reports', params.reportsPage),
			type: 'reportsPage',
		},
		{
			name: translate('primaryNavigation.connect.reports.MEG_REPORTS') as string,
			path: '/meg-reports',
			isDisabled: isLoading,
			isVisible: isVisible('meg-reports', params.reportsPage),
			type: 'reportsPage',
		},
	];
};

/**
 ** Summit Routes
 */
const base = '/groups';
export const summitReports: (
	params: { [key: string]: string },
	isLoading: boolean
) => PrimaryNavigation.Route[] = (params, isLoading) => {
	return [
		{
			name: translate('primaryNavigation.summit.reports.SCHOOL_REPORTS') as string,
			path: `${base}/reports/school-and-college-reports`,
			isDisabled: isLoading,
			isVisible: isVisible('school-and-college-reports', params.reportsPage),
			type: 'groupsReports',
		},
		{
			name: translate('primaryNavigation.summit.reports.GROUP_REPORTS') as string,
			path: `${base}/reports/group-reports`,
			isDisabled: isLoading,
			isVisible: isVisible('group-reports', params.reportsPage),
			type: 'groupsReports',
		},
		{
			name: translate('primaryNavigation.connect.reports.ADDITIONAL') as string,
			path: `${base}/reports/additional-downloads`,
			isDisabled: isLoading,
			isVisible: isVisible('additional-downloads', params.reportsPage),
			type: 'groupsReports',
		},
	];
};
