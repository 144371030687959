//@ts-nocheck
import * as React from 'react';
import { components } from 'react-select';
import { Flex } from 'basecamp';
/**
 ** Custom Value Container
 * Specifically for the filter column as what needs to be displayed is in
 * the options.value rather than options.label
 * displays - "valuename, value1name & XX more"
 */
const ValueContainerForFilter: React.ReactElement = ({ children, getValue, ...props }) => {
	var values = getValue();
	var valueLabel = '';

	if (values.length > 0) {
		valueLabel += props.selectProps
			.getOptionValue(values[0])
			.slice(props.selectProps.getOptionValue(values[0]).indexOf('|') + 1);
	}

	if (values.length > 1) {
		valueLabel += ` & ${values.length - 1} more`;
	}

	// Keep standard placeholder and input from react-select
	const childrenToRender = React.Children.toArray(children).filter(
		(child) => ['Input', 'DummyInput', 'Placeholder'].indexOf(child.type.name) >= 0
	);

	return (
		<components.ValueContainer {...props}>
			{!props.selectProps.inputValue && (
				<Flex data-test="filterValueRendererLabel">{valueLabel}</Flex>
			)}
			{childrenToRender}
		</components.ValueContainer>
	);
};

export default ValueContainerForFilter;
