import { translate } from './../utils/locale';
import { getMATViewAs } from '../utils/impersonation';

const controllers: { [key: string]: AbortController } = {};

interface Fetch {
	<T = any>(
		endpoint: string,
		options?: { [name: string]: any },
		useResponseBuilder?: false
	): Promise<T>;
	<T = any>(
		endpoint: string,
		options?: { [name: string]: any },
		useResponseBuilder?: true
	): Promise<ResponseBuilder<T>>;
}

const fetch: Fetch = (
	endpoint,
	{ body, abortId, ...customConfig } = {},
	useResponseBuilder = false
) => {
	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	// Lets check if we have an impersonation token to send. If set this will come from viewing as via a MAT as it works per tab.
	const impersonationToken = sessionStorage.getItem('impersonationToken');

	if (impersonationToken) {
		headers['Authorization'] = 'Bearer ' + impersonationToken;
	}

	const { matImpersonateClientId } = getMATViewAs();
	if (matImpersonateClientId) {
		headers['viewAsClientId'] = matImpersonateClientId;
	}

	// If the feature exists, and an abortId is given
	if (AbortController && abortId) {
		// find any matching controller
		const currentController = controllers[abortId];

		// abort the request if found
		if (currentController) {
			currentController.abort();
		}

		controllers[abortId] = new AbortController();
	}

	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
		...(abortId && controllers[abortId] ? { signal: controllers[abortId].signal } : {}),
		credentials: 'same-origin',
	};

	if (body) {
		//@ts-ignore
		config.body = JSON.stringify(body);
	}

	return window
		.fetch(endpoint, config)
		.then((response) => {
			if (abortId && controllers[abortId]) {
				delete controllers[abortId];
			}
			if (response.ok) {
				// All good here, lets return the value
				return response;
			} else if (response.status === 403) {
				return Promise.reject(response.statusText);
			} else {
				return response;
			}
		})
		.then(async (response: any) => {
			if (config.headers['Content-Type'] !== 'application/json') {
				return response.blob();
			}

			// Checks to see for too many attempts
			if (response.status === 429) {
				const retryAfter = response.headers.get('retry-after');
				const message = translate('auth.login.messages.TOO_MANY_ATTEMPTS', {
					statusText: response.statusText,
					retryAfter: retryAfter,
				});

				return Promise.reject(retryAfter ? message : response.statusText);
			}
			// Check if the response was ok but there was a redirect such as logout
			if (response.status === 200 && response.redirected) {
				return Promise.resolve('');
			}

			if (response.status != 200 && !useResponseBuilder) {
				var jsonMessage = await response.json();
				return Promise.reject(jsonMessage.Message);
			} else {
				try {
					// if the response has no body, this will fail
					var jsonMessage = await response.json();

					return jsonMessage;
				} catch (e) {
					return Promise.resolve();
				}
			}
		});
};

export default fetch;
