import { SubjectArea } from 'features/subjectArea/types';
import { SagaIterator } from 'redux-saga';
import { call, cancel, put, retry, select } from 'redux-saga/effects';
import { Context } from 'features/app/types';
import { getAppliedGradepoints, getBenchmarkSetName } from 'features/app/redux/context';
import { fetchMainGridData } from '../../../../api/subjectAreaAPI';
import { CommonAgGridChartStateTypes } from 'types/commonAgGridTypes';
import { flattenResponse } from '../../../../utils/agGridUtils/agGridComparisonsHelpers';
import { setsubjectAreaLoading } from '../slice';

/**
 * Saga that fetchs Ag grid data
 * @param subjectAreaPages Current Subject Area
 * @param examLevel Current ExamLevel
 * @param subjectId Current Subject Id
 */
export function* mainGridData(
	subjectAreaPages: SubjectArea.SubjectAnalysisPages,
	examLevel: CommonFeatureTypes.ExamLevels,
	subjectId: number
): SagaIterator<void> {
	//Applied Gradepoints
	const gradepoints: Context.Gradepoint[] = yield select(getAppliedGradepoints);
	//Current benchmark
	const benchmarkSet: string = yield select(getBenchmarkSetName);

	try {
		//Grid Endpoint
		const SECOND = 1000;
		const response: ResponseBuilder<CommonAgGridChartStateTypes.GridData> = yield retry(
			3, // Try calling 3 times
			10 * SECOND, // with a delay of 10 seconds
			fetchMainGridData, // API to call
			subjectAreaPages,
			examLevel,
			benchmarkSet,
			gradepoints,
			[subjectId]
		);

		// Check the response builder to see if any errors occurred or response is empty
		if (!response || response.hasError) {
			// Handle errors
			yield put({
				type: `subjectArea${subjectAreaPages}/setMainGridDataFail`,
				payload: !response ? 'Grid data is empty' : response.errors[0].message,
			});
			yield put({
				type: `subjectArea${subjectAreaPages}/setSagaFlowStatus`,
				payload: true,
			});
			yield put(setsubjectAreaLoading(false));
			yield cancel();
		}

		yield put({
			type: `subjectArea${subjectAreaPages}/setMainGridDataFail`,
			payload: undefined,
		});

		const flatten = {
			...response.responseObject,
			rows: flattenResponse(response.responseObject.rows),
		};

		//Add data to redux
		yield put({ type: `subjectArea${subjectAreaPages}/setMainGridDataSuccess`, payload: flatten });
		yield put({
			type: `subjectArea${subjectAreaPages}/setSagaFlowStatus`,
			payload: false,
		});
	} catch (e) {
		//Set error message
		yield put({
			type: `subjectArea${subjectAreaPages}/setMainGridDataFail`,
			payload: e,
		});
	}
}
