/**
 * Filters selectors
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';

/**
 * Gets the default report level selected filters
 * @param state Redux state
 * @returns Array of selected filters
 */
export const getSelectedDefaultFilters = (state: RootState): StrategicAnalysis.FilterOption => {
	return state.strategicAnalysis.selectedDefaultFilters;
};
/**
 * Gets the individual page level selected filters
 * @param state Redux state
 * @returns Array of selected filters
 * Once the default report level filters
 */
export const getSelectedPageFilters = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.FilterOption => {
	const selectedPageFilters = state.strategicAnalysis.selectedPageFilters;

	return selectedPageFilters[page] ?? [];
};
/**
 * Gets the individual page level applied filters
 * @param state Redux state
 * @returns Array of applied filters
 */
export const getAppliedPageFilters = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicAnalysis.FilterOption => {
	const appliedPageFilters = state.strategicAnalysis.appliedPageFilters;

	return appliedPageFilters[page] ?? [];
};

