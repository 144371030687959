import * as React from 'react';
import { FunctionComponent } from 'react';

import { translate } from '../../utils/locale';
import { Flex, Label, Icon } from 'basecamp';

type Props = {
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	moreActionsIsOpen: boolean;
};

const MoreActions: FunctionComponent<Props> = ({
	onMouseEnter,
	onMouseLeave,
	moreActionsIsOpen,
	children,
}) => {
	return (
		<Flex
			setAs="column"
			position="relative"
			onMouseEnter={() => onMouseEnter()}
			onMouseLeave={() => onMouseLeave()}
			width="85px"
		>
			{/* More Actions menu toggle */}
			<Flex setAs="column" withAlign="centerRight" width="85px">
				<Label>{translate('subjectsOverview.filterBar.MORE_ACTIONS')}</Label>
				<Flex
					width={40}
					height={40}
					bg="UI.accent.0"
					withAlign="center"
					borderRadius={5}
					color="UI.white"
				>
					<Icon icon="ellipsis" />
				</Flex>
			</Flex>
			{/* Dropdown menu */}
			{moreActionsIsOpen && (
				<Flex
					setAs="column"
					withAlign="distributeLeft"
					position="absolute"
					top={67}
					right={0}
					maxWidth="360px"
					bg="UI.secondary.1"
					borderRadius={3}
					pl={4}
					py={4}
					boxShadow="lg"
				>
					{children}
				</Flex>
			)}
		</Flex>
	);
};

export default MoreActions;
