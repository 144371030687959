import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, Heading, Text } from 'basecamp';

type Props = {
	heading: string;
	body: string;
};

const ErrorMessage: FunctionComponent<Props> = ({ heading, body }) => {
	return (
		<Flex setAs="column" withAlign="center" position="relative" width={1} height="100%">
			<Heading setAs="large" withColor="black">
				{heading}
			</Heading>
			<Text setAs="h5">{body}</Text>
		</Flex>
	);
};

export default ErrorMessage;
