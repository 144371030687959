import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, Box } from 'basecamp';

type Props = {
	close: () => void;
};

const RtmMessageDrawer: FunctionComponent<Props> = props => {
	/**
	 ** Render Component
	 */
	return (
		<>
			{/* RTM drawer */}
			<Flex
				setAs="column"
				withAlign="topLeft"
				width="400px"
				position="fixed"
				right={0}
				top={0}
				bottom={0}
				zIndex={9999}
				bg="UIAlpha.black.4"
				overflow="scroll"
				boxShadow="xxl"
				dataTest="rtmMessageDrawer"
			>
				{/* Message wrapping element required for scrolling */}
				<Box width={1} p={4}>
					{props.children}
				</Box>
			</Flex>
			{/* Transparent layer when RTM is open - allows click anywhere to close */}
			<Box
				position="fixed"
				right={0}
				top={0}
				bottom={0}
				left={0}
				zIndex={9998}
				onClick={() => props.close()}
				dataTest="rtmMessageDrawerCloseLayer"
			/>
		</>
	);
};

export default RtmMessageDrawer;
