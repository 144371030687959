import { Context } from 'features/app/types';
import { PerformanceMeasuresTypes } from './../../src/types/enum';
import fetch from '.';
import {
	IbPerformanceMeasuresGraphData,
	IbPerformanceMeasures,
} from 'features/ibPerformanceMeasures/types';

/**
 * Get the main grid data for the given area and exam level
 * @param gradepoints Context.Gradepoint[],
 */
export const getPerformanceMeasures = (
	GradePoints: Context.Gradepoint[],
	PerformanceMeasuresType: PerformanceMeasuresTypes
): Promise<ResponseBuilder<Ks5PerformanceMeasures.ApiResponse>> => {
	return fetch(
		'/bigfoot/V2/KS5PerformanceMeasures/KS5PerformanceMeasures/GetPerformanceMeasures',
		{
			method: 'POST',
			body: {
				GradePoints,
				PerformanceMeasureTypeId: PerformanceMeasuresType,
			},
		},
		true
	);
};

/**
 * Get the main chart data for the given area and exam level
 * @param gradepoints Context.Gradepoint[],
 */
export const getPerformanceMeasureCharts = (
	GradePoints: Context.Gradepoint[],
	PerformanceMeasuresType: PerformanceMeasuresTypes
): Promise<ResponseBuilder<Ks5PerformanceMeasures.ApiResponse>> => {
	return fetch(
		'/bigfoot/V2/KS5PerformanceMeasures/KS5PerformanceMeasures/GetKS5PerformanceMeasuresCharts',
		{
			method: 'POST',
			body: {
				GradePoints,
				PerformanceMeasureTypeId: PerformanceMeasuresType,
			},
		},
		true
	);
};

/**
 * Get the main grid data for the given area and exam level
 * @param GradePoints Context.Gradepoint[],
 * @param Comparisons: Ks5PerformanceMeasures.ComparisonOption
 */
export const getPerformanceMeasureComparisons = (
	GradePoints: Context.Gradepoint[],
	PerformanceMeasuresType: PerformanceMeasuresTypes,
	Comparisons: {
		Key: string;
		Value: string;
	}[]
): Promise<ResponseBuilder<Ks5PerformanceMeasures.ApiResponse>> => {
	return fetch(
		'/bigfoot/V2/KS5PerformanceMeasures/KS5PerformanceMeasures/GetPerformanceMeasures',
		{
			method: 'POST',
			body: {
				GradePoints,
				Comparisons,
				PerformanceMeasureTypeId: PerformanceMeasuresType,
			},
		},
		true
	);
};

/**
 * Get the main Chart data for the given area and exam level
 * @param GradePoints Context.Gradepoint[],
 * @param Comparisons: Ks5PerformanceMeasures.ComparisonOption
 */
export const getPerformanceMeasureChartsComparisons = (
	GradePoints: Context.Gradepoint[],
	PerformanceMeasuresType: PerformanceMeasuresTypes,
	Comparisons: {
		Key: string;
		Value: string;
	}[]
): Promise<
	ResponseBuilder<IbPerformanceMeasuresGraphData.Comparisons<IbPerformanceMeasures.ApiResponse>>
> => {
	return fetch(
		'/bigfoot/V2/KS5PerformanceMeasures/KS5PerformanceMeasures/GetKS5PerformanceMeasuresCharts',
		{
			method: 'POST',
			body: {
				GradePoints,
				Comparisons,
				PerformanceMeasureTypeId: PerformanceMeasuresType,
			},
		},
		true
	);
};
