import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { StrategicAnalysisOverview } from 'features/strategicAnalysis/types/overview';
import { theme } from 'basecamp';
import { ExcelStyle } from 'ag-grid-community';
import normalizeKey from '../../../utils/normalizeKey';
import _ from 'lodash';
import { StrategicAnalysis } from '../types';
import { AgChartThemeName } from 'ag-charts-community';

/**
 * Charts
 */
const dataVisColours = [
	theme.colors.DATAVIS.connect[0],
	theme.colors.DATAVIS.connect[1],
	theme.colors.DATAVIS.connect[2],
	theme.colors.DATAVIS.connect[3],
	theme.colors.DATAVIS.connect[4],
	theme.colors.DATAVIS.connect[5],
];

export const customChartThemes = {
	myCustomTheme: {
		baseTheme: 'ag-default' as AgChartThemeName,
		palette: {
			fills: dataVisColours,
			strokes: dataVisColours,
		},
		overrides: {
			common: {
				title: {
					fontSize: 22,
					fontFamily: 'open_sans_regular, sans-serif',
				},
			},
		},
	},
};

/**
 * Excel Styling
 */
export const excelStyles: ExcelStyle[] = [
	{
		id: 'header',
		interior: {
			color: '#aaaaaa',
			pattern: 'Solid',
		},
	},
	{
		id: 'body',
		interior: {
			color: '#dddddd',
			pattern: 'Solid',
		},
	},
];

/**
 * Flatten data coming from the API
 * @param response Raw grid data from redux
 * @returns Response data with gridValues obj flattened
 */
export const flattenResponse = (
	response: StrategicGridData.Rows<StrategicAnalysisOverview.ResponseRow>
) => {
	return {
		id: response.id,
		byId: Object.entries(response.byId).reduce((acc, [key, val]) => {
			// Flatten the gridValues object and normalise keys
			const flattenGridValues = Object.entries(val.gridValues).reduce((acc, [key, val]) => {
				const normalisedField = normalizeKey(key).toLowerCase();
				return {
					...acc,
					[normalisedField]: val,
				};
			}, {});
			// Clean up after ourselves and remove the unused obj
			const { gridValues: _, ...removeGridValuesObj } = val;

			return {
				...acc,
				[key]: Object.assign(flattenGridValues, { ...removeGridValuesObj }),
			};
		}, {}),
		allIds: response.allIds,
	};
};

/**
 * Util to compare equality of applied / selected object arrays
 * @param arr1 First array
 * @param arr2 Second array
 * @returns boolean
 */
export const appliedAndSelectedAreEqual = <T>(arr1: T[], arr2: T[]) =>
	_(arr1).xorWith(arr2, _.isEqual).isEmpty();

/**
 * Takes a comparison option and makes a key value pair.
 * Useful when you do not want the pipe in the comparison for display purposes
 * @param comparisons The comparison option to break down into key value pair
 * @returns A key value pair of the selected comparison option
 */
export const GetKeyValueFromComparisonOption = (
	comparisons: StrategicAnalysis.ComparisonOption
) => {
	return comparisons.reduce(
		(acc, curr) => {
			const keyVal = curr.value.split('|');
			return [
				//@ts-ignore
				...acc,
				{
					Key: keyVal[0],
					Value: keyVal[1],
				},
			];
		},
		[] as {
			Key: string;
			Value: string;
		}[]
	);
};
