import { translate } from '../../../utils/locale';
/**
 ** Connect Routes
 */
export const connectSubjectPage: (
	params: { [key: string]: string },
	isLoading: boolean,
	SAVE_REPORTS_ENABLED: boolean,
	canViewKS4SubjectPages?: boolean,
	canViewAttainmentPage?: boolean
) => PrimaryNavigation.Route[] = (
	params,
	isLoading,
	SAVE_REPORTS_ENABLED,
	canViewKS4SubjectPages,
	canViewAttainmentPage
) => {
	const isVisible = (p: string): boolean => params.subjectPage !== p;

	return [
		{
			name: translate('primaryNavigation.connect.subjects.OVERVIEW') as string,
			path: '/overview',
			isDisabled: isLoading,
			isVisible: isVisible('overview'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.ATTAINMENT') as string,
			path: '/AttainmentProfile',
			isDisabled: isLoading,
			isVisible: canViewAttainmentPage && isVisible('AttainmentProfile'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.STUDENTS') as string,
			path: '/students',
			isDisabled: isLoading,
			isVisible: isVisible('students'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.OUTCOMES') as string,
			path: '/outcomes',
			isDisabled: isLoading,
			isVisible: isVisible('outcomes'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.TRENDS') as string,
			path: '/trends',
			isDisabled: isLoading,
			isVisible: isVisible('trends'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.FINE_GRADES') as string,
			path: '/fine-grades',
			isDisabled: isLoading,
			isVisible: isVisible('fine-grades'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.SAVED') as string,
			path: '/saved',
			isDisabled: isLoading,
			isVisible: SAVE_REPORTS_ENABLED && isVisible('saved'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.GAP') as string,
			path: '/gap-analysis',
			isDisabled: isLoading,
			isVisible: canViewKS4SubjectPages && isVisible('gap-analysis'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.COHORT') as string,
			path: '/cohort-profile',
			isDisabled: isLoading,
			isVisible: canViewKS4SubjectPages && isVisible('cohort-profile'),
			type: 'subjectPage',
		},
		{
			name: translate('primaryNavigation.connect.subjects.HOME') as string,
			path: '/home',
			isDisabled: isLoading,
			isVisible: canViewKS4SubjectPages && isVisible('home'),
			type: 'subjectPage',
		},
	];
};
