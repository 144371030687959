import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { call, cancel, put, retry, select } from 'redux-saga/effects';
import { fetchMainGridData } from '../../../../api/strategicAnalysisAPI';
import { getAppliedGradepoints, setStrategicLoading } from '..';
import { flattenResponse } from 'features/strategicAnalysis/utils';
import { Context } from 'features/app/types';
import { getBenchmarkSetName } from 'features/app/redux/context';
import { SagaIterator } from '@redux-saga/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { AnalysisPages } from 'features/strategicAnalysis/enums';
import { ExamLevels } from '../../../../types/enum';

/**
 * Saga to call the API and fetch the main grid data
 * @param strategicAnalysisPage the strategic page we are working with
 * @param tab the active tab
 */
//TODO: Go through and check types
export function* fetchGridData(
	strategicAnalysisPage: StrategicAnalysis.AnalysisPage,
	tab: { examLevel: StrategicAnalysis.ExamLevels; index: number }
): SagaIterator<void> {
	const page = strategicAnalysisPage;
	const gradepoints: Context.Gradepoint[] = yield select(getAppliedGradepoints);
	const benchmarkSet: string = yield select(getBenchmarkSetName);

	if (strategicAnalysisPage === AnalysisPages.T_SCORE && tab.examLevel === ExamLevels.KS4) {
		yield put(setStrategicLoading(false));
		yield cancel();
	}

	try {
		/**
		 * 2. call the API
		 */
		const SECOND = 1000;
		const response: ResponseBuilder<StrategicGridData.GridData> = yield retry(
			3, // Try calling 3 times
			10 * SECOND, // with a delay of 10 seconds
			fetchMainGridData, // API to call
			page, // With these args
			tab.examLevel,
			benchmarkSet,
			gradepoints
		);

		// Check the response builder to see if any errors occurred or response is empty
		if (!response || response.hasError) {
			// 2a. set active tab so we can ensure a retry, set error
			yield put({
				type: `strategicAnalysis${page}/setMainGridDataFail`,
				payload: {
					examLevel: tab.examLevel,
					//@ts-ignore
					errorMsg: !response ? 'Grid data is empty' : response.errors[0].message,
				},
			});
			yield put(setStrategicLoading(false));
			yield cancel();
		}

		// Flatten the gridValues object in the response
		const flatten = {
			...response.responseObject,
			rows: flattenResponse(response.responseObject.rows),
		};

		// 2b. put the data in redux
		yield put({ type: `strategicAnalysis${page}/setMainGridDataSuccess`, payload: flatten });
	} catch (error) {
		// 2c. set error message
		yield put({
			type: `strategicAnalysis${page}/setMainGridDatafail`,
			payload: {
				examLevel: tab.examLevel,
				errorMsg: `The following error occurred whilst fetching the overview data - ${error}`,
			},
		});
	}
}

/**
 * Saga to call the API and fetch the main grid data, has loading flags as this is called by itself
 * unlike the one above which is always part of another flow that has its own loading flags
 * @param strategicAnalysisPage the strategic page we are working with
 * @param tab the active tab
 */
export function* loadGridData(
	action: PayloadAction<{
		strategicAnalysisPage: StrategicAnalysis.AnalysisPage;
		tab: { examLevel: StrategicAnalysis.ExamLevels; index: number };
	}>
) {
	const { tab, strategicAnalysisPage } = action.payload;
	// 1. set loading to true
	yield put(setStrategicLoading(true));

	// 2. fetch the data
	yield call(fetchGridData, strategicAnalysisPage, tab);

	// 3. set loading to true
	yield put(setStrategicLoading(false));
}
