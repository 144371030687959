import { SagaIterator } from 'redux-saga';
import { put, retry, select } from 'redux-saga/effects';
import { sendEmailForValidationRequest } from '../../../../api/mfaAPI';
import { sagaFlowConfigurations } from '../../../../constants';
import { MfaType } from '../../../../utils/mfa';
import { getToken } from 'features/app/redux/context';

/**
 * Saga that dispatches the available authentication types sequentially at log in
 */
export function* sendEmailAuthenticationFlow(): SagaIterator<void> {
	try {
		//Fetch the token returned from lfa login or a previous mfa validation
		const token = yield select(getToken);

		const response: ResponseBuilder<boolean> = yield retry(
			sagaFlowConfigurations.apiRetry, // Retry
			sagaFlowConfigurations.milliSeconds, // Delay between retry (MS)
			sendEmailForValidationRequest,
			MfaType.EMAIL_VALIDATION,
			token
		);

		if (!response || response.hasError) {
			//Log error
			yield put({
				type: `mfaValidation/setMfaValidationError`,
				payload: response.Errors[0].Message,
			});
		}

		//Confirm the email has been sent
		yield put({
			type: `mfaValidationEmailAuthentication/setConfirmationOfSecretStorage`,
			payload: response.ResponseObject,
		});
	} catch (e) {
		//Set the error
		yield put({
			type: `mfaValidationEmailAuthentication/setMfaValidationError`,
			payload: e,
		});
	}
}
