import { sanitizeFilename } from '../utils/fileExports';
import { saveAs } from 'file-saver';
import fetch from './';

// Calls the new PDF report endpoint to retrieve a generated PDF report
export const fetchPDF = (pdfReference: string, filename: string): Promise<void> => {
	// this does not require the use new export feature flag
	return fetch('/api/dataapi/pdfreports?key=' + pdfReference, {
		headers: {
			Accept: '*/*',
			'Content-Type': 'application/pdf',
		},
		output: 'PDF',
		responseType: 'blob',
	})
		.then((blob: Blob) => saveAs(blob, sanitizeFilename(filename, 'pdf')))
		.then(() => {
			// remove the generated report from storage
			fetch('/api/dataapi/pdfreports?key=' + pdfReference, {
				method: 'DELETE',
			});
		});
};
