import * as React from 'react';
import { FunctionComponent } from 'react';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { Flex, Text } from 'basecamp';
type Props = {
	params: ICellRendererParams;
	target: string[];
};

const spoGradesAchieved: FunctionComponent<Props> = ({ params, target }) => {
	const numberOnTarget = params.data[target];

	/**
	 ** Render Component
	 */
	return (
		<Flex setAs="flex" flexWrap="wrap" withAlign="center" pt={2} pl={2}>
			<Text>{numberOnTarget}</Text>
		</Flex>
	);
};

export default spoGradesAchieved;
