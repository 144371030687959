import { SagaIterator } from 'redux-saga';
import { put, retry } from 'redux-saga/effects';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';
import { fetchUserAuthenticationData } from '../../../../api/mfaAPI';
import { sagaFlowConfigurations } from '../../../../constants';

/**
 * Saga that fetches user authentication configuration
 */
export function* getUserConfigurationFlow(connectPage: string): SagaIterator<void> {
	try {
		const response: ResponseBuilder<MultiFactorAuthentication.UserDataMfaResponse[]> = yield retry(
			sagaFlowConfigurations.apiRetry, // Retry
			sagaFlowConfigurations.milliSeconds, // Delay between retry (MS)
			fetchUserAuthenticationData
		);

		if (response.hasError) {
			//Set error
			yield put({
				type: `${connectPage}/setMfaSetUpError`,
				payload: response.Errors[0].Message,
			});
		}

		//Add data to redux - This will be empty if no values
		yield put({
			type: `${connectPage}/setUserConfiguration`,
			payload: response.ResponseObject,
		});
	} catch (e) {
		yield put({
			type: `${connectPage}/setMfaSetUpError`,
			payload: e,
		});
	}
}
