import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUsers, userPermissions, getDataSharing } from '../../../api/manageUsersAPI';
import moment from 'moment';

/**
 * Initial State
 */
const initialState: ManageUsers.State = {
	loading: false,
	user: undefined,
	bulk: undefined,
	message: undefined,
	contract: [],
	enable: false,
	showReset: false,
};

/**
 * User Slice
 */
const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		listUsersPending(state: ManageUsers.State) {
			state.loading = true;
		},
		listUsersSuccess(state: ManageUsers.State, action: PayloadAction<Array<ManageUsers.User>>) {
			state.user = action.payload;
			state.loading = false;
		},
		listUsersFail(state: ManageUsers.State, action: PayloadAction<ManageUsers.Message>) {
			state.loading = false;
			state.message = action.payload;
		},
		updatePrimaryContact(state: ManageUsers.State, action: PayloadAction<ManageUsers.User>) {
			const payload = action.payload;
			state.enable = true;
			state.user = state.user?.map((user: ManageUsers.User) => {
				return {
					...user,
					isDirty: payload.id === user.id,
					isPrimaryDataContact: payload.id === user.id,
				};
			});
		},
		updateLoadingUser(state: ManageUsers.State, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		updateIsAlpsChampion(state: ManageUsers.State, action: PayloadAction<ManageUsers.User>) {
			const payload = action.payload;
			state.enable = true;

			state.user = state.user?.map((user: ManageUsers.User) => {
				return {
					...user,
					isDirty: payload.id === user.id ? !payload.isDirty : user.isDirty,
					isChamp: payload.id === user.id ? !payload.isChamp : user.isChamp,
				};
			});
		},
		addActiveUserSuccess(state: ManageUsers.State, action: PayloadAction<ManageUsers.User>) {
			state.loading = false;
			state.user = [...(state.user ?? []), action.payload, (action.payload.isActive = true)];
		},
		addUserFail(state: ManageUsers.State, action: PayloadAction<ManageUsers.Message>) {
			state.message = action.payload;
			state.loading = false;
		},
		editUserSucees(state: ManageUsers.State, action: PayloadAction<ManageUsers.User>) {
			const index = state.user?.findIndex((user) => user.id === action.payload.id) ?? -1;

			if (index >= 0 && state.user) {
				let userArr = [...state.user];

				let singleUser = {
					...userArr[index],
					...action.payload,
				};
				userArr[index] = singleUser;
				state.user = userArr;
			}
		},
		editContractSucess(
			state: ManageUsers.State,
			action: PayloadAction<Array<ManageUsers.Contract>>
		) {
			let updatedIds = action.payload.map((x) => x.contractId);
			let contracts = state.contract.filter((x) => updatedIds.indexOf(x.contractId) == -1);

			return {
				...state,
				enable: true,
				contract: [...contracts, ...action.payload],
			};
		},
		permissionsSuccess(state: ManageUsers.State, action: PayloadAction<ManageUsers.User>) {
			state.loading = false;
			const payload = action.payload;

			const index = state.user?.findIndex((user) => user.id === payload.id) ?? -1;
			if (index >= 0 && state.user) {
				let userArr = [...state.user];
				let singleUser = {
					...userArr[index],
					permissions_MyAlps_Administrator: payload.permissions_MyAlps_Administrator,
					permissions_Groups_Setup: payload.permissions_Groups_Setup,
					permissions_DataExchange_Mode: payload.permissions_DataExchange_Mode,
				};
				userArr[index] = singleUser;
				state.user = userArr;
			}
		},
		permissionsFail(state: ManageUsers.State, action: PayloadAction<ManageUsers.Message>) {
			state.message = action.payload;
			state.loading = false;
		},
		updateUserDetails(state: ManageUsers.State, action: PayloadAction<ManageUsers.User>) {
			state.loading = false;
			state.user = [...(state.user ?? []), action.payload];
		},
		listContractsSuccess(
			state: ManageUsers.State,
			action: PayloadAction<Array<ManageUsers.Contract>>
		) {
			state.loading = false;
			state.contract = action.payload;
		},
		listContractsFail(state: ManageUsers.State, action: PayloadAction<ManageUsers.Message>) {
			state.loading = false;
			state.message = action.payload;
		},
		listContractsPending(state: ManageUsers.State) {
			state.loading = true;
		},
		setSaveFooter(state: ManageUsers.State, action: PayloadAction<boolean>) {
			state.enable = action.payload;
		},
	},
});

/**
 * Export Action
 */
export const {
	listUsersPending,
	listUsersSuccess,
	listUsersFail,
	updateLoadingUser,
	updatePrimaryContact,
	updateIsAlpsChampion,
	addActiveUserSuccess,
	addUserFail,
	permissionsSuccess,
	listContractsFail,
	listContractsPending,
	listContractsSuccess,
	editUserSucees,
	setSaveFooter,
	updateUserDetails,
	editContractSucess,
} = userSlice.actions;

/**
 * Export Selectors
 */
export const isLoading = (context: RootState): boolean => context.manageUsers.loading;

/**
 * Calcualte the number of elapsed days in the users trial
 * @param user - the user
 * @returns the number of elapsed days
 */
const handleElapsedDays = (user: ManageUsers.User) => {
	const { startDate, endDate } = user;

	// Add + 1 on the end if we need to include the start date
	return moment(endDate).diff(moment(startDate), 'days');
};

//Gets disabled/deactivated users
export const GetDisabledUsers = (context: RootState): ManageUsers.User[] | undefined =>
	context.manageUsers.user?.filter((x: ManageUsers.User) => !x.isActive);

//Gets active users
export const GetActiveUsers = (context: RootState): ManageUsers.User[] | undefined => {
	const users = context.manageUsers.user?.filter(
		(x: ManageUsers.User) => x.isActive || x.isPending
	);

	// If the user has a start and end date available, calculate the number of elapsed days
	return users?.map((user: ManageUsers.User) => {
		return !!user.startDate && !!user.endDate
			? {
					...user,
					startDate: moment(user.startDate).format('DD/MM/YYYY'),
					endDate: moment(user.endDate).format('DD/MM/YYYY'),
					daysElapsed: handleElapsedDays(user),
			  }
			: user;
	});
};

//select users from id
export const GetCurrentUser = (context: RootState, id: number): ManageUsers.User | undefined => {
	return context.manageUsers?.user?.find((x: ManageUsers.User) => x.id === id);
};

//List the avaliable contracts
export const GetContracts = (context: RootState): ManageUsers.Contract[] =>
	context.manageUsers?.contract;

//Enable Save Footer
export const enableSave = (context: RootState): boolean => context.manageUsers?.enable;

//Show reset
export const cancelModal = (context: RootState): boolean => context.manageUsers?.showReset;

/**
 * Export Reducer
 */
export default userSlice.reducer;

/**
 * Thunk Creator
 */

//Lists Users
export const listUsersThunk = (): AppThunk => async (dispatch) => {
	dispatch(listUsersPending());

	getUsers()
		.then((response) => {
			dispatch(listUsersSuccess(response));
		})
		.catch(() => {
			dispatch(
				listUsersFail({
					text: 'Please speak to customer services',
				} as ManageUsers.Message)
			);
		});
};

// permissions thunk
export const listpermissionsThunk = (userId: number): AppThunk => async (dispatch) => {
	dispatch(listUsersPending());

	userPermissions(userId)
		.then((response) => {
			dispatch(permissionsSuccess(response));
		})
		.catch(() => {
			dispatch(
				listUsersFail({
					text: 'Please speak to customer services',
				} as ManageUsers.Message)
			);
		});
};

export const listDatContract = (): AppThunk => async (dispatch) => {
	getDataSharing()
		.then((response) => {
			dispatch(listContractsSuccess(response));
		})
		.catch(() => {
			dispatch(
				listContractsFail({
					text: 'Please speak to customer services',
				} as ManageUsers.Message)
			);
		});
};
