import { all } from 'redux-saga/effects';
import { strategicAnalysisSagas } from 'features/strategicAnalysis/redux/sagas';
import { subjectAreaSagas } from 'features/subjectArea/redux/sagas';
import { manageAccountSagas } from 'features/manageAccount/redux/sagas';
import { authSagas } from 'features/auth/redux/sagas';

function* rootSaga() {
	yield all([...strategicAnalysisSagas]);
	yield all([...subjectAreaSagas]);
	yield all([...manageAccountSagas]);
	yield all([...authSagas]);
}

export default rootSaga;
