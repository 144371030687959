import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex } from 'basecamp';

type Props = {
	dataTest?: string;
	height: number;
	index: number;
	paramKeys: string[];
};

/**
 ** Handles the rendering of the home icon / welcome text on homepage
 */
const PrimaryNavDDWrapper: FunctionComponent<Props> = ({
	dataTest,
	children,
	height,
	paramKeys,
	index,
}) => {
	const minWidth: { [key: string]: number } = {
		subject: 400,
		reportsPage: 300,
	};

	/**
	 ** Render Component
	 */
	return (
		<Flex
			setAs="column"
			pt={2}
			position="absolute"
			top={height}
			left="-16px"
			width="calc(100% + 100px)"
			minWidth={minWidth[paramKeys[index - 1]]}
		>
			<Flex
				dataTest={dataTest ? `${dataTest} primaryNavDDWrapper` : 'primaryNavDDWrapper'}
				setAs="column"
				bg="UI.secondary.1"
				width={1}
				overflowY="auto"
				maxHeight="300px"
				zIndex="500"
				borderRadius={3}
				boxShadow="lg"
			>
				{children}
			</Flex>
		</Flex>
	);
};

export default PrimaryNavDDWrapper;
