import { takeLatest, throttle } from 'redux-saga/effects';
import { executeMultiFactorAuthenticationFlow } from './executeMultiFactorAuthenticationSaga';
import { sendEmailAuthenticationFlow } from './sendEmailAuthenticationSaga';
import { loginFlow } from './loginSaga';

/**
 * Export all Auth Sagas in one array
 * This is imported into a root saga in the store.
 * Root saga is effectively the same as the root reducer.
 */
export const authSagas = [
	throttle(1000, 'context/triggerSagaLoop', executeMultiFactorAuthenticationFlow),

	takeLatest('context/triggerLogin', loginFlow),
	takeLatest('context/mfaLogin', executeMultiFactorAuthenticationFlow),
	takeLatest('context/resendAuthenticationEmail', sendEmailAuthenticationFlow),
];
