import React, { FunctionComponent } from 'react';
import { Heading, Modal, Text } from 'basecamp';
import { logoutThunk } from 'features/app/redux/context';
import { useDispatch } from 'react-redux';
import { translate } from '../../utils/locale';
import {
	setExportPDFAcknowledgement,
	setExportPDFIsOpen,
} from 'features/app/redux/notificationModals';

const PDFExportModal: FunctionComponent = () => {
	const dispatch = useDispatch();

	return (
		<Modal
			setAs="small"
			withTitle={''}
			andBodyPadY={0}
			withActions={[
				{
					title: translate('modalMessages.pdfExportModal.ACCEPT') as string,
					type: 'accent',
					onClick() {
						dispatch(setExportPDFAcknowledgement());
					},
				},
			]}
			withCancelText={translate('modalMessages.pdfExportModal.CLOSE') as string}
			close={() => {
				dispatch(setExportPDFIsOpen());
			}}
		>
			<Heading setAs={'medium'} withColor="black">
				{translate('modalMessages.pdfExportModal.TITLE') as string}
			</Heading>

			<Text>{translate('modalMessages.pdfExportModal.BODY') as string}</Text>
		</Modal>
	);
};

export default PDFExportModal;
