import { ICellRendererParams } from '@ag-grid-community/all-modules';
import defaultRenderer from './defaultRenderer';
import padLeft from '../../utils/padLeft';

const ScoreRenderer = (params: ICellRendererParams) => {
	return defaultRenderer({
		...params,
		value: params.value
			? padLeft(params.value, 2)
			: params.value === undefined
			? '-'
			: params.value,
	});
};

export default ScoreRenderer;
