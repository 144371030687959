/**
 * @param storageKey string that is either the local or session storage key
 * @param isSessionStorage Boolean flag to point at Session or Local storage
 * @returns Boolean value
 */
export const getBooleanFromSessionStorage = (
	storageKey: string,
	isSessionStorage: boolean
): boolean => {
	const storedValue = isSessionStorage
		? window.sessionStorage.getItem(storageKey)
		: window.localStorage.getItem(storageKey);

	if (storedValue === null) {
		return false;
	} else {
		return storedValue.toLowerCase() === 'true';
	}
};
