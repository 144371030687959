/**
 * Embedded table selectors
 */

import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { getSlice } from './';

/**
 * Gets the embedded table for the active analysis page
 * @param page the active analysis page
 * @param gridId the grid id to get the embedded table for
 * @returns Grid data for the embedded table
 */
export const getActivateEmbeddedTableGrid = (
	page: StrategicAnalysis.AnalysisPage,
	gridId: string
) => (state: RootState): StrategicGridData.Grid<{}> | undefined => {
	const slice = getSlice(page)(state);

	return slice.embeddedTable?.grids?.byId[gridId];
};

/**
 * Gets the embedded table rows for the active analysis page
 * @param page the active analysis page
 * @param rowIds the row ids to get the embedded table rows for
 * @returns Grid rows for the embedded table
 */
export const getEmbeddedTableRows = (
	page: StrategicAnalysis.AnalysisPage,
	rowIds: string[] | undefined
) => (state: RootState): StrategicGridData.GridRow[] | undefined => {
	const slice = getSlice(page)(state);
	const allRows = slice.embeddedTable?.rows;

	return !!(allRows && rowIds) ? rowIds.flatMap((id) => allRows.byId[id]) : undefined;
};

/**
 * Gets the active embedded table id
 * @param page the active analysis page
 * @returns the active embedded table id
 */
export const getActiveEmbeddedGridId = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): string | undefined => {
	const slice = getSlice(page)(state);

	return slice.embeddedTable?.activeEmbeddedTableId;
};

/**
 * Gets the active embedded table grid
 * @param page
 * @param gridId
 * @returns
 */
export const getActivateEmbeddedTableExcel = (
	page: StrategicAnalysis.AnalysisPage,
	gridId: string | undefined
) => (
	state: RootState
):
	| {
			colDefs: StrategicGridData.Columns | undefined;
			rows: StrategicGridData.GridRow[] | undefined;
	  }
	| undefined => {
	if (!gridId) return;

	const activeGrid = getActivateEmbeddedTableGrid(page, gridId)(state);
	const activeGridRows = getEmbeddedTableRows(page, activeGrid?.rowIds)(state);

	return {
		colDefs: activeGrid?.colDef,
		rows: activeGridRows,
	};
};

/**
 * Gets embedded table loading status
 * @param page the active analysis page
 * @returns boolean
 */
export const fetchEmbeddedLoading = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): boolean => {
	const slice = getSlice(page)(state);
	return Boolean(slice?.embeddedTableLoading);
};

/**
 * Gets the active embedded table data
 * @param page the active analysis page
 * @returns the active embedded table
 */
export const fetchActiveEmbeddedTable = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): string | undefined => {
	const slice = getSlice(page)(state);
	// TODO - check this is correct, should it be activeEmbeddedTableId like the others?
	return slice.activeEmbeddedTable;
};

