import fetch from './';
import { convertBitFlagToObj, checkForCurrentEntitlement } from '../utils/entitlement';
import examLevelsModel from '../utils/examLevel';
import moment from 'moment';
import { getMATViewAs } from '../utils/impersonation';
import { ConvertCountryToEnum } from '../utils/country';
import { fetchReport } from './reportAPI';
import { autoGradepointPopulation } from '../utils/gradepointSelector';
import { constants } from '../constants';
import { getBooleanFromSessionStorage } from '../utils/storageSessionHelpers';
import { Context } from 'features/app/types';

/**
 * Get the initial app context from Context/ConnectCtx
 */
export const getAppContext = (): Promise<Context.State> => {
	// If this is a session-based MAT 'view as school', build the context request body
	// to act as impersonation
	//@ts-ignore
	let body: { groupAccessId: number | null; matImpersonateClientId: number } = {};
	const { groupAccessId, matImpersonateClientId } = getMATViewAs();
	const isMATImpersonation = groupAccessId && matImpersonateClientId;
	if (isMATImpersonation) {
		body.groupAccessId = Number(groupAccessId);
		body.matImpersonateClientId = Number(matImpersonateClientId);
	}

	return fetch('/api/Context/ConnectCtx', { body }).then((response: any) =>
		mapApiResponseToContext(response, isMATImpersonation)
	);
};

/**
 * Get the reporting context from yeti reporting
 */
export const getReportingContext = (primaryGradePoint: Context.Gradepoint): Promise<any> => {
	const reportAPIOptions: ReportAPIOptions = {
		gradepoints: primaryGradePoint ? [primaryGradePoint] : [],
	};

	return fetchReport('Interactive.GradepointInfo', 'None', reportAPIOptions);
};

/**
 * Get the comparison and filter data from bigfoot
 * @param benchmarkSet string,
 * @param gradepoints Context.Gradepoint[],
 */
export const fetchFiltersAndComparisonsFromBigfootReq = (
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[]
): Promise<ResponseBuilder<ReportAPIOptions>> | null => {
	if (hasGradepoints(gradepoints))
		return fetch(
			`/bigfoot/v1/BigfootReq/FilterAndComparisons/GetComparisonsAndFilters`,
			{
				method: 'POST',
				body: {
					BenchmarkSet: benchmarkSet,
					GradePoints: gradepoints,
				},
			},
			true
		);
	else return null;
};

const hasGradepoints = (gradepoints: Context.Gradepoint[]): boolean => {
	return gradepoints.length > 0 && gradepoints[0] !== undefined;
	//When no gradepoints are set for school, somewhere in the code sets it so an array of 1 undefined...
};

export const getAllFiltersAndComparisons = (gradepoints: Context.Gradepoint[]): Promise<any> => {
	const reportAPIOptions: ReportAPIOptions = {
		gradepoints,
	};
	return fetchReport('Interactive.GradepointInfo', 'None', reportAPIOptions);
};

/**
 * Sets the cookie if the credentials are correct
 * @param {Auth.Login} loginModel
 */
export const login = (loginModel: Auth.Login): Promise<any> => {
	return fetch('/api/Auth/Login', {
		body: {
			EmailAddress: loginModel.emailAddress,
			Password: loginModel.password,
		},
	});
};

/**
 * Sets the cookie if the credentials are correct - returns new mfa logic
 * @param {Auth.Login} loginModel
 */
export const loginMfa = (
	loginModel: Auth.Login
): Promise<ResponseBuilder<Context.MfaLoginResponse>> => {
	return fetch('/api/Auth/Login/mfa', {
		body: {
			EmailAddress: loginModel.emailAddress,
			Password: loginModel.password,
		},
	}).then((response) => {
		return {
			...response,
			ResponseObject: {
				userId: response.ResponseObject.UserId,
				mfaToken: response.ResponseObject.MfaToken,
				isMfaActive: response.ResponseObject.IsMfaActive,
				isLoginSuccessful: response.ResponseObject.IsloginSuccessful,
				mfaActiveList:
					response.ResponseObject.MfaActiveList &&
					response.ResponseObject.MfaActiveList.map((x) => {
						return {
							isValidated: x.IsValidated,
							mfaTypeId: x.MfaTypeId,
							mfaTypeName: x.MfaTypeName,
						} as Context.MfaList;
					}),
			},
		} as Context.MfaLoginResponse;
	}) as Promise<ResponseBuilder<Context.MfaLoginResponse>>;
};

/**
 * Sets the cookie if the credentials are correct and links the user to the client via the activation details
 * @param {Auth.Login & Auth.Activation} loginAndActivateModel
 */
export const loginAndActivate = (
	loginAndActivateModel: Auth.Login & Auth.Activation
): Promise<ResponseBuilder<any>> => {
	return fetch(
		'/api/Auth/Login/LoginAndActivateClient',
		{
			body: {
				EmailAddress: loginAndActivateModel.emailAddress,
				Password: loginAndActivateModel.password,
				ActivationCode: loginAndActivateModel.activationCode,
				Postcode: loginAndActivateModel.postcode,
			},
		},
		true
	);
};

/**
 * Selects the default client
 */
export const autoSelectClient = (): Promise<any> => {
	return fetch('/api/Context/Client/AutoSelectClient', { body: {} });
};

/**
 * Call GetClinetList
 */

export const getSchoolList = (): Promise<Array<Select.LinkedSchool>> => {
	return fetch('/api/Context/Client/GetClientList', {
		body: {},
	}).then((response) => {
		const linkedClients = response.LinkedClients;

		return linkedClients.map((x: { Name: string; Id: number; IsDefault: boolean }) => {
			return {
				name: x.Name,
				id: x.Id,
				isDefault: x.IsDefault,
			} as Select.LinkedSchool;
		});
	});
};

/**
 * Select Client
 */
export const selectClient = (linkedSchool: Select.LinkedSchool): Promise<boolean> => {
	return fetch('/api/Context/Client/SelectClient', {
		body: {
			id: linkedSchool.id,
			makeDefault: linkedSchool.isDefault,
		},
	});
};

/**
 * Remove the cookie and log the user out
 */
export const logout = (): Promise<any> => {
	return fetch('/api/Auth/Logout');
};

export const acceptTermsAndConditions = (): Promise<boolean> => {
	return fetch('/api/TermsAndConditions/Accept', {
		method: 'POST',
		body: {
			AcceptTermsAndConditions: true,
		},
	});
};

/**
 * @param number trendId
 * @returns Fetch the gradepointKey of the MAT defiened trend
 */
export const fetchMatAppliedGpKeys = (
	trendId: number
): Promise<Array<Groups.Analysis.GroupTrendResponse>> => {
	return fetch(`/api/Groups/Member/Gradepoints/matAppliedGp/${trendId}`, {}).then((response) => {
		return response.ResponseObject;
	});
};

/**
 * Switches the current active benchmark to the inactive benchmark for a given client, while also returning the newly active benchmark
 * @returns
 */

export const switchActiveBenchmark = (): Promise<Context.AvailableBenchmarks> => {
	return fetch('/api/Settings/Benchmark/SwitchActiveBenchmark', {
		method: 'POST',
		body: {},
	}).then((response) => {
		return {
			active: response.ResponseObject.Active,
			benchmarkSet: response.ResponseObject.BenchmarkSet,
			benchmarkSetId: response.ResponseObject.BenchmarkSetId,
			type: response.ResponseObject.Type,
		} as Context.AvailableBenchmarks;
	});
};

/**
 * Wrapper that maps the response from the context API into a TypeScript object for the app
 * @param response Response from the app context api
 * Note: Only required whilst the API response is not structured in a like for like way
 * TODO: need to hook the runAutoGpPopulation function or change the approach, currently that data is coming from mock
 */
const mapApiResponseToContext = (response: any, isMATImpersonation: boolean): Context.State => {
	const entitlements = convertBitFlagToObj(response.Entitlements);

	const useClientBenchmark = getBooleanFromSessionStorage('useClientBenchmark', true);

	// Set the impersonate token in session storage if
	if (!!response?.ImpersonationToken) {
		sessionStorage.setItem('impersonationToken', response.ImpersonationToken);
	}

	let contextState = {
		requiresTermsAcceptance: response.RequiresTermsAcceptance,
		user: {
			username: response.Username,
			fullname: response.Fullname,
			id: response.UserId,
			impersonationMode: response.ImpersonationMode,
			jobTitle: response.UserJobTitle ?? null,
		} as Context.User,
		hasRenewedSubscription: response.HasRenewedSubscription,

		client: {
			name: response.ClientName,
			id: response.ClientId,
			country: {
				name: response.ClientCountry,
				type: ConvertCountryToEnum(response.ClientCountry),
			} as Context.Country,
			isContractOwner: response.IsContractOwner,
			hasGroupAccess: response.GroupAccessId && response.GroupAccessId > 0,
			reference: response.ClientReference,
			groupAccessId: response.GroupAccessId,
		} as Context.Client,

		roles: response.Roles,
		entitlements,
		currentEntitlement: checkForCurrentEntitlement(entitlements),
		showAlpsKeyContactsReminder: response.ShowAlpsKeyContactsReminder,
		alpsPrimaryDataContact: response.AlpsPrimaryDataContact,
		alpsChampions: response.AlpsChampions,
		alpsKeyContactsReminderLatestVersion: response.AlpsKeyContactsReminderLatestVersion,
		examLevels: response.AppInfo?.AvailableExamLevels,
		permissions: {
			canSwitchClientBenchmark: response.Permissions.BenchmarkToggle,
			groupsMonitoringTimeline: response.Permissions.Groups_MonitoringTimeline,
			groupsViewAsSchool: response.Permissions.Groups_ViewAsSchool,
		},
		isMfaActive: response.IsMfaEnabled,
		useClientBenchmark: isMATImpersonation ? useClientBenchmark : false,
	} as Context.State;

	if (response.DataInfo) {
		contextState.yetiToken = response.DataInfo.Token;
		contextState.reportContext = {
			currentDataset: {
				datasetItem: response.DataInfo.DataItem,
				benchmarkSet: response.DataInfo.BenchmarkSet,
				benchmarkSetId: response.DataInfo.BenchmarkSetId,
				sessionBenchmarkSet: response.DataInfo.BenchmarkSet,
				availableBenchmarks: response.DataInfo.AvailableBenchmarks?.map(
					(benchmarkOptions: {
						Active: any;
						BenchmarkSet: any;
						BenchmarkSetId: any;
						Type: any;
					}) => {
						return {
							active: benchmarkOptions.Active,
							benchmarkSet: benchmarkOptions.BenchmarkSet,
							benchmarkSetId: benchmarkOptions.BenchmarkSetId,
							type: benchmarkOptions.Type,
						};
					}
				),
			} as Context.CurrentDataset,
		};
	}

	if (response.AppInfo) {
		const appliedLevels: Array<string> = [];
		const examLevels: Array<number> = [];

		response.AppInfo.AvailableExamLevels.forEach((ael: any) => {
			//@ts-ignore
			if (examLevelsModel[ael] !== undefined) {
				examLevels.push(ael);
				//@ts-ignore
				appliedLevels.push(examLevelsModel[ael].key);
			}
		});

		let pgp: any;
		let selectedGradepoints;

		/**
		 * Get the user's saved primary gradepoint from localstorage
		 */
		const savedGPTrend = localStorage.getItem(constants.AlpsSavedGradepointTrend);
		const lsSavedGradepointTrend = savedGPTrend && JSON.parse(savedGPTrend);

		/**
		 * Check if the user's saved gradepoint trend has expired
		 */
		let savedGradepointTrendHasExpired =
			lsSavedGradepointTrend &&
			moment(lsSavedGradepointTrend.expiry).isBefore(moment().format('YYYY-MM-DD hh:mm:ss'));

		/**
		 * Get the users saved gradpoints if they exist and have not expired
		 */
		const savedGradepoints =
			lsSavedGradepointTrend && !savedGradepointTrendHasExpired ? lsSavedGradepointTrend.value : [];

		// Check the saved grade point info still exists
		if (savedGradepoints && savedGradepoints.length > 0) {
			const availableGradepoints = response.AppInfo.AvailableGradepoints;
			const savedGradepointsExists = savedGradepoints.every((savedGPKey: any) =>
				availableGradepoints.some((gp: any) => gp.key === savedGPKey)
			); // TODO: I got once savedGradepoints as a string not array and this line was throwing an error preventing me to login

			if (!savedGradepointsExists) {
				savedGradepointTrendHasExpired = true;
			}
		}

		/**
		 * Apply gradepoints
		 * If user has a saved gp trend in local storage
		 * Take primary gradepoint and saved trend from local storage
		 */
		if (
			savedGradepoints !== undefined &&
			savedGradepoints.length &&
			!savedGradepointTrendHasExpired
		) {
			pgp = savedGradepoints[savedGradepoints.length - 1];
			selectedGradepoints = savedGradepoints;
		} else {
			/**
			 * Other wise take pgp from initial context call
			 */
			if (response.AppInfo.AvailableGradepoints.length) {
				pgp =
					response.AppInfo.AvailableGradepoints[response.AppInfo.AvailableGradepoints.length - 1];
			}

			/**
			 * If there's anything saved in local storage remove it as it has expired
			 */
			localStorage.removeItem(constants.AlpsSavedGradepointTrend);

			if (response.AppInfo.AvailableGradepoints.length) {
				selectedGradepoints = autoGradepointPopulation(pgp, response.AppInfo.AvailableGradepoints);
			}
		}

		pgp =
			typeof pgp === 'object'
				? pgp
				: response.AppInfo.AvailableGradepoints.find(({ key }: any) => key === pgp);

		contextState.reportContext.currentDataset = {
			...contextState.reportContext.currentDataset,
			hasOrderedReports: response.AppInfo.HasOrderedReport,
			gradepoints: response.AppInfo.AvailableGradepoints,
			targetSettingGradepoints: response.AppInfo.TargetSettingGradepoints,
			primaryGradepoint: pgp,
			examLevels: examLevels,
			appliedGradepoints: selectedGradepoints,
		} as Context.CurrentDataset;
	}

	return contextState;
};
