/**
 * Grids selectors
 */

import { SubjectAreaGraphData } from 'features/subjectArea/types/common';
import { getAllComparisonData, getAllXAxisData, getSlice } from './';
import { SubjectArea } from 'features/subjectArea/types';

/**
 * Gets all the loaded grid data
 * @param state redux state
 * @returns An object byId / allIds for the loaded grid data
 */
export const getAllGraphData = (page: SubjectArea.AnalysisPage) => (
	state: RootState
): SubjectAreaGraphData.Grids | undefined => {
	const slice = getSlice(page)(state);
	return slice.graphs;
};

/**
 * Get the grid data for the active page and exam level
 * @param page the active analysis page
 * @param activeExamLevel the active exam level
 * @returns top level (all) rows with comparisons rows
 */
export const getGraphDataForExamLevel = (
	page: SubjectArea.AnalysisPage,
	activeExamLevel: SubjectArea.ExamLevels
) => (state: RootState): SubjectAreaGraphData.GraphDataForExamLevel | undefined => {
	const graphData = getAllGraphData(page)(state);

	const allXAxisData = getAllXAxisData(page)(state);

	const xAxisData = !!allXAxisData ? Object.values(allXAxisData.byId) : [];
	const getGridsForExamLevel = !!graphData
		? Object.values(graphData.byId).filter((data) => {
				return data.examLevelId === activeExamLevel;
		  })
		: [];

	const getGraphDataAddXAxisAndComparisonCount = getGridsForExamLevel.reduce((graphsAcc, graph) => {
		const graphValues = graph.xValueIds.length;
		const comparisonValues = xAxisData.reduce((acc, xAxis) => {
			return xAxis.graphId === graph.graphId ? acc + xAxis.comparisonIds.length : acc;
		}, 0);

		return [
			...graphsAcc,
			{
				...graph,
				rowCount: graphValues + comparisonValues,
			},
		];
	}, []);

	return getGridsForExamLevel.length > 0
		? { id: graphData!.id, graph: getGraphDataAddXAxisAndComparisonCount }
		: undefined;
};
