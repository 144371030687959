import React from 'react';
import { BaseElement, Button, Flex, Icon, Text, Heading, Box } from 'basecamp';
import { connect } from 'react-redux';
import { getConfig, getFeature } from 'features/app/redux/features';
import { translate } from '../../utils/locale';
import { apm } from '@elastic/apm-rum';

type Props = {
	children: any;
	showErrorInfo: boolean;
	enabled: boolean;
	blacklist: {
		types?: string[];
		messages?: string[];
	};
};

type State = {
	error: null | Error;
	errorInfo: null | React.ErrorInfo;
};

/*
	React doesn't yet support hooks for error boundaries
 */
class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			error: null,
			errorInfo: null,
		};
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		if (apm.isActive()) {
			apm.captureError(error);
			apm.setCustomContext(errorInfo);
		}

		const { enabled, blacklist } = this.props;

		const types = blacklist.types?.map((x) => x.toLowerCase()) ?? [];
		const messages = blacklist.messages?.map((x) => x.toLowerCase()) ?? [];
		const msgMatch = messages.reduce(
			(stop, msg) => stop || error.message.toLowerCase().indexOf(msg) > -1,
			false
		);

		// if the boundary is disabled, the error type is in the blacklist, or the message has a partial match
		if (!enabled || types.includes(error.name.toLowerCase()) || msgMatch) {
			return;
		}

		this.setState({
			error,
			errorInfo,
		});
	}

	render() {
		if (this.state.error) {
			return (
				<Flex
					setAs="column"
					withAlign="center"
					width={1}
					height={'100vh'}
					bg="UI.primary.0"
					fontSize="100px"
					color="UI.white"
					p={4}
				>
					<Flex setAs="column" withAlign="center" width={1}>
						<Icon icon={'triangle-exclamation'} />
						<BaseElement
							fontFamily="headings"
							fontSize="72px"
							color="UI.black"
							width={'60%'}
							textAlign={'center'}
						>
							{translate('error.boundary.TITLE')}
						</BaseElement>
						<Text setAs={'h5'} withColor={'white'}>
							{translate('error.boundary.MESSAGE')}
						</Text>
						<Button
							setAs={'error'}
							onClick={() => {
								window.location.href = '/';
							}}
						>
							{translate('error.boundary.RELOAD')}
						</Button>
					</Flex>

					{this.props.showErrorInfo && (
						<Flex
							setAs={'column'}
							mt={5}
							bg={'UIAlpha.black.2'}
							height={'50%'}
							width={'80%'}
							border={'UIAlpha.black.4'}
							flex={1}
						>
							<Flex height={'120px'} bg={'UIAlpha.black.4'} p={3} setAs={'column'}>
								<Heading setAs={'medium'} withColor={'error'}>
									{this.state.error.name}:
								</Heading>
								<Text setAs={'h5'} withColor={'white'}>
									{this.state.error.message}
								</Text>
							</Flex>
							{this.state.errorInfo?.componentStack && (
								<Flex p={3} setAs={'column'} overflowY={'scroll'} flex={1}>
									<Heading setAs={'medium'} withColor={'error'}>
										{translate('error.boundary.CALLSTACK')}
									</Heading>
									<Box>
										{this.state.errorInfo?.componentStack.split('\n').map((str) => (
											<Text p={0} withColor={'white'}>
												{str}
											</Text>
										))}
									</Box>
								</Flex>
							)}
						</Flex>
					)}
				</Flex>
			);
		}

		return this.props.children;
	}
}

export default connect((state: RootState) => ({
	enabled: getFeature('enable_error_boundary')(state),
	showErrorInfo: getFeature('show_error_boundary_info')(state),
	blacklist: getConfig('error_boundary_blacklist')(state),
}))(ErrorBoundary);
