import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	getOrganisationDetails,
	updateOrganisationDetails,
} from '../../../api/organisationDetailsAPI';
import { OrganisationDetailsManagement } from '../types';

/**
 * Inital State
 */
const initialState: OrganisationDetailsManagement.State = {
	loading: false,
	organisationDetails: undefined,
	saveStatus: undefined,
	loadFailed: false,
};

/**
 * Report Slice
 */
const organisationManagementSlice = createSlice({
	name: 'organisationManagement',
	initialState,
	reducers: {
		setOrganisationDetailsLoading(state: OrganisationDetailsManagement.State) {
			return {
				...state,
				loading: true,
				saveStatus: undefined,
			};
		},
		setOrganisationDetailsSuccess(
			state: OrganisationDetailsManagement.State,
			action: PayloadAction<OrganisationDetailsManagement.OrganisationDetails>
		) {
			return {
				...state,
				loading: false,
				organisationDetails: action.payload,
			};
		},
		setOrganisationDetailsLoadFailure(state: OrganisationDetailsManagement.State) {
			return {
				...state,
				loading: false,
				loadFailed: true,
			};
		},
		updatingOrganisationDetails(state: OrganisationDetailsManagement.State) {
			return {
				...state,
				loading: true,
				saveStatus: undefined,
			};
		},
		setUpdateOrganisationDetailsSaveStatus(
			state: OrganisationDetailsManagement.State,
			action: PayloadAction<boolean>
		) {
			return {
				...state,
				saveStatus: action.payload,
			};
		},
	},
});

/**
 * Export Actions
 */
export const {
	setOrganisationDetailsLoading,
	setOrganisationDetailsSuccess,
	updatingOrganisationDetails,
	setUpdateOrganisationDetailsSaveStatus,
	setOrganisationDetailsLoadFailure,
} = organisationManagementSlice.actions;

/**
 * Export Selectors
 */
export const isLoading = (state: RootState): boolean => state.organisationManagement.loading;
export const getOrganisationDetailsSelector = (
	context: RootState
): OrganisationDetailsManagement.OrganisationDetails | undefined =>
	context.organisationManagement.organisationDetails;

export const getSaveStatusSelector = (state: RootState) => {
	return state.organisationManagement.saveStatus;
};

export const getLoadFailureSelector = (state: RootState) => {
	return state.organisationManagement.loadFailed;
};

/**
 * Export Reducer
 */
export default organisationManagementSlice.reducer;

/**
 * Export Thunks
 */
export const fetchOrganisationDetailsThunk = (): AppThunk => async (dispatch) => {
	dispatch(setOrganisationDetailsLoading());

	return getOrganisationDetails()
		.then((response) => {
			if (response.HasError) {
				dispatch(setOrganisationDetailsLoadFailure());
			} else {
				dispatch(setOrganisationDetailsSuccess(response.ResponseObject));
			}
			return response;
		})
		.catch(() => {
			dispatch(setOrganisationDetailsLoadFailure());
		});
};

export const updateOrganisationDetailsThunk = (organisationDetails: any): AppThunk => async (
	dispatch
) => {
	dispatch(updatingOrganisationDetails());
	return updateOrganisationDetails(organisationDetails)
		.then((response) => {
			if (response.HasError) {
				dispatch(setUpdateOrganisationDetailsSaveStatus(false));
			} else {
				dispatch(setUpdateOrganisationDetailsSaveStatus(true));
				dispatch(setOrganisationDetailsSuccess(response.ResponseObject));
			}
		})
		.catch(() => {
			dispatch(setUpdateOrganisationDetailsSaveStatus(false));
		});
};
