import fetch from '.';

/**
 * Get the the subject by using a string lookup in the api
 */
export const getSubjectsByStringApi = (
	benchmarkId: number,
	subjectString: string,
	academicYear?: number
): Promise<ResponseBuilder<SubjectBedrock>> => {
	return fetch('/bedrock/v1/Subjects/Subjects/GetSubjectByString', {
		method: 'POST',
		body: {
			BenchmarkId: benchmarkId,
			SubjectId: null,
			SubjectString: subjectString,
			Reportlevel: null,
			AcademicYear: academicYear,
			UseDisplayNameOnFallback: false,
		},
	});
};

/**
 * Get the subjects by supplying a list of subject ids
 */
export const getSubjectsByIdApi = (
	subjectIds: number[]
): Promise<ResponseBuilder<Array<SubjectBedrock>>> => {
	return fetch('/bedrock/v1/Subjects/Subjects/GetSubjectsById', {
		method: 'POST',
		body: {
			SubjectIds: subjectIds,
		},
	});
};

/**
 * Get the full subject info by supplying a list of subject names
 */
export const getSubjectsByNameApi = (
	subjectNames: string[],
	benchmarkId: number,
	academicYear?: number
): Promise<ResponseBuilder<Array<SubjectBedrock>>> => {
	return fetch('/bedrock/v1/Subjects/Subjects/GetSubjectsByName', {
		method: 'POST',
		body: {
			SubjectNames: subjectNames,
			BenchmarkId: benchmarkId,
			AcademicYear: academicYear,
		},
	});
};

/**
 * Get the comparison for a givene type i.e. gender, ethnicity, etc
 */
export const getFieldComparisonsByApi = (
	benchmarkId: number,
	field: string
): Promise<ResponseBuilder<Array<ComparisonBedrock>>> => {
	return fetch('/bedrock/v1/Comparisons/Comparisons/GetFieldComparisons', {
		method: 'POST',
		body: {
			BenchmarkId: benchmarkId,
			ComparisonName: field,
		},
	});
};
