import * as React from 'react';
import { FunctionComponent } from 'react';

import { Modal, Heading, Text } from 'basecamp';
import { translate } from '../../../utils/locale';

type Props = {
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	modalTitle: string;
	modalBody: string;
};

const InformationModal: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<Modal
			setAs="small"
			close={() => props.setShowModal(false)}
			withActions={[
				{
					title: translate('manageUsers.modals.generic.OK_ACTION'),
					onClick: () => props.setShowModal(false),
					type: 'accent',
				},
			]}
			andBodyPadY={4}
			dataTest="unSavedChangeModal"
		>
			<Heading setAs="large" withColor={'error'}>
				{props.modalTitle}
			</Heading>
			<Text mb={3} dataTest="unSavedChangeModalBody">
				{props.modalBody}
			</Text>
		</Modal>
	);
};

export default InformationModal;
