import { configureStore, getDefaultMiddleware, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import persistedReducer, { persistConfig, RootState } from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

if (module.hot) {
	module.hot.accept('./rootReducer', () => {
		const newRootReducer = require('./rootReducer').default;

		store.replaceReducer(persistReducer(persistConfig, newRootReducer));
	});
}

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: false,
		immutableCheck: false,
	}).prepend(sagaMiddleware),
	devTools: window.environmentName !== 'production',
});

sagaMiddleware.run(rootSaga);

if (
	window.environmentName == 'development' ||
	window.environmentName == 'test' ||
	window.environmentName == 'staging'
) {
	//@ts-ignore
	//TODO: Will look into this but its a test for now :)
	window.getConnectStore = () => {
		var st = store.getState();
		if (
			st?.context?.client?.name === 'Automation Test School' ||
			st?.context?.client?.name === 'Welsh auto Test School' ||
			st?.context?.client?.name === 'NI auto Test School'
		) {
			return st;
		}

		return null;
	};
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const persistor = persistStore(store);
