import * as React from 'react';
import { FunctionComponent } from 'react';

import { List } from 'basecamp';

type Props = {
	listItems: Array<string | number>;
	listStyle: 'number' | 'circle' | 'none' | 'border' | 'striped' | 'bullet' | undefined;
};

const ListRenderer: FunctionComponent<Props> = ({ listItems, listStyle }) => {
	return <List setAs="unordered" withListStyle={listStyle} withItems={listItems} m={0} />;
};

export default ListRenderer;
