import { debounce } from 'lodash';
import {
	useEffect,
	useState,
	useRef,
	RefObject,
	useCallback,
	useLayoutEffect,
	createRef,
} from 'react';

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState<{
		width: number | undefined;
		height: number | undefined;
	}>({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		let timeoutId: any = null;

		// Handler to call on window resize
		function handleResize() {
			clearTimeout(timeoutId);
			// Set window width/height to state
			timeoutId = setTimeout(() => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}, 500);
		}

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount

	return windowSize;
}

export function useFilterBar(
	// List of elements in the filter bar
	filterBarElements: [string, number][],
	// Window width
	windowWidth: number | undefined,
	/**
	 * Width for any pinned elements, in addition to the more actions button
	 * Example - adhoc controls in Connect or apply button in Summit
	 */
	pinnedElements: number = 0,
	/**
	 * Anything that should trigger a recalculation of the menu state
	 * Example - hiding and showing a menu button such as "show in one table" on the subject overview
	 */
	recalcOn: Array<any> = []
): FilterBar {
	/**
	 * The menu state
	 * One for the filter bar, one for the more actions dropdown
	 */
	const [filterBarMoreActionsLists, setFilterBarMoreActionsLists] = useState<FilterBar>({
		filterBar: [],
		moreActions: [],
	});

	useLayoutEffect(() => {
		/**
		 * Initial value for components total width
		 * Starts at 85 because of the "more actions" button plus any other pinned elements
		 */
		let componentsTotalWidth = pinnedElements ? pinnedElements + 95 : 95;

		/**
		 * Work out how the filter bar / more actions menu should be populated
		 * This is based a running a total of the items in the filter bar vs the windows width
		 * Will return two lists - the filter bar / the more actions dropdown
		 */
		const filterBarMoreActionsItems = filterBarElements.reduce(
			(acc: FilterBar, [key, val]) => {
				/**
				 * Update the running total of the components width
				 * val = width of component
				 * 24 = components margin
				 * 48 = filter bars total padding
				 */
				if (!!val) {
					componentsTotalWidth = componentsTotalWidth += val + 24;
				}

				/**
				 * If the components total width is less than the windows width
				 * Populate the filter bar
				 */
				if (windowWidth && componentsTotalWidth < windowWidth) {
					return { ...acc, filterBar: [...acc.filterBar, key as string] };
				} else {
					/**
					 * Else populate the more actions dropdown
					 */
					return { ...acc, moreActions: [...acc.moreActions, key as string] };
				}
			},
			{
				filterBar: [],
				moreActions: [],
			}
		);
		// Set state with the result if
		setFilterBarMoreActionsLists(filterBarMoreActionsItems);
	}, [windowWidth, pinnedElements, ...recalcOn]);

	// Return the calculated result
	return filterBarMoreActionsLists;
}

export function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export function useOutsideClick(ref: RefObject<HTMLDivElement>, func: any) {
	useEffect(() => {
		/**
		 * Call func if clicked on outside of element
		 */
		const handleClickOutside = (event: any): void => {
			if (ref.current && !ref.current.contains(event.target)) {
				return func();
			}
		};
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

export const useDebounceCallback = (callback, delay) => {
	const callbackRef = useRef();
	callbackRef.current = callback;
	return useCallback(
		debounce((...args) => callbackRef.current(...args), delay),
		[]
	);
};

/**
 *
 * @param originalCollection mapped type object keyed on strings
 * @param keySelector function to return key from object
 * @returns [local copy of collection, func to set local copy, func to set individual item, func to delete individual item]
 */
export function useLocalCopy<ObjectType>(
	originalCollection: { [key: string]: ObjectType },
	keySelector: (object: ObjectType) => string | undefined
) {
	const [localCollection, setLocalCollection] = useState<{ [key: string]: ObjectType }>(
		originalCollection
	);

	const setLocalItem = (item: ObjectType) => {
		var key = keySelector(item);
		if (!key) return; //todo: throw error, or should we pass in key creator function?

		setLocalCollection((stateValue) => {
			return {
				...stateValue,
				[key as string]: item,
			};
		});
	};

	const deleteLocalItem = (itemKey: string) => {
		const initial: { [key: string]: ObjectType } = {};
		setLocalCollection((stateValue) => {
			return Object.entries(stateValue)
				.filter(([key]) => key !== itemKey)
				.reduce((previous, [key, value]) => {
					return {
						...previous,
						[key]: value,
					};
				}, initial);
		});
	};

	return [localCollection, setLocalCollection, setLocalItem, deleteLocalItem] as const;
}

/**
 * Dynamic Refs
 */
export function useDynamicRefs<T>(): [
	(key: number) => React.RefObject<T>,
	(key: number) => React.RefObject<T>,
	() => void
] {
	const map = new Map<number, React.RefObject<unknown>>();

	function clearRefs(): void {
		return map.clear();
	}

	function setRef<T>(key: number): React.RefObject<T> {
		const ref = createRef<T>();
		map.set(key, ref);
		return ref;
	}

	function getRef<T>(key: number): React.RefObject<T> {
		return map.get(key) as React.RefObject<T>;
	}

	return [getRef, setRef, clearRefs];
}
