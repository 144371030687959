/**
 * Analysis session selectors
 */

/**
 * Gets the flag that determines if the end session modal should be shown
 * @param state redux state
 * @returns boolean
 */
export const shouldShowStrategicSessionModal = (state: RootState) => {
	return state.strategicAnalysis.showStrategicSessionModal;
};

/**
 * Does the user have a active analysis session?
 * @param state Redux state
 * @returns Yay or Nay
 */
export const hasActiveAnalysisSession = (state: RootState) => {
	return state.strategicAnalysis.hasActiveAnalysisSession;
};

