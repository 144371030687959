import * as React from 'react';
import { FunctionComponent, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Text, IconButton, Tooltip } from 'basecamp';
import {
	getAppliedDefaultExamLevels,
	showStrategicSessionModal,
} from 'features/strategicAnalysis/redux';
import levels from '../../../../utils/examLevel';
import { translate } from '../../../../utils/locale';

const StrategicSessionIndicator: FunctionComponent = () => {
	/**
	 * Redux
	 */
	const dispatch = useDispatch();
	const getExamLevels = useSelector(getAppliedDefaultExamLevels).map((level) => level.value);

	/**
	 * Format the indicator wording
	 */
	const examLevels = getExamLevels.map((examLevel) => {
		return levels[examLevel].display;
	});
	const sessionType = [examLevels.slice(0, -1).join(', '), examLevels.slice(-1)[0]].join(
		examLevels.length < 2 ? '' : ' & '
	);

	/**
	 * Component
	 */
	return (
		<Fragment>
			<Flex
				setAs="row"
				position="relative"
				bg="UI.accent.7"
				px="20px"
				ml={3}
				borderRadius={99}
				zIndex={1}
			>
				<Flex withAlign="distribute" height="30px" zIndex={999}>
					<Text withColor="white" m={0} dataTest="strategicSessionIndicatorMessage">
						{translate('strategicAnalysis.standardPage.SESSION', { sessionType })}
					</Text>

					<Tooltip
						setAs="error"
						withAlign="bottom"
						withSize="small"
						withMessage="Click to end your running Strategic Analysis Session"
						width="30px"
						height="30px"
						right="-20px"
						position="absolute"
						dataTest="strategicSessionIndicatorTooltip"
					>
						<IconButton
							withIcon="stop"
							setAs="error"
							withSize="medium"
							onClick={() => dispatch(showStrategicSessionModal())}
							dataTest="strategicSessionIndicatorBtn"
						/>
					</Tooltip>
				</Flex>
			</Flex>
		</Fragment>
	);
};

export default StrategicSessionIndicator;
