/**
 ** Returns the points for each outcome in the following format:
 ** { A*: 140, A:120, B:100, C:80, D:60, E:40, U:0 }
 */
export function getPointsForOutcomes(outcomes: SubjectPage.Outcomes[]): any {
	return outcomes.reduce(
		(preValue, currVal) => ({
			[currVal.Outcome?.Display]: currVal.Outcome.Points,
			...preValue,
		}),
		{} // Initial value
	);
}

/**
 ** Returns the total student count for each outcome in the following format:
 ** { A*:1, A:0, B:2, C:3 }
 */
export function getTotalStudentsForOutcomes(
	outcomes: SubjectPage.Outcome[],
	students: SubjectPage.Student[],
	whatIf: boolean = false
): any {
	// for each outcome
	return outcomes.reduce(
		(preValue, currVal) => ({
			[currVal.Outcome?.Display]: getTotalStudentsForOutcome(currVal, students, whatIf),
			...preValue,
		}),
		{} // inital value
	);
}

/**
 ** Returns the total moved student count for each outcome in the following format:
 ** { A*:1, A:0, B:-2, C:3 }
 */
export function getTotalChangesForOutcomes(
	outcomes: SubjectPage.Outcome[],
	students: SubjectPage.Student[],
	whatIf: boolean = false
) {
	// for each outcome
	return outcomes.reduce(
		(preValue, currVal) => ({
			[currVal.Outcome?.Display]:
				getTotalStudentsForOutcome(currVal, students, whatIf) -
				getTotalStudentsForOutcome(currVal, students),
			...preValue,
		}),
		{} // inital value
	);
}

/**
 ** Returns the total points of all of the students in each outcome in the following format:
 ** { A*: 140, A:120, B:100, C:80 }
 */
export function getTotalPointsInOutcome(
	outcomes: SubjectPage.Outcomes[],
	students: SubjectPage.Student[],
	whatIf: boolean = false
): any {
	return outcomes.reduce(
		(preValue, currVal) => ({
			[currVal.Outcome?.Display]: getTotalPoints(currVal, students, whatIf),
			...preValue,
		}),
		{} // Initial value
	);
}

/**
 ** Returns the total of students for a particular outcome as a number
 */
export function getTotalStudentsForOutcome(
	outcome: SubjectPage.Outcomes,
	students: SubjectPage.Student[],
	whatIf: boolean = false
): number {
	if (whatIf) {
		return students.filter((student: SubjectPage.Student) => {
			return student.WhatIfOutcome === outcome.Outcome.Value && student.IsIncluded;
		}).length;
	}

	return students.filter((student: SubjectPage.Student) => {
		if (student.Outcome.Order) {
			return student.Outcome.Order === outcome.Order && student.IsIncluded;
		} else {
			// fine grade has no order
			return student.Outcome.Value === outcome.Outcome.Value && student.IsIncluded;
		}
	}).length;
}

/**
 ** Returns the total points for the supplied outcome as a number
 */
export function getTotalPoints(
	outcome: SubjectPage.Outcomes,
	studentGroup: SubjectPage.Student[],
	whatIf: boolean = false,
	fineGrades: boolean = false
) {
	let matchedCount = 0;
	studentGroup.forEach((s) => {
		let sO = s.Outcome.Value;

		if (whatIf) {
			sO = s.WhatIfOutcome;
		}

		if (fineGrades) {
			sO = s.FineGrade;
		}

		if (!whatIf && sO === outcome.Outcome.Value && s.IsIncluded && !s.IsQGrade) {
			matchedCount++;
		}

		/**
		 * Users can now move from a 'Q' grade to other grades
		 * if this is the case we no longer filter out the 'Q' grades
		 * this is to ensure the thermometer gets updated
		 */
		if (!!whatIf && sO === outcome.Outcome.Value && s.IsIncluded) {
			matchedCount++;
		}
	});

	return matchedCount * outcome.Outcome.Points;
}
