export enum Role {
	/* Data Exchange - 1xx */
	DataExchange_View = 101,
	DataExchange_Manage = 102,

	/* Client Settings - 2xx */
	Settings_Users = 201,
	Settings_Organisation = 202,

	/* Mountaineer - 3xx */
	Mountaineer = 301,
	Mountaineer_Trainer = 302,

	/* Office Administration - 4xx */
	Admin_ImpersonateUser = 401,
	Admin_ManageUser = 402,

	/* Connect - 5xx */
	ConnectKS4PerformanceMeasures = 501,
	ConnectKS5PerformanceMeasures = 502,
	Connect_Data_Assembly_Pilot = 597,
	Connect_Data_Pilot = 598,
	Connect_Pilot = 599,

	/* Groups - 6xx */
	Groups_Setup = 601,

	/* Contact Roles */
	Primary_Data_Manager = 701,
	Alps_Champion = 702,

	/* Early Access Privilege 8xx*/
	EarlyAccessPrivilege = 801,
}
