import { SagaIterator } from 'redux-saga';
import { setAvailableArrayOfMfaTypes, setMfaSetUpError } from '../mfa/slice';
import { put, retry } from 'redux-saga/effects';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';
import { fetchAvailableAuthenticationTypes } from '../../../../api/mfaAPI';
import { sagaFlowConfigurations } from '../../../../constants';

/**
 * Saga that fetches a list of active and available authentication types
 */
export function* getAvailableAuthenticationTypesFlow(connectPage: string): SagaIterator<void> {
	try {
		const response: ResponseBuilder<MultiFactorAuthentication.AvailableMfaResponse[]> = yield retry(
			sagaFlowConfigurations.apiRetry, // Retry
			sagaFlowConfigurations.milliSeconds, // delay between retry
			fetchAvailableAuthenticationTypes
		);

		if (response.hasError) {
			yield put({
				type: `${connectPage}/setMfaSetUpError`,
				//If the its failed and theres no 'error' then the email or qr code has failed to generate
				payload: response.Errors[0].Message,
			});
		}

		yield put({
			type: `${connectPage}/setAvailableArrayOfMfaTypes`,
			//If the its failed and theres no 'error' then the email or qr code has failed to generate
			payload: response.ResponseObject,
		});
	} catch (e) {
		yield put({
			type: `${connectPage}/setMfaSetUpError`,
			payload: e,
		});
	}
}
