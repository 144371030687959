import * as React from 'react';
import { FunctionComponent, useState, useEffect } from 'react';

import { Flex, Label, Select } from 'basecamp';

import { ValueRenderer, GroupRenderer, OptionWithAddAll } from '../../../select/renderers';

import { translate } from '../../../utils/locale';
import { useDispatch, useSelector } from 'react-redux';

import { useWindowSize, useFilterBar } from '../../../utils/hooks';
import { MoreActions, ConditionalWrapper, SPOProgress8Config } from 'features/filterBar';

import { getPrimaryGradepoint } from 'features/app/redux/context';
import {
	isLoading,
	getProgress8MeasuresThunk,
	setAppliedComparisons,
	getAppliedComparisons,
	getAvailableComparisonGroups,
} from '../redux';

const FilterBarP8: FunctionComponent = () => {
	/**
	 * Handle Dispatch
	 */
	const dispatch = useDispatch();

	/**
	 * Handle Selectors
	 */
	const loading = useSelector(isLoading);
	const primaryGradepoint = useSelector(getPrimaryGradepoint);
	const comparisonGroups = useSelector(getAvailableComparisonGroups('progress8'));
	const appliedComparisons = useSelector(getAppliedComparisons);

	/**
	 * Comparisons
	 */
	const comparisonOptions = comparisonGroups.map((comp) => {
		return OptionWithAddAll(comp.label, comp.options, appliedComparisons, (options) =>
			dispatch(setAppliedComparisons(options))
		);
	});

	/**
	 * On mount fetch data for primary gradepoint
	 */
	const isKS4 = primaryGradepoint?.meta?.yearGroups.some((yg) => [7, 8, 9, 10, 11].includes(yg));
	const initialComparisonOptions = [{ label: 'All', value: 'All|All' }];
	useEffect(() => {
		// Handle progress8 measure dispatch
		if (isKS4) {
			dispatch(getProgress8MeasuresThunk([primaryGradepoint.gradepointId]));
			// Reset all comparisons if the pgp changes
			dispatch(setAppliedComparisons(initialComparisonOptions));
		}
	}, [primaryGradepoint]);

	/**
	 * Menu config
	 */
	const [moreActionsIsOpen, setMoreActionsIsOpen] = useState<boolean>(false);
	const winWidth = useWindowSize().width;
	const config = SPOProgress8Config();
	const filterBar = useFilterBar(Object.entries(config), winWidth, 100);

	/**
	 * Filters and controls
	 */
	const FilterBarElements = (menu: string[], isMoreActions: boolean = false) => {
		const mb = isMoreActions && filterBar.moreActions.length > 1 ? 4 : 0;

		return (
			<React.Fragment>
				{/**
				 * Conditional wrapper containing filter bar elements excluding downloads
				 * These will be pushed to the left on larger screens
				 **/}
				<ConditionalWrapper
					condition={!isMoreActions && filterBar.moreActions.length === 0}
					wrapper={(children) => <Flex withAlign="distribute">{children}</Flex>}
				>
					<React.Fragment>
						{/* Comparison Select */}
						{menu.includes('comparisons') && (
							<Flex
								setAs="column"
								withAlign="centerLeft"
								minWidth={config.comparisons}
								mr={4}
								mb={mb}
							>
								<Label>{translate('spo.filterBar.COMPARISON_SELECT')}</Label>
								<Select
									options={comparisonOptions}
									value={appliedComparisons}
									isDisabled={loading}
									onChange={(value: { label: string; value: string }[]) =>
										dispatch(setAppliedComparisons(value))
									}
									withComponents={{
										GroupHeading: GroupRenderer,
										ValueContainer: ValueRenderer,
									}}
									isMulti
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
								/>
							</Flex>
						)}
					</React.Fragment>
				</ConditionalWrapper>
			</React.Fragment>
		);
	};

	/**
	 ** Render Component
	 */
	return (
		<Flex
			setAs="row"
			withAlign="distribute"
			zIndex={10}
			px={4}
			py={4}
			bg="UI.white"
			width={1}
			maxHeight="115px"
		>
			{/* Standard filter bar elements */}
			<Flex width={1} withAlign="distribute">
				{FilterBarElements(filterBar.filterBar)}
			</Flex>

			{/* Show more actions toggle and menu if we cannot fit the filter bar elements within the window width */}
			{filterBar.moreActions.length > 0 && !loading && (
				<MoreActions
					onMouseEnter={() => setMoreActionsIsOpen(true)}
					onMouseLeave={() => setMoreActionsIsOpen(false)}
					moreActionsIsOpen={moreActionsIsOpen}
				>
					{FilterBarElements(filterBar.moreActions, filterBar.moreActions.length > 0)}
				</MoreActions>
			)}
		</Flex>
	);
};

export default FilterBarP8;
