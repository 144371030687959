import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { fetchAppContext } from 'features/app/redux/context';
import { autoSelectClient } from '../../../../api/contextAPI';

//TODO: This cant be properly unit tested as is,
// the fetchAppContext thunk needs breaking down into a sag flow
//this will allow us a lot more confidence and more rigorous unit tests
/**
 * Saga that dispatches the thunks required to authenticate a user
 */
export function* loginFlow(): SagaIterator<void> {
	//authentic as normal
	//NOTE: To dispatch a thunk in a saga you need to wrap in it a put THEN a call :)
	yield put(yield call(fetchAppContext));

	//AutoSelect the school
	yield call(autoSelectClient);
}
