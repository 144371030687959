import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, Heading, Input } from 'basecamp';

import { translate } from '../../../utils/locale';

type Props = {
	heading: string;
	hasData: boolean;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
};

const SearchBar: FunctionComponent<Props> = (props) => {
	return (
		<Flex setAs="row" mb={4}>
			{/* Page Heading */}
			<Heading setAs="medium" withColor="black" mb={0} flex={2}>
				{props.heading}
			</Heading>

			{/* Search Bar */}
			{/* @ts-ignore */}
			<Input
				setAs={props.hasData ? 'default' : 'disabled'}
				type="text"
				placeholder={translate('manageUsers.modals.searchBar.PLACEHOLDER')}
				flex={1}
				mt={2}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.setSearch(e.target.value)}
			/>
		</Flex>
	);
};

export default SearchBar;
