import { getMetrics } from '../../../../api/performanceMetricAPI';
import { QueryType } from '../../../../utils/performanceMeasures';
import { setStudentMetricsPending, setStudentMetricsSuccess } from '../';

export const getStudentMetricsThunk = (
	queryType: QueryType,
	gp: number[] | null,
	metricGroup: string | null,
	metricId: number | null,
	comparisonId: number | null,
	comparisonValueId: number | null
): AppThunk => async (dispatch) => {
	dispatch(setStudentMetricsPending());

	try {
		const response = await getMetrics(
			queryType,
			gp,
			metricGroup,
			metricId,
			comparisonId,
			comparisonValueId
		);

		if (response.HasError) {
			throw new Error(`Error fetching SPO Performance Metrics, ${response.Errors[0].Message}`);
		}

		dispatch(setStudentMetricsSuccess(response.ResponseObject));
	} catch (err: any) {
		throw new Error(`Error fetching SPO Performance Metrics, ${err.message}`);
	}
};
