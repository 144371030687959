import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Features } from 'features/app/types/features';
import { Role } from '../types/roles';

const initialState: Features.Permissions.State = {
	loadingPermissions: true,
	flags: {
		/* flags would get auto-populated from the permissions map */
	},
	permissions: {
		ks4_perf_measures: [Role.ConnectKS4PerformanceMeasures],
	},
};

export const permissions = createSlice({
	name: 'permissions',
	initialState,
	reducers: {
		/*
		 * Explicitly set permission for an individual permission ID
		 */
		setPermission(
			state: Features.Permissions.State,
			action: PayloadAction<{ id: keyof Features.Permissions.Flags; enabled: boolean }>
		): Features.Permissions.State {
			// if the permission being set doesn't exist in initial state, don't try set it
			// we don't support your ad-hoc permissions here!
			if (state.permissions[action.payload.id] === undefined) {
				return state;
			}

			return {
				...state,
				flags: {
					...state.flags,
					[action.payload.id]: action.payload.enabled,
				},
			};
		},

		/*
			Set the flag for currently loading permissions
		 */
		setLoadingPermissions(state: Features.Permissions.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loadingPermissions: action.payload,
			};
		},

		/*
		 * Bulk set permissions
		 */
		setBulkPermissions(
			state: Features.Permissions.State,
			action: PayloadAction<{ [permissionId: string]: boolean }>
		): Features.Permissions.State {
			const flags = Object.entries(action.payload).reduce((obj, [permissionId, enabled]) => {
				// if the permission being set doesn't exist in initial state, don't try set it
				// we don't support your ad-hoc permissions here!
				if (state.permissions[permissionId] === undefined) {
					return obj;
				}

				return {
					...obj,
					[permissionId]: enabled,
				};
			}, {});

			return {
				...state,
				loadingPermissions: false,
				flags: {
					...state.flags,
					...flags,
				},
			};
		},
	},
});

export default permissions.reducer;

export const { setPermission, setLoadingPermissions, setBulkPermissions } = permissions.actions;

/**
 * Set all permissions based on users roles
 * @param roles
 */
export const setPermissionsFromRolesThunk = (roles: number[]): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();

	// loop through all permissions so they can be set correctly
	for await (const [permissionId, requiredRoles] of Object.entries(state.permissions.permissions)) {
		// convert the type back to be one of the defined flags, so it doesn't complain
		const id = permissionId as keyof Features.Permissions.Flags;

		// Set the permission to be true if every required role is present for the user
		dispatch(setPermission({ id, enabled: requiredRoles.every((r) => roles.includes(r)) }));
	}
};

/**
 * Set all permissions based on users roles
 * @param roles
 */
export const setBulkPermissionsFromRolesThunk = (roles: number[]): AppThunk => async (
	dispatch,
	getState
) => {
	const state = getState();

	const updatedPermissions = Object.entries<number[]>(state.permissions.permissions).reduce(
		(permissions, [permissionId, requiredRoles]) => {
			return {
				...permissions,
				[permissionId]: requiredRoles.every((r) => roles.includes(r)),
			};
		},
		{}
	);

	dispatch(setBulkPermissions(updatedPermissions));
};

/**
 * Check if the current user has permission for a particular flag
 * @param permissionId
 * @return {boolean} - Does the current user have permission
 */
export const hasPermission = (permissionId: keyof Features.Permissions.Flags) => (
	state: RootState
): boolean => {
	return (state.permissions as Features.Permissions.State).flags[permissionId] ?? true;
};

/**
 * Check if we're still loading permissions from an external source
 * @param state
 * @return {boolean} - Are permissions loading
 */
export const getLoadingPermissions = (state: RootState): boolean => {
	return state.permissions.loadingPermissions;
};
