import { createSelector } from '@reduxjs/toolkit';
import {
	getAppliedComparisons,
	getAvailableSubjectComparisons,
	getAvailableStudentComparisons,
} from 'features/app/redux/context';
import {
	getComparisonsForStudents,
	getComparisonsForSubjects,
} from 'features/analysisSettings/redux';
import { AnalysisSettings } from 'features/analysisSettings/types';

type Options = Option[];

type Option = {
	label: string;
	options: {
		label: string;
		value: string;
	}[];
};

/**
 * This function identifies when all of the sub groups within a comparison group have been applied
 * and appends an unknown sub group to request the students that are identified as unknown in the comparison group
 * so that it can be used on the API to append the unknown view groups to students that do not have a record
 * for the specified comparison group.
 */
const getAppliedFromAvailable = (
	applied: { label: string; value: string }[],
	available: Options,
	custom: { [key: string]: AnalysisSettings.CustomComparison }
) => {
	// go through all of the groups and get the options e.g.
	// 0: { label: "Female", value: "Gender|Female"}
	// 1: { label: "Male", value: "Gender|Male"}
	// 2: { label: "Disadvantaged", value: "Disadvantaged|Yes"}
	// 3: { label: "Disadvantaged", value: "Disadvantaged|No"}
	let options = available.flatMap((x) => x.options);

	// go through the comparisions/filters that have been applied and find the matching item from the above list
	let appliedOptions = applied.filter((x) =>
		options.find((y) => (y.value == x.value && y.label == x.label) || custom[x.value])
	);

	// go through each group of options e.g
	// label: "Gender"
	// options: Array(2)
	//       0: { label: "Female", value: "Gender|Female"}
	//       1: { label: "Male", value: "Gender|Male"}
	let unknownGroupOptions = available.reduce((object: any[], currValue: Option) => {
		// get the options from the group e.g.
		// 0: { label: "Female", value: "Gender|Female"}
		// 1: { label: "Male", value: "Gender|Male"}
		// and return the labels e.g. ["Female", "Male"]
		const opts = currValue.options.length > 0 ? currValue.options.map((opt) => opt.label) : [];

		const appliedComparison = applied.map((av) => {
			// get the group name of the applied comparison and make sure it matches the one we are iterating
			let appliedComparisonGroupName = av.value.split('|')[0];
			if (appliedComparisonGroupName.toLowerCase() === currValue.label.toLowerCase()) {
				return av.label;
			}
		});

		// Identify if all of the options available for this group have been been applied
		const allGroupsApplied =
			opts && opts.length && opts.every((opt) => appliedComparison.includes(opt));

		// check the options for unable to group or unknown entry
		const unableToGroupEntry = opts.some(
			(opt) =>
				opt.toLowerCase().includes('unable to group') || opt.toLowerCase().includes('unknown')
		);

		// if all of the options have been applied AND (this is important) no unable to group entry exists
		// then add the option to group them by unknown
		if (allGroupsApplied && !unableToGroupEntry) {
			return [
				...object,
				{
					label: currValue.label + ' - Unknown',
					value: currValue.label + '|Unknown',
				},
			];
		}
		return object;
	}, []);

	return [...appliedOptions, ...unknownGroupOptions];
};

export const getAppliedSubjectComparisons = createSelector(
	(state) => getAppliedComparisons(state),
	(state) => getAvailableSubjectComparisons(state),
	getComparisonsForSubjects,
	(applied, available, custom) => getAppliedFromAvailable(applied, available, custom)
);

export const getAppliedStudentComparisons = createSelector(
	(state) => getAppliedComparisons(state),
	(state) => getAvailableStudentComparisons(state),
	getComparisonsForStudents,
	(applied, available, custom) => getAppliedFromAvailable(applied, available, custom)
);
