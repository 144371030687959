import { createDefaultGridOptions } from '../agGrid';
import { theme } from 'basecamp';
import { translate } from '../utils/locale';
import { isNullOrUndefinedOrNaN } from '../utils/common';

/**
 * Query types for sproc
 */
export enum QueryType {
	STUDENT_DATA = 1,
	SUMMARY_DATA = 2,
}

/**
 * Data configs for performance measures tables
 */
export enum SummaryData {
	INC_ENG_MATH_5X94 = 148,
	INC_ENG_MATH_5X95 = 149,
	EBACC_GRADE_4 = 164,
	EBACC_GRADE_5 = 165,
}

export enum At8Data {
	ATTAINMENT_8 = 48,
	ENGLISH_AT8 = 44,
	MATHS_AT8 = 45,
	EBACC_AT8 = 46,
	OPEN_AT8 = 47,
	EBACC_AVG_SLOTS_FILLED = 52,
	OPEN_AVG_SLOTS_FILLED = 53,
	OPEN_COMP_SLOTS_FILLED = 54,
}

export enum EngMathsThresholdData {
	ENG_AND_MATHS_4 = 136,
	ENG_AND_MATHS_5 = 137,
	ENG_ONLY_4 = 132,
	MATHS_ONLY_4 = 134,
	ENG_ONLY_5 = 133,
	MATHS_ONLY_5 = 135,
}

export enum EngMathGradeData {
	EN_LANG_GRADE = 991,
	EN_LIT_GRADE = 992,
	MATHS_GRADE = 993,
}

export enum ScienceData {
	X1_SCIENCE_GRADE_4 = 142,
	X2_SCIENCE_GRADE_4 = 144,
	X3_SCIENCE_GRADE_4 = 146,
	X1_SCIENCE_GRADE_5 = 143,
	X2_SCIENCE_GRADE_5 = 145,
	X3_SCIENCE_GRADE_5 = 147,
}

export enum EntriesData {
	TOTAL_STUDENTS = 123,
	EBACC_ENTRIES = 131,
	ENGLISH = 124,
	MATHS = 125,
	LANGUAGES = 126,
	HUMANITIES = 127,
	SCIENCE_X2 = 128,
}

export enum EbaccAvgScoreData {
	EBACC_APS = 43,
	ENGLISH_APS = 38,
	MATHS_APS = 39,
	SCIENCE_APS = 40,
	HUMANITIES_APS = 41,
	LANGUAGES_APS = 42,
}

export enum EbaccGrade4Data {
	ENGLISH_GRADE_4_PLUS = 150,
	MATHS_GRADE_4_PLUS = 152,
	SCIENCE_GRADE_4_PLUS = 156,
	HUMANITIES_GRADE_4_PLUS = 160,
	LANGUAGES_GRADE_4_PLUS = 162,
}

export enum EbaccGrade5Data {
	ENGLISH_GRADE_5_PLUS = 151,
	MATHS_GRADE_5_PLUS = 153,
	SCIENCE_GRADE_5_PLUS = 157,
	HUMANITIES_GRADE_5_PLUS = 161,
	LANGUAGES_GRADE_5_PLUS = 163,
}

export enum ChartData {
	ATTAINMENT_8 = 48,
	INC_ENG_MATH_5X94 = 136,
	INC_ENG_MATH_5X95 = 137,
	EBACC_APS = 43,
}

/**
 * Gets the metric ids from enum
 * @param metrics - metric enum
 * @returns metric ids array
 */
export const getMetricIdsFromEnum = <T extends {}>(metrics: T): number[] =>
	Object.values(metrics).filter((d) => typeof d !== 'string') as number[];

/**
 * Metric Table options for spo ks4 pms
 */
export const metricTableOptions = [
	{ label: 'Summary', value: getMetricIdsFromEnum(SummaryData) },
	{ label: 'Attainment 8', value: getMetricIdsFromEnum(At8Data) },
	{
		label: 'English / Maths Threshold',
		value: [
			...getMetricIdsFromEnum(EngMathsThresholdData),
			...getMetricIdsFromEnum(EngMathGradeData),
		],
	},
	{ label: 'EBacc Avg Score', value: getMetricIdsFromEnum(EbaccAvgScoreData) },
];

/**
 * PMs cell name renderer
 */
const shouldUseMetricAverage = [
	//@ts-ignore array spread error
	...getMetricIdsFromEnum(EbaccAvgScoreData),
	...getMetricIdsFromEnum(At8Data),
];

const shouldUseMetricCount = [EntriesData.TOTAL_STUDENTS];

const shouldUseMetricAndPercentageValues = [
	...getMetricIdsFromEnum(SummaryData),
	...getMetricIdsFromEnum(EngMathsThresholdData),
	...getMetricIdsFromEnum(EbaccGrade4Data),
	...getMetricIdsFromEnum(EbaccGrade5Data),
	...getMetricIdsFromEnum(EntriesData),
];

export const Ks4PmNameRenderer = (
	metricId: number | null,
	metricName: string | null,
	toggle?: boolean
) => {
	// Render using either metric value and percentage
	if (metricId && shouldUseMetricAndPercentageValues.includes(metricId)) {
		return toggle != null && toggle
			? `${metricName} - ${translate('spo.performanceMeasures.Ks4PmNameRenderer.Count')}`
			: `${metricName} - ${translate('spo.performanceMeasures.Ks4PmNameRenderer.Percentage')}`;
	}

	// Render using metric value
	if (metricId && shouldUseMetricAverage.includes(metricId)) {
		return `${metricName} - ${translate('spo.performanceMeasures.Ks4PmNameRenderer.Average')}`;
	}

	// Render using metric percentage
	if (metricId && shouldUseMetricCount.includes(metricId)) {
		return `${metricName} - ${translate('spo.performanceMeasures.Ks4PmNameRenderer.Count')}`;
	}

	// Render using metric percentage
	if (
		metricId &&
		!shouldUseMetricCount.includes(metricId) &&
		!shouldUseMetricAverage.includes(metricId)
	) {
		return `${metricName} - ${translate('spo.performanceMeasures.Ks4PmNameRenderer.Percentage')}`;
	}

	// If we DO NOT have a value, default to dash
	return '-';
};

/**
 ** Default grid options
 * Grid options are common to all KS4 PMs Grids
 */
export const defaultGridOptions = createDefaultGridOptions({
	sideBar: {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Manage Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressPivotMode: true,
					suppressRowGroups: true,
					suppressValues: true,
				},
			},
		],
	},
});

/**
 * Get the styling configuration for AG grid
 */
export const agGridExcelStyling = () => {
	return [
		{
			id: 'all',
			interior: {
				color: theme.colors.UI.secondary[2],
				pattern: 'Solid',
			},
		},
		{
			id: 'header',
			interior: {
				color: theme.colors.UI.secondary[2],
				pattern: 'Solid',
			},
		},
	];
};

/**
 * Sort function that can handle decimals (ag grids default sort can't for some reason)
 * @param value1 first number to sort
 * @param value2 second value to sort
 * @returns
 */
export const studentScoreSort = (value1: number, value2: number) => {
	if (value1 === null && value2 === null) {
		return 0;
	}

	if (value1 === undefined && value2 === undefined) {
		return 0;
	}

	if (value1 === null || value1 === undefined) {
		return -1;
	}
	if (value2 === null || value2 === undefined) {
		return 1;
	}
	return Number(value1) - Number(value2);
};

// this is for sorting arrays such as 4, 3, 5/4, 5, -
// the output should be -,3, 4, 5/4, 5
export const megSort = (value1: string, value2: string) => {
	// First if the value is null, undefined or anything, do this bit
	if (value1 === null && value2 === null) {
		return 0;
	}

	if (value1 === undefined && value2 === undefined) {
		return 0;
	}

	// if there's no value for the first
	if (value1 === null || value1 === undefined) {
		return -1;
	}

	// if there's no value for the second
	if (value2 === null || value2 === undefined) {
		return 1;
	}

	// now for the tricky part, if the first number is 5/4
	if (value1.includes('/')) {
		// split the string at the / resulting in [5, 4]
		var split = value1.split('/');

		var split1 = split[0]; // store the 5
		var split2 = split[1]; // store the 4

		// if the first number is bigger than the second we're dealing with a top heavy number
		// if that top heavy number is the same as the value we are sorting against, make sure it is
		// sorted below that as 5/4 is lower than 5
		if (split1 > split2 && split1 === value2) {
			return -1;
		}
	}

	// if the second number is 5/4
	if (value2.includes('/')) {
		// split the string at the / resulting in [5,4]
		var split = value2.split('/');

		var split1 = split[0]; // store the 5
		var split2 = split[1]; // store the 4

		if (split1 > split2 && split1 === value1) {
			return 1;
		}
	}

	// if the first value is smaller
	if (value1 < value2) {
		return -1; // move left
	}

	// if the first value is larger
	if (value1 > value2) {
		return 1; // move right
	}

	return 0; // leave in position
};
