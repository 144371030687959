import { SagaIterator } from 'redux-saga';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';
import { put, retry } from 'redux-saga/effects';
import { setAuthenticationMfaType } from '../../../../api/mfaAPI';
import { MfaType } from '../../../../utils/mfa';
import { sagaFlowConfigurations } from '../../../../constants';

/**
 * A Saga to handle the validation of configuring Mfa
 * @param mfatype mfa type thats being validated
 * @param activateMfa do we want to enable or disable the mfa
 * @param area the slice we width to dispatch the actions from
 */
export function* activateMfaTypeFlow(
	mfaType: MfaType,
	activateMfa: boolean,
	area: string
): SagaIterator<void> {
	try {
		//on a fresh call clear the errors
		yield put({
			type: `mfaSetup${area}/setMfaValidationError`,
			payload: undefined,
		});

		const response: ResponseBuilder<MultiFactorAuthentication.ActivationOfMfaResponse> = yield retry(
			sagaFlowConfigurations.apiRetry, // Retry
			sagaFlowConfigurations.milliSeconds, // delay between retry
			setAuthenticationMfaType,
			activateMfa,
			mfaType
		);

		//if the response returns empty or it has not been validated
		if (!response || !response.ResponseObject || !response.ResponseObject.result) {
			//Set the error
			yield put({
				type: `mfaSetup${area}/setMfaValidationError`,
				//If the its failed and theres no 'error' then the email or qr code has failed to generate
				payload: !response.Errors ? response.ResponseObject.message : response.Errors[0].Message,
			});
		}

		if (response.ResponseObject.result) {
			//Set the state of the secret - we dont need to dispatch this anymore
			yield put({
				type: `mfaSetup${area}/setConfirmationOfSecretStorage`,
				//pass this in as if we want it to be false is disabled then we want to option to re send it later
				//if we want it enabled we dont want to reset it
				payload: activateMfa,
			});

			//pass the response
			yield put({
				type: `mfaSetup${area}/setQrCode`,
				//Store QR code or email success message
				payload: response.ResponseObject.message,
			});
		}
	} catch (e) {
		//Set the error
		yield put({
			type: `mfaSetup${area}/setMfaValidationError`,
			payload: e,
		});
	}
}
