import { getAvailableStudentComparisons } from 'features/app/redux/context';
import subjectOrderStringSort from '../../../../utils/subjectOrderStringSort';
import { getComparisonsForStudents } from 'features/analysisSettings/redux';
import { sortComparisonStudentViewGroups } from '../../../../utils/studentGroup';
import {
	EngMathsThresholdData,
	getMetricIdsFromEnum,
	studentScoreSort,
	megSort,
	SummaryData,
} from '../../../../utils/performanceMeasures';
import { translate } from '../../../../utils/locale';
import { getFeature } from 'features/app/redux/features';
import { ValueGetterParams } from 'ag-grid-community';

/*
 ** Selectors
 */
export const getStudentViewGroups = (state: RootState): SPO.StudentViewGroups => {
	const studentViewGroups = state.spo.studentViewGroups;

	const availableComparisons = getAvailableStudentComparisons(state);

	if (Object.values(studentViewGroups).length > 0) {
		const appliedComparisons = state.context.appliedComparisons;
		const customComparisons = getComparisonsForStudents(state);
		return sortComparisonStudentViewGroups(
			availableComparisons,
			studentViewGroups,
			appliedComparisons,
			customComparisons
		);
	}

	return studentViewGroups;
};

export const getTutorGroups = ({ spo }: RootState) => {
	return [...(spo.tutorGroups ?? [])].sort().map((x) => ({ label: x, value: x }));
};

export const getSubjects = ({ spo }: RootState) => {
	let subjects = Object.values(spo.studentViewGroups)
		.flatMap((value) => value.students)
		.flatMap((value) => value.Subjects)
		.reduce((obj, v) => ({ ...obj, ...v }), {});

	//creating a set then spreading it into an array gets us only unique values
	return [...new Set(Object.keys(subjects))]
		.sort((a, b) => {
			return (
				subjectOrderStringSort(spo.subjectData[a]?.order, spo.subjectData[b]?.order) ||
				spo.subjectData[a]?.display.localeCompare(spo.subjectData[b]?.display)
			);
		})

		.map((x) => ({
			label: subjects[x].Subject.DisplayName,
			value: x,
		}));
};

export const isLoading = ({ spo }: RootState) => {
	return spo.loading;
};

/**
 * Student level metrics
 */
// Get all student level metrics
export const getAllMetrics = (state: RootState) => {
	return state.spo.studentMetrics;
};

// Get applied metrics
export const getAppliedMetrics = (state: RootState) => {
	return state.spo.appliedMetrics;
};

// Get applied comparisons
export const getAppliedComparisons = (state: RootState) => {
	return state.spo.appliedComparisons;
};

// Get formatted options for comparisons select in spo pms
export const getAvailableComparisonGroups = (comparisonType: string) => (state: RootState) => {
	const metrics =
		comparisonType === 'progress8' ? getAllProgress8Metrics(state) : getAllMetrics(state);
	const getAllUniqueMetrics = [...new Set(metrics?.map((metric) => metric.comparisonName))];

	const filteredMetrics = getAllUniqueMetrics.map((m) => {
		return metrics!
			.filter((metric) => metric.comparisonName === m)
			.sort(
				(a, b) => a.comparisonName - b.comparisonName || b.comparisonValueId - a.comparisonValueId
			);
	});

	return filteredMetrics.map((fm, i) => {
		const displays = [...new Set(fm.map((metric) => metric.comparisonDisplay))];

		const comparisonIds = [...new Set(metrics?.map((metric) => metric.comparisonId))];
		const values = [...new Set(fm.map((metric) => metric.comparisonValue))];

		return {
			label: getAllUniqueMetrics[i],
			options: displays.map((display, j) => {
				return {
					label: display,
					value: `${getAllUniqueMetrics[i]}|${values[j]}`,
				};
			}),
			comparisonId: comparisonIds[i],
		};
	});
};

// Get formatted ag grid name, data and coldefs for each comparison for the selected metric set
export const getStudentLevelMetrics = (state: RootState) => {
	// Get all metrics
	const allMetrics = getAllMetrics(state);
	// Get applied metrics
	const appliedMetrics = getAppliedMetrics(state);
	// Get applied comparisons
	const appliedComparisons = getAppliedComparisons(state);
	// Get all unique student ids
	const getAllUniqueStudentIds = [...new Set(allMetrics?.map((metric) => metric.studentEntryId))];
	// Get a filtered metric set
	const groupByStudent = getAllUniqueStudentIds?.map((id) =>
		allMetrics?.filter((metric) => metric.studentEntryId === id)
	);
	// Get students
	const students = Object.values(getStudentViewGroups(state))[0]?.students || [];
	// Get list of all students with their MEG, MEG is same for each subject so just get first
	const studentsWithMeg: { [key: string]: string } = students.reduce((acc, curr) => {
		const id = curr.ExternalId;

		return {
			...acc,
			[id]: Object.values(curr.Subjects)[0].MEG,
		};
	}, {});
	// Create one table per applied comparison
	const studentMetrics = appliedComparisons
		.map((comp) => {
			/**
			 * Get the comparison and comparison value from the applied
			 * comparison value 'All|All'
			 */
			const getCompAndValue = comp.value.split('|');

			return groupByStudent
				.map((gs) => {
					return gs?.filter(
						(stu) =>
							stu?.comparisonName === getCompAndValue[0] &&
							stu?.comparisonValue === getCompAndValue[1] &&
							appliedMetrics.value.includes(stu?.metricId)
					);
				})
				.filter((gs) => {
					return gs?.length !== 0;
				});
		})
		.filter((sm) => {
			return sm.length !== 0;
		});

	// Get metrics and coldefs per table (A metric being a student)
	const data = studentMetrics?.map((metric) => {
		// Find the first non null student in the metrics
		const firstNonNullMetric = metric.find((el) => !!el && el.length > 0);

		// lets extract the columns from the metric (student) that we're iterating
		var cols = metric.flatMap((m) => {
			return m?.map((t) => {
				return {
					metricDisplayname: t.metricDisplayname,
					metricId: t.metricId,
				};
			}, []);
		}, []);

		// create a distinct set of columns
		var finalCols = cols.filter(
			(col, i, arr) => arr.findIndex((c) => c?.metricId === col?.metricId) === i
		);

		// Create metric name for use in accordions and headings
		const getTableName = firstNonNullMetric
			? `${firstNonNullMetric[0].comparisonName} ${
					firstNonNullMetric[0].comparisonName === 'All'
						? ''
						: '- ' + firstNonNullMetric[0].comparisonDisplay
			  } - ${metric.length} Students `
			: '';
		// Create the static col defs
		const student = [
			{
				headerName: translate('spo.performanceMeasures.agGrid.headers.STUDENT_ID'),
				field: 'student-id',
				hide: true,
			},
			{
				headerName: translate('spo.performanceMeasures.agGrid.headers.STUDENT_NAME'),
				field: 'student-name',
				pinned: true,
			},
			{
				headerName: translate('spo.performanceMeasures.agGrid.headers.PRIOR_ATTAINMENT'),
				field: 'prior-attainment',
				pinned: 'right',
				comparator: studentScoreSort,
			},
			{
				headerName: translate('spo.performanceMeasures.agGrid.headers.MEG'),
				field: 'meg',
				pinned: 'right',
				comparator: megSort,
			},
		];

		/**
		 * Prevents duplicate colf defs being created
		 */
		const columns: number[] = [];

		// Get available comparison list
		const comparisons: { [key: string]: number } = getAvailableComparisonGroups('')(state).reduce(
			(acc, curr) => {
				return {
					...acc,
					[curr.label.toLowerCase()]: curr.comparisonId,
				};
			},
			{}
		);

		// Set desired data order
		const dataOrder = [
			comparisons['all'],
			comparisons['gender'],
			comparisons['disadvantaged'],
			comparisons['send'],
			comparisons['eal'],
		];

		return {
			order: dataOrder.indexOf(firstNonNullMetric![0].comparisonId),
			tableName: getTableName,
			metrics: metric.map((fm) => {
				return fm?.reduce((acc, curr) => {
					const formattedKey = curr.metricDisplayname?.replace(/\./g, '-').replace(/\s+/g, '-');

					return {
						...acc,
						'student-id': curr.studentEntryId,
						'student-name': curr.studentName,
						/**
						 * Pass the value as an avg / agg or a  percentage
						 * Metric id is used for display purposes, see src/utils/performanceMeasures.ts
						 */
						[formattedKey as string]: curr.metricValue.toString(),
						'prior-attainment': getFeature('pa_display_precision')
							? curr.priorAttainmentDisplay
								? curr.priorAttainmentDisplay
								: curr.ks4PriorAttainment?.toFixed(2)
							: curr.ks4PriorAttainment?.toFixed(2),
						meg: studentsWithMeg[curr.studentExternalId as string],
					};
				}, {});
			}),
			coldefs: finalCols?.reduce(
				(acc, curr) => {
					const formattedKey = curr.metricDisplayname!.replace(/\./g, '-').replace(/\s+/g, '-');

					const shouldUseIconRenderer = [
						...getMetricIdsFromEnum(SummaryData),
						...getMetricIdsFromEnum(EngMathsThresholdData),
					];

					if (!columns.includes(curr.metricId)) {
						columns.push(curr.metricId);

						return [
							...acc,
							{
								headerName: translate(
									`spo.performanceMeasures.agGrid.headers.${formattedKey.toUpperCase()}`,
									{ _: curr.metricDisplayname }
								),
								field: formattedKey,
								pinned: false,
								comparator: studentScoreSort,
								valueGetter: (params: ValueGetterParams) => getValue(params, formattedKey),
								cellRenderer: shouldUseIconRenderer.includes(curr.metricId)
									? 'iconRenderer'
									: undefined,
							},
						];
					}

					return acc;
				},
				[...student]
			),
		};
	});

	return data;
};

const getValue = (params: ValueGetterParams, field: string): string => {
	const value = params.data[field];
	return !!value ? value : '-';
};

export const getPriorAttainmentTypes = (state: RootState): SPO.PriorAttainmentType[] => {
	return state.spo.priorAttainmentTypes;
};

/**
 * Progress 8 Measure
 */
// Get all Progress 8 measures
export const getAllProgress8Metrics = (state: RootState) => {
	return state.spo.progress8Metrics;
};
// Get all student measures for Progress 8
export const getStudentLevelProgress8Metrics = (state: RootState) => {
	// Get all Progress 8 Measures
	const allProgress8Metrics = getAllProgress8Metrics(state);
	// Get applied comparisons
	const appliedComparisons = getAppliedComparisons(state);
	// Get all unique student ids
	const getAllUniqueStudentIds = [
		...new Set(allProgress8Metrics?.map((metric) => metric.studentEntryId)),
	];
	// Get a filtered metric set
	const groupByStudent = getAllUniqueStudentIds?.map((id) =>
		allProgress8Metrics?.filter((metric) => metric.studentEntryId === id)
	);
	// Get students
	const students = Object.values(getStudentViewGroups(state))[0]?.students || [];
	// Get list of all students with their MEG, MEG is same for each subject so just get first
	const studentsWithMeg: { [key: string]: string } = students.reduce((acc, curr) => {
		const id = curr.ExternalId;

		return {
			...acc,
			[id]: Object.values(curr.Subjects)[0].MEG,
		};
	}, {});
	// Create one table per applied comparison
	const studentMetrics = appliedComparisons
		.map((comp) => {
			/**
			 * Get the comparison and comparison value from the applied
			 * comparison value 'All|All'
			 */
			const getCompAndValue = comp.value.split('|');

			return groupByStudent
				.map((gs) => {
					return gs?.filter(
						(stu) =>
							stu?.comparisonName === getCompAndValue[0] &&
							stu?.comparisonValue === getCompAndValue[1]
					);
				})
				.filter((gs) => {
					return gs?.length !== 0;
				});
		})
		.filter((sm) => {
			return sm.length !== 0;
		});
	// Get metrics and coldefs per table
	const data = studentMetrics?.map((metric) => {
		// Find the first non null student in the metrics
		const firstNonNullMetric = metric.find((el) => !!el && el.length > 0);
		// Create metric name for use in accordions and headings
		const getTableName = firstNonNullMetric
			? `${firstNonNullMetric[0].comparisonName} ${
					firstNonNullMetric[0].comparisonName === 'All'
						? ''
						: '- ' + firstNonNullMetric[0].comparisonDisplay
			  } - ${metric.length} Students `
			: '';

		return {
			tableName: getTableName,
			metrics: metric.map((fm) => {
				return fm?.reduce((acc, curr) => {
					const formattedKey = curr.metricDisplayName?.replace(/\./g, '-').replace(/\s+/g, '-');

					return {
						...acc,
						'student-id': curr.studentExternalId,
						'student-name': curr.studentName,
						/**
						 * Pass the value as an avg / agg or a  percentage
						 * Metric id is used for display purposes, see src/utils/performanceMeasures.ts
						 */
						[formattedKey as string]: curr.metricValueDisplay,
						'prior-attainment': curr.ks4PriorAttainmentDisplay,
						meg: studentsWithMeg[curr.studentExternalId as string],
					};
				}, {});
			}),
		};
	});

	return data;
};
