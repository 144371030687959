import { Context } from 'features/app/types';
import { setMetricsPending, setComparisonsSuccess, setComparisonsFailure } from '..';

import { getPerformanceMeasureComparisons } from '../../../../api/ks5PerformanceMeasuresAPI';
import { GetKeyValueFromComparisonOption } from '../../utils';
import { PerformanceMeasuresTypes } from '../../../../types/enum';

export const getALevelComparisonsThunk = (
	GradePoints: Context.Gradepoint[],
	Comparisons: Ks5PerformanceMeasures.ComparisonOption
): AppThunk => async (dispatch) => {
	dispatch(setMetricsPending());

	const formattedComparisons = GetKeyValueFromComparisonOption(Comparisons);
	try {
		const response = await getPerformanceMeasureComparisons(
			GradePoints,
			PerformanceMeasuresTypes.ALevel,
			formattedComparisons
		);

		const data = response.responseObject;
		dispatch(
			setComparisonsSuccess(
				data.comparisons as Ks5PerformanceMeasures.Comparisons<Ks5PerformanceMeasures.ComparisonRow>
			)
		);
	} catch (ex) {
		dispatch(
			setComparisonsFailure(
				`The following error occurred whilst fetching the overview data - ${ex}`
			)
		);
	}
};
