import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: StudentModal.State = {
	loading: false,
	open: false,
	id: 0,
	errorMessage: '',
	data: {},
};

/**
 ** Student Modal slice
 */
const studentModalSlice = createSlice({
	name: 'studentModal',
	initialState,
	reducers: {
		setLoading(state: StudentModal.State) {
			return {
				...state,
				loading: true,
			};
		},
		setErrorMessage(state: StudentModal.State, action: PayloadAction<string>) {
			return {
				...state,
				loading: false,
				id: 0,
				data: {},
				errorMessage: action.payload,
			};
		},
		setStudentInformation(
			state: StudentModal.State,
			action: PayloadAction<{ data: StudentModal.StudentInformation; id: number }>
		) {
			const { data, id } = action.payload;
			return {
				...state,
				loading: false,
				open: true,
				id,
				data,
			};
		},
		closeModal() {
			return initialState;
		},
	},
});

/**
 ** Export Reducers
 */
export default studentModalSlice.reducer;

/**
 ** Export Actions
 */
export const {
	setStudentInformation,
	setLoading,
	setErrorMessage,
	closeModal,
} = studentModalSlice.actions;
