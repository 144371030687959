/**
 * Grids selectors
 */

import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { getAllComparisonData, getAllRowData, getSlice } from './';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';

/**
 * Gets all the loaded grid data
 * @param state redux state
 * @returns An object byId / allIds for the loaded grid data
 */
export const getAllGridData = (page: StrategicAnalysis.AnalysisPage) => (
	state: RootState
): StrategicGridData.Grids | undefined => {
	const slice = getSlice(page)(state);
	return slice.grids;
};

/**
 * Get the grid data for the active page and exam level
 * @param page the active analysis page
 * @param activeExamLevel the active exam level
 * @returns top level (all) rows with comparisons rows
 */
export const getGridDataForExamLevel = (
	page: StrategicAnalysis.AnalysisPage,
	activeExamLevel: StrategicAnalysis.ExamLevels
) => (state: RootState): StrategicGridData.GridDataForExamLevel | undefined => {
	const gridData = getAllGridData(page)(state);
	const allRowData = getAllRowData(page)(state);
	const rowData = !!allRowData ? Object.values(allRowData.byId) : [];
	const getGridsForExamLevel = !!gridData
		? Object.values(gridData.byId).filter((data) => {
				return data.examLevelId === activeExamLevel;
		  })
		: [];

	const getGridDataAddRowAndComparisonCount = getGridsForExamLevel.reduce((gridsAcc, grid) => {
		const gridRows = grid.rowIds.length;
		const comparisonRows = rowData.reduce((acc, row) => {
			return row.gridId === grid.gridId ? acc + row.comparisonIds.length : acc;
		}, 0);

		return [
			...gridsAcc,
			{
				...grid,
				rowCount: gridRows + comparisonRows,
			},
		];
	}, []);

	return getGridsForExamLevel.length > 0
		? { id: gridData!.id, grid: getGridDataAddRowAndComparisonCount }
		: undefined;
};

