import { StrategicGridData } from 'features/strategicAnalysis/types/common';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { call, cancel, put, retry, select, spawn } from 'redux-saga/effects';
import { fetchAllComparisonData } from '../../../../api/strategicAnalysisAPI';
import { getAppliedGradepoints, setStrategicLoading } from '..';
import { getSelectedPageComparisons } from 'features/strategicAnalysis/redux/';
import { flattenResponse, GetKeyValueFromComparisonOption } from 'features/strategicAnalysis/utils';
import { Context } from 'features/app/types';
import { getBenchmarkSetName } from 'features/app/redux/context';
import { SagaIterator } from '@redux-saga/types';
import { StrategicAnalysisOverview } from 'features/strategicAnalysis/types/overview';
import { handleThermometerChange } from './thermometerDataFlow';
import { ExamLevels } from '../../../../types/enum';
import { AnalysisPages } from 'features/strategicAnalysis/enums';

/**
 * Saga to call the API and fetch the main grid data
 * @param strategicAnalysisPage the strategic page we are working with
 * @param tab the active tab
 */
export function* fetchAllComparisonRows(
	strategicAnalysisPage: StrategicAnalysis.AnalysisPage,
	tab: { examLevel: StrategicAnalysis.ExamLevels; index: number }
): SagaIterator<void> {
	/**
	 * TODO - revisit this casing, can we make the back accept lowercase - KH investigating
	 */
	const page = strategicAnalysisPage.charAt(0).toUpperCase() + strategicAnalysisPage.slice(1);
	const gradepoints: Context.Gradepoint[] = yield select(getAppliedGradepoints);
	const benchmarkSet: string = yield select(getBenchmarkSetName);
	const selectedComparisons: StrategicAnalysis.ComparisonOption = yield select(
		getSelectedPageComparisons(strategicAnalysisPage)
	);

	if (strategicAnalysisPage === AnalysisPages.T_SCORE && tab.examLevel === ExamLevels.KS4) {
		yield put(setStrategicLoading(false));
		yield cancel();
	}

	// Format the comparisons for the API 'cos Korey has gone rogue again :-)
	const formattedComparisons = GetKeyValueFromComparisonOption(selectedComparisons);

	// Call the thermometer change handler to see if we need to fetch comparison data
	yield spawn(handleThermometerChange, strategicAnalysisPage, tab.examLevel);

	try {
		/**
		 * 2. call the API
		 */
		const SECOND = 1000;
		const response: ResponseBuilder<{
			comparisons: StrategicGridData.Comparisons<StrategicAnalysisOverview.ResponseRow>;
		}> = yield retry(
			3, // Try calling 3 times
			10 * SECOND, // with a delay of 10 seconds
			fetchAllComparisonData, // API to call
			page, // With these args
			tab.examLevel,
			benchmarkSet,
			gradepoints,
			formattedComparisons
		);

		// Check the response builder to see if any errors occurred or response is empty
		if (!response || response.hasError) {
			// 2a. set active tab so we can ensure a retry, set error
			yield put({
				type: `strategicAnalysis${page}/setComparisonDataFail`,
				payload: {
					examLevel: tab.examLevel,
					errorMsg: !response ? 'Grid data is empty' : response.Errors[0],
				},
			});
			yield put(setStrategicLoading(false));
			yield cancel();
		}

		// Flatten the gridValues object in the response
		const flatten = flattenResponse(response.responseObject.comparisons);
		// 2b. put the data in redux
		yield put({ type: `strategicAnalysis${page}/setAllComparisonDataSuccess`, payload: flatten });
		// 2c. set the expanded rows
		yield put({
			type: `strategicAnalysis${page}/setAllExpandedComparisonRows`,
			payload: flatten.allIds,
		});
	} catch (error) {
		// 2d. set active tab so we can ensure a retry, set error
		yield put({
			type: `strategicAnalysis${page}/setComparisonDataFail`,
			payload: {
				examLevel: tab.examLevel,
				errorMsg: `The following error occurred whilst fetching the overview data - ${error}`,
			},
		});
	}
}

/**
 * Saga to call the API and fetch the comparison data, has loading flags as this is called by itself
 * unlike the one above which is always part of another flow that has its own loading flags
 * @param strategicAnalysisPage the strategic page we are working with
 * @param tab the active tab
 */
export function* loadAllComparisonRows(
	strategicAnalysisPage: StrategicAnalysis.AnalysisPage,
	tab: { examLevel: StrategicAnalysis.ExamLevels; index: number }
) {
	// 1. set loading to true
	yield put(setStrategicLoading(true));

	// 2. fetch the data
	yield call(fetchAllComparisonRows, strategicAnalysisPage, tab);

	// 3. set loading to true
	yield put(setStrategicLoading(false));
}
