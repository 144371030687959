import * as React from 'react';
import { components } from 'react-select';

import { Flex, Icon } from 'basecamp';

/**
 ** Custom Accordion Group Heading
 */
// Click handler for toggling hide / show classes
const handleHeaderClick = (id: string) => {
	const node = document.querySelector(`#${id}`)?.parentElement?.nextElementSibling;
	const classes = node && node.classList;

	if (classes && classes.contains('groupOpen')) {
		node && node.classList.remove('groupOpen');
	} else {
		node && node.classList.add('groupOpen');
	}
};
// Heading component
const GroupHeading = (props: any) => {
	/**
	 ** Render Component
	 */
	return (
		<Flex
			setAs="row"
			withAlign="distribute"
			className="group-heading-wrapper"
			onClick={() => handleHeaderClick(props.id)}
			py={2}
			bg="UIAlpha.secondary.1"
			data-test="groupRendererWrapper"
		>
			{/* React Select Group Heading */}
			<components.GroupHeading {...props}>{props.children}</components.GroupHeading>
			{/* Down Arrow Icon */}
			<Flex
				setAs="row"
				withAlign="center"
				mr={2}
				color="UI.secondary.4"
				data-test="groupRendererIconWrapper"
			>
				<Icon icon="chevron-down" data-test="groupRendererIcon" />
			</Flex>
		</Flex>
	);
};

export default GroupHeading;
