import { call, cancel, spawn, put, select, delay } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/types';
import { SubjectArea } from 'features/subjectArea/types';
import { getSubjectAreaPage } from './sagaUtils';
import { getCurrentSubjectId } from 'features/subjectPage/redux';
import {
	getCurrentSubjectNumericId,
	getSubjectAreaActiveExamLevel,
} from 'features/subjectsOverview/redux';
import {
	setCurrentSubjectIdSubjectArea,
	setSubjectAreaActiveExamLevel,
	setsubjectAreaLoading,
} from '../slice';
import { mainGridData } from './fetchMainGridData';
import {
	fetchSubjectAreaActiveExamLevel,
	fetchSubjectAreaSubjectId,
	getSelectedSubjectAreaComparisons,
} from '../selectors';
import { mainChartData } from './fetchMainGraphData';
import { changeComprisonsFlow } from './changeComparisonFlow';
import { getAppliedGradepoints } from 'features/app/redux/context';
import { setComparisonGradepoint } from '../attainmentProfile/slice';

/**
 * Saga that handles setting subject area page context
 */
export function* subjectAreaContext(): SagaIterator<void> {
	//Current subject area page
	const subjectAreaPage: SubjectArea.SubjectAnalysisPages = yield call(getSubjectAreaPage);

	//Get the subject Page 'id' - this is a string name
	const subjectPageCurrentSubject = yield select(getCurrentSubjectId);

	//Use the subject page 'id' it get the numeric subject id for use in the subject area
	const subjectOverviewStringId = yield select(
		getCurrentSubjectNumericId(subjectPageCurrentSubject)
	);

	//Get the subject page examlevel
	const currentSubjectActiveExamlevel = yield select(
		getSubjectAreaActiveExamLevel(subjectPageCurrentSubject)
	);

	//Get the applied subject area comparisons
	const subjectAreaComparisons = yield select(getSelectedSubjectAreaComparisons);

	//Update redux with the current active examlevel
	yield put(setSubjectAreaActiveExamLevel(currentSubjectActiveExamlevel));

	//Update redu with the current subject id (Numeric)
	yield put(setCurrentSubjectIdSubjectArea(subjectOverviewStringId));

	//Fetch applied Gradepoints
	const appliedGradepoints = yield select(getAppliedGradepoints);

	//format the value to add to comparison gp, if theres only one then set as undefined
	const valueToAdd =
		appliedGradepoints.length > 1
			? appliedGradepoints[appliedGradepoints.length - 2].name
			: undefined;

	//Set comparison Gradepoint
	yield put(setComparisonGradepoint(valueToAdd));

	//Fetch newly added subject id
	const subjectId = yield select(fetchSubjectAreaSubjectId);

	//Forces a cancel if this is triggered by wrong action - lots of actions in the subject page call actions that call actions
	if (subjectId === subjectOverviewStringId) {
		yield cancel();
	}
	//Set Subject Area Loading
	yield put(setsubjectAreaLoading(true));

	//Fetch current active examlevel
	const currentExamLevel = yield select(fetchSubjectAreaActiveExamLevel);

	//Fetch grid data
	yield call(mainGridData, subjectAreaPage, currentExamLevel, subjectId);

	//fetch chart data - make this a call to block loading but allowing both calls to be done at the same time
	yield call(mainChartData, subjectAreaPage, currentExamLevel, subjectId);

	//If there are applied comparisons spawn saga
	if (subjectAreaComparisons.length > 0) {
		yield call(changeComprisonsFlow);
	}

	// Allow state to update
	yield delay(1000);

	// set loading to false
	yield put(setsubjectAreaLoading(false));
}
