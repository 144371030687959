import { round } from './rounding';
import { CountryEnum } from '../types/enum';
import padLeft from './padLeft';

/**
 * Converts a GCSE score to a QCA score
 * @param {any} gcseScore
 * @param options
 * @returns
 */
export function convertGCSEToQCA(gcseScore: any, options: { country?: CountryEnum } = {}) {
	if (gcseScore == null || gcseScore == '-' || isNaN(gcseScore)) {
		return '-';
	}

	let qcaScore = gcseScore > 0 ? round(gcseScore * 6 + 10) : 0; // The minimum QCA Score allowable is 10.0

	if (qcaScore < 10) {
		qcaScore = 10;
	}

	return padLeft(qcaScore, options.country !== CountryEnum.England ? 1 : 2);
}
