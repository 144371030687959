import { throttle, takeLatest, takeLeading, take, takeEvery } from 'redux-saga/effects';
import { changeSubjectAreaGradepointData } from './changeGradepointFlow';
import { changeSubjectFlow } from './changeSubjectFlow';
import { changeComprisonsFlow } from './changeComparisonFlow';
import { subjectAreaContext } from './subjectAreaContext';

/**
 * Export all Subject Area Sagas in one array
 * This is imported into a root saga in the store.
 * Root saga is effectively the same as the root reducer.
 */
export const subjectAreaSagas = [
	takeLatest('context/changePrimaryGradepoint', changeSubjectAreaGradepointData),
	takeLatest('subjectPage/setSubjectData', subjectAreaContext),
	takeLatest('subjectArea/changeSubjectId', changeSubjectFlow),
	takeLatest('subjectArea/setSelectedSubjectAreaComparisons', changeComprisonsFlow),
];
