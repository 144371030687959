import _ from 'lodash';
import {
	getAllComparisonIdsForActivePage,
	getAllExamLevelIds,
	getActiveExamLevelData,
	getSelectedPageComparisons,
} from '../';
import { getRowIdsByExamLevel } from '../';
import {
	setActiveExamLevelTab,
	setAllExpandedComparisonRows,
	updatePageConfiguration,
	updateSelectedPageFilters,
} from 'features/strategicAnalysis/redux';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';
import { call, cancel, spawn, put, select } from 'redux-saga/effects';
import { setStrategicLoading } from '..';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from '@redux-saga/types';
import { fetchGridData } from './mainGridDataFlow';
import { fetchAllComparisonRows } from './comparisonDataFlow';
import { getStrategicAnalysisPage, getExamLevelTab } from './sagaUtils';
import { handleThermometerChange } from './thermometerDataFlow';
import { AnalysisPages } from 'features/strategicAnalysis/enums';

/**
 * Saga that handles the changing of exam level tabs
 * @param action the action payload
 */
export function* changeExamLevelTabs(
	action: PayloadAction<{
		tab: { examLevel: StrategicAnalysis.ExamLevels; index: number };
	}>
): SagaIterator<void> {
	const { tab } = action.payload;

	/**
	 * Get the current page and exam level tab
	 * Tab is passed in from the overview as this sets the active tab redux state
	 */
	const strategicAnalysisPage: StrategicAnalysis.AnalysisPage = yield call(
		getStrategicAnalysisPage
	);

	//Before anything check to see if page has data for examlevel

	const pageData = yield select(getActiveExamLevelData(strategicAnalysisPage, tab.examLevel));

	// 1. Set loading to true
	yield put(setStrategicLoading(true));

	// 2. set the active tab and reset filters
	yield put(updateSelectedPageFilters({ strategicAnalysisPage, option: [] }));
	yield put(
		setActiveExamLevelTab({
			strategicAnalysisPage,
			tab,
		})
	);

	//This will need looking at as part of the next phase
	// 3. Bail if pagedata exists
	// if (pageData) {
	// 	yield put(setStrategicLoading(false));
	// 	yield cancel();
	// }

	// 4. handle thermometer data
	yield spawn(handleThermometerChange, strategicAnalysisPage, tab.examLevel);

	// 5. handle the main grid data
	yield call(handleMainGridData, strategicAnalysisPage, tab);

	const noneComparisonPages = [
		AnalysisPages.QUALITY_OF_TEACHING,
		AnalysisPages.STUDENTS_NOT_INCLUDED,
	];

	// 6. Bail if on a none comparison page
	if (noneComparisonPages.includes(strategicAnalysisPage)) {
		yield put(setStrategicLoading(false));
		yield cancel();
	}

	// 7. handle the comparison data
	yield call(handleComparisons, strategicAnalysisPage, tab);

	// 8. set loading to false
	yield put(setStrategicLoading(false));
}

/**
 * Saga that handles the resetting of exam level tabs
 * @param action the action payload
 */
export function* resetExamLevelTabs(
	action: PayloadAction<{
		examLevelStatesAreEqual: boolean;
		comparisonStatesAreEqual: boolean;
		isReset?: boolean;
	}>
): SagaIterator<void> {
	/**
	 * Page configuration change checks comes from the filter bar (apply changes button)
	 * We only want to run the fetch tasks if the relevant area has changed
	 * isReset flag is passed to getExamLevelTab when we change the gradepoints
	 */
	const { examLevelStatesAreEqual, comparisonStatesAreEqual, isReset } = action.payload;

	// 1. set loading to true
	yield put(setStrategicLoading(true));

	// 2. update the page configuration (applied exam level(s), applied comparison(s) etc)
	yield put(updatePageConfiguration());

	/**
	 * Get the current page and exam level tab
	 * Tab is obtained from state or is reset depending on the current task
	 */
	const strategicAnalysisPage = yield call(getStrategicAnalysisPage);
	const tab = yield call(getExamLevelTab, isReset);

	// If the exam level selected and applied states are not equal there been a change
	if (!examLevelStatesAreEqual) {
		// 3. set the active tab, reset filters
		yield put(updateSelectedPageFilters({ strategicAnalysisPage, option: [] }));
		yield put(
			setActiveExamLevelTab({
				strategicAnalysisPage,
				tab,
			})
		);
		// 4. handle the main grid data
		yield call(handleMainGridData, strategicAnalysisPage, tab);
	}

	// If the comparison selected and applied states are not equal there been a change
	if (!comparisonStatesAreEqual) {
		// 5. handle the comparison data
		yield call(handleComparisons, strategicAnalysisPage, tab);
	}

	// 6. set loading to false
	yield put(setStrategicLoading(false));
}

/**
 * Generator function to handle fetching the main grid data
 * @param strategicAnalysisPage current analysis page
 * @param tab current active tab
 */
function* handleMainGridData(
	strategicAnalysisPage: StrategicAnalysis.AnalysisPage,
	tab: { examLevel: StrategicAnalysis.ExamLevels; index: number }
) {
	// Get all the loaded exam level ids
	const allExamLevelIds: StrategicAnalysis.ExamLevels[] | undefined = yield select(
		getAllExamLevelIds(strategicAnalysisPage)
	);

	// TODO - I have the examLevel as a number, api returns as string need to change all instances
	// @ts-ignore
	if (!!allExamLevelIds && !allExamLevelIds.includes(tab.examLevel.toString())) {
		yield call(fetchGridData, strategicAnalysisPage, tab);
	}
}

/**
 * Generator function to handle fetching comparison data
 * @param strategicAnalysisPage current analysis page
 * @param tab current active tab
 */
function* handleComparisons(
	strategicAnalysisPage: StrategicAnalysis.AnalysisPage,
	tab: { examLevel: StrategicAnalysis.ExamLevels; index: number }
) {
	// 1. Get the selected comparisons and format to match api comparison string (Gender-Male)
	const selectedComparisons: StrategicAnalysis.ComparisonOption = yield select(
		getSelectedPageComparisons(strategicAnalysisPage)
	);
	// If there are no select comparisons for the currently active page we go no further
	if (selectedComparisons.length === 0) {
		yield put(setStrategicLoading(false));
		yield cancel();
	}

	const selectedComparisonValues = selectedComparisons.map((comp) =>
		comp.value.split('|').join('-')
	);
	// 2. Get the rows for the active exam level
	const rowsIdsForActiveExamLevel: string[] = yield select(
		getRowIdsByExamLevel(strategicAnalysisPage, tab.examLevel)
	);

	/**
	 * Combine the the selected comparison values with the row id,
	 * to create a list of expected comparison ids for the active exam level
	 */
	const comparisonIdsForActiveExamLevel = rowsIdsForActiveExamLevel.reduce((acc, curr) => {
		//@ts-ignore spread error
		return [...acc, ...selectedComparisonValues.map((value) => `${curr}-${value}`)];
	}, [] as string[]);
	// 3. Get the loaded comparison ids
	const allComparisonIds: string[] = yield select(
		getAllComparisonIdsForActivePage(strategicAnalysisPage)
	);
	/**
	 * Use the expected comparison id list and see if they exist in
	 * the loaded comparison array, we use this to determine if we need to fetch from the api
	 */
	const comparisonsLoaded =
		allComparisonIds.length > 0 &&
		comparisonIdsForActiveExamLevel.every((comparisonId) => {
			return allComparisonIds.includes(comparisonId);
		});
	// If we do need to fetch the comparisons
	if (selectedComparisons.length > 0 && !comparisonsLoaded) {
		// 4. Call the api and handle the response
		yield call(fetchAllComparisonRows, strategicAnalysisPage, tab);
		// 5. Then cancel the running task as we have what we need
		yield put(setStrategicLoading(false));
		yield cancel();
	}

	/**
	 * If we do not need to call the api because the comparisons have already been fetched
	 */
	// 6. update the row comparison ids in redux
	yield put({
		type: `strategicAnalysis${strategicAnalysisPage}/setAllRowComparisonIds`,
		payload: comparisonIdsForActiveExamLevel,
	});
	// 7. set the expanded rows
	yield put(setAllExpandedComparisonRows(comparisonIdsForActiveExamLevel));
}
