import { translate } from '../../../utils/locale';

/**
 ** Connect Routes
 */
export const connectSettings: (
	path: string,
	loadingAccounts: boolean,
	loadingOrg: boolean,
	loadingUsers: boolean,
	userRoles: string[] | undefined,
	hasGroupAccess: boolean | undefined
) => PrimaryNavigation.Route[] = (
	path,
	loadingAccounts,
	loadingOrg,
	loadingUsers,
	userRoles,
	hasGroupAccess
) => {
	const isVisible = (p: string): boolean => !path.includes(p);

	return [
		{
			name: translate('primaryNavigation.connect.settings.USERS') as string,
			path: '/manage-users',
			isDisabled: loadingUsers,
			isVisible: isVisible('manage-users') && userRoles?.includes('Settings_Users'),
			type: 'settingsRoot',
		},
		{
			name: translate('primaryNavigation.connect.settings.SETTINGS') as string,
			path: !hasGroupAccess ? '/analysis-settings' : '/analysis-settings/client-benchmarks',
			isDisabled: loadingOrg,
			isVisible: isVisible('analysis-settings'),
			type: 'settingsRoot',
		},
		{
			name: translate('primaryNavigation.connect.settings.ACCOUNT') as string,
			path: '/manage-account',
			isDisabled: loadingAccounts,
			isVisible: isVisible('manage-account'),
			type: 'settingsRoot',
		},
	];
};
