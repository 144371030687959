import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, theme } from 'basecamp';

type Props = {
	value: string;
};

const AdhocRenderer: FunctionComponent<Props> = ({ value }) => {
	/**
	 ** Renderer
	 */
	const renderer = (bg: string) => {
		return <Flex width={1} height="100%" bg={`${bg}`} />;
	};

	/**
	 ** Render Component
	 */
	switch (value) {
		case 'green':
			return renderer('ADHOC.connect.0');
		case 'rose':
			return renderer('ADHOC.connect.3');
		case 'orange':
			return renderer('ADHOC.connect.1');
		default:
			return renderer('ADHOC.connect.2');
	}
};

export default AdhocRenderer;
