import { fetchReport, fetchXLXS } from './reportAPI';
import fetch from './index';

function buildOptions(
	options: SPO.FetchOptions
): { filters: Array<{ type: string; name?: string; value: string }> } {
	var filters: Array<{ type: string; name?: string; value: string }> = [];

	if (options.tutorGroup) {
		filters.push({ type: 'StudentEntry', name: 'Tutor Group', value: options.tutorGroup });
	}

	return { filters };
}

function buildExportOptions(
	options: SPO.FetchExportOptions
): { filters: Array<{ type: string; name?: string; value: string }>; parameters: any } {
	var { filters } = buildOptions(options);
	var parameters = [];

	if (options.searchString) {
		filters.push({ type: 'StudentNameOrTGSearch', value: options.searchString });
	}

	if (options.focusOnSubject) {
		parameters.push({ key: 'reqSPODroppedSubs', value: options.focusOnSubject });
	}

	return { filters, parameters };
}

export function fetchSpo(options: SPO.FetchOptions): Promise<any> {
	return fetchReport('Interactive.StudentPerformance', 'Student', buildOptions(options), true);
}

export function fetchSpoExport(options: SPO.FetchExportOptions): Promise<any> {
	var transformedOptions: ReportAPIOptions = {
		...buildExportOptions(options),
		filename: options.filename,
	};

	return fetchXLXS(
		'InteractiveSPO_Excel', // report reference
		'Student', // area
		transformedOptions, // options
		true, // include target setting
		false, // include teaching sets,
		options.useNewExportAPI, // use export api
		false, // formatting
		options.usePtValues // use the Personalised Target value instead of the MEG
	);
}

/**
 * Returns a list of prior attainment types, which includes display precision, names and id's
 * @returns {Promise<any}
 */
export const fetchPriorAttainmentTypes = (): Promise<any> => {
	return fetch(`/api/PriorAttainmentTypes`);
};
