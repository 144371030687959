/**
 * Constant file to hold application level read only consts values
 */
export const constants = {
	// The local storage key name for the applied grade points
	AlpsSavedGradepointTrend: 'alpsSavedGradepointTrend',
	agGridLicense:
		'Using_this_{AG_Grid}_Enterprise_key_{AG-055929}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ALKEMYGOLD_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Connect_Interactive}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Connect_Interactive}_need_to_be_licensed___{Connect_Interactive}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_May_2025}____[v3]_[01]_MTc0NjMxMzIwMDAwMA==d57b337e7015bb2919beb19c908c1b95',
} as const;

/**
 * Constant values for Redux saga flows
 */
export const sagaFlowConfigurations = {
	//Number of times for the API to retry
	apiRetry: 3,
	//Seconds between each retry (MS)
	milliSeconds: 10000,
};
