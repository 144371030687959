import { setAnalysisLoading, setAnalysisErrorMessage, setAnalysisQualTypes } from '../';
import { getQualifcationTypes } from '../../../../../api/groupsAPI';

/**
 * Fetch Qualification Types
 */
export const fetchQualificationTypes = (type: any): AppThunk => async (dispatch) => {
	dispatch(setAnalysisLoading('AnalysisQualTypes'));

	try {
		const qualTTypesRes = await getQualifcationTypes(type);

		dispatch(setAnalysisQualTypes(qualTTypesRes.responseObject));
	} catch (err) {
		dispatch(setAnalysisErrorMessage(err.message));
	}
};
