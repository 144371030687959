/**
 * Compares two strings and returns relative sort order
 * Loosely mimics the C# StringComparer.Compare method
 * https://docs.microsoft.com/en-us/dotnet/api/system.stringcomparer.compare?view=netframework-4.7.1#System_StringComparer_Compare_System_Object_System_Object_
 *
 * @param {String} a		String to compare against
 * @param {String} b 		String to compare
 */
const compareStrings = (a: string, b: string): number => {
	// If equal, return 0
	if (a.toLowerCase() === b.toLowerCase()) {
		return 0;
	}

	// Create array to enable sorting of the two string values
	const stringArr = [a, b];

	// Sort strings (using default Unicode code point)
	const sortedStringArr = stringArr.sort();

	// Return 1 if a has precedence
	if (sortedStringArr.indexOf(a) === 0) {
		return -1;
	} else {
		return 1;
	}
};

/**
 * Compares two integers and returns relative sort order
 * Loosely mimics the C# Int32 CompareTo method
 * https://docs.microsoft.com/en-us/dotnet/api/system.int32.compareto?view=netframework-4.7.1
 *
 * @param {Number} a		Number to compare against
 * @param {Number} b 		Number to compare
 */
const compareTo = (a: number, b: number): number => {
	if (a > b) {
		return 1;
	}

	if (a < b) {
		return -1;
	}

	return 0;
};

/**
 * Compare two ordering strings, with component parts split by a
 * '.' character ordered in turn - e.g. 1.2.3 comes before 1.2.4.
 * Strings with unmatching number of separators will just assume
 * empty strings where no matches take place - e.g. 1.2 comes
 * before 1.2.1.
 *
 * Returns Less than 0 if a before b, greater than 0 if a after b, and
 * 0 if equal
 * @param {String} a		String to compare against
 * @param {String} b 		String to compare
 *
 * Alps.Std.Utilities.ComparerUtilities.cs
 */
export const compareOrderingStrings = (a: string | null, b: string | null): number => {
	if (!a && !b) {
		return 0; // don't change them because everything's null
	}

	if (!a) {
		return 1; // b comes first because a is null
	}

	if (!b) {
		return -1; // a comes first because b is null
	}

	const aParts = a.split('.');
	const bParts = b.split('.');
	const maxParts = Math.max(a.length, b.length);

	for (let i = 0; i < maxParts; ++i) {
		// If a part does not exist (i.e. one of the strings has less parts)
		// then assume the missing part is an empty string.
		const aPart = i < aParts.length ? aParts[i] : '';
		const bPart = i < bParts.length ? bParts[i] : '';

		// Unable to use TryParse and out as in  C#, therefore parseInt here.
		// Strings containing letters return NaN, therefore perform isNaN check
		// when type checking part
		let intAPart = parseInt(aPart);
		let intBPart = parseInt(bPart);

		// If length is same, or either part is not an int we can sort them as strings
		if (
			aPart.length === bPart.length ||
			(isNaN(intAPart) && typeof intAPart === 'string') ||
			(isNaN(intBPart) && typeof intBPart === 'string')
		) {
			const strCmp = compareStrings(aPart, bPart);

			if (strCmp !== 0) {
				return strCmp;
			}
			// Only quit at this stage if different
		} else {
			// They are both ints, so compare them as numbers
			const intCmp = compareTo(intAPart, intBPart);

			if (intCmp !== 0) {
				return intCmp;
			} // Only quit at this stage if different
		}

		// Numbers same thus far, continue on
	}

	// Passed end of arrays, must be same strings
	return 0;
};

export default compareOrderingStrings;
