import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import { MfaType } from '../../../../utils/mfa';
import { activateMfaTypeFlow } from './activateMfaTypeSaga';
import { getCurrentActiveMfa } from '../../../../sharedSelectors/mfa/common';
import { getConnectPage } from '../../../../utils/sagaHelpers';
/**
 * Saga that fetches deactivates specific authentication types
 */
export function* deactivateAuthenticationFlow(): SagaIterator<void> {
	//A what page are we hitting this flow from
	const connectPage = yield call(getConnectPage);

	//fetch the authentication type we wish to disable
	const authenticationTypeToDisable = yield select(getCurrentActiveMfa(connectPage));

	//Which slice do we need to dispatch the action from
	const slice =
		authenticationTypeToDisable === MfaType.EMAIL_VALIDATION
			? 'EmailAuthentication'
			: 'OneTimePasswordAuthentication';

	//Deactivate the mfa type
	yield call(activateMfaTypeFlow, authenticationTypeToDisable, false, slice);

	//Set hasBeenSuccessful bool to false
	yield put({
		type: `mfaSetup${slice}/setHasBeenValidated`,
		payload: false,
	});

	//Call Mfa set up saga
	yield put({
		type: `mfa/startSetUp`,
	});
}
