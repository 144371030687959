import { ExamLevels } from '../../types/enum';
import {
	getSubjectsByStringApi,
	getSubjectsByIdApi,
	getSubjectsByNameApi,
} from './../../api/bedrockAPI';
import { getCurrentAcademicYear } from '../../utils/academicYear';

export const getSubject = async (
	benchmarkId: number,
	subjectString: string,
	options?: { academicYear?: number }
): Promise<SubjectBedrock> => {
	try {
		// Make the API call to fetch the subject
		const response = await getSubjectsByStringApi(
			benchmarkId,
			subjectString,
			options?.academicYear ?? getCurrentAcademicYear()
		);

		// Check if response is valid
		if (!response || !response.hasResponse || !response.responseObject) {
			throw new Error('Subject not found or invalid response.');
		}

		// Extract subject data from response
		const subjectData = response.responseObject;

		// Map subject data to simplified object structure
		const subject: SubjectBedrock = {
			alpsSubjectId: subjectData.alpsSubjectId ?? 0,
			value: subjectData.value,
			display: subjectData.display ?? '',
			compact: subjectData.compact,
			reportLevel: ExamLevels[subjectData.reportLevel as keyof typeof ExamLevels],
			order: subjectData.order,
			qanSyllList: subjectData.qanSyllList,
		};

		return subject;
	} catch (error) {
		throw new Error('Failed to fetch subject. Please try again later.');
	}
};

export const getSubjectsById = async (subjectsList: number[]): Promise<Array<SubjectBedrock>> => {
	try {
		const response = await getSubjectsByIdApi(subjectsList);

		// Check if response is valid
		if (!Array.isArray(response.responseObject)) {
			console.warn('Invalid response. Expected an array.');
			return [];
		}

		// Filter subjects based on subjectsList
		const filteredSubjects = response.responseObject.filter((subject) =>
			subjectsList.includes(subject.alpsSubjectId)
		);

		return filteredSubjects;
	} catch (err) {
		throw new Error('Failed to fetch subjects. Please try again later.');
	}
};

export const getSubjectsByName = async (
	subjectsList: string[],
	benchmarkId: number,
	options?: { academicYear?: number }
): Promise<Array<SubjectBedrock>> => {
	try {
		const response = await getSubjectsByNameApi(
			subjectsList,
			benchmarkId,
			options?.academicYear ?? getCurrentAcademicYear()
		);
		// Check if response is valid
		if (!Array.isArray(response.responseObject)) {
			console.warn('Invalid response. Expected an array.');
			return [];
		}

		return response.responseObject;
	} catch (err) {
		throw new Error('Failed to fetch subjects. Please try again later.');
	}
};
