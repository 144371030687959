import * as React from 'react';
import { FunctionComponent } from 'react';

import { IconButton, Flex } from 'basecamp';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

type Props = {
	onClick: (id: number) => void;
	params: ICellRendererParams;
	icon: string;
	user: ManageUsers.User;
};

const IconButtonRenderer: FunctionComponent<Props> = ({ user, onClick, icon }) => {
	return (
		<Flex setAs="row" withAlign="centerLeft" height="100%">
			<IconButton
				withIcon={icon}
				setAs="accent"
				withSize="small"
				onClick={() => onClick(user.id)}
			/>
		</Flex>
	);
};

export default IconButtonRenderer;
