import { throttle, takeLatest } from 'redux-saga/effects';

import { startSession } from './startSessionFlow';
import { changeExamLevelTabs, resetExamLevelTabs } from './changeActiveExamLevelFlow';
import { changeGradepoints } from './changeGradepointFlow';
import { loadGridData } from './mainGridDataFlow';

/**
 * Export all Strategic Analysis Sagas in one array
 * This is imported into a root saga in the store.
 * Root saga is effectively the same as the root reducer.
 */
export const strategicAnalysisSagas = [
	/**
	 * Listens for 'strategicAnalysis/changeActiveExamLevelTab' dispatches and then
	 * spawns a saga to handle changing the active exam level tab and the fetching of the main grid data.
	 * Will ignore subsequent actions of the same type for a given period of time while processing the tasks.
	 */
	throttle(1000, 'strategicAnalysis/changeActiveExamLevelTab', changeExamLevelTabs),
	throttle(1000, 'strategicAnalysis/changePageConfiguration', resetExamLevelTabs),
	/**
	 * Takes the latest action for the following and spawns the respective saga work flow
	 */
	takeLatest('context/changePrimaryGradepoint', changeGradepoints),
	takeLatest('strategicAnalysis/startNewStrategicSession', startSession),
	takeLatest('strategicAnalysis/fetchNewPageData', loadGridData),
];
