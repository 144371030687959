import { getTotalPoints } from './outcome';

export function totalActualPoints(
	os: { outcomeGroups: SubjectPage.OutcomeGroups[]; outcomes: SubjectPage.Outcomes[] },
	studentGroup: SubjectPage.Student[],
	whatIf: boolean = false,
	fineGrades: boolean = false
): number {
	return os.outcomes.reduce((acc, curr) => {
		return (acc += getTotalPoints(curr, studentGroup, whatIf, fineGrades));
	}, 0);
}

/**
 * Returns the percentage of students in each outcome group
 * { 'A*>E%': 81.8, 'A*>C%': 72.7, 'A*>B%': 63.6 }
 */
export function getMatchedPercentageForOutcomeGroups(
	og: SubjectPage.OutcomeGroups[],
	students: SubjectPage.Student[],
	whatIf: boolean = false
): any {
	return og.reduce(
		(preValue, currValue) => ({
			[`${currValue.Group}`]: (
				studentExistsInOutcome(currValue.Outcomes, students, whatIf) * 100
			).toFixed(1),
			...preValue,
		}),
		{}
	);
}

export function studentExistsInOutcome(
	outcomes: string[],
	students: SubjectPage.Student[],
	whatIf: boolean = false
): number {
	let matchedCount = 0;
	const includedStudents = students.filter((student) => student.IsIncluded);

	includedStudents.forEach((student) => {
		if (outcomes.includes(whatIf ? student.WhatIfOutcome : student.Outcome.Value)) {
			matchedCount++;
		}
	});
	return matchedCount / includedStudents.length;
}
