import * as React from 'react';
import { FunctionComponent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
	getGpSelectorState,
	toggleGpSelector,
	closeGpSelector,
} from 'features/layouts/standardPage/redux/standardPage';
import {
	UserProfileMenu,
	UserProfileMenuBtn,
	RtmMessagingBtn,
	ZohoHelpBtn,
	GroupTrendIndicator,
	ClientBenchmarkActiveWarning,
	StrategicAnalysisIndicator,
} from './components';
import { GradepointSelector, TrendSelector } from 'features/gradepointSelector';
import { RtmMessaging } from 'features/rtmMessaging';

import {
	Screen,
	NavBar,
	ConnectLogo,
	SummitLogo,
	Flex,
	Link,
	IconButton,
	Gradepoint,
} from 'basecamp';

import {
	getRtmMessages,
	getRtmMessagesThunk,
	acknowledgeMessageThunk,
	removeTRMMessageFromState,
} from '../../rtmMessaging/redux';
import {
	removeMessage as removeMessageAPI,
	removeManyMessages as removeManyMessagesAPI,
} from '../../../api/rtmAPI';
import {
	getAllGradepoints,
	getClient,
	getUser,
	getPrimaryGradepoint,
	getImpersonationMode,
	changePrimaryGradepointThunk,
	getUseClientBenchmark,
	getSubscriptionStatus,
	getBenchmarkSetCountryFromNationDataSetBenchmark,
} from 'features/app/redux/context';
import { getAllGroupTrends, getGroupSelectedTrendId } from 'features/groups/analysis/redux';
import { getGradepointIcon } from '../../../utils/gradepointSelector';

import { StudentModal } from 'features/studentModal';

import { getConfig, getFeature } from 'features/app/redux/features';

import {
	hasActiveAnalysisSession,
	shouldShowStrategicPdfReportModal,
	shouldShowStrategicSessionModal,
} from 'features/strategicAnalysis/redux';
import EndStrategicSessionModal from 'features/strategicAnalysis/endStrategicSessionModal';
import StrategicPdfReportModal from 'features/strategicAnalysis/strategicPdfReportModal';

import { getModalStatus, getLoadingStatus } from 'features/studentModal/redux';
import { Context } from 'features/app/types';

const StandardPage: FunctionComponent = ({ children }) => {
	/**
	 ** Handle Dispatch
	 */
	const dispatch = useDispatch();

	/**
	 ** Handle History
	 */
	const history = useHistory();

	/**
	 ** Handle State
	 */
	// User profile menu state
	const [userProfileMenuIsOpen, setUserProfileMenuIsOpen] = useState<boolean>(false);
	// RTM Messaging state
	const [rtmMessagingIsOpen, setRtmMessagingIsOpen] = useState<boolean>(false);
	const rtmMessages = useSelector(getRtmMessages);
	// Selected trend
	const [selectedTrend, setSelectTrend] = useState<Groups.Setup.Trend>();

	/*
	 ** Load RTM messages on mount only
	 */
	useEffect(() => {
		dispatch(getRtmMessagesThunk());
	}, []);

	/**
	 ** Handle Selectors
	 */
	// Subscription access
	const subscriptionStatus = useSelector(getSubscriptionStatus);
	// Get Gradepoint Selector state
	const gpSelectorIsOpen = useSelector(getGpSelectorState);
	// Get Gradepoints Info
	const gradepoints = useSelector(getAllGradepoints);
	const pgp = useSelector(getPrimaryGradepoint);
	// Get Trends info
	const trends = useSelector(getAllGroupTrends);
	const selectedTrendId = useSelector(getGroupSelectedTrendId);

	const impersonation = useSelector(getImpersonationMode);

	const clientBenchmarkActive = useSelector(getUseClientBenchmark);

	// Zoho help
	const canViewZohoHelp = useSelector(getFeature('zoho_help'));

	// Get Client/User Info
	const client = useSelector(getClient);
	const user = useSelector(getUser);
	// Are we dealing with Group or Single School?
	const hasGroupAccess = client?.hasGroupAccess;
	const hasMatImpersonationRights = user?.impersonationMode.toLowerCase() === 'mat';
	const isGroup = hasGroupAccess || hasMatImpersonationRights;

	// Strategic Analsis Session
	const hasActiveSession = useSelector(hasActiveAnalysisSession);
	const canViewStrategicAnalysis = useSelector(getFeature('strategic_analysis'));

	// Modals
	const showStudentModal = useSelector(getModalStatus);
	const studentInfoIsLoading = useSelector(getLoadingStatus);
	const showStrategicModal = useSelector(shouldShowStrategicSessionModal);
	const showStrategicPdfModal = useSelector(shouldShowStrategicPdfReportModal);

	//Benchmark Feature Flags
	const summitBenchmark = useSelector(getFeature('summit_benchmark'));
	const connectBenchmark = useSelector(getFeature('client_benchmark'));
	const correctContextBenchmarkFlag = hasGroupAccess ? summitBenchmark : connectBenchmark;

	const avaliableBenchmarks = useSelector(getConfig('avaliable_benchmarks'));
	const currentBenchmark = useSelector(getBenchmarkSetCountryFromNationDataSetBenchmark);
	const isBenchmarkAllowed = avaliableBenchmarks?.includes(currentBenchmark);

	/**
	 ** Set Selected Trend
	 */
	useEffect(() => {
		const getSelectedTrend = trends.find(
			(trend: Groups.Setup.Trend) => trend.id === selectedTrendId
		);
		setSelectTrend(getSelectedTrend);

		// the trend has changed so get the gradepoints for the trend
		if (getSelectedTrend && !hasGroupAccess) {
			const gradepoints = getSelectedTrend.gradepoints;
			const key = gradepoints[gradepoints.length - 1].key;
			const gradepointSelection = getSelectedTrend.gradepoints.map(
				(gp: Context.Gradepoint) => gp.key
			);

			// update the gradepoints
			dispatch(
				changePrimaryGradepointThunk({
					key,
					gradepointSelection,
					isAppStartSelection: false,
					rerunAutoGpPopulation: false,
				})
			);
		}
	}, [trends, selectedTrendId]);

	/**
	 ** Handle opening closing of GP Selector, User Profile Menu and RTM
	 */
	const toggleGradepointSelector = () => {
		setUserProfileMenuIsOpen(false);
		setRtmMessagingIsOpen(false);
		dispatch(toggleGpSelector());
	};

	const toggleUserProfileMenu = () => {
		setUserProfileMenuIsOpen(!userProfileMenuIsOpen);
		setRtmMessagingIsOpen(false);
		dispatch(closeGpSelector());
	};

	const toggleRtmMessaging = () => {
		setUserProfileMenuIsOpen(false);
		dispatch(closeGpSelector());
		setRtmMessagingIsOpen(!rtmMessagingIsOpen);
		requestNotifications();
	};

	const requestNotifications = () => {
		if (Notification.permission == 'default') {
			Notification.requestPermission();
		}
	};

	/**
	 ** Handle navigation
	 */
	const navigateToPage = (path: string): void => {
		history.push(path);
	};

	const acknowledgeMessage = async (id: number): Promise<void> => {
		dispatch(acknowledgeMessageThunk(id));
	};

	// Get messages
	const getMessages = async (): Promise<void> => {
		dispatch(getRtmMessagesThunk());
	};

	// Remove a selection of messages
	const removeMessages = async (ids: number[]): Promise<void> => {
		dispatch(removeTRMMessageFromState(ids));

		await removeManyMessagesAPI(ids);

		await getMessages();
	};

	const unreadMsgs = rtmMessages.length;

	/**
	 ** Render Component
	 */
	return (
		/* App Container */
		<Screen
			pt="60px"
			bg="UI.primary.0"
			minHeight="100vh"
			dataTest={`standardPage ${isGroup ? 'summit' : 'connect'}`}
		>
			{/* End active strategic analysis modal */}
			{showStrategicModal && <EndStrategicSessionModal />}
			{/* Show modal when user clicks on the strategic analysis create report button */}
			{showStrategicPdfModal && <StrategicPdfReportModal />}
			{/* Student Modal */}
			{!studentInfoIsLoading && showStudentModal && <StudentModal />}
			{/* NavBar */}
			<NavBar bg="UI.primary.0" dataTest="navBar">
				{/* Logo */}
				<Flex setAs="row" withAlign="center">
					<Link setAs="wrapping" withInternalUrl="/home" dataTest="alpsLogo">
						{isGroup ? <SummitLogo /> : <ConnectLogo />}
					</Link>
				</Flex>

				{/* Buttons */}
				<Flex setAs="row" withAlign="center">
					{/* Warning button when using client benchmarks*/}
					{correctContextBenchmarkFlag && isBenchmarkAllowed && (
						<ClientBenchmarkActiveWarning useClientBm={clientBenchmarkActive} />
					)}

					{/* Zoho Help Btn */}
					{canViewZohoHelp && <ZohoHelpBtn />}

					{/* Gradepoint Button */}
					{!!subscriptionStatus.hasValidSubscriptionOrContract &&
						(gradepoints.length > 0 || trends.length > 0) && (
							<Gradepoint
								withGP={
									isGroup
										? getGradepointIcon(selectedTrend?.gradepoints[0].key)
										: pgp?.type?.toLowerCase()
								}
								onClick={() => toggleGradepointSelector()}
								setAs="accent"
								ml={3}
								dataTest="gradepointSelectorBtn"
								zIndex={2}
							>
								{hasGroupAccess ? selectedTrend?.name : pgp?.name}
							</Gradepoint>
						)}
					{!!subscriptionStatus.hasValidSubscriptionOrContract && trends && trends.length > 0 && (
						<GroupTrendIndicator />
					)}
					{hasActiveSession && canViewStrategicAnalysis && <StrategicAnalysisIndicator />}

					{/* Settings Button */}
					{!hasGroupAccess && !!subscriptionStatus.hasValidSubscriptionOrContract && (
						<IconButton
							withIcon="gear"
							setAs="accent"
							withSize="medium"
							ml={3}
							onClick={() => navigateToPage('/analysis-settings')}
							dataTest="settingsBtn"
						/>
					)}

					{/* RTM Button */}
					<RtmMessagingBtn
						unreadMsgs={unreadMsgs}
						toggleRtmMessaging={() => toggleRtmMessaging()}
					/>

					{/* User Profile Button */}
					<UserProfileMenuBtn toggleMenu={() => toggleUserProfileMenu()} />
				</Flex>
			</NavBar>

			{/* User profile menu */}
			{userProfileMenuIsOpen && <UserProfileMenu close={() => setUserProfileMenuIsOpen(false)} />}

			{/* Gradepoint Selector menu */}
			{impersonation !== 'None' ? (
				!hasGroupAccess && gpSelectorIsOpen ? (
					<GradepointSelector />
				) : hasGroupAccess && gpSelectorIsOpen ? (
					<TrendSelector />
				) : (
					[]
				)
			) : !isGroup && gpSelectorIsOpen ? (
				<GradepointSelector />
			) : isGroup && gpSelectorIsOpen ? (
				<TrendSelector />
			) : (
				[]
			)}

			{/* RTM Messaging */}
			{rtmMessagingIsOpen && (
				<RtmMessaging
					close={() => toggleRtmMessaging()}
					rtmMessages={rtmMessages}
					acknowledgeMessage={(id: number) => acknowledgeMessage(id)}
					removeMessage={(id: number) => removeMessages([id])}
					removeMessages={(ids: number[]) => removeMessages(ids)}
				/>
			)}

			{/* Render children */}
			{children}
		</Screen>
	);
};

export default StandardPage;
